import styled, { css } from 'styled-components';
import { Button, Checkbox, Select } from 'antd';
import { FlexBoxCenter } from 'styles';
import { TOrderStatus } from 'interfaces/robot';

export const RobotDeliveryWrapper = styled.div``;
export const TabsWrapper = styled.div<{ $isConfirm: boolean }>`
  .top-bar-wrapper {
    position: relative;
    padding-left: 100px;
    top: ${({ $isConfirm }) => ($isConfirm ? '80px' : '0px')};
    z-index: 1;
  }
  .ant-tabs-nav {
    z-index: 2;
    top: -72px;
    .ant-tabs-nav-list {
      .ant-tabs-tab {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 130px;
        margin-top: 0;
        padding: 0;
        background: #fff;
        color: #212529;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
      }
      .ant-tabs-tab-btn {
        transition: none;
      }
      .ant-tabs-tab.ant-tabs-tab-active {
        border: solid 1px #41aa6f;
        background: #e8f6ee;
        .ant-tabs-tab-btn {
          color: #212529;
          font-weight: 700;
        }
        .tab-content-count {
          color: #41aa6f;
        }
      }
      .ant-tabs-tab:not(.ant-tabs-tab-active, :last-child) {
        border-bottom: solid 1px #eee;
      }
      .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
        display: none;
      }
    }
  }
  .ant-tabs-content-holder {
    .ant-tabs-content {
      .ant-tabs-tabpane {
        padding: 0;
      }
    }
  }
`;
export const TabLabelWrapper = styled.div`
  .tab-label {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    white-space: break-spaces;
  }
  .tab-content-count {
    height: 24px;
  }
`;
export const TabHeaderWrapper = styled(FlexBoxCenter)`
  justify-content: space-between;
  padding: 10px;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;

  .last-refresh-date {
    font-size: 16px;
    text-align: center;
    color: #6d7582;
  }

  .store-opend {
    padding: 8px 24px;
    border-radius: 4px;
    font-size: 18px;
    font-weight: 700;
    background: #eeeeee;

    .ant-switch {
      width: 76px;
      height: 36px;
      .ant-switch-handle {
        width: 32px;
        height: 32px;
        &:before {
          border-radius: 50%;
        }
      }
      .ant-switch-inner {
        margin: 1px 2px 0 30px;
        font-size: 16px;
        font-weight: 400;
      }
    }
    .ant-switch.ant-switch-checked {
      .ant-switch-handle {
        left: calc(100% - 32px - 2px);
      }
      .ant-switch-inner {
        margin: 1px 30px 0 2px;
      }
    }
    .ant-switch:not(.ant-switch-disabled):active.ant-switch-checked .ant-switch-handle::before {
      left: 0;
    }
    .ant-switch:not(.ant-switch-disabled):active .ant-switch-handle::before {
      right: 0;
    }
  }
`;
export const RefetchButton = styled(Button)`
  box-shadow: none;
  &:after {
    display: none;
  }
  &.ant-btn.ant-btn-circle {
    width: 35px;
    height: 35px;
    padding: 0;
    border: none;
    background: none;
    svg {
      width: 35px;
      height: 35px;
    }
  }
`;
export const StyledSelect = styled(Select)`
  &.ant-select,
  &.ant-select.ant-select-single.ant-select-single.ant-select-show-arrow .ant-select-selector {
    width: 240px;
    height: 40px;
    .ant-select-selection-item {
      font-size: 18px;
      font-weight: 400;
      line-height: 40px;
    }
  }
`;

export const tabLabel = {
  '.tab-label': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '60px',
    whiteSpace: 'break-spaces'
  },
  '.tab-content-count': {
    height: '24px'
  }
};

export const cssButton = css`
  &,
  &:active,
  &:focus,
  &:hover {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 70px;
    font-size: 24px;
    font-weight: 700;
    border: none;
    border-radius: 5px;
    &:not([disabled]) {
      color: #fff;
      background: #0067ff;
    }
  }
  &.ant-btn-lg {
    border-radius: 5px;
  }
`;
export const StyledButton = styled(Button)`
  ${cssButton}
`;

export const DispatchCancelButton = styled(StyledButton)`
  &.ant-btn,
  &.ant-btn:active,
  &.ant-btn:focus,
  &.ant-btn:hover {
    justify-content: space-between;
    background: #dbebff;
    padding: 0 9px;

    .robot-name {
      padding-bottom: 4px;
      line-height: 24px;
      color: #0067ff;
    }
    .delivery-start-time {
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
      color: #6d7582;
    }
    .box {
      width: 50px;
      height: 50px;
      padding: 6px 16px;
      font-size: 14px;
      font-weight: 700;
      background: #212529;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  &.ant-checkbox-wrapper {
    .ant-checkbox {
      transform: none;
      margin: 0;
      .ant-checkbox-input {
        margin: 0;
      }
      .ant-checkbox-inner {
        width: 59px;
        height: 59px;
        border: 2px solid rgb(0, 103, 255);
        border-radius: 5px;
        animation: none;
      }
    }
    .ant-checkbox-disabled {
      .ant-checkbox-inner:after {
        display: none;
      }
    }
    .ant-checkbox.ant-checkbox-checked:not(.ant-checkbox-disabled) {
      &:after {
        border: none;
        border-radius: 5px;
      }
      .ant-checkbox-inner {
        background: #0067ff;
        &:after {
          top: 47%;
          left: 22%;
          transition: none;
          width: 24px;
          height: 36px;
          border: 8px solid #fff;
          border-top: 0;
          border-left: 0;
          border-radius: 100% 4px 4px 4px;
          animation: none;
        }
      }
    }
  }
`;

export const NoContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 400px;
  font-size: 16px;
  font-weight: 400;
  color: #afb2b9;
`;
export const ContentWrapper = styled.div<{ $status?: TOrderStatus }>`
  // height: calc(100vh - ${({ $status }) => ($status === 'CONFIRM' ? '314px' : '234px')});
  height: calc(100vh - 310px);
  padding: 20px 10px;
  overflow-y: scroll;
`;
