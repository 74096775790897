/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Checkbox } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { LimitedModal } from 'components/Modal/styles';
import { CloseButton, SikdaeButton } from 'components/Button/Button';
import { setPopupDontView, getPopupDontView } from 'utils';
import moment from 'moment/moment';
import { useBanner } from 'hooks';
import { useNavigate } from 'react-router-dom';

interface NotTodayPopupProps {
  title?: string;
  maskClosable?: boolean;
  noPopupTodayBtnText?: string;
  closeBtnText?: string;
  okBtnText?: string;
  open?: boolean;
  onOk?: () => void;
  onCancel?: () => void;
}

const LimitedPopup = ({
  title,
  maskClosable = false,
  noPopupTodayBtnText = '오늘 그만 보기',
  closeBtnText = '닫기',
  okBtnText = '열기',
  open,
  onOk,
  onCancel
}: NotTodayPopupProps) => {
  const navigate = useNavigate();
  const popupDontView = getPopupDontView();
  const [checked, setChecked] = useState<boolean>(false);
  const { data } = useBanner();
  const { webBanners } = data || {};

  const menuBoardPopup = useMemo(() => {
    const boardPopup = webBanners?.filter(({ linkType }) => linkType !== 'storeLoan');
    return boardPopup?.length ? boardPopup[0] : null;
  }, [webBanners]);

  const { startDate, endDate, name, linkUrl, webImageFileUrl } = menuBoardPopup || {};
  const popupActive = moment(startDate).isBefore(moment()) && moment(endDate).isAfter(moment());

  const showPopupModal = useCallback(() => {
    if (open && !popupDontView) {
      onOk();
    }
  }, [open, popupDontView, onOk]);

  useEffect(() => {
    if (popupDontView && moment().isAfter(moment(popupDontView))) {
      setPopupDontView(null);
    }
    showPopupModal();
  }, [showPopupModal, popupDontView]);

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    setChecked(e.target.checked);
  };

  const handleModalClose = useCallback(() => {
    if (checked && noPopupTodayBtnText === '오늘 그만 보기') {
      setPopupDontView(moment().endOf('day'));
    } else if (checked && noPopupTodayBtnText === '일주일 동안 안 보기') {
      setPopupDontView(moment().endOf('weeks'));
    } else if (checked && noPopupTodayBtnText === '다시 보지 않기') {
      setPopupDontView(moment().endOf('years'));
    }
    onCancel();
  }, [checked, noPopupTodayBtnText, onCancel]);

  const handleGuide = () => {
    if (process.env.NODE_ENV === 'development') {
      navigate(linkUrl);
    } else if (process.env.NODE_ENV === 'production') {
      navigate(linkUrl);
    }
    onCancel();
  };

  return (
    <div>
      {!popupDontView && popupActive ? (
        <LimitedModal
          open={open}
          maskClosable={maskClosable}
          title={title || null}
          onOk={onOk}
          onCancel={onCancel}
          footer={[
            <div style={{ display: 'flex', justifyContent: 'space-between' }} key="footer">
              <div>
                <Checkbox className="popup-dont-view" onChange={handleCheckboxChange}>
                  {noPopupTodayBtnText}
                </Checkbox>
              </div>
              <div>
                <CloseButton onClick={handleModalClose}>{closeBtnText}</CloseButton>
                <SikdaeButton className="sikdae-btn" onClick={handleGuide}>
                  {okBtnText}
                </SikdaeButton>
              </div>
            </div>
          ]}
        >
          <div role="presentation" onClick={handleGuide}>
            <img src={webImageFileUrl} alt={name} />
          </div>
        </LimitedModal>
      ) : null}
    </div>
  );
};

export default LimitedPopup;
