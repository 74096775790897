import { useState, useEffect } from 'react';
import { setMagicCode, getRememberMe, setRememberMe, getCurrentMode } from 'utils/storages';

export const useMagicCode = () => {
  const [code, setCode] = useState<string | null>(getCurrentMode());

  useEffect(() => {
    setMagicCode(code);
  }, [code]);

  return [code, setCode];
};

export const useRemember = () => {
  const [code, setCode] = useState(() => getRememberMe() ?? true);

  useEffect(() => {
    setRememberMe(code);
  }, [code]);

  return [code, setCode];
};
