import { getRefreshToken, setToken, clearByLogout } from 'utils/storages';
import { login } from 'apis/auth';
import { storeClient } from './apiClients';

let isFetchRefresh = false;

export const storeRepInterceptor = storeClient.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  async (error) => {
    const { response } = error;
    const { data } = response;
    const { status } = data;
    if (status === -2306 && !isFetchRefresh) {
      isFetchRefresh = true;
      const params = {
        grant_type: 'refresh_token',
        refresh_token: getRefreshToken()
      };
      console.log('refresh token start');
      const resultData = await login(params);
      console.log('refresh token end');
      setToken(resultData);

      isFetchRefresh = false;
    }
    if (status === -2302 || status === -2303 || status === -2304) {
      clearByLogout();
      window.location.href = '/';
    }
    return Promise.reject(error);
  }
);

export * from './auth';
export * from './account';
export * from './store';
export * from './admission';
export * from './settlement';
export * from './booking';
export * from './shipping';
export * from './salesStats';
export * from './review';
export * from './popupBanner';
export { default as errorHandler } from './errorHandler';
