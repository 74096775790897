import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import moment from 'moment/moment';
import { generate } from 'shortid';
import React from 'react';
import { Moment } from 'moment';

const monthBtn = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

interface MonthSelectorProps {
  date: { startDate: Moment; endDate: Moment };
  current;
  currentFocus: 'start' | 'end';
  onArrowLeft;
  onArrowRight;
  onSelectMonth;
}

const MonthSelector = ({ date, current, currentFocus, onArrowLeft, onArrowRight, onSelectMonth }: MonthSelectorProps) => {
  return (
    <div>
      <div className="select-year">
        <LeftOutlined className="arrow" onClick={onArrowLeft} />
        <div className="title">{current.format('YYYY')}년</div>
        <RightOutlined className="arrow" onClick={onArrowRight} />
      </div>
      <div className="select-month">
        {monthBtn.map((month) => {
          let activeMonth;
          if (currentFocus === 'start') {
            activeMonth = moment(date.startDate)
              .month(month - 1)
              .startOf('month');
          } else if (currentFocus === 'end') {
            activeMonth = moment(date.endDate)
              .month(month - 1)
              .startOf('month');
          }
          return (
            <div key={generate()}>
              <button type="button" onClick={() => onSelectMonth(month)}>
                <div className={current.isSame(activeMonth, 'month') ? 'single-month active' : 'single-month'}>{month}월</div>
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MonthSelector;
