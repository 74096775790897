import React from 'react';
import { Button, ButtonProps } from 'antd';
import styled from 'styled-components';

type TBottom = 'mint' | 'cancel';

interface ButtonStyleProps {
  $bottomType?: TBottom;
}

export interface BottomButtonProps extends ButtonProps {
  bottomType?: TBottom;
}

const StyledButton = styled(Button)<ButtonStyleProps>`
  & {
    height: 55px;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    width: 100%;
    border-radius: 4px;
    background-color: ${({ $bottomType, theme }) => {
      if ($bottomType === 'mint') return theme.color.mint;
      if ($bottomType === 'cancel') return theme.color.gray01;
      return null;
    }};
    color: ${({ $bottomType, theme }) => {
      if ($bottomType === 'mint') return theme.color.primary;
      if ($bottomType === 'cancel') return theme.color.white;
      return null;
    }};
  }
`;

const BottomButton = ({ bottomType, ...props }: BottomButtonProps) => <StyledButton $bottomType={bottomType} {...props} />;

export default BottomButton;
