import { Button } from 'antd';
import React from 'react';
import { telWithHyphen } from 'utils/stringUtils';
import { DeliveryModal, Table } from '../styles';

const DeliveryInfoModal = ({ open, close, data }) => {
  return (
    <DeliveryModal
      visible={open}
      title="배달 정보"
      onCancel={close}
      width={800}
      closable={false}
      footer={[
        <Button key="DeliveryInfoClose" onClick={close} ghost>
          확인
        </Button>
      ]}
    >
      <div>
        <Table>
          <tbody className="deliveryInfo">
            <tr>
              <td className="header">고객사</td>
              <td className="content">
                <div>{data?.companyName}</div>
              </td>
            </tr>
            <tr>
              <td className="header">배달지 별명</td>
              <td className="content">
                <div>{data?.spotName}</div>
                <div>
                  도로명 주소 : {data?.roadAddress} {data?.addressDetail}
                </div>
                <div>
                  지번 주소 : {data?.jibunAddress} {data?.addressDetail}
                </div>
              </td>
            </tr>
            <tr>
              <td className="header">받는 사람</td>
              <td className="content">
                <div>{data?.recipient}</div>
              </td>
            </tr>
            <tr>
              <td className="header">연락처</td>
              <td className="content">
                <div>{telWithHyphen(data?.tel)}</div>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </DeliveryModal>
  );
};

export default DeliveryInfoModal;
