import React, { useEffect, useState } from 'react';
import { validateMessages, onlyNumberValidator, minLength } from 'validators';
import { Form, Input, Select } from 'antd';
import { mainCategoryStringToArray } from 'utils';
import AddressPost from 'components/AddressPost';
import BottomButton from 'components/Button/BottomButton';

const { useForm } = Form;

const AppInfoForm = ({ initValue, mainCategories, onSubmit, isLoading }) => {
  const [form] = useForm();

  const [is, forceUpdate] = useState<Record<string, undefined> | undefined>({});

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    if (!form || !initValue) {
      return;
    }
    const reValue = { ...initValue };
    if (typeof initValue.mainCategoryIdx === 'string') {
      reValue.mainCategoryIdx = mainCategoryStringToArray(initValue.mainCategoryIdx);
    }
    const { zoneCode, addressType, addressDetail, jibunAddress, roadAddress, phone } = initValue;
    if (addressType) {
      reValue.address = {
        zoneCode,
        addressType,
        addressDetail,
        jibunAddress,
        roadAddress
      };
    } else {
      reValue.address = {
        zoneCode,
        addressType,
        addressDetail: initValue.addressDetail,
        jibunAddress,
        roadAddress
      };
    }
    if (!initValue?.storePhone) {
      reValue.storePhone = phone;
    }

    form.setFieldsValue(reValue);
  }, [form, initValue]);

  const handleValuesChange = () => {
    if (is) {
      forceUpdate(undefined);
    }
  };

  const handleSubmit = async (v) => {
    await onSubmit(v);
    forceUpdate({});
  };

  return (
    <Form
      autoComplete="off"
      layout="vertical"
      className="app-display-form"
      name="appDisplayFrom"
      form={form}
      onValuesChange={handleValuesChange}
      onFinish={handleSubmit}
      validateMessages={validateMessages}
    >
      <Form.Item name="address" label="사업장 주소" rules={[{ required: true }]}>
        <AddressPost disabled />
      </Form.Item>
      <Form.Item name="storeName" label="상호명" rules={[{ required: true }]}>
        <Input placeholder="상호명 입력" />
      </Form.Item>
      <Form.Item
        name="storePhone"
        label="상호 전화번호"
        rules={[{ required: true }, onlyNumberValidator<string>('-없이 숫자만 입력'), minLength<string>(7)]}
      >
        <Input placeholder="-없이 숫자만 입력" />
      </Form.Item>

      <Form.Item name="mainCategoryIdx" label="업종" rules={[{ required: true }]}>
        <Select mode="multiple">
          {mainCategories &&
            mainCategories.length &&
            mainCategories.map((category) => <Select.Option key={category.idx}>{category.name}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item shouldUpdate>
        {() => (
          <BottomButton
            type="primary"
            htmlType="submit"
            className="settlement-submit"
            disabled={!!is || !form.isFieldsTouched(true) || !!form.getFieldsError().filter(({ errors }) => errors.length).length}
            loading={isLoading}
          >
            변경내역 수정
          </BottomButton>
        )}
      </Form.Item>
    </Form>
  );
};

export default AppInfoForm;
