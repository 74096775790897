import { Button } from 'antd';
import { useMeInfo } from 'hooks';
import React from 'react';
import { Link } from 'react-router-dom';
import { setPassCertification } from 'utils/storages';
import { StepLayout } from 'layouts';
import image from 'images/reorganization_image.png';
import { DescriptionWrapper, ColorSpan, ImageWrapper, ExclaimWrapper, ReorganizationButtonArea } from './style';

const redirect = {
  success: '/confirmReorganization'
  // fault: '/'
};

const Reorganization = () => {
  const { meInfo } = useMeInfo();
  const onClick = () => {
    const id = meInfo?.admin?.id;
    if (id) {
      setPassCertification(id, true);
    }
  };
  return (
    <div>
      <StepLayout
        desc={
          <DescriptionWrapper>
            <div>
              <ColorSpan>식권대장 사장님</ColorSpan>으로
            </div>
            <div>새롭게 개편되었어요!</div>
          </DescriptionWrapper>
        }
        subDesc={
          <DescriptionWrapper>
            <div>원활한 사용을 위해</div>
            <div>필수정보를 인증해주세요</div>
          </DescriptionWrapper>
        }
        content={
          <>
            <ImageWrapper>
              <img width={260} src={image} alt="reorganization logo" />
            </ImageWrapper>
            <ExclaimWrapper>
              <div>정산에 꼭 필요한 인증이 포함되어 있으니</div>
              <div>번거로우시더라도 인증하기를 진행해주세요!</div>
            </ExclaimWrapper>
          </>
        }
        bottom={
          <ReorganizationButtonArea>
            <Link to="/certification" state={{ from: '/reorganization', redirect, signId: meInfo?.admin?.id }} replace>
              <Button type="primary">인증하기</Button>
            </Link>
            <Link className="skip-link" to="/" onClick={onClick}>
              다음에 할래요
            </Link>
          </ReorganizationButtonArea>
        }
      />
    </div>
  );
};

export default Reorganization;
