import React from 'react';
import { Chart } from 'react-google-charts';

const DonutChart = ({ data }) => {
  const height = '450px';

  const options = {
    chartArea: { width: '100%' },
    is3D: false,
    pieHole: 0.3,
    colors: ['#61C282', '#647FB3', '#A294D6', '#FB828A', '#FFB982', '#F0E46B']
  };

  return <Chart chartType="PieChart" data={data} options={options} graph_id="ScatterChart" width="100%" height={height} />;
};

export default DonutChart;
