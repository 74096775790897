import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const CompleteContainer = styled.div`
  width: 100%;
  padding: 24px 20px;
  font-size: 18px;
  gap: 16px;
  border-radius: 8px;
  color: ${({ theme }) => theme.color.gray01};
  background-color: ${({ theme }) => theme.color.gray05};
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  div {
    width: 100%;
    display: flex;
    & .complete-label {
      color: ${({ theme }) => theme.color.gray03};
      width: 30%;
    }
    & .complete-label.sign-id + b {
      color: ${({ theme }) => theme.color.primary};
    }
    & .complete-date {
      color: ${({ theme }) => theme.color.gray03};
      font-size: 12px;
    }
  }
`;
