import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation, Location, useNavigate } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import ReactGa from 'react-ga4';
import { ConfigProvider } from 'antd';
import { MeInfoProvider } from 'contexts/MeInfoContext';
import { RequireStateRoute, UpdateNoticeModal } from 'components';
import { useAuth } from 'hooks';
import { TransitionLayout } from 'layouts';
import {
  Login,
  ForgotSignId,
  ForgotPassword,
  SignUp,
  AgreeSignUp,
  AlreadyExists,
  CompleteSignUp,
  Certification,
  Main,
  CertificationResult,
  CertificationFault,
  ChangePassword,
  Withdraw
} from 'pages';
import PasswordSkipAndChange from 'pages/ChangePassword';
import { routes } from 'configs/route';
import { getMagicCode } from 'utils/storages';
import { ReactQueryDevtools } from 'react-query/devtools';

import './App.less';
import { sendFlutterGetTokenBridge } from 'utils';

const App = () => {
  const navigate = useNavigate();
  const magicCode = getMagicCode();
  const location: Location = useLocation();
  const auth = useAuth();
  const [current, setCurrent] = useState({ page: location.pathname, direction: 'next1', order: 0, newOrder: 0 });

  useEffect(() => {
    if (auth?.account?.guid) {
      ReactGa.gtag('set', 'user_properties', {
        account_verified: true,
        user_uid: auth?.account?.guid
      });
    }
  }, [auth]);

  useEffect(() => {
    if (location?.pathname) {
      ReactGa.send({ hitType: 'pageview', page: location.pathname, user_id: auth?.account?.guid });
    }
  }, [location?.pathname, auth]);

  const goMain = () => {
    navigate('/', { replace: true });
  };

  useEffect(() => {
    const newPage = location.pathname;
    const newPageOrder = routes.filter((page) => page.path === newPage);

    const curPage = current.page;
    const curPageOrder = routes.filter((page) => page.path === curPage);

    if (newPage !== curPage && curPageOrder.length > 0 && newPageOrder.length > 0) {
      const order = curPageOrder[0].order || 0;
      const newOrder = newPageOrder[0].order || 0;
      const direction = order < newOrder ? 'next1' : 'prev1';
      setCurrent({
        page: newPage,
        direction,
        order,
        newOrder
      });
    }
  }, [current.page, location]);

  if (window.getToken) {
    sendFlutterGetTokenBridge();
    return <>안녕하세요 Flutter</>;
  }

  return (
    <div className="App">
      <UpdateNoticeModal />
      <ConfigProvider componentSize="large">
        {auth?.authenticated ? (
          <MeInfoProvider>
            {auth?.account?.password === 'NONE' ? (
              <Main />
            ) : (
              <PasswordSkipAndChange userId={auth?.account?.guid} passwordStatus={auth?.account?.password} openMain={goMain} />
            )}
          </MeInfoProvider>
        ) : (
          <TransitionGroup className={`transition-group-${current.direction}`}>
            <CSSTransition key={location.pathname} classNames="page-transition" timeout={500}>
              <Routes location={location}>
                <Route path="/" element={<TransitionLayout />}>
                  <Route index element={<Login />} />
                  <Route path="forgotSignId" element={<ForgotSignId />} />
                  <Route path="forgotPassword" element={<ForgotPassword />} />
                  <Route
                    path="certificationResult"
                    element={
                      <RequireStateRoute to="certificationResult">
                        <CertificationResult />
                      </RequireStateRoute>
                    }
                  />
                  <Route path="certificationFault" element={<CertificationFault />} />
                  <Route
                    path="changePassword"
                    element={
                      <RequireStateRoute to="changePassword">
                        <ChangePassword />
                      </RequireStateRoute>
                    }
                  />
                  <Route path="password" element={<PasswordSkipAndChange />} />
                  <Route path="agreeSignUp" element={<AgreeSignUp />} />
                  <Route
                    path="alreadyExists"
                    element={
                      <RequireStateRoute to="alreadyExists">
                        <AlreadyExists />
                      </RequireStateRoute>
                    }
                  />
                  <Route path="signUp" element={<SignUp />} />
                  <Route path="completeSignUp" element={<CompleteSignUp />} />
                  <Route path="test" element={<AlreadyExists />} />
                  <Route path="withdraw" element={<Withdraw />} />
                  <Route
                    path="certification"
                    element={
                      <RequireStateRoute to="certification">
                        <Certification />
                      </RequireStateRoute>
                    }
                  />
                </Route>
              </Routes>
            </CSSTransition>
          </TransitionGroup>
        )}
      </ConfigProvider>
      <ReactQueryDevtools initialIsOpen={magicCode === 'test'} />
    </div>
  );
};

export default App;
