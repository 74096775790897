import { useBookingStats, useExcelDownload } from 'hooks';
import { TDate, TStats } from 'interfaces/booking';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Range } from 'react-date-range';
import { numberWithCommas, toJson } from 'utils';
import BookingStats from './BookingStats';

type TBookingStatsParams = {
  start: string;
  end: string;
  groupBy: string;
};

const ReserveStats = () => {
  const { changeExcelParams } = useExcelDownload();
  const [params, setParams] = useState<TBookingStatsParams>({
    start: moment().date(1).format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD'),
    groupBy: 'DATE'
  });
  const { data } = useBookingStats(params, () => {
    changeExcelParams({
      date1: params.start,
      date2: params.end,
      data: { isLimit: false }
    });
  });

  const getBookingStats = (p: unknown) => {
    setParams(p as TBookingStatsParams);
  };

  const [date, setDate] = useState<TDate>({
    key: 'selection',
    startDate: new Date(moment().date(1).toDate()),
    endDate: new Date(),
    formatText: `${moment().date(1).format('YYYY. M. DD.')} - ${moment().format('YYYY. M. DD.')}`,
    years: `${moment().date(1).format('YYYY')} 년`
  });

  const [groupBy, setGroupBy] = useState<string>('DATE');
  const [statsList, setStatsList] = useState<TStats[] | null | undefined>(null);
  const [company, setCompany] = useState<string | null>(null);
  const [booking, setBooking] = useState<number | null>(null);
  const [companyList, setCompanyList] = useState<string[]>([]);
  const [bookingList, setBookingList] = useState<number[]>([]);
  const [graphData, setGraphData] = useState<unknown[] | Record<string, undefined>>({});

  /**
   * 그래프 데이터 세팅
   */
  const makeGraphData = (statsGraphList: TStats[] | null | undefined) => {
    let defaultInfo: unknown[] = [
      [
        '월',
        '금액',
        {
          sourceColumn: 0,
          role: 'tooltip',
          type: 'string',
          calc: 'stringify'
        }
      ]
    ];

    if (groupBy !== 'DATE') defaultInfo = [['회사명', '금액', '%']];

    if (statsGraphList) {
      const statsData: TStats[] = toJson(statsGraphList);
      const graphList: { name: string; price: number; info: string; companyName: string }[] = [];
      const dateNames: string[] = [];
      const graphInfo = [];

      statsData.sort().forEach((stats: TStats) => {
        const { groupByName, bookingGroup, bookingPrice, companyName } = stats;
        const yMonth = groupByName.substring(0, 7);
        const name = `${groupBy === 'DATE' ? yMonth : groupByName} - ${bookingGroup}`;
        const info = `${name}: ${numberWithCommas(bookingPrice)} 원`;
        const obj = { name, price: bookingPrice, info, companyName };

        dateNames.push(name);
        graphList.push(obj);
      });

      const dNames = Array.from(new Set(dateNames));

      // 같은 날짜에 중복된 식사시간이 있는지 탐색
      for (let i = 0; i < dNames.length; i += 1) {
        let total = 0;

        const objInfo: Partial<{ name: string; price: number; info: null | string }> | Record<string, unknown> = graphList
          .filter((item) => item.name === dNames[i])
          .reduce((acc, item) => {
            const result = { ...acc, name: item.name, price: (total += item.price), info: null };
            return result;
          }, {});

        objInfo.info = `${objInfo.name}: ${numberWithCommas(objInfo.price as number)} 원`;
        graphInfo.push(Object.values(objInfo));
      }

      const newData = defaultInfo.concat(graphInfo);

      setGraphData(newData);
    } else {
      setGraphData({});
    }
  };

  const remapData = (contents: TStats[]) => {
    const response: { company: string[]; booking: number[]; contents: TStats[] } = {
      company: [],
      booking: [],
      contents: []
    };
    const tempData: Record<string, TStats[][]> = {};
    contents &&
      contents.forEach((k) => {
        if (!Object.prototype.hasOwnProperty.call(tempData, k.companyName)) {
          tempData[k.companyName] = [];
        }

        if (!Object.prototype.hasOwnProperty.call(tempData[k.companyName], k.bookingGroup)) {
          tempData[k.companyName][k.bookingGroup] = [];
        }

        if (!response.company.includes(k.companyName)) response.company.push(k.companyName);

        if (!response.booking.includes(k.bookingGroup)) response.booking.push(k.bookingGroup);

        tempData[k.companyName][k.bookingGroup].push(k);
      });

    const selectCompany = company || response.company[0];
    const selectBooking = booking || response.booking[0];
    // console.log(selectCompany, selectBooking, company, booking, response.company, response.booking);
    response.contents = contents ? tempData[selectCompany][selectBooking] : [];
    return response;
  };

  /**
   * 통계 데이터 세팅
   */
  const setData = (result: { contents?: TStats[] } = {}) => {
    const { contents } = result;
    if (contents && groupBy === 'DIVISION') {
      const getMApData = remapData(contents);
      setCompanyList(getMApData.company);
      setBookingList(getMApData.booking);
      setStatsList(getMApData.contents);
      makeGraphData(getMApData.contents);
    } else {
      setStatsList(contents);
      makeGraphData(contents);
    }
  };

  useEffect(() => {
    setData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, company, booking]);

  /**
   * [ 달력 날짜 변경 ]
   * @param {[Object]} ranges [ 선택 된 startDate, endDate ]
   */
  const handleCalendar = (ranges: Record<string, Range>) => {
    const key = date.key || 'selection';
    setDate({ ...date, ...ranges[key] });
  };

  /**
   * 년도 텍스트
   */
  // const setYears = () => {
  //   const { startDate, endDate } = date;
  //   const sDate = moment(startDate).format('YYYY');
  //   const eDate = moment(endDate).format('YYYY');

  //   const years = sDate === eDate ? `${sDate} 년` : `${sDate} ~ ${eDate} 년`;
  //   setDate({ ...date, years });
  // };

  /**
   * 통계 데이터 조회
   */
  const getData = async (v?: string) => {
    const sDate = moment(date.startDate).format('YYYY-MM-DD');
    const eDate = moment(date.endDate).format('YYYY-MM-DD');
    const bookingParams = {
      start: sDate,
      end: eDate,
      groupBy: v || groupBy
    };

    getBookingStats(bookingParams);
  };

  /**
   * 날짜 재설정
   */
  // const resetDate = (ranges: { selection: Range }) => {
  //   const { startDate, endDate, key } = ranges.selection;
  //   date.key = key;
  //   date.startDate = startDate;
  //   date.endDate = endDate;
  //   setYears();
  // };

  /**
   * 그룹명 텍스트
   */
  const changeGroup = (value: string) => {
    setGroupBy(value);
    getData(value);
  };

  /**
   * 회사명 텍스트
   */
  const changeCompany = (value: string) => {
    setCompany(value);
  };

  /**
   * 식사시간명 텍스트
   */
  const changeBooking = (value: string) => {
    setBooking(Number(value));
  };

  /**
   * 날짜 선택 후 데이터 조회
   */
  const handleCalenderSuccess = () => {
    const sDate = moment(date.startDate);
    const eDate = moment(date.endDate);

    getData();
    date.formatText = `${sDate.format('YYYY. M. DD.')} - ${eDate.format('YYYY. M. DD.')}`;
  };

  return (
    <BookingStats
      handleCalendar={handleCalendar}
      handleCalenderSuccess={handleCalenderSuccess}
      date={date}
      changeGroup={changeGroup}
      changeBooking={changeBooking}
      changeCompany={changeCompany}
      tabStore={{
        company,
        booking,
        date,
        graphData,
        groupBy,
        bookingList,
        companyList,
        statsList
      }}
    />
  );
};

export default ReserveStats;
