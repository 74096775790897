/* eslint-disable */
// @ts-nocheck
import { isMobile } from 'utils';

const channelTalk = {
  loadScript() {
    const w = window;
    if (w.ChannelIO) {
      return (window.console.error || window.console.log || function () {})('ChannelIO script included twice.');
    }
    const d = window.document;
    var ch = function () {
      ch.c(arguments);
    };
    ch.q = [];
    ch.c = function (args) {
      ch.q.push(args);
    };
    w.ChannelIO = ch;

    function l() {
      if (w.ChannelIOInitialized) {
        return;
      }
      w.ChannelIOInitialized = true;
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js';
      s.charset = 'UTF-8';
      const x = document.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
    }
    if (document.readyState === 'complete') {
      l();
    } else if (window.attachEvent) {
      window.attachEvent('onload', l);
    } else {
      window.addEventListener('DOMContentLoaded', l, false);
      window.addEventListener('load', l, false);
    }
  },

  boot(settings) {
    settings.pluginKey = this.getPluginKey();
    if (isMobile()) {
      settings.mobileOpenUI = 'iframe'; // 채널톡에서 추천하지 않음
    }
    window.ChannelIO('boot', settings);
  },

  shutdown() {
    window.ChannelIO('shutdown');
  },
  changeProfile(profile) {},
  getPluginKey() {
    const sv = localStorage.getItem('magicCode');
    if (!sv) {
      return 'f0b92bdf-2325-4df3-b3fb-a32126868ea4';
    }
    return '8cbe3473-f8cc-4818-88fb-b1adf275013f';
  }
};

export default channelTalk;
