/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Image } from 'antd';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const CustomUploadList = ({ originNode, file, currFileList, action }) => {
  const [src, setSrc] = useState('');

  useEffect(() => {
    if (file?.url) {
      setSrc(file.url);
    } else {
      getBase64(file).then((result) => setSrc(result as string));
    }
  }, [file]);

  return <Image width={150} src={src} />;
};

export default CustomUploadList;
