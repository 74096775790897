import styled from 'styled-components';
import { Modal } from 'antd';

export const Content = styled.div`
  padding: 24px;
`;

export const StyledDateModal = styled(Modal)`
  .ant-modal-body {
    padding-bottom: 0;
  }
  .modal-footer {
    padding: 0;
    border-top: none;
  }
  .ant-modal-footer {
    padding: 20px;
  }
`;

export const StyledTermModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
    text-align: center;
  }
  .ant-modal-body {
    padding: 0px 11px 16px 11px;
  }
  .ant-modal-footer {
    border-top: none;
    .ant-btn {
      width: 100%;
      height: 55px;
    }
  }
`;

export const QuarterStrBtn = styled.div`
  cursor: pointer;
  display: inline-block;
  width: 80px;
  height: 60px;
  border-radius: 5px;
  background-color: #ffffff;
  border: 1px solid #dcdcdc;
  font-size: 15px;
  text-align: center;
  line-height: 30px;
  margin-right: 10px;
  margin-top: 10px;
  color: #999999;

  &.active {
    color: white !important;
    background-color: #333333 !important;
  }
`;

export const Container = styled.div`
  .flex {
    width: 652px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .inner-width {
    padding: 0px 16px 26px 16px;
    width: 50%;
  }

  .inner-title {
    padding-bottom: 16px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19.2px;
  }

  .date-range {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #6d7582;
    padding: 4px 0px 4px 0;
    text-align: center;
  }
`;

export const DateRange = styled.div`
  button {
    all: unset;
  }

  .date-btn-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .date-btn {
    cursor: pointer;
    display: inline-block;
    width: 100px;
    height: 40px;
    border-radius: 5px;
    background-color: #ffffff;
    border: 1px solid #dcdcdc;
    font-weight: 700;
    font-size: 14px;
    text-align: center;
    line-height: 23px;
    margin-right: 5px;
    padding-top: 8px;
    color: #afb2b9;

    :focus {
      color: #4bb378;
      border: 1px solid #4bb378;
    }
  }

  .date-btn.active {
    color: #4bb378;
    border: 1px solid #4bb378;
  }

  .to-from {
    padding: 0px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19.2px;
  }

  .margin-right {
    margin-right: 10px;
  }

  .select-year {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 24px 0px 24px 0px;

    .title {
      font-weight: 700;
      font-size: 16px;
      line-height: 19.2px;
      margin: 0px 25px 0px 25px;
    }

    .arrow {
      cursor: pointer;
    }
  }

  .select-month {
    display: flex;
    flex-direction: row;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    color: #afb2b9;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;

    .single-month {
      width: 145px;
      height: 45px;
      margin: auto;
      padding-top: 10px;
      border-radius: 4px;

      :hover {
        color: black;
        background-color: #e4f8ef;
      }
    }
    .single-month.active {
      color: black;
      background-color: #e4f8ef;
    }
  }
`;

export const ConviSet = styled.div`
  button {
    all: unset;
  }

  .btn-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .btn {
    cursor: pointer;
    display: inline-block;
    width: 80px;
    height: 60px;
    border-radius: 5px;
    background-color: #ffffff;
    border: 1px solid #dcdcdc;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    line-height: 23px;
    margin-right: 10px;
    margin-top: 10px;
    color: #afb2b9;

    :hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  .btn.active {
    color: white;
    background-color: #333333;
  }
`;

export const LimitedModal = styled(Modal)`
  position: absolute;
  top: 25%;
  left: 35%;

  .ant-modal-close {
    display: none;
  }
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-content,
  .ant-modal-header {
    border-radius: 10px;
  }
  .sikdae-btn {
    margin-left: 10px;
  }
  .popup-dont-view {
    padding-top: 8px;
    font-weight: 400;
  }

  img {
    border-radius: 10px 10px 0 0;
    width: 520px;
    height: 444px;
    cursor: pointer;
  }
`;
