import { Button, Checkbox, Collapse, Modal } from 'antd';
import { useBookingHistoriesDetail } from 'hooks';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { generate } from 'shortid';
import BookingDetailModal from '../modal/BookingDetailModal';
import BookingPushModal from '../modal/BookingPushModal';
import { BookingTimeInfoContainer, Table } from '../styles';

const { Panel } = Collapse;

const BookingCompanyInfos = ({ data, companyId, bookingDate, sid }) => {
  const [deliveryData, setDeliveryData] = useState<object>({});
  const [isDeliveryInfo, setDeliveryInfo] = useState<boolean>(false);
  const [checkedList, setCheckedList] = useState<Array<string>>([]);
  const [isPush, setPush] = useState<boolean>(false);
  const [recipient, setRecipient] = useState<Array<object>>([]);
  const [activeKey, setActiveKey] = useState<string | string[]>([]);

  const [cid, setCouponId] = useState<string | undefined>(null);
  const { data: detail } = useBookingHistoriesDetail(cid);

  const deliveryMsgOn = data?.storeOption.isExposeDeliveryRequirement;

  useEffect(() => {
    setActiveKey([]);
  }, [data]);

  useEffect(() => {
    if (!isDeliveryInfo) {
      setCouponId(null);
    }
  }, [isDeliveryInfo]);

  const onDeliveryInfo = (delivery) => {
    setCouponId(delivery.couponId);
    setDeliveryData(delivery);
    setDeliveryInfo(true);
  };

  const closeDeliveryInfo = () => {
    setDeliveryInfo(false);
  };

  const onPushModal = (e) => {
    e.stopPropagation();
    const pushData = [];
    if (!checkedList || checkedList.length <= 0) {
      Modal.info({
        content: <>대상을 선택해 주세요.</>,
        icon: null,
        okText: '확인',
        onOk: () => {},
        className: 'info-handler-modal',
        style: { top: 'calc(50% - 200px)' }
      });
      return;
    }
    checkedList.forEach((id) => {
      const pushArray = data?.bookingTimeCompanyInfos.filter(({ couponId }) => couponId === id)[0];
      pushData.push(pushArray);
    });
    setRecipient(pushData);
    setPush(true);
  };

  const closePushModal = () => {
    setPush(false);
    setRecipient([]);
  };

  const onCheck = (name, checked, id?) => {
    if (name === 'ALL' && checked) {
      const array = data?.bookingTimeCompanyInfos?.map(({ couponId }) => {
        return couponId;
      });
      setCheckedList(array);
    } else if (name === 'ALL' && !checked) {
      setCheckedList([]);
    } else if (name !== 'ALL' && checked && id) {
      setCheckedList([...checkedList, id]);
    } else if (name !== 'ALL' && !checked && id) {
      setCheckedList(checkedList.filter((el) => el !== id));
    }
  };

  const onPaymentTypeCheck = (paymentType, check) => {
    let array = [...checkedList];
    if (check) {
      data?.bookingTimeCompanyInfos.forEach(({ status, couponId }) => {
        if (status.paymentType === paymentType || (paymentType === 'COMPLETE' && status.paymentType === 'COMPLETE_PART_CANCEL')) {
          array.push(couponId);
        }
      });
    } else {
      data?.bookingTimeCompanyInfos.forEach(({ status, couponId }) => {
        if (status.paymentType === paymentType || (paymentType === 'COMPLETE' && status.paymentType === 'COMPLETE_PART_CANCEL')) {
          array = array.filter((id) => id !== couponId);
        }
      });
    }
    setCheckedList(array);
  };

  const onChange = (key: string | string[]) => {
    setActiveKey(key);
  };

  const paymentTypes = {
    COMPLETE: { color: '#4c9aff', status: '결제완료' },
    CANCEL: { color: '#ff562f', status: '결제취소' },
    COMPLETE_PART_CANCEL: { color: '#ff991f', status: '결제완료' }
  };

  return (
    <BookingTimeInfoContainer>
      <Collapse className="bookingCollapse" activeKey={activeKey} onChange={onChange}>
        <Panel
          key="bookingTimeInfo"
          header={
            <div className="title">
              <span>예약결제시간 기준</span>
              {bookingDate === moment().format('YYYY-MM-DD') && companyId !== 'ALL' && (
                <span role="button" tabIndex={0} onClick={(e) => e.stopPropagation()} onKeyUp={(e) => e.stopPropagation()}>
                  <Checkbox onChange={({ target }) => onPaymentTypeCheck('CANCEL', target?.checked)}>예약취소 전체</Checkbox>
                  <Checkbox onChange={({ target }) => onPaymentTypeCheck('COMPLETE', target?.checked)}>예약완료 전체</Checkbox>
                  <Button className="pushBtn" onClick={onPushModal}>
                    PUSH 보내기
                  </Button>
                </span>
              )}
            </div>
          }
        >
          <div className="total-content">
            <ul>
              <li className="succeed">결제완료 : 변동사항이 없는 정상 주문</li>
              <li className="cenceled">결제완료 : 부분취소로 변동이 있는 주문</li>
            </ul>
          </div>
          <Table>
            <thead>
              <tr>
                <th style={{ textAlign: 'center', width: deliveryMsgOn ? '14%' : '16%' }}>예약 결제 시간</th>
                {bookingDate === moment().format('YYYY-MM-DD') && companyId !== 'ALL' && (
                  <th style={{ textAlign: 'center', width: '2%' }}>
                    <Checkbox onChange={({ target }) => onCheck('ALL', target.checked)} />
                  </th>
                )}
                <th style={{ textAlign: 'center', width: '9%' }}>고객사</th>
                <th style={{ textAlign: 'center', width: deliveryMsgOn ? '8%' : '9%' }}>부서명</th>
                <th style={{ textAlign: 'center', width: deliveryMsgOn ? '8%' : '9%' }}>배달지 별명</th>
                {deliveryMsgOn ? <th style={{ textAlign: 'center', width: '10%' }}>배송요청사항</th> : null}
                <th style={{ textAlign: 'center', width: '8%' }}>받는 사람</th>
                <th style={{ textAlign: 'center', width: deliveryMsgOn ? '10%' : '12%' }}>식사명</th>
                <th style={{ textAlign: 'center', width: deliveryMsgOn ? '9%' : '10%' }}>메뉴</th>
                <th style={{ textAlign: 'center', width: '5%' }}>수량</th>
                <th style={{ textAlign: 'center', width: '5%' }}>단가</th>
                <th style={{ textAlign: 'center', width: '5%' }}>총액</th>
                <th style={{ textAlign: 'center', width: '5%' }}>예약상태</th>
                <th style={{ textAlign: 'center', width: '6%' }}>결제상태</th>
              </tr>
            </thead>
            <tbody>
              {data?.bookingTimeCompanyInfos.map((item) => (
                <tr
                  key={generate()}
                  className={item.status.paymentType === 'CANCEL' ? 'pointer cancelRow' : 'pointer'}
                  onClick={() => onDeliveryInfo(item)}
                  onKeyDown={() => onDeliveryInfo(item)}
                >
                  <td style={{ textAlign: 'center' }}>{moment(item.bookingDateTime).format('YYYY. MM. DD. / HH:mm:ss')}</td>
                  {bookingDate === moment().format('YYYY-MM-DD') && companyId !== 'ALL' && (
                    <td
                      role="gridcell"
                      tabIndex={0}
                      style={{ textAlign: 'center' }}
                      onClick={(e) => e.stopPropagation()}
                      onKeyDown={(e) => e.stopPropagation()}
                    >
                      <Checkbox
                        checked={checkedList.includes(item.couponId)}
                        onChange={({ target }) => onCheck('', target.checked, item.couponId)}
                      />
                    </td>
                  )}
                  <td style={{ textAlign: 'center' }}>{item.companyName}</td>
                  <td style={{ textAlign: 'center' }}>{item.divisionName}</td>
                  <td style={{ textAlign: 'center' }}>
                    <span>{item.spotName}</span>
                  </td>
                  {deliveryMsgOn ? <td style={{ textAlign: 'center' }}>{item.deliveryRequirementMemo}</td> : null}
                  <td style={{ textAlign: 'center' }}>{item.bookedUserName}</td>
                  <td style={{ textAlign: 'center' }}>{item.bookingStoreName}</td>
                  <td style={{ textAlign: 'center' }}>
                    {item?.menus?.map(({ menuName }) => <div key={generate()}>{menuName}</div>)}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {item.menus?.map(({ quantity }) => <div key={generate()}>{quantity}</div>)}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {item.menus?.map(({ price }) => <div key={generate()}>{price.toLocaleString()}</div>)}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {item.menus?.map(({ price, quantity }) => {
                      const totalPrice = price * quantity;
                      return <div key={generate()}>{totalPrice.toLocaleString()}</div>;
                    })}
                  </td>
                  <td style={{ textAlign: 'center' }}>{item.status.booking}</td>
                  <td style={{ textAlign: 'center', color: paymentTypes[item.status.paymentType].color }}>
                    {paymentTypes[item.status.paymentType].status}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Panel>
      </Collapse>

      <BookingDetailModal
        open={isDeliveryInfo}
        close={closeDeliveryInfo}
        data={{ delivery: deliveryData, deliveryMsgOn, ...detail }}
      />
      <BookingPushModal
        open={isPush}
        close={closePushModal}
        recipient={recipient}
        searchParams={{ sid, bookingDate }}
        defaultChecked
      />
    </BookingTimeInfoContainer>
  );
};

export default BookingCompanyInfos;
