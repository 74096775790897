import styled from 'styled-components';
import { Card } from 'antd';
import { EmployeeStatus } from 'interfaces/store';
import { ReactComponent as ExclamationIconImg } from 'images/exclamationMarkBlack30.svg';

export const EmployeeCard = styled(Card)`
  & {
    border-radius: 8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    p,
    .ant-card-head-wrapper .ant-card-head-title {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
    }
    .ant-card-extra {
      flex: 0 0 78px;
    }
  }
`;

export const EmployeeWrapper = styled.div`
  flex: 0 1 750px;
`;

type Status = { color?: EmployeeStatus };
export const EmployeeColorStatus = styled.div<Status>`
  border-radius: 20px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
  width: 78px;
  padding: 4px;
  text-align: center;
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme, color }) => {
    if (color === 'WORK') return theme.color.primary;
    if (color === 'CANCEL') return theme.color.gray07;
    if (color === 'REFUSE') return theme.color.red03;
    if (color === 'REST') return theme.color.gray07;
    if (color === 'DELETE') return theme.color.primary;
    if (color === 'PREPARATION') return theme.color.yellow;
    return theme.color.gray07;
  }};
`;

export const FullWidthSpanDate = styled.span`
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
`;

export const EmployeeDetailModalBody = styled.div`
  font-size: 16px;
  line-height: 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  .name {
    font-size: 32px;
    line-height: 48px;
    font-weight: 700;
    margin: 27px 0 21px 0;
  }
  .cell-phone {
    font-weight: 700;
  }
`;

export const ExclamationIcon = styled(ExclamationIconImg)`
  width: 30px;
`;

export const RefuseNotice = styled.div`
  width: 240px;
  margin: 16px auto;
  align-self: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: center;
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme }) => theme.color.black};
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  border-radius: 5px;
  padding: 14px 34px 25px;
`;
