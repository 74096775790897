import React, { useState, useRef } from 'react';
import { RollingModal } from 'pages/StoreImage/style';
import { CloseButton } from 'components/Button/Button';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import styled from 'styled-components';
import Guide_01 from 'images/storeImage/image_register_guide_01.png';
import Guide_02 from 'images/storeImage/image_register_guide_02.png';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

interface RollingPopupProps {
  maskClosable?: boolean;
  open: boolean;
  onOk: () => void;
  onCancel: () => void;
}

interface TStyleSwiper {
  slideidx: number;
}

const StyleSwiper = styled(Swiper)<TStyleSwiper>`
  .swiper-button-prev,
  .swiper-button-next {
    color: #afb3b8;
  }
  .swiper-button-prev:after {
    visibility: ${({ slideidx }) => (slideidx === 0 ? 'hidden' : 'visible')};
    padding-left: 40px;
    font-size: 30px;
  }
  .swiper-button-next:after {
    visibility: ${({ slideidx }) => (slideidx === 1 ? 'hidden' : 'visible')};
    padding-right: 40px;
    font-size: 30px;
  }
  .swiper-pagination-bullet-active {
    background: #606366;
  }

  .swiper-button-prev .swiper-button-disabled {
    visibility: hidden;
  }
  .swiper-button-next .swiper-button-disabled {
    visibility: hidden;
  }
`;

const RegisterGuideRollingPopup = ({ maskClosable, open, onOk, onCancel }: RollingPopupProps) => {
  const swiper = useRef(null);
  const [slideIdx, setSlideIdx] = useState<number>(0);
  const handleSlideChange = (swiperInfo) => {
    setSlideIdx(swiperInfo.activeIndex);
  };

  const handleCloseModal = () => {
    setTimeout(() => {
      swiper?.current.swiper.slideTo(0);
    }, 500);
    onCancel();
  };

  return (
    <RollingModal
      maskClosable={maskClosable}
      open={open}
      onOk={onOk}
      onCancel={handleCloseModal}
      footer={[
        <CloseButton onClick={handleCloseModal} key="footer">
          닫기
        </CloseButton>
      ]}
    >
      <StyleSwiper
        modules={[Navigation, Pagination]}
        slideperview={1}
        navigation
        pagination={{ clickable: true }}
        onSlideChange={(swiperChange) => handleSlideChange(swiperChange)}
        slideidx={slideIdx}
        ref={swiper}
      >
        <SwiperSlide>
          <img src={Guide_01} alt="register-guide" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Guide_02} alt="register-guide" />
        </SwiperSlide>
      </StyleSwiper>
    </RollingModal>
  );
};

export default RegisterGuideRollingPopup;
