/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo, useRef } from 'react';
import { Input, Space } from 'antd';
import { debounce } from 'utils';
import VAddressPost from 'components/VendysComponents/VAddressPost';

export interface AddressValue {
  jibunAddress?: string;
  roadAddress?: string;
  zoneCode?: string;
  userSelectedType?: string;
  addressType?: string;
  addressDetail?: string;
}

export interface VAddressPostProps {
  onChange?: (v: AddressValue) => void;
  visibleLabel?: boolean;
  value?: AddressValue;
  readOnly?: boolean;
  embed?: string;
  disabled?: boolean;
}

export interface VAddressPostRef {
  openPost: () => void;
}

const { Search } = Input;

const AddressPost = ({ value, onChange, disabled, ...other }: VAddressPostProps) => {
  const addressRef = useRef<VAddressPostRef>({ openPost: () => {} });

  const openPost = debounce(() => addressRef?.current?.openPost(), 200);
  const handleOpen = () => {
    openPost();
  };

  const handleChange = (newAddressValue: AddressValue) => {
    onChange?.(newAddressValue);
  };

  const handleDetailChange = ({ target: { value: v } }: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.({ ...value, addressDetail: v });
  };

  const address = useMemo(() => {
    const { addressType, jibunAddress, roadAddress } = value || {};
    if (addressType === 'R') return roadAddress;
    return jibunAddress;
  }, [value]);

  return (
    <Space style={{ width: '100%' }} direction="vertical">
      <Search value={address} readOnly onClick={handleOpen} onSearch={handleOpen} disabled={!!disabled} />
      <Input value={value?.addressDetail} onChange={handleDetailChange} disabled={!!disabled} />
      <div style={{ display: 'none' }}>
        <VAddressPost ref={addressRef} value={value} onChange={handleChange} {...other} />
      </div>
      <p>사업장 주소 변경 시 식권대장으로 문의 바랍니다. </p>
    </Space>
  );
};

export default AddressPost;
