import { storeClient } from './apiClients';

export const getSalesStatsForList = async (sid: string, params: unknown) => {
  const { data } = await storeClient.get(`v1/store/stats/${sid}`, { params });
  return data;
};

export const getSalesStatsForGraph = async (sid: string, params: unknown) => {
  const { data } = await storeClient.get(`v1/store/stats/${sid}/statsData`, { params });
  return data;
};

export const getSalesStatsListDetail = async (sid: string, params: unknown) => {
  const { data } = await storeClient.get(`v1/store/stats/${sid}/detail`, { params });
  return data;
};

export const getStoreStatsAdmin = async (params: unknown) => {
  const { data } = await storeClient.get(`/v1/store/stats/admin`, { params });
  return data;
};

export const getSalesStatsSales = async (sid: string, params: unknown) => {
  const { data } = await storeClient.get(`v1/store/stats/${sid}/sales`, { params });
  return data;
};

export const getStoreCompany = async (params: unknown) => {
  const { data } = await storeClient.get(`v1/store/company`, { params });
  return data;
};

export const getAdminSales = async (params: unknown) => {
  const { data } = await storeClient.get(`v1/store/adminSales`, { params });
  return data;
};
