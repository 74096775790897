import { CaretRightOutlined } from '@ant-design/icons';
import { Collapse, Select, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { TStats, TStore } from 'interfaces/booking';
import React, { useState } from 'react';
import styled from 'styled-components';
import { formatToYMD, numberWithCommas } from 'utils';
import ColumnChart from './ColumnChart';

const COLORS = ['#61C282', '#647FB3', '#A294D6', '#FB828A', '#FFB982', '#F0E46B'];

const StyledStatistics = styled.div`
  display: flex;

  .chart-section {
    border: 1px solid #d9d9d9;
    background-color: white;
    display: flex;
    flex-direction: column;
    flex: none;
    width: 35%;

    .chart-title {
      background-color: #f7f7f7;
      padding: 12px 16px;
      border-bottom: 1px solid #d9d9d9;
      text-align: center;
    }
  }

  .table-section {
    height: fit-content;
    border: 1px solid #d9d9d9;
    margin-left: 16px;
    background-color: white;
    flex: 1 1 0%;

    .collapse-wrapper {
      .collapse-title {
        border-bottom: 1px solid #d9d9d9;
        background-color: #f7f7f7;
        padding: 0 16px;
        margin: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 47px;

        .ant-select {
          width: 120px;

          &:not(:first-child) {
            margin-left: 16px;
          }
        }
      }

      .antd-collapse {
        border: none;

        .ant-collapse-header {
          background-color: white;

          .ant-collapse-header-text {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .panel-title {
              color: #757575;
              font-size: 18px;
            }

            .total-price {
              font-size: 30px;
              font-weight: 700;
              margin-right: 16px;
            }
          }
        }

        .ant-collapse-content-box {
          padding: 0;

          svg {
            transition: transform 0.24s;
          }

          .no-data {
            margin: 0;
            padding: 16px;
            text-align: center;
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    display: block;

    .chart-section {
      width: 100%;
    }

    .table-section {
      margin-left: 0;
      margin-top: 16px;
    }
  }
`;

const StyledTable = styled(Table)`
  .ant-pagination {
    margin: 12px 16px;
  }

  th {
    color: #333;
    font-size: 18px;
    font-weight: 700;

    border-bottom: 1px solid #333 !important;
  }

  td {
    color: #333333;
    font-size: 16px;
    font-weight: 400;
    border: none;
    padding: 0 !important;

    .custom-td {
      padding: 12px 8px;
      margin: 0;
    }
  }
`;

export interface StatisticsProps {
  title: string;
  store: TStore;
  onChangeCompany?: (value: string) => void;
  onChangeBooking?: (value: string) => void;
}
interface DataType {
  key: string;
  groupType: string;
  time: string;
  amount: string;
  price: string;
}

const Statistics = ({ title, store, onChangeCompany, onChangeBooking }: StatisticsProps) => {
  let totalPrice = 0;
  const { Panel } = Collapse;
  const dataSource: any = [];
  const companyList: { value: string; label: string }[] = [];
  const bookingList: { value: number; label: number }[] = [];
  const [activePanelKeys, setActivePanelKeys] = useState<string[]>([title]);

  const columns: ColumnsType<DataType> = [
    {
      title: `${title}별`,
      dataIndex: 'groupType',
      key: 'groupType'
    },
    {
      title: '식사시간',
      dataIndex: 'time',
      key: 'time'
    },
    {
      title: '수량',
      dataIndex: 'amount',
      key: 'amount',
      align: 'right'
    },
    {
      title: '금액',
      dataIndex: 'price',
      key: 'price',
      align: 'right'
    }
  ];

  if (store) {
    if (store.statsList?.length > 0) {
      store.statsList.forEach((stats: TStats, idx: number) => {
        const textByGroupType = store.groupBy === 'DATE' ? formatToYMD(stats.groupByName) : stats.groupByName;

        dataSource.push({
          key: `${textByGroupType}_${stats.bookingGroup}_${stats.bookingCount}_${numberWithCommas(stats.bookingPrice as number)}`,
          groupType: (
            <p className="custom-td" style={store.groupBy === 'DATE' ? {} : { borderLeft: `4px solid  ${COLORS[idx % 6]}` }}>
              {textByGroupType}
            </p>
          ),
          time: <p className="custom-td">{stats.bookingGroup}</p>, // Ref: ["조식", "중식", "석식"]
          amount: <p className="custom-td">{stats.bookingCount}</p>,
          price: <p className="custom-td">{`${numberWithCommas(stats.bookingPrice as number)} 원`}</p>
        });
      });

      store.statsList.forEach((stats: TStats) => {
        totalPrice += stats.bookingPrice as number;
      });
    }

    if (store.companyList.length > 0) {
      store.companyList.forEach((company) => {
        companyList.push({ value: company, label: company });
      });
    }

    if (store.bookingList.length > 0) {
      store.bookingList.forEach((booking) => {
        bookingList.push({ value: booking, label: booking });
      });
    }
  }

  return (
    <StyledStatistics>
      <div className="chart-section">
        <p className="chart-title">{title}별 차트</p>
        {store ? <ColumnChart type={store.groupBy === 'DATE' ? 'ColumnChart' : 'PieChart'} gData={store.graphData} /> : null}
      </div>
      <div className="table-section">
        <div className="collapse-wrapper">
          <div className="collapse-title">
            <span>{store.date.years}</span>
            {store && onChangeCompany && (
              <div>
                <Select
                  onChange={onChangeCompany}
                  options={companyList}
                  value={store.company || store.companyList[0]}
                  placeholder="선택"
                  size="middle"
                />
                <Select
                  onChange={onChangeBooking}
                  options={bookingList}
                  value={store.booking?.toString() || store.bookingList[0]}
                  placeholder="선택"
                  size="middle"
                />
              </div>
            )}
          </div>
          <Collapse
            defaultActiveKey={[title]}
            onChange={(activeKeys: string[]) => {
              setActivePanelKeys(activeKeys);
            }}
            className="antd-collapse"
          >
            <Panel
              header={
                <>
                  <em className="panel-title">합계</em>
                  <div>
                    <span className="total-price">{`${numberWithCommas(totalPrice)}원`}</span>
                    <CaretRightOutlined style={{ fontSize: '30px' }} rotate={activePanelKeys.includes(title) ? 90 : 0} />
                  </div>
                </>
              }
              showArrow={false}
              key={title}
            >
              {dataSource.length > 0 ? (
                <StyledTable dataSource={dataSource} columns={columns} pagination={{ defaultPageSize: 20 }} size="middle" />
              ) : (
                <p className="no-data">데이터가 없습니다.</p>
              )}
            </Panel>
          </Collapse>
        </div>
      </div>
    </StyledStatistics>
  );
};

export default Statistics;
