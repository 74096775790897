import { storeClient } from './apiClients';

export const getReviews = async (params: unknown) => {
  const { data } = await storeClient.get(`review/v1/stores`, { params });
  return data;
};

export const getReviewReportTypes = async () => {
  const { data } = await storeClient.get(`review/v1/report-types`);
  return data;
};

export const closeReport = async (reviewIdx, saveData) => {
  await storeClient.post(`review/v2/${reviewIdx}/report`, saveData);
};

export const saveComment = async (reviewIdx, saveData) => {
  await storeClient.post(`review/v1/${reviewIdx}/comment`, saveData);
};

export const updateComment = async (reviewIdx, commentIdx, saveData) => {
  await storeClient.put(`review/v1/${reviewIdx}/comment/${commentIdx}`, saveData);
};

export const deleteComment = async (reviewIdx, commentIdx) => {
  await storeClient.delete(`review/v1/${reviewIdx}/comment/${commentIdx}`);
};
