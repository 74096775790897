import React, { useEffect, useState } from 'react';
import { onlyNumberValidator, validateMessages } from 'validators';
import { BankCode, IBankCodeValue } from 'components';
import { Form, Typography } from 'antd';
import { IAccountCheckResult } from 'interfaces/admission';

type IValue = IBankCodeValue | undefined;

export interface BankAccountFormItemProps {
  onSearch: (v: Partial<IBankCodeValue>) => Promise<IAccountCheckResult | undefined>;
  isSearching;
  bankCodes;
  checkValid?: (v: boolean) => void;
  accountHolderKey?;
  nonCheckAccountHolder?: boolean;
  disabled?: boolean;
}

const BankAccountFormItem = ({
  onSearch,
  isSearching,
  bankCodes,
  checkValid,
  accountHolderKey,
  nonCheckAccountHolder = false,
  disabled
}: BankAccountFormItemProps) => {
  const [isValidBankAccount, setValidBankAccount] = useState(false);

  useEffect(() => {
    checkValid && checkValid(isValidBankAccount);
  }, [checkValid, isValidBankAccount]);

  const handleSearch = async (values) => {
    try {
      const result = await onSearch(values);
      if (!result) {
        return undefined;
      }
      const { resultCode } = result;
      const isValid = resultCode === 0;
      setValidBankAccount(isValid);
      return result;
    } catch (e) {
      setValidBankAccount(false);
      throw e;
    }
  };

  return (
    <Form.Item
      name="bank"
      label="계좌번호"
      rules={[
        {
          required: true,
          type: 'object',
          validator(_, value) {
            if (value && value.bankCode && value.bankAccount) {
              return Promise.resolve();
            }
            return Promise.reject(new Error(validateMessages.required));
          }
        },
        onlyNumberValidator<IBankCodeValue>('-없이 숫자만 입력', 'bankAccount'),
        ({ getFieldValue }) => ({
          validator() {
            const accountHolder = getFieldValue(accountHolderKey || 'accountHolder');
            if (!nonCheckAccountHolder && !accountHolder) {
              return Promise.reject(new Error('예금주명은 필수 항목입니다.'));
            }
            return Promise.resolve();
          }
        }),
        () => ({
          validator(_, value: IValue) {
            if (value && value.validStatus === 'success') {
              return Promise.resolve();
            }
            if (value && value.validStatus === 'error') {
              return Promise.reject(new Error(value?.invalidMessage || '계좌를 확인해 주세요.'));
            }
            if (value && value.validStatus === 'changed') {
              if (isValidBankAccount) {
                setValidBankAccount(false);
              }
              return Promise.reject(new Error('계좌를 조회 확인해 주세요.'));
            }
            return Promise.resolve();
          }
        })
      ]}
      help={isValidBankAccount ? <Typography.Text type="success">계좌 확인이 완료되었습니다.</Typography.Text> : null}
    >
      <BankCode onSearch={handleSearch} isSearching={isSearching} bankCodes={bankCodes} disabled={disabled} />
    </Form.Item>
  );
};

export default BankAccountFormItem;
