import React from 'react';
import { useMutation } from 'react-query';
import { AuthContext } from 'contexts/AuthContext';
import { ChangePasswordParams } from 'interfaces/auth';
import { changeVendysPassword, skipChangeVendysPassword } from 'apis';
import { Buffer } from 'buffer';

export const useAuth = () => React.useContext(AuthContext);

export const useChangeVendysPassword = () =>
  useMutation<void, unknown, ChangePasswordParams, unknown>(['changeVendysPassword'], (v) => {
    const { target, source } = v;

    return changeVendysPassword({
      ...v,
      target: Buffer.from(target, 'utf8').toString('base64'),
      source: source ? Buffer.from(source, 'utf8').toString('base64') : undefined
    });
  });

export const useSkipChangeVendysPassword = () => useMutation(['skipChangeVendysPassword'], () => skipChangeVendysPassword());
