import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Form, Input, Button, Space } from 'antd';

export interface AddressValue {
  jibunAddress?: string;
  roadAddress?: string;
  zoneCode?: string;
  userSelectedType?: string;
  addressType?: string;
  addressDetail?: string;
}

export interface VAddressPostProps {
  onChange?: (v: AddressValue) => void;
  visibleLabel?: boolean;
  value?: AddressValue;
  readOnly?: boolean;
  embed?: string;
}

export interface VAddressPostRef {
  openPost: () => void;
}

const VAddressPost = (props: VAddressPostProps, ref: React.Ref<VAddressPostRef>) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onChange = () => {}, visibleLabel = true, value, readOnly, embed } = props;

  const [, setAddressObj] = useState<Record<string, string>>({});
  const [visible, setVisible] = useState<{ address?: any }>({});
  const [form] = Form.useForm();

  const [addressValue, setAddressValue] = useState<AddressValue>({});

  // useEffect(() => {
  //   onChange(undefined);
  // }, []);

  const openPost = () => {
    if (readOnly) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!daum) {
      alert('다음 라이브러리가 존재하지 않습니다.');
      return;
    }
    const width = 500; // 팝업의 너비
    const height = 600; // 팝업의 높이

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const daumPost = new daum.Postcode({
      onsearch: () => {
        // 다음 검색시 history stack 이 싸임
        if (embed) {
          const event = new CustomEvent('postHistoryStackAdd', { detail: 1 });
          document.dispatchEvent(event);
        }
      },
      oncomplete(data: Record<string, string>) {
        // 팝업에서 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분.
        console.log(data, addressValue);
        const {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          address,
          zonecode,
          jibunAddress,
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          buildingName,
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          buildingCode,
          roadAddress,
          addressType,
          userSelectedType,
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          sido,
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          sigungu,
          autoJibunAddress
        } = data; // zonecode
        setAddressValue({
          ...addressValue,
          jibunAddress,
          roadAddress,
          zoneCode: zonecode,
          userSelectedType,
          addressType
        });
        setAddressObj(data);
        form.setFieldsValue({ ...data, addressDetail: undefined });
        setVisible({ address: true });
        form.validateFields();
        const reJibunAddress = jibunAddress || autoJibunAddress;
        onChange({
          ...addressValue,
          jibunAddress: reJibunAddress,
          roadAddress,
          zoneCode: zonecode,
          userSelectedType,
          addressType
        });
      },
      width: embed ? window.screen.width : null,
      submitMode: !!embed // form submit 방법 설정, false 일경우 history replace 동작
    });
    if (embed) {
      daumPost.embed(embed);
    } else {
      daumPost.open({
        left: window.screen.width / 2 - width / 2,
        top: window.screen.height / 2 - height / 2
      });
    }
  };

  const handleChange = (k: string, v: string) => {
    setAddressValue({ ...addressValue, [k]: v });
    onChange({ ...addressValue, [k]: v });
  };
  // useEffect(()=>{
  //   if(!isUpdated) return;
  //   onChange(_value)
  // },[_value])
  useEffect(() => {
    if (!value) return;
    if (value.constructor !== Object) {
      console.error('Addresspost component get empty value!!!');
      return;
    }
    setAddressValue(value);
    const key = 'address';
    setVisible({ [key]: true });
  }, [value]);

  useImperativeHandle(ref, () => ({
    openPost
  }));

  return (
    <Space direction="vertical">
      <Space>
        <Button onClick={openPost}>도로명, 지번, 건물명 검색</Button>
        <div>{addressValue.roadAddress}</div>
      </Space>
      <Input
        readOnly
        name="address"
        style={{ display: 'none' }}
        value={addressValue?.roadAddress}
        onChange={(e) => handleChange('roadAddress', e.target.value)}
      />
      <Input
        name="addressDetail"
        placeholder="상세 주소"
        maxLength={100}
        value={addressValue?.addressDetail}
        readOnly={readOnly}
        onChange={(e) => handleChange('addressDetail', e.target.value)}
        style={{
          height: visible.address ? 'auto' : '0px',
          opacity: visible.address ? 1 : 0,
          visibility: visible.address ? 'visible' : 'hidden'
        }}
      />
    </Space>
  );
};

export default forwardRef(VAddressPost);
