import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  getOauth,
  clearByLogout,
  getExistsToken,
  getAccount,
  getExistsAccount,
  getLocalStorageAfterLogout,
  setLocalStorageAfterLogout
} from 'utils/storages';
import { OAuth, Account } from 'interfaces/auth';
import { useLogin, useLogout } from 'hooks/useLogin';

interface AuthContextInterface {
  authenticated: boolean;
  account: Account | undefined;
  setAccount: (v: Account) => void;
  signIn: (username: string, password: string, callback?: (auth: OAuth) => void) => void;
  signOut: (callback?: VoidFunction) => void;
  isSignInLoading: boolean;
  isSignOutLoading: boolean;
}

export const AuthContext = React.createContext<AuthContextInterface>({
  isSignInLoading: false,
  isSignOutLoading: false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  signIn(username: string, password: string, callback: ((auth: OAuth) => void) | undefined): void {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  signOut(callback: VoidFunction | undefined): void {},
  authenticated: false,
  account: undefined,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setAccount(account: Account): void {}
});

export const AuthProvider = ({ children }) => {
  const auth = getOauth();
  const existsAuth: OAuth = getExistsToken();
  const navigate = useNavigate();
  const [authenticated, setAuthenticated] = useState(!!auth?.access_token || !!existsAuth?.access_token);
  const [account, setAccount] = useState<Account>(auth?.account || existsAuth?.account || getAccount() || getExistsAccount());
  const { mutate: signInMutate, isLoading: isSignInLoading } = useLogin();
  const { mutateAsync: signOutMutate, isLoading: isSignOutLoading } = useLogout();

  const signIn = useCallback(
    (username: string, password: string, callback?: (oAuth: OAuth) => void) => {
      signInMutate(
        { username, password },
        {
          onSuccess: (oAuth: OAuth) => {
            setAuthenticated(true);
            setAccount(oAuth.account);
            callback && callback(oAuth);
          }
        }
      );
    },
    [signInMutate]
  );

  const signOut = useCallback(
    async (callback?: VoidFunction) => {
      const popupDontView = getLocalStorageAfterLogout();
      await signOutMutate(auth.access_token, { onSuccess: callback });
      setAuthenticated(false);
      setAccount(undefined);
      clearByLogout();
      setLocalStorageAfterLogout(popupDontView);
      if (!callback) navigate('/');
    },
    [auth?.access_token, navigate, signOutMutate]
  );

  const value = useMemo(
    () => ({
      authenticated,
      account,
      setAccount,
      signIn,
      isSignInLoading,
      signOut,
      isSignOutLoading
    }),
    [account, authenticated, isSignInLoading, isSignOutLoading, signIn, signOut]
  );

  useEffect(() => {
    console.log('############# AuthProvider````` ##############');
  }, []);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
