import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BannerWrapper } from 'pages/Menu/Style';

interface TWebBanner {
  linkUrl: string;
  bannerName: string;
  webImageFileUrl: string;
}

const Banner = ({ linkUrl, bannerName, webImageFileUrl }: TWebBanner) => {
  const navigate = useNavigate();

  const handlePageRoute = () => {
    if (process.env.NODE_ENV === 'development') {
      navigate(linkUrl);
    } else if (process.env.NODE_ENV === 'production') {
      navigate(linkUrl);
    }
  };

  return (
    <BannerWrapper role="presentation" onClick={handlePageRoute}>
      <img src={webImageFileUrl} alt={bannerName} />
    </BannerWrapper>
  );
};

export default Banner;
