import styled from 'styled-components';

export const FlexBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const DetailInfo = styled.div`
  width: 600px;
  min-height: 500px;
  border: 1px solid #dcdcdc;

  .flex-column {
    display: flex;
    flex-direction: column;
    align-items: end;
  }
`;

export const AboutInfo = styled.div`
  background-color: white;
  padding: 24px 40px 14px 40px;

  .abin-date {
    font-weight: 700;
    font-size: 24px;
    line-height: 38px;
    padding-bottom: 16px;
  }

  .abin-income-box {
    padding: 8px;
    width: 520px;
    height: 98px;
    background-color: #f0fbf6;
    border: 1px solid #c6dbcf;
    color: #4bb278;
    border-radius: 4px;
    div:nth-child(1) {
      font-weight: 700;
      font-size: 20px;
      line-height: 34px;
    }
    div:nth-child(2) {
      font-weight: 700;
      font-size: 32px;
      line-height: 48px;
    }
  }

  .abin-box-top {
    padding: 16px 0 16px 0;
  }

  .abin-box {
    padding: 3px 0 3px 0;
  }

  .abin-description {
    color: #afb2b9;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
  }

  .abin-content {
    color: #6d7582;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
  }

  .abin-division {
    color: #afb2b9;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    padding-left: 8px;
  }

  .abin-warning {
    display: flex;
    justify-content: center;
    padding: 8px;
    width: 520px;
    height: 44px;
    background-color: #f2f4f6;
    border-radius: 4px;
    align-items: center;
    color: #fa503c;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
  }
`;

export const RevenueInfo = styled.div`
  border-top: 8px solid #f3f5f6;
  background-color: white;
  padding: 16px 40px 16px 40px;

  .rvin-title-box {
    border-bottom: 1px solid #dcdcdc;
    padding-bottom: 8px;
  }

  .rvin-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 38px;
  }

  .rvin-box-top {
    padding: 8px 0 0 0;
  }

  .rvin-box {
    padding: 3px 0 3px 0;
  }

  .rvin-description {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
  }

  .rvin-content {
    font-weight: 700;
    font-size: 20px;
    line-height: 34px;
  }

  .rvin-warning {
    display: flex;
    justify-content: center;
    padding: 8px;
    width: 520px;
    height: 44px;
    background-color: #f2f4f6;
    border-radius: 4px;
    align-items: center;
    color: #fa503c;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
  }
`;
