export const handlerKeyUp = (handler: () => void) => (e: React.KeyboardEvent<HTMLElement>) => {
  const key = e.key || e.keyCode;
  if (key === 'Enter' || key === 13) handler();
};

export const debounce = <T>(func, delay) => {
  let timer: NodeJS.Timeout | null = null;
  return (...args) => {
    if (timer) {
      clearTimeout(timer);
    }

    return new Promise<T>((resolve, reject) => {
      timer = setTimeout(async () => {
        try {
          const result = await func(...args);
          timer = null;
          resolve(result);
        } catch (error) {
          reject(error);
        }
      }, delay);
    });
  };
};
