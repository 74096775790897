import React from 'react';
import CheckboxShape from 'components/Checkbox/CheckboxShape';
import { StyledRadioButtonWithCheckboxShape } from './style';

interface RadioButtonWithCheckboxShapeProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  checked?: boolean;
  children?: React.ReactNode;
  fullWidth?: boolean;
  color?: 'reverse';
}

const RadioButtonWithCheckboxShape = ({ value, checked, children, fullWidth, color }: RadioButtonWithCheckboxShapeProps) => {
  return (
    <StyledRadioButtonWithCheckboxShape value={value} $fullWidth={fullWidth} $color={color}>
      <CheckboxShape checked={checked} color={color} />
      {children}
    </StyledRadioButtonWithCheckboxShape>
  );
};

export default RadioButtonWithCheckboxShape;
