import { Button } from 'antd';
import { StepLayout } from 'layouts';
import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { CertificationResultDescription } from 'components';
import { TState } from 'interfaces/transition';

const AlreadyExistsContainer = () => {
  const location = useLocation();

  const displayInfo = useMemo(() => {
    const { from, data } = (location?.state as TState) || {};
    if (data) {
      return { info: data.account?.signId, reg: data.account?.joinDate, from };
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StepLayout
      desc="이미 가입된 회원입니다!"
      content={<CertificationResultDescription signId={displayInfo?.info} regDate={displayInfo?.reg} viewPasswordDesc />}
      bottom={
        <>
          <Link to="/" replace>
            <Button type="default">로그인</Button>
          </Link>
          <Link to="/changePassword" replace state={location?.state}>
            <Button type="primary">비밀번호 재설정</Button>
          </Link>
        </>
      }
    />
  );
};

export default AlreadyExistsContainer;
