import React, { useState } from 'react';
import { DateRange, RangeKeyDict } from 'react-date-range';
import { ko } from 'date-fns/locale';
import { TDate } from 'interfaces/booking';
import { Button } from 'antd';
import DefaultModal from 'components/Booking/WeekBooking/DefaultModal';
import styled from 'styled-components';

const StyledDurationCalendar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  padding-top: 8px;
  border-color: #37925f;
  border-style: solid;
  border-width: 1px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 8px;
  background-color: #46b476;
  font-size: 18px;

  .title {
    flex: none;
    margin-right: 10%;
  }

  .date-button {
    flex: 1 1 0%;

    .modal-button {
      width: 100%;
      background-color: #37925f;
      color: #fff;
      font-size: 18px;
    }
  }
`;

interface DurationCalendarProps {
  date: TDate;
  handleCalenderSuccess?: () => void;
  handleCalendar?: (v: RangeKeyDict) => void;
}

const DurationCalendar = ({ date, handleCalenderSuccess, handleCalendar }: DurationCalendarProps) => {
  const [state, setState] = useState({
    isOpen: false
  });

  const openModal = () => {
    setState({ isOpen: true });
  };

  const onClickOk = () => {
    handleCalenderSuccess?.();
    setState({ isOpen: false });
  };

  const { isOpen } = state;

  const selectionRange = {
    startDate: date.startDate,
    endDate: date.endDate,
    key: date.key
  };

  return (
    <StyledDurationCalendar>
      <div className="title">조회기간</div>
      <div className="date-button">
        <Button type="link" onClick={openModal} className="modal-button" size="large">
          {date.formatText}
        </Button>
        <DefaultModal
          heading="기간 설정"
          component={<DateRange ranges={[selectionRange]} onChange={handleCalendar} locale={ko} />}
          open={isOpen}
          close={() => setState({ isOpen: false })}
          handleOk={onClickOk}
        />
      </div>
    </StyledDurationCalendar>
  );
};
export default DurationCalendar;
