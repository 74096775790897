import React from 'react';
import channelTalkBtn from './channelTalk_btn.svg';

const ChannelTalkButton = () => {
  return (
    <a className="custom-channel" href="#channel">
      <img src={channelTalkBtn} alt="channel talk img button" />
    </a>
  );
};

export default ChannelTalkButton;
