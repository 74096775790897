import React from 'react';
import { Sidebar, SidebarProps } from 'components';
import { useMeInfo, useAuth } from 'hooks';

type SidebarContainerProps = Pick<SidebarProps, 'onCloseDrawer' | 'posBreakPoint'>;

const SidebarContainer = ({ onCloseDrawer, posBreakPoint }: SidebarContainerProps) => {
  const { meInfo, selectedStore, changeStore } = useMeInfo();
  const auth = useAuth();
  return (
    <Sidebar
      onSignOut={auth.signOut}
      posBreakPoint={posBreakPoint}
      meInfo={meInfo}
      selectedStore={selectedStore}
      changeStore={changeStore}
      onCloseDrawer={onCloseDrawer}
    />
  );
};

export default SidebarContainer;
