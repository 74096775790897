import React, { useEffect } from 'react';
import { generate } from 'shortid';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FeatureType = {
  BIZ: 1,
  ACCOUNT: 2
};
const FeeType = {
  RATE: 1,
  FIX: 2
};

const FeeForm = ({ contact, companyList }) => {
  useEffect(() => {
    // console.log('FeeForm');
  }, []);
  const render = (item) => {
    let value;
    const { priceType } = item;

    if (priceType.code === FeeType.FIX) {
      value = priceType.value;
    } else if (priceType.code === FeeType.RATE) {
      value = `${item.serviceMealRate}%`;
    }
    return value;
  };
  return (
    <div>
      {!companyList?.length ? (
        <div className="settlement-company-content">
          <table className="settlement-company-table">
            <thead>
              <tr className="settlement-company-table-pc-tr">
                <th style={{ textAlign: 'center' }}>데이터가 존재하지 않습니다.</th>
              </tr>
            </thead>
          </table>
        </div>
      ) : (
        <div className="settlement-company-content">
          <div className="info-box">
            <div>
              수수료 요율은 <span className="bold">부가가치세 포함</span> 입니다.
            </div>
            <div>해당 목록은 연결된 고객사 목록이며, 공통 수수료 요율과 다르게 계약된 고객사의 경우 녹색으로 표시됩니다.</div>
            <div>
              <span className="bold">대장포인트</span> : 식대 외 개인 결제로 충전한 포인트
            </div>
          </div>

          <table className="settlement-company-table">
            <thead>
              <tr className="settlement-company-table-tr">
                <th colSpan={2}>공통 수수료 요율</th>
              </tr>
              <tr className="settlement-company-table-pc-tr">
                <th style={{ width: '50%' }}>식대</th>
                <th style={{ width: '50%' }}>대장포인트</th>
              </tr>
            </thead>
            <tbody>
              <tr className="infoTr">
                <td>{contact?.mealRate}%</td>
                <td>{contact?.myPointRate}%</td>
              </tr>
            </tbody>
          </table>

          <table className="settlement-company-table">
            <thead>
              <tr className="settlement-company-table-pc-tr">
                <th style={{ textAlign: 'center', width: '34%' }}>고객사</th>
                <th style={{ textAlign: 'center', width: '33%' }}>식대</th>
                <th style={{ textAlign: 'center', width: '33%' }}>대장포인트</th>
              </tr>
            </thead>
            <tbody>
              {companyList.map((item) => (
                <tr key={generate()} className={item.separateContractFlag ? 'seperate infoTr' : 'infoTr'}>
                  <td style={{ textAlign: 'center' }}>{item.companyName}</td>
                  <td style={{ textAlign: 'center' }}>{render(item)}</td>
                  <td style={{ textAlign: 'center' }}>{item.serviceMyPointRate}%</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default FeeForm;
