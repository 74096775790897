import styled from 'styled-components';
import { List } from 'antd';

export const ListItem = styled(List.Item)<{ disabled?: boolean }>`
  padding-left: 20px;
  padding-right: 20px;
  background-color: ${({ disabled, theme }) => (disabled === true ? theme.color.gray06 : null)};
`;

export const RequestHistoryContentWrapper = styled.div`
  padding: 0 20px 20px;
  background-color: ${({ theme }) => theme.color.white};
  & .ant-list {
    font-size: 12px;
    font-weight: 400;
    & .top-desc {
      color: ${({ theme }) => theme.color.gray};
      margin-bottom: 12px;
    }
    & .point {
      font-size: 14px;
      font-weight: 700;
    }
    & .redirect .ant-btn {
      font-size: 12px;
      font-weight: 400;
      padding: 0;
      height: auto;
    }
    & .ant-list-item:not([disabled]) {
      cursor: pointer;
    }
  }
`;
