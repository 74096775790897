import React, { useState } from 'react';
import { Modal, Form, Input, Select } from 'antd';
import moment from 'moment';
import { BottomButton, modalMethod } from 'components';
import { FullSpaceCenter, BottomButtonArea, FlexBoxFullWidthCenter, PrimaryColorSpan } from 'styles';
import { useEmployee, useMeInfo, useAddEmployee, useUpdateEmployee } from 'hooks';
import { employeeTypeMap, Employee, EmployeeStatus } from 'interfaces/store';
import { validateMessages, onlyNumberValidator, minLength } from 'validators';
import errorHandler from 'apis/errorHandler';
import {
  EmployeeCard,
  EmployeeWrapper,
  EmployeeColorStatus,
  FullWidthSpanDate,
  EmployeeDetailModalBody,
  RefuseNotice,
  ExclamationIcon
} from './style';

const { useForm } = Form;

const EmployeeContainer = () => {
  const [form] = useForm();
  const { selectedStore } = useMeInfo();
  const { data, refetch } = useEmployee(selectedStore?.sid || '');
  const { mutateAsync, isLoading: isAdding } = useAddEmployee();
  const { mutateAsync: changeStatus } = useUpdateEmployee();

  const [visible, setVisible] = useState(false);
  const [view, setView] = useState<{ visible: boolean; employee: Employee | null }>({ visible: false, employee: null });

  const openAdd = () => {
    setVisible(true);
  };
  const closeAdd = () => {
    setVisible(false);
  };

  const openView = (employee) => {
    setView({
      visible: true,
      employee
    });
  };
  const closeView = () => {
    setView({
      visible: false,
      employee: null
    });
  };

  const addEmployee = async (values) => {
    if (selectedStore?.sid) {
      try {
        const params = {
          storeId: selectedStore.sid,
          employee: {
            ...values
          }
        };
        await mutateAsync(params);
        setVisible(false);
        refetch();
        modalMethod.info(
          <>
            <div>요청을 발송했습니다.</div>
            <div>직원이 10일 안에 수락하지 않을 시</div>
            <div>요청은 자동으로 취소됩니다.</div>
            <br />
            <div>수락할 수 있도록</div>
            <div>반드시 사전공지 해주세요.</div>
          </>
        );
        form.resetFields();
      } catch (error) {
        errorHandler(error);
      }
    }
  };

  const updateEmployee = async (idx: number | undefined, status: EmployeeStatus, name?: string) => {
    if (selectedStore?.sid && idx) {
      if (status === 'CANCEL') {
        const isCancelConfirm = await modalMethod.confirm(
          <>
            <div>
              <PrimaryColorSpan>{name}</PrimaryColorSpan>님에게 요청한 직원 요청을
            </div>
            <div>정말 취소하시겠습니까?</div>
          </>,
          {
            cancelText: '요청 취소하기',
            okText: '요청 유지하기'
          }
        );
        if (isCancelConfirm === true) {
          return;
        }
      }
      if (status === 'DELETE') {
        const isDeleteConfirm = await modalMethod.confirm(
          <>
            <div>
              <PrimaryColorSpan>{name}</PrimaryColorSpan>님과의 고용관계를
            </div>
            <div>종료하시겠습니까?</div>
          </>,
          {
            cancelText: '고용 종료하기',
            okText: '고용 유지하기'
          }
        );
        if (isDeleteConfirm === true) {
          return;
        }
      }
      const params = {
        storeId: selectedStore.sid,
        employeeApplyIdx: idx,
        status
      };
      await changeStatus(params);
      closeView();
      refetch();

      if (status === 'CANCEL') {
        modalMethod.info(
          <>
            <div>직원 요청이 취소되었습니다.</div>
            <div>필요 시 다시 요청해주세요.</div>
          </>
        );
      }
      if (status === 'DELETE') {
        modalMethod.info(
          <>
            <div>고용관계가 종료되었습니다.</div>
            <div>필요 시 다시 직원 요청을 진행해주세요.</div>
          </>
        );
      }
    }
  };

  return (
    <FlexBoxFullWidthCenter justify="center">
      <EmployeeWrapper>
        <FullSpaceCenter direction="vertical" justify="flex-start" $minHeight="auto" style={{ marginBottom: 16 }}>
          {data?.workers &&
            data?.workers.map((w) => (
              <EmployeeCard
                key={w.employeeApplyIdx}
                hoverable
                title={w.name}
                extra={<EmployeeColorStatus color={w.status as EmployeeStatus}>{employeeTypeMap[w.status]}</EmployeeColorStatus>}
                onClick={() => openView(w)}
              >
                {/* <div><span>{w.name}</span><span>{employeeTypeMap[w.status]}</span></div> */}
                <p>{w.cellPhone}</p>
                <FlexBoxFullWidthCenter>
                  <FullWidthSpanDate>
                    초대: {w.inviteDate ? moment(w.inviteDate).format('YYYY-MM-DD HH:mm') : '-'}
                  </FullWidthSpanDate>
                  <FullWidthSpanDate>
                    {w.status === 'REFUSE' ? '거절' : '승인'}:{' '}
                    {w.approveDate ? moment(w.approveDate).format('YYYY-MM-DD HH:mm') : '-'}
                  </FullWidthSpanDate>
                </FlexBoxFullWidthCenter>
              </EmployeeCard>
            ))}
        </FullSpaceCenter>
        <BottomButtonArea>
          <BottomButton type="primary" onClick={openAdd}>
            직원 추가
          </BottomButton>
        </BottomButtonArea>
      </EmployeeWrapper>
      <Modal title={view.employee?.name || ''} footer={null} visible={view.visible} onCancel={closeView}>
        <EmployeeDetailModalBody>
          <div>
            <EmployeeColorStatus color={view.employee?.status as EmployeeStatus}>
              {view.employee?.status && employeeTypeMap[view.employee?.status]}
            </EmployeeColorStatus>
          </div>
          <p className="name">{view.employee?.name}</p>
          <p className="cell-phone">{view.employee?.cellPhone}</p>
          <FullWidthSpanDate>
            초대: {view.employee?.inviteDate ? moment(view.employee?.inviteDate).format('YYYY-MM-DD HH:mm') : '-'}
          </FullWidthSpanDate>
          <FullWidthSpanDate style={{ marginBottom: 16 }}>
            {view.employee?.status === 'REFUSE' ? '거절' : '승인'}:{' '}
            {view.employee?.approveDate ? moment(view.employee?.approveDate).format('YYYY-MM-DD HH:mm') : '-'}
          </FullWidthSpanDate>

          {view.employee?.status === 'PREPARATION' ? (
            <BottomButton
              bottomType="cancel"
              onClick={() => updateEmployee(view.employee?.employeeApplyIdx, 'CANCEL', view.employee?.name)}
            >
              요청 취소
            </BottomButton>
          ) : null}
          {view.employee?.status === 'REFUSE' ? (
            <RefuseNotice>
              <ExclamationIcon />
              <div>연결되지 않은 요청은</div>
              <div>거절된 후 3일 후</div>
              <div>자동 삭제됩니다.</div>
            </RefuseNotice>
          ) : null}
          {view.employee?.status === 'WORK' ? (
            <BottomButton
              bottomType="cancel"
              onClick={() => updateEmployee(view.employee?.employeeApplyIdx, 'DELETE', view.employee?.name)}
            >
              고용종료
            </BottomButton>
          ) : null}
        </EmployeeDetailModalBody>
      </Modal>
      <Modal title="직원 등록 요청" footer={null} visible={visible} onCancel={closeAdd}>
        <Form form={form} layout="vertical" name="employee_form" onFinish={addEmployee} validateMessages={validateMessages}>
          <Form.Item name="grade" label="권한" rules={[{ required: true }]}>
            <Select>
              <Select.Option key="MANAGER">매니저</Select.Option>
              <Select.Option key="STAFF">직원</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="name" label="이름" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name="phone"
            label="휴대폰 번호"
            rules={[{ required: true }, onlyNumberValidator('-없이 숫자만 입력'), minLength(7)]}
          >
            <Input placeholder="-없이 숫자만 입력" />
          </Form.Item>
          <Form.Item shouldUpdate>
            {() => (
              <BottomButtonArea>
                <BottomButton onClick={closeAdd}>취소</BottomButton>
                <BottomButton
                  type="primary"
                  htmlType="submit"
                  disabled={!form.isFieldsTouched(true) || !!form.getFieldsError().filter(({ errors }) => errors.length).length}
                  loading={isAdding}
                >
                  요청하기
                </BottomButton>
              </BottomButtonArea>
            )}
          </Form.Item>
        </Form>
      </Modal>
    </FlexBoxFullWidthCenter>
  );
};

export default EmployeeContainer;
