import React from 'react';
import type { LinkProps } from 'react-router-dom';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import ReactGA from 'react-ga4';

const gaMap: Record<string, { category: string; action: string }> = {
  realtime: { category: 'sikdang_daily sales', action: 'sikdang_daily sales_menu' },
  shippingOrderMgt: { category: 'sikdang_shipping order', action: 'sikdang_shipping order management' },
  masterStats: { category: 'sikdang_mastersales stats', action: 'sikdang_mastersales stats_menu' },
  salesStats: { category: 'sikdang_sales stats', action: 'sikdang_sales stats_menu' },
  settlementMetaInfo: { category: 'sikdang_settlement', action: 'sikdang_settlement_menu' },
  setting: { category: 'sikdang_setting', action: 'sikdang_setting_menu' },
  excelStorage: { category: 'sikdang_excel box', action: 'sikdang_excel box_menu' },
  menus: { category: 'sikdang_menuList box', action: 'sikdang_menuList menu_list' },
  review: { category: 'sikdang_review box', action: 'sikdang_review review' },
  thumbnail: { category: 'sikdang_thumbnail box', action: 'sikdang_thumbnail thumbnail' },
  manageBookingMenu: { category: 'sikdang_booking', action: 'sikdang_booking booking_menu' },
  reserveStatus: { category: 'sikdang_reserve_status', action: 'sikdang_reserve_status status' },
  reserveStats: { category: 'sikdang_reserve_stats', action: 'sikdang_reserve_stats stats' },
  employee: { category: 'sikdang_employee', action: 'sikdang_employee employee' },
  calculateInfo: { category: 'sikdang_calculate', action: 'sikdang_calculate calculate' }
};

const MenuLink = ({
  children,
  to,
  icon,
  name,
  state,
  onClick,
  materialIcon,
  disabled = false,
  ...props
}: LinkProps & { icon?: string; name?: string; materialIcon?: React.ReactNode; disabled?: boolean }) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  const handleClick = (e) => {
    const { category, action } = gaMap[to as string] || {};
    ReactGA.event({
      category,
      action
    });
    onClick?.(e);
  };

  return (
    <li className="sidebar-menu-item">
      {disabled ? (
        children || (
          <div className="disabledMenus">
            {materialIcon || <img src={icon} alt="lnb-icon" />}
            <span className="fa fa-anchor solo menuName">{name}</span>
          </div>
        )
      ) : (
        <Link to={to} state={state} onClick={handleClick} {...props}>
          {children || (
            <div className={match ? 'sidebar-active' : ''}>
              {materialIcon || <img src={icon} alt="lnb-icon" />}
              <span className="fa fa-anchor solo menuName">{name}</span>
            </div>
          )}
        </Link>
      )}
    </li>
  );
};

export default MenuLink;
