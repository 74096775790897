import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { modalMethod } from 'components';
import { useAuth, useMeInfo, useWithdraw } from 'hooks';

const WithdrawWrapper = styled.div`
  width: 650px;
  padding: 16px;
  margin: 0px auto;
  background: #fff;

  .withdraw-title {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    padding-bottom: 24px;
  }
  .withdraw-content {
    padding: 8px 16px;
    margin: 16px 0;
    font-size: 16px;
    background: #f7f7f7;
  }
  .area {
    font-size: 16px;
    padding: 0 16px;
  }
  button {
    float: right;
    width: 146px;
    margin-top: 40px;
    border-radius: 4px;
  }
`;

const Withdraw = () => {
  const { meInfo } = useMeInfo();
  const { mutateAsync: withdrawMutate } = useWithdraw();
  const { signOut: logout } = useAuth();
  const handleClick = async () => {
    const confirmResult = await modalMethod.confirm(
      <div>
        동의 및 회원탈퇴를 진행하시겠습니까? <br />
        확인 시 즉시 탈퇴 처리되며 계정 복구가 불가합니다.
      </div>,
      { okText: '탈퇴', cancelText: '취소' }
    );
    if (confirmResult) {
      await withdrawMutate(meInfo.admin.id);
      modalMethod.info(
        <div>
          회원 탈퇴를 완료했습니다. <br />
          그동안 식권대장 사장님을 이용해주셔서 감사합니다.
        </div>
      );
      logout();
    }
  };
  return (
    <WithdrawWrapper>
      <div className="withdraw-title">식권대장 사장님 회원 탈퇴</div>
      <div>
        <div className="area">회원 탈퇴하시기 전에 안내사항을 꼭 확인해주세요.</div>
        <div className="withdraw-content">
          <div>[탈퇴 안내]</div>
          <ul>
            <li>탈퇴한 후에는 식권대장 사장님에 기존에 사용하던 계정으로 로그인 하실 수 없습니다.</li>
            <li>
              회원 탈퇴를 하시면, 현재 로그인된 아이디는 즉시 탈퇴 처리되며 서비스 탈퇴 후 전자상거래법에 의해 보존해야하는
              거래기록은 5년간 보관됩니다.
            </li>
          </ul>
          <div>[정산 안내]</div>
          <ul>
            <li>정산은 기존 해당 계정과 연결된 제휴점 정산정보에 기재된 내용대로 정산됩니다.</li>
            <li>탈퇴 이후에는 정산 정보 확인과 수정이 불가합니다.</li>
            <li>탈퇴 이전에 제휴점의 정산정보를 반드시 확인해주세요.</li>
          </ul>
          <div>[주문 안내]</div>
          <ul>
            <li>탈퇴한 후에는 진행중인 주문 건에 대한 상태 확인과 변경이 불가합니다.</li>
            <li>배달식사 혹은 식품관 상품의 배송이 완료되지 않은 상태인지 확인해주세요.</li>
          </ul>
        </div>
        <div className="area">
          위의 내용을 확인하신 후 탈퇴하기를 누르시면
          <br />
          동의하신 것으로 간주하여 탈퇴가 가능합니다.
        </div>
      </div>
      <Button type="primary" onClick={handleClick}>
        동의 후 탈퇴하기
      </Button>
    </WithdrawWrapper>
  );
};

export default Withdraw;
