import { getAuthHost } from 'apis/host';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
let popup = null;

const useCertification = (
  onSuccess?: (v: string) => void,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onError?: (data: any) => void
): [v: string, f: () => void] => {
  const navigate = useNavigate();
  const host = getAuthHost();
  const url = `${host}/gateway/v1/store/certification?thirdPartyType=MOBILIANS&refferType=STORE_CERTIFICATION`;
  const [transactionId, setTransactionId] = useState('');
  const handler = async (e) => {
    if (e.data.mobileCert) {
      popup.close();
      const certificationData = JSON.parse(e.data.mobileCert);
      console.log(certificationData);
      if (certificationData.responseCode === 'SUCCESS') {
        setTransactionId(certificationData.transactionId);
        onSuccess?.(certificationData.transactionId);
      } else if (certificationData.responseCode === 'DUPLICATE_ACCOUNT') {
        const account = {
          signId: certificationData.duplicationId,
          joinDate: certificationData.regDate
        };
        navigate('/alreadyExists', {
          state: { transactionId: certificationData.transactionId, data: { account } },
          replace: true
        });
      } else {
        onError?.(certificationData);
        alert('인증 중 에러 발생');
      }
      popup = null;
    }
  };

  useEffect(() => {
    window.addEventListener('message', handler);
    return () => {
      window.removeEventListener('message', handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const open = () => {
    popup = window.open(url, '__blank', 'height=100%');
    // setTimeout(() => {
    //   pop.close();
    // }, 1000);
  };

  return [transactionId, open];
};

export default useCertification;
