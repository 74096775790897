/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import icSaleFrom from 'images/other/ic-sale@3x.png';
import icDiscontinued from 'images/other/ic-discontinued@3x.png';
import icSoldOut from 'images/other/ic-soldout@3x.png';
import { encryption, handlerKeyUp } from 'utils';
import classNames from 'classnames';

interface MenuTableRowProps {
  categoryId;
  categoryData;
  mobileSelect;
  checkedItems: Set<string>;
}

const MenuTableRow = ({ categoryId, categoryData, mobileSelect, checkedItems }: MenuTableRowProps) => {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {categoryData &&
        categoryData.map((data) => {
          let status;
          let fontColor = 'green';
          let icon;
          if (data.status === 'ACTIVE') {
            status = '판매중';
            fontColor = 'green';
            icon = icSaleFrom;
          } else if (data.status === 'INACTIVE') {
            status = '판매종료';
            fontColor = 'red';
            icon = icDiscontinued;
          } else {
            status = '일시품절';
            fontColor = 'orange';
            icon = icSoldOut;
          }

          return (
            <tr key={data.id} className={data.status === 'INACTIVE' ? 'inactive_row' : undefined}>
              <td className="bgc-white menu-check-box media-display-none">
                <div
                  className={classNames('menu-check-div-box menu-data-box', categoryId, {
                    'back-color-green': checkedItems.has(data.id)
                  })}
                >
                  <input
                    type="checkbox"
                    onChange={() => mobileSelect(`${data.id}`)}
                    className="dataBox"
                    name={categoryId}
                    id={data.id}
                    checked={checkedItems.has(data.id)}
                  />
                </div>
              </td>
              <td className="media-display-none bgc-white">
                <div
                  role="presentation"
                  className={classNames('menu-list menu-data-box', categoryId, {
                    'back-color-green': checkedItems.has(data.id)
                  })}
                  onClick={() => mobileSelect(`${data.id}`)}
                  onKeyUp={handlerKeyUp(() => mobileSelect(`${data.id}`))}
                >
                  <div className="menu-name">{data.name}</div>
                  <div className="menu-info">{data.intro}</div>
                  <div className="text-right padding-right-10px">
                    {`${encryption(data.price)}원`}
                    <p className="web-display-none" style={{ color: fontColor }}>
                      {status}
                    </p>
                  </div>
                </div>
              </td>
              <td className="bgc-white menu-status-box media-display-none" style={{ color: fontColor }}>
                <div
                  role="presentation"
                  className={classNames('menu-status-div-box menu-data-box', categoryId, {
                    'back-color-green': checkedItems.has(data.id)
                  })}
                  onClick={() => mobileSelect(`${data.id}`)}
                  onKeyUp={handlerKeyUp(() => mobileSelect(`${data.id}`))}
                >
                  {status}
                </div>
              </td>
            </tr>
          );
        })}
    </>
  );
};

export default MenuTableRow;
