import React, { useEffect, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Radio, Button, RadioChangeEvent, Row, Col } from 'antd';
import { TSchedule } from 'interfaces/robot';
import useThrottle, { useRobotSchedule } from 'hooks/useRobotDelivery';

import { ReactComponent as RefetchIcon } from 'images/ic_refetch.svg';
import DefaultModal from '../components/DefaultModal';

const ModalHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 46px 0 26px;
  font-size: 32px;
  font-weight: 700;
  .title {
    flex: 1;
    text-align: center;
  }
  .ant-btn.ant-btn-circle {
    width: 35px;
    height: 35px;
    padding: 0;
    border: none;
    background: none;
    svg {
      width: 35px;
      height: 35px;
    }
  }
`;
const ModalContentWrapper = styled.div`
  .empty-schedule {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 350px;
    font-size: 20px;
    color: #afb2b9;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    color: #797c80;
  }
`;
const CardWrapper = styled(Col)`
  label.ant-radio-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 170px;
    height: 150px;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
    color: #797c80;

    .robot-name {
      padding-bottom: 8px;
      font-size: 32px;
      font-weight: 700;
      color: #353c49;
    }
    .delivery-start-time {
      color: #46b476;
      font-weight: 700;
    }
  }
  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:first-child,
  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
    border: 5px solid #0067ff;
    background: #dbebff;
  }
`;

interface RobotDispatchModalProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: (robot: TSchedule) => void;
}

const RobotDispatchModal = ({ open, onCancel, onConfirm }: RobotDispatchModalProps) => {
  const [scheduleId, setScheduleId] = useState<string>();

  const { data: robotSchedules, refetch } = useRobotSchedule(open);
  const throttleRefetch = useThrottle(refetch, 5000);

  useEffect(() => {
    if (robotSchedules?.schedules.length) {
      const defaultValue = robotSchedules.schedules[0].scheduleId;
      setScheduleId(defaultValue);
    }
  }, [robotSchedules, open]);

  const handleConfirmClick = () => {
    const robot = robotSchedules.schedules.find((item) => item.scheduleId === scheduleId);
    onConfirm(robot);
    onCancel();
  };

  const handleSpotClick = ({ target }: RadioChangeEvent) => {
    setScheduleId(target.value);
  };

  const handleModalClose = () => {
    setScheduleId('');
    onCancel();
  };

  const formatDeliveryTime = (time: string) => {
    return moment(`2025-01-01 ${time}`).format('HH시 mm분');
  };

  return (
    <DefaultModal open={open} onCancel={handleModalClose} onOk={handleConfirmClick} okButtonProps={{ disabled: !scheduleId }}>
      <ModalHeaderWrapper>
        <div className="title">
          <span style={{ color: '#0067ff' }}>배달 로봇</span>
          <span>을 선택한 후 확인 버튼을 눌러주세요</span>
        </div>
        <Button type="primary" shape="circle" icon={<RefetchIcon />} onClick={() => throttleRefetch()} />
      </ModalHeaderWrapper>
      {robotSchedules?.schedules.length ? (
        <ModalContentWrapper>
          <Radio.Group value={scheduleId} onChange={handleSpotClick}>
            <Row gutter={[20, 20]}>
              {robotSchedules?.schedules.map((schedule) => (
                <CardWrapper key={schedule.scheduleId}>
                  <Radio.Button value={schedule.scheduleId}>
                    <div className="robot-name">{schedule.robotName}</div>
                    <div className="delivery-start-time">출발 : {formatDeliveryTime(schedule.deliveryStartTime)}</div>
                    <div>도착 : {formatDeliveryTime(schedule.deliveryEndTime)}</div>
                    <div>회차 : {formatDeliveryTime(schedule.deliveryReturnTime)}</div>
                  </Radio.Button>
                </CardWrapper>
              ))}
            </Row>
          </Radio.Group>
          <div style={{ color: '#46B476', fontSize: '20px', textAlign: 'center', paddingTop: '55px' }}>
            *출발 시간이 현재 시간으로부터 1시간 이내인 배달 로봇만 선택 가능합니다.
          </div>
        </ModalContentWrapper>
      ) : (
        <ModalContentWrapper>
          <div className="empty-schedule">1시간 이내에 출발 가능한 로봇이 없습니다.</div>
        </ModalContentWrapper>
      )}
    </DefaultModal>
  );
};

export default RobotDispatchModal;
