import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AuthProvider } from 'contexts/AuthContext';
import GlobalStyle from 'styles/globalStyle';
import theme from 'styles/theme';
import { ThemeProvider } from 'styled-components';
import { channelTalkService } from 'utils';
import ReactGa from 'react-ga4';
import { AxiosError } from 'axios';

import 'pretendard/dist/web/variable/pretendardvariable.css';
import 'pretendard/dist/web/static/pretendard.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './index.css';

import App from './App';
import reportWebVitals from './reportWebVitals';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retryOnMount: false,
      retry: 3
    },
    mutations: {
      retry: (failureCount, error) => {
        if (failureCount > 2) {
          return false;
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const errorStatus = ((error as AxiosError)?.response?.data as any)?.status;
        if (failureCount >= 0 && errorStatus) {
          return (error as AxiosError)?.response?.status === 401;
        }
        return true;
      }
    }
  }
});
channelTalkService.loadScript();

const gaId = process.env.NODE_ENV === 'development' ? 'G-F8YPFY6NB2' : 'G-GD4B0DESK1';
ReactGa.initialize([{ trackingId: gaId }]);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <App />
          </AuthProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// export * from './webappBridgeExport';
