/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import classNames from 'classnames';
import { DateRangeNavigation } from 'components';
import { useExcelDownload, useLogistics, useSaveShipping, useShipping, useUploadTrackingNumberExcel } from 'hooks';
import moment, { Moment } from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { deepClone, numberWithCommas } from 'utils/stringUtils';
import ExcelUploadModal from './ExcelUploadModal';
import ShippingOrderManagementModal from './ShippingOrderManagementModal';

interface TState {
  startDate: Moment;
  endDate: Moment;
  filter: {
    isTracking: string;
    orderStatus: string;
    inquiryCondition: string;
    inquiry: string;
  };
  contents: any[];
  originContents: any[];
  logistics: any[];
  logisticsMap: Record<string, string>;
  selectedCouponId: null | string;
  pagination: {
    page: number;
    pageSize: number;
    pageCount: number;
  };
  saveAsyncBtn: boolean;
}

const ShippingOrderManagement = () => {
  const { changeExcelParams } = useExcelDownload();

  const [params, setParams] = useState<any>({
    startDate: moment().add(-7, 'days'),
    endDate: moment(),
    isTracking: 'ALL',
    orderStatus: 'ALL',
    inquiryCondition: 'USER',
    inquiry: ''
  });

  const { data: shipping, refetch } = useShipping(params);
  const { data: logistics = [] } = useLogistics();
  const { mutateAsync: saveShipping } = useSaveShipping();

  const [state, setState] = useState<TState>({
    startDate: moment().add(-7, 'days'),
    endDate: moment(new Date()),
    filter: {
      isTracking: 'ALL',
      orderStatus: 'ALL',
      inquiryCondition: 'USER',
      inquiry: ''
    },
    contents: [],
    originContents: [],
    logistics: [],
    logisticsMap: {},
    selectedCouponId: null,
    pagination: {
      page: 1,
      pageSize: 20,
      pageCount: 1
    },
    saveAsyncBtn: false
  });

  const [, setExcelModalOpen] = useState<boolean>(false);
  const { mutateAsync: uploadExcelFile, data: uploadExcelResult } = useUploadTrackingNumberExcel();

  useEffect(() => {
    changeExcelParams({
      date1: state.startDate,
      date2: state.endDate,
      target: 'shipping',
      data: {
        filter: { ...state.filter },
        logistics
      },
      filterData: { ...state.filter }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, logistics]);

  useEffect(() => {
    if (!shipping) {
      return;
    }
    const { contents = [] } = shipping;

    const editableData = contents.map((e) => {
      const {
        coupon: { couponId, payRoomIdx, comName, userName, paymentPrice, paymentDate },
        shipping: { trackingNumber, logisticsIdx } = { trackingNumber: undefined, logisticsIdx: undefined },
        status: { statusCode, statusName }
      } = e;

      return {
        isDirty: false,
        editable: false,
        couponId,
        payRoomIdx,
        comName,
        userName,
        paymentPrice,
        paymentDate,
        trackingNumber: trackingNumber || '',
        logisticsIdx: logisticsIdx || '',
        statusCode,
        statusName
      };
    });

    setState((prevState: TState) => {
      const {
        pagination: { pageSize }
      } = prevState;
      return {
        ...prevState,
        contents: editableData,
        originContents: deepClone(editableData) as [],
        pagination: { ...prevState.pagination, pageCount: editableData.length / pageSize }
      };
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipping]);

  const logisticsMap = useMemo(() => {
    if (!logistics) {
      return {};
    }
    return logistics.map(({ idx, name }) => ({ [idx]: name })).reduce((a, c) => ({ ...a, ...c }), {});
  }, [logistics]);

  const changePage = (page, e) => {
    if (e) {
      e.preventDefault();
    }
    const { pagination } = params;
    setParams({ ...params, pagination: { ...pagination, page } });
    // () => this.getShipping
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const prevPage = (e) => {
    e.preventDefault();
    const {
      pagination: { page }
    } = state;
    let previousPageNumber = page - 1;
    if (previousPageNumber < 1) {
      previousPageNumber = 1;
    }
    changePage(previousPageNumber, null);
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const nextPage = (e) => {
    e.preventDefault();
    const {
      pagination: { page, pageCount }
    } = state;
    let nextPageNumber = page + 1;
    if (nextPageNumber > pageCount) {
      nextPageNumber = pageCount;
    }
    changePage(nextPageNumber, null);
  };

  const getShipping = async (e) => {
    if (e) {
      e.preventDefault();
      const { startDate, endDate, filter } = state;
      setParams({ startDate, endDate, ...filter });
      // refetch();
    } else {
      refetch();
    }
  };

  useEffect(() => {
    refetch();
  }, [params, refetch]);

  /**
   * 저장함수
   */
  const saveTrackingAndLogistics = async () => {
    const { contents } = state;
    let inValid = false;
    const dirtyData = contents
      .filter((e) => e.isDirty)
      .map((e) => {
        const { couponId, trackingNumber, logisticsIdx } = e;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line no-bitwise
        if (!inValid && !trackingNumber ^ !logisticsIdx) {
          state.logistics
            .filter((l) => !l.isUseTrackingNumber)
            .forEach((l) => {
              if (l.idx === Number(logisticsIdx)) {
                inValid = false;
              } else {
                inValid = true;
              }
            });
        }

        return {
          couponId,
          trackingNumber,
          logisticsIdx,
          inValid
        };
      });

    const mapping = dirtyData
      .map(({ couponId, inValid: iv }) => ({ [couponId]: iv }))
      .reduce((acc, current) => ({ ...acc, ...current }));
    // validation;
    const { length } = dirtyData;
    if (!length) {
      return;
    }
    if (inValid) {
      alert('송장번호와 택배사가 모두 입력되어야 합니다. 다시 확인 부탁드립니다.');
      contents
        .filter((e) => e.isDirty)
        .map((e) => {
          const { couponId } = e;
          e.inValid = mapping[couponId];
          return e;
        });
      setState({ ...state, contents });
      return;
    }

    // eslint-disable-next-line no-restricted-globals
    if (confirm(`총 ${length} 건의 주문 정보가 수정되었습니다. 저장하시겠습니까?`)) {
      try {
        setState({ ...state, saveAsyncBtn: true });
        await saveShipping({ shippingProducts: dirtyData });
        alert('저장 되었습니다.');
        await getShipping(null);
      } finally {
        setState({ ...state, saveAsyncBtn: false });
      }
    }
  };

  const cancel = () => {
    const { originContents } = state;
    setState({ ...state, contents: deepClone(originContents) as [] });
  };

  const dateHandle = (range) => {
    setParams({ ...params, startDate: range.startDate, endDate: range.endDate });
    setState({ ...state, startDate: range.startDate, endDate: range.endDate });
    changeExcelParams({ data: params, date1: params.startDate, date2: params.endDate });
  };

  const handleFilterChange = ({ target }) => {
    const key = target.name;
    const { value } = target;
    const { filter: prevFilter = {} } = state;
    const filter: any = { ...prevFilter, [key]: value };
    setState({ ...state, filter });
    // setdefault();
  };

  const updateData = (index, row, callback) => {
    const { contents: draft, originContents } = state;
    const currentRow = draft[index] as Record<string, unknown>;
    const originRow = originContents[index];
    const changedRow = { ...currentRow, ...row };

    const { trackingNumber: changedValue, logisticsIdx: changedCode } = currentRow;
    const { trackingNumber: originValue, logisticsIdx: originCode } = originRow;

    let dirty: Record<string, unknown> = { isDirty: true };
    if (changedValue === originValue && changedCode === originCode) {
      dirty = { isDirty: false };
    }
    if (callback) {
      dirty = {};
    }

    draft[index] = { ...changedRow, ...dirty, inValid: false };
    setState({ ...state, contents: draft });
    callback?.();
  };

  const handleUpdateField = (index, { target }) => {
    const { pattern, name } = target;
    // const { logistics, logisticsMap } = state;
    let { value } = target;
    if (pattern) {
      value = value.replace(new RegExp(pattern, 'gi'), '');
    }

    const { contents } = state;
    const row = contents[index];

    if (name === 'logisticsIdx') {
      logistics
        .filter((e) => !e.isUseTrackingNumber)
        .forEach((e) => {
          if (e.idx === value) {
            row.trackingNumber = '-';
            // this.refs[`edittrackingNumber${index}`].disabled = true
          } else {
            // this.refs[`edittrackingNumber${index}`].disabled = false
          }
        });
    }

    if (!row[name] && !value) {
      return;
    }

    row[name] = value;
    updateData(index, row, null);
  };

  const editableCell = (index, row, name) => {
    // const { logistics, logisticsMap } = state;
    const { isDirty, editable, logisticsIdx, trackingNumber, statusCode } = row;
    let isDisable = false;
    const handleFocus = (e) => {
      e.stopPropagation();
      const tag = e.target.tagName;
      if (tag === 'TD') {
        if (statusCode === 'CANCEL') {
          e.target.blur();
          alert('해당 주문은 취소된 상태입니다.');
          return;
        }
        row.editable = true;
        if (statusCode === 'COMPLETE') {
          row.editable = false;
          return;
        }

        if (!isDirty && !logisticsIdx) {
          row.logisticsIdx = 1;
          row.isDirty = true;
        }
        updateData(index, row, () => {
          // const editInput = this.refs[`edit${name}${index}`];
          // editInput.focus();
        });
      }
    };

    const handleInputBlur = (e) => {
      e.stopPropagation();
      if (!isDirty) {
        row.editable = false;
        updateData(index, row, () => {});
      }
    };

    let text = '등록안됨';
    if (name === 'trackingNumber') {
      text = trackingNumber || '송장번호';
    } else if (name === 'logisticsIdx' && logisticsIdx) {
      text = logisticsMap[logisticsIdx] || '등록안됨';
    }

    logistics
      .filter((e) => !e.isUseTrackingNumber)
      .forEach((e) => {
        if (e.idx === row.logisticsIdx) {
          isDisable = true;
        } else {
          isDisable = false;
        }
      });

    return (
      <td className="table-td-cursor-pointer" onFocus={handleFocus} tabIndex={0} style={{ width: '200px' }}>
        {editable ? (
          name === 'trackingNumber' ? (
            <input
              // ref={`edit${name}${index}`}
              type="text"
              className="form-control"
              name={name}
              onChange={(e) => {
                handleUpdateField(index, e);
              }}
              maxLength={100}
              onBlur={handleInputBlur}
              pattern="\s"
              value={trackingNumber}
              disabled={isDisable}
            />
          ) : (
            <select
              // ref={`edit${name}${index}`}
              className="form-control"
              value={logisticsIdx}
              name={name}
              onChange={(e) => handleUpdateField(index, e)}
              onBlur={handleInputBlur}
            >
              <option value="">등록안됨</option>
              {logistics.map((e) => {
                return <option value={e.idx}>{e.name}</option>;
              })}
            </select>
          )
        ) : (
          text
        )}
      </td>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const paginationEl = () => {
    const {
      pagination: { page, pageCount }
    } = state;
    const pageList = [];
    for (let i = 1; i <= pageCount; i += 1) {
      pageList.push(
        <li className={`page-item ${page === i ? 'active' : ''}`}>
          <a className="page-link" href="#" onClick={(e) => changePage(i, e)}>
            {i}
          </a>
        </li>
      );
    }
    return pageList;
  };
  const openDetail = (couponId) => {
    setState({ ...state, selectedCouponId: couponId });
  };
  const closeDetail = () => {
    // TODO delete bootstrap
    // eslint-disable-next-line
    // @ts-ignore
    $('#shippingModal').modal('hide');
    getShipping(null);
  };
  const openModal = () => {
    // TODO delete bootstrap
    // eslint-disable-next-line
    // @ts-ignore
    setExcelModalOpen(true);
    $('#excelDateModal').modal('show');
  };

  const { filter, contents, selectedCouponId, saveAsyncBtn } = state;
  let isAllDirty = false;
  const rows = contents.map((row, index) => {
    const { inValid, isDirty, couponId, comName, payRoomIdx, userName, paymentPrice, paymentDate, statusCode, statusName } = row;
    if (!isAllDirty && isDirty) {
      isAllDirty = true;
    }

    return (
      <tr className={classNames('table-row', { 'table-error': inValid }, { 'table-info': !inValid && isDirty })} key={couponId}>
        <td>{payRoomIdx}</td>
        <td>{comName}</td>
        <td>{userName}</td>
        <td>{numberWithCommas(paymentPrice)}</td>
        <td>{moment(paymentDate).format('YYYY-MM-DD HH:mm:ss')}</td>
        {editableCell(index, row, 'trackingNumber')}
        {editableCell(index, row, 'logisticsIdx')}
        <td
          data-toggle="modal"
          data-target="#shippingModal"
          className="table-td-cursor-pointer"
          onClick={() => {
            openDetail(couponId);
          }}
        >
          <a
            className={classNames(
              { 'status-cancel': statusCode === 'CANCEL' },
              { 'status-part-cancel': statusCode === 'USED_PART_CANCEL' },
              { 'status-used': statusCode === 'USED' || statusCode === 'COMPLETE' || statusCode === 'ING' }
            )}
          >
            {statusName}
          </a>
        </td>
      </tr>
    );
  });

  return (
    <div className="salesstats-content" id="shipping">
      <ExcelUploadModal
        title="송장번호 일괄 등록"
        close={() => getShipping(null)}
        upload={uploadExcelFile}
        uploadResult={uploadExcelResult}
      />
      <DateRangeNavigation startDate={state.startDate} endDate={state.endDate} onChange={dateHandle} />
      <div className="filter">
        <form onSubmit={getShipping} className="filter-form">
          <div className="form-group">
            <label htmlFor="isTracking">송장 등록 여부</label>
            <select
              className="form-control"
              name="isTracking"
              id="isTracking"
              value={filter.isTracking}
              onChange={handleFilterChange}
            >
              <option value="ALL">전체</option>
              <option value="NONE">등록안됨</option>
              <option value="REG">등록</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="status">상태</label>
            <select
              className="form-control"
              name="orderStatus"
              id="orderStatus"
              value={filter.orderStatus}
              onChange={handleFilterChange}
            >
              <option value="ALL">전체</option>
              <option value="SUCCEED">주문 완료</option>
              <option value="ING">배송중</option>
              <option value="COMPLETE">배송 완료</option>
              <option value="CANCELED">주문 취소</option>
            </select>
          </div>

          <div className="form-group empty">
            <label htmlFor="inquiry">&nbsp;</label>
            <ul className="guide">
              <li className="succeed">주문완료 : 변동사항이 없는 정상 주문</li>
              <li className="cenceled">주문완료 : 부분취소로 변동이 있는 주문</li>
            </ul>
          </div>

          <div className="form-group">
            <label htmlFor="inquiryCondition">검색대상</label>
            <select
              className="form-control"
              name="inquiryCondition"
              value={filter.inquiryCondition}
              onChange={handleFilterChange}
            >
              <option value="USER">결제자</option>
              <option value="PAYROOM_IDX">결제번호</option>
              <option value="TRACKING_NUMBER">송장번호</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="inquiry">&nbsp;</label>
            <input
              type="text"
              className="form-control"
              maxLength={50}
              id="inquiry"
              name="inquiry"
              placeholder="검색어를 입력해주세요"
              value={filter.inquiry}
              onChange={handleFilterChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="inquiry">&nbsp;</label>
            <button className="form-control shipping-search-btn" type="submit">
              검색
            </button>
          </div>
        </form>
      </div>

      <div className="content-area">
        <div className="btn-area">
          <label className="notice-label">
            리스트에서 송장번호와 택배사 클릭 시, 수정 가능합니다. 데이터 업데이트 후 반드시 저장해야 수정된 내용이 반영됩니다.
            {!isAllDirty ? (
              <span>
                송장번호와 택배사를 입력하시면, 배송중 상태로변경됩니다.
                <br />
                주문완료 상태의 주문건을 송장번호 일괄등록에서 한 번에 등록 할 수 있습니다. 우측상단엑셀파일 다운로드 파일을
                이용하여 주문완료 상태의 주문건을 미리 받아 놓으시면 편리합니다.
              </span>
            ) : null}
          </label>

          {isAllDirty ? (
            <div className="btn-group pull-right">
              <button type="button" className="shipping-cancel-btn shipping-search-btn" disabled={saveAsyncBtn} onClick={cancel}>
                취소
              </button>
              <button type="button" className="shipping-save-btn" disabled={saveAsyncBtn} onClick={saveTrackingAndLogistics}>
                저장
              </button>
            </div>
          ) : (
            <div className="btn-group pull-right">
              <button type="button" className="shipping-upload-btn btn btn-success" id="excelUploadBtn" onClick={openModal}>
                송장번호 일괄등록
              </button>
            </div>
          )}
        </div>
        <table className="table table-bordered table-hover realtime-table">
          <thead>
            <tr className="table-row">
              <th scope="col">결제번호</th>
              <th scope="col">회사명</th>
              <th scope="col">결제자</th>
              <th scope="col">결제 금액</th>
              <th scope="col">결제일시</th>
              <th scope="col">송장번호</th>
              <th scope="col">택배사</th>
              <th scope="col">상태</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
      <ShippingOrderManagementModal
        couponId={selectedCouponId}
        logistics={logistics}
        logisticsMap={logisticsMap}
        close={closeDetail}
      />
    </div>
  );
};

export default ShippingOrderManagement;
