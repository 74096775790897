import { ChangePasswordParams } from 'interfaces/auth';
import { getClient, getClients, getLocalStorage, getToken, setLocalStorage } from 'utils/storages';
import { authClient } from './apiClients';

export interface OAuth {
  access_token: string;
  refresh_token: string;
  token_type: string;
  account?: {
    duplicated: boolean;
    guid: string;
    login_time?: number | string;
    password: string;
    user_lack: boolean;
    user_restore: boolean;
  };
  expire_time: number;
  ver: number;
}

export interface LoginParams {
  username?: string;
  password?: string;
  grant_type: string;
  client_id?: string;
  client_secret?: string;
}

const REMEMBER_ME = 'save';

export const getRememberMe = () => getLocalStorage(REMEMBER_ME);
export const setRememberMe = (value: boolean) => setLocalStorage(REMEMBER_ME, value);

const clearStorage = () => {
  const auto = getRememberMe();
  localStorage.clear();
  sessionStorage.clear();
  if (typeof auto !== 'undefined' || auto !== null) {
    setRememberMe(auto);
  }
};

export const login = async (params: LoginParams): Promise<OAuth> => {
  const loginParams = {
    ...params,
    client_id: getClient(),
    client_secret: getClients()
  };
  const { data } = await authClient.post('/vendys/v1/token', loginParams);
  return data;
};

export const logout = async (token: string): Promise<void> => {
  await authClient.delete(`/oauth2/tokens/${token}`);
  clearStorage();
};

export const changeVendysPassword = async (params: ChangePasswordParams): Promise<void> => {
  const token = getToken();
  if (!token) {
    return;
  }
  const Authorization = `Bearer ${token}`;
  await authClient.put('/vendys/v1/password', params, { headers: { Authorization } });
};

export const skipChangeVendysPassword = async (): Promise<void> => {
  const token = getToken();
  if (!token) {
    return;
  }
  const Authorization = `Bearer ${token}`;
  await authClient.put('/vendys/v1/password/ignore', null, { headers: { Authorization } });
};
