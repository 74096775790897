import React, { useEffect } from 'react';

const ExcelSuccessModal = ({ open, close, successCount, alreadySuccessCount, returnFunc }) => {
  useEffect(() => {
    if (open === true) {
      // TODO delete bootstrap
      // eslint-disable-next-line
      // @ts-ignore
      $('#excelSuccessModal').modal('show');
    } else if (open === false) {
      // TODO delete bootstrap
      // eslint-disable-next-line
      // @ts-ignore
      $('#excelSuccessModal').modal('hide');
    }
  }, [open]);
  useEffect(() => {
    // TODO delete bootstrap
    // eslint-disable-next-line
    // @ts-ignore
    $('#excelSuccessModal').on('hidden.bs.modal', () => {
      close();
    });
  }, [close]);
  const successMessage = successCount > 0 ? '송장번호 일괄 등록이 완료되었습니다.' : '';
  const alreadySuccessMessage =
    alreadySuccessCount > 0 ? '이미 송장번호가 있는 주문건은 일괄등록 불가합니다. 수동으로 변경해주세요.' : '';

  return (
    <div id="excelSuccessModal" className="modal fade" role="dialog">
      <div className="modal-dialog margin-top-50" id="modal-box">
        <div className="modal-content modal-size">
          <div className="modal-header">
            <h4 className="modal-title">일괄 등록 결과</h4>
          </div>
          <div className="modal-body">
            <div className="padding-left-20px">
              <p>
                성공 {successCount} 건 / 기등록 {alreadySuccessCount} 건
              </p>
              <p>{successMessage}</p>
              <p>{alreadySuccessMessage}</p>
            </div>
          </div>
          <div className="modal-footer margin-top-30">
            <button type="button" className="btn btn-sikdae" data-dismiss="modal" onClick={returnFunc}>
              확인
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExcelSuccessModal;
