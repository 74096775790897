import { Checkbox, CheckboxChangeEvent, modalMethod, PersonInChargeForm } from 'components';
import React, { useCallback, useState } from 'react';
import { useMeInfo, usePersonInChargeOfSettlement, useSavePersonInChargeOfSettlement, useSettlementStoreAccount } from 'hooks';
import {
  ReadOnlySwitchWrapper,
  SearchCheckbox,
  SettlementDescription,
  SettlementDescriptionContent,
  SettlementDescriptionTitle
} from '../styles';

const PersonInChargeOfSettlement = () => {
  const [checked, setChecked] = useState(false);
  const [readOnly, setReadOnly] = useState(true);
  const { selectedStore, meInfo } = useMeInfo();

  const initial = useCallback(async () => {
    await modalMethod.confirm(
      <div>
        <div>정산담당자 정보가 입력되지 않았습니다.</div>
        <div>정보를 입력하시겠습니까?</div>
      </div>,
      { onOk: () => setReadOnly(false), okText: '입력하기', cancelText: '닫기' }
    );
  }, []);

  const { data } = usePersonInChargeOfSettlement(selectedStore?.sid || '', async (result) => {
    if (!result?.settleManagerName) await initial();
  });
  const { data: account } = useSettlementStoreAccount(meInfo.admin.said, !checked, () => {
    setChecked(false);
  });
  const { isLoading: isUpdating, mutateAsync: updateCharge } = useSavePersonInChargeOfSettlement();

  const handleCheck = ({ target: { checked: v } }: CheckboxChangeEvent) => {
    setChecked(v);
  };

  const handleFieldsChange = () => {
    if (checked) {
      setChecked(false);
    }
  };

  const handleSwitchForm = (e?: React.MouseEvent<HTMLButtonElement>) => {
    if (e) {
      e.preventDefault();
    }
    setReadOnly(!readOnly);
  };

  const handleUpdate = async (values) => {
    if (selectedStore?.sid) {
      const { settleManagerName, settleManagerEmail, settleManagerPhone } = values;
      const params = {
        settleManagerName,
        settleManagerEmail,
        settleManagerPhone
      };
      await updateCharge({ storeId: selectedStore.sid, chargeData: { ...params } });
      handleSwitchForm();
    }
  };

  return (
    <>
      <SettlementDescription className="box-description">
        <SettlementDescriptionTitle>정확한 정산담당자 정보를 입력해주세요</SettlementDescriptionTitle>
        <SettlementDescriptionContent>
          아래의 정산담당자에게 세금계산서 및 정산정보가 발송됩니다.{' '}
          <span className="bold">잘못된 정보일 경우, 타인에게 정산정보가 발송되어 입금처리가 진행되지 않을 수 있습니다.</span>
        </SettlementDescriptionContent>
      </SettlementDescription>
      <ReadOnlySwitchWrapper>
        {!readOnly ? (
          <SearchCheckbox>
            <Checkbox $border $backgroundColor="transparent" checked={checked} onChange={handleCheck}>
              사업자 정보 가져오기
            </Checkbox>
          </SearchCheckbox>
        ) : null}
        <PersonInChargeForm
          initValue={data}
          accountValue={account}
          onSubmit={handleUpdate}
          isLoading={isUpdating}
          readOnly={readOnly}
          onSwitchForm={handleSwitchForm}
          onChangeForm={handleFieldsChange}
        />
      </ReadOnlySwitchWrapper>
    </>
  );
};

export default PersonInChargeOfSettlement;
