import styled from 'styled-components';
import iconExclamationMark24 from 'images/exclamationMarkGreen24.svg';

interface ResultContentWrapperProps {
  top?: string;
}

export const ResultContentWrapper = styled.div<ResultContentWrapperProps>`
  margin-top: ${(props) => props?.top ?? '80px'};
  font-size: 18px;
  line-height: 26px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.color.gray02};
  gap: 80px;
`;

export const ChannelTalkWrapper = styled.div`
  text-align: center;
  margin-top: 29px;
`;

export const SignIdWrapper = styled.div`
  width: 343px;
  height: 114px;
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.color.gray06};
  gap: 4px;
  border-radius: 4px;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.color.gray03};
  & .signId {
    font-size: 28px;
    line-height: 40px;
    font-weight: 700;
    color: ${({ theme }) => theme.color.black};
  }
`;

export const DescriptionContentWrapper = styled.div`
  margin-top: 80px;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.color.gray02};
  gap: 80px;
`;

export const WarringBorrowedAccountWrapper = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: ${({ theme }) => theme.color.gray02};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 13px;
  background-color: rgba(242, 244, 246, 0.5);
  border-radius: 8px;
  padding: 16px 16px 44px 16px;
  & .title {
    color: ${({ theme }) => theme.color.gray01};
    font-weight: 700;
    display: flex;
    align-items: center;
    &::before {
      margin-right: 8px;
      width: 24px;
      height: 24px;
      content: url(${iconExclamationMark24});
    }
  }
`;
