import { AccountParams, AccountInfo, AccountUser, ITerm } from 'interfaces/account';
import { TSupplyType } from 'interfaces/admission';
import { getToken } from 'utils';
import { authClient, authClientWithoutRequestInterceptor } from './apiClients';

export const getStoreTransactionId = async (transactionId: string): Promise<AccountInfo> => {
  try {
    const { data } = await authClientWithoutRequestInterceptor.get(`/vendys/v1/store/${transactionId}`);
    return data;
  } catch (error) {
    const { response } = error as { response: { data: unknown } };
    if (response) {
      throw response.data;
    }
    throw error;
  }
};

// export const getAccountCi = async (userId: string): Promise<AccountInfo> => {
//   try {
//     const { data } = await authClientWithoutRequestInterceptor.get(`/account/v1/store/${userId}`);
//     return data;
//   } catch (error) {
//     const { response } = error as { response: { data: unknown } };
//     if (response) {
//       throw response.data;
//     }
//     throw error;
//   }
// };
export const getStoreAccount = async (transactionId: string, signId?: string): Promise<AccountInfo> => {
  try {
    const { data } = await authClientWithoutRequestInterceptor.get(`/account/v1/store/${transactionId}`, { params: { signId } });
    return data;
  } catch (error) {
    const { response } = error as { response: { data: unknown } };
    if (response) {
      throw response.data;
    }
    throw error;
  }
};
export const putPasswordChange = async (params: Partial<AccountParams>): Promise<void> => {
  const { data } = await authClient.put('/account/v1/store/password/change', params);
  return data;
};

export const signUp = async (signData: AccountParams): Promise<AccountUser> => {
  const { data } = await authClient.post('/account/v1/store/signup', signData);
  return data;
};

export const withdraw = async (signId: string) => {
  const token = getToken();
  if (!token) {
    return;
  }
  const Authorization = `Bearer ${token}`;
  await authClient.post('/account/v1/store/withdraw', { id: signId }, { headers: { Authorization } });
};

export const getTerm = async (types: string): Promise<{ termList: ITerm[] }> => {
  const { data } = await authClient.get(`/common/term/v1`, { params: { types } });
  return data;
};

export const getAdmissionServiceTerm = async (
  admissionIdx: number,
  supplyType: TSupplyType
): Promise<Pick<ITerm, 'type' | 'content'>> => {
  const { data } = await authClient.get(`/common/term/v1/store/service/${admissionIdx}`, { params: { supplyType } });
  return data;
};

export const checkDuplicateSignId = async (id: string): Promise<boolean> => {
  try {
    await authClientWithoutRequestInterceptor.get('/account/v1/store/valid/signid', { params: { id } });
    return true;
  } catch (e) {
    return false;
  }
};
