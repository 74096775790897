import {
  getShipping,
  getShippingLogistics,
  getShippingProduct,
  saveShipping,
  saveTrackingAndLogistics,
  uploadTrackingNumberExcel,
  downloadShippingExcelDown
} from 'apis';
import { Moment } from 'moment';
import { useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';

interface TSaveParams {
  couponId: string;
  saveData: unknown;
}

interface TParams {
  startDate: Moment;
  endDate: Moment;
  isTracking: string;
  orderStatus: string;
  inquiryCondition: string;
  inquiry: string;
}

export const useShippingProduct = (couponId: string) => {
  return useQuery(['getShippingProduct', couponId], () => getShippingProduct(couponId), {
    enabled: !!couponId
  });
};
export const useShipping = (params: TParams) => {
  const filter = useMemo(() => {
    const { startDate, endDate } = params;
    return { ...params, startDate: startDate.format('YYYY-MM-DD'), endDate: endDate.format('YYYY-MM-DD') };
  }, [params]);

  return useQuery(['getShipping', filter], () => getShipping(filter), {
    // enabled: !!params?.sid,
    enabled: false
  });
};

export const useLogistics = () => useQuery(['getShippingLogistics'], () => getShippingLogistics());

export const useSaveTrackingAndLogistics = () =>
  useMutation(['saveTrackingAndLogistics'], (params: TSaveParams) => {
    const { couponId, saveData } = params;
    return saveTrackingAndLogistics(couponId, saveData);
  });

export const useSaveShipping = () => useMutation(['saveShipping'], (saveData: unknown) => saveShipping(saveData));

export const useUploadTrackingNumberExcel = () =>
  useMutation(['uploadTrackingNumberExcel'], (excelFileFormData: FormData) => uploadTrackingNumberExcel(excelFileFormData));

export const useDownloadShippingExcel = () =>
  useMutation(['uploadShippingExcelDown'], (params: unknown) => downloadShippingExcelDown(params));
