import { Moment } from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

type TExcelParams = {
  companySelectId?: string;
  target?: string;
  data?: Record<string, unknown>;
  filterData?: Record<string, unknown>;
  date1?: Moment;
  date2?: Moment;
};

interface ExcelDownloadContextInterface {
  excelParams: TExcelParams | undefined;
  changeExcelParams: (v: Record<string, unknown>) => void;
}
export const ExcelDownloadContext = React.createContext<ExcelDownloadContextInterface>({
  excelParams: undefined,
  changeExcelParams: () => {}
});

const ExcelDownloadProvider = ({ children }) => {
  const location = useLocation();
  const [excelParams, setExcelParams] = useState<TExcelParams>();
  useEffect(() => {
    setExcelParams((prev) => {
      if (prev && Object.entries(prev).length) {
        return undefined;
      }
      return prev;
    });
  }, [location.pathname]);

  const changeExcelParams = useCallback((params) => {
    setExcelParams(params);
  }, []);

  const value = useMemo(
    () => ({
      excelParams,
      changeExcelParams
    }),
    [excelParams, changeExcelParams]
  );

  return <ExcelDownloadContext.Provider value={value}>{children}</ExcelDownloadContext.Provider>;
};

export default ExcelDownloadProvider;
