import React, { useEffect } from 'react';
import iconCloseBlack from 'images/other/ic-close-black.svg';

const ReviewImageModal = ({ imageUrl, open, close }) => {
  useEffect(() => {
    if (open === true) {
      // TODO delete bootstrap
      // eslint-disable-next-line
      // @ts-ignore
      $('#review-image-viewer').modal('show');
    } else if (open === false) {
      // TODO delete bootstrap
      // eslint-disable-next-line
      // @ts-ignore
      $('#review-image-viewer').modal('hide');
    }
  }, [open]);
  useEffect(() => {
    // TODO delete bootstrap
    // eslint-disable-next-line
    // @ts-ignore
    $('#review-image-viewer').on('hidden.bs.modal', () => {
      close();
    });
  }, [close]);

  return (
    <div id="review-image-viewer" className="modal fade" role="dialog">
      <div className="full-width modal-dialog-lg modal-fullscreen-md-down" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={close}>
              <img aria-hidden="true" src={iconCloseBlack} alt="icon-close-btn" />
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid">
              <div className="image-viewer-modal-wrapper">
                <img src={imageUrl} loading="lazy" alt="review" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewImageModal;
