import styled from 'styled-components';
import exclamationMarkGreen24 from 'images/exclamationMarkGreen24.svg';

// eslint-disable-next-line import/prefer-default-export
export const SettlementContainer = styled.div`
  .settlement-detail-content {
    background-color: #fff;
  }
  .settlement-info-content {
    margin: 20px 0;
  }
  .settlement-submit {
    font-size: 16px;
  }
`;

export const SettlementDescription = styled.div`
  text-align: center;
  margin-bottom: 24px;
  .red-color {
    color: ${({ theme }) => theme.color.red03};
  }
  .bold {
    font-weight: bold;
  }
  &.box-description {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border-radius: 8px;
    background: rgba(242, 244, 246, 0.5);
  }
`;

export const SettlementDescriptionUl = styled.ul`
  margin: 0;
  margin-block: 0;
  padding-inline-start: 0;
  list-style-type: none;
`;

export const SettlementDescriptionTitle = styled.div`
  font-size: 16px;
  height: 28px;
  line-height: 28px;
  font-weight: bold;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ theme }) => theme.color.gray01};
  :before {
    content: url(${exclamationMarkGreen24});
    height: 24px;
    width: 24px;
  }
`;
export const SettlementDescriptionContent = styled.div`
  text-align: left;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.color.gray02};
`;

export const SettlementUl = styled.ul`
  width: 340px;
  margin: auto;
`;

export const SettlementTextStyleDiv = styled.div`
  color: #ff0000;
`;

export const SettlementTextStyleSpan = styled.span`
  color: #ff0000;
`;

export const SearchCheckbox = styled.div`
  margin-bottom: 24px;
`;

export const ReadOnlySwitchWrapper = styled.div`
  input[readonly] {
    cursor: default;
    border: none;
    :focus {
      border: none;
      box-shadow: none;
    }
  }
`;
