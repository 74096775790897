import { modalMethod, Tabs } from 'components';
import { useAdmissionHistory, useMeInfo, useUpdateWorker, useWorkers } from 'hooks';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PrimaryColorSpan } from 'styles';
import AdmissionHistory from './components/AdmissionHistory';
import EmployeeApplyHistory from './components/EmployeeApplyHistory';
import ImproveModal from './components/ImproveModal';
import PreparationModal from './components/PreparationModal';
import RejectModal from './components/RejectModal';
import RequestResultModal from './components/RequestResultModal';
import { RequestHistoryContentWrapper } from './styles';

const Grade = {
  MANAGER: '매니저',
  STAFF: '직원'
};

const RequestHistory = () => {
  const location = useLocation();
  const { meInfo } = useMeInfo();
  const { mutateAsync: updateWorker } = useUpdateWorker();
  const [active, setActive] = useState(0);
  const [reason, setReason] = useState('');
  const [admissionIdx, setAdmissionIdx] = useState();
  const [modal, setModal] = useState({ improve: false, reject: false, preparation: false, request: false });

  const { data: admissionHistory } = useAdmissionHistory(meInfo?.admin.said || '');
  const { data: employeeApplyHistory, refetch } = useWorkers();
  const tabItems = useMemo(() => {
    return [
      { label: '입점 신청 내역', key: 0 },
      { label: '직원 요청 받은 내역', key: 1 }
    ];
  }, []);

  const handleChange = (key) => {
    setActive(key);
  };

  const toggleModal = (type) => {
    const current = modal[type];
    setModal({ ...modal, [type]: !current });
  };

  const openModalByStatus = (status, newReason, type, idx) => {
    if (type === 'ADMISSION' && status === 'IMPROVE') {
      toggleModal('improve');
      setAdmissionIdx(idx);
    }
    if (type === 'ADMISSION' && status === 'REJECT') {
      setReason(newReason);
      toggleModal('reject');
    }
    if (type === 'ADMISSION' && status === 'PREPARATION') {
      toggleModal('preparation');
    }
    if (type !== 'ADMISSION' && status !== 'COMPLETE') {
      toggleModal('request');
    }
  };

  const handleUpdateWorker = useCallback(
    async (employeeApplyIdx, storeName, grade, storeAddress) => {
      const result = await modalMethod.confirm(
        <>
          <div>
            <PrimaryColorSpan>{storeName}</PrimaryColorSpan>의 <span>{Grade[grade]}</span>직
          </div>
          <div>요청을 수락하시겠습니까?</div>
          <div>({storeAddress})</div>
        </>,
        {
          cancelText: '거절하기',
          okText: '수락하기'
        }
      );
      let employeeApplyList;
      if (result) {
        employeeApplyList = [
          {
            employeeApplyIdx,
            status: 'WORK'
          }
        ];
      } else {
        employeeApplyList = [
          {
            employeeApplyIdx,
            status: 'REFUSE'
          }
        ];
      }
      await updateWorker({ employeeApplyList });
      await refetch();
    },
    [refetch, updateWorker]
  );

  useEffect(() => {
    const { employee } = (location.state as { processing: boolean; employee: boolean }) || {
      processing: false,
      employee: false
    };
    if (employee && active !== 1) {
      setActive(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { employee } = (location.state as { processing: boolean; employee: boolean }) || {
      processing: false,
      employee: false
    };
    if (!employee) {
      return;
    }
    const preparationEmployees = employeeApplyHistory?.employeeApplyList.filter(({ status }) => status === 'PREPARATION');

    if (preparationEmployees?.length === 1) {
      const { employeeApplyIdx, storeName, grade, storeAddress } = preparationEmployees[0];
      handleUpdateWorker(employeeApplyIdx, storeName, grade, storeAddress).then();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeApplyHistory, handleUpdateWorker]);

  return (
    <>
      <ImproveModal
        title="입점 신청 상세보기"
        open={modal.improve}
        onClose={() => toggleModal('improve')}
        admissionIdx={admissionIdx}
      />
      <RejectModal title="입점 신청 상세보기" open={modal.reject} onClose={() => toggleModal('reject')} reason={reason} />
      {modal.preparation ? (
        <PreparationModal title="입점 신청 상세보기" open={modal.preparation} onClose={() => toggleModal('preparation')} />
      ) : null}
      {modal.request ? (
        <RequestResultModal title="요청 결과 상세보기" open={modal.request} onClose={() => toggleModal('request')} />
      ) : null}
      <div>
        <Tabs items={tabItems} onChange={handleChange} defaultActive={active} />
      </div>
      <RequestHistoryContentWrapper>
        {active === 0 ? <AdmissionHistory admissionHistory={admissionHistory?.historyList} onClick={openModalByStatus} /> : null}
        {active === 1 ? (
          <EmployeeApplyHistory employeeApplyList={employeeApplyHistory?.employeeApplyList} onClick={handleUpdateWorker} />
        ) : null}
      </RequestHistoryContentWrapper>
    </>
  );
};

export default RequestHistory;
