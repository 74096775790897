import React from 'react';
import { Modal, Button } from 'antd';
import { FlexBoxFullWidthCenter, FlexBoxFullWidthHeight } from 'styles';
import { useNavigate, useLocation } from 'react-router-dom';

const RejectModal = ({ title, open, onClose, reason = '' }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const handleGotoAdmission = () => {
    onClose();
    navigate('/admission', { state: { backgroundLocation: location, step: 1 } });
  };
  return (
    <Modal title={title} open={open} onCancel={onClose} footer={null} bodyStyle={{ height: 712 }}>
      <FlexBoxFullWidthHeight direction="column" gap="55px" justify="space-evenly">
        <FlexBoxFullWidthCenter direction="column">
          <div>{reason} 로</div>
          <div>식권대장에 입점하실 수 없습니다.</div>
          <br />
          <div>다른 매장을 등록해주세요.</div>
        </FlexBoxFullWidthCenter>
        <Button type="link" onClick={handleGotoAdmission}>
          다른매장 정보 입력하기
        </Button>
      </FlexBoxFullWidthHeight>
    </Modal>
  );
};

export default RejectModal;
