import React from 'react';
import styled from 'styled-components';
import { FlexBoxFullWidthCenter } from 'styles';
import { IOrderDetail, TOrderStatus } from 'interfaces/robot';

import DefaultModal from '../components/DefaultModal';
import DefaultTable, { TColumns } from '../components/DefaultTable';
import DefaultGrid, { TGridColumns } from '../components/DefaultGrid';

const STATUS_LABEL = {
  CONFIRM: { label: '주문요청', color: '#0067FF' },
  ACCEPT: { label: '주문확정', color: '#46B476' },
  USER_CANCEL: { label: '취소완료', color: '#FA503C' },
  STORE_CANCEL: { label: '취소완료', color: '#FA503C' },
  ADMIN_CANCEL: { label: '취소완료', color: '#FA503C' },
  DELIVERING: { label: '배달중', color: '#FFBE00' },
  COMPLETE: { label: '배달완료', color: '#212529' },
  PICKED_UP: { label: '픽업완료', color: '#6D7582' }
};

const ModalWrapper = styled(FlexBoxFullWidthCenter)<{ $status: TOrderStatus }>`
  .title {
    font-size: 28px;
    font-weight: 600;
    color: #6d7582;
  }
  td,
  .totalOrderCount,
  .totalOrderAmount {
    text-decoration: ${({ $status }) => {
      return $status === 'USER_CANCEL' || $status === 'STORE_CANCEL' || $status === 'ADMIN_CANCEL' ? 'line-through' : 'none';
    }};
  }
  .statusName,
  .orderStatusPerDate {
    color: ${({ $status }) => STATUS_LABEL[$status]?.color};
  }
`;

interface OrderDetailModalProps {
  open: boolean;
  onCancel: () => void;
  orderDetail: IOrderDetail;
}

const PAYMENT_INFO_COLUMNS: TGridColumns[] = [
  { field: 'payRoomIdx', headerName: '결제번호', grid: 2 },
  {
    field: 'status',
    headerName: '상태',
    grid: 2,
    component: (value) => <span className="statusName">{STATUS_LABEL[value]?.label}</span>
  },
  { field: 'buyer', headerName: '결제자', grid: 2 },
  { field: 'useType', headerName: '사용형태', grid: 2 },
  { field: 'useDate', headerName: '결제일시', grid: 2 },
  { field: 'companyName', headerName: '회사명', grid: 2 }
];

const DELIVERY_INFO_COLUMNS: TGridColumns[] = [
  { field: 'receiverName', headerName: '받는 사람' },
  { field: 'receiverPhone', headerName: '연락받을 번호' },
  { field: 'deliverySpotName', headerName: '배달장소' },
  { field: 'deliveryMemo', headerName: '배달요청사항', component: (value) => value || '-' }
];

const getOrderSummaryColumns = (status): TGridColumns[] => {
  const isCancel = status === 'USER_CANCEL' || status === 'ADMIN_CANCEL' || status === 'STORE_CANCEL';
  return [
    {
      field: 'totalOrderCount',
      headerName: '총 주문 수량',
      grid: 2,
      component: (value) => <span className="totalOrderCount">{value}개</span>
    },
    {
      field: 'totalOrderAmount',
      headerName: '주문금액',
      grid: 2,
      component: (value) => <span className="totalOrderAmount">{value.toLocaleString()}</span>
    },
    { field: 'approvalNo', headerName: '주문승인번호', grid: 2 },
    {
      field: isCancel ? 'canceledDate' : 'approvalDate',
      headerName: isCancel ? '주문취소일시' : '주문승인일시',
      grid: 2,
      component: (value) => <span className={isCancel ? 'orderStatusPerDate' : null}>{value}</span>
    },
    { field: 'deliveryRobotName', headerName: '배달로봇번호', grid: 2 },
    { field: 'deliveryStartTime', headerName: '배달출발일시', grid: 2 },
    { field: 'deliveryEndTime', headerName: '배달완료일시', grid: 2 },
    { field: 'pickedUpTime', headerName: '픽업완료일시', grid: 2 }
  ];
};

const ORDER_MENUS_COLUMNS: TColumns[] = [
  { field: 'menuName', headerName: '메뉴명', width: 400 },
  { field: 'count', headerName: '수량', width: 150, textAlign: 'center' },
  { field: 'price', headerName: '가격', width: 200, textAlign: 'end' }
];

const OrderDetailModal = ({ open, onCancel, orderDetail }: OrderDetailModalProps) => {
  const orderMenus = orderDetail?.orderMenus.map(({ price, ...menu }) => ({ ...menu, price: price.toLocaleString() }));
  return (
    <DefaultModal title="주문 상세 정보" open={open} onOk={onCancel} onCancel={onCancel} cancellable closable>
      <ModalWrapper gap="20px" direction="column" align="flex-start" $status={orderDetail?.paymentInfo.status}>
        <div className="title">결제 정보</div>
        <DefaultGrid columns={PAYMENT_INFO_COLUMNS} data={orderDetail?.paymentInfo} />
        <div className="title">배달 정보</div>
        <DefaultGrid columns={DELIVERY_INFO_COLUMNS} data={orderDetail?.deliveryInfo} />
        <div className="title">주문 내역</div>
        <div>
          <DefaultGrid columns={getOrderSummaryColumns(orderDetail?.paymentInfo.status)} data={orderDetail?.orderSummary} />
          <DefaultTable columns={ORDER_MENUS_COLUMNS} data={orderMenus || []} />
        </div>
      </ModalWrapper>
    </DefaultModal>
  );
};

export default OrderDetailModal;
