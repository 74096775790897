/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import moment, { Moment } from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { generate } from 'shortid';
import { DateModal } from 'components';
import { useExcelDownload, useMeInfo, useStoreStatsAdmin, useStoreCompany, useStores } from 'hooks';
import { numberWithCommas } from 'utils';

import imgSelect from 'images/login/select.png';
import imgSelected from 'images/login/selected.png';
import imgSelectedDim from 'images/other/select_dim.png';

type TStateValue = {
  startDate: Moment;
  endDate: Moment;
  selectStore: string;
  selectCompany: string;
  isSelectStore: boolean;
  isSelectCompanyMode: boolean;
  isSelectMenuMode: boolean;
  storeList: any[];
};

type TStatsParams = {
  startDate: string;
  endDate: string;
  kind: 'MONTH' | 'DAY';
  menu: boolean;
  company: boolean;
  comId?: string;
  page: number;
  pageRow: number;
  store: boolean;
  storeId?: string;
};

const MasterStats = () => {
  const { changeExcelParams } = useExcelDownload();
  const { meInfo } = useMeInfo();

  const [isOpen, setOpen] = useState<boolean>(false);

  const [state, setState] = useState<TStateValue>({
    startDate: moment().date(1),
    endDate: moment(new Date()),
    selectStore: 'all',
    selectCompany: 'all',
    isSelectStore: true,
    isSelectCompanyMode: false,
    isSelectMenuMode: false,
    storeList: meInfo.storeList
  });

  const [statsParams, setStatsParams] = useState<TStatsParams>({
    startDate: moment().date(1).format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    kind: 'MONTH',
    menu: false,
    company: false,
    comId: undefined,
    page: 1,
    pageRow: 100,
    store: true,
    storeId: ''
  });

  useStores({
    onSuccess: (data) => {
      setState({ ...state, storeList: data.storeList });
    }
  });

  const { data: adminStats, fetchNextPage, hasNextPage } = useStoreStatsAdmin(statsParams);
  const { data: storeCompany } = useStoreCompany({ getOption: 'isLoadingPass' });

  useEffect(() => {
    changeExcelParams({
      date1: state.startDate,
      date2: state.endDate,
      data: {
        ...state,
        type: statsParams.kind
      }
    });
  }, [changeExcelParams, state.startDate, state.endDate, state, statsParams.kind]);

  const getReport = (changeParams: { startDate?: Moment; endDate?: Moment } & Partial<TStatsParams>) => {
    const { startDate, endDate, kind, menu, company, store, comId, storeId } = changeParams;
    const params: TStatsParams = {
      startDate: startDate?.format('YYYY-MM-DD') ?? state.startDate.format('YYYY-MM-DD'),
      endDate: endDate?.format('YYYY-MM-DD') ?? state.endDate.format('YYYY-MM-DD'),
      kind: kind ?? statsParams.kind,
      menu: menu ?? statsParams.menu,
      company: company ?? statsParams.company,
      page: statsParams.page,
      pageRow: statsParams.pageRow,
      store: store ?? statsParams.store
    };

    if (params.company) {
      if ((comId ?? state.selectCompany) === 'all') params.comId = '';
      else params.comId = comId ?? state.selectCompany;
    }

    if (params.store) {
      if ((storeId ?? state.selectStore) === 'all') {
        params.storeId = '';
      } else {
        params.storeId = storeId ?? state.selectStore;
      }
    }
    setStatsParams(params);
  };

  const contents = useMemo(() => {
    if (!adminStats?.pages.length) {
      return [];
    }
    const resultArr = [];
    let beforeHour = '';
    return adminStats.pages.reduce((acc, page) => {
      const dataList = (page.data as any[]).map((data) => {
        let useDateStr = moment(data.useDate * 1000).format('YYYY. M.'); // MONTH DEFAULT
        if (statsParams.kind === 'DAY') {
          useDateStr = moment(data.useDate * 1000).format('YYYY. M. DD.');
        }
        if (beforeHour !== useDateStr) {
          beforeHour = useDateStr.toString();
          // 이중 데이터 검증.
          const isCheck = resultArr.some((d) => {
            return d.type === 'category' && d.data === useDateStr;
          });
          if (!isCheck) {
            // resultArr.push({ type: 'category', data: useDateStr });
            // return { type: 'category', data: useDateStr };
            acc.push({ type: 'category', data: useDateStr });
          }
        }

        // resultArr.push({ type: 'history', data });
        return acc.push({ type: 'history', data });
      });
      return [...acc, ...dataList];
    }, []);
    // return resultArr;
  }, [adminStats?.pages, statsParams.kind]);

  const dateModel = () => {
    setOpen(true);
  };

  const closeDateModal = () => {
    setOpen(false);
  };

  const dateHandle = (range: { startDate: Moment; endDate: Moment }) => {
    const startDate = range.startDate.format('YYYY-MM-DD');
    const endDate = range.endDate.format('YYYY-MM-DD');
    closeDateModal();
    setStatsParams({ ...statsParams, startDate, endDate });
    setState({ ...state, startDate: range.startDate, endDate: range.endDate });
  };

  const modeChange = (mode) => {
    setStatsParams({ ...statsParams, kind: mode });
  };

  const handleCompanyMode = () => {
    if (!state.isSelectCompanyMode === false && state.isSelectStore === false) {
      alert('1개 이상의 옵션을 체크해주세요.');
      return;
    }
    const isSelectCompanyMode = !state.isSelectCompanyMode;
    getReport({ company: isSelectCompanyMode });
    setState({ ...state, isSelectCompanyMode });
  };

  const handleMenuMode = () => {
    if (!state.isSelectStore) return;

    const isSelectMenuMode = !state.isSelectMenuMode;

    getReport({ menu: isSelectMenuMode });
    setState({ ...state, isSelectMenuMode });
  };

  const handleStoreMode = () => {
    if (state.isSelectCompanyMode === false && !state.isSelectStore === false) {
      alert('1개 이상의 옵션을 체크해주세요.');
      return;
    }

    const isSelectStore = !state.isSelectStore;
    let { isSelectMenuMode } = state;
    if (isSelectStore === false) {
      isSelectMenuMode = false;
    }
    setState({ ...state, isSelectStore, isSelectMenuMode });
    getReport({ store: isSelectStore, menu: isSelectMenuMode });
  };

  const handleSelectStoreChange = (value) => {
    const sid = value.target.value;

    setState({ ...state, selectStore: sid });

    getReport({ storeId: sid });
  };

  const handleSelectCompanyChange = (value) => {
    const comId = value.target.value;
    setState({ ...state, selectCompany: comId });
    getReport({ comId });
  };

  const startDate = state.startDate.format('YYYY-MM-DD');
  const endDate = state.endDate.format('YYYY-MM-DD');

  let isSelectCompany = imgSelect;
  let isCompanyListDisabled = true;
  let isStoreListDisabled = false;

  if (statsParams.company) {
    isSelectCompany = imgSelected;
    isCompanyListDisabled = false;
  }

  let isSelectMenu = imgSelect;
  if (statsParams.menu) isSelectMenu = imgSelected;

  let isSelectStore = imgSelect;

  if (statsParams.store) {
    isSelectStore = imgSelected;
  } else {
    isStoreListDisabled = true;
    isSelectMenu = imgSelectedDim;
  }

  const myStoreListRow = meInfo?.storeList
    ?.filter(({ grade }) => grade !== 'STAFF')
    .map(function (a) {
      return (
        <option value={a.sid} key={a.sid}>
          {a.name}
        </option>
      );
    });

  const myCompanyListRows = storeCompany?.map(function (a) {
    return (
      <option value={a.comId} key={a.comId}>
        {a.name}
      </option>
    );
  });

  const pcRows = useMemo(
    () =>
      contents.map(function (obj) {
        if (obj.type === 'category') {
          let colSpan = 5;
          if (statsParams.menu) colSpan += 1;
          if (statsParams.company) colSpan += 1;
          return (
            <tr className="cutline" key={generate()}>
              <td colSpan={colSpan}>{obj.data}</td>
            </tr>
          );
        }
        if (obj.type === 'history') {
          return (
            <tr className="dataRow" key={generate()}>
              <td>
                {statsParams.kind === 'DAY'
                  ? moment(obj.data.useDate * 1000).format('YYYY. M. DD.')
                  : moment(obj.data.useDate * 1000).format('YYYY. MM.')}
              </td>
              {statsParams.store ? <td className="textInfo">{obj.data.storeName}</td> : null}
              {statsParams.company ? <td className="textInfo">{obj.data.comName}</td> : null}
              {statsParams.menu ? <td className="textInfo">{obj.data.menuName}</td> : null}
              <td className="priceLabel last">{numberWithCommas(obj.data.totalPayCount)}</td>
              <td className="priceLabel last">{numberWithCommas(obj.data.totalPrice)}원</td>
              <td className="priceLabel last">{numberWithCommas(obj.data.cancelPrice)}원</td>
            </tr>
          );
        }
        return null;
      }),
    [contents, statsParams.company, statsParams.kind, statsParams.menu, statsParams.store]
  );

  let typeSelect1 = 'string';
  let typeSelect2 = 'string';

  if (statsParams.kind === 'MONTH') typeSelect1 = 'string active';
  if (statsParams.kind === 'DAY') typeSelect2 = 'string active';

  return (
    <div className="MasterSalesstats-content" id="salesstats">
      <div className="date">
        <div className="left">
          <div className="str">조회기간</div>
          <div className="types">
            <div className="btn1" onClick={() => modeChange('MONTH')}>
              <div className={typeSelect1}>월별 통계보기</div>
            </div>
            <div className="btn2" onClick={() => modeChange('DAY')}>
              <div className={typeSelect2}>일별 통계보기</div>
            </div>
          </div>
        </div>
        <div className="right" onClick={dateModel}>
          <div className="todate">
            <div className="string">
              {startDate} ~ {endDate}
            </div>
          </div>
        </div>
      </div>
      <div className="optionType">
        <img src={isSelectStore} className="toggle" onClick={handleStoreMode} alt="" />

        <div className="storeSelect">
          <select
            className="form-control"
            onChange={handleSelectStoreChange}
            value={state.selectStore}
            disabled={isStoreListDisabled}
          >
            <option value="all">식당명 전체</option>
            {myStoreListRow}
          </select>
        </div>
        <div className="comapnySelect">
          <img src={isSelectCompany} className="toggle" onClick={handleCompanyMode} alt="" />

          <select
            className="form-control"
            onChange={handleSelectCompanyChange}
            value={state.selectCompany}
            disabled={isCompanyListDisabled}
          >
            <option value="all">회사명 전체</option>
            {myCompanyListRows}
          </select>
        </div>
        <div className="menu">
          <img src={isSelectMenu} className="toggle" onClick={handleMenuMode} alt="" />
          메뉴별
        </div>
      </div>

      <div className="content-area">
        <table className="mastersalesStats-table">
          <thead>
            <tr>
              <th>날짜</th>
              {statsParams.store ? <th className="textInfo">식당명</th> : null}
              {statsParams.company ? <th className="textInfo">회사명</th> : null}
              {statsParams.menu ? <th className="textInfo">메뉴명</th> : null}
              <th className="priceLabel last">건수</th>
              <th className="priceLabel last">판매가격</th>
              <th className="priceLabel last">취소금액</th>
            </tr>
          </thead>
          <tbody>
            {pcRows}
            {hasNextPage ? (
              <tr className="more" onClick={() => fetchNextPage()}>
                <td colSpan={7}>더보기</td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>

      <div id="mobileView" />
      <DateModal
        isOpenCalendar={isOpen}
        startDate={state.startDate}
        endDate={state.endDate}
        dateSelected={dateHandle}
        month={6}
        close={closeDateModal}
      />
    </div>
  );
};

export default MasterStats;
