import React from 'react';
import Chart from 'react-google-charts';

interface ColumnChartProps {
  gData?: Record<string, undefined> | unknown[];
  type: any;
}

const ColumnChart = ({ gData, type }: ColumnChartProps) => {
  let options = {};
  let style: React.CSSProperties | undefined;

  if (!gData) {
    style = {
      height: 300,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center'
    };
  }

  if (type === 'ColumnChart') {
    options = {
      colors: ['#61C282'],
      legend: { position: 'none' },
      vAxis: { format: '#,### 원' },
      bar: { groupWidth: '75%' }
    };
  } else {
    options = {
      chartArea: { width: '100%' },
      is3D: false,
      pieHole: 0.3,
      colors: ['#61C282', '#647FB3', '#A294D6', '#FB828A', '#FFB982', '#F0E46B']
    };
  }
  return gData ? (
    <Chart width="100%" height="300px" chartType={type} loader={<div>Loading Chart...</div>} data={gData} options={options} />
  ) : (
    <div style={style}>
      <span>데이터가 없습니다.</span>
    </div>
  );
};

export default ColumnChart;
