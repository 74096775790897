/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { createRef, useRef } from 'react';
import MenuTableRow from './MenuTableRow';

interface MenuTableProps {
  category;
  data;
  checkCategory;
  mobileSelect;
  checkedItems: Set<string>;
}

const MenuTable = ({ category, data, checkCategory, mobileSelect, checkedItems }: MenuTableProps) => {
  const categoryCheckboxRefs = useRef([]);
  if (categoryCheckboxRefs.current.length !== category?.length || 0) {
    categoryCheckboxRefs.current = Array(category.length)
      .fill(0)
      .map((_, i) => categoryCheckboxRefs.current[i] || createRef());
  }

  return (
    <tbody>
      {category &&
        category.map((name, i) => {
          const categoryData = data.filter((menu) => menu.category === name);
          const { categoryId } = categoryData.length !== 0 ? categoryData[0] : { categoryId: '' };

          const categoriesMenuItems = new Set<string>(data.filter(({ category: c }) => name === c).map(({ id }) => id));
          const intersection = new Set([...checkedItems].filter((e) => categoriesMenuItems.has(e)));
          const isChecked = [...categoriesMenuItems].every((menuId) => intersection.has(menuId));

          if (categoryCheckboxRefs.current[i]?.current) {
            if (!isChecked && intersection.size > 0 && !categoryCheckboxRefs.current[i].current.indeterminate) {
              categoryCheckboxRefs.current[i].current.indeterminate = true;
            } else if (categoryCheckboxRefs.current[i].current.indeterminate && (isChecked || intersection.size === 0)) {
              categoryCheckboxRefs.current[i].current.indeterminate = false;
            }
          }

          return (
            <React.Fragment key={name || 'etc'}>
              <tr className={`category-row-${categoryId} menu-category`}>
                <td className="text-center media-display-none">
                  <div className="menu-category-check-div-box">
                    <input
                      type="checkbox"
                      ref={categoryCheckboxRefs.current[i]}
                      onChange={() => checkCategory(name)}
                      checked={isChecked}
                    />
                  </div>
                </td>
                <td className="media-display-none">
                  <div onClick={() => checkCategory(name)}>
                    <span className="menu-group">{name || '기타'}</span>
                  </div>
                </td>
                <td className="media-display-none">
                  <div className="menu-category-null-box" onClick={() => checkCategory(name)} />
                </td>
              </tr>
              <MenuTableRow
                checkedItems={checkedItems}
                key={categoryId}
                categoryId={categoryId}
                categoryData={categoryData}
                mobileSelect={mobileSelect}
              />
            </React.Fragment>
          );
        })}
    </tbody>
  );
};

export default MenuTable;
