import React from 'react';
import ChannelTalkDescription from './ChannelTalkDescription';

const NoticeBeforeCertification = () => (
  <ChannelTalkDescription>
    <div>
      <div>최초 1회 본인인증을 진행한 경우만</div>
      <div>본인인을 통해 아이디를</div>
      <div>찾을 수 있습니다.</div>
    </div>
    <div>
      <div>본인인증을 진행한 적 없다면</div>
      <div>채팅 상담으로 문의 해주세요.</div>
    </div>
  </ChannelTalkDescription>
);

export default NoticeBeforeCertification;
