/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Modal, Space } from 'antd';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { isMobile, handlerKeyUp } from 'utils';
import { StoreType } from 'interfaces/store';
import { MeInfo, MeInfoStore } from 'interfaces/auth';

import iconLogo from 'images/N_Logo.png';
import g_icon03 from 'images/gnb/g_icon03.png';
import iconRealtime from 'images/sidemenu/icon01_realtime.svg';
import iconRobotDelivery from 'images/sidemenu/icon16_robotDelivery.svg';
import iconShippingOrder from 'images/sidemenu/icon02_shippingOrder.svg';
import iconSettlementMeta from 'images/sidemenu/icon03_settlementMeta.svg';
import iconMenus from 'images/sidemenu/icon04_menus.svg';
import iconStoreImage from 'images/sidemenu/icon05_storeImage.svg';
import iconReview from 'images/sidemenu/icon06_review.svg';
import iconExcelfile from 'images/sidemenu/icon07_excelfile.svg';
import iconManageBooking from 'images/sidemenu/icon08_manageBooking.svg';
import iconReserveStatus from 'images/sidemenu/icon09_reserveStatus.svg';
import iconReserveStats from 'images/sidemenu/icon10_reserveStats.svg';
import iconEmployee from 'images/sidemenu/icon11_employee.svg';
import iconCalculateInfo from 'images/sidemenu/icon12_calculateInfo.svg';
import iconPaperBill from 'images/sidemenu/icon13_bill.svg';
import iconSetting from 'images/sidemenu/icon14_setting.svg';
import iconCalculate from 'images/sidemenu/icon15_calculate2.svg';

import MenuLink from './MenuLink';
import { StoreSelectContainer, AdmissionButton, SettleModal } from './styles';

export interface SidebarProps {
  onSignOut: () => void;
  posBreakPoint?: boolean;
  onCloseDrawer?: () => void;
  meInfo?: MeInfo;
  selectedStore?: StoreType;
  storeList?: MeInfoStore[];
  changeStore?: (v) => void;
}

const Sidebar = ({ meInfo, selectedStore, changeStore, onCloseDrawer, onSignOut, posBreakPoint }: SidebarProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [settleVisible, setSettleVisible] = useState(false);

  const handleClick = () => {
    onCloseDrawer?.();
  };

  const openChangeStoreModal = () => {
    setVisible(true);
    onCloseDrawer?.();
  };

  const closeChangeStoreModal = () => {
    setVisible(false);
  };

  const viewStat = () => {
    changeStore(null);
    setVisible(false);
    navigate('/');
  };

  const openAdmission = () => {
    setVisible(false);
    navigate('/admission', { state: { backgroundLocation: location } });
  };

  const changeStoreAccount = (store) => {
    changeStore(store);
    setVisible(false);
    navigate('/');
  };

  const goMain = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    navigate('/');
    onCloseDrawer?.();
  };

  const handleSettleClick = () => {
    setSettleVisible((prevState) => !prevState);
  };

  const { supplyType, isBooking, cancelpw: cancelPassword, meInfoCurrent } = selectedStore || {};

  const ownerStores = meInfo?.storeList?.filter(({ grade }) => grade === 'OWNER');
  const staffStores = meInfo?.storeList?.filter(({ grade }) => grade === 'STAFF');
  const myGrade = meInfoCurrent?.grade;
  const isStaff = myGrade === 'STAFF';

  let isView = true;
  if (!isBooking) {
    isView = false;
  }

  const isStores = meInfo?.storeList && meInfo?.storeList.length > 1;
  const isEmptyStore = !meInfo?.storeList?.length;

  return (
    <div id="sidebar-wrapper">
      <nav className="navbar navbar-default sidebarNavbar">
        <div className="container-fluid">
          <div className="navbar-header">
            {posBreakPoint ? (
              <button type="button" onClick={handleClick} className="navbar-toggle pull-left">
                <img src={g_icon03} alt="toggle-icon" className="gnbicon" />
              </button>
            ) : null}
            <a className="sikdae-brand" href="#" onClick={goMain}>
              <img height={22} src={iconLogo} alt="icon logo" />
            </a>
          </div>
        </div>
      </nav>

      <div className="multiple-store">
        <div className="store-name-wrapper">
          <p className="storename">{selectedStore ? selectedStore?.name : meInfo?.admin?.name}</p>
          <div className="nav-store-btn">
            <div
              className={isStores ? 'change' : 'number'}
              role="button"
              tabIndex={0}
              onClick={() => isStores && openChangeStoreModal()}
              onKeyUp={handlerKeyUp(() => isStores && openChangeStoreModal())}
            >
              <p>{isStores ? '다른 제휴점 선택' : meInfo?.admin?.id}</p>
            </div>
            <AdmissionButton onClick={openAdmission}>입점 신청</AdmissionButton>
          </div>
        </div>
      </div>
      <nav id="spy">
        <ul className="sidebar-nav nav">
          <MenuLink to="realtime" icon={iconRealtime} name="실시간 매출" disabled={!selectedStore} />
          {supplyType === 12 ? (
            <MenuLink
              to="robotDelivery"
              icon={iconRobotDelivery}
              name="로봇배달 주문관리"
              disabled={!selectedStore || supplyType !== 12}
            />
          ) : null}
          {!isStaff && supplyType === 7 && !isMobile() ? (
            <MenuLink to="shippingOrderMgt" icon={iconShippingOrder} name="배송상품 주문관리" />
          ) : null}
          {!isStaff ? <MenuLink to="salesStats" icon={iconShippingOrder} name="매출 통계" disabled={!selectedStore} /> : null}
          <MenuLink to="settlementMetaInfo" icon={iconSettlementMeta} name="정산 기준정보" disabled={!selectedStore} />
          {!isStaff && !selectedStore ? (
            <MenuLink to="masterStats" icon={iconShippingOrder} name="제휴점별 매출 통계" disabled={isEmptyStore} />
          ) : null}
          <MenuLink to="menus" icon={iconMenus} name="우리가게 메뉴판" disabled={!selectedStore} />
          <MenuLink to="storeImage" icon={iconStoreImage} name="제휴점 이미지 설정" disabled={!selectedStore} />
          <MenuLink to="review" name="리뷰 관리" icon={iconReview} disabled={!selectedStore} />
          <MenuLink to="excelStorage" icon={iconExcelfile} name="엑셀파일 보관함" disabled={isEmptyStore} />
          {isView ? (
            <>
              <MenuLink to="manageBookingMenu" icon={iconManageBooking} name="주간 예약 관리" />
              <MenuLink to="reserveStatus" icon={iconReserveStatus} name="예약 현황" />
              <MenuLink to="reserveStats" icon={iconReserveStats} name="예약 통계" />
            </>
          ) : null}
          {!isStaff ? (
            <MenuLink
              to="employee"
              name="직원관리"
              icon={iconEmployee}
              disabled={!selectedStore}
              materialIcon={<span className="material-icons material-symbols-outlined review-side-menu">person</span>}
            />
          ) : null}
          {!isStaff ? <MenuLink to="calculateInfo" icon={iconCalculateInfo} name="입금정보" disabled={!selectedStore} /> : null}
          <MenuLink to="evidences" icon={iconPaperBill} disabled={!selectedStore} name="계산서 발행 확인" />
          {!isStaff && meInfo?.admin?.level === 40 ? (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
            <li className="sidebar-menu-item" onClick={handleSettleClick} onKeyUp={handleSettleClick}>
              <div>
                <img src={iconCalculate} alt="lnb-icon" />
                <span className="fa fa-anchor solo menuName">선정산</span>
              </div>
            </li>
          ) : null}
          <MenuLink to="setting" icon={iconSetting} name="설정" />
          <div>
            {cancelPassword && cancelPassword.length > 0 ? (
              <div className="cancelNum">
                식권 취소 번호<span id="cancelNumber">{cancelPassword}</span>
              </div>
            ) : null}
          </div>
          <div className="setting" role="button" tabIndex={0} onClick={() => onSignOut()} onKeyUp={handlerKeyUp(onSignOut)}>
            <span>로그아웃</span>
          </div>
        </ul>
      </nav>
      <Modal title="제휴점 선택" open={visible} onCancel={closeChangeStoreModal} footer={null}>
        <StoreSelectContainer>
          <Space direction="vertical">
            <Button onClick={viewStat}>제휴점 통계 모아보기</Button>
          </Space>
          {ownerStores?.length ? (
            <>
              <p style={{ marginTop: 24, textAlign: 'center' }}>* {meInfo?.admin.name}님이 관리하는 제휴점들입니다.</p>
              <Space direction="vertical">
                {ownerStores.map((store) => (
                  <Button key={store?.id} onClick={() => changeStoreAccount(store)}>
                    {store.name}
                  </Button>
                ))}
              </Space>
            </>
          ) : null}
          {staffStores?.length ? (
            <>
              <p style={{ marginTop: 24, textAlign: 'center' }}>* {meInfo?.admin.name}님이 직원인 제휴점들입니다.</p>
              <Space direction="vertical">
                {staffStores.map((store) => (
                  <Button key={store?.id} onClick={() => changeStoreAccount(store)}>
                    {store.name}
                  </Button>
                ))}
              </Space>
            </>
          ) : null}
        </StoreSelectContainer>
      </Modal>
      <SettleModal
        title="선정산"
        open={settleVisible}
        okText="확인"
        onOk={handleSettleClick}
        closable={false}
        onCancel={handleSettleClick}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <div>
          선정산 이용은 <span className="bold">“식권대장 사장님”</span> 모바일 앱을 통해 가능합니다.
        </div>
        <div>
          안드로이드폰은 구글 <span className="bold">플레이 스토어</span>에서, 아이폰은 <span className="bold">앱스토어</span>
          에서
        </div>
        <div>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          <span className="bold">"식권대장 사장님"</span> 을 검색 후 다운로드하세요
        </div>
      </SettleModal>
    </div>
  );
};

export default Sidebar;
