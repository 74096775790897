import React, { useEffect, useState } from 'react';
import type { UploadFile, RcFile } from 'antd/es/upload/interface';
import { Tooltip, Image } from 'antd';

function getBase64(file: RcFile) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export interface CustomUploadListProps {
  originNode: React.ReactElement;
  file: UploadFile;
  currFileList: UploadFile[];
  actions: {
    download: () => void;
    preview: () => void;
    remove: () => void;
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CustomUploadList = ({ originNode, file, currFileList, actions }: CustomUploadListProps) => {
  const [src, setSrc] = useState('');

  useEffect(() => {
    if (file?.url) {
      setSrc(file.url);
    } else {
      getBase64(file as RcFile).then((result) => setSrc(result as string));
    }
  }, [file]);

  const errorNode = <Tooltip title="Upload Error">{originNode.props.children}</Tooltip>;

  return file.status === 'error' ? errorNode : <Image width={150} src={src} />;
};

export default CustomUploadList;
