import React, { useEffect, useState } from 'react';
import { Upload, Button, UploadProps, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { UploadFile } from 'antd/es/upload/interface';
import CustomUploadList from './CustomUploadList';

export interface FileUploadProps extends UploadProps {
  value?: Array<UploadFile>;
  size?: number;
  onChange?;
  children?: React.ReactNode;
}

const ItemRender = (originNode, file, currFileList, action) => (
  <CustomUploadList originNode={originNode} file={file} currFileList={currFileList} action={action} />
);

const FileUpload = ({ value, onChange, children, maxCount, size, ...uploadProps }: FileUploadProps) => {
  const [fileList, setFileList] = useState<Array<any>>([]);

  useEffect(() => {
    if (value && value.length > 0) {
      setFileList(value);
    }
  }, [value]);

  const beforeUpload = (file: UploadFile) => {
    if (size && file.size / 1024 / 1024 < size) {
      message.warning(`${size}mb 까지 첨부하실 수 있습니다.`);
      return false;
    }
    const newFileList = [...fileList, file];
    if (typeof onChange === 'function') {
      if (maxCount === 1) {
        onChange([file]);
      } else {
        onChange(newFileList);
      }
    }
    return false;
  };

  const onRemove = (file: UploadFile) => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    // setFileList(newFileList);
    if (typeof onChange === 'function') {
      onChange(newFileList);
    }
  };

  const props = {
    beforeUpload,
    onRemove,
    fileList
  };

  return (
    <Upload maxCount={maxCount} listType="picture" itemRender={ItemRender} {...props} {...uploadProps}>
      <Button icon={<UploadOutlined />}>{children || '파일 선택'}</Button>
    </Upload>
  );
};

export default FileUpload;
