import React from 'react';
import emptyStoreImage from 'images/EmptyStore.png';
import { EmptyFlexWrapper } from './style';

const EmptyStore = () => (
  <EmptyFlexWrapper>
    <img width={188} height={214} src={emptyStoreImage} alt="" />
  </EmptyFlexWrapper>
);

export default EmptyStore;
