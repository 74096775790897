import React, { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Modal } from 'antd';
import moment from 'moment';
import { RouteModal, TermsModal } from 'components';
import { AdmissionServiceTermContainer } from 'containers';
import { useAdmission, useAdmissionTypes, useMeInfo, useSaveAdmission } from 'hooks';
import { BottomButtonArea, ContentPadding24, FlexBoxFullWidthCenter } from 'styles';
import { StepLayout } from 'layouts';
import { ContractTable } from '../style';

const ContractContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data: types } = useAdmissionTypes();

  const [visible, setVisible] = useState(false);

  const admissionIdx = useMemo(() => {
    const { admissionIdx: idx } = location.state as { admissionIdx: number };
    return idx;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { meInfo } = useMeInfo();
  const { data } = useAdmission(admissionIdx);

  const changedData = useMemo(() => {
    if (!data) {
      return null;
    }
    const { addressType, addressDetail, jibunAddress, roadAddress, supplyType } = data;
    if (!types?.admissionSupplyTypeList) {
      return null;
    }
    const supplyTypeName = types?.admissionSupplyTypeList.find((s) => s.code === supplyType)?.name;
    if (addressType === 'R') {
      return { address: `${roadAddress} ${addressDetail}`, supplyTypeName };
    }
    return { address: `${jibunAddress} ${addressDetail}`, supplyTypeName };
  }, [data, types]);

  const { isLoading: isSaveLoading, mutateAsync: saveAdmission } = useSaveAdmission();

  const onSubmit = async () => {
    if (!meInfo) {
      return;
    }
    const {
      admin: { said }
    } = meInfo;
    const idx = data?.idx;
    const admissionParams = { sid: said, admission: { isAccountConfirmation: true, step: 5, idx } };
    await saveAdmission(admissionParams);
    navigate('/admission/complete', { state: location.state, replace: true });
  };

  const onCancelSubmit = () => {
    if (!meInfo) {
      return;
    }
    const {
      admin: { said }
    } = meInfo;
    Modal.confirm({
      // title: 'error',
      content: <>정말 신청을 취소하시겠습니까?</>,
      icon: null,
      okText: '네, 취소합니다.',
      cancelText: '제출합니다.',
      onOk: async () => {
        const idx = data?.idx;
        const admissionParams = { sid: said, admission: { isAccountConfirmation: false, isDelete: true, step: 5, idx } };
        await saveAdmission(admissionParams);
        navigate(-1);
      },
      className: 'confirm-cancel-handler-modal-reverse'
    });
  };

  return (
    <RouteModal title="계약조건 최종 확인" onCancel={onCancelSubmit}>
      <ContentPadding24>
        <StepLayout
          content={
            <FlexBoxFullWidthCenter direction="column" gap="100px">
              <ContractTable>
                <tbody>
                  <tr>
                    <th>신청일</th>
                    <td colSpan={2}>{moment().format('YYYY-MM-DD')}</td>
                  </tr>
                  <tr>
                    <th>공급 형태</th>
                    <td colSpan={2}>
                      <b>{changedData?.supplyTypeName}</b>
                    </td>
                  </tr>
                  <tr>
                    <th>수수료율</th>
                    <td colSpan={2}>
                      <b>{data?.commission}</b>
                    </td>
                  </tr>
                  <tr>
                    <th rowSpan={3}>제휴식당</th>
                    <th>상호(법인명)</th>
                    <td>{data?.storeName}</td>
                  </tr>
                  <tr>
                    <th>주소</th>
                    <td>{changedData?.address}</td>
                  </tr>
                  <tr>
                    <th>대표자</th>
                    <td>{data?.presidentName}</td>
                  </tr>

                  <tr>
                    <th rowSpan={4}>운영회사</th>
                    <th>법인명</th>
                    <td>주식회사 현대벤디스</td>
                  </tr>
                  <tr>
                    <th>주소</th>
                    <td>서울시 강남구 봉은사로103길 5, 4F (삼성동, 성재빌딩)</td>
                  </tr>
                  <tr>
                    <th>대표자</th>
                    <td>조정호</td>
                  </tr>
                  <tr>
                    <th>이메일</th>
                    <td>biz@vendys.co.kr</td>
                  </tr>
                </tbody>
              </ContractTable>
              <FlexBoxFullWidthCenter justify="center">
                <Button type="link" onClick={() => setVisible(true)}>
                  <u>계약서 전체보기</u>
                </Button>
                <TermsModal open={visible} close={() => setVisible(false)} title="서비스 공급 계약 동의">
                  <AdmissionServiceTermContainer admissionIdx={admissionIdx} supplyType={data?.supplyType} />
                </TermsModal>
              </FlexBoxFullWidthCenter>
            </FlexBoxFullWidthCenter>
          }
          bottom={
            <div>
              <BottomButtonArea>
                <Button className="admission-submit-btn cancel-btn" onClick={onCancelSubmit}>
                  신청철회
                </Button>
                <Button className="admission-submit-btn" type="primary" loading={isSaveLoading} onClick={onSubmit}>
                  제출하기
                </Button>
              </BottomButtonArea>
            </div>
          }
        />
      </ContentPadding24>
    </RouteModal>
  );
};

export default ContractContainer;
