import { Select } from 'antd';
import { DateNavigation } from 'components';
import { useCompanies, useMeInfo, usePollingDeliveryStatus, useBookingByCompany, useExcelDownload } from 'hooks';
import moment from 'moment';
import React, { useState, useEffect } from 'react';

import { handlerKeyUp } from 'utils';
import PollingModal from './modal/PollingModal';
import { Container } from './styles';
import CompanyBy from './TabContents/CompanyBy';
import MenuBy from './TabContents/MenuBy';
import SpotBy from './TabContents/SpotBy';

const { Option } = Select;

const ReserveStatus = () => {
  const { changeExcelParams } = useExcelDownload();
  const [type, setType] = useState<string>('COMPANY');
  const [date, setDate] = useState<Date>(new Date());

  const [companyId, setCompanyId] = useState<string>('ALL');
  const [diffSpots, setDiffSpots] = useState([]);
  const [pollingArray, setPollingArray] = useState([]);
  const [diffArray, setDiffArray] = useState([]);

  const { selectedStore } = useMeInfo();
  const bookingDate = moment(date).format('YYYY-MM-DD');
  const { data: companyList } = useCompanies(selectedStore?.sid);
  const { data: pollingSpots } = usePollingDeliveryStatus(selectedStore?.sid, bookingDate);

  const { data: bookingByCompany, refetch } = useBookingByCompany(selectedStore?.sid, companyId, bookingDate, () => {
    changeExcelParams({ date1: date, date2: date });
  });

  useEffect(() => {
    const diffList: object[] = [];
    const pollingList: object[] = [];
    if (pollingSpots?.spots) {
      pollingSpots.spots.forEach((item) => {
        const spot = diffSpots.filter(({ spot: s }) => s.spotKey === item.spot.spotKey)[0];
        spot?.agents?.forEach(async (agent) => {
          const diff = item.agents.filter(({ orderIdx, status }) => agent.orderIdx === orderIdx && agent.status !== status)[0];

          if (diff) {
            if (agent.status === 'PICKING_UP' && diff.status === 'ASSIGNING_AGENT') {
              pollingList.unshift({
                ...diff,
                ...item.company,
                ...item.spot,
                status: 'CANCELED'
              });
            }

            if (diff.status === 'PICKING_UP' || diff.status === 'SUBMIT_FAILED' || diff.status === 'ASSIGN_FAILED') {
              pollingList.unshift({
                ...diff,
                ...item.company,
                ...item.spot
              });
            }

            if (agent.status !== 'ASSIGNING_AGENT' && diff.status === 'CANCELED') {
              pollingList.unshift({
                ...diff,
                ...item.company,
                ...item.spot
              });
            }
            diffList.push(diff);
            await refetch();
          }
        });
      });
      setDiffArray(diffList);
      setDiffSpots(pollingSpots?.spots);
    }
    setPollingArray(pollingList);
  }, [pollingSpots?.spots, refetch, diffSpots]);

  const searchData = (searchType: string) => {
    setType(searchType);
  };

  const onValueChange = (value) => {
    setDate(value);
  };

  const changeCompany = (value: string) => {
    setCompanyId(value);
  };

  const typeClassName1 = type === 'COMPANY' ? 'type1 active' : 'type1';
  const typeClassName2 = type === 'MENU' ? 'type2 active' : 'type2';
  const typeClassName3 = type === 'SPOT' ? 'type3 active' : 'type3';

  return (
    <Container id="reserveStatus">
      <DateNavigation date={date} onChange={onValueChange} infinite />

      <div className="selectType">
        <div
          id="selectType"
          role="tab"
          tabIndex={0}
          className={typeClassName1}
          onClick={() => {
            searchData('COMPANY');
          }}
          onKeyUp={handlerKeyUp(() => {
            searchData('COMPANY');
          })}
        >
          고객사 별
        </div>
        <div
          id="selectType"
          role="tab"
          tabIndex={0}
          className={typeClassName2}
          onClick={() => {
            searchData('MENU');
          }}
          onKeyUp={handlerKeyUp(() => {
            searchData('MENU');
          })}
        >
          메뉴별
        </div>
        <div
          id="selectType"
          role="tab"
          tabIndex={0}
          className={typeClassName3}
          onClick={() => {
            searchData('SPOT');
          }}
          onKeyUp={handlerKeyUp(() => {
            searchData('SPOT');
          })}
        >
          배달지 별
        </div>
      </div>

      {type === 'COMPANY' && (
        <div>
          <Select onChange={changeCompany} value={companyId} className="company-select">
            <Option value="ALL">전체</Option>
            {companyList?.contents?.map(({ company: { id, name } }) => (
              <Option key={id} value={id}>
                {name}
              </Option>
            ))}
          </Select>
          <CompanyBy
            data={bookingByCompany}
            sid={selectedStore?.sid}
            bookingDate={bookingDate}
            onRefetch={refetch}
            companyId={companyId}
            diffArray={diffArray}
          />
        </div>
      )}
      {type === 'MENU' ? <MenuBy bookingDate={bookingDate} /> : null}
      {type === 'SPOT' ? <SpotBy bookingDate={bookingDate} /> : null}
      <PollingModal pollingArray={pollingArray} />
    </Container>
  );
};

export default ReserveStatus;
