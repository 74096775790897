/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useMeInfo } from 'hooks';
import React, { useEffect, useState } from 'react';
import ExcelResultModal from './ExcelResultModal';
import ExcelSuccessModal from './ExcelSuccessModal';

const ExcelUploadModal = ({ title, close, upload, uploadResult }) => {
  const { selectedStore } = useMeInfo();
  const [isSuccessOpen, setSuccessOpen] = useState<boolean>(false);

  useEffect(() => {
    $('#attachExcelFile').val('');
    $('#uploadExcelFile').val('');
  }, []);

  const clickFileUploadButton = (event) => {
    const fileId = $(event.currentTarget).data('fileid');

    $(fileId).click();
  };

  const changeFileUpload = (event) => {
    const file = $(event.currentTarget)[0].files[0];
    const filename = file ? file.name : '';
    const txtid = $(event.currentTarget).data('txtid');

    $(txtid).val(filename);
  };

  const handleOpenSuccess = () => {
    setSuccessOpen(true);
  };
  const handleCloseSuccess = () => {
    setSuccessOpen(false);
  };

  const setExcel = async () => {
    if (!selectedStore?.sid) {
      return;
    }

    if (($('#uploadExcelFile')[0] as any).files.length === 0) {
      alert('파일을 업로드해주세요');
      return;
    }

    const excelFile = ($('#uploadExcelFile')[0] as any).files[0];

    if (excelFile.size > 10485760) {
      alert('10MB 미만의 파일만 첨부 가능합니다.');
      return;
    }

    const params = {
      sid: selectedStore.sid,
      file: excelFile
    };

    const formData = new FormData();
    Object.entries(params).forEach(([k, v]) => {
      formData.append(k, v);
    });

    // eslint-disable-next-line no-restricted-globals
    if (confirm('업로드한 엑셀 파일로 일괄 등록 하시겠어요?')) {
      const data = await upload(formData);
      $('#excelDateModal').modal('hide');
      $('#attachExcelFile').val('');
      $('#uploadExcelFile').val('');
      if (data.failCount === 0) {
        // $('#excelSuccessModal').modal('show');
        handleOpenSuccess();
      } else {
        $('#excelResultModal').modal('show');
      }
    }
  };

  return (
    <>
      <div id="excelDateModal" className="modal fade" role="dialog">
        <div className="modal-dialog modal-lg">
          <div className="modal-content" id="test">
            <div className="modal-header">
              <h4 className="modal-title">{title}</h4>
            </div>
            <div className="modal-body">
              <div className="uploadInfo">
                <div className="uploadInfoMsg side-space">
                  <h5 className="font-wei-bold">
                    송장정보가 등록되지 않은 주문완료 상태인 배송정보를 한 번에 등록 할 수 있습니다.
                  </h5>
                  <h5 className="font-wei-bold">
                    양식에 맞지 않는 파일을 업로드한 경우 송장번호가 등록되지 않으니 주의해주세요.
                  </h5>
                </div>
              </div>
              <div className="excelFileZone">
                <div className="input-group input-file" onClick={clickFileUploadButton} data-fileid="#uploadExcelFile">
                  <input
                    type="text"
                    className="form-control"
                    id="attachExcelFile"
                    style={{ cursor: 'pointer' }}
                    placeholder="파일을 선택해주세요."
                    readOnly
                  />
                  <span className="input-group-btn">
                    <button className="btn btn-file" type="button">
                      업로드
                    </button>
                  </span>
                </div>
                <input
                  type="file"
                  className="form-control"
                  onChange={changeFileUpload}
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  multiple={false}
                  id="uploadExcelFile"
                  data-txtid="#attachExcelFile"
                  style={{ display: 'none' }}
                />
              </div>
              <div id="excel-upload-warning">
                <label className="excel-upload-label">.xlsx, .cvs 확장자인 엑셀 파일만 업로드 가능합니다.</label>
              </div>
              <div id="excel-upload-info">
                <ul>
                  <li>{`배송상품 주문관리 화면 우측상단 엑셀파일 다운로드 화면에서 '주문완료' 상태 선택 > 택배사 선택 > 즉시 다운로드를 이용하여 다운로드하세요.`}</li>
                  <li>송장번호, 택배사를 입력하여 저장한 후 업로드 하시면 일괄 등록됩니다.</li>
                </ul>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-cancel" data-dismiss="modal">
                취소
              </button>
              <button type="button" className="btn btn-sikdae" onClick={setExcel}>
                확인
              </button>
            </div>
          </div>
        </div>
      </div>
      <ExcelSuccessModal
        open={isSuccessOpen}
        close={handleCloseSuccess}
        successCount={uploadResult?.successCount}
        alreadySuccessCount={uploadResult?.alreadySuccessCount}
        returnFunc={close}
      />
      <ExcelResultModal
        title="일괄 등록 결과"
        failList={uploadResult?.failContents}
        successCount={uploadResult?.successCount}
        failCount={uploadResult?.failCount}
        failFile={uploadResult?.failFileName}
        alreadySuccessCount={uploadResult?.alreadySuccessCount}
        returnFunc={close}
      />
    </>
  );
};

export default ExcelUploadModal;
