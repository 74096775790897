import { SearchOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import React from 'react';
import styled from 'styled-components';

const StyledMenuForm = styled.div`
  display: flex;

  .menu-form-title {
    width: 53px;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.6;
    flex: none;
  }

  .search-button {
    width: 100px;
    flex: none;
  }
`;

// Warning: 전역적으로 적용된 style 속성 때문에 important 부여 (input:not([type="password"]).ant-input.ant-input-lg)
const StyledInput = styled(Input)`
  flex: 1 1 0%;
  height: 32px !important;
  margin-right: 12px;
  font-size: 16px !important;
`;

interface MenuFormProps {
  handleForm: () => void;
  value: string;
  setValue: (value: string) => void;
}

const MenuForm = ({ handleForm, value, setValue }: MenuFormProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: v } = e.target;
    setValue(v);
  };

  return (
    <StyledMenuForm>
      <span className="menu-form-title">메뉴</span>
      <StyledInput
        placeholder="예약할 메뉴를 입력해주세요"
        value={value}
        onChange={handleChange}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            handleForm();
          }
        }}
      />
      <Button onClick={handleForm} icon={<SearchOutlined />} className="search-button">
        검색
      </Button>
    </StyledMenuForm>
  );
};

export default MenuForm;
