import React, { useState } from 'react';
import { Radio, Input, Modal, RadioChangeEvent } from 'antd';
import styled from 'styled-components';
import DefaultModal from '../components/DefaultModal';

const ModalHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 46px 0 26px;
  font-size: 32px;
  font-weight: 700;
  .title {
    flex: 1;
    text-align: center;
  }
  .ant-btn.ant-btn-circle {
    width: 35px;
    height: 35px;
    padding: 0;
    border: none;
    background: none;
    svg {
      width: 35px;
      height: 35px;
    }
  }
`;

const ModalContentsWrapper = styled.div`
  padding 0 43px;
  .title {
    font-size: 24px;
    text-align: left;
    font-weight: 700;
    line-height: 36px;
  }
  .radio-group {
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 16px 0 16px 12px;
    .radio-button {
      gap: 8px;
      height: 24px;
      font-size: 16px;
      font-weight: 400;
    }
  }
  .input-box {
    width: 100%;
    height: 40px;
    padding: 12px 8px 12px 8px;
    margin: 12px 0 12px 0;
    border-radius: 8px
  }
  ul {
    padding-left: 24px;
  }
  .contents {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    color: var(--Grayscale-Gray02, #6d7582);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 16px 0 16px 0;
  }
  .ant-btn.ant-btn-circle {
    width: 35px;
    height: 35px;
    padding: 0;
    border: none;
    background: none;
    svg {
      width: 35px;
      height: 35px;
    }
  }
`;

interface OrderCancelModalProps {
  open: boolean;
  close: () => void;
  couponId: string;
  onConfirm: (cancelCouponId: string, cancelMessage: string) => void;
}

const OrderCancelModal = ({ open, close, couponId, onConfirm }: OrderCancelModalProps) => {
  const [orderCancelType, setOrderCancelType] = useState<string>('1');
  const [inputDisabled, setInputDisabled] = useState<boolean>(true);
  const [okButtonDisabled, setOkButtonDisabled] = useState<boolean>(false);
  const [cancelMessage, setCancelMessage] = useState<string>('재료소진');

  const resetState = () => {
    setOrderCancelType('1');
    setInputDisabled(true);
    setCancelMessage('재료소진');
  };

  const handleConfirmClick = () => {
    if (cancelMessage === undefined || cancelMessage === '') {
      Modal.info({
        content: `취소 사유를 입력해주세요.`,
        icon: null,
        okText: '확인',
        className: 'info-handler-modal'
      });
    } else {
      onConfirm(couponId, cancelMessage);
      resetState();
      close();
    }
  };

  const handleClose = () => {
    resetState();
    close();
  };

  const handleDetailChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const inputText = target.value;
    setCancelMessage(inputText);
    setOkButtonDisabled(inputText.trim() === '');
  };

  const cancelMessageObj = {
    '1': '재료소진',
    '2': '고객요청',
    '3': '가게사정',
    '4': '배달불가',
    '5': cancelMessage
  };

  const handleOrderCancelClick = ({ target }: RadioChangeEvent) => {
    setOrderCancelType(target.value);
    setInputDisabled(target.value !== '5');

    if (target.value === '5') {
      setCancelMessage('');
      setOkButtonDisabled(true);
    } else {
      setCancelMessage(cancelMessageObj[target.value]);
      setOkButtonDisabled(false);
    }
  };

  return (
    <DefaultModal
      open={open}
      onCancel={handleClose}
      onOk={handleConfirmClick}
      cancelText="닫기"
      isOkButtonDisabled={okButtonDisabled}
    >
      <ModalHeaderWrapper>
        <div className="title">
          <span style={{ color: '#FA503C' }}>주문 취소</span>
          <span> 사유를 선택해 주세요</span>
        </div>
      </ModalHeaderWrapper>
      <ModalContentsWrapper>
        <div className="title">주문 취소 사유</div>
        <Radio.Group className="radio-group" value={orderCancelType} onChange={handleOrderCancelClick}>
          <Radio className="radio-button" value="1">
            재료소진
          </Radio>
          <Radio className="radio-button" value="2">
            고객요청
          </Radio>
          <Radio className="radio-button" value="3">
            가게사정
          </Radio>
          <Radio className="radio-button" value="4">
            배달불가
          </Radio>
          <Radio className="radio-button" value="5">
            직접입력
          </Radio>
        </Radio.Group>
        <Input
          placeholder="주문 취소 사유를 입력해 주세요.최대 50자"
          maxLength={50}
          size="small"
          className="input-box"
          value={cancelMessage}
          onChange={handleDetailChange}
          disabled={inputDisabled}
        />
      </ModalContentsWrapper>
      <ModalContentsWrapper style={{ paddingBottom: '20px' }}>
        <div className="title" style={{ marginTop: '13px' }}>
          주문 취소 시 유의사항
        </div>
        <ul className="contents">
          <li>하나의 주문 건에 대해 전체 취소만 가능합니다. (부분 취소 불가)</li>
          <li>
            취소완료 처리된 주문 건은 다시 주문요청 상태로 변경할 수 없습니다. (실수로 취소한 경우, 사용자가 식권대장 앱에서
            재주문필요)
          </li>
          <li>
            취소완료 시, 사용자의 식권대장 앱 내에 주문 취소 일시와 사유가 노출되며 주문요청 상태에서 취소완료 상태로 변경됩니다.
          </li>
          <li>
            식대포인트/대장포인트는 즉시 환불됩니다. 단, 환불 시점에 식대포인트/대장포인트의 유효기간이 만료된 경우, 환불 즉시
            소멸됩니다.
          </li>
        </ul>
      </ModalContentsWrapper>
    </DefaultModal>
  );
};

export default OrderCancelModal;
