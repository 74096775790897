import { DownloadOutlined } from '@ant-design/icons';
import handleFileExport from '@jungsoo/excel-export';
import { Modal, Pagination } from 'antd';
import { useLogData, useMeInfo, useMenus } from 'hooks';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { generate } from 'shortid';

const KO_STATUS = { ACTIVE: '판매중', INACTIVE: '판매종료', SOLD_OUT: '일시품절' };

const StoreAuditLogButton = ({ location }) => {
  const [page, setPage] = useState<number>(0);
  const [isOpen, setOpen] = useState<boolean>(false);
  const { data = {}, invalidateQueries } = useLogData(page);

  const { selectedStore } = useMeInfo();
  const { data: menus } = useMenus(selectedStore?.sid);

  const exportExcelAboutMenus = useCallback(() => {
    const currentDateTime = moment();
    const fileName = `메뉴_리스트_${currentDateTime.format('YYYYMMDD_kkmmss')}`;
    const storeMenu = menus?.menu || [];

    if (storeMenu.length) {
      const excelDatas = [];
      const fieldLabels = ['메뉴고유번호', '메뉴명', '메뉴소개', '분류', '우선순위', '판매가격', '사용자결제금액', '판매상태'];

      storeMenu.forEach((menu) => {
        const { category, categorySeq, id, intro, name, price, sellPrice, status } = menu;
        excelDatas.push([id, name, intro, category, categorySeq, price, sellPrice, KO_STATUS[status]]);
      });

      handleFileExport({}, { menu: { newRows: [fieldLabels, ...excelDatas] } }, fileName);
    }
  }, [menus]);

  const close = () => {
    setOpen(false);
  };

  const open = () => {
    if (page === 0) {
      invalidateQueries();
    } else {
      setPage(0);
    }
    setOpen(true);
  };

  const getStatusType = (status) => {
    let text = '';
    if (status === 0) {
      text = '판매종료';
    } else if (status === 1) {
      text = '판매중';
    } else if (status === 2) {
      text = '일시품절';
    }
    return text;
  };

  if (location.pathname !== '/menus') {
    return null;
  }

  const onPage = (e) => {
    setPage(e - 1);
  };

  return (
    <>
      <div id="excelDownBtn">
        <button type="button" className="excel-download pull-right excelbtn" onClick={exportExcelAboutMenus}>
          <DownloadOutlined style={{ fontSize: 18, marginTop: 8, marginRight: 6 }} />
          엑셀파일 다운로드
        </button>
      </div>
      <div id="excelBtn">
        <button type="button" className="excel-download pull-right excelbtn" onClick={open}>
          판매 상태 변경 내역
        </button>
      </div>

      <Modal
        width={700}
        open={isOpen}
        title="판매 상태 변경 내역"
        onCancel={close}
        closable
        footer={
          <button type="button" className="btn btn-sikdae" onClick={close}>
            확인
          </button>
        }
      >
        <div className="text-center">
          <table className="table table-bordered menuTable">
            <thead className="menuHead">
              <tr>
                <th>#</th>
                <th>수정일</th>
                <th>메뉴</th>
                <th>변경 전</th>
                <th>변경 후</th>
                <th>변경한 사람</th>
              </tr>
            </thead>
            <tbody>
              {data?.logs?.map((item) => {
                const beforeData = JSON.parse(item.beforeData);
                const afterData = JSON.parse(item.afterData);
                const beforeStatus = getStatusType(beforeData.status);
                const afterStatus = getStatusType(afterData.status);
                const regDate = moment(item.regDate).format('YYYY-MM-DD HH:mm:ss');
                const { userName } = item;

                return (
                  <tr key={generate()}>
                    <td>{item.storeAuditLogIdx}</td>
                    <td>{regDate}</td>
                    <td>{afterData.menuname}</td>
                    <td>{beforeStatus}</td>
                    <td>{afterStatus}</td>
                    <td>{userName}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Pagination
            defaultCurrent={1}
            total={data?.totalDataCount || 1}
            onChange={onPage}
            showSizeChanger={false}
            current={page + 1}
          />
        </div>
      </Modal>
    </>
  );
};

export default StoreAuditLogButton;
