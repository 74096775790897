import React from 'react';
import { AppInfoForm } from 'components';
import errorHandler from 'apis/errorHandler';
import { useAdmissionTypes, useMeInfo, useSaveAppDisplayInfo, useSettlementMetaInfo } from 'hooks';

const AppDisplayInfo = () => {
  const { selectedStore } = useMeInfo();
  const { data: types } = useAdmissionTypes();
  const { data: metaInfo } = useSettlementMetaInfo(selectedStore?.sid || '');
  const { mutateAsync: saveAppDisplayInfo, isLoading: isAppDisplaySaving } = useSaveAppDisplayInfo();

  const saveAppInfo = async (values) => {
    if (!selectedStore?.sid || !values) {
      return;
    }
    try {
      const { storeName, storePhone, address, mainCategoryIdx } = values;
      const params = {
        storeId: selectedStore.sid,
        displayData: {
          storeName,
          storePhone,
          ...address,
          mainCategoryIdx
        }
      };
      await saveAppDisplayInfo(params);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <AppInfoForm
      initValue={metaInfo}
      onSubmit={saveAppInfo}
      isLoading={isAppDisplaySaving}
      mainCategories={types?.mainCategoryList}
    />
  );
};

export default AppDisplayInfo;
