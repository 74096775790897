import React, { useEffect, useState, useMemo } from 'react';
import { Button, Input } from 'antd';
import { ValidStatus } from 'interfaces/admission';
import { getValue } from 'utils';

type TValue = { value: string; status: ValidStatus };

interface ValidSearchProps {
  isSearching?: boolean;
  value?: TValue;
  onChange?: (e: TValue) => void;
  onSearch?: (v: string) => Promise<unknown>;
  onValid?: (v: Omit<ValidStatus, undefined>) => void;
  placeholder?: string;
  enterButton?: React.ReactNode;
  enterButtonText?: string;
  disabled?: boolean | ((v: string) => boolean);
  buttonDisabled?: boolean | ((v: string) => boolean);
}

const { Search } = Input;

const ValidSearch = ({
  isSearching,
  value,
  onChange,
  onSearch,
  onValid,
  placeholder,
  enterButton,
  enterButtonText,
  disabled,
  buttonDisabled
}: ValidSearchProps) => {
  const [handleValue, setHandleValue] = useState<string | undefined>();
  const [validStatus, setValidStatus] = useState<ValidStatus>();
  useEffect(() => {
    setHandleValue(getValue<TValue>(value, 'value'));
  }, [value]);

  const handleValid = (status: ValidStatus) => {
    setValidStatus(status);
    onValid?.(status);
  };

  const handleSearch = async (searchValue: string) => {
    if (!searchValue) {
      return;
    }
    const result = await onSearch(searchValue);
    if (result) {
      handleValid('success');
      onChange?.({ value: handleValue, status: 'success' });
      return;
    }
    handleValid('error');
    onChange?.({ value: handleValue, status: 'error' });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value: v }
    } = e;
    let status = validStatus;
    if (validStatus !== 'changed') {
      status = 'changed';
      handleValid('changed');
    }
    onChange?.({ value: v, status });
  };

  const isButtonDisabled = useMemo(() => {
    if (typeof buttonDisabled === 'boolean') return buttonDisabled;
    if (buttonDisabled) return buttonDisabled(handleValue);
    return validStatus !== 'changed';
  }, [buttonDisabled, handleValue, validStatus]);

  const isDisabled = useMemo(() => {
    if (typeof disabled === 'boolean') return disabled;
    if (disabled) return disabled(handleValue);
    return false;
  }, [disabled, handleValue]);

  return (
    <Search
      placeholder={placeholder}
      allowClear
      value={handleValue}
      disabled={isDisabled}
      enterButton={
        enterButton ?? (
          <Button type="primary" loading={isSearching} disabled={isButtonDisabled}>
            {validStatus === 'success' || validStatus === 'error' ? '완료' : enterButtonText}
          </Button>
        )
      }
      size="large"
      onSearch={handleSearch}
      onChange={handleChange}
    />
  );
};

export default ValidSearch;
