import { Button, Col, Collapse, Row } from 'antd';
import { TMenuContent } from 'interfaces/booking';
import React from 'react';
import styled from 'styled-components';
import { dateTimeToKorean, formatToHM, formatToYMD, formatToYMDHM, numberWithCommas } from 'utils';

interface ArtifactDetailProps {
  menuDet: { content: TMenuContent };
  handleDialog: () => void;
  menuDel: (v: string) => void;
  templateIdx: number;
  detailInfoIdx: number;
  allComponent?: React.ReactNode;
}

const RoundedBtn = styled(Button)`
  border-radius: 5px;
  float: right;
`;

const CustomStyleRow = styled(Row)`
  border: 1px solid #dcdcdc;
  color: #333;
  font-size: 13px;
  font-weight: 400;

  &:not(:first-child) {
    border-top: none;
  }
`;

const GridCol = styled(Col)`
  font-size: 13px;
  display: flex;
  align-items: center;
`;

const GridHeadCol = styled(GridCol)`
  border-right: 1px solid #dcdcdc;
  background-color: #eeeeee;
  padding: 16px;
`;

const GridBodyCol = styled(GridCol)`
  border-right: 1px solid #dcdcdc;
  background-color: #f9f9f9;
  padding: 16px;

  &:last-child {
    border: none;
  }
`;

const GridBodyMenuCol = styled(GridCol)`
  background-color: #f9f9f9;
`;

const MenuContainer = styled.div`
  width: 100%;

  .menu-wrapper {
    padding: 10px 25px;
    background-color: #f9f9f9;
    border-bottom: 1px solid #dcdcdc;
    height: 50px;

    span: {
      line-height: 2;
    }
  }

  .menu-item {
    height: 50px;
    padding: 0 25px 0 35px;
    border-bottom: 1px solid #dcdcdc;

    img {
      float: left;
    }

    .menu-text {
      height: 100%;
      padding: 15px;
      width: 20%;
      float: left;
    }

    .menu-price {
      height: 100%;
      width: 50%;
      padding: 15px;
      text-align: right;
      float: left;
    }

    .menu-btn {
      padding: 10px 0;
    }
  }
`;

const { Panel } = Collapse;

const CustomPanel = styled(Panel)`
  .ant-collapse-header {
    padding: 0 !important;
  }

  .ant-collapse-content-box {
    padding: 0 !important;
  }
`;

const ArtifactDetail = ({
  menuDet,
  handleDialog,
  menuDel,
  templateIdx,
  detailInfoIdx,
  allComponent: AllComponent
}: ArtifactDetailProps) => {
  if (!menuDet) return <Collapse />;
  const { content } = menuDet;
  const { date, company, isCancel, menus, limitCount, isPayment, status } = content;
  const btnIsDim = status !== 'DRAFT';

  return (
    <Collapse accordion bordered={false} activeKey={detailInfoIdx}>
      <CustomPanel header="" key={templateIdx} showArrow={false}>
        {!AllComponent ? (
          <>
            <CustomStyleRow>
              <GridHeadCol span={4}>식사일</GridHeadCol>
              <GridBodyCol span={8}>{formatToYMD(date.booking)}</GridBodyCol>
              <GridHeadCol span={4}>고객사</GridHeadCol>
              <GridBodyCol span={8}>{company.name}</GridBodyCol>
            </CustomStyleRow>
            <CustomStyleRow>
              <GridHeadCol span={4}>고객사 식사 구분</GridHeadCol>
              <GridBodyCol span={8}>{company.bookingGroup}</GridBodyCol>
              <GridHeadCol span={4}>식사시간</GridHeadCol>
              <GridBodyCol span={8}>{formatToHM(date.service)}</GridBodyCol>
            </CustomStyleRow>
            <CustomStyleRow>
              <GridHeadCol span={4}>예약 가능시간</GridHeadCol>
              <GridBodyCol span={8}>{`${formatToYMDHM(date.open)} - ${formatToYMDHM(date.close)}`}</GridBodyCol>
              <GridHeadCol span={4}>예약 취소 설정</GridHeadCol>
              <GridBodyCol span={8}>{isCancel ? '예약 취소가능' : '예역 취소불가능'}</GridBodyCol>
            </CustomStyleRow>
            <CustomStyleRow>
              <GridHeadCol span={4}>최대 수량</GridHeadCol>
              <GridBodyCol span={8}>{limitCount === 0 ? '제한 없음' : `${limitCount}건`}</GridBodyCol>
              <GridHeadCol span={4}>예약 취소 가능 시간</GridHeadCol>
              <GridBodyCol span={8}>- {formatToYMDHM(date.cancel)}</GridBodyCol>
            </CustomStyleRow>
            <CustomStyleRow>
              <GridHeadCol span={4}>결제 설정</GridHeadCol>
              <GridBodyCol span={8}>{isPayment ? '예약/결제' : '예약'}</GridBodyCol>
              <GridHeadCol span={4}>예약 공개 설정</GridHeadCol>
              <GridBodyCol span={8}>식사시간 {dateTimeToKorean(date.release)} 전 자동 공개</GridBodyCol>
            </CustomStyleRow>
          </>
        ) : null}
        <CustomStyleRow>
          <GridHeadCol span={4}>
            <span>메뉴</span>
          </GridHeadCol>
          <GridBodyMenuCol span={20}>
            {AllComponent ? (
              React.cloneElement(AllComponent as React.ReactElement, {
                btnIsDim,
                menus,
                handleDialog,
                menuDel,
                menuAddBtn: RoundedBtn,
                menuDelBtn: RoundedBtn
              })
            ) : (
              <MenuContainer>
                <div className="menu-wrapper">
                  <span>{menus ? numberWithCommas(menus.length) : '0'}개</span>
                  {!btnIsDim ? (
                    <RoundedBtn onClick={handleDialog} type="primary" size="middle">
                      메뉴등록
                    </RoundedBtn>
                  ) : null}
                </div>
                {menus
                  ? menus.map((menu) => (
                      <div className="menu-item" key={menu.id}>
                        {menu.image.thumb ? (
                          <img
                            src={menu.image.thumb}
                            alt="Smiley face"
                            height="49"
                            width="50"
                            onError={(e: React.ChangeEvent<HTMLImageElement>) => {
                              e.target.onerror = null;
                              e.target.src = ''; // tray;
                            }}
                          />
                        ) : (
                          <img src="" alt="Smiley face" height="49" width="50" />
                        )}
                        <div className="menu-text">
                          <span>{menu.name}</span>
                        </div>
                        <div className="menu-price">
                          <span>{numberWithCommas(menu.price)}</span>
                        </div>
                        <div className="menu-btn">
                          <RoundedBtn disabled={btnIsDim} onClick={() => menuDel(menu.id)} size="middle" danger>
                            삭제
                          </RoundedBtn>
                        </div>
                      </div>
                    ))
                  : null}
              </MenuContainer>
            )}
          </GridBodyMenuCol>
        </CustomStyleRow>
      </CustomPanel>
    </Collapse>
  );
};

export default ArtifactDetail;
