import React, { useCallback, useMemo } from 'react';

import { getStoreHost } from 'apis/host';
import { useInfiniteExcelStorage } from 'hooks';
import moment from 'moment';
import { debounce, handlerKeyUp } from 'utils/eventUtils';
import { getToken } from 'utils/storages';

const ExcelStorage = () => {
  const { data: excelStorage, fetchNextPage, invalidateQueries, hasNextPage } = useInfiniteExcelStorage();

  const morePaging = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  const debouncedDownload = debounce((token, fileId, type, regDate) => {
    const fileName = `${type}_${regDate}`;
    const href = `${getStoreHost()}${encodeURI(`/excel/v1/down?access_token=${token}&fileId=${fileId}&fileName=${fileName}`)}`;

    window.location.href = href;
    invalidateQueries();
  }, 200);

  const downloadExcel = useCallback(
    (excel) => {
      const token = getToken();
      const { fileId } = excel;

      const regDate = moment(excel.requestDate * 1000).format('YYYY-MM-DD_HH:mm:ss');
      let type = '';
      if (excel.contentType === 'MC') type = '실시간 매출';
      else if (excel.contentType === 'C') type = '제휴점정산내역';
      else if (excel.contentType === 'SS') type = '제휴점별매출통계';
      else if (excel.contentType === 'MS') type = '매출통계';
      else if (excel.contentType === 'BH') type = '예약현황';
      else if (excel.contentType === 'BS') type = '예약통계';
      else if (excel.contentType === 'SP') type = '배송상품 주문관리';
      else if (excel.contentType === 'ST') type = '입금정보';
      else if (excel.contentType === 'SV') type = '부가가치세정보';
      else if (excel.contentType === 'SD') type = '부가가치세신고참고자료';
      else if (excel.contentType === 'SCSR') type = '매출통계_결제수단별';
      debouncedDownload(token, fileId, type, regDate);
    },
    [debouncedDownload]
  );

  const rows: React.ReactNode = useMemo(() => {
    if (!excelStorage?.pages || excelStorage.pages.length === 0) {
      return null;
    }
    return excelStorage.pages.map((page: any) => (
      <React.Fragment key={page.nextId}>
        {page.data.map((excel) => {
          const reqDate = moment(excel.requestDate * 1000).format('YYYY. M. DD. / HH:mm:ss');
          let makedate = moment(excel.makeDate * 1000).format('YYYY. M. DD. / HH:mm:ss');
          let type;
          let requestType;

          if (excel.contentType === 'MC') type = '실시간 매출';
          else if (excel.contentType === 'C') type = '제휴점정산내역';
          else if (excel.contentType === 'SS') type = '제휴점별매출통계';
          else if (excel.contentType === 'MS') type = '매출통계';
          else if (excel.contentType === 'BH') type = '예약현황';
          else if (excel.contentType === 'BS') type = '예약통계';
          else if (excel.contentType === 'SP') type = '배송상품 주문관리';
          else if (excel.contentType === 'ST') type = '입금정보';
          else if (excel.contentType === 'SV') type = '부가가치세정보';
          else if (excel.contentType === 'SD') type = '부가가치세신고참고자료';
          else if (excel.contentType === 'SCSR') type = '매출통계_결제수단별';

          if (excel.requestType === 'S') requestType = '제휴점';
          else if (excel.requestType === 'SA') requestType = '제휴점관리자';

          let statusLink: React.ReactNode;
          if (excel.status === 0) {
            statusLink = <td>삭제됨</td>;
            makedate = '';
          } else if (excel.status === 2) {
            statusLink = <td>생성중</td>;
            makedate = '';
          } else if (excel.status === 4) {
            statusLink = <td>생성실패</td>;
            makedate = '';
          } else if (excel.status === 99) {
            statusLink = <td>삭제됨</td>;
            makedate = '';
          } else if (excel.status === 5) {
            statusLink = (
              <td
                role="gridcell"
                onClick={() => downloadExcel(excel)}
                className="downLink"
                onKeyUp={handlerKeyUp(() => downloadExcel(excel))}
              >
                다운로드
              </td>
            );
          }

          return (
            <tr className="infoTr" key={excel.fileId}>
              <td>{excel.name}</td>
              <td>{reqDate}</td>
              <td>{makedate}</td>
              <td>{type}</td>
              <td>{requestType}</td>
              <td>없음</td>
              <td>{excel.downCount}</td>
              {statusLink}
            </tr>
          );
        })}
      </React.Fragment>
    ));
  }, [excelStorage, downloadExcel]);

  return (
    <div className="excel-content">
      <table className="excel-table">
        <thead>
          <tr className="excel-table-pc-tr">
            <th>요청자</th>
            <th>요청일</th>
            <th>생성일</th>
            <th>요청자료</th>
            <th>요청권한</th>
            <th>조건</th>
            <th>다운로드횟수</th>
            <th>상태</th>
          </tr>
        </thead>
        <tbody>
          {rows}
          {/* {moreTab} */}
          {hasNextPage ? (
            <tr className="oldcalc-morepc" onClick={morePaging}>
              <td colSpan={8}>더보기</td>
            </tr>
          ) : null}
        </tbody>
      </table>
    </div>
  );
};

export default ExcelStorage;
