import { Button } from 'antd';
import moment from 'moment';
import React, { useEffect } from 'react';
import { generate } from 'shortid';
import { telWithHyphen } from 'utils/stringUtils';
import { DeliveryModal, Table } from '../styles';

interface BookingDetailModalProps {
  open: boolean;
  close?: () => void;
  data?;
}

interface IMenu {
  payDate: Date;
}

const BookingDetailModal = ({ open, close, data }: BookingDetailModalProps) => {
  useEffect(() => {
    const scroll = document.getElementsByClassName('ant-modal-body');

    if (scroll.length > 0) {
      for (let i = 0; i < scroll.length; i += 1) {
        setTimeout(() => scroll[i].scrollTo(0, 0), 100);
      }
    }
  }, [open]);

  const { delivery, coupon, menus, deliveryMsgOn } = data;
  const stringmember = coupon?.couponType === 'SINGLE' ? '혼자결제' : '함께결제';

  const paymentTypes = {
    COMPLETE: { color: '#4c9aff', status: '결제완료' },
    CANCEL: { color: '#ff562f', status: '결제취소' },
    COMPLETE_PART_CANCEL: { color: '#ff991f', status: '결제완료' }
  };

  let totalPrice = 0;
  menus?.forEach((item) => {
    totalPrice += item.price;
  });
  const menuList = menus?.sort((a: IMenu, b: IMenu) => new Date(b.payDate).getTime() - new Date(a.payDate).getTime());

  return (
    <DeliveryModal
      visible={open}
      title="예약 상세 정보"
      onCancel={close}
      width={800}
      closable={false}
      footer={[
        <Button key="DeliveryInfoClose" onClick={close} ghost>
          확인
        </Button>
      ]}
    >
      <div>
        <div className="title">결제 정보</div>
        <Table>
          <tbody className="deliveryInfo">
            <tr>
              <td className="header col-sm-4">결제번호</td>
              <td className="content col-sm-4">
                <div>{coupon?.payRoomIdx}</div>
              </td>

              <td className="header col-sm-4">상태</td>
              <td className="content col-sm-4" style={{ color: coupon && paymentTypes[coupon?.paymentStatus.paymentType].color }}>
                <div>{coupon && paymentTypes[coupon?.paymentStatus.paymentType].status}</div>
              </td>
            </tr>

            <tr>
              <td className="header col-sm-4">결제자</td>
              <td className="content col-sm-4">
                <div>{coupon?.leaderName}</div>
              </td>

              <td className="header col-sm-4">사용형태</td>
              <td className="content col-sm-4">
                <div>{stringmember}</div>
              </td>
            </tr>

            <tr>
              <td className="header col-sm-4">결제일시</td>
              <td className="content col-sm-4">
                <div>{coupon?.useDate ? moment(coupon.useDate * 1000).format('YYYY-MM-DD HH:mm:ss') : null}</div>
              </td>

              <td className="header col-sm-4">취소일시</td>
              <td className="content col-sm-4">
                <div>{delivery?.cancelDate ? moment(delivery?.cancelDate).format('YYYY-MM-DD HH:mm:ss') : '-'}</div>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>

      <div>
        <div className="title padding">배달 정보</div>
        <Table>
          <tbody className="deliveryInfo">
            <tr>
              <td className="header">고객사</td>
              <td className="content">
                <div>{delivery?.companyName}</div>
              </td>
            </tr>
            <tr>
              <td className="header">배달지 별명</td>
              <td className="content">
                <div>{delivery?.spotName}</div>
                <div>
                  도로명 주소 : {delivery?.roadAddress} {delivery?.addressDetail}
                </div>
                <div>
                  지번 주소 : {delivery?.jibunAddress} {delivery?.addressDetail}
                </div>
              </td>
            </tr>
            {deliveryMsgOn ? (
              <tr>
                <td className="header">배달요청사항</td>
                <td className="content">{delivery?.deliveryRequirementMemo}</td>
              </tr>
            ) : null}
            <tr>
              <td className="header">받는 사람</td>
              <td className="content">
                <div>{coupon?.recipient}</div>
              </td>
            </tr>
            <tr>
              <td className="header">연락처</td>
              <td className="content">
                <div>{telWithHyphen(delivery?.tel)}</div>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>

      <div>
        <div className="title padding">메뉴/상품 금액</div>
        <Table>
          <thead className="deliveryInfo">
            <tr className="header">
              <th>메뉴/상품명</th>
              <th>수량</th>
              <th>결제금액</th>
            </tr>
          </thead>
          <tbody>
            {menuList?.map(({ menuName, count, price }) => (
              <tr key={generate()}>
                <td style={{ textAlign: 'center' }}>{menuName}</td>
                <td style={{ textAlign: 'center' }}>{count}</td>
                <td style={{ textAlign: 'center' }}>{price.toLocaleString()}</td>
              </tr>
            ))}
            <tr>
              <td style={{ textAlign: 'center' }} className="total-content" colSpan={2}>
                확정 금액
              </td>
              <td style={{ textAlign: 'center' }} className="total-content">
                {totalPrice.toLocaleString()}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </DeliveryModal>
  );
};

export default BookingDetailModal;
