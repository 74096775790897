import React from 'react';
import styled from 'styled-components';
import { FlexBoxFullWidthCenter } from 'styles';

const NumberCircle = styled.div`
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  width: 26px;
  height: 26px;
  border-radius: 26px;
  line-height: 26px;
  color: ${({ theme }) => theme.color.darkGreen};
  background-color: ${({ theme }) => theme.color.lightGreen};
  margin-right: 8px;
  &:only-child {
    align-self: center;
  }
`;

const NumberCircleWrapper = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: ${({ theme }) => theme.color.gray02};
  display: flex;
  align-items: center;
  margin-bottom: 26px;
`;

const DescriptionWrapper = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.primary};
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 70px;
`;

const AgreeContainer = () => {
  return (
    <div>
      <FlexBoxFullWidthCenter direction="column" align="flex-start">
        <NumberCircleWrapper>
          <NumberCircle>1</NumberCircle>사업자 등록증 사진
        </NumberCircleWrapper>
        <NumberCircleWrapper>
          <NumberCircle>2</NumberCircle>통장사본 사진
        </NumberCircleWrapper>
        <DescriptionWrapper>
          <div>법인 회원은 채팅 문의를 통해 가입하실 수 있습니다.</div>
          <div>하단의 법인 버튼을 누른 후 채팅 문의하기 해주세요.</div>
        </DescriptionWrapper>
      </FlexBoxFullWidthCenter>
    </div>
  );
};

export default AgreeContainer;
