import styled from 'styled-components';
import { Modal } from 'antd';
import { deleteLine } from './image';

export const Container = styled.div`
  & {
    .company-select {
      min-width: 200px;
      margin: 10px 0px 20px;
    }

    .company-select .ant-select-selector,
    .company-select .ant-select-selector input {
      height: 40px !important;
    }

    .company-select span.ant-select-selection-item {
      line-height: 42px !important;
    }

    .selectType {
      background-color: white;
      height: 50px;
      border-top: 1px solid #dcdcdc;
    }

    .selectType .type1,
    .selectType .type2,
    .selectType .type3,
    .selectType .type4 {
      cursor: pointer;
      width: 33.33%;
      height: 50px;
      line-height: 50px;
      text-align: center;
      font-size: 18px;
      display: inline-block;
    }

    .selectType .active {
      color: #46b476;
      background-color: white;
      font-weight: bold;
      border-bottom: 3px solid #46b476 !important;
    }

    .selectType .type1 {
      border-left: 1px solid #dcdcdc;
      border-bottom: 1px solid #dcdcdc;
    }

    .selectType .type1 .active {
      border: 0px solid transparent;
    }
    .selectType .type2 .active {
      border: 0px solid transparent;
    }
    .selectType .type3 .active {
      border: 0px solid transparent;
    }

    .selectType .type2,
    .selectType .type3 {
      border-left: 1px solid #dcdcdc;
      border-bottom: 1px solid #dcdcdc;
    }

    .selectType .type3 {
      border-right: 1px solid #dcdcdc;
      width: 33.33%;
    }
  }
`;

export const BookingContainer = styled.div`
  & {
    .header {
      display: flex;
      align-items: center;
      line-height: 26px;
      font-weight: 700;
      margin-bottom: 32px;
    }

    .header .bookingStoreName {
      font-size: 28px;
      padding-right: 40px;
    }

    .header button {
      border-radius: 5px;
      width: 180px;
      height: 40px;
      font-size: 16px;
    }

    .pushBtn {
      background: #333333;
      color: #fff;
    }
    .pushBtn:hover,
    .pushBtn:focus {
      border-color: #333;
      color: #fff;
    }

    .allExpand {
      background: none;
      border: none;
      box-shadow: none;
      font-size: 18px;
      line-height: 30px;
      font-weight: 600;
      color: #6d7582 !important;
      right: 0;
      position: absolute;
    }

    .deliveryStatus {
      margin: 19px 0px;
      border: 1px solid #d7d8dc;
      display: grid;
      justify-content: center;
      padding: 14px 0px;
      background: #fff;
    }
  }
`;

export const BookingRow = styled.div`
  display: flex;
  padding: 24px 16px;
  margin-bottom: 32px;
  background: #fff;

  & {
    span.anticon.anticon-right.ant-collapse-arrow {
      background: #fff;
      border-radius: 50%;
      padding: 15px;

      svg {
        font-size: 18px;
      }
    }

    .ant-collapse-item [aria-expanded='false'] {
      svg {
        transform: rotate(90deg) !important;
      }
    }

    .ant-collapse-item [aria-expanded='true'] {
      svg {
        transform: rotate(-90deg) !important;
      }
    }

    .ant-collapse-header {
      align-items: center !important;
    }

    .ant-collapse-content {
      background: #f7f7f7;
    }

    .title {
      font-weight: 700;
      font-size: 16px;
    }

    .ant-divider {
      margin: 0 12px;
    }

    .deliveries .grid {
      width: 92px !important;
    }

    .bookingCollapse .header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin: 0;
    }

    .companyName {
      width: 240px;
      height: fit-content;
      font-weight: 700;
      padding-right: 16px;
      justify-content: center;
      min-height: 80px;
      display: flex;
      align-items: center;
      font-size: 24px;
    }

    .spotGroup {
      width: 100%;
    }

    .bookingCollapse {
      width: 100%;
      margin-bottom: 24px;
    }
    .bookingCollapse:last-child {
      margin: 0;
    }

    .bookingCollapse .bookingInfos {
      width: 50%;
      justify-content: space-between;
      display: flex;
      align-items: center;
      text-align: center;

      .agentCallBtn {
        margin-right: 32px;
        width: 150px;
        font-size: 20px;
        height: 56px;
        color: #fff;
      }

      .ant-btn[disabled] {
        color: #fff !important;
        background: #dbdbdb !important;
      }
    }

    .bookingCollapse .bookingInfos .grid {
      // width: 85px !important;
      width: max-content;
      margin-right: 16px;
    }

    .bookingCollapse .spotName {
      width: 120px;
      // margin: 0px 16px;
      font-weight: 700;
      font-size: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #4bb378;
    }

    & .desc {
      // margin: 0px 16px;
      font-weight: 500;
      font-size: 18px;
      color: #6d7582;
      text-align: center;
    }

    .ant-btn {
      width: 204px;
      height: 56px;
      background: #4bb378;
      border-radius: 5px;
      border: none;
    }

    .deliveries {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 50%;
    }

    .deliveries .desc {
      margin: 0;
      width: max-content;
    }

    .deliveries .full {
      width: 100%;
    }

    @media (max-width: 1400px) {
      .deliveries .desc,
      .deliveries .media {
        display: grid;
      }
    }

    .bookedCount .desc {
      min-width: 74px;
      width: 72px;
      span {
        display: grid;
      }
    }

    ul,
    li {
      margin: auto;
      font-size: 18px;
      font-weight: 500;
      color: #6d7582;
      min-width: 56px;
    }
  }
`;

export const BookingTimeInfoContainer = styled.div`
  & {
    .title {
      font-size: 18px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .pointer {
      cursor: pointer;
    }

    .spotName {
      color: #337ab7;
      cursor: pointer;
    }

    .spotName:hover {
      text-decoration: underline;
    }

    .pushBtn {
      border-radius: 5px;
      width: 180px !important;
      height: 40px !important;
      font-size: 16px !important;
      margin-left: 10px;
    }

    .bookingCollapse {
      width: 100%;
    }

    .cancelRow {
      color: #dcdcdc;
      background-image: url(${deleteLine});
      background-size: contain;

      .spotName {
        color: #ff562f;
      }
    }

    .succeed {
      color: #4c9aff;
      font-weight: bold;
    }

    .cenceled {
      color: #ffc400;
      font-weight: bold;
    }

    .ant-collapse-header {
      align-items: center !important;
    }

    .ant-collapse-item [aria-expanded='false'] {
      svg {
        transform: rotate(90deg) !important;
      }
    }

    .ant-collapse-item [aria-expanded='true'] {
      svg {
        transform: rotate(-90deg) !important;
      }
    }

    span.anticon.anticon-right.ant-collapse-arrow {
      background: #fff;
      border-radius: 50%;
      padding: 15px;

      svg {
        font-size: 18px;
      }
    }
  }
`;

export const AgentsCallContainer = styled.div`
  font-family: 'Pretendard';
  font-style: normal;

  .menuCheck-handler-modal {
    .ant-modal-content {
      border-radius: 10px;
    }

    .ant-modal-confirm-btns,
    .ant-modal-confirm-body {
      padding: 20px;
    }

    .ant-modal-confirm-btns {
      border-top: 1px solid #dcdcdc;
    }
  }

  .ant-btn-lg {
    padding: 8.5px 15px;
  }

  & {
    .title {
      font-weight: 700;
      font-size: 20px;
      line-height: 34px;
      padding-bottom: 4px;
      color: #333;
    }

    .title span {
      color: #4bb378;
    }

    .desc {
      font-size: 14px;
      line-height: 28px;
      color: #6d7582;
      padding-bottom: 4px;
    }

    .borderBox {
      border: 1px solid #d7d8dc;
      border-radius: 5px;
      margin-bottom: 40px;
      padding: 17px 24px;
      font-size: 18px;
    }

    .info .companyInfo {
      padding-bottom: 20px;
      border-bottom: solid 1px #f3ecec;
      margin-bottom: 20px;
    }

    .info .serviceTime {
      padding: 4px 10px 2px 10px;
      float: right;
      background: #fad9d9;
      border-radius: 5px;
      font-weight: 700;
      color: #e82d03;
      font-size: 20px;
    }

    .info .addressGroup {
      padding-bottom: 12px;
    }

    .info .addressDetail {
      background: #f2f4f6;
      border-radius: 4px;
      color: #6d7582;
      font-weight: 700;
      font-size: 18px;
      padding: 4px 10px;
      margin-left: 8px;
    }

    .info .totalAmount {
      font-weight: 700;
      margin-left: 16px;
      padding-left: 16px;
      border-left: solid 1px #e2e6e9;
      font-size: 20px;
    }

    .pickupDate .borderBox {
      padding: 21px 46px;
      text-align: center;
      max-height: 156px;
      font-weight: 700;
    }

    .pickupDate .hour {
      display: flex;
      justify-content: space-between;
      padding-bottom: 24px;
    }

    .hour-select .ant-select-selector,
    .hour-select .ant-select-selector input {
      height: 40px !important;
    }

    .hour-select span.ant-select-selection-item {
      line-height: 38px !important;
    }

    .pickupDate .ant-btn[disabled] {
      color: #d7d8dc;
      background: #fff;
    }

    .pickupDate .ant-select {
      width: 100%;
      text-align: left;

      .ant-select-selector {
        padding: 2px 11px;
      }
    }

    .riderCnt .borderBox {
      display: flex;
      justify-content: space-between;
      padding: 24px 190px;
    }

    .pickupDate button,
    .riderCnt button {
      color: #4bb378;
      border: none;
      border-radius: 50%;
      font-size: 16px;
      font-weight: 700;
      line-height: 37px;
      width: 50px;
      height: 50px;
      box-shadow: none;
    }

    .pickupDate .focus,
    .riderCnt .focus {
      background: #ecf7f1;
    }

    .quantity .guide {
      font-size: 18px;
      line-height: 28px;
      color: #afb2b9;
      padding-bottom: 16px;
      font-family: 'Pretendard';
    }

    .quantity .quantityGroup {
      display: flex;
      justify-content: space-between;
    }

    .cellPhone {
      padding-top: 8px;
    }

    .cellPhone input {
      padding: 22px 8px 18.5px 16px;
      max-height: 55px;
    }

    .cellPhone .ant-input-suffix {
      margin-top: 5px;
    }

    .cellPhone .ant-input-affix-wrapper {
      padding: 0;
      padding-right: 15px;
    }

    .cellPhone {
      input[type='number']::-webkit-inner-spin-button,
      input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      .ant-input-affix-wrapper input.ant-input.ant-input-lg {
        padding: 22px 8px 18.5px 16px;
        height: 53px;
      }
    }

    .fareBox {
      margin: 40px 0;
      display: flex;

      button {
        width: 165px;
        margin-left: 20px;
      }
    }

    .fare {
      width: 100%;
      height: 42px;
      background: #f3f5f6;
      border-radius: 5px;
      padding: 9px 17px;
      font-size: 16px;
      line-height: 28px;

      svg {
        margin-right: 8px;
      }

      .bold {
        font-weight: 700;
      }
    }
  }
`;

export const Quantity = styled.div`
  width: 148px;
  margin-bottom: 40px;

  & {
    .ant-input {
      border: none;
      background: #fff;
    }

    button {
      border: none;
    }

    button span {
      width: auto;
    }

    .aria {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #d7d8dc;
      border-radius: 5px;
      font-size: 18px;
    }

    [aria-disabled='true'] {
      opacity: 0.5;
      pointer-events: none;

      .modifier {
        opacity: 0.4;
      }

      .modifier,
      .screen {
        color: #d7d8dc;
        filter: grayscale(100%);
      }
    }

    .total {
      font-weight: 700;
      font-size: 16px;
      text-align: end;
      color: #6d7582;
      padding-top: 4px;
    }

    .modifier {
      padding: 0.7rem;
      text-align: center;
      cursor: pointer;
      width: 65px;
      height: 65px;
      color: #4bb378;
    }

    .left {
      font-weight: 600;
    }

    .right {
      font-weight: bold;
    }

    .screen {
      text-align: center;
      padding: 0px !important;
      padding-top: 3px !important;
    }
  }
`;

export const StatusContainer = styled.div`
  display: flex;
  padding: 10px 0px;

  button.ant-btn.ant-btn-default.ant-btn-lg {
    width: 128px;
    height: 36px;
    font-size: 16px;
    border: none;
    border-radius: 38px;
    margin-left: 17px;
    display: flex;
    align-items: center;
    padding: 0px 10px;
  }

  & .dot {
    font-size: 18px;
    margin-right: 6px;
    padding-bottom: 2px;
    width: 5px;
  }

  & .arrow {
    color: #333;
    font-size: 14px;
    margin-left: 2px !important;
    line-height: 0;
  }

  & .number {
    color: #afb2b9;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    padding-right: 16px;
  }

  & .status {
    width: 60px;
  }

  & .phone {
    width: 45px;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    font-family: 'Pretendard';
  }

  & .quantity {
    background: #fff;
    border-radius: 50%;
    min-width: 20px;
    height: 20px;
    align-items: center;
    margin: auto;
    display: flex;
    justify-content: center;
    margin-left: 4px;
  }
`;

export const PollingContainer = styled.div`
  & {
    .container {
      width: 428px;
      z-index: 100000000 !important;
      max-height: 710px;
      background: #fff;
      position: fixed;
      right: 20px;
      bottom: 20px;
      z-index: 1;
      padding: 20px 10px 0px;
      -webkit-animation: fadeinout 4s linear forwards;
      animation: fadeinout 4s linear forwards;
      opacity: 0;
      visibility: hidden;

      border: 1px solid #d7d8dc;
      box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
    }

    .clearAlarm {
      font-weight: 700 !important;
      font-size: 14px !important;
      line-height: 28px;
      color: #afb2b9 !important;
      border-top: solid 1px #f2f4f6;
      width: 100%;
      height: 48px;
    }

    .header {
      // text-align: end;
      display: flex;
      justify-content: space-between;
      padding: 0px 10px 5px;
      align-items: baseline;
      border-bottom: solid 1px #f2f4f6;
    }

    .header .flex {
      display: flex;
      align-items: center;
    }

    .header .close-polling {
      font-size: 16px;
    }

    .header span {
      font-weight: 700;
      font-size: 14px;
      color: #6d7582;
      padding-right: 8px;
    }

    .header .ant-switch-checked .ant-switch-handle {
      left: calc(100% - 16px - 2px);
    }

    .header .ant-switch {
      width: 42px;
      height: 20px;

      .ant-switch-handle {
        width: 16px;
        height: 16px;
      }
    }

    .alarm-content {
      overflow-y: scroll;
      max-height: 600px;
    }

    .alarm {
      display: flex;
      padding: 20px 16px;
      border-bottom: solid 1px #f2f4f6;
      justify-content: space-between;
    }

    .alarm .info {
      display: flex;
    }

    .alarm .icon {
      margin: 4px 16px 0px 0px;
      width: 24px;
      height: 24px;
    }

    .alarm .title {
      font-weight: 700;
      font-size: 18px;
      line-height: 30px;
    }

    .alarm .desc {
      font-weight: 400;
      font-size: 14px;
      color: #6d7582;
      font-family: 'Pretendard';
    }

    .fade-in {
      animation: fadeIn ease-in 1;
      animation-fill-mode: forwards;
      animation-duration: 0.4s;
      visibility: visible;
    }

    .fade-out {
      animation: fadeOut ease-in 1;
      animation-fill-mode: forwards;
      animation-duration: 0.4s;
      pointer-events: none;
      visibility: hidden;
    }

    @keyframes fadeIn {
      to {
        opacity: 1;
      }
    }

    @keyframes fadeOut {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }
  }
`;

const cancelType = {
  CANCELED: '배달취소',
  OWNER_CANCELED: '호출취소',
  ASSIGN_FAILED: '배차실패',
  SUBMIT_FAILED: '배차실패'
};

export const RiderContainer = styled.div<{ status: string }>`
  & {
    .ant-slider-rail {
      background-color: ${(props) => (props.status === 'DELIVERED' ? '#4BB378' : '#f5f5f5')};
    }

    .ant-slider-track {
      display: ${(props) => (cancelType[props.status] ? 'none' : 'block')};
    }

    .ant-slider-handle {
      display: ${(props) => (cancelType[props.status] ? 'none' : 'block')};
    }

    .title {
      font-weight: 700;
      font-size: 20px;
      line-height: 34px;
      padding-bottom: 8px;
    }

    .title .primary {
      color: #4bb378;
    }

    .title .danger {
      color: #ff5630;
    }

    .borderBox {
      border: 1px solid #d7d8dc;
      border-radius: 5px;
      margin-bottom: 40px;
      padding: 17px 24px;
      font-size: 18px;
    }

    .ant-slider {
      margin: 44px 60px 14px;
    }

    .ant-slider-track {
      background-color: #4bb378;
    }

    .ant-slider-handle {
      background: rgb(75, 179, 120);
      border-radius: 50%;
      width: 12px;
      height: 12px;
      margin-top: -4px;
      border: none;
      box-shadow: rgb(0 123 54) 0px 0px 24px;
    }

    .colSpan {
      text-align: center;
    }

    .imageGroup {
      text-align: center;
      padding: 0px 44px 60px;
      display: flex;
      justify-content: space-around;
      font-family: 'Pretendard';
    }

    .imageGroup .status {
      font-weight: 700;
      font-size: 16px;
    }

    .imageGroup .date {
      font-weight: 400;
      font-size: 16px;
    }

    .info .companyInfo {
      padding-bottom: 20px;
      border-bottom: solid 1px #f3ecec;
      margin-bottom: 20px;
    }

    .info .serviceTime {
      padding: 2px 10px;
      float: right;
      background: #fad9d9;
      border-radius: 5px;
      font-weight: 700;
      color: #e82d03;
      font-size: 20px;
    }

    .info .addressGroup {
      padding-bottom: 12px;
    }

    .info .addressDetail {
      background: #f2f4f6;
      border-radius: 4px;
      color: #6d7582;
      font-weight: 700;
      font-size: 18px;
      padding: 4px 10px;
      margin-left: 8px;
    }

    .info .totalAmount {
      font-weight: 700;
      margin-left: 16px;
      padding-left: 16px;
      border-left: solid 1px #e2e6e9;
      font-size: 20px;
    }

    table {
      margin-top: 0;
    }

    th {
      background: #f7f7f7;
      border: none !important;
    }

    table,
    th,
    td {
      border: none;
    }
  }
`;

export const Table = styled.table`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid #dcdcdc;
  background-color: #ffffff;

  th {
    font-weight: 700;
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    border: solid 1px rgba(0, 0, 0, 0.1);
  }

  td {
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
  }

  th,
  td {
    height: 54px;
  }

  & .total-content {
    font-weight: bold;
  }

  & .bookingStoreName {
    padding-left: 20px;
    background-color: #dcdcdc;
  }

  & .deliveryInfo .header {
    background-color: #dcdcdc;
    text-align: center;
    width: 160px;
  }

  & .deliveryInfo .content {
    padding-left: 5px;
  }

  & .pushBtn {
    border-radius: 5px;
    width: 180px !important;
    height: 40px !important;
    font-size: 16px !important;
    margin-left: 10px;
  }
`;

export const DetailModal = styled(Modal)<{ status: string }>`
  .ant-modal-header {
    border: none;
    padding: 32px 24px 12px 24px;
  }

  .ant-modal-title {
    font-weight: 700;
    font-size: 22px;
  }

  .ant-modal-body {
    height: 650px;
    overflow: scroll;
  }

  .ant-modal-header,
  .ant-modal-content {
    border-radius: 16px;
  }

  .ant-modal-footer {
    padding: 0;
    border-top: 1px solid #d7d8dc;
  }

  .ant-modal-footer .footer {
    display: flex;
  }

  .ant-modal-footer .ant-btn-lg {
    width: ${(props) => (props.status === 'RESERVED' || props.status === 'ASSIGNING_AGENT' ? '50%' : '100%')};
    height: 50px;
    border: none;
    border-radius: 0;
    color: ${(props) => props.status !== 'RESERVED' && props.status !== 'ASSIGNING_AGENT' && '#0067FF'};
    border-radius: 16px;
    font-weight: 700;
  }

  .ant-modal-footer .cancel {
    border-left: solid 1px #d7d8dc;
    border-radius: 0 0 16px 0;
  }
`;

export const AgentModal = styled(Modal)`
  .ant-modal-header {
    border: none;
    padding: 32px 24px 12px 24px;
  }

  .ant-modal-title {
    font-weight: 700;
    font-size: 22px;
    color: #212529;
  }

  .ant-modal-header,
  .ant-modal-content {
    border-radius: 16px;
  }

  .ant-modal-body {
    height: 650px;
    overflow: scroll;
  }

  .ant-modal-footer {
    padding: 0;
    display: flex;
    border-top: 1px solid #d7d8dc;
  }

  .ant-modal-footer button {
    margin: 0;
    border-radius: 16px;
    border: none;
    height: 50px;
    font-size: 16px;
    width: 100%;
  }

  .ant-modal-footer .ant-btn[disabled] {
    background: #fff;
    color: rgba(0, 0, 0, 0.25);
  }

  .ant-modal-footer .request {
    color: #0067ff;
    font-weight: 700;
    border-left: solid 1px #d7d8dc;
    border-radius: 0 0 16px 0;
  }
`;

export const LoadingModal = styled(Modal)`
  z-index: 100000;
  top: 50%;
  transform: translate(0, -50%);

  .ant-modal-content {
    border-radius: 10px;
  }

  .ant-modal-body {
    text-align: center;
    height: 226px;
    padding: 60px;
  }

  & {
    .ant-spin-spinning {
      margin-bottom: 24px;
      width: 48px;
      height: 48px;
    }

    .ant-spin-dot {
      font-size: 48px;
    }

    .content {
      text-align: center;
      font-weight: 700;
      font-size: 20px;
      line-height: 34px;
    }
  }
`;

export const DeliveryModal = styled(Modal)`
  .ant-modal-footer {
    padding: 0px;

    button {
      width: 100%;
      border: none;
      color: #0067ff;
      font-weight: 700;
      font-size: 16px;
    }
  }

  .ant-modal-header {
    border-radius: 16px 16px 0 0;
  }

  .ant-modal-content {
    border-radius: 16px;
  }

  .ant-modal-body {
    max-height: 650px;
    overflow: scroll;
  }

  .ant-modal-title {
    font-size: 22px;
  }

  table {
    margin: 0;
  }

  & .title {
    font-size: 20px;
    padding-bottom: 10px;
  }

  & .padding {
    padding: 40px 0px 10px;
  }
`;

export const PushModal = styled(Modal)`
  .ant-modal-header {
    border-radius: 16px 16px 0px 0px;
  }

  .ant-modal-content {
    border-radius: 16px;
  }

  .ant-modal-body {
    max-height: 650px;
    overflow: scroll;
  }

  .ant-modal-footer {
    border: none;
  }

  & {
    .pushMessage {
      padding: 10px;
    }

    .pushMessage .textArea {
      padding: 15px 0px 20px;
    }

    button {
      border-radius: 0px;
      width: 100%;
      border: none;
      border-top: solid 1px #dcdcdc;
    }
  }
`;

export const CompanyTableContainer = styled.div`
  & {
    .spotName {
      color: rgb(76, 154, 255);
    }
  }
`;
