import React, { useState } from 'react';
import styled from 'styled-components';

import imgArrowRightGray from 'images/arrow/arrow_right_gray.png';
import imgArrowDownGray from 'images/arrow/arrow_down_gray.png';
import classNames from 'classnames';

const SettingMenuItemWrapper = styled.div`
  &.disabled {
    pointer-events: none;
    color: rgba(0, 0, 0, 0.3);
  }
`;
const ToggleIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-top: 0;
  margin-right: 10px;
`;

interface SettingMenuItemProps {
  name: string;
  className?: string;
  onClick?: (e: React.MouseEvent) => void;
  ignoreToggle?: boolean;
  disabled?: boolean;
}

const SettingMenuItem = ({ name, className, onClick, ignoreToggle = false, disabled }: SettingMenuItemProps) => {
  const [toggle, setToggle] = useState<boolean>(false);

  const handleClick = (e: React.MouseEvent) => {
    if (disabled) return;
    if (!ignoreToggle) {
      setToggle(!toggle);
    }
    onClick?.(e);
  };

  return (
    <SettingMenuItemWrapper className={classNames('tab', className, { disabled })} onClick={handleClick}>
      <div className="left text-overflow-dotdotdot">{name}</div>
      <div className="right">
        <ToggleIcon src={toggle ? imgArrowDownGray : imgArrowRightGray} alt="icon private" />
      </div>
    </SettingMenuItemWrapper>
  );
};

export default SettingMenuItem;
