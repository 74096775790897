import React, { useEffect, useState } from 'react';
import { Input, Select, Space, Button, Modal } from 'antd';
import { regex } from 'validators';
import { IAccountCheckResult, ValidStatus } from 'interfaces/admission';
import { useMeInfo } from 'hooks';

const { Search } = Input;
const { Option } = Select;

export interface IBankCodeValue {
  bankCode: string | null | undefined;
  bankAccount: string | undefined;
  validStatus?: ValidStatus;
  invalidMessage?: string;
}

export interface BankCodeProps {
  onSearch: (v: Partial<IBankCodeValue>) => Promise<IAccountCheckResult | undefined>;
  isSearching: boolean;
  bankCodes;
  value?: IBankCodeValue;
  onChange?: (v: IBankCodeValue) => void;
  disabled?: boolean;
}

const BankCode = ({ value, onChange, onSearch, isSearching, bankCodes, disabled }: BankCodeProps) => {
  const { meInfo } = useMeInfo();
  const [searchValue, setSearchValue] = useState<string | undefined>();
  const [selectValue, setSelectValue] = useState<string | null | undefined>();
  const [validStatus, setValidStatus] = useState<ValidStatus>();

  useEffect(() => {
    const { bankCode, bankAccount } = value ?? {};
    setSelectValue(bankCode);
    setSearchValue(bankAccount);
  }, [value]);

  const handleSearchChange = ({ target: { value: v } }: React.ChangeEvent<HTMLInputElement>) => {
    let status = validStatus;
    if (!!v && (status === 'success' || status === 'error')) {
      setValidStatus('changed');
      status = 'changed';
    }
    onChange && onChange({ bankCode: selectValue, bankAccount: v, validStatus: status });
  };

  const handleSelectChange = (selectedValue: string) => {
    let status = validStatus;
    if (!!selectedValue && (status === 'success' || status === 'error')) {
      setValidStatus('changed');
      status = 'changed';
    }
    onChange && onChange({ bankCode: selectedValue, bankAccount: searchValue, validStatus: status });
  };

  const handleSearch = async (v) => {
    if (meInfo?.admin?.informationAgreeStatus) {
      Modal.info({
        content: <>선정산 사용 제휴점은 선정산 업체를 통해 계좌 변경이 가능합니다.</>,
        icon: null,
        okText: '확인',
        className: 'info-handler-modal'
      });
      return;
    }
    if (!v) {
      return;
    }
    try {
      const result = await onSearch({ bankCode: selectValue, bankAccount: searchValue });
      if (!result) {
        return;
      }
      const { resultCode, message } = result;
      let status;
      let invalidMessage;
      if (resultCode === 0) {
        status = 'success';
      } else {
        status = 'error';
        invalidMessage = message;
      }
      setValidStatus(status);
      onChange && onChange({ bankCode: selectValue, bankAccount: searchValue, validStatus: status, invalidMessage });
    } catch (error) {
      onChange && onChange({ bankCode: selectValue, bankAccount: searchValue, validStatus: 'error' });
    }
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Select value={selectValue} onChange={handleSelectChange} disabled={disabled}>
        {bankCodes && bankCodes.map(({ bankId, bankName }) => <Option key={bankId}>{bankName}</Option>)}
      </Select>
      <Search
        value={searchValue}
        placeholder="-없이 숫자만 입력"
        allowClear
        disabled={disabled}
        enterButton={
          <Button
            loading={isSearching}
            disabled={disabled || !(searchValue && regex.onlyNumberReg.test(searchValue))}
            type="primary"
          >
            조회
          </Button>
        }
        size="large"
        onSearch={handleSearch}
        onChange={handleSearchChange}
      />
    </Space>
  );
};

export default BankCode;
