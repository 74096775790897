import {
  deleteBookingArtifactMenuByMenuId,
  deleteDelivery,
  getBookingArtifactByIdx,
  getBookingArtifacts,
  getBookingDetail,
  getBookingHistoriesByMenu,
  getBookingHistoriesBySpot,
  getBookingMenus,
  getBookingStats,
  getDeliveryInfo,
  getBookingByCompany,
  getPushMessage,
  pollingDeliveryStatus,
  postExpectFee,
  postOrderIndices,
  putAgentCall,
  saveBookingArtifactMenu,
  getCompanies,
  postPushMessage,
  getBookingHistoriesDetail,
  saveBookingArtifactMenusByDay,
  deleteBookingArtifactMenusByDay,
  getBookingRelationCompaniesByDay
} from 'apis';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { IDefaultData } from 'interfaces/booking';
import { useMemo } from 'react';

interface IAgents {
  sid: string | undefined;
  data: object;
}
interface TSaveBookingArtifactMenu {
  artifactIdx: number;
  saveData: unknown;
}

interface TDeleteBookingArtifactMenu {
  artifactIdx: number;
  menuId: string;
}

interface TVariableBookingArtifactMenusByDate {
  bookingDate: string;
  saveData: {
    menus: string[];
    bookingStoreIdx: number;
  };
}

interface ArtifactGroup {
  artifactIdxList: number[];
  menu: Map<string, { name: string; count: number }>;
  status: 'CLOSED' | 'BOOKING' | 'DRAFT';
}
interface BookingArtifactGroupBookingDate {
  artifactIdxList: number[];
  artifact: Record<string, ArtifactGroup>;
}

interface ArtifactsGroup {
  menus: Map<string, { name: string; count: number }>;
  status: 'CLOSED' | 'BOOKING' | 'DRAFT';
}
interface TTemplateObj {
  template: Record<string, string | number>;
  artifacts: Record<string, ArtifactsGroup>;
}

export const useBookingByCompany = (sid: string | undefined, companyId: string, bookingDate: string, onSuccess: () => void) => {
  // const params = useMemo(() => {
  //   if (!!sid && !!companyId && !!bookingDate) {
  //     return { sid, companyId, bookingDate };
  //   }
  //   return null;
  // }, [sid, companyId, bookingDate]);

  return useQuery(['getBookingByCompany', { companyId, bookingDate }], () => getBookingByCompany(sid, companyId, bookingDate), {
    enabled: !!companyId && !!bookingDate,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess
  });
};

export const useCompanies = (sid: string | undefined) => {
  return useQuery(['getCompanies', sid], () => getCompanies(), {
    enabled: !!sid,
    refetchOnWindowFocus: false,
    retry: false
  });
};

export const useBookingHistoriesBySpot = (bookingDate: string | undefined) => {
  return useQuery(['getBookingHistoriesBySpot', bookingDate], () => getBookingHistoriesBySpot(bookingDate), {
    enabled: !!bookingDate,
    refetchOnWindowFocus: false,
    retry: false
  });
};

export const useBookingHistoriesMenus = (bookingDate: string | undefined) => {
  return useQuery(['getBookingHistoriesByMenu', bookingDate], () => getBookingHistoriesByMenu(bookingDate), {
    enabled: !!bookingDate,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    // refetchOnReconnect: false,
    retry: false
  });
};

export const useBookingStats = (params: unknown, onSuccess: (v: unknown) => void) => {
  return useQuery(['getBookingStats', params], () => getBookingStats(params), {
    enabled: !!params,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    // refetchOnReconnect: false,
    retry: false,
    onSuccess
  });
};

export const useBookingMenus = (params?: unknown) => {
  return useQuery(['getBookingMenus', params], () => getBookingMenus(params));
};

export const useBookingArtifacts = (params: unknown) => {
  const queryClient = useQueryClient();
  const reload = () => {
    queryClient.invalidateQueries(['getBookingArtifacts', params]);
  };
  const query = useQuery(['getBookingArtifacts', params], () => getBookingArtifacts(params), {
    enabled: !!params,
    select: (data) => {
      if (!data?.isTotalMod || !data?.contents) return data;

      const bookingArtifactGroupBookingDate: BookingArtifactGroupBookingDate = {
        artifactIdxList: [],
        artifact: {}
      };

      const templateObj: Record<string, TTemplateObj> = {};

      data.contents.forEach(({ templates }) => {
        templates?.forEach(({ artifacts, template }) => {
          if (!templateObj[template.name]) {
            templateObj[template.name] = { template, artifacts: {} };
          }
          const { artifactIdxList } = bookingArtifactGroupBookingDate;

          if (artifacts) {
            artifacts.forEach((artifact) => {
              const { artifactIdx, bookingDate, menus, status } = artifact;
              let menuGroup;
              artifactIdxList.push(artifactIdx);

              if (templateObj[template.name]?.artifacts[bookingDate]) {
                menuGroup = templateObj[template.name]?.artifacts[bookingDate].menus;
                templateObj[template.name].artifacts[bookingDate].status = status;
              } else {
                menuGroup = new Map();
              }

              if (menus?.length) {
                for (let l = 0; l < menus.length; l += 1) {
                  const { id, name, price, imageThumbnail } = menus[l];
                  if (menuGroup.has(id)) {
                    const menuG = menuGroup.get(id);
                    menuG.count += 1;
                  } else {
                    menuGroup.set(id, { name, image: { thumb: imageThumbnail }, price, count: 1 });
                  }
                }
              }

              if (!templateObj[template.name].artifacts[bookingDate]) {
                templateObj[template.name].artifacts[bookingDate] = { ...template, menus: menuGroup, status };
              }
            });
            bookingArtifactGroupBookingDate.artifactIdxList = artifactIdxList;
          }
        });
      });

      const templates = Object.values(templateObj).map((item, index) => {
        return {
          template: { ...item?.template, idx: -9 + index, bookingStoreIdx: item.template?.bookingStoreIdx },
          artifacts: Object.entries(item.artifacts)?.map(([bookingDate, artifact]) => {
            return {
              artifactIdx: `${item.template.name}_${bookingDate}`,
              bookingDate,
              menus: artifact.menus.size > 0 ? Array.from(artifact.menus)?.map(([id, m]) => ({ id, ...m })) : null,
              status: artifact.status
            };
          })
        };
      });

      const groupContents = {
        company: { id: 'GROUP', name: '고객사 일괄 세팅' },
        templates
      };

      return {
        ...data,
        contents: [groupContents, ...data.contents],
        artifactGroup: bookingArtifactGroupBookingDate
      };
    }
  });
  return {
    ...query,
    reload
  };
};

export const useBookingArtifact = (artifactIdx) => {
  const queryClient = useQueryClient();
  const reload = () => {
    queryClient.invalidateQueries(['getBookingArtifactByIdx', artifactIdx]);
  };
  const query = useQuery(['getBookingArtifactByIdx', artifactIdx], () => getBookingArtifactByIdx(artifactIdx), {
    enabled: typeof artifactIdx === 'number' && !!artifactIdx
  });
  return {
    ...query,
    reload
  };
};

export const useSaveBookingArtifactMenu = () =>
  useMutation(['saveBookingArtifactMenu'], (params: TSaveBookingArtifactMenu) => {
    const { artifactIdx, saveData } = params;
    return saveBookingArtifactMenu(artifactIdx, saveData);
  });

export const useDeleteBookingArtifactMenu = () =>
  useMutation(['deleteBookingArtifactMenuByMenuId'], (params: TDeleteBookingArtifactMenu) => {
    const { artifactIdx, menuId } = params;
    return deleteBookingArtifactMenuByMenuId(artifactIdx, menuId);
  });

export const useSaveBookingArtifactMenusByBookingDate = () =>
  useMutation(['saveBookingArtifactMenusByDay'], (params: TVariableBookingArtifactMenusByDate) => {
    const { bookingDate, saveData } = params;
    return saveBookingArtifactMenusByDay(bookingDate, saveData);
  });

export const useDeleteBookingArtifactMenusByBookingDate = () =>
  useMutation(['deleteBookingArtifactMenusByDay'], (params: TVariableBookingArtifactMenusByDate) => {
    const { bookingDate, saveData } = params;
    return deleteBookingArtifactMenusByDay(bookingDate, saveData);
  });

export const useDeliveryInfo = (params: { sid: string | undefined; spotKey: string; bookingDate: string }, open) => {
  const { sid, spotKey, bookingDate } = params;
  return useQuery(['getDeliveryInfo', open], () => getDeliveryInfo(sid, spotKey, bookingDate), {
    enabled: !!sid && !!open
  });
};

export const useOrderIndices = (params, open) => {
  const { sid, spotKey, bookingArtifactIdx, agentCount } = useMemo(() => {
    if (params) {
      const { agentCount: ac } = params;
      return {
        ...params,
        agentCount: ac > 0 ? ac : 1
      };
    }
    return null;
  }, [params]);

  return useQuery(['postOrderIndices', params], () => postOrderIndices(sid, spotKey, bookingArtifactIdx, { agentCount }), {
    enabled: !!sid && !!open
  });
};

export const usePollingDeliveryStatus = (sid: string | undefined, bookingDate: string) => {
  return useQuery(['pollingDeliveryStatus', bookingDate], () => pollingDeliveryStatus(sid, bookingDate), {
    enabled: !!bookingDate,
    refetchInterval: 5000,
    retry: false
  });
};

export const useAgentCall = () =>
  useMutation(['putAgentCall'], (params: IAgents) => {
    const { sid, data } = params;
    return putAgentCall(sid, data);
  });

export const useBookingDetail = () =>
  useMutation(['getBookingDetail'], (params: number) => {
    return getBookingDetail(params);
  });

export const useExpectFee = () =>
  useMutation(['postExpectFee'], (params: { searchParams: IDefaultData; agents }) => {
    const { searchParams, agents } = params;
    const { sid, spotKey, bookingDate } = searchParams;
    return postExpectFee(sid, spotKey, bookingDate, { agents });
  });

export const useGetPushMessage = (sid: string | undefined) => {
  return useQuery(['getPushMessage', sid], () => getPushMessage(sid), {
    enabled: !!sid
  });
};

export const usePushMessage = () =>
  useMutation(['postPushMessage'], (params: object) => {
    return postPushMessage(params);
  });

export const useCanceled = () =>
  useMutation(['deleteDelivery'], (params: { sid: string | undefined; orderIdx: number }) => {
    const { sid, orderIdx } = params;
    return deleteDelivery(sid, orderIdx);
  });

export const useBookingHistoriesDetail = (couponId) => {
  return useQuery(['getBookingHistoriesDetail', couponId], () => getBookingHistoriesDetail(couponId), {
    enabled: !!couponId
  });
};
export const useBookingRelationCompaniesByDay = (
  bookingDate: string,
  menuId?: string,
  params?: { searchValue?: string },
  options?: UseQueryOptions
) => {
  return useQuery(
    ['getBookingRelationCompaniesByDay', bookingDate, menuId, params],
    () => getBookingRelationCompaniesByDay(bookingDate, menuId, params),
    {
      enabled: options?.enabled ? !!(bookingDate && menuId && options.enabled === true) : !!(bookingDate && menuId),
      cacheTime: 0
    }
  );
};
