import React from 'react';
import { WarringBorrowedAccountWrapper } from './style';

const WarringBorrowedAccount = () => (
  <WarringBorrowedAccountWrapper>
    <div className="title">차명 계좌 주의</div>
    <div>
      <div>본인의 계좌가 아닌 차명으로 된 계좌 등록 시,</div>
      <div>해당 행위로 발생하는</div>
      <div>불이익은 점주에게 귀속됩니다.</div>
    </div>
  </WarringBorrowedAccountWrapper>
);

export default WarringBorrowedAccount;
