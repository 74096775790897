import React from 'react';
import { useChannelTalk } from 'hooks';
import { ChannelTalkButton } from 'components/Button';
import ContentDescription from './ContentDescription';
import { ChannelTalkWrapper } from './style';

const ChannelTalkDescription = ({ children }) => {
  useChannelTalk(true);
  return (
    <>
      <ContentDescription>{children}</ContentDescription>
      <ChannelTalkWrapper>
        <ChannelTalkButton />
      </ChannelTalkWrapper>
    </>
  );
};

export default ChannelTalkDescription;
