import classNames from 'classnames';
import React from 'react';
import { StyledSpan } from './style';

interface CheckboxShapeProps {
  checked?: boolean;
  color?: 'reverse';
}

const CheckboxShape = ({ checked, color }: CheckboxShapeProps) => {
  return (
    <StyledSpan
      color={color}
      className={classNames('ant-checkbox-wrapper ant-checkbox-wrapper-in-form-item', {
        'ant-checkbox-wrapper-checked': checked
      })}
    >
      <span className={classNames('ant-checkbox', { 'ant-checkbox-checked': checked })}>
        <span className="ant-checkbox-inner" />
      </span>
    </StyledSpan>
  );
};

export default CheckboxShape;
