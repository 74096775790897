import { useMutation, useQuery } from 'react-query';
import {
  checkDuplicateSignId,
  getAdmissionServiceTerm,
  getStoreAccount,
  getStoreTransactionId,
  getTerm,
  putPasswordChange,
  signUp,
  withdraw
} from 'apis';
import { AccountInfo, AccountParams, AccountUser } from 'interfaces/account';
import { TSupplyType } from 'interfaces/admission';

export const useStoreAccount = (transactionId: string, signId?: string, config?: Record<string, unknown>) => {
  return useQuery<AccountInfo>(['forgotSignId', transactionId, signId], () => getStoreAccount(transactionId, signId), {
    enabled: !!transactionId,
    staleTime: 1000,
    ...config
  });
};

export const useStoreTransactionId = (config?: Record<string, unknown>) =>
  useMutation<AccountInfo, unknown, string, unknown>(
    ['getStoreTransactionId'],
    (transactionId: string) => getStoreTransactionId(transactionId),
    { ...config }
  );

export const useChangePassword = () =>
  useMutation<void, unknown, Partial<AccountParams>, unknown>(['changePassword'], (data: Partial<AccountParams>) => {
    return putPasswordChange(data);
  });

export const useCheckDuplicate = () => useMutation(['checkDuplicate'], (signId: string) => checkDuplicateSignId(signId));

export const useSignUp = () =>
  useMutation<AccountUser, unknown, AccountParams, unknown>(['signUp'], (account: AccountParams) => signUp(account));

export const useWithdraw = () => useMutation(['withdraw'], (signId: string) => withdraw(signId));

export const useTerm = (types: string) => useQuery(['getTerm', types], () => getTerm(types));

export const useAdmissionServiceTerm = (admissionIdx: number, supplyType: TSupplyType) =>
  useQuery(['useAdmissionServiceTerm', admissionIdx, supplyType], () => getAdmissionServiceTerm(admissionIdx, supplyType), {
    enabled: typeof admissionIdx === 'number' && !!supplyType
  });
