import styled from 'styled-components';
import exclamationMark from 'images/exclamationMark.svg';

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const ColorSpan = styled.span`
  color: ${({ theme }) => theme.color.primary};
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 46px;
  margin-bottom: 61px;
`;

export const ExclaimWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.gray06};
  color: ${({ theme }) => theme.color.gray02};
  border-radius: 8px;
  width: 100%;
  height: 76px;
  padding: 16px 16px 16px 48px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  position: relative;

  &::before {
    content: url(${exclamationMark});
    width: 20px;
    height: 20px;
    position: absolute;
    left: 18px;
    top: calc(50% - 10px);
  }
`;

export const ReorganizationButtonArea = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  align-content: center;
  .skip-link {
    height: 36px;
    line-height: 36px;
    text-align: center;
    font-size: 16px;
    color: ${({ theme }) => theme.color.gray03};
    text-decoration-line: underline;
    font-weight: 400;
  }
`;

export const ConfirmWrapper = styled.div`
  height: 100%;
  overflow-y: scroll;
`;
