import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ko } from 'date-fns/locale';
import React, { useState } from 'react';
import { Calendar, DateRange, Range } from 'react-date-range';
import styled from 'styled-components';
import DefaultModal from './DefaultModal';

const StyledSelectCalendar = styled.div`
  height: 60px;
  min-width: 1027px;
  margin-bottom: 0;

  .heading {
    margin-top: 20px;
    font-weight: 700;
  }

  .arrowIcon {
    font-size: 24px;
  }

  .inner {
    min-height: 60px;
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #46b476;
  }

  .calendar {
    position: absolute;
    top: 22px;
    left: 50%;
    margin-left: -161px;
  }

  .customButton {
    font-size: 17px;
    font-weight: 700;
    display: flex;
    color: #fff;
  }
`;

interface SelectCalendarProps {
  text: {
    beforeText: string;
    afterText: string;
  };
  stores: {
    calenderOptions: {
      selectedDate: Date;
      key?: unknown;
      startDate: Date;
      endDate: Date;
      formatText: string;
    } & Range;
    handleCalenderSuccess: () => void;
    handleCalenderCancel: () => void;
    handleCalender?: () => void;
    handleBeforeAndAfter: (e: React.MouseEvent<HTMLElement>) => void;
  };
  type: string;
}

const SelectCalendar = ({ text, stores, type }: SelectCalendarProps) => {
  /**
   * @param  {[ String ]} type [ DateRange: <DateRange />, Calendar: <Calendar /> ]
   * @param  {[ Object ]} text [
   *   이전, 다음 텍스트
   *   {
   *     beforeText: 이전 텍스트,
   *     afterText: 다음 텍스트
   *   }
   * ]
   * @param  {[ Object ]} stores.calenderOptions [
   *   달력 데이터
   *   {
   *     days: 선택 된 날짜 (필수)
   *     key:  range 데이터 object key 값 ( Range component 사용시 필수 )
   *     startDate: range 시작 날짜 ( Range component 사용시 필수 )
   *     endDate: range 마지막 날짜  ( Range component 사용시 필수 )
   *     formatText: AppBar에 표시되는 날짜 포맷 (필수)
   *   }
   * ]
   * @param  {[ Function ]} stores.handleCalenderSuccesßs [ 팝업 확인 클릭 후 처리되는 funcion ]
   * @param  {[ Function ]} stores.handleCalender  [ 달력 날짜 선택 시 처리되는 function ]
   * @param  {[ Function ]} stores.handleBeforeAndAfter  [ 이전, 다음 선택 시 처리되는 function]
   */
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     isOpen: false,
  //     isMobile: StoreUtil.mobileCheck()
  //   };
  // }

  const [isOpen, setOpen] = useState<boolean>(false);

  // 팝업 open
  const openModal = () => {
    setOpen(true);
  };

  // 팝업 close
  const closeModal = () => {
    stores.handleCalenderCancel();
    setOpen(false);
  };

  const handleOk = () => {
    stores.handleCalenderSuccess();
    setOpen(false);
  };

  let component: React.ReactNode;
  switch (type) {
    case 'DateRange':
      component = (
        <DateRange
          ranges={[stores.calenderOptions]}
          showDateDisplay={false}
          date={stores.calenderOptions.selectedDate}
          focusedRange={[0, 0]}
          direction="vertical"
          onChange={stores.handleCalender}
          locale={ko}
        />
      );
      break;
    case 'Calendar':
      component = <Calendar date={stores.calenderOptions.selectedDate} onChange={stores.handleCalender} locale={ko} />;
      break;
    default:
      break;
  }

  return (
    <StyledSelectCalendar>
      <div className="inner">
        <Button type="text" className="customButton" name="PREV" onClick={stores.handleBeforeAndAfter}>
          <ArrowLeftOutlined className="arrowIcon" />
          {text.beforeText}
        </Button>
        <div>
          <Button type="text" className="customButton" onClick={openModal}>
            {stores.calenderOptions.formatText}
          </Button>
          <DefaultModal heading="기간 설정" component={component} open={isOpen} close={closeModal} handleOk={handleOk} />
        </div>
        <Button type="text" className="customButton" name="NEXT" onClick={stores.handleBeforeAndAfter}>
          {text.afterText}
          <ArrowRightOutlined className="arrowIcon" />
        </Button>
      </div>
    </StyledSelectCalendar>
  );
};

export default SelectCalendar;
