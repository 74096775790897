import { useAdminSales } from 'hooks';
import moment from 'moment';
import React, { useMemo } from 'react';
import { BarChart } from 'components';

const MasterMain = () => {
  const { data } = useAdminSales({ date: moment().format('YYYY-MM-DD') });

  const graph = useMemo(() => {
    if (!data?.monthlyData) {
      return [];
    }
    const insertData = [['Element', '지난달', '이번달']];
    data.monthlyData.forEach((item) => {
      insertData.push([item.storeName, item.prevMonth.totalPrice, item.month.totalPrice]);
    });
    return insertData;
  }, [data]);

  const startDate = new Date();
  startDate.setDate(1);
  startDate.setMonth(startDate.getMonth() - 1);
  const date1 = moment(startDate).format('YYYY-MM-DD');
  const date2 = moment().format('YYYY-MM-DD');

  return (
    <div className="main-mastergraph">
      <div className="storegraphs">
        <div className="overlay">
          <p className="date">
            {date1} ~ {date2}
          </p>
        </div>
        <BarChart data={graph} />
      </div>
    </div>
  );
};

export default MasterMain;
