import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import styled from 'styled-components';
import closeImg from 'images/login/ic-pw-close.svg';

interface PasswordExampleProps {
  show: boolean;
  close: () => void;
  mobileView?: boolean;
}

const StyledButton = styled(Button)`
  background: transparent;
  &:focus {
    box-shadow: none;
  }
`;

const PasswordExample = ({ show, close, mobileView = false }: PasswordExampleProps) => {
  const modalClass = mobileView ? '' : 'modal fade';
  const modalRole = mobileView ? '' : 'dialog';
  const paddingBottom = mobileView ? 'padding-bottom20' : '';

  return (
    <>
      <Modal id="passwordModal" show={show} onHide={close} size="lg" centered>
        <Modal.Header closeButton>
          <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close">
            <img aria-hidden="true" alt="close-btn" src={closeImg} />
          </button>
        </Modal.Header>
        <Modal.Body className="password-change-wrap">
          <div className="modal-header">
            <StyledButton type="button" className="btn-close" data-dismiss="modal" aria-label="Close">
              <img aria-hidden="true" alt="close-btn" src={closeImg} />
            </StyledButton>
          </div>
          <div className={`modal-body password-change-wrap ${paddingBottom}`}>
            <div className="password-change">
              <div className="content">
                <section className="flex-item">
                  <p>쉬운 비밀번호는?</p>
                  <p style={{ fontWeight: 'normal' }}>누구나 생각할 수 있어 위험해요</p>
                  <input type="text" className="userinput form-control invalid" readOnly value="!!!!" />
                  <div className="validation-wrap">
                    <div className="validation invalid">최소 8자리 이상이어야해요!!</div>
                  </div>
                  <input type="text" className="userinput form-control invalid" readOnly value="abc1110000" />
                  <div className="validation-wrap">
                    <div className="validation invalid">특수문자를 사용하셔야해요!!</div>
                  </div>
                </section>
                <section className="flex-item">
                  <p>안전한 비밀번호란?</p>
                  <p style={{ fontWeight: 'normal' }}>아래와 같이 만들어주세요</p>
                  <input type="text" className="userinput form-control valid" readOnly value="a!s@d#12" />
                  <div className="validation-wrap">
                    <div className="validation valid">8-20자 입력</div>
                    <div className="validation valid">영문 입력</div>
                    <div className="validation valid">숫자 입력</div>
                    <div className="validation valid">특수문자 입력</div>
                  </div>
                </section>
              </div>
              <div className="content spec-item">
                <section className="flex-item">
                  <p>특수문자란?</p>
                  <p style={{ fontWeight: 'normal' }}>아래와 같은 문자들이에요</p>
                  <p>{`! @ # $ % ^ & * ( )- _ + = { } [ ] | \\ : ; “ ‘ , . < > ? / \`~`}</p>
                </section>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer />
      </Modal>
      <div id="passwordModal" className={modalClass} role={modalRole} style={{ display: show ? 'block' : 'none' }}>
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <img aria-hidden="true" alt="close-btn" src={closeImg} />
              </button>
            </div>
            <div className={`modal-body password-change-wrap ${paddingBottom}`}>
              <div className="password-change">
                <div className="content">
                  <section className="flex-item">
                    <p>쉬운 비밀번호는?</p>
                    <p style={{ fontWeight: 'normal' }}>누구나 생각할 수 있어 위험해요</p>
                    <input type="text" className="userinput form-control invalid" readOnly value="!!!!" />
                    <div className="validation-wrap">
                      <div className="validation invalid">최소 8자리 이상이어야해요!!</div>
                    </div>
                    <input type="text" className="userinput form-control invalid" readOnly value="abc1110000" />
                    <div className="validation-wrap">
                      <div className="validation invalid">특수문자를 사용하셔야해요!!</div>
                    </div>
                  </section>
                  <section className="flex-item">
                    <p>안전한 비밀번호란?</p>
                    <p style={{ fontWeight: 'normal' }}>아래와 같이 만들어주세요</p>
                    <input type="text" className="userinput form-control valid" readOnly value="a!s@d#12" />
                    <div className="validation-wrap">
                      <div className="validation valid">8-20자 입력</div>
                      <div className="validation valid">영문 입력</div>
                      <div className="validation valid">숫자 입력</div>
                      <div className="validation valid">특수문자 입력</div>
                    </div>
                  </section>
                </div>
                <div className="content spec-item">
                  <section className="flex-item">
                    <p>특수문자란?</p>
                    <p style={{ fontWeight: 'normal' }}>아래와 같은 문자들이에요</p>
                    <div>{`! @ # $ % ^ & * ( )- _ + = { } [ ] | \\ : ; “ ‘ , . < > ? / \`~`}</div>
                  </section>
                </div>
              </div>
            </div>
            <div className="modal-footer" />
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordExample;
