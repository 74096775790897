import { Grade, MeInfoStore } from './auth';

interface TSignatureImageList {
  idx: number;
  storeId: string;
  type: string;
  image: string;
  displayOrder: number;
  createDate: Date;
  createdBy: string;
  lastModifiedDate?: Date;
  lastModifiedBy?: string;
}
interface TNoticeImageList {
  idx: number;
  storeId: string;
  type: string;
  image: string;
  displayOrder: number;
  createDate: Date;
  createdBy: string;
  lastModifiedDate?: Date;
  lastModifiedBy?: string;
}

interface TMenuImageList {
  idx: number;
  storeId: string;
  type: string;
  image: string;
  displayOrder: number;
  createDate: Date;
  createdBy: string;
  lastModifiedDate?: Date;
  lastModifiedBy?: string;
}

export interface StoreType {
  sid: string;
  id: string;
  name: string;
  intro: string;
  phone?: string;
  cellphone?: string;
  address?: string;
  addressType?: 'R' | 'J';
  zoneCode?: string;
  roadAddress?: string;
  jibunAddress?: string;
  addressDetail?: string;
  cancelfunc: boolean;
  cancelpwchange: boolean;
  cancelpw: string;
  pushcoupon: boolean;
  bizname?: string;
  bizserial?: string;
  bankCode?: string;
  bankAccount?: string;
  bankAccountHold?: string;
  supplyType: number;
  isBooking: boolean;
  isCafeteriaPw: boolean;
  isExposeDeliveryRequirement?: boolean;
  thumbnailImage?: string;
  signatureImageList?: Array<TSignatureImageList>;
  menuBoardImageList?: Array<TMenuImageList>;
  noticeImageList?: Array<TNoticeImageList>;
  isTermsAgree: boolean;
  meInfoCurrent?: MeInfoStore;
}

export interface StoreImagesType {
  isRegisterThumbnailImage: boolean;
  canRegisterThumbnailImage: boolean;
  isRegisterSignatureImage: boolean;
  canRegisterSignatureImage: boolean;
  isRegisterMenuBoardImage: boolean;
  canRegisterMenuBoardImage: boolean;
  isRegisterNoticeImage: boolean;
  canRegisterNoticeImage: boolean;
}

export interface DisplayData {
  storeName: string;
  storePhone: string;
  addressType: string;
  zoneCode: string;
  roadAddress: string;
  jibunAddress: string;
  addressDetail: string;
  mainCategoryIdx: number[];
}

export interface ISaveAppDisplayInfo {
  storeId: string;
  displayData: DisplayData;
}

export type EmployeeStatus = 'PREPARATION' | 'CANCEL' | 'REFUSE' | 'REST' | 'WORK' | 'DELETE';

export const employeeTypeMap = {
  PREPARATION: '대기중',
  CANCEL: '취소',
  REFUSE: '거절',
  REST: '휴직중',
  WORK: '근무중'
};

export interface Employee {
  employeeApplyIdx: 0;
  name: string;
  cellPhone: string;
  status: string | EmployeeStatus;
  inviteDate: 0;
  approveDate: 0;
}

export interface Worker {
  employeeApplyIdx: 0;
  storeId: string;
  storeName: string;
  storeAddress: string;
  grade: Grade;
  status: string | EmployeeStatus;
  inviteDate: 0;
  approveDate: 0;
}

export interface CouponParam {
  sid: string;
  date: string;
}

export type CouponParamPaging = CouponParam & { page: number; pageRow: number };

export type TInfiniteReturn<T> = {
  data: T;
  nextId: number | null;
  previousId: number | null;
};
