import React, { useState } from 'react';
import styled from 'styled-components';
import { BaseCheckBox, modalMethod } from 'components';
import { numberWithCommas } from 'utils';
import { BookingMenu } from 'interfaces/booking';
import RelationCompaniesModal from './RelationOfficeModal';

const ArtifactDetailMenuAllWrapper = styled.div`
  & {
    width: 100%;
    img {
      float: left;
    }
    .menu-text {
      height: 100%;
      padding: 15px;
      width: 20%;
      flex: 1;
      &.underline {
        &.header {
          text-decoration: none;
          cursor: inherit;
        }
        text-decoration: underline;
        display: flex;
        padding: 0;
        cursor: pointer;
        & > span {
          padding: 15px;
        }
      }
    }
    .menu-price {
      height: 100%;
      padding: 15px 20px;
      //width: 40%;
      flex: 1;
      display: flex;
      justify-content: flex-end;
      text-align: right;
    }
    .menu-btn {
      display: inline-flex;
      justify-content: space-around;
      align-items: center;
      gap: 8px;
      padding: 10px 0;
      width: 168px;
    }
    .menu-checkbox {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      //width: 49px;
      .ant-checkbox-wrapper {
        padding: 9px 17px;
      }
    }
    .menu-group {
      padding: 0 25px 0 35px;
      background-color: #f9f9f9;
      border-bottom: 1px solid #dcdcdc;
      height: 50px;
      display: inline-flex;
      align-items: center;
      .menu-group-empty {
        width: 50px;
      }
      .menu-text {
        font-size: 13px;
        color: #333;
      }
    }
    .menu {
      height: 50px;
      padding: 0 25px 0 35px;
      border-bottom: 1px solid #dcdcdc;
      display: flex;
    }
    .ant-btn.menuAddBtn,
    .ant-btn.menuDelBtn {
      padding: 6px 12px;
      font-size: 14px;
      font-weight: normal;
      line-height: 1.42857143;
      border-radius: 4px;
    }
    .ant-btn.menuAddBtn {
      background-color: #46b476;
      color: #ffffff;
    }
  }
`;

interface ArtifactDetailMenuAllProps {
  bookingDate: string;
  btnIsDim?: boolean;
  menus?: BookingMenu[];
  handleDialog?: () => void;
  removeMenu?: (ids: string[]) => Promise<void>;
  menuAddBtn?: any;
  menuDelBtn?: any;
}

const ArtifactDetailMenuAll = ({
  bookingDate,
  btnIsDim,
  menus,
  handleDialog,
  removeMenu,
  menuAddBtn: MenuAddBtn,
  menuDelBtn: MenuDelBtn
}: ArtifactDetailMenuAllProps) => {
  const [selectedMenuIds, setSelectedMenuIds] = useState<string[]>([]);
  const [selectedMenu, setSelectedMenu] = useState<any>();
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleToggleAll = () => {
    if (selectedMenuIds.length === menus.length) {
      setSelectedMenuIds([]);
    } else {
      setSelectedMenuIds(menus.map(({ id }) => id));
    }
  };

  const handleCheckedMenu = (menuId: string) => {
    if (selectedMenuIds.includes(menuId)) {
      setSelectedMenuIds([...selectedMenuIds.filter((id) => id !== menuId)]);
    } else {
      setSelectedMenuIds([...selectedMenuIds, menuId]);
    }
  };

  const handleRemoveMenu = async () => {
    if (selectedMenuIds.length === 0) {
      modalMethod.warning('삭제할 메뉴를 선택해 주세요');
      return;
    }
    const confirmResult = await modalMethod.confirm('일괄 삭제 하시겠습니까?', { okText: '삭제하기', cancelText: '취소' });
    if (!confirmResult) return;
    await removeMenu?.(selectedMenuIds);
    setSelectedMenuIds([]);
  };

  const handleOpen = (menuId) => {
    setSelectedMenu(menuId);
    setOpen(true);
  };

  return (
    <ArtifactDetailMenuAllWrapper>
      <div className="menu-group">
        <div className="menu-checkbox">
          <BaseCheckBox
            indeterminate={selectedMenuIds.length > 0 && menus.length !== selectedMenuIds.length}
            checked={selectedMenuIds.length > 0 && menus.length === selectedMenuIds.length}
            disabled={!menus?.length}
            onChange={handleToggleAll}
          />
        </div>
        <span className="menu-group-empty" />
        <div className="menu-text">메뉴명</div>
        <div className="menu-text underline header">
          <span>연결된 고객사</span>
        </div>
        <div className="menu-price" />
        <div className="menu-btn">
          {!btnIsDim ? (
            <>
              <MenuAddBtn className="menuAddBtn" onClick={handleDialog}>
                메뉴등록
              </MenuAddBtn>
              <MenuDelBtn className="menuDelBtn" onClick={handleRemoveMenu}>
                삭제
              </MenuDelBtn>
            </>
          ) : null}
        </div>
      </div>
      {menus
        ? menus.map((menu) => (
            <div className="menu" key={menu.id}>
              <div className="menu-checkbox">
                <BaseCheckBox checked={selectedMenuIds.includes(menu.id)} onChange={() => handleCheckedMenu(menu.id)} />
              </div>
              {menu.image.thumb ? (
                <img
                  src={menu.image.thumb}
                  alt="Smiley face"
                  height="49"
                  width="50"
                  onError={(e: React.ChangeEvent<HTMLImageElement>) => {
                    e.target.onerror = null;
                    e.target.src = ''; // tray;
                  }}
                />
              ) : (
                <img src="" alt="Smiley face" height="49" width="50" />
              )}
              <div className="menu-text">
                <span>{menu.name}</span>
              </div>
              <div className="menu-text underline">
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <span onClick={() => handleOpen(menu)}>{numberWithCommas(menu.count)}개</span>
              </div>
              <div className="menu-price">
                <span>{numberWithCommas(menu.price)}</span>
              </div>
              <div className="menu-btn" />
            </div>
          ))
        : null}
      <RelationCompaniesModal bookingDate={bookingDate} menu={selectedMenu} open={isOpen} close={() => setOpen(false)} />
    </ArtifactDetailMenuAllWrapper>
  );
};

export default ArtifactDetailMenuAll;
