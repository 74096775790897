import React, { useMemo } from 'react';
import { generate } from 'shortid';
import { Button } from 'antd';
import { BookingInfos } from 'interfaces/booking';
import moment from 'moment';
import { CompanyTableContainer, Table } from '../styles';

interface CompanyTableProps {
  data: BookingInfos[];
  bookingDate: string;
  companyId: string;
  onPushModal: (idx) => void;
  onDeliveryInfo: (e, idx) => void;
}

const CompanyTable = ({ data, bookingDate, companyId, onPushModal, onDeliveryInfo }: CompanyTableProps) => {
  const bookingMenu = useMemo(() => {
    const bookingCompanyInfoData = [];

    data?.forEach((obj) => {
      if (obj.bookingCompanyInfos && obj.bookingCompanyInfos.length > 0) {
        bookingCompanyInfoData.push({
          row: 'header',
          bookingStoreIdx: obj.bookingStoreIdx,
          bookingStoreName: obj.bookingStoreName,
          couponId: '',
          companyId: '',
          companyName: '',
          spotKey: '',
          spotName: '',
          bookedCount: 0,
          canceledCount: 0,
          menuId: '',
          menuName: '',
          bookedPrice: 0,
          canceledPrice: 0
        });
        obj.bookingCompanyInfos.forEach((companyObj) => {
          const companyInfo = {
            row: 'body',
            bookingStoreIdx: obj.bookingStoreIdx,
            bookingStoreName: obj.bookingStoreName,
            companyId: companyObj.companyId,
            companyName: companyObj.companyName,
            companyRowSpan: 0
          };

          Object.entries(companyObj.spots).forEach(([, v]) => {
            companyInfo.companyRowSpan += v.menus.length;
          });

          Object.entries(companyObj.spots).forEach(([, v]) => {
            const menuInfo = {
              ...companyInfo,
              spotKey: v.spotKey,
              spotName: v.spotName,
              rowSpan: v.menus.length
            };

            v.menus?.forEach((menu) => {
              bookingCompanyInfoData.push({
                ...menuInfo,
                couponId: menu.couponId,
                bookedCount: menu.bookedCount,
                canceledCount: menu.canceledCount,
                menuId: menu.menuId,
                menuName: menu.menuName,
                bookedPrice: menu.bookedPrice,
                canceledPrice: menu.canceledPrice
              });
            });
          });
        });
      }
    });
    return bookingCompanyInfoData;
  }, [data]);

  return (
    <CompanyTableContainer>
      <Table>
        <thead>
          <tr>
            <th style={{ textAlign: 'center', width: '10%' }}>고객사 </th>
            <th style={{ textAlign: 'center', width: '8%' }}>배달지 별명</th>
            <th style={{ textAlign: 'center', width: '10%' }}>메뉴</th>
            <th style={{ textAlign: 'center', width: '10%' }}>예약완료</th>
            <th style={{ textAlign: 'center', width: '10%' }}>예약취소</th>
            <th style={{ textAlign: 'center', width: '10%' }}>결제완료</th>
            <th style={{ textAlign: 'center', width: '10%' }}>총액</th>
          </tr>
        </thead>
        <tbody>
          {bookingMenu.map((obj, idx) => {
            if (obj.row === 'header') {
              return (
                <tr key={generate()}>
                  <td
                    colSpan={bookingDate === moment().format('YYYY-MM-DD') && companyId !== 'ALL' ? 6 : 7}
                    className="bookingStoreName"
                  >
                    {obj.bookingStoreName}
                  </td>

                  {bookingDate === moment().format('YYYY-MM-DD') && companyId !== 'ALL' && (
                    <td className="bookingStoreName">
                      <Button className="pushBtn" onClick={() => onPushModal(obj.bookingStoreIdx)}>
                        예약완료 push 보내기
                      </Button>
                    </td>
                  )}
                </tr>
              );
            }
            return (
              <tr key={generate()}>
                {idx !== 0 && bookingMenu[idx - 1].companyId === obj.companyId ? null : (
                  <td style={{ textAlign: 'center' }} rowSpan={obj.companyRowSpan}>
                    {obj.companyName}
                  </td>
                )}
                {idx !== 0 && bookingMenu[idx - 1].spotKey === obj.spotKey ? null : (
                  <td style={{ textAlign: 'center' }} rowSpan={obj.rowSpan}>
                    <div
                      role="button"
                      tabIndex={0}
                      onClick={(e) => onDeliveryInfo(e, obj.couponId)}
                      onKeyDown={(e) => onDeliveryInfo(e, obj.couponId)}
                      className="spotName"
                    >
                      {obj.spotName}
                    </div>
                  </td>
                )}
                <td style={{ textAlign: 'center' }}>{obj.menuName}</td>
                <td style={{ textAlign: 'center' }}>{obj.bookedCount.toLocaleString()}</td>
                <td style={{ textAlign: 'center' }}>{obj.canceledCount.toLocaleString()}</td>
                <td style={{ textAlign: 'center' }}>{obj.bookedCount.toLocaleString()}</td>
                <td style={{ textAlign: 'center' }}>{obj.bookedPrice.toLocaleString()}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </CompanyTableContainer>
  );
};

export default CompanyTable;
