import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  saveAdmission,
  getAdmission,
  getAdmissions,
  getBizSerial,
  getAdmissionTypes,
  getBankCodes,
  checkAccountNumber,
  errorHandler
} from 'apis';
import {
  ContentAdmission,
  Admission,
  StepsAdmission,
  IAdmissionParams,
  IAccountCheckParams,
  IAccountCheckResult
} from 'interfaces/admission';
import { useMeInfo } from './useMeInfo';

export const useSaveAdmission = () => {
  const queryClient = useQueryClient();
  return useMutation<ContentAdmission, unknown, IAdmissionParams, unknown>(
    ['saveAdmission'],
    (admission: IAdmissionParams) => saveAdmission(admission),
    {
      onSuccess: () =>
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey[0] === 'getAdmissions' || query.queryKey[0] === 'getAdmissionHistory'
        }),
      onError: errorHandler
    }
  );
};

export const useAdmissions = () => {
  const { meInfo } = useMeInfo();
  const { admin } = meInfo || {};
  return useQuery<StepsAdmission | undefined>(['getAdmissions'], () => getAdmissions(admin?.said), {
    enabled: !!admin?.said,
    cacheTime: 10000
  });
};

export const useAdmission = (admissionIdx: number) => {
  const { meInfo } = useMeInfo();
  const { admin } = meInfo || {};
  return useQuery<Admission | undefined>(['getAdmission'], () => getAdmission(admin?.said, admissionIdx), {
    enabled: typeof admissionIdx === 'number' && !!admin?.said
  });
};

export const useAdmissionTypes = () => useQuery(['getAdmissionTypes'], () => getAdmissionTypes());

export const useBankCodes = () => useQuery(['getBankCodes'], () => getBankCodes());

export const useBizSerial = () => useMutation(['getBizSerial'], (bizSerial: string) => getBizSerial(bizSerial));

export const useValidBankAccount = () =>
  useMutation<IAccountCheckResult, unknown, IAccountCheckParams, unknown>(
    ['checkAccountNumber'],
    (checkData: IAccountCheckParams) => checkAccountNumber(checkData)
  );
