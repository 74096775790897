import React, { useMemo } from 'react';
import { numberWithCommas } from 'utils/stringUtils';
import moment from 'moment';
import { generate } from 'shortid';
import classNames from 'classnames';

interface RealtimeTableProps {
  isTestId: boolean;
  readonly realtimeSales: Record<string, any>[];
  isMore: boolean;
  getMore: () => void;
  onClick: (couponId: string) => void;
}

const RealtimeTable = ({ isTestId, realtimeSales, isMore, getMore, onClick }: RealtimeTableProps) => {
  const contents = useMemo(() => {
    if (!realtimeSales?.length) {
      return [];
    }
    const useDataArr = [];

    let beforeHour = '';

    for (let i = 0; i < realtimeSales.length; i += 1) {
      const obj = realtimeSales[i];
      const d = new Date(obj.useDate * 1000);
      const dateHourString = `${d.getHours()}시`;

      if (beforeHour !== dateHourString) {
        beforeHour = dateHourString;
        let isCheck = false;
        isCheck = useDataArr.some((c) => {
          if (c.type === 'category' && c.data === dateHourString) {
            return true;
          }
          return false;
        });
        if (!isCheck) {
          useDataArr.push({ type: 'category', data: dateHourString });
        }
      }
      const nextI = i + 1;
      let endOfHour = false;
      if (nextI < realtimeSales.length) {
        const nextSale = realtimeSales[nextI];
        const nextDate = new Date(nextSale.usedate);

        if (d.getHours() !== nextDate.getHours()) {
          endOfHour = true;
        }
      }
      useDataArr.push({ type: 'history', data: obj, endOfHour });
    }
    return useDataArr;
  }, [realtimeSales]);

  return (
    <table className="realtime-table">
      <thead>
        <tr className="realtime-table-pc-tr">
          <th>결제번호</th>
          <th>시간</th>
          {!isTestId ? <th>회사명</th> : null}

          <th>결제자</th>
          <th className="menuInfo">메뉴명</th>
          <th>사용형태</th>
          <th className="price">결제금액</th>
        </tr>
      </thead>
      <tbody>
        {contents &&
          contents.map(({ type, data: coupon, endOfHour }, key) => {
            if ((type as string) === 'category') {
              return (
                <tr className="pc-hour-line" key={generate()}>
                  <td colSpan={8}>{coupon as string}</td>
                </tr>
              );
            }

            const { couponId, couponType, payRoomIdx, status, comName, leaderName, menuName, price, useDate, isCanceled } =
              coupon as Record<string, string | number | boolean | undefined>;
            const stringDate = moment((useDate as number) * 1000).format('YYYY. M. DD. / HH:mm:ss');
            let idx = payRoomIdx;
            let textStyle;
            let textStyleTitle;
            const totalRows = contents.length;
            if ((status as number) < 0 || (isCanceled as boolean)) {
              idx = '결제취소';
              textStyle = { color: '#dcdcdc' };
              textStyleTitle = { color: '#fa503c' };
            }
            return (
              <tr
                className={classNames('realtime-table-pc-tr', {
                  'realtime-table-pc-tr-bottom': key + 1 !== totalRows && endOfHour !== true,
                  'pc-tr-cancel': (status as number) < 0 || (isCanceled as boolean)
                })}
                key={couponId as string}
                onClick={() => onClick(couponId as string)}
              >
                <td style={textStyleTitle}>{idx}</td>
                <td style={textStyle}>{stringDate}</td>
                {!isTestId ? <td style={textStyle}>{comName}</td> : null}

                <td style={textStyle}>{leaderName}</td>
                <td style={textStyle} className="menuInfo">
                  {menuName}
                </td>
                <td style={textStyle}>{couponType === 'GROUP' ? '함께결제' : '혼자결제'}</td>
                <td className="price" style={textStyle}>
                  {numberWithCommas(price as number)}원
                </td>
              </tr>
            );
          })}
        {/* {mobilerow} */}
        {isMore ? (
          <tr className="moretab" onClick={getMore}>
            <td colSpan={7}>더보기</td>
          </tr>
        ) : null}
      </tbody>
    </table>
  );
};

export default RealtimeTable;
