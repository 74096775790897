import { getStoreHost } from 'apis/host';
import classNames from 'classnames';
import DateRange from 'components/Date/DateRange';
import { useDownloadShippingExcel, useMeInfo } from 'hooks';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { Range } from 'react-date-range';
import { getToken, handlerKeyUp } from 'utils';
import { modalMethod } from './modalMethod';
import { StyledDateModal } from './styles';

type TDate = {
  startDate: Moment;
  endDate: Moment;
};

interface DateModalProps {
  startDate: Moment;
  endDate: Moment;
  dateSelected: (date: { startDate: Moment; endDate: Moment; orderStatus?: string; logisticsValue?: string }) => void;
  close?: (v?: unknown) => void;
  isOpenCalendar?: boolean;
  filter?: Record<string, unknown>;
  month?: number;
  title?: string;
  target?: string;
  logistics?: any | unknown[];
  isLimit?: boolean;
}

const DateModal = ({
  isOpenCalendar,
  startDate,
  endDate,
  filter,
  month,
  title = '기간 설정',
  target,
  logistics,
  dateSelected,
  close,
  isLimit = true
}: DateModalProps) => {
  const { selectedStore } = useMeInfo();

  const [date, setDate] = useState<TDate>({
    startDate: moment(),
    endDate: moment()
  });
  const [isVisible, setVisible] = useState<boolean>(false);
  const [filterState, setFilterState] = useState<Record<string, unknown>>({});
  const [logisticsValue, setLogisticsValue] = useState<string | undefined>();

  const { mutate: uploadShippingExcelDown } = useDownloadShippingExcel();

  useEffect(() => {
    setDate({
      startDate,
      endDate
    });
  }, [startDate, endDate]);

  useEffect(() => {
    if (filter) {
      setFilterState(filter);
    }
  }, [filter]);

  useEffect(() => {
    if (isOpenCalendar === true) {
      setVisible(true);
    }
    if (isOpenCalendar === false) {
      setVisible(false);
    }
  }, [isOpenCalendar]);

  const handleClose = () => {
    setVisible(false);
    close?.();
    setDate({ startDate, endDate });
  };

  const handleSelect = (r: Range) => {
    const changedStartDate = moment(r.startDate);
    const changedEndDate = moment(r.endDate);
    const now = moment();

    const checkMonth = month || 3;
    let message = (
      <span>
        최대 3개월 까지 조회가 가능합니다.
        <br />
        조회기간을 다시 선택해주세요.
      </span>
    );
    if (checkMonth) {
      message = (
        <span>
          최대 6개월 까지 조회가 가능합니다.
          <br />
          조회기간을 다시 선택해주세요.
        </span>
      );
    }
    if (changedEndDate.diff(changedStartDate, 'month') >= checkMonth) {
      modalMethod.info(message);
      return;
    }

    if (isLimit && changedStartDate > now) {
      modalMethod.info(' 오늘 이전으로 시작일을 선택해주세요.');
      return;
    }

    if (isLimit && changedEndDate > now) {
      modalMethod.info('오늘까지만 종료일을 선택할 수 있습니다.');
      return;
    }

    setDate({
      startDate: changedStartDate,
      endDate: changedEndDate
    });
  };
  const handleConfirm = () => {
    dateSelected({ ...date, orderStatus: filterState.orderStatus as string, logisticsValue });
  };

  const handleChangeStatus = (status: 'ALL' | 'COMPLETE' | 'SUCCEED' | 'ING' | 'CANCELED') => {
    setFilterState({ orderStatus: status });
  };

  const selectThisMonth = () => {
    setDate({
      startDate: moment().startOf('month'),
      endDate: moment()
    });
  };
  const selectPrevMonth = () => {
    const prevMonth = moment().subtract(1, 'months');
    setDate({
      startDate: prevMonth.clone().startOf('month'),
      endDate: prevMonth.endOf('month')
    });
  };

  const selectTwoMonth = () => {
    const twoMonthAgo = moment().subtract(2, 'months');
    setDate({
      startDate: twoMonthAgo.clone().startOf('month'),
      endDate: twoMonthAgo.endOf('month')
    });
  };

  const selectToday = () => {
    setDate({ startDate: moment(), endDate: moment() });
  };

  const selectOneWeek = () => {
    setDate({
      startDate: moment().subtract(1, 'week'),
      endDate: moment()
    });
  };

  const selectTwoWeek = () => {
    setDate({
      startDate: moment().subtract(2, 'week'),
      endDate: moment()
    });
  };

  const excelDirectDownload = () => {
    const startdate = moment(date.startDate).format('YYYY-MM-DD');
    const enddate = moment(date.endDate).format('YYYY-MM-DD');

    const params = {
      sid: selectedStore?.sid,
      startDate: startdate,
      endDate: enddate,
      contentType: 'SP',
      isTracking: filter.isTracking,
      orderStatus: filterState.orderStatus,
      inquiryCondition: filter.inquiryCondition,
      inquiry: filter.inquiry,
      logistics: logisticsValue
    };

    uploadShippingExcelDown(params, {
      onSuccess: (data) => {
        const token = getToken();
        window.location.href = `${getStoreHost()}${encodeURI(
          `/excel/v1/down?access_token=${token}&fileId=${data.fileId}&fileName=배송상품주문관리엑셀`
        )}`;
        handleClose();
      },
      onError: () => {
        alert('요청에 실패하였습니다.');
      }
    });
  };

  const handleChangeLogistics = ({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => {
    setLogisticsValue(value);
  };

  const d = new Date();
  const dateYear = d.getFullYear();
  const dateMonth = d.getMonth() + 1;

  const prevDate = new Date();
  prevDate.setDate(1);
  prevDate.setMonth(prevDate.getMonth() - 1);

  const prevPrevDate = new Date();
  prevPrevDate.setDate(1);
  prevPrevDate.setMonth(prevPrevDate.getMonth() - 2);

  const targetFlag = target === 'shipping';

  const strStartDate = moment(date.startDate).format('YYYY-MM-DD');
  const strEndDate = moment(date.endDate).format('YYYY-MM-DD');
  const monthDefaultStartDate = moment([dateYear, dateMonth - 1]).format('YYYY-MM-DD');

  // 		var month_default_endDate = moment(month_default_startDate).endOf('month').format('YYYY-MM-DD');
  const monthDefaultEndDate = moment(new Date()).format('YYYY-MM-DD');

  const monthPrevMonthStartDate = moment([prevDate.getFullYear(), prevDate.getMonth()]).format('YYYY-MM-DD');
  const monthPrevMonthEndDate = moment(monthPrevMonthStartDate).endOf('month').format('YYYY-MM-DD');
  const monthTwoPrevMonthStartDate = moment([prevPrevDate.getFullYear(), prevPrevDate.getMonth()]).format('YYYY-MM-DD');
  const monthTwoPrevMonthEndDate = moment(monthTwoPrevMonthStartDate).endOf('month').format('YYYY-MM-DD');

  const momentToday = moment(d).format('YYYY-MM-DD');
  const strWeekOne = moment().subtract(1, 'week').format('YYYY-MM-DD');
  const strWeekTwo = moment().subtract(2, 'week').format('YYYY-MM-DD');
  const thisMonth = 'thisMonth';
  const prevMonth = 'prevMonth';
  const twoMonth = 'twoMonth';
  const today = 'today';
  const oneWeek = 'oneweek';
  const twoWeek = 'twoweek';

  const isThisMonth = monthDefaultStartDate === strStartDate && monthDefaultEndDate === strEndDate;
  const isPrevMonth = monthPrevMonthStartDate === strStartDate && monthPrevMonthEndDate === strEndDate;
  const isTwoMonth = monthTwoPrevMonthStartDate === strStartDate && monthTwoPrevMonthEndDate === strEndDate;
  const isOneWeek = strWeekOne === strStartDate && momentToday === strEndDate;
  const isTwoWeek = strWeekTwo === strStartDate && momentToday === strEndDate;
  const isToday = momentToday === strStartDate && momentToday === strEndDate;

  const statusAll = 'statusAll';
  const statusComplete = 'statusComplete';
  const statusIng = 'statusIng';
  const statusCancel = 'statusCancel';
  const statusSuccess = 'statusSuccess';
  const logisticsList = 'btns';

  return (
    <StyledDateModal
      width={700}
      open={isVisible}
      title={title}
      onCancel={handleClose}
      closable
      footer={
        <div className="modal-footer">
          {targetFlag ? (
            <button type="button" className="btn btn-success pull-left" onClick={excelDirectDownload}>
              즉시 다운로드
            </button>
          ) : null}
          <button type="button" className="btn btn-cancel" data-dismiss="modal" onClick={handleClose}>
            취소
          </button>
          <button type="button" className="btn btn-sikdae" onClick={handleConfirm}>
            확인
          </button>
        </div>
      }
    >
      <div id="dateModal">
        <div className="calendar" style={{ fontSize: 14, color: 'rgba(0, 0, 0, 0.85)', lineHeight: 1.5715 }}>
          <div className="dateInfo">
            <div className="left">조회기간</div>
            <div className="right">
              {strStartDate} ~ {strEndDate}
            </div>
          </div>
          <div className="draw" id="calendar" style={{ height: 380, lineHeight: 1.5715 }}>
            <DateRange
              // isVisible={isOpenCalendar}
              startDate={date.startDate.toDate()}
              endDate={date.endDate.toDate()}
              onChange={handleSelect}
              // calendars="1"
            />
          </div>
        </div>
        <div className="preset">
          <h4>간편설정</h4>
          <div className="btns">
            <div
              className={classNames(thisMonth, { active: isThisMonth })}
              role="button"
              tabIndex={0}
              onClick={selectThisMonth}
              onKeyUp={handlerKeyUp(selectThisMonth)}
            >
              이번달
            </div>
            <div
              className={classNames(prevMonth, { active: isPrevMonth })}
              role="button"
              tabIndex={0}
              onClick={selectPrevMonth}
              onKeyUp={handlerKeyUp(selectPrevMonth)}
            >
              {moment([prevDate.getFullYear(), prevDate.getMonth()]).format('M')}월
            </div>
            <div
              className={classNames(twoMonth, { active: isTwoMonth })}
              role="button"
              tabIndex={0}
              onClick={selectTwoMonth}
              onKeyUp={handlerKeyUp(selectTwoMonth)}
            >
              {moment([prevPrevDate.getFullYear(), prevPrevDate.getMonth()]).format('M')}월
            </div>
            <div
              className={classNames(today, { active: isToday })}
              role="button"
              tabIndex={0}
              onClick={selectToday}
              onKeyUp={handlerKeyUp(selectToday)}
            >
              오늘
            </div>
            <div
              className={classNames(oneWeek, { active: isOneWeek })}
              role="button"
              tabIndex={0}
              onClick={selectOneWeek}
              onKeyUp={handlerKeyUp(selectOneWeek)}
            >
              최근 1주
            </div>
            <div
              className={classNames(twoWeek, { active: isTwoWeek })}
              role="button"
              tabIndex={0}
              onClick={selectTwoWeek}
              onKeyUp={handlerKeyUp(selectTwoWeek)}
            >
              최근 2주
            </div>
          </div>
          {targetFlag ? (
            <div>
              <h4 className="margin-top-20px">
                상태 <span style={{ fontWeight: 'normal', fontSize: 16, color: 'dimgray' }}>(즉시 다운로드 전용)</span>
              </h4>
              <div className="btns">
                <div
                  className={classNames(statusAll, { active: filterState.orderStatus === 'ALL' })}
                  id="statusAll"
                  onClick={() => handleChangeStatus('ALL')}
                  aria-hidden="true"
                >
                  전체
                </div>
                <div
                  className={classNames(statusSuccess, { active: filterState.orderStatus === 'SUCCEED' })}
                  id="statusSuccess"
                  onClick={() => handleChangeStatus('SUCCEED')}
                  aria-hidden="true"
                >
                  주문완료
                </div>
                <div
                  className={classNames(statusIng, { active: filterState.orderStatus === 'ING' })}
                  id="statusIng"
                  onClick={() => handleChangeStatus('ING')}
                  aria-hidden="true"
                >
                  배송중
                </div>
                <div
                  className={classNames(statusComplete, { active: filterState.orderStatus === 'COMPLETE' })}
                  id="statusComplete"
                  onClick={() => handleChangeStatus('COMPLETE')}
                  aria-hidden="true"
                >
                  배송완료
                </div>
                <div
                  className={classNames(statusCancel, { active: filterState.orderStatus === 'CANCELED' })}
                  id="statusCancel"
                  onClick={() => handleChangeStatus('CANCELED')}
                  aria-hidden="true"
                >
                  주문취소
                </div>
              </div>
              <div
                id="logisticsArea"
                className={classNames(logisticsList, { 'display-none': filterState.orderStatus !== 'SUCCEED' })}
              >
                <div className="form-group w-85">
                  <h5 className="margin-top-20px">택배사 일괄적용(송장번호 일괄등록용)</h5>
                  <select className="form-control" id="logisticsIdx" value={logisticsValue} onChange={handleChangeLogistics}>
                    <option value="">선택</option>
                    {logistics &&
                      logistics.map((e: Record<string, string>) => {
                        return (
                          <option value={e.idx} key={e.idx}>
                            {e.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </StyledDateModal>
  );
};

export default DateModal;
