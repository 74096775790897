import React, { useEffect } from 'react';
import { minLength, onlyNumberValidator, validateMessages } from 'validators';
import { Form, Input } from 'antd';
import BottomButton from 'components/Button/BottomButton';
import styled from 'styled-components';

const { useForm } = Form;

const Text = styled.div`
  display: flex;
  margin-bottom: 24px;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  span:first-child {
    margin-right: 35px;
    color: ${({ theme }) => theme.color.gray03};
  }
  span:last-child {
    color: ${({ theme }) => theme.color.primary};
    font-weight: normal;
  }
`;

export interface PersonInChargeFormProps {
  initValue;
  accountValue?;
  onSubmit;
  isLoading;
  readOnly;
  onSwitchForm: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  onChangeForm: () => void;
}

const PersonInChargeForm = ({
  initValue,
  accountValue,
  onSubmit,
  isLoading,
  readOnly,
  onChangeForm,
  onSwitchForm
}: PersonInChargeFormProps) => {
  const [form] = useForm();

  useEffect(() => {
    if (!form || !initValue) {
      return;
    }

    form.setFieldsValue(initValue);
  }, [form, initValue]);

  useEffect(() => {
    if (!form || !accountValue) {
      return;
    }
    const { name, email, cellPhone } = accountValue || {};
    const data = {
      settleManagerName: name,
      settleManagerEmail: email,
      settleManagerPhone: cellPhone
    };

    form.setFieldsValue(data);
  }, [form, accountValue]);

  return (
    <Form
      autoComplete="off"
      layout="vertical"
      name="personInChargeForm"
      form={form}
      onFinish={onSubmit}
      onFieldsChange={onChangeForm}
      validateMessages={validateMessages}
    >
      <Form.Item name="settleManagerName" label="이름" rules={[{ required: true }]}>
        <Input placeholder={!readOnly ? '이름을 입력해주세요' : null} readOnly={readOnly} />
      </Form.Item>
      <Form.Item name="settleManagerEmail" label="이메일" rules={[{ required: true, type: 'email' }]}>
        <Input placeholder={!readOnly ? 'example@xxx.com' : null} readOnly={readOnly} />
      </Form.Item>
      <Form.Item
        name="settleManagerPhone"
        label="전화번호"
        rules={[{ required: true }, onlyNumberValidator<string>('-없이 숫자만 입력'), minLength<string>(7)]}
      >
        <Input placeholder={!readOnly ? '전화번호를 입력해주세요' : null} readOnly={readOnly} />
      </Form.Item>
      {initValue?.barobillId ? (
        <Text>
          <span>바로빌 계정</span>
          <span>{initValue?.barobillId}</span>
        </Text>
      ) : null}

      <Form.Item shouldUpdate>
        {() =>
          readOnly ? (
            <BottomButton type="primary" htmlType="button" className="settlement-submit" onClick={onSwitchForm}>
              정보 수정하기
            </BottomButton>
          ) : (
            <BottomButton
              type="primary"
              htmlType="submit"
              className="settlement-submit"
              disabled={!form.isFieldsTouched(true) || !!form.getFieldsError().filter(({ errors }) => errors.length).length}
              loading={isLoading}
            >
              정보 저장하기
            </BottomButton>
          )
        }
      </Form.Item>
    </Form>
  );
};

export default PersonInChargeForm;
