import { Button } from 'antd';
import { TDetailInfo, TMenuContent, TTemplates } from 'interfaces/booking';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import ArtifactBtn from './ArtifactBtn';
import ArtifactDetail from './ArtifactDetail';
import ArtifactMenu from './ArtifactMenu';

const CustomRow = styled.div`
  height: 72px;
  padding: 0;
  border-bottom: 1px solid #dcdcdc;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .title {
    width: 239px;
    height: 100%;
    padding: 21px 0 18px;
    line-height: 2;
    border-right: 1px solid #dcdcdc;
    background-color: #ffffff;
  }

  .text {
    padding: 0 25px;
    font-size: 16px;
    font-weight: bold;

    &:first-child {
      border-right: 2px solid;
    }
  }

  .button-list {
    width: 85%;
    height: 100%;
    text-align: left;
    margin-left: 0 !important;
    padding-right: 24px;
    background-color: #f7f7f7f7;
  }
`;

interface TemplateProps {
  detailInfo: TDetailInfo;
  getMenuDetail: (v: TDetailInfo) => void;
  weekDays: string[];
  templates: TTemplates;
  menuDetail: { content: TMenuContent };
  deleteMenuDetail: (v: string) => void;
  handleDialog: () => void;
  allComponent?: React.ReactNode;
}

const Template = ({
  detailInfo,
  getMenuDetail,
  weekDays,
  templates,
  menuDetail,
  deleteMenuDetail,
  handleDialog,
  allComponent
}: TemplateProps) => {
  const { template, artifacts } = templates;

  return (
    <div>
      <CustomRow>
        <div className="title">
          <span className="text">{template.name}</span>
          <span className="text">{moment(template.date).format('HH:mm')}</span>
        </div>
        <div className="button-list">
          {weekDays.map((day, idx) => {
            const dayData = artifacts?.find((artifact) => moment(artifact.bookingDate).format('M. DD.') === day);
            if (!dayData) {
              return (
                <ArtifactBtn key={day}>
                  <span>-</span>
                </ArtifactBtn>
              );
              // return <ArtifactBtn key={`null-${uid}`} type="" />;
            }
            const { status, menus, artifactIdx } = dayData;
            /**
             * status == DRAFT(설정중) && menus !== null : 메뉴 노출에 불 x
             * status == CLOSED(예약종료) && menus !== null : 메뉴 노출에 불 x
             * status == BOOKING(예약중) && menus !== null : 메뉴 노출에 불 o
             * status == DRAFT(설정중) && menus === null : 메뉴 등록 버튼
             * status == EMPTY(메뉴 미등록) : 등록된 메뉴 없음
             * bookingDate(예약일)에 해당하는 데이터가 없을 시 : -
             */
            let menuType;
            if (status === 'DRAFT' && menus !== null) {
              menuType = 'off-light';
            } else if (status === 'CLOSED' && menus !== null) {
              menuType = 'off-light';
            } else if (status === 'BOOKING' && menus !== null) {
              menuType = 'one-light';
            } else if (status === 'EMPTY') {
              menuType = 'EMPTY';
            } else if (status === 'DRAFT' && menus === null) {
              return (
                <ArtifactBtn key={`artifact-${artifactIdx}`}>
                  <Button
                    onClick={() => artifactIdx && template.idx && getMenuDetail({ artifactIdx, templateIdx: template.idx })}
                  >
                    등록
                  </Button>
                </ArtifactBtn>
              );
            }

            return (
              <ArtifactMenu
                isBorder={idx === 6 ? { borderRight: '1px solid #dcdcdc' } : undefined}
                key={artifactIdx}
                type={menuType}
                artifactIdx={artifactIdx}
                templateIdx={template.idx}
                menus={menus || []}
                detailInfo={detailInfo}
                selMenuDet={getMenuDetail}
              />
            );
          })}
        </div>
      </CustomRow>
      <ArtifactDetail
        templateIdx={template.idx}
        detailInfoIdx={detailInfo.templateIdx}
        menuDet={menuDetail}
        handleDialog={handleDialog}
        menuDel={deleteMenuDetail}
        allComponent={allComponent}
      />
    </div>
  );
};

export default Template;
