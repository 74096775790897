import React, { useEffect, useState } from 'react';
import { Button, Form } from 'antd';
import { FileUpload } from 'components';
import { validateMessages } from 'validators';
import { FlexBoxFullWidthCenter, BottomButtonArea } from 'styles';
import { ImageSizeNotice } from './styles';

const { useForm } = Form;

export interface AdmissionStepFourFormProps {
  onNextStep;
  onPreviousStep;
  loading;
}

const AdmissionStepFourForm = ({ onNextStep, onPreviousStep, loading }: AdmissionStepFourFormProps) => {
  const [form] = useForm();

  const [, forceUpdate] = useState({});

  // To disable submit button at the beginning.
  useEffect(() => {
    forceUpdate({});
  }, []);

  const onSubmit = async (values) => {
    const { bizFile, accountFile } = values;
    onNextStep({
      bizFile: bizFile[0],
      accountFile: accountFile[0]
    });
  };

  return (
    <Form
      autoComplete="off"
      layout="vertical"
      name="admission_step4_form"
      form={form}
      onFinish={onSubmit}
      validateMessages={validateMessages}
    >
      <FlexBoxFullWidthCenter direction="column" justify="space-between" style={{ minHeight: 618 }}>
        <FlexBoxFullWidthCenter direction="column" align="flex-start">
          <FlexBoxFullWidthCenter justify="space-between" align="flex-start">
            <Form.Item name="bizFile" label="사업자등록증 파일" rules={[{ required: true }]}>
              <FileUpload maxCount={1} accept="image/*,.pdf" />
            </Form.Item>
            <Form.Item name="accountFile" label="통장사본 파일" rules={[{ required: true }]}>
              <FileUpload maxCount={1} accept="image/*,.pdf" />
            </Form.Item>
          </FlexBoxFullWidthCenter>
          <ImageSizeNotice>
            <li>사진은 40mb까지 첨부하실 수 있습니다.</li>
          </ImageSizeNotice>
        </FlexBoxFullWidthCenter>
        <Form.Item shouldUpdate style={{ width: '100%' }}>
          {() => {
            return (
              <BottomButtonArea>
                <Button className="admission-submit-btn prev-btn" onClick={onPreviousStep}>
                  이전
                </Button>
                <Button
                  className="admission-submit-btn"
                  type="primary"
                  htmlType="submit"
                  disabled={!form.isFieldsTouched(true) || !!form.getFieldsError().filter(({ errors }) => errors.length).length}
                  loading={loading}
                >
                  제출하기
                </Button>
              </BottomButtonArea>
            );
          }}
        </Form.Item>
      </FlexBoxFullWidthCenter>
    </Form>
  );
};

export default AdmissionStepFourForm;
