import {
  ic_assigning_agent,
  ic_canceled,
  ic_delivered,
  ic_delivering,
  ic_picking_up,
  ic_reserved
} from 'pages/ReserveStatus/image';
import { MomentInput } from 'moment';
import { Range } from 'react-date-range';

export interface BookingInfo {
  leaderId: string;
  leaderName: string;
  amount: number;
  shippingIdx: number;
}

export interface Menu {
  menuId: string;
  menuName: string;
  quantity: number;
  amount: number;
}

export interface DeliveryInfo {
  company: {
    companyId: string;
    companyName: string;
  };
  spot: {
    roadAddress: string;
    roadAddressDetail: string;
    spotKey: string;
    spotName: string;
  };
  booking: {
    quantity: number;
    totalAmount: number;
    serviceTime: string;
    bookingInfo: Array<BookingInfo>;
    menus: Array<Menu>;
  };
  store: {
    cellPhone: string;
  };
}

export interface IDefaultData {
  sid: string | undefined;
  spotKey: string;
  bookingDate: string;
  bookingArtifactIdx?: number | string;
  agentCount?: number;
}

export interface IBooking {
  bookedCount: number;
  bookedPrice: number;
  bookingArtifactIdx: number;
  bookingOfficeIdx: number;
  bookingStoreIdx: number;
  bookingStoreName: string;
  closeTime: Date;
  canceledCount: number;
  canceledPrice: number;
  companyId: string;
  companyName: string;
  couponId: string;
  menuId: string;
  menuName: string;
  spotKey: string;
  spotName: string;
  bookingOfficeName: string;
  userId: string;
  bookedUserName: string;

  row: string;
  rowSpan: number;
  companyRowSpan: number;
}

interface ISpot {
  spotKey: string;
  spotName: string;
  menus: IBooking[];
  deliveries: object;
  bookingArtifactIdx: number;
  bookedCount: number;
  couponId: string;
}

interface IBookingCompanyInfos {
  spots: ISpot[];
  companyName: string;
  companyId: string;
  spotName: string;
  bookedCount: number;
}

export interface BookingInfos {
  bookingCompanyInfos: Array<IBookingCompanyInfos>;
  bookingStoreIdx: number;
  bookingStoreName: string;
}

export const statusType = {
  RESERVED: { img: ic_reserved, status: '배차예약', style: { color: '#6C6C6C', background: '#EFEFEF' } },
  ASSIGNING_AGENT: { img: ic_assigning_agent, status: '배차진행', style: { color: '#FF7A00', background: '#FFECD6' } },
  PICKING_UP: { img: ic_picking_up, status: '픽업중', style: { color: '#01B1E9', background: '#D6FAFF' } },
  DELIVERING: { img: ic_delivering, status: '배달중', style: { color: '#00AB30', background: '#D6FFE8' } },
  DELIVERED: { img: ic_delivered, status: '배달완료', style: { color: '#0067FF', background: '#D6E7FF' } },

  CANCELED: { img: ic_canceled, status: '배달취소', style: { color: '#FF5630', background: '#FFE5E5' } },
  OWNER_CANCELED: { img: ic_canceled, status: '호출취소', style: { color: '#FF5630', background: '#FFE5E5' } },
  ASSIGN_FAILED: { img: ic_canceled, status: '배차실패', style: { color: '#FF5630', background: '#FFE5E5' } },
  SUBMIT_FAILED: { img: ic_canceled, status: '배차실패', style: { color: '#FF5630', background: '#FFE5E5' } }
};
export interface IPolling {
  orderIdx: number;
  status: string;
  agentAlias: string;
  companyName: string;
  companyKey: string;
  spotName: string;
  spotKey: string;
}

export interface BookingMenu {
  id: string;
  name: string;
  price: number;
  count?: number;
  image: {
    main: string;
    thumb: string;
  };
  status: string;
}

export interface TDetailInfo {
  artifactIdx: number;
  templateIdx: number;
}

export interface TMenu {
  id: string;
  name: string;
  price: number;
  image: {
    main: string;
    thumb: string;
  };
  status: string;
}

export interface TMenuContent {
  date: {
    booking: MomentInput;
    service: MomentInput;
    release: string;
    cancel: MomentInput;
    open: MomentInput;
    close: MomentInput;
  };
  company: {
    bookingGroup: string;
    name: string;
  };
  isCancel: boolean;
  menus?: TMenu[];
  limitCount: number;
  isPayment: boolean;
  status: string;
}

export interface TArtifacts {
  artifactIdx: number;
  status: string;
  menus: TMenu[] | null;
  bookingDate: MomentInput;
}

export interface TTemplate {
  idx: number;
  name: string;
  date: MomentInput;
}

export interface TTemplates {
  template: TTemplate;
  artifacts: TArtifacts[];
}
export interface TBooking {
  company: {
    id: string;
    name: string;
  };
  templates: TTemplates[];
}

export interface TCategoryMenu {
  categoryId: number;
  categoryName: string;
  menus: TMenu[];
}

export type TDate = { formatText: string; years: string } & Range;

export interface TStats {
  groupByName: string;
  bookingGroup: number;
  bookingPrice: number;
  companyName: string;
  bookingCount: number;
}
export interface TStore {
  date: TDate;
  graphData?: unknown[] | Record<string, undefined>;
  groupBy: string;
  company?: string | null;
  booking?: number | null;
  companyList?: string[];
  bookingList?: number[];
  statsList?: TStats[] | null;
}
