import { useEffect } from 'react';
import { channelTalkService, debounce, makeAddress } from 'utils';
import { useMeInfo } from './useMeInfo';

const boot = debounce((config) => {
  channelTalkService.boot(config);
}, 300);

export const useChannelTalk = (customLauncher?: boolean, unmount?: boolean) => {
  const { meInfo, selectedStore } = useMeInfo();
  useEffect(() => {
    // 애플심사용?? TODO PC모드로만 동작시 제거
    if (selectedStore?.id === '000000' || meInfo?.admin?.id === '000000') {
      channelTalkService.shutdown();
      return;
    }

    let storeAddress: string;
    if (selectedStore) {
      storeAddress = makeAddress(
        selectedStore.addressType,
        selectedStore.roadAddress,
        selectedStore.jibunAddress,
        selectedStore.address,
        selectedStore.addressDetail
      );
    } else {
      storeAddress = makeAddress(
        meInfo?.storeList?.[0]?.addressType as 'R' | 'J',
        meInfo?.storeList?.[0]?.roadAddress,
        meInfo?.storeList?.[0]?.jibunAddress,
        meInfo?.storeList?.[0]?.address,
        meInfo?.storeList?.[0]?.addressDetail
      );
    }

    const profile = {
      channel: `사장님용`,
      description: meInfo?.admin ? '로그인사용자' : '익명사용자',
      username: meInfo?.admin?.name ?? (meInfo?.admin ? meInfo?.admin?.id : '로그인하지 않은 유저'),
      // company: selectedStore?.name,
      mobileNumber: meInfo?.admin?.cellPhone ?? selectedStore ? selectedStore?.cellphone : meInfo?.storeList?.[0]?.storePhone,
      // email: '',
      // companyAddress: '',
      storeAccountType: selectedStore?.sid ? '제휴점계정' : '상위계정',
      storeCount: meInfo?.storeList?.length,
      storeName: selectedStore ? selectedStore?.name : meInfo?.storeList?.[0]?.name,
      storeAddress,
      storeId: selectedStore ? selectedStore?.sid : meInfo?.storeList?.[0]?.sid,
      storeAccountId: meInfo?.admin?.said,
      userId: meInfo?.admin?.said
      // companyId: ''
    };
    let customLauncherSelector: string;
    let hideChannelButtonOnBoot: boolean;
    if (customLauncher) {
      customLauncherSelector = '.custom-channel';
      hideChannelButtonOnBoot = true;
    }
    // React StrictMode mode 대응 debounce
    boot({
      memberId: meInfo?.admin?.id ? `store_${meInfo?.admin?.id}` : null,
      profile,
      customLauncherSelector,
      hideChannelButtonOnBoot
    });

    // eslint-disable-next-line consistent-return
    return () => {
      if (unmount) {
        boot({
          memberId: meInfo?.admin?.id ? `store_${meInfo?.admin?.id}` : null,
          profile
        });
      }
    };
  }, [selectedStore, meInfo, customLauncher, unmount]);
};

export const useChannelTalkScrollHide = () => {
  let timer;
  const handler = () => {
    if (window.innerHeight + window.pageYOffset >= document.body.scrollHeight) {
      clearTimeout(timer);
      $('div[data-ch-testid=launcher]:not([hidden])').fadeOut(500, function u() {
        $('div[data-ch-testid=launcher]:not([hidden])').attr('style', 'display: none !important');
      });
    } else if (window.innerHeight + window.pageYOffset < document.body.scrollHeight) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        $('div[data-ch-testid=launcher]:not([hidden])').attr('style', 'display: block !important');
      }, 100);
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', handler);

    return () => {
      window.removeEventListener('scroll', handler);
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
