import {
  getAdminSales,
  getSalesStatsForGraph,
  getSalesStatsForList,
  getSalesStatsListDetail,
  getSalesStatsSales,
  getStoreCompany,
  getStoreStatsAdmin
} from 'apis';
import { TInfiniteReturn } from 'interfaces/store';
import { QueryFunctionContext, useInfiniteQuery, useQuery } from 'react-query';
import { debounce } from 'utils';

interface Ttype {
  startDate: string;
  endDate: string;
  kind?: string;
}

export const useAdminSales = (params: unknown) => useQuery(['getAdminSales', params], () => getAdminSales(params));

export const useSalesStatsGraph = (params: Ttype, sid?: string) =>
  useQuery(
    ['getSalesStatsForGraph', sid, params],
    () => {
      if (!sid) {
        throw new Error(`[sid] required`);
      }
      return getSalesStatsForGraph(sid, params);
    },
    {
      enabled: !!sid && params.kind !== 'PAYMENT'
    }
  );

export const useSalesStatsList = (params: unknown, sid?: string, config?: Record<string, unknown>) =>
  useQuery(
    ['getSalesStatsForList', sid, params],
    () => {
      if (!sid) {
        throw new Error(`[sid] required`);
      }
      return getSalesStatsForList(sid, params);
    },
    {
      enabled: !!sid,
      ...config
    }
  );

export const useSalesStatsListDetail = (params: unknown, sid?: string) =>
  useQuery(
    ['getSalesStatsListDetail', sid, params],
    () => {
      if (!sid) {
        throw new Error(`[sid] required`);
      }
      return getSalesStatsListDetail(sid, params);
    },
    {
      enabled: !!sid
    }
  );

export const useStoreStatsAdmin = (params: Record<string, unknown>) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const debounceGetQuery: (...args: any[]) => Promise<TInfiniteReturn<any>> = debounce(async (info: QueryFunctionContext) => {
    const searchParams = {
      ...params,
      page: info.pageParam ?? 1
    };
    const result = await getStoreStatsAdmin(searchParams);
    const { dataList, totalCount } = result;
    const nextId = searchParams.page * ((params.pageRow as number) || 100) < totalCount ? searchParams.page + 1 : null;
    return { data: dataList, nextId, previousId: null };
  }, 100);
  return useInfiniteQuery(
    ['getStoreStatsAdmin', params],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (info): Promise<TInfiniteReturn<any>> => {
      return debounceGetQuery(info);
    },
    {
      getPreviousPageParam: (firstPage) => firstPage.previousId ?? undefined,
      getNextPageParam: (lastPage) => lastPage.nextId ?? undefined
    }
  );
};

export const useSalesStatsSales = (params: Ttype, sid: string) => {
  const { kind, ...other } = params;
  return useQuery(['getSalesStatsSales', params, sid], () => getSalesStatsSales(sid, other), {
    enabled: !!sid && kind === 'PAYMENT'
  });
};

export const useStoreCompany = (params: unknown) =>
  useQuery(['getStoreCompany', params], () => getStoreCompany(params), {
    enabled: !!params
  });
