import React from 'react';
import moment from 'moment';
import { List } from 'components';
import { FlexBoxCenter, FlexBoxFullWidthCenter } from 'styles';

import { ListItem } from '../styles';

const Grade = {
  MANAGER: '매니저',
  STAFF: '직원'
};

const EmployeeStatus = {
  PREPARATION: '대기중',
  CANCEL: '취소',
  REFUSE: '거절',
  REST: '휴직',
  WORK: '근무'
};

const EmployeeApplyHistory = ({ employeeApplyList, onClick }) => {
  return (
    <List
      items={employeeApplyList}
      renderItem={({ employeeApplyIdx, grade, storeName, inviteDate, status, approveDate, storeAddress }) => {
        let statusMessage: string;
        if (status === 'PREPARATION') {
          statusMessage = '터치하여 수락/거절 하세요';
        } else if (status === 'WORK') {
          statusMessage = '근무중인 요청입니다.';
        } else if (status === 'CANCEL') {
          statusMessage = '거절한 요청입니다.';
        }

        return (
          <ListItem
            key={employeeApplyIdx}
            onClick={() => (status === 'PREPARATION' ? onClick(employeeApplyIdx, storeName, grade, storeAddress) : null)}
            disabled={status !== 'PREPARATION'}
          >
            <FlexBoxCenter className="top-desc" justify="space-between">
              <div>요청일: {moment(inviteDate).format('YYYY.MM.DD HH:mm')}</div>
            </FlexBoxCenter>
            <FlexBoxCenter justify="space-between">
              <FlexBoxFullWidthCenter direction="column" align="flex-start" gap="6px">
                <FlexBoxFullWidthCenter justify="space-between">
                  <div>{approveDate ? `등록일: ${moment(approveDate).format('YYYY.MM.DD HH:mm')}` : '-'}</div>
                  <div>{Grade[grade]}</div>
                </FlexBoxFullWidthCenter>
                <FlexBoxFullWidthCenter justify="space-between">
                  <div className="point">{storeName}</div>
                  <div className="point">({EmployeeStatus[status]})</div>
                </FlexBoxFullWidthCenter>
                <div className="redirect">{statusMessage}</div>
              </FlexBoxFullWidthCenter>
            </FlexBoxCenter>
          </ListItem>
        );
      }}
    />
  );
};

export default EmployeeApplyHistory;
