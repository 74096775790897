import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { MonthModal } from 'components';
import { useExcelDownload, useExportExcel, useMeInfo } from 'hooks';
import moment, { Moment } from 'moment';

const VatDeclarationExcelDownloadButton = () => {
  const { selectedStore } = useMeInfo();
  const { excelParams } = useExcelDownload();
  const [isOpen, setOpen] = useState<boolean>(false);
  const [currentDate, setCurrentDate] = useState({ startDate: moment(), endDate: moment() });
  const { mutate: exportExcel } = useExportExcel();

  useEffect(() => {
    if (excelParams?.date1 || excelParams?.date2) {
      setCurrentDate({ startDate: excelParams?.date1, endDate: excelParams?.date2 });
    }
  }, [excelParams?.date1, excelParams?.date2]);

  const handleClose = () => {
    setOpen(false);
  };

  const download = (params: unknown) => {
    exportExcel(params, {
      onSuccess: () => {
        alert('요청하신 파일생성이 완료되었습니다. 파일은 엑셀파일 보관함에서 다운로드 받을 수 있습니다.');
        // $('#dateModal').modal('hide');
        handleClose();
      },
      onError: () => {
        alert('요청에 실패하였습니다.');
      }
    });
  };

  const handleDateSelected = ({
    startDate,
    endDate
  }: {
    startDate: Moment;
    endDate: Moment;
    orderStatus?: string;
    logisticsValue?: string;
  }) => {
    const startDateString = moment(startDate).format('YYYY-MM-DD');
    const endDateString = moment(endDate).format('YYYY-MM-DD');
    setCurrentDate({ startDate, endDate });

    // SettlementVatDeclaration
    const contentType = 'SD';
    ReactGA.event({
      category: 'sikdang_sales SettlementVatDeclaration',
      action: 'sikdang_sales SettlementVatDeclaration_excel'
    });

    if (!selectedStore?.sid) {
      return;
    }

    const params: Record<string, unknown> = {
      sid: selectedStore.sid,
      startDate: startDateString,
      endDate: endDateString,
      contentType
    };

    download(params);
  };

  const handleExcelDownload = () => {
    setOpen(true);
  };

  return (
    <>
      <div id="excelBtn">
        <button type="button" className="excel-download vat-excel pull-right" onClick={handleExcelDownload}>
          부가가치세 신고 참고 자료 엑셀 다운로드
        </button>
      </div>
      <MonthModal
        isOpenCalendar={isOpen}
        startDate={moment(currentDate.startDate)}
        endDate={moment(currentDate.endDate)}
        dateSelected={handleDateSelected}
        close={handleClose}
        target={excelParams?.target}
      />
    </>
  );
};

export default VatDeclarationExcelDownloadButton;
