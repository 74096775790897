import React from 'react';
import { Modal } from 'antd';
import Calendar from '../Date/Calendar';

export interface CalendarModalProps {
  isVisible: boolean;
  date: Date;
  onChange: (v: Date) => void;
  close: (v: boolean) => void;
}

const CalendarModal = ({ isVisible, close, date, onChange }) => {
  const handleChange = (changedDate: Date) => {
    onChange(changedDate);
  };

  return (
    <Modal width={380} open={isVisible} title="날짜 설정" footer={null} closable onCancel={close}>
      <Calendar date={date} onChange={handleChange} />
    </Modal>
  );
};

export default CalendarModal;
