/* eslint-disable @typescript-eslint/naming-convention */
import ic_assigning_agent from './ic_assigning_agent.svg';
import ic_canceled from './ic_canceled.svg';
import ic_delivered from './ic_delivered.svg';
import ic_delivering from './ic_delivering.svg';
import ic_picking_up from './ic_picking_up.svg';
import ic_reserved from './ic_reserved.svg';

import state_canceled_on from './state_canceled_on.svg';
import state_delivered_on from './state_delivered_on.svg';
import state_delivering_on from './state_delivering_on.svg';
import state_picking_up_on from './state_picking_up_on.svg';
import state_reserved_on from './state_reserved_on.svg';

import state_delivered_off from './state_delivered_off.svg';
import state_delivering_off from './state_delivering_off.svg';
import state_picking_up_off from './state_picking_up_off.svg';
import state_reserved_off from './state_reserved_off.svg';

import noti_submit_failed from './noti_submit_failed.svg';
import noti_delivered from './noti_delivered.svg';
import noti_canceled from './noti_canceled.svg';

import decrement from './decrement.svg';
import increment from './increment.svg';

import volume from './ic_volume.svg';
import deleteLine from './deleteLine.png';

const status_on = {
  RESERVED: state_reserved_on,
  ASSIGNING_AGENT: state_reserved_on,
  PICKING_UP: state_picking_up_on,
  DELIVERING: state_delivering_on,
  CANCELED: state_canceled_on,
  DELIVERED: state_delivered_on
};

const status_off = {
  RESERVED: state_reserved_off,
  ASSIGNING_AGENT: state_reserved_off,
  PICKING_UP: state_picking_up_off,
  DELIVERING: state_delivering_off,
  CANCELED: state_canceled_on,
  DELIVERED: state_delivered_off
};

export {
  deleteLine,
  decrement,
  increment,
  ic_assigning_agent,
  ic_canceled,
  ic_delivered,
  ic_delivering,
  ic_picking_up,
  ic_reserved,
  state_canceled_on,
  status_on,
  status_off,
  noti_submit_failed,
  noti_delivered,
  noti_canceled,
  volume
};
