import { Button } from 'antd';
import { AccountUser } from 'interfaces/account';
import { StepLayout } from 'layouts';
import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { CompleteContainer } from './style';

const marketingMapping = {
  marketingPush: 'PUSH',
  marketingSms: 'SMS',
  marketingEmail: '이메일'
};

const CompleteSignUpContainer = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const { state } = location;
    const { signUpData } = (state as Record<string, unknown>) || {};
    if (!signUpData) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const completeData = useMemo(() => {
    const { state } = location;
    const { signUpData } = (state as Record<string, unknown>) || {};
    if (signUpData) {
      // return data;
      const { userInfo } = (signUpData as AccountUser) || {};
      const { agreementTerms } = userInfo || {};
      const init: Array<string> = [];
      const agreements = Object.entries(agreementTerms || {})
        .filter(([, v]) => v)
        .reduce((acc, [k]) => [...acc, marketingMapping[k]], init);
      return {
        ...userInfo,
        agreements: agreements.length ? `동의 (${agreements.join('/')})` : '미동의'
      };
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StepLayout
      desc="회원가입이 완료되었습니다!"
      content={
        <CompleteContainer>
          <div>
            <span className="complete-label sign-id">아이디</span>
            <b>{completeData?.id}</b>
          </div>
          <div>
            <span className="complete-label">이름</span>
            <b>{completeData?.name}</b>
          </div>
          <div>
            <span className="complete-label">이메일</span>
            <b>{completeData?.email}</b>
          </div>
          <div>
            <span className="complete-label">마케팅수신</span>
            <b>
              {completeData?.agreements}
              <br />
              <span className="complete-date">
                {completeData ? moment(completeData?.regDate).format('YYYY년 MM월 DD일 24hh시 mm분') : null}
              </span>
            </b>
          </div>
        </CompleteContainer>
      }
      bottom={
        <Link to="/">
          <Button type="primary">지금 바로 시작하기</Button>
        </Link>
      }
    />
  );
};

export default CompleteSignUpContainer;
