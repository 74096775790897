import { TopPopDescription } from 'components';
import { useMeInfo } from 'hooks';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import TopWrapper from './styles';

const urlMap = {
  ADMISSION: 'admission',
  PROCESSING: 'processing',
  EMPLOYEE: 'employee'
};

const TopPopDescriptionContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { meInfo } = useMeInfo();
  const [openAdmission, setCloseAdmission] = useState(true);
  const [openProcessingAdmission, setCloseProcessingAdmission] = useState(true);
  const [openEmployeeApply, setCloseEmployeeApply] = useState(true);
  const closetAdmission = (path: string) => {
    if (path === urlMap.ADMISSION) {
      setCloseAdmission(false);
    }
    if (path === urlMap.PROCESSING) {
      setCloseProcessingAdmission(false);
    }
    if (path === urlMap.EMPLOYEE) {
      setCloseEmployeeApply(false);
    }
  };

  const handleRedirect = (path: string) => {
    if (path.includes(urlMap.ADMISSION)) {
      closetAdmission(urlMap.ADMISSION);
      navigate(path, { state: { backgroundLocation: location } });
    }
    if (path.includes(urlMap.PROCESSING)) {
      closetAdmission(urlMap.PROCESSING);
      navigate('/requestHistory', { state: { [urlMap.PROCESSING]: true } });
    }
    if (path.includes(urlMap.EMPLOYEE)) {
      closetAdmission(urlMap.EMPLOYEE);
      navigate('/requestHistory', { state: { [urlMap.EMPLOYEE]: true } });
    }
  };

  const isNotAdmission = !meInfo?.storeList?.length;
  const isProcessAdmission = !!meInfo?.admissionStoreList?.filter(({ status }) => status === 'PREPARATION' || status === 'HOLD')
    ?.length;
  const isEmployeeApply = !!meInfo?.employeeApplyList?.filter?.(({ status }) => status === 'PREPARATION')?.length;

  if (openEmployeeApply && isEmployeeApply) {
    return (
      <TopWrapper>
        <TopPopDescription
          extra="수락/거절 하기"
          onClose={() => closetAdmission(urlMap.EMPLOYEE)}
          onRedirect={() => handleRedirect(`/${urlMap.EMPLOYEE}`)}
        >
          <div>회원님에게 직원 권한을 주려는</div>
          <div>매장이 있습니다.</div>
          <div>승인 하시겠습니까?</div>
        </TopPopDescription>
      </TopWrapper>
    );
  }
  if (openProcessingAdmission && isProcessAdmission) {
    return (
      <TopWrapper>
        <TopPopDescription
          extra={
            <>
              <div>자세히</div>
              <div>보기</div>
            </>
          }
          onClose={() => closetAdmission(urlMap.PROCESSING)}
          onRedirect={() => handleRedirect(`/${urlMap.PROCESSING}`)}
        >
          <div>현재 등록중인 매장이 있습니다.</div>
          <div>상세한 내용을 확인 하려면 자세히 보기를</div>
          <div>터치해 주세요.</div>
        </TopPopDescription>
      </TopWrapper>
    );
  }

  if (openAdmission && isNotAdmission) {
    return (
      <TopWrapper>
        <TopPopDescription
          extra="입정신청하러가기"
          onClose={() => closetAdmission(urlMap.ADMISSION)}
          onRedirect={() => handleRedirect(`/${urlMap.ADMISSION}`)}
        >
          <div>사장님, 아직 입점을 안 하셨군요!</div>
          <div>지금 바로 우리 가게를 최고의 모바일 식권앱</div>
          <div>
            <b>{`"식권대장"`}</b>에등록시키세요.
          </div>
        </TopPopDescription>
      </TopWrapper>
    );
  }

  return null;
};

export default TopPopDescriptionContainer;
