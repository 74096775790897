import { paramsToFormData } from 'utils/apiUtils';
import { IEvidence } from 'interfaces/settlement';
import { storeClient } from './apiClients';

export const getSettlementMetaInfo = async (storeId: string) => {
  const { data } = await storeClient.get(`/settlement/v1/store/${storeId}/metaInfo`);
  return data;
};

export const getSettlementFeeInfo = async (storeId: string) => {
  const { data } = await storeClient.get(`/settlement/v1/store/${storeId}/feeInfo`);
  return data;
};

export const saveSettlementMetaInfo = async (storeId: string, metaInfo) => {
  const { data } = await storeClient.post(`/settlement/v1/store/${storeId}/metaInfo`, paramsToFormData(metaInfo), {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
  return data;
};

export const updateSettlementBankAccount = async (storeId: string, bankAccount) => {
  const { data } = await storeClient.post(`/settlement/v1/store/${storeId}/account`, paramsToFormData(bankAccount), {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
  return data;
};

export const getEvidenceAgency = async (storeId: string) => {
  const { data } = await storeClient.get(`settlement/v1/store/${storeId}/evidence-agency`);
  return data;
};

export const updateEvidenceAgency = async (updateData) => {
  const { data } = await storeClient.put(`settlement/v1/store/evidence-agency`, updateData);
  return data;
};

export const getDeposit = async (storeId) => {
  const { data } = await storeClient.get(`settlement/v1/store/${storeId}/deposit`);
  return data;
};

export const getDepositDetail = async (storeId, settleProgressSeq, companySeq) => {
  const { data } = await storeClient.get(`settlement/v1/store/${storeId}/deposit/${settleProgressSeq}/${companySeq}`);
  return data;
};

export const getVat = async (storeId, startDate, endDate) => {
  const { data } = await storeClient.get(`settlement/v1/store/${storeId}/vat`, { params: { startDate, endDate } });
  return data;
};

export const getPersonInChargeOfSettlement = async (storeId) => {
  const { data } = await storeClient.get(`settlement/v1/store/${storeId}/manager`);
  return data;
};

export const getSettlementStoreAccount = async (storeId: string) => {
  const { data } = await storeClient.get(`settlement/v1/store-account/${storeId}`);
  return data;
};

export const savePersonInChargeOfSettlement = async (storeId, data) => {
  await storeClient.put(`settlement/v1/store/${storeId}/manager`, data);
};

export const getEvidences = async (storeId: string, params) => {
  const { data } = await storeClient.get<IEvidence[]>(`settlement/v1/store/${storeId}/evidences`, { params });
  return data;
};

export const getEvidenceDetail = async (storeId: string, evidenceIdx: number) => {
  const { data } = await storeClient.get(`/settlement/v1/store/${storeId}/evidences/${evidenceIdx}`);
  return data;
};

export const saveEvidence = async (storeId: string, evidenceIdx: number, params) => {
  await storeClient.put(`settlement/v1/store/${storeId}/evidences/${evidenceIdx}/issue-state`, params);
};
