import React, { useEffect, useMemo, useState } from 'react';
import { Form, Input, Button } from 'antd';
import { ValidStatus } from 'interfaces/admission';
import SignUpField from 'components/Input/ValidSearch';

// const { Search } = Input;

const { useForm } = Form;

const engReg = /[a-zA-Z]+/;
const numberReg = /\d+/;
const specReg = /[~`!@#$%^&*()_\-+={}[\];:"\\'|<>,.?/]+/;

const SignUpForm = ({ loading, searchLoading, onSignUp, onSearch }) => {
  const formItemLayout = {
    labelCol: {
      span: 24
      // xs: { span: 24 },
      // sm: { span: 6 }
    },
    wrapperCol: {
      span: 24
      // xs: { span: 24 },
      // sm: { span: 14 }
    }
  };

  const validateMessages = {
    required: '필수 입력 항목입니다.',
    types: {
      email: '형식에 맞지 않습니다.'
    }
    // ...
  };

  const [form] = useForm();

  const [, forceUpdate] = useState({});
  const [checked, setChecked] = useState<ValidStatus>(undefined);

  // To disable submit button at the beginning.
  useEffect(() => {
    forceUpdate({});
  }, []);

  const handleSearch = async (v: string) => {
    if (!v) {
      return false;
    }
    const signId = v;
    const result = await onSearch(signId);
    return result;
  };

  const handleValid = (status: ValidStatus) => {
    setChecked(status);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const validStatus = useMemo<{ status: '' | 'success' | 'validating' | 'error' | undefined; message: string }>(() => {
    if (checked === 'success') {
      return { status: 'validating', message: '사용 가능한 아이디입니다.' };
    }
    if (checked === 'error') {
      return { status: 'error', message: '이미 사용 중인 아이디입니다.' };
    }
    return { status: null, message: null };
  }, [checked]);

  return (
    <Form
      autoComplete="off"
      layout="vertical"
      name="sign_up_form"
      form={form}
      onFinish={onSignUp}
      validateMessages={validateMessages}
      {...formItemLayout}
    >
      <Form.Item
        id="signId"
        className="signId"
        name="signId"
        label="아이디"
        rules={[
          { required: true },
          () => ({
            validator(_, value) {
              if (value?.value && value.value.length >= 5 && value.value.length <= 15) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('5~15자 입력'));
            }
          }),
          () => ({
            validator(_, value) {
              if (value?.status === 'changed') {
                return Promise.reject(new Error('중복확인을 해주세요.'));
              }
              return Promise.resolve();
            }
          })
        ]}
        validateStatus={validStatus.status}
        help={validStatus.message}
      >
        <SignUpField
          placeholder="5~15자 영문, 숫자"
          isSearching={searchLoading}
          onSearch={handleSearch}
          onValid={handleValid}
          enterButtonText="중복확인"
        />
        {/* <Input placeholder="5~15자 영문, 숫자" /><Button>중복확인</Button> */}
        {/* <Search
          placeholder="5~15자 영문, 숫자"
          allowClear
          enterButton="중복확인"
          size="large"
          loading={searchLoading}
          onSearch={handlerSearch}
        /> */}
      </Form.Item>
      <Form.Item
        id="password"
        className="password"
        name="password"
        label="비밀번호"
        rules={[
          { required: true },
          () => ({
            validator(_, value) {
              if (value && value.length >= 8 && value.length <= 16) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('8~20자 입력'));
            }
          }),
          () => ({
            validator(_, value) {
              if (value && engReg.test(value)) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('영문 미포함'));
            }
          }),
          () => ({
            validator(_, value) {
              if (value && numberReg.test(value)) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('숫자 미포함'));
            }
          }),
          () => ({
            validator(_, value) {
              if (value && specReg.test(value)) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('특수문자 미포함 ( ? ! @ # )'));
            }
          })
        ]}
      >
        <Input.Password placeholder="8~15자 영문, 숫자, 특수문자" />
      </Form.Item>
      <Form.Item
        id="passwordRe"
        className="password-re"
        name="passwordRe"
        label="비밀번호 재입력"
        rules={[
          { required: true },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('비밀번호가 일치하지 않습니다.'));
            }
          })
        ]}
      >
        <Input.Password placeholder="8~15자 영문, 숫자, 특수문자" />
      </Form.Item>
      <Form.Item id="email" className="email" name="email" label="이메일" rules={[{ required: true }, { type: 'email' }]}>
        <Input placeholder="example@xxx.com" type="email" />
      </Form.Item>
      <Form.Item id="joinPath" className="join-path" name="joinPath" label="가입경로">
        <Input placeholder="식권대장사장님을 어떻게" />
      </Form.Item>
      <Form.Item shouldUpdate>
        {() => {
          const isTouched =
            form.isFieldTouched('signId') &&
            form.isFieldTouched('password') &&
            form.isFieldTouched('passwordRe') &&
            form.isFieldTouched('email');
          return (
            <Button
              type="primary"
              htmlType="submit"
              disabled={!isTouched || !!form.getFieldsError().filter(({ errors }) => errors.length).length}
              loading={loading}
            >
              가입하기
            </Button>
          );
        }}
      </Form.Item>
    </Form>
  );
};

export default SignUpForm;
