import { Button, Form } from 'antd';
import styled from 'styled-components';

export const SelectBox = styled(Form)`
  width: 100%;
  padding: 24.5px 26px;
  margin-bottom: 16px;
  display: flex;
  gap: 8px;
  position: relative;

  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 4px 2px 0px rgba(0, 0, 0, 0.25);

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 3px;
    width: 100%;
    background-color: #46b476;
  }
  .ant-form-item {
    margin: 0;
  }
  .ant-form-item-control-input,
  .ant-select-selector,
  .ant-select-selector input,
  .ant-select-selection-item {
    width: 130px;
    height: 32px !important;
    min-height: 32px;
    line-height: 32px !important;
    font-size: 14px;
  }
`;

export const StyledButton = styled(Button)`
  &,
  &.ant-btn:hover,
  &.ant-btn:focus {
    height: 32px;
    padding: 4px 16px;
    font-size: 14px;

    color: #fff;
    background: #212529;
    border-color: #212529;
    border-radius: 0.5px !important;
  }
`;

export const TableWrapper = styled.div`
  padding: 15px 26px;
  margin-bottom: 16px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 4px 2px 0px rgba(0, 0, 0, 0.25);

  .search-result {
    padding-bottom: 21px;
    font-size: 14px;
  }
`;

export const StyledTable = styled.table`
  width: 100%;
  th,
  td {
    padding: 7px 5px;
    border: none;
    border-bottom: 0.2px solid #000;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }
  th {
    background: #f9fafb;
  }
  td {
    cursor: pointer;
  }
`;
