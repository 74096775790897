import React, { useState } from 'react';
import { Button, Input, Radio } from 'antd';
import { useSaveEvidence } from 'hooks';
import { StyledModal } from './style';

const { TextArea } = Input;

const TaxRefuseModal = ({ isVisible, close, idx }) => {
  const { mutate } = useSaveEvidence();
  const [reason, setReason] = useState('');
  const [errorType, setErrorType] = useState('amount');

  const handleOk = async () => {
    const type = { amount: '금액 오류', period: '기간 오류', etc: '기타' };
    await mutate({
      ...idx,
      params: {
        issueStateCode: 4,
        cancellationReason: `${type[errorType]}_${reason}`
      }
    });
    setReason('');
    setErrorType('amount');
    close();
  };

  const handleReasonChange = ({ target }) => {
    setReason(target.value);
  };

  const handleChange = ({ target }) => {
    setErrorType(target.value);
  };

  return (
    <StyledModal
      title="계산서 거절"
      width={760}
      open={isVisible}
      onCancel={close}
      footer={
        <div className="modal-footer">
          <Button className="cancelBtn" onClick={close}>
            취소
          </Button>
          <Button className="okBtn" onClick={handleOk} disabled={!reason.length}>
            확인
          </Button>
        </div>
      }
    >
      <Radio.Group onChange={handleChange} value={errorType}>
        <Radio.Button value="amount">금액 오류</Radio.Button>
        <Radio.Button value="period">기간 오류</Radio.Button>
        <Radio.Button value="etc">기타</Radio.Button>
      </Radio.Group>
      <div className="desc">거절 사유를 상세히 입력해 주세요.</div>
      <TextArea
        maxLength={200}
        onChange={handleReasonChange}
        value={reason}
        placeholder="ex. 총액이 12,000원 더 나온 듯 합니다. 다시한번 확인해주세요"
      />
    </StyledModal>
  );
};

export default TaxRefuseModal;
