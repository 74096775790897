import { Button } from 'antd';
import { StepLayout } from 'layouts';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChannelTalkDescription } from 'components';
import { channelTalkService } from 'utils';

const FaultPasswordContainer = () => {
  const navigate = useNavigate();

  useEffect(() => {
    channelTalkService.boot({
      profile: {
        name: `${localStorage.getItem('magicCode') ? '테스트_' : ''}식권대장사장님 로그인하지 않은 유저입니다.`
      },
      customLauncherSelector: '.custom-channel',
      hideChannelButtonOnBoot: true
    });
  }, []);

  const next = () => {
    navigate('/agreeSignUp', { replace: true });
  };

  return (
    <StepLayout
      desc={
        <>
          <div>입력한 정보와 일치하는</div>
          <div>아이디가 없습니다.</div>
        </>
      }
      content={
        <ChannelTalkDescription>
          <div>
            <p>지금 식권대장 사장님앱에 가입해</p>
            <p>주변 회사의 임직원을</p>
            <p>고객으로 확보하세요.</p>
          </div>
          <div>
            <p>휴대전화번호가 변경된 경우에는,</p>
            <p>고객센터로 문의해 주세요.</p>
          </div>
        </ChannelTalkDescription>
      }
      bottom={
        <Button type="primary" onClick={next}>
          회원가입
        </Button>
      }
    />
  );
};

export default FaultPasswordContainer;
