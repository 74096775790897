import React from 'react';
import { RouteObject } from 'react-router-dom';

import Settlement from 'pages/Settlement';
import Employee from 'pages/Employee';
import Realtime from 'pages/Realtime';
import Menu from 'pages/Menu';
import StoreImage from 'pages/StoreImage';
import Setting from 'pages/Setting';
import ShippingOrderManagement from 'pages/ShippingOrderManagement';
import ReserveStats from 'pages/ReserveStats';
import ManageBookingMenu from 'pages/ManageBookingMenu';
import Review from 'pages/Review';
import SalesStats from 'pages/SalesStats';
import MainPage from 'pages/MainPage';
import Withdraw from 'pages/Withdraw';
import ExcelStorage from 'pages/ExcelStorage';
import ReserveStatus from 'pages/ReserveStatus';
import MasterStats from 'pages/MasterStats';
import RequestHistory from 'pages/RequestHistory';
import CalculateInfoPage from 'pages/CalculateInfoPage';
import Evidences from 'pages/Evidences';
import EvidenceDetail from 'pages/EvidenceDetail';
import RobotDeliveryPage from 'pages/RobotDelivery';

type IRouteType = {
  name: string;
  order?: number;
  excelDownload?: { type: string };
} & RouteObject;

export const routes: IRouteType[] = [
  { path: '/', name: '식권대장사장님', order: 1 },
  { path: '/forgotSignId', name: '아이디찾기', order: 2 },
  { path: '/forgotPassword', name: '비밀번호찾기', order: 2 },
  { path: '/agreeSignUp', name: '회원가입', order: 2 },
  { path: '/certification', name: '인증', order: 3 },
  { path: '/signUp', name: '회원가입', order: 4 },
  { path: '/alreadyExists', name: '회원가입', order: 4 },
  { path: '/certificationResult', name: '인증', order: 4 },
  { path: '/certificationFault', name: '인증', order: 4 },
  { path: '/completeSignUp', name: '회원가입', order: 5 },
  { path: '/changePassword', name: '비밀번호변경', order: 5 },
  { path: '/confirmReorganization', name: '사업자 정보 확인', order: 5 }
];

export const mainRoutes: IRouteType[] = [
  { path: '/', name: '식권대장사장님', element: <MainPage /> },
  { path: '/masterStats', name: '제휴점별 매출 통계', excelDownload: { type: 'v1' }, element: <MasterStats /> },
  { path: '/employee', name: '직원관리', element: <Employee /> },
  { path: '/setting', name: '설정', element: <Setting /> },
  { path: '/salesStats', name: '매출통계', excelDownload: { type: 'v1' }, element: <SalesStats /> },
  { path: '/realtime', name: '실시간 매출', excelDownload: { type: 'v1' }, element: <Realtime /> },
  { path: '/menus', name: '우리가게 메뉴판', element: <Menu /> },
  { path: '/storeImage', name: '제휴점 이미지 설정', element: <StoreImage /> },
  { path: '/review', name: '리뷰 관리', element: <Review /> },
  { path: '/calculateInfo', name: '입금정보', excelDownload: { type: 'v1' }, element: <CalculateInfoPage /> },
  { path: '/withdraw', name: '회원 탈퇴', element: <Withdraw /> },
  {
    path: '/shippingOrderMgt',
    name: '배송상품 주문관리',
    excelDownload: { type: 'v1' },
    element: <ShippingOrderManagement />
  },
  { path: '/manageBookingMenu', name: '주간 예약 관리', element: <ManageBookingMenu /> },
  { path: '/reserveStats', name: '예약 통계', excelDownload: { type: 'v1' }, element: <ReserveStats /> },
  { path: '/reserveStatus', name: '예약 현황', excelDownload: { type: 'v2' }, element: <ReserveStatus /> },
  { path: '/excelStorage', name: '엑셀파일 보관함', element: <ExcelStorage /> },
  { path: '/settlementMetaInfo', name: '정산 기준정보', element: <Settlement /> },
  { path: '/requestHistory', name: '요청 히스토리', element: <RequestHistory /> },
  { path: '/evidences', name: '계산서 발행 확인', element: <Evidences /> },
  { path: '/evidenceDetail', name: '계산서 발행 확인', element: <EvidenceDetail /> },
  { path: '/robotDelivery', name: '로봇배달 주문관리', element: <RobotDeliveryPage /> }
];

export const getRoutePath = (path: string) => mainRoutes.filter((route) => route.path === path);
export const getAppRoutePath = (path: string) => routes.filter((route) => route.path === path);

export const getName = (path: string) => getRoutePath(path)?.[0]?.name;
export const getAppName = (path: string) => getAppRoutePath(path)?.[0]?.name;

export const getExcelUsed = (path: string) => getRoutePath(path)?.[0]?.excelDownload;
