import { getStoreHost } from 'apis/host';
import React from 'react';
import { getToken, numberWithCommas } from 'utils';

const ExcelResultModal = ({ title, failFile, failCount, failList, successCount, alreadySuccessCount, returnFunc }) => {
  const getFailExcel = () => {
    const token = getToken();
    window.location.href = `${getStoreHost()}${encodeURI(
      `/excel/v1/down?access_token=${token}&fileId=${failFile}&fileName=실패주문엑셀`
    )}`;
  };

  const successMessage = '송장번호 일괄 등록이 완료되었습니다.';
  const alreadySuccessMessage = '이미 송장번호가 있는 주문건은 일괄등록 불가합니다. 수동으로 변경해주세요.';
  const failMessage = '실패한 주문을 엑셀 다운로드하여 다시 일괄 등록해주세요.';

  const rows = failList?.map((row) => {
    const { comName, payRoomIdx, userName, tsalesPrice, trackingNumber, logisticsName, useDate, failReason } = row;

    return (
      <tr className="table-row" key={payRoomIdx}>
        <td>{payRoomIdx}</td>
        <td>{comName}</td>
        <td>{userName}</td>
        <td>{numberWithCommas(tsalesPrice)}</td>
        <td>{useDate}</td>
        <td>{trackingNumber}</td>
        <td>{logisticsName}</td>
        <td>
          <span className="fail-status">실패</span>
        </td>
        <td>{failReason}</td>
      </tr>
    );
  });

  return (
    <div id="excelResultModal" className="modal fade" role="dialog">
      <div className="modal-dialog modal-xlg">
        <div className="modal-content" id="test">
          <div className="modal-header">
            <h4 className="modal-title">{title}</h4>
          </div>
          <div className="modal-body">
            <div className="uploadInfo">
              <div className="uploadInfoMsg">
                <h5 className="font-wei-bold">
                  성공 : {successCount}건 / 기등록 : {alreadySuccessCount} 건 /{' '}
                  <span className="fail-status"> 실패 : {failCount} 건 </span>{' '}
                </h5>
                {successCount > 0 && <h4 className="font-wei-bold">{successMessage}</h4>}
                {alreadySuccessCount > 0 && <h4 className="font-wei-bold">{alreadySuccessMessage}</h4>}
                {failCount > 0 && <h4 className="font-wei-bold">{failMessage}</h4>}
              </div>
              <div className="btn-group pull-right">
                <button type="button" className="fail-download-btn btn btn-basic" onClick={getFailExcel}>
                  실패 주문 엑셀 다운로드
                </button>
              </div>
            </div>
            <table className="table table-bordered table-hover excel-fail-table">
              <thead>
                <tr className="table-row">
                  <th scope="col">결제번호</th>
                  <th scope="col">회사명</th>
                  <th scope="col">결제자</th>
                  <th scope="col">결제 금액</th>
                  <th scope="col">결제일시</th>
                  <th scope="col">송장번호</th>
                  <th scope="col">택배사</th>
                  <th scope="col">상태</th>
                  <th scope="col">사유</th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </table>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-sikdae" data-dismiss="modal" onClick={returnFunc}>
              확인
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExcelResultModal;
