import { CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { getAppName } from 'configs/route';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useMeInfo } from 'hooks';
import { setPassCertification } from 'utils';

const Header = styled.header`
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  color: ${({ theme }) => theme.color.black};
  position: relative;
  height: 44px;
  width: 100%;
  max-width: 480px;
  padding: 10px 0;
`;

const StyledButton = styled(Button)`
  position: absolute;
  right: 10px;
  top: 2px;
  padding: 10px;
  border: none;
  box-shadow: none;
`;

const TransitionHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { meInfo } = useMeInfo();

  const handleClick = () => {
    const id = meInfo?.admin?.id;
    if (location.pathname === '/' && id) {
      setPassCertification(id, true);
    }
    navigate('/');
  };

  const text = getAppName(location.pathname) || '';
  return (
    <Header>
      {text}
      <StyledButton icon={<CloseOutlined />} onClick={handleClick} />
    </Header>
  );
};

export default TransitionHeader;
