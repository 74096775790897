import React from 'react';
import styled from 'styled-components';

const MenuButon = styled.div`
  width: 14%;
  height: 100%;
  display: inline-block;
  min-width: 30px;
  border-left: 1px solid #dcdcdc;
  border-radius: 0;
  vertical-align: middle;

  .artifact-menu-name {
    border-left: 1px solid #dcdcdc;
    background-color: #ffffff;
  }

  .btn {
    background: #f7f7f7f7;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  p {
    font-size: 12;
  }
`;

interface ArtifactBtnProps {
  children: React.ReactNode;
}

const ArtifactBtn = ({ children }: ArtifactBtnProps) => {
  return (
    <MenuButon>
      <div className="btn">{children}</div>
    </MenuButon>
  );
};

export default ArtifactBtn;
