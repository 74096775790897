import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Select, Form } from 'antd';
import { useEvidences, useMeInfo } from 'hooks';
import { IEvidenceParams } from 'interfaces/settlement';

import { SelectBox, StyledButton, TableWrapper, StyledTable } from './style';

const searchIssueTypes = [
  { label: '구분 전체', value: null },
  { label: '매출', value: 0 },
  { label: '매입', value: 1 }
];

const searchTaxInvoiceTypes = [
  { label: '유형 전체', value: null },
  { label: '종이세금계산서', value: 'PAPER' },
  { label: '전자세금계산서', value: 'ELECTRONIC' }
];

const Evidences = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [params, setParams] = useState<IEvidenceParams>({
    searchYear: moment().year().toString(),
    searchIssueType: null,
    searchTaxInvoiceType: null
  });
  const { selectedStore } = useMeInfo();
  const { data } = useEvidences(selectedStore.sid, params);

  const searchYears = useMemo(() => {
    const years = [];
    for (let i = 2023; i <= moment().year(); i += 1) {
      years.push(i.toString());
    }
    return years.map((year) => ({ label: `${year}년`, value: year }));
  }, []);

  const handleClick = (value) => {
    setParams(value);
  };

  const handleRowClick = (evidenceSeq: number) => {
    navigate(`/evidenceDetail`, { state: { evidenceSeq } });
  };

  return (
    <div>
      <SelectBox onFinish={handleClick} form={form}>
        <Form.Item name="searchYear" initialValue={`${moment().year()}`}>
          <Select options={searchYears} />
        </Form.Item>
        <Form.Item name="searchIssueType" initialValue={null}>
          <Select options={searchIssueTypes} />
        </Form.Item>
        <Form.Item name="searchTaxInvoiceType" initialValue={null}>
          <Select options={searchTaxInvoiceTypes} />
        </Form.Item>
        <StyledButton htmlType="submit">검색</StyledButton>
      </SelectBox>
      <TableWrapper>
        세금계산서 발행(전자/종이) 내역을 확인하는 페이지입니다. 전자세금계산서는 확인하는 용도로만 사용되며 승인, 거절은 안내된
        매뉴얼에 따라 진행을 해주세요. <br />
        종이세금계산서는 생성 후 발급대기 상태일 때 10일내 안에 승인, 거절이 가능하며, 이후 자동 승인완료가 진행됩니다.
      </TableWrapper>
      <TableWrapper>
        <div className="search-result">검색결과: {data?.length || 0}건</div>
        <StyledTable>
          <thead>
            <tr>
              <th>구분</th>
              <th>계산서 식별 코드</th>
              <th>품목명</th>
              <th>유형</th>
              <th>공급가액</th>
              <th>부가세</th>
              <th>총액</th>
              <th>정산 일자</th>
              <th>작성 일자</th>
              <th>생성일시</th>
              <th>확인일시</th>
              <th>승인일시</th>
              <th>발급 상태</th>
            </tr>
          </thead>
          <tbody>
            {data?.length ? (
              data?.map((item) => {
                const textColor = { 발급거절: 'red', 발급대기: '#1400FF' };
                return (
                  <tr key={item.evidenceSeq} onClick={() => handleRowClick(item.evidenceSeq)}>
                    <td>{item.issueType}</td>
                    <td>{item.evidenceSeq}</td>
                    <td>{item.itemName}</td>
                    <td>{item.evidenceType}</td>
                    <td>{item.evidenceSupplyAmt?.toLocaleString()}</td>
                    <td>{item.evidenceVATAmt?.toLocaleString()}</td>
                    <td>{item.evidenceAmt?.toLocaleString()}</td>
                    <td>{item.settlePeriod || '-'}</td>
                    <td>{item.publishDateYmd || '-'}</td>
                    <td>{item.createdDate || '-'}</td>
                    <td>{item.firstConfirmDate || '-'}</td>
                    <td>{item.issueDate || '-'}</td>
                    <td style={{ color: textColor[item.settleIssueState] }}>{item.settleIssueState}</td>
                  </tr>
                );
              })
            ) : (
              <tr style={{ textAlign: 'center' }}>
                <td colSpan={13}>데이터가 존재하지 않습니다.</td>
              </tr>
            )}
          </tbody>
        </StyledTable>
      </TableWrapper>
    </div>
  );
};

export default Evidences;
