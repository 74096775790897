import styled from 'styled-components';
import { Modal } from 'antd';

export const StyledTable = styled.table`
  width: 100%;
  margin-bottom: 4px;
  th {
    padding: 12px;
    background: rgb(249, 250, 251);
    border: 0.8px solid rgba(34, 36, 38, 0.1);
    font-size: 14px;
    font-weight: 700;
    text-align: center;
  }
  td {
    padding: 12px;
    background: #fff;
    border: 0.8px solid rgba(34, 36, 38, 0.1);
    font-size: 14px;
    text-align: center;
  }
`;

export const SelectBox = styled.div`
  width: 100%;
  padding: 24.5px 26px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  position: relative;

  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 4px 2px 0px rgba(0, 0, 0, 0.25);

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 3px;
    width: 100%;
    background-color: #46b476;
  }

  span {
    min-width: 90px;
    padding: 7px;
    text-align: center;
    align-self: center;
  }
  .button-group {
    justify-content: end;
  }
  .field-label {
    font-size: 14px;
    font-weight: 700;
  }
  .field-input {
    font-size: 14px;
    min-width: 143px;
    border-bottom: 0.2px solid #000;
  }
  button {
    display: flex;
    height: 36px;
    span {
      padding: 0;
    }
  }
  .approval,
  .approval:hover,
  .approval:focus {
    color: #fff;
    border: 1px solid #243346;
    border-radius: 0;
    background: #243346;
  }
`;

export const Wrapper = styled.div`
  .btn-group {
    display: flex;
    gap: 16px;
    padding-top: 28px;
  }
  .lineBtn,
  .lineBtn:hover,
  .lineBtn:focus {
    border: 1px solid #243346;
    border-radius: 0;
    background: #fff;
    color: #243346;
    font-size: 16px;
    font-weight: 700;
  }
  .pdfBtn {
    border-radius: 0;
    background: #fff;
    box-shadow: 0px 0px 0px 1px #0bb656 inset;
    color: #0bb656;
    font-size: 16px;
    font-weight: 700;
  }
  .display-flex {
    width: 100%;
    display: flex;
  }
  .display-flex.gap {
    gap: 10px;
  }
  .confirm-cancel-handler-modal {
    width: 10px;
  }
`;

export const FormWrapper = styled.div`
  width: 100%;
  margin-bottom: 4px;

  .title,
  .title-field,
  .title-input,
  .target,
  .ant-form-item-label,
  .ant-form-item-control {
    font-size: 14px;
    background: #f9fafb;
    border: 0.8px solid rgba(34, 36, 38, 0.1);
  }
  .title {
    padding: 12px 0;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
  }
  .title-field {
    width: 100%;
    max-width: 106px;
    padding: 15px;
    font-size: 15px;
    font-weight: 700;
    line-height: 25px;
    text-align: center;
  }
  .title-input {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .target {
    padding: 0 10px;
    display: flex;
    align-items: center;
    font-weight: 700;
  }
  .ant-form-item {
    flex: 1;
    margin: 0;
    .ant-form-item-label {
      width: 74px;
      padding: 12px;
      white-space: pre-wrap;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .ant-form-item-label label {
      margin: 0;
      font-size: 14px;
      font-weight: 700;
      white-space: pre-wrap;
      text-align: center;
    }
    .ant-form-item-label label:after {
      display: none;
    }
    .ant-form-item-control {
      padding: 0 10px;
      background: #fff;
      width: 100px;
    }
  }
`;

export const StyledModal = styled(Modal)`
  .ant-radio-group label {
    width: 110px;
    margin-right: 3px;
    border-radius: 50px;
    font-size: 14px;
    text-align: center;
  }
  .ant-radio-group label:before {
    display: none;
  }
  .ant-radio-button-wrapper {
    border-radius: 20px;
    color: #21ba45;
    border: 1px solid #21ba45;
    box-shadow: 0px 0px 0px 0px rgba(34, 36, 38, 0.15) inset;
  }
  .ant-radio-button-wrapper-checked,
  .ant-radio-button-checked {
    color: #fff !important;
    background: #21ba45;
    border-radius: 50px;
  }
  .desc {
    padding: 14px;
  }
  textarea {
    border: 1px solid #e0b4b4;
    border-radius: 4px;
    background: #fff6f6;
    font-size: 14px;
  }
  .ant-modal-footer {
    padding: 0;
  }
  .modal-footer {
    padding: 17px 24px;
    button {
      width: 128px;
      border-radius: 0;
      box-shadow: 0px 0px 0px 0px rgba(34, 36, 38, 0.15) inset;
    }
    .cancelBtn {
      background: #e0e1e2;
    }
    .okBtn {
      color: #fff;
      background: #21ba45;
    }
    .ant-btn[disabled] {
      color: rgba(0, 0, 0, 0.25) !important;
      background: #f5f5f5 !important;
    }
  }
`;
