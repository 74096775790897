import React from 'react';
import moment from 'moment';
import { SignIdWrapper, ResultContentWrapper } from './style';

interface CertificationResultDescriptionProps {
  signId?: string;
  regDate?: string;
  viewPasswordDesc?: boolean;
}

const CertificationResultDescription = ({ signId, regDate, viewPasswordDesc }: CertificationResultDescriptionProps) => (
  <div>
    <SignIdWrapper>
      <div className="signId">{signId}</div>
      <div>{moment(regDate).format('YYYY.MM.DD 가입')}</div>
    </SignIdWrapper>
    {viewPasswordDesc ? (
      <ResultContentWrapper top="16px">
        <div>
          <p>비밀번호가 기억나지 않는다면</p>
          <p>비밀번호 재설정을 눌러주세요.</p>
        </div>
      </ResultContentWrapper>
    ) : null}
  </div>
);

export default CertificationResultDescription;
