import { useEffect } from 'react';

// eslint-disable-next-line import/prefer-default-export
export const useHistoryPop = (onPopState) => {
  useEffect(() => {
    const handler = () => {
      if (typeof onPopState === 'function') {
        onPopState();
      }
    };
    window.addEventListener('popstate', handler);
    return () => {
      window.removeEventListener('popstate', handler);
    };
  }, [onPopState]);
};
