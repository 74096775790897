import styled from 'styled-components';

const TopWrapper = styled.div`
  margin-top: -20px;
  margin-left: -20px;
  margin-right: -20px;
  width: calc(100% + 40px);
`;

export default TopWrapper;
