import { notification } from 'antd';

export * from './apiUtils';
export { default as channelTalkService } from './channelTalkService';
export * from './clientUtils';
export * from './eventUtils';
export * from './storages';
export * from './stringUtils';
export * from './validUtils';

type NotificationType = 'success' | 'info' | 'warning' | 'error';

interface OpenNotificationWithIconType {
  message: string;
  type: NotificationType;
  duration: number;
}

export const openNotificationWithIcon = ({ message, type = 'info', duration = 4.5 }: OpenNotificationWithIconType) => {
  notification[type]({
    message: '알림',
    description: message,
    duration
  });
};

export const unionTwoArray = (a: any, b: any) => {
  const unionedNewArray = [...new Set([...a, ...b])];
  return unionedNewArray;
};
