import moment, { MomentInput } from 'moment';

export const getQuerystring = (paramName, search) => {
  const name = paramName.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
  const results = search ? regex.exec(search) : regex.exec(window.location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export const addHyphen = (str: string) => {
  if (!str) {
    return '';
  }
  return `${str}`
    .replace(/[^0-9]/g, '')
    .replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, '$1-$2-$3')
    .replace('--', '-');
};

/**
 * 숫자 ,(콤마) 삽입
 * ex) 1000000 => 1,000,000
 * @param num
 */
export const encryption = (num: number) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const numberWithCommas = (x: number) => {
  if (!x) {
    return x;
  }
  return encryption(x);
};

export const deepClone = (obj: unknown) => {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }
  const result = Array.isArray(obj) ? [] : {};

  Object.keys(obj).forEach((key) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    result[key] = deepClone(obj[key]);
  });
  return result;
};

export const makeAddress = (
  addressType?: 'J' | 'R',
  roadAddress?: string,
  jibunAddress?: string,
  address?: string,
  addressDetail?: string
) => {
  let storeAddress: string;
  if (addressType === 'R') {
    storeAddress = `${roadAddress} ${addressDetail}`;
  } else if (addressType === 'J') {
    storeAddress = `${jibunAddress} ${addressDetail}`;
  } else if (!addressType) {
    storeAddress = `${address} ${addressDetail}`;
  }
  return storeAddress;
};

export const mainCategoryStringToArray = (mainCategory) => {
  return mainCategory?.replaceAll(/\[|\]| /g, '').split(',');
};

export const formatToHM = (date: MomentInput) => moment(date).format('HH:mm');
export const formatToMD = (date: MomentInput) => moment(date).format('M. DD.');
export const formatToYMD = (date: MomentInput) => moment(date).format('YYYY. M. DD');
export const formatToYMDHM = (date: MomentInput) => `${formatToYMD(date)} ${formatToHM(date)}`;

export const dateTimeToKorean = (time: string) => {
  let result = time.substring(0, time.indexOf('H'));
  if (result) {
    return `${result}시간`;
  }
  result = time.substring(0, time.indexOf('D'));
  return `${result}일`;
};

export const toJson = (json: unknown) => {
  if (!json) return '';
  return JSON.parse(JSON.stringify(json));
};

export const telWithHyphen = (tel: string) => {
  const part1 = tel?.slice(0, 3);
  const part2 = tel?.slice(3, 7);
  const part3 = tel?.slice(7, 11);
  return true ? tel : `${part1}-${part2}-${part3}`;
  // ToDo: 전화번호가 일관성있게 저장되지 않기에 Back 작업이 마무리될때까지 임시로 레어하게 출력
};
