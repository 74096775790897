import React from 'react';
import { Modal, Button } from 'antd';
import { FlexBoxFullWidthCenter, FlexBoxCenter, FlexBoxFullWidthHeight } from 'styles';
import improveImage from 'images/history/improve.png';
import { useLocation, useNavigate } from 'react-router-dom';

const ImproveModal = ({ title, admissionIdx, open, onClose }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const handleGotoSettlement = () => {
    onClose();
    navigate('/admission', { state: { backgroundLocation: location, admissionIdx } });
  };
  return (
    <Modal title={title} open={open} onCancel={onClose} footer={null} bodyStyle={{ height: 712 }}>
      <FlexBoxFullWidthHeight direction="column" justify="space-evenly">
        <FlexBoxFullWidthCenter direction="column">
          <img src={improveImage} alt="" />
          <FlexBoxCenter direction="column">
            <div>사장님께서 제출하신 자료에</div>
            <div>보완할 부분이 있습니다.</div>
          </FlexBoxCenter>
        </FlexBoxFullWidthCenter>
        <Button type="link" onClick={handleGotoSettlement}>
          보완하여 제출하기
        </Button>
      </FlexBoxFullWidthHeight>
    </Modal>
  );
};

export default ImproveModal;
