import React, { useMemo } from 'react';
import { Button, Input } from 'antd';
import { Quantity } from '../styles';
import { decrement, increment } from '../image';

const QuantityPicker = ({ onClick, value, disabled, orderIdx }) => {
  const item = useMemo(() => {
    let quantity = 0;
    let total = 0;
    if (value[orderIdx]) {
      quantity = value[orderIdx].length;
      value[orderIdx].forEach((menu) => {
        total += menu.amount;
      });
    }
    return { quantity, total };
  }, [value, orderIdx]);

  return (
    <div>
      <div className="desc">라이더</div>
      <Quantity key={value}>
        <div className="aria" aria-disabled={disabled}>
          <Button className="modifier left" onClick={() => onClick('decrement', orderIdx)}>
            <img src={decrement} alt="" />
          </Button>
          <Input className="screen" type="text" value={item.quantity} readOnly />
          <Button className="modifier right" onClick={() => onClick('increment', orderIdx)}>
            <img src={increment} alt="" />
          </Button>
        </div>

        {value[orderIdx] ? <div className="total">{item.total.toLocaleString()}원</div> : null}
      </Quantity>
    </div>
  );
};

export default QuantityPicker;
