import React, { useMemo } from 'react';
import { useBookingHistoriesBySpot } from 'hooks';
import { generate } from 'shortid';
import { Table } from '../styles';

const SpotBy = ({ bookingDate }) => {
  const { data } = useBookingHistoriesBySpot(bookingDate);

  const bookingSpot = useMemo(() => {
    if (data?.bookingSpotInfos) {
      return data?.bookingSpotInfos;
    }
    return [];
  }, [data]);

  return (
    <div>
      <Table>
        <thead>
          <tr>
            <th style={{ textAlign: 'center', width: '6%' }}>도/시 </th>
            <th style={{ textAlign: 'center', width: '8%' }}>시/군/구</th>
            <th style={{ textAlign: 'center', width: '30%' }}>도로명 주소</th>
            <th style={{ textAlign: 'center', width: '30%' }}>지번 주소</th>
            <th style={{ textAlign: 'center', width: '10%' }}>고객사명</th>
            <th style={{ textAlign: 'center', width: '10%' }}>배달지 별명</th>
            <th style={{ textAlign: 'center', width: '6%' }}>결제 완료 수량</th>
          </tr>
        </thead>
        <tbody>
          {bookingSpot.map((obj) => (
            <>
              {obj.bookingSpots.length > 0 && (
                <tr>
                  <td colSpan={7} className="bookingStoreName">
                    {obj.bookingStoreName}
                  </td>
                </tr>
              )}
              {obj.bookingSpots.map((item) => (
                <tr key={generate()}>
                  <td style={{ textAlign: 'center' }}>{item.sido}</td>
                  <td style={{ textAlign: 'center' }}>{item.sigungu}</td>
                  <td style={{ textAlign: 'center' }}>{item.roadAddress}</td>
                  <td style={{ textAlign: 'center' }}>{item.jibunAddress}</td>
                  <td style={{ textAlign: 'center' }}>{item.companyName}</td>
                  <td style={{ textAlign: 'center' }}>{item.spotName}</td>
                  <td style={{ textAlign: 'center' }}>{item.bookedCount}</td>
                </tr>
              ))}
            </>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default SpotBy;
