import { Form, Typography } from 'antd';
import { IBankCodeValue } from 'components/BankCode';
import BottomButton from 'components/Button/BottomButton';
import { BankAccountFormItem } from 'components/FormItem';
import { IAccountCheckResult } from 'interfaces/admission';
import React, { useEffect, useState } from 'react';
import { validateMessages } from 'validators';
import VFileUpload from 'components/VendysComponents/VFileUpload';
import theme from 'styles/theme';
import AccountHolder from './AccountHolder';

const { useForm } = Form;

export interface BankAccountFormProps {
  initValue;
  onSearch: (v: Partial<IBankCodeValue>) => Promise<IAccountCheckResult | undefined>;
  isSearching;
  onSubmit;
  isLoading;
  bankCodes;
  isPreparationHistory?: boolean;
}

const BankAccountForm = ({
  initValue,
  onSearch,
  isSearching,
  onSubmit,
  isLoading,
  bankCodes,
  isPreparationHistory
}: BankAccountFormProps) => {
  const [form] = useForm();
  const [isValidBankAccount, setValidBankAccount] = useState<undefined | null | boolean>();
  const [isNonCheckAccountHolder, setNonCheckAccountHolder] = useState<boolean>(false);

  useEffect(() => {
    if (!form || !initValue || !initValue?.contact) {
      return;
    }
    const { contact } = initValue;
    const contactValue = { ...contact };
    const { bankCode, accountNo } = contact;
    contactValue.bank = {
      bankCode,
      bankAccount: accountNo
    };
    form.setFieldsValue(contactValue);
  }, [form, initValue]);

  const handleSearch = async (values) => {
    const accountHolder = form.getFieldValue('accountHolder');
    const { bankCode, bankAccount } = values;
    const param: Record<string, unknown> = {
      accountHolder,
      bankCode,
      accountNo: bankAccount
    };
    return onSearch(param);
  };

  const handleValid = (isValid) => {
    setValidBankAccount(isValid);
  };
  const handleNonCheckAccountHolder = () => {
    const checked = !isNonCheckAccountHolder;
    if (!checked) {
      form.setFieldValue('accountHolder', initValue?.contact?.accountHolder);
    }
    setNonCheckAccountHolder(checked);
    return checked;
  };

  return (
    <Form
      autoComplete="off"
      layout="vertical"
      name="bankAccountFrom"
      form={form}
      onFinish={onSubmit}
      validateMessages={validateMessages}
    >
      <Form.Item name="accountHolder" label="예금주명(대표자 또는 사업자명의)" rules={[{ required: true }]}>
        <AccountHolder
          onChangeCheck={handleNonCheckAccountHolder}
          disabled={isPreparationHistory}
          initValue={initValue?.contact?.accountHolder}
        />
      </Form.Item>
      <div style={{ padding: '5px', fontSize: '13px', margin: '-20px 0px 20px', color: theme.color.gray02 }}>
        예금주명은 한글, 영문, 특수문자 및 공백문자 포함 최대 10자까지 입력 가능합니다.
      </div>
      <BankAccountFormItem
        nonCheckAccountHolder={isNonCheckAccountHolder}
        bankCodes={bankCodes}
        onSearch={handleSearch}
        isSearching={isSearching}
        checkValid={handleValid}
        disabled={isPreparationHistory}
      />
      <Form.Item
        name="accountFile"
        label="통장사본"
        hidden={isPreparationHistory || !isValidBankAccount}
        rules={[{ required: true }]}
        help={<Typography.Text type="danger">사진은 40mb까지 첨부하실 수 있습니다.</Typography.Text>}
      >
        <VFileUpload maxCount={1} accept="image/*" />
      </Form.Item>
      <Form.Item shouldUpdate>
        {() => (
          <BottomButton
            type="primary"
            htmlType="submit"
            className="settlement-submit"
            disabled={
              isPreparationHistory ||
              !form.isFieldsTouched(true) ||
              !!form.getFieldsError().filter(({ errors }) => errors.length).length
            }
            loading={isLoading}
          >
            {isPreparationHistory ? '현재 진행중인 변경사항이 있습니다' : '계좌 수정 요청'}
          </BottomButton>
        )}
      </Form.Item>
    </Form>
  );
};

export default BankAccountForm;
