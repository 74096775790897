import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    color: ${({ theme }) => theme.color.black}
  }
  .index .quickicons .block1, .index .quickicons .block2 {
    width: 50%;
    margin-right: 3%;
  }

  @media (max-width: 1000px) {
    .index .quickicons .block1, .index .quickicons .block2 {
      width: 80%;
    }
  }

  @media (max-width: 767px) {
    #excelBtn {
      display: none;
    }

    .index .quickicons .block1, .index .quickicons .block2 {
      width: 80%;
    }
  }
  & {
    .ant-layout {
      background: #f7f7f7;
    }
    .ant-list, .ant-list-item {
      color: ${({ theme }) => theme.color.black}
    }
    .ant-modal.router-modal {
      padding-bottom: 0;
      @media screen and (max-width: 768px) {
        margin: 0;
        height: 100%;
        max-width: 100%;
      }
      .ant-progress-inner {
        border-radius: 0;
      }
      .ant-modal-body {
        padding: 0;
        overflow-y: auto;
        @media screen and (max-width: 768px) {
          height: calc(100vh - 55px);
        }
        max-height: calc(100vh - 55px);
      }
      .ant-progress-small.ant-progress-line, .ant-progress-small.ant-progress-line .ant-progress-text .anticon {
        line-height: 0;
      }
    }
    .ant-modal.error-handler-modal, .ant-modal.info-handler-modal,
    .ant-modal.confirm-handler-modal, .ant-modal.confirm-cancel-handler-modal, .ant-modal.confirm-cancel-handler-modal-reverse {
      .ant-modal-content {
        border-radius: 16px;
        .ant-modal-body {
          padding: 32px 0 0;
          text-align: center;
          .ant-modal-confirm-body {
            padding: 0 16px;
            ant-modal-confirm-content {
              font-size: 16px;
            }
          }
          .ant-modal-confirm-btns {
            width: 100%;
            float: none;
            border-top: 1px solid #D7D8DC;
            display: flex;
            .ant-btn {
              font-weight: bold;
              font-size: 16px;
              height: 48px;
              background: none;
              color: #0067FF;
              border: none;
              width: 100%;
              border-bottom-right-radius: 16px;
              border-bottom-left-radius: 16px;
            }
          }
        }
      }
    }
    .ant-modal.ant-modal-confirm.confirm-handler-modal {
      .ant-modal-content {
        .ant-modal-body {
          .ant-modal-confirm-btns {
            .ant-btn {
              color: #FF0606;
              width: auto;
              flex: 1;
              font-weight: 400;
            }
            .ant-btn.ant-btn-primary {
              color: #0067FF;
            }
          }
        }
      }
    }
    .ant-modal.ant-modal-confirm.confirm-cancel-handler-modal-reverse {
      .ant-modal-content {
        .ant-modal-body {
          .ant-modal-confirm-btns {
            flex-direction: row-reverse;
            .ant-btn {
              color: #0067FF;
              width: auto;
              flex: 1;
              font-weight: 400;
            }
            .ant-btn.ant-btn-primary {
              color: #FF0606;
            }
          }
        }
      }
    }
    .ant-modal.ant-modal-confirm.confirm-cancel-handler-modal {
      .ant-modal-content {
        .ant-modal-body {
          .ant-modal-confirm-btns {
            .ant-btn {
              color: ${({ theme }) => theme.color.gray08};
              width: auto;
              flex: 1;
              font-weight: 400;
            }
            .ant-btn.ant-btn-primary {
              color: ${({ theme }) => theme.color.blue};
            }
          }
        }
      }
    }

    input:not([type="password"]).ant-input.ant-input-lg {
      height: 65px;
      padding: 18.5px 8px 18.5px 16px;
      font-size: 18px;
      border-radius: 4px;
    }
    .ant-input-password input[type="text"].ant-input.ant-input-lg {
      height: 50px;
    }
    .ant-input-affix-wrapper {
      border-radius: 4px;
      input.ant-input.ant-input-lg {
        padding-left: 5px;
      }
    }
    .ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      .ant-input {
        height: 50px;
      }
    }
    .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
      border-radius: 0 4px 4px 0;
    }
    .ant-input-search-large .ant-input-search-button {
      height: 65px;
    }
    .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      display: none;
    }
    .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
      display: inline-block;
      margin-right: 4px;
      color: #4BB378;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: '*';
    }

    .ant-input-password {
      & input {
        height: 50px;
      }
    }
    .ant-form-large .ant-form-item-label > label {
      height: 22px;
    }
    .ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label {
      padding-bottom: 10px;
    }
    .ant-btn-lg {
      border-radius: 8px;
    }
    .ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
      height: 65px;
    }
    .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
      height: 65px;
      border-radius: 4px;
    }
    .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector::after, .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item, .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
      line-height: 65px;
    }
    .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
      min-height: 65px;
      border-radius: 4px;
    }
  }
`;

export default GlobalStyle;
