import { useMeInfo, useMenus, useUpdateMenu } from 'hooks';
import React, { useEffect, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import MenuList from './MenuList';

const Menu = () => {
  const { selectedStore } = useMeInfo();
  const { data } = useMenus(selectedStore?.sid);

  const queryClient = useQueryClient();
  const { mutateAsync } = useUpdateMenu();

  const updateMenuStatus = async (params) => {
    const sid = selectedStore?.sid;
    if (!sid) {
      return;
    }

    await mutateAsync({ sid, data: params });

    await queryClient.invalidateQueries('getMenus');
  };

  const setDefault = () => {
    const divBox = $(`div[name="suubar-header"]`);
    divBox.addClass('mobile-fixed-top-bar');
  };

  const backEvent = () => {
    const divBox = $(`div[name="suubar-header"]`);
    divBox.removeClass('mobile-fixed-top-bar');
  };

  useEffect(() => {
    global.back = backEvent;
    setDefault();
  }, []);

  const category = useMemo(() => {
    if (!data?.menu) {
      return [];
    }
    const categories = data?.menu?.map((menu) => {
      if (menu.category) {
        return menu.category;
      }
      return '';
    });
    return Array.from(new Set(categories));
  }, [data]);

  return <MenuList data={data?.menu} category={category} totalCount={data?.totalCount} update={updateMenuStatus} />;
};

export default Menu;
