import { Button, Checkbox, Col, Input, Modal, Radio, Row } from 'antd';
import { usePushMessage } from 'hooks';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { generate } from 'shortid';
import { PushModal, Table } from '../styles';

const { TextArea } = Input;

const pushMessage = {
  send: `[${moment().format('YYYY-MM-DD')}] 예약 메뉴가 발송되었습니다.`,
  arrival: `[${moment().format('YYYY-MM-DD')}] 예약 메뉴가 도착하였습니다.`
};

const BookingPushModal = ({ open, close, recipient, searchParams, defaultChecked }) => {
  const [recipientList, setRecipientList] = useState([]);
  const [pushMessageType, setPushMessageType] = useState<string>('send');
  const [pushContent, setPushContent] = useState<string>(pushMessage.send);
  const [checkedList, setCheckedList] = useState<Array<string>>([]);
  const { mutateAsync } = usePushMessage();

  useEffect(() => {
    if (open) {
      if (defaultChecked) {
        const list = recipient.map(({ couponId }) => couponId && couponId);
        setCheckedList(list);
      }
      setRecipientList(recipient);
    } else {
      setCheckedList([]);
    }
  }, [open, recipient, defaultChecked]);

  const onChange = (e, id) => {
    if (e.target.checked) {
      setCheckedList([...checkedList, id]);
    } else {
      // 체크 해제
      setCheckedList(checkedList.filter((el) => el !== id));
    }
  };

  const onRadioChange = ({ target }) => {
    setPushMessageType(target.value);
    setPushContent(pushMessage[target.value]);
  };

  const onContent = ({ target }) => {
    setPushContent(target.value);
  };

  const openAlert = (content, isClose) => {
    Modal.info({
      content,
      icon: null,
      okText: '확인',
      onOk: () => {
        if (isClose) {
          close(true);
        }
      },
      className: 'info-handler-modal',
      style: { top: 'calc(50% - 200px)' }
    });
  };

  const onSubmit = async () => {
    const users = [];
    if (!checkedList || checkedList.length <= 0) {
      await openAlert(<>대상을 선택해 주세요.</>, false);
      return;
    }

    checkedList.forEach((id) => {
      const user = recipient.filter(({ couponId }) => couponId === id)[0];
      const array = {
        groupIdx: user.bookingStoreIdx,
        groupName: user.bookingOfficeName,
        id: user.userId,
        name: user.bookedUserName
      };
      users.push(array);
    });

    const params = {
      push: {
        favorite: null,
        content: pushContent,
        booked: null
      },
      booking: {
        company: recipient[0].companyId,
        date: moment(searchParams.bookingDate).format('YYYY-MM-DD')
      },
      users
    };
    Modal.confirm({
      content: <>{checkedList.length}건을 즉시 발송 하시겠습니까?</>,
      icon: null,
      cancelText: '취소',
      okText: '확인',
      style: { top: 'calc(50% - 200px)' },
      onOk: async () => {
        await mutateAsync(params);
        await openAlert(<>발송되었습니다.</>, true);
      },
      onCancel: () => {}
    });
  };

  return (
    <PushModal visible={open} title="식권대장앱 PUSH 발송 서비스" footer={[]} onCancel={close} width={1000} closable={false}>
      <Row gutter={[24, 0]}>
        <Col span={15}>
          <div>수신자</div>
          <Table>
            <thead>
              <tr>
                <th style={{ textAlign: 'center', width: '8%' }}>No</th>
                <th style={{ textAlign: 'center', width: '15%' }}>예약자</th>
                <th style={{ textAlign: 'center', width: '19%' }}>식사명</th>
                <th style={{ textAlign: 'center', width: '30%' }}>메뉴명</th>
                <th style={{ textAlign: 'center', width: '20%' }}>고객사</th>
                <th style={{ textAlign: 'center', width: '8%' }}>발송</th>
              </tr>
            </thead>
            <tbody>
              {recipientList.map((item, index) => {
                return (
                  <tr key={generate()}>
                    <td style={{ textAlign: 'center' }}>{index + 1}</td>
                    <td style={{ textAlign: 'center' }}>{item?.bookedUserName}</td>
                    <td style={{ textAlign: 'center' }}>{item?.bookingOfficeName}</td>
                    <td style={{ textAlign: 'center' }}>
                      {item?.menus?.map(({ menuName, menuId }) => <div key={menuId}>{menuName}</div>)}
                    </td>
                    <td style={{ textAlign: 'center' }}>{item?.companyName}</td>
                    <td style={{ textAlign: 'center' }}>
                      <Checkbox
                        onChange={(e) => onChange(e, item?.couponId)}
                        checked={checkedList.includes(item?.couponId)}
                        defaultChecked={defaultChecked}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
        <Col span={9}>
          <div>발신자</div>
          <Table>
            <thead>
              <tr>
                <th style={{ textAlign: 'center' }}>자주 쓰는 메시지</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="pushMessage">
                    <Radio.Group onChange={onRadioChange} value={pushMessageType}>
                      <Radio value="send">[{moment().format('YYYY-MM-DD')}] 예약 메뉴가 발송되었습니다.</Radio>
                      <Radio value="arrival">[{moment().format('YYYY-MM-DD')}] 예약 메뉴가 도착하였습니다.</Radio>
                    </Radio.Group>
                    <div className="textArea">
                      <TextArea showCount value={pushContent} maxLength={200} onChange={onContent} />
                    </div>
                    <div>[중요] 고객사 개인정보 보호를 위해 예약배송(지연/발송/도착) 관련 메시지 발송 만 허용됩니다.</div>
                  </div>
                  <Button onClick={onSubmit}>즉시 발송</Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </PushModal>
  );
};

export default BookingPushModal;
