/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo, useState } from 'react';
import arrowLeft from 'images/arrow/arrow_left.png';
import arrowRight from 'images/arrow/arrow_right.png';
import { CalendarModal, modalMethod } from '../Modal';

export interface DateNavigationProps {
  date: Date;
  onChange: (v: Date) => void;
  infinite?: boolean;
  onChangePrevious?: (v: Date) => void;
  onChangeNext?: (v: Date) => void;
}

const isTomorrow = (currentDate: Date) => {
  const now = new Date().getTime();
  const tomorrowOfCurrentDate = new Date(currentDate).getTime() + 1000 * 60 * 60 * 24;
  return tomorrowOfCurrentDate > now;
};
const isGreaterThanToday = (date: Date) => {
  const target = date.getTime();
  const today = new Date().getTime();
  return target > today;
};

const DateNavigation = ({ date, infinite, onChange, onChangePrevious, onChangeNext }: DateNavigationProps) => {
  const [isDateOpen, setDateOpen] = useState<boolean>(false);
  const handlePrevious = () => {
    const yesterday = new Date(date);
    yesterday.setDate(date.getDate() - 1);
    onChange(yesterday);
  };

  const handleNext = () => {
    if (!infinite && isTomorrow(date)) {
      return;
    }
    const tomorrow = new Date(date);
    tomorrow.setDate(date.getDate() + 1);
    onChange(tomorrow);
  };

  const handleSelectDate = () => {
    setDateOpen(true);
  };

  const handleCloseModal = () => {
    setDateOpen(false);
  };

  const handleChangeDate = (v: Date) => {
    if (!infinite && isGreaterThanToday(v)) {
      modalMethod.info('오늘 이전 날짜를 선택해주세요.');
      return;
    }
    onChange(v);
    handleCloseModal();
  };

  const rightBtn = useMemo(() => {
    if (!infinite && isTomorrow(date)) return 'right_disabled';
    return 'right';
  }, [date, infinite]);

  return (
    <div className="navigation">
      <div className="left" onClick={handlePrevious}>
        {' '}
        <img src={arrowLeft} alt="prev_button_icon" className="arrowicon" /> 전날
      </div>
      <div className="center" onClick={handleSelectDate}>
        {' '}
        {date.getFullYear()}. {date.getMonth() + 1}. {date.getDate().toString().padStart(2, '0')}.
      </div>
      <div className={rightBtn} onClick={handleNext}>
        <span className="string">
          다음날 <img src={arrowRight} alt="next_button_icon" className="arrowicon" />
        </span>
      </div>
      <CalendarModal isVisible={isDateOpen} date={date} close={handleCloseModal} onChange={handleChangeDate} />
    </div>
  );
};

export default DateNavigation;
