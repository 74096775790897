import React, { useEffect, useState } from 'react';
import ReactGa from 'react-ga4';
import { DateModal, MonthModal } from 'components';
import { useExcelDownload, useExportExcel, useMeInfo } from 'hooks';
import moment, { Moment } from 'moment';
import { getManual } from 'utils';
import { getExcelUsed } from 'configs/route';
import excelDownloadImage from 'images/real-time-report/excel_down.png';

const ExcelDownloadButton = ({ location }) => {
  const { selectedStore, meInfo } = useMeInfo();
  const { excelParams } = useExcelDownload();
  const [isOpen, setOpen] = useState<boolean>(false);
  const [currentDate, setCurrentDate] = useState({ startDate: moment(), endDate: moment() });
  const { mutate: exportExcel } = useExportExcel();

  useEffect(() => {
    if (excelParams?.date1 || excelParams?.date2) {
      setCurrentDate({ startDate: excelParams?.date1, endDate: excelParams?.date2 });
    }
  }, [excelParams?.date1, excelParams?.date2]);

  const handleClose = () => {
    setOpen(false);
  };

  const download = (params: unknown) => {
    exportExcel(params, {
      onSuccess: () => {
        alert('요청하신 파일생성이 완료되었습니다. 파일은 엑셀파일 보관함에서 다운로드 받을 수 있습니다.');
        // $('#dateModal').modal('hide');
        handleClose();
      },
      onError: () => {
        alert('요청에 실패하였습니다.');
      }
    });
  };

  const handleDateSelected = ({
    startDate,
    endDate,
    orderStatus,
    logisticsValue
  }: {
    startDate: Moment;
    endDate: Moment;
    orderStatus?: string;
    logisticsValue?: string;
  }) => {
    const startDateString = moment(startDate).format('YYYY-MM-DD');
    const endDateString = moment(endDate).format('YYYY-MM-DD');
    setCurrentDate({ startDate, endDate });
    const { data } = excelParams;
    const target = location.pathname.replace('/', '');
    let contentType: string;
    if (target === 'realtime') {
      contentType = 'MC';
      ReactGa.event({
        category: 'sikdang_daily sales',
        action: 'sikdang_daily sales_excel'
      });
    } else if (target === 'calc') {
      contentType = 'C';
      ReactGa.event({
        category: 'sikdang_sales list',
        action: 'sikdang_sales list_excel'
      });
    } else if (target === 'masterStats') {
      contentType = 'SS';
      ReactGa.event({
        category: 'sikdang_master list',
        action: 'sikdang_master list_excel'
      });
    } else if (target === 'salesStats') {
      contentType = orderStatus === 'PAYMENT' ? 'SCSR' : 'MS';
      ReactGa.event({
        category: 'sikdang_sales list',
        action: 'sikdang_sales list_excel'
      });
    } else if (target === 'reserveStats') {
      contentType = 'BS';
      ReactGa.event({
        category: 'sikdang_bookingStats list',
        action: 'sikdang_bookingStats list_excel'
      });
    } else if (target === 'reserveStatus') {
      contentType = 'BH';
      ReactGa.event({
        category: 'sikdang_bookingStatus list',
        action: 'sikdang_bookingStatus list_excel'
      });
    } else if (target === 'shippingOrderMgt') {
      contentType = 'SP';
      ReactGa.event({
        category: 'sikdang_shipping list',
        action: 'sikdang_shipping list_excel'
      });
    } else if (target === 'calculateInfo' && excelParams.target === 'convisetoff') {
      contentType = 'ST';
      ReactGa.event({
        category: 'sikdang_incomeHistory list',
        action: 'sikdang_incomeHistory list_excel'
      });
    } else if (target === 'calculateInfo' && excelParams.target === 'conviseton') {
      contentType = 'SV';
      ReactGa.event({
        category: 'sikdang_vat list',
        action: 'sikdang_vat list_excel'
      });
    }

    if (target !== 'masterStats' && !selectedStore?.sid) {
      return;
    }

    let params: Record<string, unknown> = {
      sid: selectedStore?.sid,
      startDate: startDateString,
      endDate: endDateString,
      contentType
    };

    if (target === 'masterStats') {
      if (!meInfo?.admin?.said) {
        return;
      }
      params.sid = meInfo.admin.said;
      params.dateSort = data.type;
      params.menuSort = data.isSelectMenuMode;
      params.companySort = data.isSelectCompanyMode;
      params.storeSort = data.isSelectStore;
      if (data.selectStore === 'all') {
        params.storeSelectAll = true;
        params.storeSelectId = '';
      } else {
        params.storeSelectAll = false;
        params.storeSelectId = data.selectStore;
      }

      if (data.isSelectCompanyMode) {
        if (data.selectCompany === 'all') {
          params.companySelectAll = true;
          params.companySelectId = '';
        } else {
          params.companySelectAll = false;
          params.companySelectId = data.selectCompany;
        }
      }
    } else if (target === 'shippingOrderMgt') {
      const { filter } = data;
      const f = filter as Record<string, unknown>;
      params.isTracking = f.isTracking;
      params.orderStatus = orderStatus;
      params.logistics = logisticsValue;
      params.inquiryCondition = f.inquiryCondition;
      params.inquiry = f.inquiry;
    } else {
      params = {
        sid: selectedStore.sid,
        startDate: startDateString,
        endDate: endDateString,
        contentType
      };
    }

    download(params);
  };

  const downloadExcel = () => {
    const { companySelectId, date1, date2 } = excelParams;
    const params = {
      companySelectId: companySelectId || null,
      contentType: 'BH',
      startDate: moment(date1).format('YYYY-MM-DD'),
      endDate: moment(date2).format('YYYY-MM-DD'),
      sid: selectedStore.sid
    };
    download(params);
  };

  const handleExcelDownload = () => {
    const { type } = getExcelUsed(location.pathname) || {};
    if (location.pathname === '/') {
      getManual();
      return;
    }
    if (type === 'v2') {
      downloadExcel();
      return;
    }
    setOpen(true);
  };

  const { type: excelType } = getExcelUsed(location.pathname) || {};
  if (!excelType) {
    return null;
  }

  const calculateFlag = excelParams?.target === 'convisetoff' || excelParams?.target === 'conviseton';

  return (
    <>
      <div id="excelBtn">
        <button type="button" className="excel-download pull-right" onClick={handleExcelDownload}>
          <img src={excelDownloadImage} className="excelbtn" alt="" />{' '}
          {location.pathname === '/' ? '사용설명서 다운로드' : '엑셀파일 다운로드'}
        </button>
      </div>
      {excelParams?.date1 && excelParams?.date2 && !calculateFlag ? (
        <DateModal
          isOpenCalendar={isOpen}
          close={handleClose}
          dateSelected={handleDateSelected}
          startDate={moment(currentDate.startDate)}
          endDate={moment(currentDate.endDate)}
          target={excelParams?.target}
          filter={excelParams?.filterData}
          logistics={excelParams?.data?.logistics}
          month={6}
          title="엑셀 다운로드"
          isLimit={excelParams?.data?.isLimit as boolean}
        />
      ) : (
        <MonthModal
          isOpenCalendar={isOpen}
          startDate={moment(currentDate.startDate)}
          endDate={moment(currentDate.endDate)}
          dateSelected={handleDateSelected}
          close={handleClose}
          target={excelParams?.target}
        />
      )}
    </>
  );
};

export default ExcelDownloadButton;
