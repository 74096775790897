import React, { useMemo } from 'react';
import errorHandler from 'apis/errorHandler';
import { BizForm, modalMethod } from 'components';
import {
  useMeInfo,
  useSettlementMetaInfo,
  useSaveSettlementBizInfo,
  useBizSerial,
  useChannelTalk,
  useAdmissionHistory
} from 'hooks';
import { SettlementDescription } from '../styles';

const BizInfo = () => {
  const { selectedStore, meInfo } = useMeInfo();
  const { data: metaInfo } = useSettlementMetaInfo(selectedStore?.sid || '');
  const { data: admissionHistory } = useAdmissionHistory(meInfo.admin.said || '');
  const { isLoading: isSearching, mutateAsync: searchBizSerial } = useBizSerial();
  const { isLoading: isSavingBizInfo, mutateAsync: saveBizInfo } = useSaveSettlementBizInfo();

  useChannelTalk(true, true);

  const handleSearch = async (bizSerial: string) => {
    if (!bizSerial) {
      return undefined;
    }
    const result = await searchBizSerial(bizSerial);
    return result;
  };

  const handleSaveBizInfo = async (values) => {
    if (!selectedStore?.sid) {
      return;
    }
    try {
      const saveData = {
        storeId: selectedStore.sid,
        bizInfo: values
      };
      await saveBizInfo(saveData);
      modalMethod.info(
        <>
          <div>상호명 변경 요청이 완료되었습니다.</div>
          <div>반영까지 3~4 영업일정도 소요되며,</div>
          <div>완료 시 마이페이지에서</div>
          <div>변경된 정보를 확인하실 수 있습니다.</div>
        </>
      );
    } catch (e) {
      errorHandler(e);
    }
  };

  const isPreparationHistory = useMemo(() => {
    return !!admissionHistory?.historyList.filter(
      ({ type, status, storeId }) => type === 'BIZ' && status === 'PREPARATION' && storeId === selectedStore?.sid
    ).length;
  }, [admissionHistory?.historyList, selectedStore?.sid]);

  return (
    <>
      <SettlementDescription>
        <div>사업자등록증 상의 상호명 변경을 원하실 경우</div>
        <div>버튼을 눌러 상호명 수정을 신청합니다.</div>
        <div>이 때 새로운 사업자등록번호의 등록증 사본을</div>
        <div>첨부해 주시면 심사를 거쳐 재승인 해드리겠습니다.</div>
        <div className="red-color">승인 여부와 별개로 정산은 익월부터 변경됩니다.</div>
      </SettlementDescription>
      <BizForm
        initValue={metaInfo}
        onSearch={handleSearch}
        isSearching={isSearching}
        onSave={handleSaveBizInfo}
        isLoading={isSavingBizInfo}
        isPreparationHistory={isPreparationHistory}
      />
    </>
  );
};

export default BizInfo;
