import { useMeInfo, useTodayReport, useChannelTalk } from 'hooks';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactGa from 'react-ga4';
import { numberWithCommas } from 'utils/stringUtils';
import { getManual, handlerKeyUp } from 'utils';
import { ColumnChart, EmptyStore, LimitedPopup } from 'components';
import moment from 'moment';
import mainIcon01 from 'images/main/main_icon01.png';
import mainIcon02 from 'images/main/main_icon02.png';
import mainIcon05 from 'images/main/main_icon05.png';
import mainIcon11 from 'images/sidemenu/icon09_reserveStatus.svg';
import mainIcon12 from 'images/sidemenu/icon10_reserveStats.svg';
import iconSetting from 'images/sidemenu/icon14_setting.svg';
import MasterMain from './MasterMain';

const MainPage = () => {
  const navigate = useNavigate();
  const { selectedStore, meInfo } = useMeInfo();
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [curStore, setCurStore] = useState(selectedStore?.sid);

  const handleOk = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (curStore !== selectedStore?.sid) {
      setCurStore(selectedStore?.sid);
      handleOk();
    }
  }, [curStore, selectedStore?.sid]);

  useChannelTalk();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, setState] = useState<any>({
    intro: '로드중',
    cancelfunc: false,
    storeDayPrice: {},
    cancelpw: ''
  });

  const { data: todayReport } = useTodayReport(
    {
      date: moment().format('YYYY-MM-DD'),
      sid: selectedStore?.sid
    },
    {
      enabled: !!selectedStore?.sid
    }
  );

  const graphData = useMemo(() => {
    if (!todayReport) {
      return [];
    }
    const resultData: unknown[][] = [];
    resultData.push(['월', '금액', { role: 'tooltip' }]);
    const today = moment(new Date());

    todayReport.storeWeekPrice.forEach((content) => {
      let dateStr = '';
      const timeDate = moment(content.date);

      if (timeDate.format('M') === today.format('M')) {
        dateStr = timeDate.format('MM-DD');
      } else if (timeDate.format('YYYY') === today.format('YYYY')) {
        dateStr = timeDate.format('MM-DD');
      } else {
        dateStr = timeDate.format('YYYY-MM-DD');
      }

      const tooltip = `${dateStr}:\n${numberWithCommas(content.dayTotalPrice)}원`;
      resultData.push([dateStr, content.dayTotalPrice / 10000, tooltip]);
    });

    return resultData;
  }, [todayReport]);

  const openRealTime = () => {
    ReactGa.event({
      category: 'sikdang_daily sales',
      action: 'sikdang_daily sales_menu'
    });
    navigate('/realtime');
  };

  const openSalesReport = () => {
    ReactGa.event({
      category: 'sikdang_sales stats',
      action: 'sikdang_sales stats_menu'
    });
    navigate('/salesStats');
  };

  const openSetting = () => {
    ReactGa.event({
      category: 'sikdang_sales setting',
      action: 'sikdang_sales setting_menu'
    });
    navigate('/setting');
  };

  const openMenu = () => {
    ReactGa.event({
      category: 'sikdang_sales menu',
      action: 'sikdang_sales menu_list'
    });
    navigate('/menus');
  };

  const openReserveStatus = () => {
    ReactGa.event({
      category: 'sikdang_menuList box',
      action: 'sikdang_menuList menu_list'
    });
    navigate('/reserveStatus');
  };

  const openReserveStats = () => {
    ReactGa.event({
      category: 'sikdang_menuList box',
      action: 'sikdang_menuList menu_list'
    });
    navigate('/reserveStats');
  };

  const guideDown = () => {
    getManual();
  };

  const d = new Date();
  const month = d.getMonth() + 1;
  const stringdate = `${month}월 ${d.getDate()}일`;

  let cancelPassword: React.ReactNode = '';
  if (state.cancelfunc) {
    cancelPassword = (
      <div>
        {state.cancelpw && state.cancelpw.length > 0 ? (
          <div className="mainGuideBtn mobileonly cancelPw">
            식권 취소 번호<span id="cancelNumber">{state.cancelpw}</span>
          </div>
        ) : null}
      </div>
    );
  }

  const guideBtn = (
    <div className="mainGuideBtn mobileonly" role="button" tabIndex={0} onKeyUp={handlerKeyUp(guideDown)} onClick={guideDown}>
      사용설명서 다운로드
    </div>
  );
  let monthSell: number | string = '0';
  let todaySell: number | string = '0';

  if (todayReport?.storeDayPrice) {
    monthSell = numberWithCommas(todayReport.storeDayPrice.monthTotalPrice);
    todaySell = numberWithCommas(todayReport.storeDayPrice.dayTotalPrice);
  }
  // let isView = true;
  // if (!selectedStore?.isBooking) {
  //   isView = false;
  // }
  if (!meInfo?.storeList?.length) {
    return <EmptyStore />;
  }
  if (!selectedStore) {
    return <MasterMain />;
  }

  return (
    <div className="index">
      <div className="top">
        <div className="today">{stringdate}</div>
        <div className="monthSell">
          <div className="left-mobile">
            {month}월<br />
            누적매출
          </div>
          <div className="left-pc">{month}월 누적매출</div>
          <div className="right">
            {monthSell} <span className="won">원</span>
          </div>
        </div>
        <div className="todaySell">
          <div className="left">오늘 매출</div>
          <div className="right">
            {todaySell} <span className="won">원</span>
          </div>
        </div>
        <div className="graph" id="graph">
          <ColumnChart data={graphData} />
        </div>
      </div>
      <div className="quickicons">
        <div className="block1">
          <div className="qbtn" onClick={openRealTime} role="button" tabIndex={0} onKeyUp={handlerKeyUp(openRealTime)}>
            <div className="box blockleft">
              <img src={mainIcon01} alt="icon-realtime" />
              <p className="name">실시간 매출</p>
            </div>
          </div>
          <div className="qbtn" onClick={openSalesReport} onKeyUp={handlerKeyUp(openSalesReport)} role="button" tabIndex={0}>
            <div className="box blockright">
              <img src={mainIcon02} alt="icon- sales-report" />
              <p className="name">매출 통계</p>
            </div>
          </div>
        </div>
        {selectedStore?.isBooking ? (
          <div className="block2">
            <div
              className="qbtn"
              onClick={openReserveStatus}
              onKeyUp={handlerKeyUp(openReserveStatus)}
              role="button"
              tabIndex={0}
            >
              <div className="box blockleft">
                <img src={mainIcon11} alt="icon-reserve-status" />
                <p className="name">예약 현황</p>
              </div>
            </div>
            <div className="qbtn" onClick={openReserveStats} onKeyUp={handlerKeyUp(openReserveStats)} role="button" tabIndex={0}>
              <div className="box blockright">
                <img src={mainIcon12} alt="icon-reserve-stats" />
                <p className="name">예약 통계</p>
              </div>
            </div>
          </div>
        ) : null}
        <div className="block2">
          <div className="qbtn" onClick={openMenu} onKeyUp={handlerKeyUp(openMenu)} role="button" tabIndex={0}>
            <div className="box blockleft">
              <img src={mainIcon05} alt="icon-menu" />
              <p className="name">메뉴판</p>
            </div>
          </div>
          <div className="qbtn" onClick={openSetting} onKeyUp={handlerKeyUp(openSetting)} role="button" tabIndex={0}>
            <div className="box blockright">
              <img src={iconSetting} alt="icon-setting" />
              <p className="name">설정</p>
            </div>
          </div>
        </div>
      </div>
      <div className="storesupport">
        {/* {storepay} */}
        {cancelPassword}
        {guideBtn}
      </div>
      <div className="" />
      <LimitedPopup open={isModalOpen} onOk={handleOk} onCancel={handleCancel} okBtnText="바로가기" />
    </div>
  );
};

export default MainPage;
