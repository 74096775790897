import React, { useEffect, useMemo, useState } from 'react';
import NodataPage from 'pages/CalculateInfoPage/NodataPage';
import HistoryDetail from 'pages/CalculateInfoPage/HistoryDetail';
import { useDeposit, useDepositDetail, useExcelDownload, useMeInfo } from 'hooks';
import { Select } from 'antd';
import moment from 'moment';
import { generate } from 'shortid';
import { numberWithCommas, setPassCertification } from 'utils';
import companyIcon from 'images/ic_office_16.svg';
import { IMatchDate, IMatchDeposit } from 'interfaces/settlement';
import { useNavigate } from 'react-router-dom';
import { IncomeHistoryWrapper, FlexBox, SelectBox, ListBox, ListContent } from './styles/incomeHistoryStyle';

const IncomeHistory = () => {
  const navigate = useNavigate();
  const nowDate = moment();
  const oldestDate = moment().subtract(60, 'month');
  const selectYearInfo = [];
  for (let i = oldestDate.year(); i <= nowDate.year(); i += 1) {
    selectYearInfo.push(i);
  }
  const selectMonthInfo = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const [selectYear, setSelectYear] = useState<number>(selectYearInfo[selectYearInfo.length - 1]);
  const [selectMonth, setSelectMonth] = useState<number>(selectMonthInfo[nowDate.month()]);
  const [detailParams, setDetailParams] = useState({ settleProgressSeq: 0, companySeq: 0 });
  const { changeExcelParams } = useExcelDownload();
  const { meInfo, selectedStore } = useMeInfo();
  const { data } = useDeposit(selectedStore.sid, () =>
    changeExcelParams({ date1: moment().startOf('month'), date2: moment(), target: 'convisetoff' })
  );
  const { data: dataDetail } = useDepositDetail(selectedStore.sid, detailParams.settleProgressSeq, detailParams.companySeq);

  useEffect(() => {
    if (!meInfo?.admin.isCertification) {
      setPassCertification(meInfo?.admin?.id, false);
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleYearSelectChange = (value: any) => {
    setSelectYear(value);
    if (
      moment([value, selectMonth - 1]).year() === moment().year() &&
      moment([value, selectMonth - 1]).month() === moment().month()
    ) {
      changeExcelParams({
        date1: moment().startOf('month'),
        date2: moment(),
        target: 'convisetoff'
      });
    } else {
      changeExcelParams({
        date1: moment([value, selectMonth - 1]),
        date2: moment([value, selectMonth - 1]).endOf('month'),
        target: 'convisetoff'
      });
    }
  };

  const handleMonthSelectChange = (value: any) => {
    setSelectMonth(selectMonthInfo[value - 1]);
    if (
      moment([selectYear, value - 1]).year() === moment().year() &&
      moment([selectYear, value - 1]).month() === moment().month()
    ) {
      changeExcelParams({
        date1: moment().startOf('month'),
        date2: moment(),
        target: 'convisetoff'
      });
    } else {
      changeExcelParams({
        date1: moment([selectYear, value - 1]),
        date2: moment([selectYear, value - 1]).endOf('month'),
        target: 'convisetoff'
      });
    }
  };

  const handleParams = (settleProgressSeq, companySeq) => {
    setDetailParams({
      settleProgressSeq,
      companySeq
    });
  };

  const handleClickParams = (settleProgressSeq, companySeq) => {
    setDetailParams({
      settleProgressSeq,
      companySeq
    });
  };

  const matchDate = useMemo<IMatchDate>(() => {
    if (!data?.contents?.length) {
      return null;
    }
    const match = data.contents
      .filter(({ transferYm }) => transferYm === selectYear.toString() + selectMonth.toString().padStart(2, '0'))
      .reduce((acc, cur) => ({ ...acc, ...cur }), {});
    if (match?.depositItems?.length) {
      handleParams(match.depositItems[0].settleProgressSeq, match.depositItems[0].companySeq);
    }
    return match;
  }, [data?.contents, selectMonth, selectYear]);

  return (
    <IncomeHistoryWrapper>
      <SelectBox>
        <Select
          className="year-select"
          defaultValue={nowDate.year()}
          onChange={handleYearSelectChange}
          options={selectYearInfo.map((year) => ({ label: `${year}년`, value: year }))}
        />
        <Select
          className="month-select"
          defaultValue={nowDate.month() + 1}
          onChange={handleMonthSelectChange}
          options={selectMonthInfo.map((month) => ({ label: `${month}월`, value: month }))}
        />
        {matchDate && Object.keys(matchDate).length > 0 ? (
          <FlexBox>
            <div className="net-income">입금액 {numberWithCommas(matchDate.transferAmt)}원</div>
            <div className="sales-revenue">매출액 {numberWithCommas(matchDate.settleAmt)}원</div>
            <div className="commission-fee">수수료 {numberWithCommas(matchDate.serviceAmt)}원</div>
          </FlexBox>
        ) : null}
      </SelectBox>
      {matchDate && Object.keys(matchDate).length > 0 ? (
        <div className="listbox-detailinfo-flex">
          <ListBox key={generate()}>
            <div className="border-bottom">
              <ListContent
                key={generate()}
                dataSource={matchDate?.depositItems}
                renderItem={(item: IMatchDeposit) => {
                  return (
                    <ListContent.Item
                      className={item.companySeq === dataDetail?.transfer?.companyInfo.companySeq ? 'active' : ''}
                      style={{ padding: '0' }}
                      onClick={() => handleClickParams(item.settleProgressSeq, item.companySeq)}
                    >
                      <ListContent.Item.Meta
                        className={
                          item.companySeq === dataDetail?.transfer?.companyInfo.companySeq &&
                          item.transferDate === dataDetail?.transfer?.transferInfo.transferDate
                            ? 'active'
                            : ''
                        }
                        title={
                          <div>
                            <li
                              className={
                                item.companySeq === dataDetail?.transfer?.companyInfo.companySeq &&
                                item.transferDate === dataDetail?.transfer?.transferInfo.transferDate
                                  ? 'list active'
                                  : 'list'
                              }
                              role="presentation"
                            >
                              {moment(item.transferDate).format('YYYY년 MM월 DD일')} 입금정보
                            </li>
                          </div>
                        }
                        description={
                          <div className="display">
                            <img src={companyIcon} alt="companyIcon" />
                            <div>{item?.companyName}</div>
                            <div>{item?.divisionName}</div>
                          </div>
                        }
                      />
                    </ListContent.Item>
                  );
                }}
              />
            </div>
          </ListBox>
          <HistoryDetail dataDetail={dataDetail} />
        </div>
      ) : (
        <NodataPage />
      )}
    </IncomeHistoryWrapper>
  );
};

export default IncomeHistory;
