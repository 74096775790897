/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useRef, useState } from 'react';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import Cropper, { ReactCropperElement } from 'react-cropper';
import { CloseButton, SikdaeButton } from 'components/Button/Button';
import emptyImgIcon from 'images/storeImage/emptyImage.svg';
import deleteImgIcon from 'images/storeImage/delete_image_X_btn.svg';
import cropScissorIcon from 'images/storeImage/scissors_icon.svg';
import { useSaveThumbnail, useSaveSignatureMenuImg, useDeleteSignatureMenuImg } from 'hooks';
import { modalMethod } from 'components';
import { ImageModal, ImageEnrollBox, CropBox, CollapseBox, RegisterImgBox } from './style';

interface TImageFileArr {
  idx: number;
  storeId: string;
  type: string;
  image: string;
  displayOrder: number;
  createdDate: Date;
  createdBy: string;
  lastModifiedDate?: Date;
  lastModifiedBy?: string;
}

interface TThumbnailArr {
  idx?: number | null;
  image: string;
}

interface SignatureImgProps {
  maskClosable?: boolean;
  open: boolean;
  onOk: () => void;
  onCancel: () => void;
  imgType: 'THUMBNAIL' | 'SIGNATURE' | 'MENU' | 'NOTICE';
  imgFile: TImageFileArr[] | TThumbnailArr[] | null;
}

interface TSignatureImgArrProps {
  idx?: number;
  imgInfo: string;
  name: string;
}

interface TFilesArr {
  fileInfo: File | null;
}

interface TcropBlop {
  blobData?: string | Blob | null;
}

interface TImageStateProps {
  modalImgType: 'THUMBNAIL' | 'SIGNATURE' | 'MENU' | 'NOTICE';
  signatureImgArr: TSignatureImgArrProps[];
  filesArr: TFilesArr[];
  blobArr: TcropBlop[];
  blobedNull: boolean;
}

interface TCropState {
  crop: boolean;
  cropImage: string | ArrayBuffer | null;
  cropBlob?: string | Blob | null;
  cropData?: {
    width: number;
    height: number;
  };
  isDeletedImage: boolean;
  clickedImgIdx?: number;
  deleteIdxArr?: number[];
  checked: boolean;
}

const { Panel } = CollapseBox;

const DEFAULTINFO = {
  THUMBNAIL: {
    title: '썸네일',
    maxImgCount: 1,
    imgWidth: 1000,
    imgHeight: 1000,
    aspectRatio: 1
  },
  SIGNATURE: {
    title: '대표',
    maxImgCount: 7,
    imgWidth: 1440,
    imgHeight: 810,
    aspectRatio: 1.777777777777777
  },
  MENU: {
    title: '메뉴판',
    maxImgCount: 7,
    imgWidth: 5000,
    imgHeight: 5000,
    aspectRatio: null
  },
  NOTICE: {
    title: '공지사항',
    maxImgCount: 1,
    imgWidth: 1440,
    imgHeight: 810,
    aspectRatio: 1.777777777777777
  }
};

const SignatureImg = ({ maskClosable = false, open, onOk, onCancel, imgType, imgFile }: SignatureImgProps) => {
  const cropperRef = useRef<ReactCropperElement>(null);
  const filePhotoRef = useRef<HTMLInputElement>(null);
  const { mutateAsync: saveThumbnail, isLoading: isThumbnailLoading } = useSaveThumbnail();
  const { mutateAsync: saveSignatureMenuImg, isLoading: isSignatureMenuImgLoading } = useSaveSignatureMenuImg();
  const { mutateAsync: deleteSignatureMenuImg } = useDeleteSignatureMenuImg();
  const [cropState, setCropState] = useState<TCropState>({
    crop: false,
    cropImage: '',
    cropBlob: null,
    isDeletedImage: true,
    clickedImgIdx: 0,
    deleteIdxArr: null,
    checked: false
  });
  const [imageState, setImageState] = useState<TImageStateProps>({
    modalImgType: null,
    signatureImgArr: null,
    filesArr: null,
    blobArr: null,
    blobedNull: false
  });

  const { blobArr } = imageState;

  useEffect(() => {
    if (!imgFile || !imgFile?.length) {
      setImageState((v) => ({ ...v, modalImgType: imgType, signatureImgArr: [] }));
    } else if (imgFile) {
      const tempSignatureImg = [];
      const tempBlobArr = [];
      const tempFile = [];

      imgFile.forEach((el, idx) => {
        const fileName = el.image.split('/').pop();
        const metadata = { type: 'image/png' };
        const imgBlob = new Blob([el.image], metadata);

        tempSignatureImg.push({ idx: el.idx ? el.idx : 0, imgInfo: el.image, name: `${imgType}_${el?.idx}` });
        tempBlobArr.push({ blobData: imgBlob });
        tempFile.push({ fileInfo: new File([tempBlobArr[idx].blobData], fileName, metadata) });
      });

      setCropState((v) => ({
        ...v,
        isDeletedImage: false
      }));
      setImageState((v) => ({
        ...v,
        modalImgType: imgType,
        signatureImgArr: tempSignatureImg,
        filesArr: tempFile,
        blobArr: tempBlobArr
      }));
    }
  }, [open, imgType, imgFile]);

  useEffect(() => {
    const tempBlobedNull = blobArr?.some((el) => !el.blobData);
    setImageState((v) => ({ ...v, blobedNull: tempBlobedNull }));
  }, [blobArr]);

  useEffect(
    function stateReset() {
      if (!open) {
        setCropState((v) => ({
          ...v,
          crop: false,
          cropImage: '',
          cropBlob: null,
          isDeletedImage: true,
          checked: false,
          clickedImgIdx: 0,
          deleteIdxArr: null
        }));
        setImageState({
          modalImgType: 'THUMBNAIL',
          signatureImgArr: null,
          filesArr: null,
          blobArr: null,
          blobedNull: false
        });
      }
    },
    [open]
  );

  const handleSaveImage = async () => {
    const { deleteIdxArr } = cropState;
    const { signatureImgArr } = imageState;
    if (deleteIdxArr && deleteIdxArr?.length) {
      try {
        await deleteSignatureMenuImg({ storeImageIdxList: deleteIdxArr });
      } catch (error) {
        console.log('error', error);
      }
    }

    const formData = new FormData();
    if (signatureImgArr?.length) {
      if (imgType === 'THUMBNAIL') {
        formData.append('image', blobArr[0].blobData, signatureImgArr[0].name);
        try {
          await saveThumbnail(formData);
        } catch (error) {
          console.log('error', error);
        }
      } else {
        const newImgArr = [];
        const tempBlob = [];
        signatureImgArr.forEach((el, idx) => {
          if (!el.idx) {
            newImgArr.push(el);
            tempBlob.push(blobArr[idx]);
          }
        });
        // 이미지 타입 설정
        const imgObj = {
          SIGNATURE: 'STORE_IMAGE_TYPE_SIGNATURE',
          MENU: 'STORE_IMAGE_TYPE_MENU_BOARD',
          NOTICE: 'STORE_IMAGE_TYPE_NOTICE'
        };
        formData.append('type', imgObj[imgType]);

        // 기존 이미지와 새 이미지를 포함한 전체 이미지 배열 생성
        const allImages = signatureImgArr.map((img, index) => ({
          idx: img.idx || null,
          displayOrder: index + 1,
          image: blobArr[index].blobData,
          name: img.name
        }));

        // 각 이미지의 정보를 개별 필드로 추가
        allImages.forEach((img, index) => {
          // idx가 있는 경우에만 추가 (기존 이미지)
          if (img.idx) {
            formData.append(`storeImages[${index}].idx`, img.idx.toString());
          }
          // displayOrder와 이미지 추가
          formData.append(`storeImages[${index}].displayOrder`, (index + 1).toString());
          formData.append(`storeImages[${index}].image`, img.image, img.name);
        });
        try {
          await saveSignatureMenuImg(formData);
        } catch (error) {
          console.log('error', error);
        }
      }
    }

    if (!isThumbnailLoading || !isSignatureMenuImgLoading) {
      modalMethod.info('이미지 등록이 완료되었습니다.');
    }

    onOk();
  };

  const reorderArray = <T,>(arr: T[], fromIndex: number, toIndex: number): T[] => {
    const newArr = [...arr];
    const [movedItem] = newArr.splice(fromIndex, 1);
    newArr.splice(toIndex, 0, movedItem);
    return newArr;
  };

  // 드래그 시작할 때 실행되는 핸들러
  const handleDragStart = (e: React.DragEvent<HTMLDivElement>, index: number) => {
    e.dataTransfer.setData('text/plain', index.toString());
  };

  // 드래그 오버 시 기본 동작 방지
  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  // 드롭 시 실행되는 핸들러
  const handleDrop = (e: React.DragEvent<HTMLDivElement>, dropIndex: number) => {
    e.preventDefault();
    const dragIndex = parseInt(e.dataTransfer.getData('text/plain'), 10);

    if (dragIndex === dropIndex) return;

    setImageState((v) => ({
      ...v,
      signatureImgArr: reorderArray(v.signatureImgArr, dragIndex, dropIndex),
      filesArr: reorderArray(v.filesArr, dragIndex, dropIndex),
      blobArr: reorderArray(v.blobArr, dragIndex, dropIndex)
    }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { signatureImgArr, filesArr, blobArr: existingBlobArr } = imageState;
    const tempSignatureImgArr = [];
    const tempFilesArr = [];
    const tempBlobArr = [];
    const { files } = e.target;
    const maxLength = DEFAULTINFO[imgType].maxImgCount;
    const signatureImgLen = signatureImgArr ? signatureImgArr.length : 0;
    if (files && signatureImgLen + files.length > maxLength) {
      modalMethod.info(`사진은 최대 ${DEFAULTINFO[imgType].maxImgCount}개까지 등록 가능합니다.`);
      return;
    }

    if (files) {
      for (let i = 0; i < files.length; i += 1) {
        const reader = new FileReader();
        reader.readAsDataURL(files[i]);
        tempFilesArr.push({ fileInfo: files[i] });
        tempBlobArr.push({ blobData: null });
        reader.onload = (event) => {
          const img = new Image();
          if (typeof event.target.result === 'string') {
            img.src = event.target.result;
          }
          img.onload = () => {
            tempSignatureImgArr.push({ imgInfo: img.src, name: tempFilesArr[i].fileInfo.name });
            setCropState((v) => {
              return {
                ...v,
                crop: true,
                cropImage: reader.result,
                isDeletedImage: false,
                clickedImgIdx: signatureImgLen
              };
            });
            setImageState((v) => ({
              ...v,
              signatureImgArr: signatureImgArr ? [...signatureImgArr, ...tempSignatureImgArr] : tempSignatureImgArr,
              filesArr: filesArr ? [...filesArr, ...tempFilesArr] : tempFilesArr,
              blobArr: existingBlobArr ? [...existingBlobArr, ...tempBlobArr] : tempBlobArr
            }));
          };
        };
        reader.onloadend = () => {
          e.target.value = '';
        };
      }
    }
  };

  const deleteImage = (idx: number) => {
    const { isDeletedImage, deleteIdxArr } = cropState;
    const { modalImgType, filesArr, signatureImgArr } = imageState;
    const tempDeleteArr = [];
    if (modalImgType === 'THUMBNAIL') {
      signatureImgArr.pop();
      filesArr.pop();
      blobArr.pop();
    } else {
      tempDeleteArr.push(signatureImgArr[idx].idx);
      signatureImgArr.splice(idx, 1);
      filesArr.splice(idx, 1);
      blobArr.splice(idx, 1);
    }

    if (!signatureImgArr) {
      setCropState((v) => ({ ...v, crop: false, isDeletedImage: true }));
    }

    if (!isDeletedImage) {
      setCropState((v) => ({
        ...v,
        cropImage: '',
        cropBlob: null,
        cropData: null,
        deleteIdxArr: deleteIdxArr ? [...deleteIdxArr, ...tempDeleteArr] : tempDeleteArr
      }));
    }
    setImageState((v) => ({
      ...v,
      signatureImgArr,
      filesArr
    }));
  };

  const onCropData = () => {
    const { clickedImgIdx } = cropState;
    const { signatureImgArr } = imageState;
    if (cropperRef) {
      const imageElement = cropperRef?.current;
      const { cropper } = imageElement;
      const canvas = cropper.getCroppedCanvas({ width: DEFAULTINFO[imgType].imgWidth, height: DEFAULTINFO[imgType].imgHeight });

      canvas.toBlob((blob) => {
        const dupSignatureImgArr = Array.from(signatureImgArr);
        const dupBlobArr = Array.from(blobArr);

        dupSignatureImgArr[clickedImgIdx] = { imgInfo: canvas.toDataURL(), name: dupSignatureImgArr[clickedImgIdx].name };
        dupBlobArr[clickedImgIdx] = { blobData: blob };

        setCropState((v) => ({ ...v, crop: false, isDeletedImage: false }));
        setImageState((v) => ({ ...v, signatureImgArr: dupSignatureImgArr, blobArr: dupBlobArr }));
      });
    }
  };

  const onCrop = (e) => {
    const { detail } = e;
    setCropState((v) => ({
      ...v,
      cropData: {
        width: parseInt(detail.width, 10),
        height: parseInt(detail.height, 10)
      }
    }));
  };

  const onCheckboxChange = (e: CheckboxChangeEvent) => {
    setCropState((v) => ({ ...v, checked: e.target.checked }));
  };

  const saveBtnDisabled = () => {
    const { checked } = cropState;
    const { signatureImgArr, blobedNull } = imageState;
    const isImgNullWhenThumbnail = imgType === 'THUMBNAIL' && !signatureImgArr?.length;
    const isImgNullWhenSignatureMenu = (imgType === 'SIGNATURE' || imgType === 'MENU') && !signatureImgArr?.length;

    if (isImgNullWhenThumbnail) {
      return true;
    }
    if (isImgNullWhenSignatureMenu && checked) {
      return false;
    }
    return !!(!checked || blobedNull);
  };

  const { isDeletedImage, clickedImgIdx, crop, checked } = cropState;
  const { signatureImgArr } = imageState;

  return (
    <ImageModal
      title={<div className="header">이미지 등록</div>}
      maskClosable={maskClosable}
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      footer={[
        <div className="footer" key="footer">
          <CloseButton className="close-btn" onClick={onCancel}>
            닫기
          </CloseButton>
          <SikdaeButton onClick={handleSaveImage} disabled={saveBtnDisabled()}>
            저장
          </SikdaeButton>
        </div>
      ]}
    >
      <div className="modal-box">
        <div className="title">{DEFAULTINFO[imgType].title} 이미지 등록</div>
        <div className="title-description">우리가게 {DEFAULTINFO[imgType].title} 이미지를 등록해 주세요!</div>
      </div>
      <div className="modal-box">
        <div className="modal-inner-box">
          <span className="guide-description">사진을 등록해주세요</span>
          <span className="guide-mandatory">&#42;</span>
        </div>
        <ImageEnrollBox isImage={!signatureImgArr || signatureImgArr.length === 0}>
          <div
            style={signatureImgArr?.length > 0 ? { marginBottom: '20px' } : null}
            className="empty-image-container"
            role="presentation"
            onClick={() => handleFileChange}
          >
            <label htmlFor="img-files">
              <div className="container-center">
                <img src={emptyImgIcon} alt="img-preview" />
                <div className="image-count">
                  <span>{signatureImgArr ? signatureImgArr.length : 0}</span>
                  <span>/{DEFAULTINFO[imgType].maxImgCount}</span>
                </div>
              </div>
              <input
                id="img-files"
                className="thumbnail-file"
                ref={filePhotoRef}
                type="file"
                accept=".jpg, .jpeg, .png"
                onChange={handleFileChange}
                multiple
              />
            </label>
          </div>
          {signatureImgArr
            ? signatureImgArr.map((el, idx) => {
                return (
                  <RegisterImgBox key={el.name} imgIdx={idx} blobData={blobArr ? blobArr[idx]?.blobData : null}>
                    <div
                      className="enrolled-image-container"
                      role="presentation"
                      onClick={() => setCropState((v) => ({ ...v, crop: true, clickedImgIdx: idx }))}
                      draggable={imgType !== 'THUMBNAIL'}
                      onDragStart={(e) => handleDragStart(e, idx)}
                      onDragOver={handleDragOver}
                      onDrop={(e) => handleDrop(e, idx)}
                    >
                      {!isDeletedImage ? (
                        <img
                          className="enrolled-image-delete-btn"
                          alt="btn delete image"
                          aria-hidden="true"
                          src={deleteImgIcon}
                          onClick={() => deleteImage(idx)}
                        />
                      ) : null}
                      <div className="enrolled-image-numbering">{idx + 1}</div>
                      <div className="enrolled-image-dimmed" />
                      <div className="enrolled-image">
                        <img id="img-preview" src={el.imgInfo} alt={el.name} crossOrigin="anonymous" />
                      </div>
                      <img src={cropScissorIcon} alt="crop-icon" className="enrolled-image-crop" />
                    </div>
                  </RegisterImgBox>
                );
              })
            : null}
        </ImageEnrollBox>
        {crop
          ? signatureImgArr
              .filter((el, idx) => idx === clickedImgIdx)
              .map((el, idx) => {
                return (
                  <CropBox key={el.name} imgType={imgType} imgCount={signatureImgArr?.length}>
                    <div className="crop-container">
                      <Cropper
                        className="cropper"
                        key={el.name}
                        crop={onCrop}
                        src={el.imgInfo as string}
                        guides={false}
                        ref={cropperRef}
                        viewMode={1}
                        zoomOnWheel
                        aspectRatio={DEFAULTINFO[imgType].aspectRatio}
                        autoCropArea={0.99}
                      />
                    </div>
                    <div className="crop-container" key="img-control">
                      <CloseButton className="close-btn" width={325} onClick={() => deleteImage(idx)}>
                        사진 삭제
                      </CloseButton>
                      <SikdaeButton width={325} onClick={onCropData}>
                        사진 자르기
                      </SikdaeButton>
                    </div>
                  </CropBox>
                );
              })
          : null}
      </div>
      <div className="modal-box">
        <div className="modal-inner-box">
          <span className="guide-description">안내사항 확인</span>
          <span className="guide-mandatory">&#42;</span>
        </div>
        <CollapseBox
          defaultActiveKey={['1']}
          expandIconPosition="end"
          collapsible="icon"
          expandIcon={({ isActive }) => <div className="expand-icon">{isActive ? '닫기' : '내용확인'}</div>}
          checkbox={checked}
        >
          <Panel
            header={
              <Checkbox className="checkbox-round" name="guide-checked" onChange={onCheckboxChange} checked={checked}>
                안내사항을 확인하였습니다.
              </Checkbox>
            }
            key="1"
          >
            <div className="checkbox-inner-content">
              <div>
                &#8226; 관련 사진만 등록해 주세요. 부적절하거나 제휴점과 관련이 없는 사진은 내부 운영 원칙에 따라 삭제될 수
                있습니다.
              </div>
              <div>
                &#8226; 직접 업로드 이미지는 {DEFAULTINFO[imgType].imgWidth} * {DEFAULTINFO[imgType].imgHeight} 크기 이하의
                jpg/png 형식의 파일을 등록하실 수 있습니다.
              </div>
              <div>
                &#8226; {DEFAULTINFO[imgType].title} 이미지는 최대 {DEFAULTINFO[imgType].maxImgCount}개 등록 가능합니다.
              </div>
              {imgType !== 'THUMBNAIL' && imgType !== 'NOTICE' ? (
                <div>&#8226; 이미지 순서 조정이 필요한 경우 파일을 1개씩 순서대로 업로드 해야 합니다.</div>
              ) : null}
              {imgType === 'NOTICE' ? <div>&#8226; 공지 이미지의 삭제는 등록된 이미지를 삭제 후 저장해야 가능합니다.</div> : null}
            </div>
          </Panel>
        </CollapseBox>
      </div>
    </ImageModal>
  );
};

export default SignatureImg;
