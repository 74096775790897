import styled from 'styled-components';

export const MenuListWrapper = styled.div`
  display: flex;

  .style-div {
    height: 137px;
  }

  @media screen and (max-width: 1000px) {
    flex-direction: column;

    .style-div {
      display: none;
    }
  }
`;

export const MenuBoardWrapper = styled.div`
  width: 100%;

  .menu-salesStats-info {
    margin-top: 20px;
    width: 95%;
    margin-left: 20px;
    margin-bottom: 10px;
  }

  .ele2 {
    position: sticky;
    top: 0;
    background-color: #f7f7f7;
    height: 110px;
    width: 95%;
    display: inline-block;
    margin-left: 20px;
  }

  .menu-header-table {
    float: left;
    width: 100%;
    margin-top: 20px;
  }

  .menu-button-box {
    display: flex;
    justify-content: space-between;
    /*text-align: right;*/
    /*margin-left: 20px;*/
    margin-bottom: 10px;
  }

  .menu-search-box {
    display: flex;
    flex: 1;
  }

  .menu-button-box .menu-status-select {
    width: 100px;
  }
  .menu-button-box .menu-status-select .ant-select-selector {
    border: none !important;
    height: 32px !important;
  }
  .menu-button-box .menu-status-select .ant-select-selector input {
    height: 32px !important;
  }
  .menu-button-box .menu-status-select .ant-select-selection-item {
    line-height: 32px !important;
  }

  .menu-table-header {
    height: 45px;
    background-color: #666666;
  }
  .menu-check-box {
    width: 50px;
    text-align: center;
  }
  .menu-status-box {
    width: 90px;
    text-align: center;
  }

  .menu-salesStats-table {
    float: left;
    width: 95%;
    margin-left: 20px;
    margin-bottom: 30px;
    border: 1px solid #444444;
    border-collapse: collapse;
  }

  .menu-salesStats-table th,
  td {
    border: solid 1px rgba(0, 0, 0, 0.1);
    border-collapse: collapse;
  }

  .scroll-box {
    @media screen and (max-width: 1000px) {
      height: 550px;
      overflow: scroll;
    }
  }
`;

export const BannerWrapper = styled.div`
  position: sticky;
  top: 20px;
  width: 375px;
  height: 163px;
  cursor: pointer;

  .banner-wrapper {
    padding-top: 20px;
  }

  @media screen and (max-width: 1000px) {
    padding-top: 0;
    padding-left: 20px;
  }
`;
