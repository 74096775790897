import { Checkbox } from 'antd';
import styled, { css } from 'styled-components';

export const AgreeCheckboxGroupWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.color.gray04};
  background-color: ${({ theme }) => theme.color.gray06};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  width: 100%;
`;

export const AgreeCheckboxWithModalWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const OptionalAgreeCheckboxGroupWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 0 0 24px 36px;
  background-color: ${({ theme }) => theme.color.gray06};
  & .ant-checkbox-wrapper {
    width: auto;
    padding: 0;
    color: ${({ theme }) => theme.color.gray02};
  }
`;
interface StyledCheckBoxProps {
  $color?: 'reverse' | 'light';
  $backgroundColor?: 'transparent';
  $border?: boolean;
}

const cssCheckbox = css<StyledCheckBoxProps>`
  &.ant-checkbox-wrapper-checked {
    background-color: ${({ $color, theme }) => ($color === 'reverse' ? theme.color.primary : null)};
    color: ${({ $color, theme }) => ($color === 'reverse' ? theme.color.white : null)};
  }
  & .ant-checkbox:hover::after,
  &:hover .ant-checkbox::after {
    border-color: ${({ theme }) => theme.color.gray06};
    border-radius: 22px;
  }
  & .ant-checkbox-inner {
    width: 22px;
    height: 22px;
    background-color: ${({ $color, theme }) => ($color === 'reverse' ? theme.color.gray05 : theme.color.gray06)};
    border-color: ${({ $color, theme }) => ($color === 'reverse' ? theme.color.gray04 : theme.color.gray06)};
    border-radius: 22px;
    &::after {
      border-color: ${({ theme }) => theme.color.gray04};
      border-width: 3px;
      width: 7.714286px;
      height: 13.142857px;
      transform: rotate(45deg) scale(1) translate(-50%, -50%);
      opacity: 1;
    }
  }
  & .ant-checkbox-checked {
    .ant-checkbox-inner {
      width: 22px;
      height: 22px;
      background-color: ${({ $color, theme }) => ($color === 'reverse' ? theme.color.white : theme.color.gray06)};
      border-color: ${({ $color, theme }) => ($color === 'reverse' ? theme.color.white : theme.color.gray06)};
      border-radius: 22px;
      &::after {
        border-color: ${({ theme }) => theme.color.primary};
        border-width: 3px;
        width: 7.714286px;
        height: 13.142857px;
      }
    }
    &::after {
      border-radius: ${({ $color }) => ($color === 'reverse' ? '22px' : null)};
    }
  }
`;

export const StyledCheckBox = styled(Checkbox)<StyledCheckBoxProps>`
  width: 100%;
  padding: 17px 8px 17px 18px;
  color: ${({ theme, $color }) => {
    if ($color === 'light') {
      return theme.color.gray04;
    }
    return theme.color.black;
  }};
  background-color: ${({ $color, $backgroundColor, theme }) => {
    if ($backgroundColor === 'transparent') return $backgroundColor;
    if ($color === 'reverse') return theme.color.white;
    return theme.color.gray06;
  }};
  font-size: ${({ $color }) => ($color === 'reverse' ? '18px' : '16px')};
  font-weight: ${({ $color }) => ($color === 'reverse' ? 600 : 400)};
  line-height: ${({ $color }) => ($color === 'reverse' ? '21.6px' : '24px')};
  letter-spacing: ${({ $color }) => ($color === 'reverse' ? 0 : '-0.0005em')};
  & + .ant-checkbox-wrapper {
    margin-left: 0;
    .ant-checkbox > input {
      margin: 0;
    }
  }

  ${cssCheckbox}

  & .required-text {
    color: ${({ theme }) => theme.color.primary};
    margin-right: 4px;
  }
  border: ${({ $border, theme }) => ($border ? `1px solid ${theme.color.gray04}` : null)};
  border-radius: ${({ $border }) => ($border ? '8px' : null)};
  &.ant-checkbox-wrapper-checked {
    border-color: ${({ $border, theme }) => ($border ? theme.color.primary : null)};
  }
`;

export const StyledSpan = styled.span<Pick<StyledCheckBoxProps, '$color'>>`
  ${cssCheckbox}
  &.ant-checkbox-wrapper-checked {
    background-color: transparent;
    color: transparent;
  }
  &.ant-checkbox-wrapper {
    width: 40px;
    .ant-checkbox-inner {
      background-color: #fafbfc;
      border-color: transparent;
    }
    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: ${({ theme, color }) => (color === 'reverse' ? theme.color.white : theme.color.primary)};
        border-color: ${({ theme, color }) => (color === 'reverse' ? theme.color.white : theme.color.primary)};
        &::after {
          border-color: ${({ theme, color }) => (color === 'reverse' ? theme.color.primary : theme.color.white)};
        }
      }
      &::after {
        border-radius: 22px;
      }
    }
  }
`;
