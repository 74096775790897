/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import ReactGa from 'react-ga4';
import { Link, useNavigate } from 'react-router-dom';
import { Modal } from 'antd';
import { getMobileParams } from 'utils/storages';
import { useAuth, useChangeVendysPassword, useForm, useMeInfo } from 'hooks';
import { ChangePasswordParams } from 'interfaces/auth';
import { TermsModal } from 'components';
import { TermContainer } from 'containers';

import SettingMenuItem from './SettingMenuItem';
import SettingMenuContent from './SettinfMenuContent';
import AppDisplayInfo from './AppDisplayInfo';

import ChangePassword from '../ChangePassword';

interface TCafeteriaValue {
  cafeteriaOldPassword: string;
  cafeteriaNewPassword: string;
  cafeteriaNewPassword2: string;
}

const Setting = () => {
  const navigate = useNavigate();
  const { meInfo, selectedStore } = useMeInfo();
  const { signOut: logout } = useAuth();
  const { mutateAsync: changePasswordMutate } = useChangeVendysPassword();
  const [cafeteriaPassword, onChangeCafeteriaPassword] = useForm<Partial<TCafeteriaValue>>({});
  const [open, setOpen] = useState({ password: false, cafeteriaPassword: false, image: false, term: false, appDisplay: false });

  const gotoRequestHistory = () => {
    ReactGa.event({
      category: 'sikdang_setting',
      action: 'sikdang_request_history move request_history'
    });
  };

  const openChangePassword = () => {
    if (!open.password) {
      ReactGa.event({
        category: 'sikdang_setting',
        action: 'sikdang_reset password'
      });
    }
    setOpen({ ...open, password: !open.password });
  };

  const openCafeteriaChangePassword = () => {
    if (!open.cafeteriaPassword) {
      ReactGa.event({
        category: 'sikdang_setting',
        action: 'sikdang_cafeteria_reset password'
      });
    }
    setOpen({ ...open, cafeteriaPassword: !open.cafeteriaPassword });
  };

  const toggleTermModal = () => {
    if (!open.term) {
      ReactGa.event({
        category: 'sikdang_setting',
        action: 'sikdang_term open modal'
      });
    }
    setOpen({ ...open, term: !open.term });
  };

  const toggleAppDisplay = () => {
    if (!open.appDisplay) {
      ReactGa.event({
        category: 'sikdang_setting',
        action: 'sikdang_app_display open'
      });
    }
    setOpen({ ...open, appDisplay: !open.appDisplay });
  };

  // const textChange = (text) => {
  //   if (text.target.id == 'oldpassword') {
  //     this.state.oldpw = text.target.value;
  //   } else if (text.target.id == 'newpassword') {
  //     this.state.newpw = text.target.value;
  //   } else if (text.target.id == 'newpassword2') {
  //     this.state.newpw2 = text.target.value;
  //   }
  // };
  //
  // const cafeteriaTextChange = (text) => {
  //   if (text.target.id == 'cafeteriaOldpassword') {
  //     this.state.cafeteriaOldpw = text.target.value;
  //   } else if (text.target.id == 'cafeteriaNewpassword') {
  //     this.state.cafeteriaNewpw = text.target.value;
  //   } else if (text.target.id == 'cafeteriaNewpassword2') {
  //     this.state.cafeteriaNewpw2 = text.target.value;
  //   }
  // };

  const pushstateChange = () => {
    // const pushstats = !network.getMyInfo().pushcoupon;
    //
    // network.HTTPManager(
    //   'POST',
    //   'v1/store/push',
    //   { push: pushstats, storeId: network.getMyInfo().sid },
    //   function (data) {
    //     alert(data.message);
    //     const userinfo = network.getMyInfo();
    //     userinfo.pushcoupon = pushstats;
    //     network.writeitem(null, 'myInfo', JSON.stringify(userinfo));
    //     this.forceUpdate();
    //
    //     const uanaVigatorOs = navigator.userAgent;
    //     const AgentUserOs = uanaVigatorOs.replace(/ /g, '');
    //     if (AgentUserOs.indexOf('Android') != -1) {
    //       if (window.SikdaeBridge && window.SikdaeBridge.changedPush) {
    //         const result = { status: String(pushstats) };
    //         window.SikdaeBridge.changedPush(JSON.stringify(result));
    //       }
    //     }
    //   }.bind(this),
    //   function (xhr, status, error) {
    //     alert('처리 실패');
    //   }
    // );
  };

  const ttsStatsChange = () => {
    // if (network.readitem('mparams.tts').toString() == 'true') {
    //   network.writeitem(null, 'mparams.tts', false);
    //   location.href = 'sikdang://tts/false';
    // } else {
    //   network.writeitem(null, 'mparams.tts', true);
    //   location.href = 'sikdang://tts/true';
    // }
    // alert('변경되었습니다.');
    // this.forceUpdate();
  };

  const changeCafeteriaPasswd = async () => {
    const { cafeteriaOldPassword, cafeteriaNewPassword, cafeteriaNewPassword2 } = cafeteriaPassword || {};
    if (!selectedStore?.sid) {
      return;
    }

    if (!cafeteriaOldPassword || !cafeteriaNewPassword || !cafeteriaNewPassword2) {
      alert('모든 입력칸을 입력해주십시요.');
      return;
    }
    if (cafeteriaNewPassword !== cafeteriaNewPassword2) {
      alert('재입력한 비밀번호가 일치하지 않습니다.');
      return;
    }
    const params: ChangePasswordParams = {
      id: selectedStore.sid,
      type: 'CAFETERIA',
      target: cafeteriaNewPassword,
      source: cafeteriaOldPassword,
      reset: 'N'
    };
    await changePasswordMutate(params);
    alert('비밀번호가 변경되었습니다.');
  };

  let passwordbox = 'passwordbox';
  if (open.password) {
    passwordbox = 'passwordbox active';
  }
  let cafeteriaPasswordbox = 'cfPasswordbox';
  if (open.cafeteriaPassword) {
    cafeteriaPasswordbox = 'cfPasswordbox active';
  }

  const box = 'box';
  // if (this.state.mobileopen) box = 'box active';

  const userid = meInfo?.admin?.id;
  // let isAdmin = false;
  const lIsAdmin = localStorage.getItem('storeAdminId');
  const sIsAdmin = sessionStorage.getItem('storeAdminId');
  const isCafeteriaPw = selectedStore?.isCafeteriaPw;

  const { isMobile } = getMobileParams(); // tmp
  let voice = 'voice';
  let push = 'tab';
  const ttsImg = '';
  const pushImage = '';
  let cafeteriaTab = '';
  const voiceNotice = '식권 안내가 음성으로 안내됩니다.';

  if (typeof isCafeteriaPw === 'undefined' || !isCafeteriaPw || String(lIsAdmin) !== 'null' || String(sIsAdmin) !== 'null') {
    cafeteriaTab = 'tab notsupport';
  }
  if (!isMobile) {
    voice = 'voice notsupport';
    push = 'tab notsupport';
  } else {
    // if (network.readitem("mparams.os").toLowerCase() == "ios") {
    //   voiceNotice = "앱이 활성화되어 있을 때만 안내합니다.";
    // }
    //
    // let ttsImg, pushImage = "";
    // if (network.readitem("mparams.tts").toString() == "true") {
    //   ttsImg = imgOn;
    // } else {
    //   ttsImg = imgOff;
    // }
    //
    // if (network.readitem("mparams.pushtoken").toLowerCase() == "(null)") {
    //   push = "tab notsupport";
    // } else {
    //   if (network.getMyInfo()["pushcoupon"]) {
    //     pushImage = imgOn;
    //   } else {
    //     pushImage = imgOff;
    //   }
    // }
    //
    // if (String(lIsAdmin) != 'null' || String(sIsAdmin) != 'null') {	// 상위 계정 일 때 푸시, 음성 알림 미노출
    //   voice = "voice notsupport";
    //   push = "tab notsupport";
    // }
  }

  const handleClick = () => {
    if (meInfo?.admin?.informationAgreeStatus) {
      Modal.info({
        content: (
          <>
            선정산 사용 제휴점은 선정산 업체를 통해
            <br />
            선정산 계약 종료 후 탈퇴가 가능합니다.
          </>
        ),
        icon: null,
        okText: '확인',
        className: 'info-handler-modal'
      });
      return false;
    }
    return navigate('/withdraw');
  };

  const isSelectedStore = selectedStore && meInfo?.storeList?.length;
  return (
    <div className="setting-content">
      <div className={box}>
        <div className="myid">
          <p> 로그인 ID </p>
          {userid}
        </div>
        <SettingMenuItem name="비밀번호 변경" onClick={openChangePassword} className="custom" />
        <div className={passwordbox}>
          <ChangePassword fromSetting />
        </div>
        <SettingMenuItem name="제휴점 앱 노출정보 " onClick={toggleAppDisplay} disabled={!isSelectedStore} />
        {open.appDisplay ? (
          <SettingMenuContent>
            <AppDisplayInfo />
          </SettingMenuContent>
        ) : null}
        <Link to="/requestHistory" style={{ color: '#333333' }} onClick={gotoRequestHistory}>
          <SettingMenuItem name="요청 히스토리" ignoreToggle />
        </Link>
        <SettingMenuItem className={cafeteriaTab} name="구내식당 기기 비밀번호 변경" onClick={openCafeteriaChangePassword} />
        <div className={cafeteriaPasswordbox}>
          <input
            type="password"
            className="form-control"
            id="cafeteriaOldPassword"
            placeholder="기존 비밀번호"
            onChange={onChangeCafeteriaPassword}
          />
          <p>변경할 새로운 비밀번호를 입력해주세요.</p>
          <input
            type="password"
            className="form-control"
            id="cafeteriaNewPassword"
            placeholder="새로운 비밀번호"
            onChange={onChangeCafeteriaPassword}
          />
          <input
            type="password"
            className="form-control"
            id="cafeteriaNewPassword2"
            placeholder="새로운 비밀번호 재입력"
            onChange={onChangeCafeteriaPassword}
          />

          <div className="sbtn">
            <div className="changepassword" onClick={changeCafeteriaPasswd}>
              비밀번호변경하기
            </div>
          </div>
        </div>

        <div className={push}>
          <div className="left">식권사용 푸시알림</div>
          <div className="right">
            <img src={pushImage} className="toggle" onClick={pushstateChange} alt="icon push" />
          </div>
        </div>
        <div className={voice}>
          <div className="left">
            식권사용 음성안내
            <p className="notice">{voiceNotice}</p>
          </div>
          <div className="right">
            <img src={ttsImg} className="toggle" onClick={ttsStatsChange} alt="icon tts stats" />
          </div>
        </div>
        <SettingMenuItem name="이용약관" onClick={toggleTermModal} ignoreToggle />
        <TermsModal width={900} title="서비스 이용약관" open={open.term} close={toggleTermModal}>
          <TermContainer type="STORE_SIGNUP_SERVICE" />
        </TermsModal>
        <div
          className="logout"
          style={{ height: meInfo?.admin?.isAdmin && (!selectedStore || meInfo?.storeList.length <= 1) ? 100 : 60 }}
        >
          <div className="logoutbtn" onClick={() => logout()}>
            로그아웃
          </div>
          {meInfo?.admin?.isAdmin && (!selectedStore || meInfo?.storeList.length <= 1) ? (
            // eslint-disable-next-line react/button-has-type
            <button className="withdrawbtn" onClick={handleClick}>
              회원탈퇴
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Setting;
