/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Form, Select, Input } from 'antd';
import { errorHandler } from 'apis';
import { AddressPost, modalMethod } from 'components';
import { StepLayout } from 'layouts';
import { useSettlementMetaInfo, useMeInfo, useTransformAccount, useAdmissionTypes } from 'hooks';
import { mainCategoryStringToArray, getToken, setPassCertification } from 'utils';
import { TState } from 'interfaces/transition';
import { DescriptionWrapper, ConfirmWrapper } from './style';

const { useForm } = Form;

const ConfirmReorganization = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [form] = useForm();
  const { selectedStore, meInfo } = useMeInfo();
  const { mutateAsync } = useTransformAccount();
  const { data: metaInfo } = useSettlementMetaInfo(selectedStore?.sid || '');
  const { data: types } = useAdmissionTypes();

  useEffect(() => {
    if (!(location?.state as TState)?.transactionId) {
      navigate(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (metaInfo) {
      const value = { ...metaInfo };
      if (typeof value?.mainCategoryIdx === 'string') {
        value.mainCategoryIdx = mainCategoryStringToArray(value?.mainCategoryIdx);
      }
      const { address, zoneCode, addressType, addressDetail, jibunAddress, roadAddress, bizInfo, phone: p } = value;
      const { phone } = bizInfo || p;
      if (addressType) {
        value.address = {
          zoneCode,
          addressType,
          addressDetail,
          jibunAddress,
          roadAddress
        };
      } else {
        value.address = {
          zoneCode,
          addressType,
          addressDetail: address,
          jibunAddress,
          roadAddress
        };
      }
      value.chargePhone = phone;

      form.setFieldsValue({ ...bizInfo, ...value });
    }
  }, [form, metaInfo]);
  const handleNext = async () => {
    const tId = (location?.state as TState)?.transactionId;
    if (!tId) {
      return;
    }
    try {
      const paramData = {
        token: getToken(),
        transactionId: tId,
        isAdmin: meInfo.admin.isAdmin,
        id: meInfo.admin.id
      };
      await mutateAsync(paramData);
      modalMethod.info(
        <>
          <div>인증이 완료되었습니다.</div>
          <br />

          <div>식권대장 사장님앱을</div>
          <div>지금 바로 사용하실 수 있습니다.</div>
        </>
      );
      navigate('/');
    } catch (error) {
      const { response } = error;
      const { status } = response.data;
      if (status === -4038) {
        modalMethod.error(
          <>
            <div>필수 입력 정보가 없어</div>
            <div>인증을 완료하지 못하였습니다.</div>
            <div>1:1 문의 주시거나 고객센터로 전화 주시면</div>
            <div>신속하게 처리 해드리겠습니다.</div>
            <br />

            <div>{'>>'} 1:1 문의 : 하단 문의 버튼</div>
            <div>{'>>'} 전화문의 : 1644-5047</div>
          </>
        );
      } else {
        errorHandler(error);
      }
      navigate('/');
      setPassCertification(meInfo?.admin.id, true);
    }
  };
  return (
    <ConfirmWrapper>
      <StepLayout
        desc="사업자 정보 확인"
        content={
          <Form autoComplete="off" layout="vertical" name="admission_step1_form" form={form}>
            <Form.Item name="bizSerial" label="사업자등록번호" rules={[{ required: true }]}>
              <Input readOnly />
            </Form.Item>
            <Form.Item name="chargeName" label="대표자 이름" rules={[{ required: true }]}>
              <Input placeholder="대표자 이름 입력" readOnly />
            </Form.Item>
            <Form.Item name="chargePhone" label="대표자 전화번호" rules={[{ required: true }]}>
              <Input placeholder="대표자 전화번호 입력" readOnly />
            </Form.Item>
            <Form.Item name="storeName" label="상호명" rules={[{ required: true }]}>
              <Input placeholder="상호명 입력" readOnly />
            </Form.Item>
            <Form.Item name="phone" label="상호 전화번호" rules={[{ required: true }]}>
              <Input placeholder="-없이 숫자만 입력" readOnly />
            </Form.Item>
            <Form.Item name="address" label="사업장 주소" rules={[{ required: true }]}>
              <AddressPost readOnly />
            </Form.Item>
            <Form.Item name="bizCondition" label="사업자등록증 업태" rules={[{ required: true }]}>
              <Input placeholder="도, 소매업" readOnly />
            </Form.Item>
            <Form.Item name="bizType" label="사업자등록증 종목" rules={[{ required: true }]}>
              <Input placeholder="일반음식점" readOnly />
            </Form.Item>
            <Form.Item name="mainCategoryIdx" label="업종" rules={[{ required: true }]}>
              <Select mode="multiple">
                {types?.mainCategoryList &&
                  types.mainCategoryList.length &&
                  types.mainCategoryList.map((category) => <Select.Option key={category.idx}>{category.name}</Select.Option>)}
              </Select>
            </Form.Item>
            <Form.Item>
              <DescriptionWrapper>
                <div>상기 정보는 식권대장 사장님(구 식당대장)에</div>
                <div>이미 기입된 정보로,</div>
                <div>실제 정보와 다를 시 진행을 완료한 후</div>
                <div>1:1문의 주세요.</div>
              </DescriptionWrapper>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="button" onClick={handleNext}>
                확인
              </Button>
            </Form.Item>
          </Form>
        }
      />
    </ConfirmWrapper>
  );
};

export default ConfirmReorganization;
