import React from 'react';
import styled from 'styled-components';
import AgreeCheckbox, { AgreeCheckboxProps } from './AgreeCheckbox';

const CheckboxWrapper = styled.div<{ checked?: boolean }>`
  display: flex;
  width: 100%;
  background-color: ${({ theme, checked }) => (checked ? theme.color.primary : theme.color.gray04)};
  border-radius: 4px;
  overflow: hidden;
  & .ant-checkbox + span {
    color: ${({ checked, theme }) => {
      if (checked) return theme.color.white;
      if (!checked) return theme.color.gray02;
      return null;
    }};
  }
  & .ant-btn.ant-btn-link > span {
    font-size: 12px;
    text-decoration: underline;
    color: ${({ checked }) => {
      if (checked) return '#DCDCDC';
      if (!checked) return '#979797';
      return null;
    }};
  }
`;

export type FormCheckboxProps = AgreeCheckboxProps;
const FormCheckbox = ({ children, modalTitle, modalContent, checked, onChange, beforeVisible }: FormCheckboxProps) => (
  <CheckboxWrapper checked={checked}>
    <AgreeCheckbox
      required=""
      color="reverse"
      checked={checked}
      onChange={onChange}
      transparent
      beforeVisible={beforeVisible}
      modalTitle={modalTitle}
      modalContent={modalContent}
      confirmText="내용확인"
    >
      {children}
    </AgreeCheckbox>
  </CheckboxWrapper>
);

export default FormCheckbox;
