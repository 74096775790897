export const color = {
  primary: '#4BB378',
  lightGreen: '#E4F8EF',
  mint: '#E4F8EF',
  darkGreen: '#41AA6F',
  black: '#212529',
  black02: '#4B4D4B',
  white: '#FFFFFF',
  red: '#FF5630',
  redSecond: '#FF0606',
  red03: '#FF3B3E',
  yellow: '#FDC725',
  bgOrange: '#FFF0DE',
  blue: '#0067FF',
  gray: '#AFB3B8',
  gray01: '#353C49',
  gray02: '#6D7582',
  gray03: '#AFB2B9',
  gray04: '#D7D8DC',
  gray05: '#F3F5F6',
  gray06: '#F2F4F6',
  gray07: '#DCDCDC',
  gray08: '#BABABA'
};

export type TColor = typeof color;

export default { color };
