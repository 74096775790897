import { TBooking, TDetailInfo, TMenuContent } from 'interfaces/booking';
import React from 'react';
import styled from 'styled-components';
import CompanyList from './CompanyList';
import WeekDay from './WeekDay';
import SelectCalendar from './SelectCalendar';

const StyledWeekBooking = styled.div`
  .custom-tooltip {
    div:first-child {
      float: left;
      margin-right: 10px;
    }
    margin-bottom: 16px;
  }

  .icon {
    display: inline-block;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 5px;
  }

  .font {
    font-size: 14px;
  }
`;

interface WeekBookingMainProps {
  getMenuDetail: (v: TDetailInfo) => void;
  detailInfo: TDetailInfo;
  deleteMenuDetail: (v: string) => void;
  menuDetail: { content: TMenuContent };
  store: any;
  bookingList?: TBooking[];
  handleDialog: () => void;
  allComponent?: React.ReactNode;
}

const WeekBookingMain = ({
  store,
  bookingList,
  getMenuDetail,
  deleteMenuDetail,
  detailInfo,
  menuDetail,
  handleDialog,
  allComponent
}: WeekBookingMainProps) => {
  return (
    <StyledWeekBooking>
      <div className="custom-tooltip">
        <div>
          <span className="icon" style={{ background: '#46b476' }} />
          <span className="font" style={{ color: '#707371' }}>
            예약 중 메뉴
          </span>
        </div>
        <div>
          <span className="icon" style={{ background: '#a6a6a6' }} />
          <span className="font" style={{ color: '#a6a6a6' }}>
            공개 전 메뉴
          </span>
        </div>
      </div>
      <SelectCalendar
        stores={store}
        type="DateRange"
        text={{
          beforeText: '지난주',
          afterText: '다음주'
        }}
      />
      <WeekDay weekDays={store.weekDays} />
      <CompanyList
        weekDays={store.weekDays}
        bookingList={bookingList}
        handleDialog={handleDialog}
        getMenuDetail={getMenuDetail}
        detailInfo={detailInfo}
        deleteMenuDetail={deleteMenuDetail}
        menuDetail={menuDetail}
        allComponent={allComponent}
      />
    </StyledWeekBooking>
  );
};

export default WeekBookingMain;
