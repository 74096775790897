import { IOrderMenu, TOrderStatus } from 'interfaces/robot';
import DefaultTable, { TColumns } from 'pages/RobotDelivery/components/DefaultTable';
import { FlexBoxCenter, FlexBoxFullWidthCenter } from 'styles';
import { StatusBadge } from 'pages/RobotDelivery/components/OrderStatusBadge';
import moment from 'moment/moment';
import React from 'react';
import styled from 'styled-components';

const AggregateCardWrapper = styled(FlexBoxFullWidthCenter)`
  gap: 35px;
  align-items: start;
  width: 100%;
  padding: 14px 26px;
  background: #fff;
  border: 1px solid #e2e2e2;
`;
const OrderWrapper = styled(FlexBoxFullWidthCenter)<{ $status: TOrderStatus }>`
  .status-badge {
    margin-right: 60px;
  }
  .aggregate-content {
    font-size: 18px;
    color: #6d7582;
    & div:not(.status-badge):not(.decoration):not(.order) {
      &:not(.order-detail-button) {
        text-decoration: ${({ $status }) => {
          return $status === 'USER_CANCEL' || $status === 'STORE_CANCEL' || $status === 'ADMIN_CANCEL' ? 'line-through' : 'none';
        }};
      }
    }
    & .order-detail-button {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  td {
    font-size: 18px;
    color: #6d7582;
    text-decoration: ${({ $status }) => {
      return $status === 'USER_CANCEL' || $status === 'STORE_CANCEL' || $status === 'ADMIN_CANCEL' ? 'line-through' : 'none';
    }};
  }
  .order-total-count {
    min-width: 160px;
    color: ${({ $status }) => {
      return $status === 'USER_CANCEL' || $status === 'STORE_CANCEL' || $status === 'ADMIN_CANCEL' ? '#6d7582' : '#0067ff';
    }};
  }
  .delivery-memo {
    flex: 1;
    color: ${({ $status }) => {
      return $status === 'USER_CANCEL' || $status === 'STORE_CANCEL' || $status === 'ADMIN_CANCEL' ? '#6d7582' : '#212529';
    }};
  }
  .flex {
    flex: 1;
    text-align: right;
  }
  .order {
    flex: 1;
    font-size: 16px;
  }
  .p15 {
    padding: 15px 0;
  }
  .w210 {
    width: 210px;
  }
`;
const OrderStatusWrapper = styled(FlexBoxFullWidthCenter)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 160px;
  min-width: 90px;
  height: 180px;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  border-radius: 10px;
  color: #afb2b9;
  background: #eeeeee;
  .pd10 {
    padding-bottom: 10px;
  }
  .font16 {
    font-size: 16px;
    font-weight: 400;
  }
`;

interface OrderAggregateCardProps {
  isActive?: boolean;
  status: TOrderStatus;
  spotName: string;
  couponId: string;
  payRoomIdx: number;
  receiverName: string;
  deliveryMemo?: string;
  confirmDate?: string;
  createDate?: string;
  canceledDate?: string;
  totalOrderCount: number;
  columns: TColumns[];
  menus: IOrderMenu[];
  onDetailClick?: (couponId: string) => void;
}

const OrderAggregateCard = ({
  isActive = true,
  status,
  spotName,
  couponId,
  payRoomIdx,
  confirmDate,
  createDate,
  canceledDate,
  receiverName,
  deliveryMemo,
  totalOrderCount,
  columns,
  menus,
  onDetailClick
}: OrderAggregateCardProps) => {
  const orderMenus = menus.map(({ price, ...menu }) => ({ ...menu, price: price.toLocaleString() }));
  return (
    <AggregateCardWrapper>
      <OrderWrapper align="initial" direction="column" $status={status}>
        <FlexBoxFullWidthCenter gap="16px" className="aggregate-content">
          <StatusBadge status={status} />
          <div className="w210">{payRoomIdx}</div>
          <div className="w210">{createDate ?? confirmDate}</div>
          {isActive ? (
            <FlexBoxCenter className="order" justify="space-between">
              <div>배달장소 : {spotName}</div>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <div className="order-detail-button" onClick={() => onDetailClick(couponId)}>
                주문상세 &gt;
              </div>
            </FlexBoxCenter>
          ) : (
            <div className="order-total-count flex">총 주문 수량 : {totalOrderCount}개</div>
          )}
        </FlexBoxFullWidthCenter>
        <div className="accordion-content">
          <FlexBoxFullWidthCenter gap="16px" className="aggregate-content p15">
            <div className="order-total-count">총 주문 수량 : {totalOrderCount}개</div>
            <div className="w210">{receiverName}</div>
            <div className="delivery-memo">배달 요청사항 : {deliveryMemo || '-'}</div>
          </FlexBoxFullWidthCenter>
          <DefaultTable columns={columns} data={orderMenus} />
        </div>
      </OrderWrapper>
      {isActive && (status === 'ADMIN_CANCEL' || status === 'USER_CANCEL' || status === 'STORE_CANCEL') ? (
        <OrderStatusWrapper>
          <div className="pd10">취소 완료</div>
          <div className="font16">{moment(canceledDate).format('YYYY.MM.DD')}</div>
          <div className="font16">{moment(canceledDate).format('HH:mm:ss')}</div>
        </OrderStatusWrapper>
      ) : null}
    </AggregateCardWrapper>
  );
};

export default OrderAggregateCard;
