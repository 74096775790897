import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { getCoupon, getCouponGroup, getCouponDetail } from 'apis';

export const useCoupon = (sid: string | undefined, date: string) => {
  const params = useMemo(() => {
    if (!!sid && !!date) {
      return { sid, date };
    }
    return null;
  }, [sid, date]);
  return useQuery(['getCoupon', params], () => !!params && getCoupon(params), {
    enabled: !!sid && !!date
  });
};

export const useCouponGroup = (page: number, pageRow: number, sid: string | undefined, date: string, onSuccess: (v) => void) => {
  const params = useMemo(() => {
    if (!!page && !!sid && !!date) {
      return { sid, date, page, pageRow };
    }
    return null;
  }, [sid, date, page, pageRow]);
  return useQuery(['getCouponGroup', params], () => !!params && getCouponGroup(params), {
    enabled: !!page && !!sid && !!date,
    onSuccess
  });
};

export const useCouponDetail = (couponId, params) => {
  return useQuery(['getCouponDetail', couponId, params], () => !!params && getCouponDetail(couponId, params), {
    enabled: !!couponId && !!params
  });
};
