import React, { useEffect, useMemo, useState, useRef } from 'react';
import { Button, Switch } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { IPolling } from 'interfaces/booking';
import moment from 'moment';
import { generate } from 'shortid';
import { PollingContainer } from '../styles';
import { noti_submit_failed, noti_delivered, noti_canceled, volume } from '../image';
import audio_picking_up from '../audio/audio_picking_up.mp3';
import audio_submit_failed from '../audio/audio_submit_failed.mp3';
import audio_canceled from '../audio/audio_canceled.mp3';

export const setCookie = (name, value) => {
  const options: { path: string; expires } = {
    path: '/',
    expires: moment('2099-01-01')
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }
  let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

  Object.entries(options).forEach(([k, v]) => {
    updatedCookie += `; ${k}`;
    const optionValue = v;
    if (optionValue !== true) {
      updatedCookie += `=${optionValue}`;
    }
  });
  document.cookie = updatedCookie;
};

export const getCookie = (name) => {
  const matches = document.cookie.match(new RegExp(`(?:^|; )${name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1')}=([^;]*)`));
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

const PollingModal = ({ pollingArray }) => {
  const modalRef = useRef<any>(null);
  const [isAudio, setAudio] = useState<boolean>(true);
  const [playIdx, setPlayIdx] = useState(null);
  const [fade, setFade] = useState<string>();
  const [diffData, setDiffData] = useState<Array<IPolling>>([]);

  const [playing, setPlaying] = useState(true);
  const [myAudio, setMyAudio] = useState(null);

  const statusType = useMemo(
    () => ({
      PICKING_UP: { icon: noti_delivered, color: '#4BB378', status: '배차완료', audio: audio_picking_up },
      SUBMIT_FAILED: { icon: noti_submit_failed, status: '배차실패', audio: audio_submit_failed },
      CANCELED: { icon: noti_canceled, color: '#FF5630', status: '배달취소', audio: audio_canceled },
      ASSIGN_FAILED: { icon: noti_submit_failed, status: '배차실패', audio: audio_submit_failed }
    }),
    []
  );

  useEffect(() => {
    const cookie = getCookie('isAudio');
    if (!cookie) {
      setCookie('isAudio', isAudio);
    } else {
      setAudio(cookie === 'true');
    }
  }, [isAudio]);

  const onClose = async () => {
    setFade('fade-out');
    setPlaying(false);
  };

  useEffect(() => {
    function handleClickOutside(e: MouseEvent): void {
      if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
        onClose();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [modalRef]);

  useEffect(() => {
    if (!playing && myAudio) {
      myAudio.pause();
      myAudio.currentTime = 0;
      myAudio.load();
    }
    setPlaying(true);
  }, [playing, myAudio]);

  useEffect(() => {
    if (pollingArray.length > 0) {
      setFade('fade-in');
    }
  }, [pollingArray, diffData]);

  useEffect(() => {
    if (pollingArray && pollingArray.length > 0) {
      let i = 0;
      let j = 1;
      let nextSong;
      const audio = new Audio(statusType[pollingArray[i].status].audio);
      audio.volume = isAudio ? 1 : 0;
      audio.play();
      audio.id = 'audio';
      setMyAudio(audio);
      setPlayIdx(pollingArray.length - i - 1);
      audio.addEventListener('ended', async () => {
        if (j === 3) {
          i += 1;
          j = 1;
          nextSong = statusType[pollingArray[i].status].audio;
          setPlayIdx(pollingArray.length - i - 1);
          audio.src = nextSong;
          audio.load();
          audio.play();
          setMyAudio(audio);
        } else {
          audio.play();
          j += 1;
        }
      });
    }
  }, [pollingArray, playing, isAudio, statusType]);

  const onClearAlarm = async () => {
    setDiffData([]);
    setPlaying(false);
    setFade('fade-out');
  };

  const onChange = () => {
    setCookie('isAudio', !isAudio);
    setAudio(!isAudio);
  };

  return (
    <PollingContainer ref={modalRef}>
      <div className={`container ${fade}`}>
        <div className="header">
          <div className="flex">
            <span>음성안내</span>
            <Switch checked={isAudio} onChange={onChange} />
          </div>
          <div role="button" tabIndex={0} className="close-polling" onClick={onClose} onKeyDown={onClose}>
            <CloseOutlined />
          </div>
        </div>

        <div className="alarm-content">
          {diffData.map((item, index) => (
            <div key={generate()} className="alarm">
              <div className="info">
                <img src={statusType[item.status].icon} className="icon" alt="" />
                <div>
                  <div style={{ color: statusType[item.status].color }} className="title">
                    {statusType[item.status].status}- {item.agentAlias}
                  </div>
                  <div className="desc">{item.companyName}</div>
                  <div className="desc">{item.spotName}</div>
                </div>
              </div>
              {playIdx === index && <img src={volume} alt="" />}
            </div>
          ))}
        </div>
        <Button className="clearAlarm" onClick={onClearAlarm} ghost>
          모든 알람 지우기
        </Button>
      </div>
      <div id="sound" />
    </PollingContainer>
  );
};

export default PollingModal;
