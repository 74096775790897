import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Modal } from 'antd';
import { FlexBoxCenter } from 'styles';
import { IConfirmOrder, IRobotSpot, TSchedule } from 'interfaces/robot';
import { useWindowSize } from 'hooks/useWindowSize';
import { useRobotOrderAggregate, useRobotOrderCancel, useRobotOrderDetail } from 'hooks/useRobotDelivery';
import { ReactComponent as RobotDeliveryIcon } from 'images/robot_delivery.svg';

import OrderConfirmCard from './components/OrderConfirmCard';
import RobotDispatchModal from './modal/RobotDispatchModal';
import OrderAggregateModal from './modal/OrderAggregateModal';
import OrderDetailModal from './modal/OrderDetailModal';
import { ContentWrapper, DispatchCancelButton, NoContentWrapper, StyledButton } from './styles';
import OrderCancelModal from './modal/OrderCancelModal';

const OrderConfirmTopBar = styled.div`
  position: relative;
  top: -72px;
  //
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  background: #ffffff;

  .top-bar-title {
    font-size: 20px;
    font-weight: 700;
    text-align: center;

    .top-bar-description {
      font-size: 16px;
      font-weight: 400;
      color: #6d7582;
    }
  }
  .blue {
    color: #0067ff;
  }
`;
const OrderConfirmContent = styled.div``;

interface OrderConfirmPageProps {
  confirmOrders: IConfirmOrder[];
  maxOrderCount: number;
  selectedDate: string;
  selectedSpot: IRobotSpot;
}

const OrderConfirmPage = ({ confirmOrders, maxOrderCount, selectedDate, selectedSpot }: OrderConfirmPageProps) => {
  const [isDispatchModal, setIsDispatchModal] = useState<boolean>(false);
  const [isAggregateModal, setIsAggregateModal] = useState<boolean>(false);
  const [isDetailModal, setIsDetailModal] = useState<boolean>(false);
  const [isCancelModal, setIsCancelModal] = useState<boolean>(false);
  const [cancelCouponId, setCancelCouponId] = useState<string>();

  const [checkedCouponIds, setCheckedCouponIds] = useState<string[]>([]);
  const [schedule, setSchedule] = useState<TSchedule>();
  const { width } = useWindowSize();

  const { mutate: saveOrderAggregate } = useRobotOrderAggregate(
    schedule,
    () => {
      setSchedule(undefined);
      setCheckedCouponIds([]);
    },
    () => {
      setSchedule(undefined);
    }
  );
  const { mutate: cancelOrder } = useRobotOrderCancel();
  const { mutate: getOrderDetail, data: orderDetail } = useRobotOrderDetail();

  useEffect(() => {
    setCheckedCouponIds([]);
    if (schedule && !moment().startOf('day').isSame(moment(selectedDate).startOf('day'), 'day')) {
      setSchedule(undefined);
    }
  }, [selectedDate, schedule, selectedSpot]);

  useEffect(() => {
    const checked = [];
    checkedCouponIds.forEach((couponId) => {
      const isOrder = confirmOrders.filter((item) => item.couponId === couponId)[0];
      if (isOrder) {
        checked.push(couponId);
      }
    });
    setCheckedCouponIds(checked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmOrders]);

  const confirmOrderArray = useMemo(() => {
    const array: IConfirmOrder[][] = [];
    const zoom = ((window.outerWidth / width) * 100).toFixed();
    const col = width >= 1440 || Number(zoom) <= 75 ? 3 : 2;
    confirmOrders?.forEach((value, index) => {
      if (!array[index % col]) {
        array[index % col] = [];
      }
      array[index % col].push(value);
    });
    return array;
  }, [confirmOrders, width]);

  const { checkedOrders, totalCount } = useMemo(() => {
    const checkedList = confirmOrders?.filter((item) => checkedCouponIds.includes(item.couponId)) ?? [];
    const menuCount = checkedList.reduce((number, cur) => {
      return cur.menus.reduce((count, menu) => menu.count + count, 0) + number;
    }, 0);

    return { checkedOrders: checkedList, totalCount: menuCount };
  }, [confirmOrders, checkedCouponIds]);

  const handleOrderCardClick = (couponId: string) => {
    if (checkedCouponIds.indexOf(couponId) !== -1) {
      checkedCouponIds.splice(checkedCouponIds.indexOf(couponId), 1);
      setCheckedCouponIds([...checkedCouponIds]);
    } else {
      setCheckedCouponIds([...checkedCouponIds, couponId]);
    }
  };

  const handleOnOffDispatchModal = () => setIsDispatchModal(!isDispatchModal);
  const handleOnOffDetailModal = () => setIsDetailModal(!isDetailModal);
  const handleOnOffCancelModal = () => setIsCancelModal(!isCancelModal);
  const handleOnOffAggregateModal = () => {
    let content = '';
    const address = [];
    checkedOrders.forEach(({ robotDeliverySpotIdx }) => {
      if (!address.includes(robotDeliverySpotIdx)) {
        address.push(robotDeliverySpotIdx);
      }
    });
    if (totalCount > maxOrderCount) {
      content = `최대 ${maxOrderCount}잔까지만 합배송 가능합니다.`;
    } else if (address.length > 1) {
      content = `동일한 배달장소인 주문 건만 합배송이 가능합니다.\n합배송할 주문 건을 다시 선택해 주세요.`;
    } else {
      setIsAggregateModal(!isAggregateModal);
      return;
    }
    Modal.info({ content, icon: null, okText: '확인', className: 'info-handler-modal' });
  };

  const handleDetailClick = async (couponId: string) => {
    getOrderDetail(couponId);
    handleOnOffDetailModal();
  };

  const handleCancelClick = async (couponId: string) => {
    setCancelCouponId(couponId);
    handleOnOffCancelModal();
  };

  const handleAggregateConfirm = async () => {
    saveOrderAggregate({ schedule, couponIds: checkedCouponIds });
    handleOnOffAggregateModal();
  };

  const handleConfirmClick = (robot?: TSchedule) => {
    setSchedule(robot);
  };

  const handleCancelConfirmClick = (couponId: string, cancelMessage: string) => {
    cancelOrder({ couponId, cancelMessage });
    setCancelCouponId(undefined);
    handleOnOffCancelModal();
  };

  const handleCancelDispatch = () => {
    Modal.confirm({
      className: 'info-handler-modal',
      content: '배차를 취소하시겠습니까?',
      icon: null,
      okText: '확인',
      cancelText: '취소',
      onOk: () => {
        setCheckedCouponIds([]);
        handleConfirmClick();
      }
    });
  };

  return (
    <div>
      {moment().format('YYYY-MM-DD') === moment(selectedDate).format('YYYY-MM-DD') ? (
        <OrderConfirmTopBar>
          {schedule?.scheduleId ? (
            <DispatchCancelButton onClick={handleCancelDispatch}>
              <RobotDeliveryIcon />
              <div>
                <div className="robot-name">{schedule?.robotName}</div>
                <div className="delivery-start-time">
                  ({moment(`2025-01-01 ${schedule?.deliveryStartTime}`).format('HH:mm 출발')})
                </div>
              </div>
              <div className="box">취소</div>
            </DispatchCancelButton>
          ) : (
            <StyledButton onClick={handleOnOffDispatchModal}>배차 요청하기</StyledButton>
          )}
          <div className="top-bar-title">
            {checkedCouponIds && checkedCouponIds.length ? (
              <div>
                <span>선택한 주문 :</span>
                <span className="blue">&#32;{checkedCouponIds.length}건&#32;</span>(<span className="blue">{totalCount}개</span>)
              </div>
            ) : (
              `배차 요청 후 합배송할 주문 건을 선택해 주세요.`
            )}
            <div className="top-bar-description">동일한 배달장소인 경우, 최대 {maxOrderCount}잔까지 합배송 가능</div>
          </div>
          <StyledButton disabled={!schedule?.scheduleId || !checkedCouponIds.length} onClick={handleOnOffAggregateModal}>
            주문 확정하기
          </StyledButton>
        </OrderConfirmTopBar>
      ) : null}
      <OrderConfirmContent>
        <ContentWrapper $status="CONFIRM">
          {confirmOrders?.length ? (
            <FlexBoxCenter gap="20px" align="flex-start">
              {confirmOrderArray.map((orderArray) => (
                <FlexBoxCenter gap="20px" direction="column">
                  {orderArray?.map((order) => (
                    <div key={`${order.couponId}-${checkedCouponIds.length}`}>
                      <OrderConfirmCard
                        cardDisabled={!schedule?.scheduleId}
                        createDate={order.createDate}
                        deliveryFee={order.deliveryFee}
                        deliveryMemo={order.deliveryMemo}
                        spotName={order.spotName}
                        couponId={order.couponId}
                        payRoomIdx={order.payRoomIdx}
                        menus={order?.menus}
                        checked={checkedCouponIds.indexOf(order.couponId) >= 0}
                        onClick={handleOrderCardClick}
                        onDetailClick={handleDetailClick}
                        onCancelClick={handleCancelClick}
                      />
                    </div>
                  ))}
                </FlexBoxCenter>
              ))}
            </FlexBoxCenter>
          ) : (
            <NoContentWrapper>해당 상태의 주문 건이 없습니다.</NoContentWrapper>
          )}
        </ContentWrapper>
      </OrderConfirmContent>
      <RobotDispatchModal open={isDispatchModal} onCancel={handleOnOffDispatchModal} onConfirm={handleConfirmClick} />
      <OrderDetailModal open={isDetailModal} orderDetail={orderDetail} onCancel={handleOnOffDetailModal} />
      <OrderAggregateModal
        open={isAggregateModal}
        checkedOrders={checkedOrders}
        schedule={schedule}
        spot={selectedSpot}
        totalCount={totalCount}
        onCancel={handleOnOffAggregateModal}
        onConfirm={handleAggregateConfirm}
      />
      <OrderCancelModal
        open={isCancelModal}
        close={handleOnOffCancelModal}
        couponId={cancelCouponId}
        onConfirm={handleCancelConfirmClick}
      />
    </div>
  );
};

export default OrderConfirmPage;
