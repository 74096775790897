import { Button } from 'antd';
import { StepLayout } from 'layouts';
import React, { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CertificationResultDescription } from 'components';

const ResultPasswordContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const displayInfo = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { from, data } = (location.state as any) || {};
    if (data) {
      return { info: data.account?.signId, reg: data.account?.joinDate, from };
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const next = () => {
    navigate('/changePassword', { state: location.state, replace: true });
  };
  const gotoLogin = () => {
    navigate('/');
  };

  return (
    <StepLayout
      desc={
        <>
          <p>입력한 정보와 일치하는</p>
          <p>아이디를 찾았습니다!</p>
          {displayInfo?.from === '/forgotPassword' ? <p>비밀번호를 다시 설정해주세요.</p> : null}
        </>
      }
      content={
        <CertificationResultDescription
          signId={displayInfo?.info}
          regDate={displayInfo?.reg}
          viewPasswordDesc={displayInfo?.from === '/forgotSignId'}
        />
      }
      bottom={
        displayInfo?.from === '/forgotSignId' ? (
          <>
            <Button type="default" onClick={gotoLogin}>
              로그인
            </Button>
            <Button type="primary" onClick={next}>
              비밀번호 재설정
            </Button>
          </>
        ) : (
          <Button type="primary" onClick={next}>
            비밀번호 재설정
          </Button>
        )
      }
    />
  );
};

export default ResultPasswordContainer;
