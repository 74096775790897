import React from 'react';
import styled from 'styled-components';

const SettingMenuContentWrapper = styled.div<{ contentFixed: boolean }>`
  padding: 20px 60px;
  max-width: ${({ contentFixed }) => (contentFixed ? '600px' : null)};
`;

interface SettingMenuContentProps {
  children: React.ReactNode;
  contentFixed?: boolean;
}
const SettingMenuContent = ({ children, contentFixed = true }: SettingMenuContentProps) => {
  return <SettingMenuContentWrapper contentFixed={contentFixed}>{children}</SettingMenuContentWrapper>;
};

export default SettingMenuContent;
