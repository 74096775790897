import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Progress, Modal } from 'antd';
import {
  AdmissionStepOneForm,
  AdmissionStepTwoForm,
  AdmissionStepThreeForm,
  AdmissionStepFourForm,
  RouteModal,
  BottomButton
} from 'components';
import {
  useAdmissions,
  useSaveAdmission,
  useMeInfo,
  useBizSerial,
  useAdmissionTypes,
  useBankCodes,
  useValidBankAccount
} from 'hooks';
import { ContentPadding24 } from 'styles';
import { Admission } from 'interfaces/admission';

import { StepLayout } from 'layouts';
import AgreeForm from './AgreeForm';
import ReadyDocumentForm from './ReadyDocumentForm';
import { ProgressContainer, AdmissionWrapper } from './style';

type TAdmission = { presidentPhone?: number | string } & Partial<Admission>;

const steps = [
  {
    desc: (
      <>
        <p>서비스 이용을 위해</p>
        <p>약관 동의가 필요합니다.</p>
      </>
    ),
    content: <AgreeForm />
  },
  {
    desc: (
      <>
        <p>미리 준비해주세요!</p>
        <p>다음 서류가 필요합니다.</p>
      </>
    ),
    content: <ReadyDocumentForm />
  },
  {
    desc: '식권대장 노출정보 입력',
    content: null
  },
  {
    desc: '판매 형태 선택',
    content: (
      <>
        <p>식권대장에서 판매할 형태 5가지 중</p>
        <p>1가지를 선택해주세요.</p>
      </>
    )
  },
  {
    desc: '정산계좌 및 지출증빙 방식',
    content: null
  },
  {
    desc: '서류 업로드',
    subDesc: (
      <>
        <div>휴대폰으로 촬영하거나</div>
        <div>보유중인 이미지 파일을</div>
        <div>업로드 버튼을 눌러 등록해주세요.</div>
      </>
    ),
    content: null
  }
];

const AdmissionContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state as Record<string, unknown>;
  const { meInfo } = useMeInfo();
  const { isLoading: isSearching, mutateAsync } = useBizSerial();
  const { data } = useAdmissions();
  const { data: types } = useAdmissionTypes();
  const { data: bankCodes } = useBankCodes();
  const { isLoading: isSaveLoading, mutateAsync: saveAdmission } = useSaveAdmission();
  const { isLoading: isCheckLoading, mutateAsync: checkBankAccount } = useValidBankAccount();

  const [applyingAdmissionIdx, setApplyingAdmissionIdx] = useState<number | undefined>();
  const [step, setStep] = useState(1);

  const applyingAdmission = useMemo(() => {
    const draft: TAdmission = { presidentName: meInfo?.admin.name, presidentPhone: meInfo?.admin.cellPhone };
    if (!data?.admissionList || !data?.admissionList?.length) {
      return [draft];
    }
    const { admissionList } = data;
    let applyingAdmissionList;
    if (locationState?.admissionIdx) {
      applyingAdmissionList = admissionList.filter((admission) => admission.idx === locationState.admissionIdx);
    } else {
      applyingAdmissionList = admissionList.filter((admission) => admission.status === 'APPLYING');
    }
    if (!applyingAdmissionList?.length) {
      return [draft];
    }
    const lastApplyingAdmission = applyingAdmissionList.pop();

    setStep((prev) => (prev === 0 ? 2 : prev));
    setApplyingAdmissionIdx(lastApplyingAdmission!.idx);
    return [{ ...draft, ...lastApplyingAdmission }];
  }, [data, meInfo, locationState]);

  useEffect(() => {
    if (locationState?.step) {
      setStep(locationState.step as number);
    }
  }, [locationState]);

  const onPrevious = () => {
    setStep(step - 1);
    document.querySelector('.ant-modal-body')?.scrollTo({
      top: -1,
      behavior: 'auto'
    });
  };

  const onNext = () => {
    setStep(step + 1);
    document.querySelector('.ant-modal-body')?.scrollTo({
      top: -1,
      behavior: 'auto'
    });
  };

  const gotoContract = () => {
    navigate('contract', {
      state: { admissionIdx: applyingAdmissionIdx, ...(location.state as Record<string, unknown>) },
      replace: true
    });
  };

  const onSearch = async (bizSerial) => {
    const searchData = await mutateAsync(bizSerial);
    return searchData;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSaveStep = async (values: any) => {
    try {
      if (!meInfo?.admin?.said) {
        return;
      }
      const {
        admin: { said }
      } = meInfo;
      const idx = applyingAdmissionIdx;
      const admissionParams = { sid: said, admission: { ...values, step: step - 1, idx } };
      const savedData = await saveAdmission(admissionParams);
      if (step === 2 && savedData?.idx) {
        setApplyingAdmissionIdx(savedData.idx);
      }

      if (step < 5) {
        onNext();
      } else if (step === 5) {
        gotoContract();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const onCorp = () => {
    Modal.info({
      // title: 'error',
      content: (
        <>
          <div>법인 사업자는 1:1문의를 통해</div>
          <div>입점 신청을 진행하실 수 있습니다.</div>
          <div>로그인 화면 하단의 1:1문의를</div>
          <div>이용해주세요.</div>
        </>
      ),
      icon: null,
      okText: '확인',
      className: 'info-handler-modal'
    });
  };

  return (
    <RouteModal title="사업자 정보 작성">
      <AdmissionWrapper>
        <ProgressContainer>
          <Progress
            size="small"
            percent={step === 6 ? 100 : (step + 1) * 16.6}
            showInfo={false}
            strokeLinecap="square"
            status="success"
          />
        </ProgressContainer>
        <ContentPadding24 className="admission-content">
          <StepLayout
            className="admission-layout"
            desc={steps[step].desc}
            subDesc={steps[step].subDesc}
            content={
              <>
                {steps[step].content}
                {step === 2 && (
                  <AdmissionStepOneForm
                    initValues={applyingAdmission}
                    onNextStep={onSaveStep}
                    onSearch={onSearch}
                    searchLoading={isSearching}
                    loading={isSaveLoading}
                    mainCategories={types ? types.mainCategoryList : null}
                  />
                )}
                {step === 3 && (
                  <AdmissionStepTwoForm
                    applyingAdmissionIdx={applyingAdmissionIdx}
                    initValues={applyingAdmission}
                    onNextStep={onSaveStep}
                    onPreviousStep={onPrevious}
                    supplyTypes={types?.admissionSupplyTypeList}
                    loading={isSaveLoading}
                  />
                )}
                {step === 4 && (
                  <AdmissionStepThreeForm
                    initValues={applyingAdmission}
                    onNextStep={onSaveStep}
                    onPreviousStep={onPrevious}
                    bankCodes={bankCodes?.bankCodeList}
                    loading={isSaveLoading}
                    onSearch={checkBankAccount}
                    searchLoading={isCheckLoading}
                    bizTypeList={types?.bizTypeList}
                    bizConditionList={types?.bizConditionList}
                  />
                )}
                {step === 5 && (
                  <AdmissionStepFourForm onNextStep={onSaveStep} onPreviousStep={onPrevious} loading={isSaveLoading} />
                )}
              </>
            }
            bottom={
              <>
                {step === 0 && (
                  <BottomButton type="primary" onClick={onNext}>
                    동의하기
                  </BottomButton>
                )}
                {step === 1 && (
                  <>
                    <BottomButton bottomType="mint" onClick={onCorp}>
                      법인사업자
                    </BottomButton>
                    <BottomButton type="primary" onClick={onNext}>
                      일반사업자
                    </BottomButton>
                  </>
                )}
              </>
            }
          />
        </ContentPadding24>
      </AdmissionWrapper>
    </RouteModal>
  );
};

export default AdmissionContainer;
