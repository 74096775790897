import IncomeHistory from 'pages/CalculateInfoPage/IncomeHistory';
import React from 'react';
import styled from 'styled-components';

export const CalculateWrapper = styled.div`
  width: 900px;
  height: 100%;
  margin: auto;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 0;
`;

const CalculateInfoPage = () => {
  return (
    <CalculateWrapper>
      <IncomeHistory />
    </CalculateWrapper>
  );
};

export default CalculateInfoPage;
