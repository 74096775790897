import React, { useState } from 'react';
import moment from 'moment';
import { Button } from 'antd';
import { FlexBoxCenter } from 'styles';
import styled from 'styled-components';
import { IOrderMenu } from 'interfaces/robot';

import { StatusBadge } from './OrderStatusBadge';
import { StyledCheckbox } from '../styles';

const OrderCardWrapper = styled.div<{ $isActive: boolean }>`
  width: 390px;
  padding: 16px;
  background: ${({ $isActive }) => ($isActive ? '#eff6ff' : '#ffffff')};
  border: ${({ $isActive }) => ($isActive ? '1px solid #0067ff' : '1px solid #afb2b9')};
  border-radius: 8px;
`;
const OrderCardHeader = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: column;
  font-size: 16px;
  font-weight: 400;
  color: #6d7582;
  .order-detail-button {
    text-decoration: underline;
    text-underline-position: from-font;
    cursor: pointer;
  }
  .payment-number {
    font-size: 18px;
    color: #797c80;
  }
`;
const OrderInfoWrapper = styled.div`
  padding-bottom: 12px;
  border-bottom: solid 1px #d7d8dc;
  font-size: 16px;
  font-weight: 400;

  .order-count {
    padding-bottom: 12px;
    font-size: 24px;
    font-weight: 700;
    color: #0067ff;
  }
  .delivery-address {
    padding-bottom: 12px;
  }
  .delivery-request {
    color: #6d7582;
  }
`;
const OrderMenuWrapper = styled.div`
  .order-history-title {
    padding: 12px 0 10px;
    font-size: 18px;
    font-weight: 700;
  }
  .order-menu-content {
    padding: 10px 5px 15px;
  }
  .order-menu-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 13px;
    font-size: 14px;
    font-weight: 400;

    .order-menu-name {
      width: 200px;
      font-weight: 700;
    }
    .order-menu-count {
      width: 40px;
      text-align: end;
    }
    .order-menu-price {
      width: 100px;
      text-align: end;
    }
  }
  .order-menu-row.delivery-fee {
    color: #6d7582;
  }
  .order-total-price {
    padding-top: 6px;
    padding-bottom: 0;
    border-top: solid 1px #ededf2;
    font-weight: 700;
  }
`;
const CancelButton = styled(Button)`
  &:active,
  &:focus,
  &:hover,
  &.ant-btn-lg {
    width: 91px;
    color: #fff;
    background: #212529;
    border: none;
    border-radius: 4px;
  }
`;

interface OrderCardProps {
  cardDisabled: boolean;
  createDate: string;
  deliveryFee?: number;
  deliveryMemo?: string;
  couponId: string;
  payRoomIdx: number;
  menus: IOrderMenu[];
  spotName: string;
  checked?: boolean;
  onClick: (couponId: string) => void;
  onDetailClick: (couponId: string) => void;
  onCancelClick: (couponId: string) => void;
}

const OrderConfirmCard = ({
  cardDisabled,
  createDate,
  deliveryFee,
  deliveryMemo,
  spotName,
  couponId,
  payRoomIdx,
  menus,
  checked,
  onClick,
  onDetailClick,
  onCancelClick
}: OrderCardProps) => {
  const [isActive, setIsActive] = useState(checked ?? false);

  const handleCheckboxClick = () => {
    setIsActive(!isActive);
    onClick(couponId);
  };

  const totalPrice = menus?.reduce((acc, menu) => acc + menu.count * menu.price, 0);
  const totalCount = menus?.reduce((acc, menu) => acc + menu.count, 0);
  return (
    <OrderCardWrapper $isActive={isActive}>
      <FlexBoxCenter justify="space-between">
        <OrderCardHeader>
          <FlexBoxCenter gap="14px">
            <StatusBadge status="CONFIRM" />
            <div className="payment-number">{payRoomIdx}</div>
          </FlexBoxCenter>
          <FlexBoxCenter gap="26px">
            <div>{moment(createDate).format('YYYY-MM-DD HH:mm:ss')}</div>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div className="order-detail-button" onClick={() => onDetailClick(couponId)}>
              주문상세 &gt;
            </div>
          </FlexBoxCenter>
        </OrderCardHeader>
        <StyledCheckbox disabled={cardDisabled} checked={isActive} onClick={handleCheckboxClick} />
      </FlexBoxCenter>
      {/**/}
      <OrderInfoWrapper>
        <div className="order-count">총 주문 수량 : {totalCount}개</div>
        <div className="delivery-address">배달장소 : {spotName}</div>
        <div className="delivery-request">요청사항 : {deliveryMemo || '-'}</div>
      </OrderInfoWrapper>
      {/**/}
      <OrderMenuWrapper>
        <div className="order-history-title">주문 내역</div>
        <div className="order-menu-content">
          {menus?.map((menu) => (
            <div className="order-menu-row" key={menu.menuName}>
              <div className="order-menu-name">{menu.menuName}</div>
              <div className="order-menu-count">{menu.count}</div>
              <div className="order-menu-price">{menu.price.toLocaleString()}</div>
            </div>
          ))}
          {deliveryFee ? (
            <div className="order-menu-row delivery-fee">
              <div className="order-menu-name">배달비</div>
              <div className="order-menu-price">{deliveryFee.toLocaleString()}</div>
            </div>
          ) : null}
          <div className="order-menu-row order-total-price">
            <div className="bold">주문금액</div>
            <div className="bold">{totalPrice.toLocaleString()}</div>
          </div>
        </div>
      </OrderMenuWrapper>
      {/**/}
      <FlexBoxCenter justify="center">
        <CancelButton onClick={() => onCancelClick(couponId)}>주문 취소</CancelButton>
      </FlexBoxCenter>
    </OrderCardWrapper>
  );
};

export default OrderConfirmCard;
