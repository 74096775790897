import { Button } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { FlexBoxFullWidthCenter } from 'styles';
import { ReactComponent as CloseIcon } from 'images/pw_error.svg';
import { ReactComponent as RightIcon } from 'images/Vector175.svg';

interface TopPopDescriptionProps {
  children?: React.ReactNode;
  extra?: React.ReactNode;
  onClose?: () => void;
  onRedirect?: () => void;
}

const MeStatusDescriptionWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  padding: 12px 22px;
  background-color: ${({ theme }) => theme.color.bgOrange};
  .close-desc {
    border: none;
    width: 32px;
    height: 32px;
    padding: 4px;
    background-color: transparent;
    border-radius: 24px;
    svg {
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      border: 1px solid ${({ theme }) => theme.color.black};
      border-radius: 24px;
    }
    &:hover {
      svg {
        opacity: 0.7;
        border-color: ${({ theme }) => theme.color.primary};
      }
    }
  }
  .redirect-btn {
    display: flex;
    flex-direction: column;
    gap: 9px;
    font-size: 12px;
    line-height: 14px;
    color: ${({ theme }) => theme.color.black02};
    width: 82px;
    height: 76px;
    white-space: pre-wrap;
    align-items: center;
  }
`;

const TopPopDescription = ({ children, extra, onClose, onRedirect }: TopPopDescriptionProps) => {
  return (
    <MeStatusDescriptionWrapper>
      <Button className="close-desc" icon={<CloseIcon width={24} height={24} />} onClick={onClose} />
      <FlexBoxFullWidthCenter direction="column">{children}</FlexBoxFullWidthCenter>
      <Button type="link" className="redirect-btn" onClick={onRedirect}>
        <RightIcon width={17} height={24} />
        {extra}
      </Button>
    </MeStatusDescriptionWrapper>
  );
};

export default TopPopDescription;
