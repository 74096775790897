import { useQuery } from 'react-query';
import { getBanner, getShortcut } from 'apis';
import { TBannerType } from 'interfaces/popupBanner';

export const useBanner = () => {
  return useQuery<TBannerType>(['getBanner'], () => getBanner());
};

export const useShortcut = () => {
  return useQuery<TBannerType>(['getShortcut'], () => getShortcut());
};
