/* eslint-disable @typescript-eslint/no-unused-vars */
import { DateNavigation, PayInfoModal } from 'components';
import { useCoupon, useCouponGroup, useCouponDetail, useMeInfo, useExcelDownload } from 'hooks';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { numberWithCommas } from 'utils/stringUtils';

import RealtimeTable from './RealtimeTable';

const Realtime = () => {
  const testId = 'hvendys';
  const { changeExcelParams } = useExcelDownload();
  const [date, setDate] = useState(new Date());
  const [paging, setPaging] = useState({ page: 1, pageRow: 15 });
  const [isOpen, setOpen] = useState<boolean>(false);
  const [couponId, setCouponId] = useState<string | undefined>();
  const [contents, setContents] = useState<Record<string, unknown>[]>([]);
  const { selectedStore, meInfo } = useMeInfo();
  const { data } = useCoupon(selectedStore?.sid, moment(date).format('YYYY-MM-DD'));
  const { data: realtimeSales } = useCouponGroup(
    paging.page,
    paging.pageRow,
    selectedStore?.sid,
    moment(date).format('YYYY-MM-DD'),
    (result) => {
      changeExcelParams({ date1: date, date2: date });
      if (result?.sales?.length) {
        setContents([...contents, ...result.sales]);
      } else {
        setContents([]);
      }
    }
  );
  const { data: detail, isLoading } = useCouponDetail(couponId, { sid: selectedStore?.sid });

  const handleDateChange = (v: Date) => {
    setPaging({ ...paging, page: 1 });
    setContents([]);
    setDate(v);
  };

  const getMoreList = () => {
    setPaging({ ...paging, page: paging.page + 1 });
  };

  const openDetail = (selectedCouponId: string) => {
    setCouponId(selectedCouponId);
    setOpen(true);
  };

  const closeDetail = () => {
    setOpen(false);
  };

  return (
    <div className="realtime-content" id="realtime">
      <DateNavigation date={date} onChange={handleDateChange} />
      <div className="priceInfo">
        <div className="salereport">
          <div className="left">총액</div>
          <div className="right">
            {numberWithCommas(data?.totalPrice || 0)} <span className="won">원</span>
          </div>
        </div>
      </div>
      <RealtimeTable
        isTestId={meInfo?.admin.id === testId}
        realtimeSales={contents}
        isMore={paging.page * paging.pageRow < (realtimeSales?.totalCount || 0)}
        getMore={getMoreList}
        onClick={openDetail}
      />
      <PayInfoModal
        isLoading={isLoading}
        open={isOpen}
        close={closeDetail}
        data={detail?.coupon}
        menus={detail?.menus}
        shippingFeeInfos={detail?.shippingFeeInfo ? [detail?.shippingFeeInfo] : []}
        supplyType={selectedStore?.supplyType}
      />
    </div>
  );
};

export default Realtime;
