import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.div`
  height: 100vh;
  @media screen and (min-width: 768px) {
    width: 480px;
    margin-left: -53px;
    height: 820px;
    //margin-top: -73px;
    //margin-bottom: -24px;
  }
  & iframe {
    border: none;
    width: 100%;
    overflow-y: hidden;
  }
`;
