import React, { useEffect, useState } from 'react';
import noticeLogo from 'images/sajanim-notice.png';
import exclamationMark from 'images/exclamationMark.svg';

const ID = 'updateNoticeModal';

const UpdateNoticeModal = () => {
  const [isIos, setIos] = useState<boolean>(false);

  useEffect(() => {
    const uanaVigatorOs = navigator.userAgent;
    const AgentUserOs = uanaVigatorOs.replace(/ /g, '');
    // 식당대장 체크
    if (
      (AgentUserOs.indexOf('Android') !== -1 && window.SikdaeBridge && window.SikdaeBridge.sendStoreInfo) ||
      (uanaVigatorOs.match(/iPhone/i) &&
        window.webkit &&
        window.webkit.messageHandlers &&
        window.webkit.messageHandlers.SikdaeBridge)
    ) {
      setIos(!!uanaVigatorOs.match(/iPhone/i));
      $(`#${ID}`).modal({ backdrop: 'static' });
    }
  }, []);

  const update = () => {
    $(`#${ID}`).modal('hide');
    // Apple Store or Google Player
    const { userAgent } = navigator || { userAgent: '' };
    const AgentUserOs = userAgent.replace(/ /g, '');
    if (AgentUserOs.indexOf('Android') !== -1) {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.vendys.mealc.android.store';
    } else {
      window.location.href = 'https://itunes.apple.com/kr/app/milkeugamaengjeom/id944844150?l=ko&ls=1&mt=8';
    }
  };

  return (
    <div style={{ color: '#212529' }}>
      <div id="updateNoticeModal" className="modal fade" role="dialog" data-bs-backdrop="static" data-bs-keyboard="false">
        <div className="modal-dialog modal-fullscreen-md-down" role="document">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{
                textAlign: 'center',
                background: '#FFFFFF',
                fontSize: 16,
                fontWeight: 400,
                lineHeight: '24px',
                borderBottom: 'none',
                padding: 10
              }}
            >
              <b>공지</b>
            </div>
            <div className="modal-body" style={{ padding: '16px 16px 0' }}>
              <div className="notice-wrapper">
                <div
                  className="head"
                  style={{ fontSize: 24, fontWeight: 700, textAlign: 'center', lineHeight: '36px', wordBreak: 'keep-all' }}
                >
                  <div className="title">
                    <b>식권대장 사장님앱으로</b>
                  </div>
                  <div>
                    <b>업데이트 해주세요</b>
                  </div>
                  <img
                    style={{ width: 115, height: 115, marginTop: 20 }}
                    aria-hidden="true"
                    src={noticeLogo}
                    alt="notice-image"
                  />
                </div>
                <div className="content" style={{ marginTop: 20 }}>
                  <section
                    style={{
                      marginTop: 16,
                      fontSize: 16,
                      fontWeight: 400,
                      lineHeight: '24px',
                      textAlign: 'center',
                      wordBreak: 'keep-all'
                    }}
                  >
                    <b>
                      <div>2022년 12월 20일부터</div>
                      <div>식당대장 사용이 불가합니다.</div>
                    </b>
                    <div>식권대장 사장님앱으로 업데이트 시</div>
                    <div>기존에 제공하던 모든 서비스를</div>
                    <div>이용하실 수 있습니다.</div>
                  </section>
                  <section
                    style={{
                      marginTop: 16,
                      fontSize: 14,
                      fontWeight: 400,
                      lineHeight: '26px',
                      color: '#6D7582',
                      textAlign: 'left',
                      wordBreak: 'keep-all'
                    }}
                  >
                    식권대장 사장님은 기존 식당대장에서 제공하는 모든 서비스를 동일하게 제공하며, 아이디 찾기와 비밀번호 재설정
                    기능 등 사용성을 강화한 앱입니다. 한 번의 본인인증으로 추가된 모든 서비스를 이용하실 수 있습니다.
                  </section>
                  <section
                    className="notice"
                    style={{
                      marginTop: 16,
                      padding: '16px 16px 16px 48px',
                      fontSize: 16,
                      fontWeight: 400,
                      lineHeight: '24px',
                      background: '#F2F4F6',
                      position: 'relative',
                      borderRadius: 8
                    }}
                  >
                    <img
                      style={{ width: 24, height: 24, position: 'absolute', top: 16, left: 16 }}
                      aria-hidden="true"
                      src={exclamationMark}
                      alt=""
                    />
                    {isIos ? (
                      <div style={{ wordBreak: 'keep-all' }}>
                        <div>
                          <b>앱스토어에서 &quot;식권대장 사장님&ldquo;을 검색해 업데이트 하실 수 있습니다.</b>
                        </div>
                      </div>
                    ) : (
                      <div style={{ wordBreak: 'keep-all' }}>
                        운영에 차질 없도록 반드시 아래 버튼을 터치해 식권대장 사장님앱으로 업데이트해 주세요.
                      </div>
                    )}
                  </section>
                  <section style={{ marginTop: 16 }}>
                    {!isIos ? (
                      <button
                        type="button"
                        style={{ background: '#4BB378', height: 56, borderRadius: 5, fontSize: 20, color: '#FFFFFF' }}
                        className="agree-btn btn btn-block"
                        onClick={update}
                      >
                        <b>업데이트</b>
                      </button>
                    ) : null}
                  </section>
                </div>
              </div>
            </div>
            <div className="modal-footer" style={{ borderTop: 'none', padding: 10 }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateNoticeModal;
