import { Form, Modal, Typography } from 'antd';
import ValidSearch from 'components/Input/ValidSearch';
import { ValidStatus } from 'interfaces/admission';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { regex } from 'validators';
import { useMeInfo } from 'hooks';

export interface BizSerialFormItemProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSearch: (v: string) => Promise<any>;
  isSearching;
  checkValid?: (v: boolean) => void;
  notNavigate?;
  disabled?: boolean;
  help?: string;
}

const BizSerialFormItem = ({
  onSearch,
  isSearching,
  checkValid,
  notNavigate = false,
  disabled = false,
  help
}: BizSerialFormItemProps) => {
  const navigate = useNavigate();
  const { meInfo } = useMeInfo();
  const [validStatus, setValidStatus] = useState<ValidStatus>();
  const [ntsTaxTypeName, setNtsTaxTypeName] = useState<number | undefined>(); // 과세유형

  useEffect(() => {
    if (!checkValid) {
      return;
    }
    if (validStatus === 'success') {
      checkValid(true);
    } else {
      checkValid(false);
    }
  }, [checkValid, validStatus]);

  const handlerSearch = async (bizSerial) => {
    if (meInfo?.admin?.informationAgreeStatus) {
      Modal.info({
        content: (
          <>
            선정산 사용 제휴점은 사업자정보 변경이 불가능합니다.
            <br />
            자세한 사항은 페이워치를 통해 문의 바랍니다.
          </>
        ),
        icon: null,
        okText: '확인',
        className: 'info-handler-modal'
      });
      return null;
    }
    if (!bizSerial || bizSerial?.length !== 10) {
      return false;
    }
    try {
      const result = await onSearch(bizSerial);
      if (!result) {
        setValidStatus('error');
        return false;
      }
      const { ntsShutdownState, ntsTaxType, ntsTaxTypeName: typeName } = result;
      if (ntsShutdownState === 0) {
        Modal.info({
          content: (
            <>
              존재하지 않는 사업자등록번호입니다.
              <br />
              다시 입력해주세요.
            </>
          ),
          icon: null,
          okText: '확인',
          className: 'info-handler-modal'
        });
        return false;
      }
      if (ntsShutdownState === 2) {
        Modal.info({
          content: <>사업자 등록번호 조회 결과 현재 휴업 상태입니다. 홈택스에 로그인 해 확인 해보세요.</>,
          icon: null,
          okText: '확인',
          className: 'info-handler-modal'
        });
        return false;
      }
      if (ntsShutdownState === 3) {
        Modal.info({
          content: <>사업자 등록번호 조회 결과 폐업한 사업장입니다. 홈택스에 로그인 해 확인 해보세요.</>,
          icon: null,
          okText: '확인',
          className: 'info-handler-modal'
        });
        return false;
      }
      if (ntsShutdownState === 1) {
        // 계속사업자
        setNtsTaxTypeName(typeName);
      }
      // 간이과세, 면세사업자 유형
      if (!notNavigate && (ntsTaxType === 2 || ntsTaxType === 4)) {
        Modal.info({
          content: '간이과세자, 면세사업자의 경우는 제휴가 불가합니다.',
          icon: null,
          okText: '신청취소',
          onOk: () => {
            Modal.info({
              content: '다음에 다시 식권대장 사장님을 찾아와주세요. 사장님들에게 도움되는 식권대장 사장님이 되겠습니다.',
              icon: null,
              okText: '확인',
              onOk: () => {
                Modal.destroyAll();
                navigate(-1);
              },
              className: 'info-handler-modal'
            });
          },
          className: 'info-handler-modal'
        });
      }
      return true;
    } catch (error) {
      const { response } = error as Record<string, Record<string, unknown>>;
      setValidStatus('error');
      if (response?.data) {
        const { data } = response;
        Modal.info({
          content: (data as Record<string, string>).message,
          icon: null,
          okText: '확인',
          className: 'error-handler-modal'
        });
      }
      throw error;
    }
  };

  const handlerValidStatus = (status: ValidStatus) => {
    setValidStatus(status);
  };

  const handleDisabled = (bizSerial) => {
    return disabled || !(bizSerial && regex.onlyNumberReg.test(bizSerial) && bizSerial.length === 10);
  };

  const validAttribute = useMemo<{ status: '' | 'error' | 'success'; help?: React.ReactNode }>(() => {
    if (validStatus === 'changed') {
      return null;
    }
    if (validStatus === 'success') {
      return { status: 'success', help: <Typography.Text type="success">{ntsTaxTypeName}</Typography.Text> };
    }
    if (validStatus === 'error') {
      return { status: 'error', help: null };
    }
    if (help) {
      return { status: 'success', help: <Typography.Text type="success">{help}</Typography.Text> };
    }
    return null;
  }, [validStatus, ntsTaxTypeName, help]);

  return (
    <Form.Item shouldUpdate style={{ marginBottom: 0 }}>
      {() => {
        return (
          <Form.Item
            name="bizSerial"
            label="사업자등록번호"
            rules={[
              { required: true },
              () => ({
                validator(_, value) {
                  if (value?.value && (value.value.length < 10 || value.value.length > 10)) {
                    return Promise.reject(new Error('-없이 숫자 10자리 입력'));
                  }
                  if (!/\d/.test(value?.value)) {
                    return Promise.reject(new Error('-없이 숫자 10자리 입력'));
                  }
                  return Promise.resolve();
                }
              }),
              () => ({
                validator(_, value) {
                  if (value?.status === 'error') {
                    return Promise.reject(new Error('사업자번호조회 에러'));
                  }
                  if (value?.status === 'changed') {
                    return Promise.reject(new Error('사업자번호를 조회 확인 해주세요.'));
                  }
                  return Promise.resolve();
                }
              })
            ]}
            validateStatus={validAttribute?.status}
            help={validAttribute?.help}
          >
            <ValidSearch
              placeholder="-없이 숫자 10자리 입력"
              isSearching={isSearching}
              onSearch={handlerSearch}
              onValid={handlerValidStatus}
              disabled={disabled}
              buttonDisabled={handleDisabled}
              enterButtonText="조회"
            />
          </Form.Item>
        );
      }}
    </Form.Item>
  );
};

export default BizSerialFormItem;
