import { storeClient } from 'apis/apiClients';

export const getBookingByCompany = async (sid: string | undefined, companyId: string, bookingDate: string) => {
  const { data } = await storeClient.get(`/booking/v1/histories/companies/${companyId}/${bookingDate}`);
  return data;
};

export const getBookingHistoriesBySpot = async (bookingDate: string | undefined) => {
  if (!bookingDate) {
    throw new Error('[bookingDate] parameter is mandatory');
  }
  const { data } = await storeClient.get(`/booking/v1/histories/spots/${bookingDate}`);
  return data;
};

export const getBookingHistoriesByMenu = async (bookingDate: string | undefined) => {
  if (!bookingDate) {
    throw new Error('[bookingDate] parameter is mandatory');
  }
  const { data } = await storeClient.get(`/booking/v1/histories/menus/${bookingDate}`);
  return data;
};
export const getDeliveryInfo = async (sid: string | undefined, spotKey: string, bookingDate: string) => {
  const { data } = await storeClient.get(`/booking/v1/delivery/${spotKey}/${bookingDate}`);
  return data;
};

export const postOrderIndices = async (sid: string | undefined, spotKey: string, bookingArtifactIdx: string, params) => {
  const { data } = await storeClient.post(`/booking/v1/delivery/${spotKey}/${bookingArtifactIdx}`, params);
  return data;
};

export const pollingDeliveryStatus = async (sid: string | undefined, bookingDate: string) => {
  const { data } = await storeClient.get(`/booking/v1/delivery/polling/${bookingDate}`);
  return data;
};

export const putAgentCall = async (sid: string | undefined, params: object) => {
  const { data } = await storeClient.put(`/booking/v1/delivery`, params);
  return data;
};

export const getBookingDetail = async (orderIdx: number) => {
  const { data } = await storeClient.get(`/booking/v1/delivery/${orderIdx}`);
  return data;
};

export const postExpectFee = async (sid: string | undefined, spotKey: string, bookingDate: string, params) => {
  const { data } = await storeClient.post(`/booking/v1/delivery/${spotKey}/${bookingDate}/expect/fee`, params);
  return data;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getPushMessage = async (sid: string | undefined) => {
  const { data } = await storeClient.get(`/booking/v1/push/favorite`);
  return data;
};

export const postPushMessage = async (params: object) => {
  await storeClient.post(`/booking/v1/push`, params);
};

export const deleteDelivery = async (sid: string | undefined, orderIdx: number) => {
  const { data } = await storeClient.delete(`/booking/v1/delivery/${orderIdx}`);
  return data;
};
export const getBookingStats = async (params: unknown) => {
  const { data } = await storeClient.get(`/stat/v1/booking`, { params });
  return data;
};

export const getBookingMenus = async (params?: unknown) => {
  const { data } = await storeClient.get(`/store/v1/menu`, { params });
  return data;
};

export const getBookingArtifacts = async (params) => {
  const { data } = await storeClient.get(`/booking/v1/artifact`, { params });
  return data;
};

export const getBookingArtifactByIdx = async (artifactIdx: number) => {
  const { data } = await storeClient.get(`/booking/v1/artifact/${artifactIdx}`);
  return data;
};

export const saveBookingArtifactMenu = async (artifactIdx: number, params: unknown) => {
  const { data } = await storeClient.post(`/booking/v1/artifact/${artifactIdx}/menu/`, params);
  return data;
};

export const deleteBookingArtifactMenuByMenuId = async (artifactIdx: number, menuId: string) => {
  const { data } = await storeClient.delete(`/booking/v1/artifact/${artifactIdx}/menu/${menuId}`);
  return data;
};

export const saveBookingArtifactMenusByDay = async (bookingDate: string, params: unknown) => {
  const { data } = await storeClient.post(`/booking/v1/artifact/${bookingDate}/menus`, params);
  return data;
};

export const deleteBookingArtifactMenusByDay = async (bookingDate: string, params: unknown) => {
  const { data } = await storeClient.put(`/booking/v1/artifact/${bookingDate}/menus`, params);
  return data;
};

export const getBookingHistoriesDetail = async (couponId: string) => {
  const { data } = await storeClient.get(`/booking/v1/histories/${couponId}`);
  return data;
};

export const getBookingRelationCompaniesByDay = async (
  bookingDate: string,
  menuId: string,
  params?: { searchValue?: string }
) => {
  const { data } = await storeClient.get(`/booking/v1/artifact/${bookingDate}/menus/${menuId}/relation-companies`, { params });
  return data;
};
