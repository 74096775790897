import React, { useMemo, useState } from 'react';
import {
  useBookingArtifact,
  useBookingArtifacts,
  useBookingMenus,
  useDeleteBookingArtifactMenu,
  useSaveBookingArtifactMenu,
  useSaveBookingArtifactMenusByBookingDate,
  useDeleteBookingArtifactMenusByBookingDate
} from 'hooks';
import moment from 'moment';
import ArtifactDetailMenuAll from 'pages/ManageBookingMenu/ArtifactDetailMenuAll';
import NewManageBookingMenu from './NewManageBookingMenu';

const ManageBookingMenu = () => {
  const [params, setParams] = useState<unknown>({
    start: moment().startOf('day').day(0).format('YYYY-MM-DD'),
    end: moment().startOf('day').day(6).format('YYYY-MM-DD')
  });
  const [menuKeywordParams, setMenuKeywordParams] = useState<unknown>();
  const [artifactIdx, setArtifactIdx] = useState<number | string>();
  const [bookingStoreIdx, setBookingStoreIdx] = useState<number>();

  const { data: bookingArtifact, reload: reloadCurrentBookingArtifacts } = useBookingArtifacts(params);

  const { data: artifact, reload: reloadCurrentArtifact } = useBookingArtifact(artifactIdx);
  const { data: menus } = useBookingMenus(menuKeywordParams);
  const { mutateAsync: saveBookingArtifactTemplateMenus } = useSaveBookingArtifactMenu();
  const { mutateAsync: deleteBookingArtifactTemplateMenu } = useDeleteBookingArtifactMenu();
  const { mutateAsync: saveMenusByBookingDate } = useSaveBookingArtifactMenusByBookingDate();
  const { mutateAsync: deleteMenusByBookingDate } = useDeleteBookingArtifactMenusByBookingDate();

  const handleGetBookingArtifacts = (p: unknown) => {
    setParams(p);
  };

  const handleGetBookingArtifactDetail = (selectedArtifactIdx: number | string) => {
    if (typeof selectedArtifactIdx === 'string') {
      const [templateName] = selectedArtifactIdx.split('_');
      const content = bookingArtifact?.contents[0];
      const target = content.templates.filter(({ template }) => template.name === templateName)[0];
      setBookingStoreIdx(target.template.bookingStoreIdx);
    }
    setArtifactIdx(selectedArtifactIdx);
  };

  const handleSaveArtifactMenus = async (selectedArtifactIdx: number | string, saveData: { contents: string[] }) => {
    if (typeof selectedArtifactIdx === 'number') {
      await saveBookingArtifactTemplateMenus({
        artifactIdx: selectedArtifactIdx,
        saveData
      });
    } else {
      // selectedArtifactIdx 날짜 넘어오면!
      const [, bookingDate] = selectedArtifactIdx.split('_');
      await saveMenusByBookingDate({
        bookingDate: moment(bookingDate).format('YYYY-MM-DD'),
        saveData: { menus: saveData.contents, bookingStoreIdx }
      });
    }
    reloadCurrentBookingArtifacts();
    reloadCurrentArtifact();
  };

  const handleDeleteArtifactMenu = async (selectedArtifactIdx: number, menuId: string) => {
    const deleteParams = {
      artifactIdx: selectedArtifactIdx,
      menuId
    };
    await deleteBookingArtifactTemplateMenu(deleteParams);
    reloadCurrentBookingArtifacts();
    reloadCurrentArtifact();
  };

  const handleDeleteArtifactMenusByDate = async (selectedMenus: string[]) => {
    let date = artifactIdx;
    if (typeof artifactIdx === 'string') {
      const [, bookingDate] = artifactIdx.split('_');
      date = bookingDate;
    }
    const deleteParams = {
      bookingDate: moment(date).format('YYYY-MM-DD'),
      saveData: { menus: selectedMenus, bookingStoreIdx }
    };
    await deleteMenusByBookingDate(deleteParams);
    reloadCurrentBookingArtifacts();
    reloadCurrentArtifact();
  };

  const handleGetMenus = async (keywordParas: unknown) => {
    setMenuKeywordParams(keywordParas);
  };

  const dayMenuDetail = useMemo(() => {
    if (typeof artifactIdx !== 'string' || !bookingArtifact?.contents?.length) {
      return null;
    }
    const [templateName, bookingDate] = artifactIdx.split('_');
    const templates = bookingArtifact?.contents[0].templates;
    const { artifacts } = templates.filter((item) => item.template.name === templateName)[0];

    // eslint-disable-next-line no-unsafe-optional-chaining,@typescript-eslint/no-shadow
    const artifact = artifacts?.filter((item) => item.bookingDate === bookingDate)[0];
    if (!artifact) return null;
    const { menus: artifactMenus, status } = artifact;
    return {
      content: {
        date: {
          release: '360M'
        },
        company: {},
        menus: artifactMenus?.map(({ id, ...other }) => ({
          id,
          ...(other as Record<string, unknown>),
          status
        })),
        status
      }
    };
  }, [artifactIdx, bookingArtifact]);

  const bookingDate = useMemo(() => {
    if (typeof artifactIdx === 'string') {
      const [, data] = artifactIdx.split('_');
      return moment(data).format('YYYY-MM-DD');
    }
    return '';
  }, [artifactIdx]);

  return (
    <div>
      <NewManageBookingMenu
        bookingList={bookingArtifact?.contents}
        menuDetail={dayMenuDetail || artifact}
        menuList={menus}
        getBookings={handleGetBookingArtifacts}
        getMenuDetail={handleGetBookingArtifactDetail}
        saveMenu={handleSaveArtifactMenus}
        deleteMenu={handleDeleteArtifactMenu}
        getMenus={handleGetMenus}
        allComponent={
          bookingArtifact?.isTotalMod ? (
            <ArtifactDetailMenuAll key={artifactIdx} bookingDate={bookingDate} removeMenu={handleDeleteArtifactMenusByDate} />
          ) : null
        }
      />
    </div>
  );
};

export default ManageBookingMenu;
