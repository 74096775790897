import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MeInfo } from 'interfaces/auth';
import { StoreType } from 'interfaces/store';
import { getStoreAdmin, getMyInfo, setMyInfo } from 'utils/storages';
import { useStore } from 'hooks';

interface MeInfoContextInterface {
  meInfo: MeInfo | undefined;
  selectedStore: StoreType | undefined;
  changeMeInfo: (v: MeInfo) => void;
  changeStore: (v) => void;
}
export const MeInfoContext = React.createContext<MeInfoContextInterface>({
  meInfo: undefined,
  selectedStore: undefined,
  changeMeInfo: () => {},
  changeStore: () => {}
});

export const MeInfoProvider = ({ children }) => {
  const initStore = getMyInfo();
  const [meInfo, setMeInfo] = useState<MeInfo | undefined>(getStoreAdmin());
  const [selectedStore, setSelectedStore] = useState<StoreType>(initStore);
  const [sid, setSid] = useState<string>(initStore?.sid || '');
  const { data } = useStore(sid, !!sid);

  useEffect(() => {
    if (data) {
      const { sid: key } = data;
      const currentStore = meInfo?.storeList?.filter(({ sid: id }) => id === key);
      const newData = { ...data };
      newData.meInfoCurrent = currentStore?.length === 1 ? currentStore[0] : undefined;
      setSelectedStore(newData);
    }
  }, [meInfo, data]);

  const changeMeInfo = useCallback((me: MeInfo) => {
    if (me.storeList && me.storeList.length === 1) {
      const store = me.storeList[0];
      setMyInfo(store); // for legacy code
      setSid(store.sid); // store search trigger
    }
    setMeInfo(me);
  }, []);

  const changeStore = useCallback((store) => {
    setSelectedStore(store);
    setMyInfo(store); // for legacy code
    setSid(store?.sid);
  }, []);
  const value = useMemo(
    () => ({ meInfo, selectedStore, changeStore, changeMeInfo }),
    [meInfo, selectedStore, changeStore, changeMeInfo]
  );

  return <MeInfoContext.Provider value={value}>{children}</MeInfoContext.Provider>;
};

export default MeInfoProvider;
