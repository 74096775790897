import styled from 'styled-components';

const Container = styled.div`
  background-color: #f2f4f6;
  height: 100%;
  width: 100%;
  #mask {
    z-index: 1;
  }
  .admission {
    align-self: center;
  }
  & {
    #wrapper {
      display: flex;
    }
  }
  @media (max-width: 767px) {
    #wrapper {
      display: block;
    }
    #sidebar {
      height: auto;
    }
    #sidebar-wrapper {
      display: none;
      width: 0;
    }
    #page-content-wrapper {
      width: 100%;
    }
  }
`;

export default Container;
