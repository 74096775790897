/* eslint-disable jsx-a11y/label-has-associated-control */
import { useReviewReportTypes } from 'hooks';
import React, { useEffect, useState } from 'react';
import { generate } from 'shortid';
import iconCloseBlack from 'images/other/ic-close-black.svg';

const ReviewReportModal = ({ open, close }) => {
  const [state, setState] = useState({
    reportTypes: [],
    reason: null,
    reasonDetail: '',
    requesterName: '',
    requesterPhone: ''
  });

  const { data: reportTypes } = useReviewReportTypes();
  // TODO delete jquery

  useEffect(() => {
    $('#review-report').on('hidden.bs.modal', () => {
      setState({ ...state, reason: null, reasonDetail: '', requesterName: '', requesterPhone: '' });
      close();
    });
  }, [close, state]);

  useEffect(() => {
    if (open === true) {
      $('#reason-detail').val('');
      $('#requester-name').val('');
      $('#requester-phone').val('');
      $('input[type=radio]').prop('checked', false);
      // TODO delete bootstrap
      // eslint-disable-next-line
      // @ts-ignore
      $('#review-report').modal('show');
      // getReportTypes();
    }
    if (open === false) {
      // TODO delete bootstrap
      // eslint-disable-next-line
      // @ts-ignore
      $('#review-report').modal('hide');
    }
  }, [open]);

  const handleChange = ({ target }) => {
    const { name, value } = target;
    if (name === 'reasonDetail') {
      if (value.length > 0 && value.length < 10) {
        $('#reason-detail').css({ border: '1px solid #ff0000' });
      } else {
        $('#reason-detail').css({ border: '1px solid #cccccc' });
      }
      setState({ ...state, reasonDetail: value });
    } else if (name === 'requesterPhone') {
      if (!value) {
        $('#requester-phone').css({ border: '1px solid #ff0000' });
      } else {
        $('#requester-phone').css({ border: '1px solid #cccccc' });
      }
      const regex = /[^0-9]/g;
      $('#requester-phone').val(value.replace(regex, ''));
      setState({ ...state, requesterPhone: value.replace(regex, '') });
    } else if (name === 'requesterName') {
      if (!value) {
        $('#requester-name').css({ border: '1px solid #ff0000' });
      } else {
        $('#requester-name').css({ border: '1px solid #cccccc' });
      }
      setState({ ...state, requesterName: value });
    } else {
      setState({ ...state, [name]: value });
    }
  };

  const register = () => {
    const { reason, reasonDetail, requesterName, requesterPhone } = state;
    if (reasonDetail.length < 10 || !requesterPhone) {
      return;
    }
    close({ reason, reasonDetail, requesterName, requesterPhone });
    setState({ ...state, reason: null, reasonDetail: '', requesterName: '', requesterPhone: '' });
  };

  const { reason, reasonDetail, requesterName, requesterPhone } = state;

  return (
    <div id="review-report" className="modal fade" role="dialog">
      <div className="full-width modal-dialog modal-fullscreen-md-down" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">이의제기 신청</h5>
            <button type="button" className="close" aria-label="Close" onClick={close}>
              <img aria-hidden="true" src={iconCloseBlack} alt="icon-close-btn" />
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid">
              <div className="mb-3">
                <label htmlFor="reason-list" className="form-label">
                  이의제기 신청사유를 선택해주세요.<span style={{ color: '#ff0000' }}> *</span>
                </label>
                <ul id="reason-list" className="list-group list-group-flush">
                  {reportTypes?.length
                    ? reportTypes.map(({ code, name }, i) => (
                        <li className="list-group-item" key={generate()}>
                          <div className="radio">
                            <label htmlFor={`reason${i}`} className="form-check-label">
                              <input
                                type="radio"
                                id={`reason${i}`}
                                className="form-check-input"
                                value={code}
                                checked={state.reason === code}
                                name="reason"
                                onChange={handleChange}
                              />
                              {name}
                            </label>
                          </div>
                        </li>
                      ))
                    : null}
                </ul>
              </div>
              <div className="mb-3">
                <label htmlFor="reason-detail" className="form-label">
                  상세 내용을 작성해주세요.<span style={{ color: '#ff0000' }}> *</span>
                </label>
                <textarea
                  className="form-control"
                  id="reason-detail"
                  name="reasonDetail"
                  rows={5}
                  onChange={handleChange}
                  placeholder="적절한 조치가 이루어질 수 있도록 내용을 10자 이상 입력해주세요"
                  maxLength={500}
                />
              </div>
              <div className="mb-3">
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <div>* 이의 신청 된 리뷰는 담당자 확인 후 운영 원칙에 따라 처리됩니다.</div>
                  <div>{reasonDetail.length}/500</div>
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="requester-name" className="form-label">
                  이름을 입력해주세요.<span style={{ color: '#ff0000' }}> *</span>
                </label>
                <textarea
                  className="form-control"
                  id="requester-name"
                  name="requesterName"
                  rows={1}
                  onChange={handleChange}
                  placeholder="신청자 본인의 이름을 입력해주세요."
                  maxLength={100}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="requester-phone" className="form-label">
                  연락처를 입력해주세요.<span style={{ color: '#ff0000' }}> *</span>
                </label>
                <textarea
                  className="form-control"
                  id="requester-phone"
                  name="requesterPhone"
                  rows={1}
                  onChange={handleChange}
                  placeholder="신청자 본인의 연락처를 입력해주세요."
                  maxLength={20}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-block btn-success"
              disabled={!reason || !reasonDetail || !requesterName || !requesterPhone}
              onClick={register}
            >
              등록
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewReportModal;
