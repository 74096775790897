import React from 'react';
import styled from 'styled-components';

const TableWrapper = styled.table`
  width: 100%;
  th {
    min-width: 150px;
    height: 43px;
    border: solid 1px #afb2b9;
    background: rgba(0, 0, 0, 0.06);
    font-size: 18px;
    font-weight: 600;
    text-align: center;
  }
  td {
    height: 48px;
    padding: 10px 12px;
    border: solid 1px #afb2b9;
    font-size: 18px;
    font-weight: 400;
    color: #797c80;
  }
`;

export interface TColumns {
  field: string;
  headerName: string;
  width?: number;
  textAlign?: 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'match-parent';
}

export interface DefaultTableProps<TData> {
  columns: TColumns[];
  data: TData[];
}

const DefaultTable = <TData,>({ columns, data }: DefaultTableProps<TData>) => {
  return (
    <TableWrapper>
      <thead className="a">
        <tr>
          {columns.map(({ field, headerName, width }) => (
            <th key={`th-${field}`} style={{ width }}>
              {headerName}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => {
          const key = `tr-${index}`;
          return (
            <tr key={key}>
              {columns.map(({ field, textAlign }) => {
                const tdKey = `td-${field}-${index}`;
                return (
                  <td key={tdKey} style={{ textAlign }}>
                    {item[field]}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </TableWrapper>
  );
};

export default DefaultTable;
