import { RuleObject, UnionKeys } from 'interfaces/validation';
import { getValue } from 'utils';

const engReg = /[a-zA-Z]+/;
const numberReg = /\d+/;
const specReg = /[~`!@#$%^&*()_\-+={}[\];:"\\'|<>,.?/]+/;
const onlyNumberReg = /^[0-9]+$/;

const passwordLength = () => ({
  validator(_, value) {
    if (value && value.length >= 8 && value.length <= 20) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('8~20자 입력'));
  }
});
const passwordEnglish = () => ({
  validator(_, value) {
    if (value && engReg.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('영문 미포함'));
  }
});
const passwordNumber = () => ({
  validator(_, value) {
    if (value && numberReg.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('숫자 미포함'));
  }
});
const passwordSpec = () => ({
  validator(_, value) {
    if (value && specReg.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('특수문자 미포함'));
  }
});

export const onlyNumberValidator =
  <T>(errorMessage?: string, key?: UnionKeys<T>) =>
  () => ({
    validator(_, value: T | string) {
      const v: string = getValue<T>(value, key);
      if (v && !onlyNumberReg.test(v)) {
        return Promise.reject(new Error(errorMessage || '숫자만 입력'));
      }
      return Promise.resolve();
    }
  });

export const minLength =
  <T>(length: number, errorMessage?: string, key?: UnionKeys<T>) =>
  () => ({
    validator(_, value: T | string) {
      const v: string = getValue<T>(value, key);
      if (v && v.length < length) {
        return Promise.reject(new Error(errorMessage || `최소 ${length}자리 이상 입력하세요`));
      }
      return Promise.resolve();
    }
  });

export const regex = {
  includeEngReg: engReg,
  includeNumberReg: numberReg,
  includeSpecReg: specReg,
  onlyNumberReg
};

export const passwordValidator = [passwordLength, passwordEnglish, passwordNumber, passwordSpec];

export const checkboxRequiredRuleValidator = (_: RuleObject, value: boolean) =>
  value ? Promise.resolve() : Promise.reject(new Error());
