import { UnionKeys } from 'interfaces/validation';

// eslint-disable-next-line import/prefer-default-export
export const getValue = <T>(value: T | string, key?: UnionKeys<T>): string => {
  let v = '';
  if (typeof value === 'string') {
    v = value;
  } else if (key && typeof value === 'object') {
    const property: unknown = value[key];
    v = property as string;
  }
  return v;
};
