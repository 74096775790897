import { TBooking, TDetailInfo, TMenuContent } from 'interfaces/booking';
import React from 'react';
import styled from 'styled-components';
import Template from './Template';

const StyledCompanyList = styled.div`
  min-width: 1027px;
  font-size: 13px;

  .company-container {
    margin-bottom: 16px;

    .company-title {
      font-size: 14px;
      height: 34px;
      border-bottom: 1px solid #dcdcdc;
      margin-bottom: -1px;
      background-color: #eeeeee;
      padding-left: 16px;
      display: flex;
      align-items: center;
    }

    p {
      margin: 0;
      font-weight: 400;
      line-height: 1.5;
    }
  }
`;

interface CompanyListProps {
  weekDays: string[];
  bookingList?: TBooking[];
  handleDialog: () => void;
  getMenuDetail: (v: TDetailInfo) => void;
  detailInfo: TDetailInfo;
  deleteMenuDetail: (v: string) => void;
  menuDetail: { content: TMenuContent };
  allComponent?: React.ReactNode;
}

const CompanyList = ({
  weekDays,
  bookingList,
  menuDetail,
  detailInfo,
  getMenuDetail,
  deleteMenuDetail,
  handleDialog,
  allComponent
}: CompanyListProps) => {
  return (
    <StyledCompanyList>
      {bookingList ? (
        bookingList.map((item) => {
          const { company, templates } = item;
          return (
            <div key={`${company.id}`} className="company-container">
              <div className="company-title">
                <p>{company.name}</p>
              </div>
              {templates.map((tItem) => {
                return (
                  <Template
                    key={`templates-${tItem.template.idx}`}
                    templates={tItem}
                    handleDialog={handleDialog}
                    detailInfo={detailInfo}
                    getMenuDetail={getMenuDetail}
                    weekDays={weekDays}
                    deleteMenuDetail={deleteMenuDetail}
                    menuDetail={menuDetail}
                    allComponent={company.id === 'GROUP' ? allComponent : null}
                  />
                );
              })}
              {/* <Template isDetail={true} /> */}
            </div>
          );
        })
      ) : (
        <div key="disabled-1" className="company-container">
          <div className="company-title">
            <p>예약된 메뉴가 없습니다.</p>
          </div>
        </div>
      )}
    </StyledCompanyList>
  );
};

export default CompanyList;
