import { Modal, ModalFuncProps } from 'antd';
import React from 'react';

const modalConfig = {
  icon: null,
  okText: '확인',
  className: 'info-handler-modal'
};

export const modalMethod = {
  info(content, props?) {
    Modal.info({
      ...modalConfig,
      content,
      ...props
    });
  },
  warning(content, props?) {
    Modal.warning({
      ...modalConfig,
      className: 'error-handler-modal',
      content,
      ...props
    });
  },
  error(content, props?) {
    Modal.error({
      ...modalConfig,
      className: 'error-handler-modal',
      content,
      ...props
    });
  },
  confirm(content?: React.ReactNode, props?: ModalFuncProps): Promise<boolean> {
    return new Promise((resolve) => {
      const { onOk, onCancel, ...other } = props;
      const handleOk = () => {
        onOk?.();
        return resolve(true);
      };

      const handleCancel = () => {
        onCancel?.();
        return resolve(false);
      };
      Modal.confirm({
        ...modalConfig,
        className: 'confirm-cancel-handler-modal',
        content,
        onOk: handleOk,
        onCancel: handleCancel,
        ...other
      });
    });
  }
};

export default modalMethod;
