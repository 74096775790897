import { getCurrentMode } from 'utils/storages';

export const getAuthHost = () => {
  const currentEnv = getCurrentMode();
  if (currentEnv === 'test') {
    return process.env.REACT_APP_DEVELOP_AUTH_URL;
  }
  if (currentEnv === 'stage') {
    return process.env.REACT_APP_DEVELOP_AUTH_URL;
  }
  return process.env.REACT_APP_PRODUCTION_AUTH_URL;
};

export const getStoreHost = () => {
  const currentEnv = getCurrentMode();
  if (currentEnv === 'test') {
    return process.env.REACT_APP_DEVELOP_STORE_URL;
  }
  if (currentEnv === 'stage') {
    return process.env.REACT_APP_DEVELOP_STORE_URL;
  }
  return process.env.REACT_APP_PRODUCTION_STORE_URL;
};
