/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { useLocation, useNavigate, useResolvedPath, useMatch, Navigate } from 'react-router-dom';

const RequireStateRoute = ({ children, to }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  // console.log(location?.state);
  // // if (!location?.state) {
  // //   return <Navigate to="/" replace />
  // // }
  // useEffect(() => {
  //   console.log('RequireStateRoute mount', location);
  //   if (location.pathname !== '/' && !location?.state) {
  //     navigate('/');
  //     // window.location.href = '/';
  //   }
  // }, [location, navigate]);
  useEffect(() => {
    console.log('RequireState Mount ======================', to, location);
    return () => {
      console.log('[[[[[RequireState Un Mount]]]]] ======================', to, location);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    console.log('RequireState location Change >>>>>>>>>>>>>>>>', to, location);
    return () => {
      console.log('[[[[[RequireState location Change]]]]] UNMOUNT >>>>>>>>>>>>>>>>', to, location);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  console.log(to, match, resolved, location);
  useEffect(() => {
    if (location?.pathname !== '/' && !location?.state) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // if (!location?.state) {
  //   return <Navigate to="/" replace />;
  // }

  return children;
};

export default RequireStateRoute;
