import React, { useMemo } from 'react';
import { DateRange as ReactDateRange, Range, RangeKeyDict } from 'react-date-range';
import { ko } from 'react-date-range/dist/locale';

interface DateRangeProps {
  onChange;
  startDate;
  endDate;
}

const key = 'selection';
const DateRange = ({ onChange, startDate, endDate }: DateRangeProps) => {
  const handleChange = (ranges: RangeKeyDict): void => {
    const { [key]: range }: Record<string, Range> = ranges;
    onChange(range);
  };

  const ranges = useMemo<Range[] | undefined>(() => {
    return [
      {
        startDate,
        endDate,
        key
      }
    ];
  }, [startDate, endDate]);

  return <ReactDateRange ranges={ranges} onChange={handleChange} locale={ko} />;
};

export default DateRange;
