/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react';
import { Select, Divider, Input, Typography, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

export interface CustomSelectProps {
  value?;
  onChange?;
}

const { Option } = Select;

let index = 0;

const CustomSelect = ({ value, onChange }: CustomSelectProps) => {
  const [items, setItems] = useState(['jack', 'lucy']);
  const [name, setName] = useState('');

  const onNameChange = (event) => {
    setName(event.target.value);
  };

  const addItem = (e) => {
    e.preventDefault();
    const newItem = name || `New item ${(index += 1)}`;
    setItems([...items, newItem]);
    onChange([...value, newItem]);
    setName('');
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleChange = (newValue: []) => {
    onChange(newValue);
  };

  const onKeyDown = (e) => {
    if (e.key === 'Backspace') {
      console.log(e.key);
      e.preventDefault();
    }
  };

  return (
    <Select
      mode="multiple"
      style={{ width: 300 }}
      placeholder="custom dropdown render"
      value={value}
      onChange={onChange}
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider style={{ margin: '8px 0' }} />
          <Space align="center" style={{ padding: '0 8px 4px' }}>
            <Input placeholder="Please enter item" value={name} onChange={onNameChange} onKeyDown={onKeyDown} />
            <Typography.Link onClick={addItem} style={{ whiteSpace: 'nowrap' }}>
              <PlusOutlined /> 직접입력
            </Typography.Link>
          </Space>
        </>
      )}
    >
      {items.map((item) => (
        <Option key={item}>{item}</Option>
      ))}
    </Select>
  );
};

export default CustomSelect;
