import { TDetailInfo, TMenu } from 'interfaces/booking';
import React from 'react';
import styled from 'styled-components';

export const ArtifactMenuStyle = styled.div`
  width: 14%;
  cursor: pointer;
  height: 100%;
  display: inline-block;
  min-width: 30px;
  border-radius: 0;
  vertical-align: middle;
  color: #333;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #fff;
  box-shadow:
    0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  p {
    font-size: 12px;
    margin: 0;
  }

  .pl-6 {
    padding-left: 6px;
  }

  .color-a6 {
    color: #a6a6a6;
  }

  .w-95 {
    width: 95%;
  }

  .text-center {
    text-align: center;
  }

  .vertical-align-middle {
    vertical-align: middle;
  }

  .artifact-menu-name {
    border-left: 1px solid #dcdcdc;
    background-color: #ffffff;
  }

  .btn {
    background: #f7f7f7f7;
  }

  .artifact-menu-icon {
    display: inline-block;
    width: 7px;
    height: 7px;
    background: #46b476;
    border-radius: 50%;
    vertical-align: middle;
  }
`;

type TArtifactMenu = Omit<TMenu, 'price' | 'image'>;

interface ArtifactMenuProps {
  detailInfo: TDetailInfo;
  selMenuDet: (v: TDetailInfo) => void;
  type?: string;
  menus: Array<TArtifactMenu>;
  artifactIdx: number;
  templateIdx: number;
  isBorder?: React.CSSProperties;
}

const ArtifactMenu = ({ type, detailInfo, selMenuDet, menus, artifactIdx, templateIdx, isBorder }: ArtifactMenuProps) => {
  const isLight: React.CSSProperties | undefined = type === 'off-light' ? { background: '#a6a6a6' } : undefined;
  const isActive: React.CSSProperties | undefined =
    detailInfo.artifactIdx === artifactIdx ? { background: '#222222', color: '#dddddd' } : undefined;
  const seenMenus: TArtifactMenu[] = menus && menus.length > 2 ? menus.slice(0, 3) : menus;

  return (
    <ArtifactMenuStyle style={isBorder} onClick={() => selMenuDet({ artifactIdx, templateIdx })}>
      {type === 'EMPTY' ? (
        <div className="artifact-menu-name" style={isActive}>
          <p style={isActive}>
            <span className="pl-6 color-a6">등록된 메뉴 없음</span>
          </p>
        </div>
      ) : (
        <div className="artifact-menu-name" style={isActive}>
          {seenMenus.map((menu, idx) => {
            if (idx === 2) {
              return (
                <p className="w-95 text-center" key={menu.id} style={isActive}>
                  <span>{`외 ${menus.length - 2}`}개</span>
                </p>
              );
            }
            return (
              <p className="w-95" key={menu.id} style={isActive}>
                <span className="artifact-menu-icon" style={isLight} />
                <span className="pl-6 vertical-align-middle">{menu.name}</span>
              </p>
            );
          })}
        </div>
      )}
    </ArtifactMenuStyle>
  );
};

export default ArtifactMenu;
