import React from 'react';
import { Space } from 'antd';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  min-height: calc(100vh - 16em);

  p {
    margin-bottom: 0;
    margin-block-start: 0;
    margin-block-end: 0;
  }
  & .desc {
    font-size: 24px;
    font-weight: 700;
    line-height: 38px;
    color: ${({ theme }) => theme.color.black};
  }
  & .sub-desc {
    margin-top: 8px;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: ${({ theme }) => theme.color.gray02};
  }
  & .content {
    margin-top: 34px;
    flex: 1;
  }
  & .step-bottom {
    width: 100%;
    .ant-space-item {
      flex: 1;
      .ant-btn {
        width: 100%;
        font-size: 20px;
        font-weight: 700;
      }
    }
  }
  @media screen and (max-width: 768px) {
    height: 100%;
  }
`;

export interface StepLayoutProps {
  className?: string;
  desc?: React.ReactNode;
  subDesc?: React.ReactNode;
  content: React.ReactNode;
  bottom?: React.ReactNode;
}

const StepLayout = ({ className, desc, subDesc, content, bottom }: StepLayoutProps) => {
  // const { authenticated } = useAuth();
  return (
    <Container className={className}>
      {desc ? <div className="desc">{desc}</div> : null}
      {subDesc ? <div className="sub-desc">{subDesc}</div> : null}
      <div className="content">{content}</div>
      {bottom ? (
        <div className="step-bottom">
          <Space style={{ display: 'flex' }}>{bottom}</Space>
        </div>
      ) : null}
    </Container>
  );
};

export default StepLayout;
