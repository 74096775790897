/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-destructuring */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import moment from 'moment';
import React, { CSSProperties, useMemo, useState } from 'react';
import { numberWithCommas } from 'utils';
import { generate } from 'shortid';
import arrowDownPng from 'images/arrow/arrow_down_gray.png';
import arrowUpPng from 'images/arrow/arrow_up_b.png';

interface SalesStatsTableProps {
  type: string;
  contents: any[];
  openDetail?: any;
  openMonth?: number;
  getMonthData;
  colorTable: any;
  colorSet: any[];
}

const SalesStatsTable = ({ type, contents, openDetail, openMonth, getMonthData, colorTable, colorSet }: SalesStatsTableProps) => {
  const [state, setState] = useState<any>({
    menuName: true,
    menuCount: true,
    totalPrice: true,
    sortColumn: '',
    isSort: false,
    sortType: null
  });

  const menuSort = (sortColumn, isSort) => {
    let sortType = null;
    if (isSort) {
      sortType = `up_${sortColumn}`;
    } else {
      sortType = `down_${sortColumn}`;
    }
    setState({
      menuName: sortColumn === 'menuName' ? !isSort : true,
      menuCount: sortColumn === 'menuCount' ? !isSort : false,
      totalPrice: sortColumn === 'totalPrice' ? !isSort : false,
      sortColumn,
      isSort,
      sortType
    });
  };

  const sortedContents = useMemo(() => {
    if (!contents?.length) {
      return [];
    }
    const { sortColumn, isSort } = state;
    if (!sortColumn) {
      return contents;
    }
    const sortData = [];
    const temp = [];
    let pushIdx = 0;
    contents.forEach((item, index) => {
      if (item.type !== 'data') sortData.push(item);
      else {
        temp.push(item);
      }
      if (item.type === 'info') pushIdx = index;
    });

    if (isSort) {
      temp.sort((a, b) => {
        if (a[sortColumn] < b[sortColumn]) return -1;
        if (a[sortColumn] > b[sortColumn]) return 1;
        return 0;
      });
    } else {
      temp.sort((a, b) => {
        if (a[sortColumn] > b[sortColumn]) return -1;
        if (a[sortColumn] < b[sortColumn]) return 1;
        return 0;
      });
    }

    temp.forEach((item) => {
      pushIdx += 1;
      sortData.splice(pushIdx, 0, item);
    });
    return sortData;
  }, [contents, state]);

  const totalSum = useMemo(() => {
    let sum = 0;
    sortedContents.forEach((obj) => {
      if (obj.type === 'month') {
        sum += obj.price;
      }
    });
    return sum;
  }, [sortedContents]);

  return (
    <table className="salesStats-table">
      <thead>
        <tr />
      </thead>
      <tbody>
        {sortedContents && sortedContents.length ? (
          <tr className="total-sum-line">
            <td>
              <div className="won">총 합계 금액 : {numberWithCommas(totalSum)} 원</div>
            </td>
          </tr>
        ) : null}
        {sortedContents && sortedContents.length
          ? sortedContents.map((obj, key) => {
              if (obj.type === 'year') {
                return (
                  <tr className="year-line" key={generate()}>
                    <td>{obj.year}년</td>
                  </tr>
                );
              }
              if (obj.type === 'info') {
                let cateInfo = '수량';
                let strType = '날짜';
                const typeStyle: { nameStyle: CSSProperties; countStyle: CSSProperties; priceStyle: CSSProperties } = {
                  nameStyle: { cursor: 'pointer' },
                  countStyle: { cursor: 'pointer' },
                  priceStyle: { cursor: 'pointer' }
                };
                if (type === 'DATE') {
                  cateInfo = '건수';
                }
                if (type === 'MENU') {
                  strType = '메뉴명';
                  const { sortType } = state;
                  if (sortType !== null) {
                    if (sortType === 'up_menuName' || sortType === 'down_menuName') {
                      typeStyle.nameStyle =
                        sortType === 'up_menuName'
                          ? { cursor: 'pointer', borderTop: '2px #3BA863 solid' }
                          : { cursor: 'pointer', borderBottom: '2px #3BA863 solid' };
                    } else if (sortType === 'up_menuCount' || sortType === 'down_menuCount') {
                      typeStyle.countStyle =
                        sortType === 'up_menuCount'
                          ? { cursor: 'pointer', borderBottom: '2px #3BA863 solid' }
                          : { cursor: 'pointer', borderTop: '2px #3BA863 solid' };
                    } else if (sortType === 'up_totalPrice' || sortType === 'down_totalPrice') {
                      typeStyle.priceStyle =
                        sortType === 'up_totalPrice'
                          ? { cursor: 'pointer', borderBottom: '2px #3BA863 solid' }
                          : { cursor: 'pointer', borderTop: '2px #3BA863 solid' };
                    }
                  }
                }
                if (type === 'COMPANY') {
                  strType = '회사별';
                  cateInfo = '';
                }
                return (
                  <tr className="salesstats-info" key={generate()}>
                    <td>
                      <div className="type" style={typeStyle.nameStyle} onClick={() => menuSort('menuName', state.menuName)}>
                        {strType}
                      </div>
                      <div className="right">
                        <div className="left" style={typeStyle.countStyle} onClick={() => menuSort('menuCount', state.menuCount)}>
                          {cateInfo}
                        </div>
                        <div
                          className="info"
                          style={typeStyle.priceStyle}
                          onClick={() => menuSort('totalPrice', state.totalPrice)}
                        >
                          금액
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              }
              if (obj.type === 'month') {
                let imgName = arrowDownPng;
                if (openDetail && openMonth === key) {
                  imgName = arrowUpPng;
                }

                return (
                  <tr className="salesstats-month" key={generate()}>
                    <td
                      className="month"
                      onClick={() => {
                        getMonthData(obj.year, obj.month, obj.key);
                      }}
                    >
                      <div className="left">{obj.month}월</div>
                      <div className="right">
                        {numberWithCommas(obj.price)} <span className="won">원</span>
                        <img src={imgName} className="arrow" alt="" />
                      </div>
                    </td>
                  </tr>
                );
              }
              if (obj.type === 'data') {
                let leftStr = '';
                let count: number | string = 0;
                let leftBorderColor;

                if (type === 'DATE') {
                  leftStr = moment(obj.useDate * 1000).format('M. DD.');
                  count = obj.payCount;
                } else if (type === 'MENU') {
                  leftStr = obj.menuName;
                  count = obj.menuCount;
                  let color = colorTable[leftStr];
                  if (!color) color = colorSet[5];

                  if (window.innerWidth <= 767) {
                    const widthSize = window.innerWidth - 190;
                    leftBorderColor = { borderLeft: `5px solid ${color}`, width: widthSize };
                  } else {
                    leftBorderColor = { borderLeft: `5px solid ${color}` };
                  }
                } else if (type === 'COMPANY') {
                  leftStr = obj.comName;

                  let color = colorTable[leftStr];
                  if (!color) color = colorSet[5];

                  leftBorderColor = { borderLeft: `5px solid ${color}` };

                  if (window.innerWidth <= 767) {
                    const widthSize = window.innerWidth - 180;
                    leftBorderColor.width = widthSize;
                  }

                  // TODO : 확인 //
                  if (obj.ticketFormat) {
                    count = `${obj.menuCount}장`;
                  } else {
                    count = '';
                    return (
                      <tr className="salesstats-daily" key={generate()}>
                        <td>
                          <div className="type" style={leftBorderColor}>
                            {leftStr}
                          </div>
                          <div className="onlyright">
                            {numberWithCommas(obj.totalPrice)} <span className="won">원</span>
                          </div>
                        </td>
                      </tr>
                    );
                  }
                }

                return (
                  <tr className="salesstats-daily" key={generate()}>
                    <td>
                      <div className="type" style={leftBorderColor}>
                        {leftStr}
                      </div>
                      <div className="right">
                        <div className="left">{count}</div>
                        <div className="info">
                          {numberWithCommas(obj.totalPrice)} <span className="won">원</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              }
              return null;
            })
          : null}
      </tbody>
    </table>
  );
};

export default SalesStatsTable;
