import { Modal } from 'antd';
import { TCategoryMenu } from 'interfaces/booking';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import MenuForm from './MenuForm';
import MenuTable from './MenuTable';

const StyledMenuDialog = styled(Modal)`
  min-width: 688px;

  .ant-modal-header {
    background: #eee;

    .ant-modal-title {
      font-size: 20px;
    }
  }

  .ant-modal-body {
    display: flex;
  }

  .title {
    border-radius: 10px;
    border-bottom: 1px solid #d4d4d4;
    background-color: #f7f7f7;
    padding: 15px 24px 20px;
    height: 60px;
    h6 {
      font-size: 22px;
    }
  }

  .actions {
    padding: 0 24px 12px;
    margin: 8px 0;
  }

  .menu-list {
    width: 640px;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    overflow: hidden;

    .w-full {
      width: 100%;
    }
  }
`;

interface MenuDialogProps {
  open: boolean;
  menuAdd: () => Promise<boolean>;
  handleDialog: () => void;
  handleForm: (v?: string) => void;
  handleMenuAdd: (mIdx: string[]) => void;
  menuList?: { contents: TCategoryMenu[] };
}

const MenuDialog = ({ open, menuAdd, handleDialog, handleForm, handleMenuAdd, menuList }: MenuDialogProps) => {
  const [value, setValue] = useState<string>('');
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  const handleClose = () => {
    setValue('');
    setSelectedKeys([]);
    handleDialog();
  };

  const handleClickOk = async () => {
    const success = await menuAdd();
    if (success) setSelectedKeys([]);
  };

  const handleChange = (selectedRowKeys: string[]) => {
    setSelectedKeys(selectedRowKeys);
    handleMenuAdd(selectedRowKeys);
  };

  useEffect(() => {
    setSelectedKeys([]);
  }, [menuList]);

  return (
    <StyledMenuDialog
      closable={false}
      open={open}
      onCancel={handleClose}
      onOk={handleClickOk}
      centered
      title="메뉴 검색"
      cancelText="취소"
      okText="등록"
      maskClosable={false}
    >
      <div className="menu-list">
        <div className="w-full">
          {handleForm && <MenuForm handleForm={() => handleForm(value)} value={value} setValue={setValue} />}
        </div>
        <div className="w-full">
          <MenuTable handleChange={handleChange} selectedKeys={selectedKeys} menuList={menuList} />
        </div>
      </div>
    </StyledMenuDialog>
  );
};

export default MenuDialog;
