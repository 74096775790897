import React, { useMemo } from 'react';
import { useBookingHistoriesMenus } from 'hooks';
import { generate } from 'shortid';
import { Table } from '../styles';

const MenuBy = ({ bookingDate }) => {
  const { data } = useBookingHistoriesMenus(bookingDate);

  const bookingMenu = useMemo(() => {
    if (data?.bookingMenuInfos) {
      return data?.bookingMenuInfos;
    }
    return [];
  }, [data]);

  return (
    <div>
      <Table>
        <thead>
          <tr>
            <th style={{ textAlign: 'center', width: '33%' }}>카테고리 </th>
            <th style={{ textAlign: 'center', width: '34%' }}>메뉴명</th>
            <th style={{ textAlign: 'center', width: '33%' }}>예약 완료 수량</th>
          </tr>
        </thead>
        <tbody>
          {bookingMenu.map((obj) => (
            <>
              <tr>
                <td colSpan={3} className="bookingStoreName">
                  {obj.bookingStoreName}
                </td>
              </tr>
              {obj.menuInfos.map((item) => (
                <tr key={generate()}>
                  <td style={{ textAlign: 'center' }}>{item.categoryName}</td>
                  <td style={{ textAlign: 'center' }}>{item.menuName}</td>
                  <td style={{ textAlign: 'center' }}>{item.bookedCount}</td>
                </tr>
              ))}
            </>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default MenuBy;
