import { getMenus, updateMenuStatus } from 'apis';
import { useMutation, useQuery } from 'react-query';

type UpdateParam = { sid: string; data: unknown };

export const useMenus = (sid: string | undefined) => {
  return useQuery(['getMenus'], () => getMenus(sid), {
    enabled: !!sid
  });
};

export const useUpdateMenu = () => {
  return useMutation(['updateMenuStatus'], (params: UpdateParam) => {
    const { sid, data } = params;
    return updateMenuStatus(sid, data);
  });
};
