import { Grid } from 'antd';
import { useEffect, useState } from 'react';

const responsivePos = {
  pos: '(max-width: 1400px)'
};

const { useBreakpoint: useVBreakpoint } = Grid;

const useBreakpoint = () => {
  const [posMediaQuery, setPosMediaQuery] = useState<Partial<MediaQueryListEvent>>({
    matches: window.innerWidth < 1400,
    media: ''
  });

  const handler = (e: MediaQueryListEvent) => {
    setPosMediaQuery(e);
  };
  useEffect(() => {
    const mql = window.matchMedia(responsivePos.pos);
    mql.addEventListener('change', handler);

    return () => {
      mql.removeEventListener('change', handler);
    };
  }, []);
  const screens = useVBreakpoint();

  const breakpoint = {
    ...screens,
    pos: posMediaQuery.matches
  };
  return breakpoint;
};

export default useBreakpoint;
