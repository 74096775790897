import React from 'react';
import { AgreeCheckbox } from 'components';
import { TermContainer } from 'containers';

const AgreeContainer = () => {
  return (
    <div>
      <AgreeCheckbox required checkbox={false} modalContent={<TermContainer type="STORE_SIGNUP_SERVICE" />}>
        서비스 이용 계약
      </AgreeCheckbox>
      <AgreeCheckbox required checkbox={false} modalContent={<TermContainer type="PRIVACY" />}>
        개인정보 수집 및 이용 동의
      </AgreeCheckbox>
    </div>
  );
};

export default AgreeContainer;
