import { getName } from 'configs/route';
import { useHistoryPop } from 'hooks';
import React from 'react';
import { Location } from 'react-router-dom';
import styled from 'styled-components';

import hederLogo from 'images/N_Logo_Header.svg';

import ExcelDownloadButton from './ExcelDownloadButton';
import VatDeclarationExcelDownloadButton from './VatDeclarationExcelDownloadButton';
import StoreAuditLogButton from './StoreAuditlogButton';

const ExcelButtonArea = styled.div`
  display: inline-flex;
  float: right;
`;

export interface HeaderProps {
  location: Location;
}

const Header = ({ location }: HeaderProps) => {
  useHistoryPop(() => {
    console.log('history change -----------------------------------------------');
  });

  let mode = '';
  let text: React.ReactNode;

  if (location.pathname === '/') {
    mode = 'main';
    text = <img style={{ fontSize: 22, height: 22, marginTop: -4 }} src={hederLogo} alt="" />;
  } else {
    text = getName(location.pathname) || '';
  }

  return (
    <nav className="navbar subnav-default">
      <div className="subbar-header">
        <div className="navbar-collapse toptitle">
          <span id="title">
            <span style={{ paddingRight: mode === 'main' ? 15 : null, verticalAlign: 'middle' }}>{text}</span>
          </span>
          <ExcelButtonArea>
            {location.pathname === '/salesStats' ? <VatDeclarationExcelDownloadButton /> : null}
            <ExcelDownloadButton location={location} />
            <StoreAuditLogButton location={location} />
          </ExcelButtonArea>
        </div>
      </div>
    </nav>
  );
};

export default Header;
