import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { TransitionHeaderContainer } from 'containers';

const Container = styled.div`
  background-color: #ffffff;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const Content = styled.div`
  font-size: 18px;
  height: calc(100vh - 44px);
  /* overflow-y: auto; */
  text-align: left;
  padding: 28px 16px 43px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media only screen and (min-width: 768px) {
    max-width: 375px;
    width: 375px;
    //max-height: 724px;
    padding: 0;
  }

  & {
    /* p {
      padding: 0 16px;
      font-size: 24px;
      font-weight: 700;
      color: #212529;
      line-height: 38px;
      margin: 0;
      text-align: left;
    } */
    button {
      width: 100%;
      height: 55px;
    }
  }
`;

const TransitionLayout = () => (
  <Container>
    <TransitionHeaderContainer />
    <Content>
      <Outlet />
    </Content>
  </Container>
);

export default TransitionLayout;
