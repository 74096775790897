import {
  ContentAdmission,
  Admission,
  StepsAdmission,
  IAdmissionParams,
  IAccountCheckParams,
  IAccountCheckResult
} from 'interfaces/admission';
import { paramsToFormData } from 'utils/apiUtils';
import { storeClient } from './apiClients';

export const saveAdmission = async (params: IAdmissionParams): Promise<ContentAdmission> => {
  const { admission, sid } = params;
  const admissionFormData = paramsToFormData(admission);
  const { data } = await storeClient.post(`/store/v1/${sid}/admission`, admissionFormData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
  return data;
};

export const getAdmissions = async (sid: string | undefined): Promise<StepsAdmission | undefined> => {
  if (!sid) {
    return undefined;
  }
  const { data } = await storeClient.get(`/store/v1/${sid}/admission`);
  return data;
};

export const getAdmission = async (sid: string | undefined, admissionIdx: number): Promise<Admission | undefined> => {
  if (!sid) {
    return undefined;
  }
  const { data } = await storeClient.get(`/store/v1/${sid}/admission/${admissionIdx}`);
  return data;
};

export const getBizSerial = async (bizSerial: string) => {
  const { data } = await storeClient.get('/store/v1/check/biz-serial', { params: { bizSerial } });
  return data;
};

export const getAdmissionTypes = async () => {
  const { data } = await storeClient.get('/store/v1/types');
  return data;
};

export const getBankCodes = async () => {
  const { data } = await storeClient.get('/settlement/v1/code/bank');
  return data;
};

export const checkAccountNumber = async (checkData: IAccountCheckParams): Promise<IAccountCheckResult> => {
  const { data } = await storeClient.post('/settlement/v1/store/account-check', checkData);
  return data;
};
