import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getEmployee, addEmployee, updateEmployee, getWorkers, updateWorkers, errorHandler } from 'apis';
import { Grade } from 'interfaces/auth';
import { Employee, Worker, EmployeeStatus } from 'interfaces/store';

interface IEmployee {
  storeId: string;
  employee: {
    name: string;
    phone: string;
    grade: Grade;
  };
}

interface IUEmployee {
  storeId: string;
  employeeApplyIdx: number;
  status: EmployeeStatus;
}

interface IEmployeeList {
  workers: Array<Employee>;
}

interface IEmployeeApplyList {
  employeeApplyList: Array<Worker>;
}

export const useEmployee = (sid: string) =>
  useQuery<IEmployeeList>('getEmployee', () => getEmployee(sid), {
    enabled: !!sid,
    onError: errorHandler
  });

export const useAddEmployee = () =>
  useMutation(['addEmployee'], (params: IEmployee) => {
    const { storeId, employee } = params;
    return addEmployee(storeId, employee);
  });
export const useUpdateEmployee = () =>
  useMutation(['updateEmployee'], (params: IUEmployee) => {
    const { storeId, employeeApplyIdx, status } = params;
    return updateEmployee(storeId, { employeeApplyIdx, status });
  });

export const useWorkers = () => useQuery<IEmployeeApplyList>(['getEmployee'], () => getWorkers());

export const useUpdateWorker = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ['updateWorkers'],
    (params: { employeeApplyList: Pick<IUEmployee, 'employeeApplyIdx' | 'status'> }) => updateWorkers(params),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['getMe']);
      }
    }
  );
};
