import styled from 'styled-components';

export const ProgressContainer = styled.div`
  line-height: 0;
`;

export const AdmissionWrapper = styled.div`
  @media screen and (max-width: 768px) {
    height: 100%;
  }
  .admission-content {
    @media screen and (max-width: 768px) {
      height: calc(100% - 6px);
      .admission-layout {
        height: 100%;
      }
    }
  }
`;

export const ContractTable = styled.table`
  width: 100%;
  td,
  th {
    border: 1px solid #bfbfbf;
    padding: 8px;
    font-size: 12px;
    line-height: 14px;
  }
  th {
    width: 20%;
    font-weight: 700;
    background-color: #f2f2f2;
  }
`;
