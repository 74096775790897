import React from 'react';
import { FeeForm } from 'components';
import { useMeInfo, useSettlementFeeInfo } from 'hooks';

const FeeInfo = () => {
  const { selectedStore } = useMeInfo();
  const { data: feeInfo } = useSettlementFeeInfo(selectedStore?.sid || '');
  return <FeeForm companyList={feeInfo?.companyList} contact={feeInfo?.contact} />;
};

export default FeeInfo;
