import React from 'react';
import styled from 'styled-components';
import noData from 'images/Frame-270.png';

const Nodata = styled.div`
  background-color: white;
  min-height: 1000px;
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 201px;
  }
`;

const NodataPage = () => {
  return (
    <Nodata>
      <img src={noData} alt="no data" />
    </Nodata>
  );
};

export default NodataPage;
