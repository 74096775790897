/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { Tag } from 'antd';
import { getMyInfo } from 'utils';
import { useMeVerTwo, useStoreImagesInfo } from 'hooks';
import iconThumbnail from 'images/storeImage/icon_thumbnail.svg';
import iconSignatureImg from 'images/storeImage/icon_signatureImg.svg';
import iconMenu from 'images/storeImage/icon_menu.svg';
import rightArrow from 'images/arrow/arrow-right-16.svg';
import questionMark from 'images/storeImage/question_mark_icon.svg';
import SignatureImg from 'pages/StoreImage/SignatureImg';
import ViewGuideRollingPopup from 'pages/StoreImage/ViewGuideRollingPopup';
import RegisterGuideRollingPopup from 'pages/StoreImage/RegisterGuideRollingPopup';
import { Container, SubContainer } from './style';

const EnrollBox = ({ enrollFunc, titleIcon, title, enrollStatus }) => {
  return (
    <SubContainer>
      <div className="direction" role="presentation" onClick={enrollFunc}>
        <div className="space-between">
          <img src={titleIcon} alt="icon-thumbnail" />
          <span className="list">{title}</span>
          <Tag color={enrollStatus ? '#46b476' : '#afb2b9'} className="tag">
            {enrollStatus ? '등록완료' : '미등록'}
          </Tag>
        </div>
        <img src={rightArrow} alt="right-arrow" />
      </div>
    </SubContainer>
  );
};

interface TStateStoreImage {
  thumbnailOpen: boolean;
  signatureOpen: boolean;
  menuOpen: boolean;
  noticeOpen: boolean;
  imgType: 'THUMBNAIL' | 'SIGNATURE' | 'MENU' | 'NOTICE';
  viewGuideOpen: boolean;
  registerGuideOpen: boolean;
}

const StoreImage = () => {
  const initStore = getMyInfo();
  const [sid, setSid] = useState<string>(initStore?.sid || '');
  const { data } = useMeVerTwo(sid);
  const { thumbnailImage, signatureImageList, menuBoardImageList, noticeImageList } = data || {};
  const { data: imageInfo } = useStoreImagesInfo(sid);
  const {
    isRegisterThumbnailImage,
    canRegisterThumbnailImage,
    isRegisterSignatureImage,
    canRegisterSignatureImage,
    isRegisterMenuBoardImage,
    canRegisterMenuBoardImage,
    isRegisterNoticeImage,
    canRegisterNoticeImage
  } = imageInfo || {};

  const [state, setState] = useState<TStateStoreImage>({
    thumbnailOpen: false,
    signatureOpen: false,
    menuOpen: false,
    noticeOpen: false,
    imgType: 'THUMBNAIL',
    viewGuideOpen: false,
    registerGuideOpen: false
  });
  const { thumbnailOpen, signatureOpen, menuOpen, noticeOpen, imgType, viewGuideOpen, registerGuideOpen } = state;

  const handleThumbnail = () => {
    setState((v) => ({ ...v, imgType: 'THUMBNAIL', thumbnailOpen: !thumbnailOpen }));
  };

  const handleSignatureImg = () => {
    setState((v) => ({ ...v, imgType: 'SIGNATURE', signatureOpen: !signatureOpen }));
  };

  const handleMenu = () => {
    setState((v) => ({ ...v, imgType: 'MENU', menuOpen: !menuOpen }));
  };

  const handleNotice = () => {
    setState((v) => ({ ...v, imgType: 'NOTICE', noticeOpen: !noticeOpen }));
  };

  const handleCancel = () => {
    setState((v) => ({
      ...v,
      thumbnailOpen: false,
      signatureOpen: false,
      menuOpen: false,
      noticeOpen: false,
      imgType: 'THUMBNAIL'
    }));
  };

  const handleViewGuide = () => {
    setState((v) => ({
      ...v,
      viewGuideOpen: !viewGuideOpen
    }));
  };

  const handleRegisterGuide = () => {
    setState((v) => ({
      ...v,
      registerGuideOpen: !registerGuideOpen
    }));
  };

  const divisionType = {
    THUMBNAIL: {
      title: '썸네일',
      titleIcon: iconThumbnail,
      enrollFunc: handleThumbnail,
      enrollStatus: isRegisterThumbnailImage,
      open: thumbnailOpen,
      ok: handleThumbnail,
      cancel: handleCancel,
      imgFile: thumbnailImage ? [{ image: thumbnailImage }] : null
    },
    SIGNATURE: {
      title: '대표 이미지',
      titleIcon: iconSignatureImg,
      enrollFunc: handleSignatureImg,
      enrollStatus: isRegisterSignatureImage,
      open: signatureOpen,
      ok: handleSignatureImg,
      cancel: handleCancel,
      imgFile: signatureImageList
    },
    MENU: {
      title: '메뉴판',
      titleIcon: iconMenu,
      enrollFunc: handleMenu,
      enrollStatus: isRegisterMenuBoardImage,
      open: menuOpen,
      ok: handleMenu,
      cancel: handleCancel,
      imgFile: menuBoardImageList
    },
    NOTICE: {
      title: '공지사항',
      titleIcon: iconMenu,
      enrollFunc: handleNotice,
      enrollStatus: isRegisterNoticeImage,
      open: noticeOpen,
      ok: handleNotice,
      cancel: handleCancel,
      imgFile: noticeImageList
    }
  };

  return (
    <Container>
      <div className="header">
        <div className="title">무엇을 등록할까요?</div>
        <div className="flex-space-between">
          <div className="description">제휴점의 모든 이미지를 여기서 관리하세요!</div>
          <button type="button" className="register-guide-btn" onClick={handleRegisterGuide}>
            <img src={questionMark} alt="question-mark-icon" />
            <div>등록방법</div>
          </button>
        </div>
      </div>
      {canRegisterThumbnailImage ? (
        <EnrollBox
          key={divisionType.THUMBNAIL.title}
          enrollFunc={divisionType.THUMBNAIL.enrollFunc}
          titleIcon={divisionType.THUMBNAIL.titleIcon}
          title={divisionType.THUMBNAIL.title}
          enrollStatus={divisionType.THUMBNAIL.enrollStatus}
        />
      ) : null}
      {canRegisterSignatureImage ? (
        <EnrollBox
          key={divisionType.SIGNATURE.title}
          enrollFunc={divisionType.SIGNATURE.enrollFunc}
          titleIcon={divisionType.SIGNATURE.titleIcon}
          title={divisionType.SIGNATURE.title}
          enrollStatus={divisionType.SIGNATURE.enrollStatus}
        />
      ) : null}
      {canRegisterMenuBoardImage ? (
        <EnrollBox
          key={divisionType.MENU.title}
          enrollFunc={divisionType.MENU.enrollFunc}
          titleIcon={divisionType.MENU.titleIcon}
          title={divisionType.MENU.title}
          enrollStatus={divisionType.MENU.enrollStatus}
        />
      ) : null}
      {canRegisterNoticeImage ? (
        <EnrollBox
          key={divisionType.NOTICE.title}
          enrollFunc={divisionType.NOTICE.enrollFunc}
          titleIcon={divisionType.NOTICE.titleIcon}
          title={divisionType.NOTICE.title}
          enrollStatus={divisionType.NOTICE.enrollStatus}
        />
      ) : null}
      <div className="help-description">
        <button type="button" onClick={handleViewGuide}>
          등록한 이미지는 식권대장에서 어떻게 보이나요?
        </button>
      </div>

      <RegisterGuideRollingPopup open={registerGuideOpen} onOk={handleRegisterGuide} onCancel={handleRegisterGuide} />
      <SignatureImg
        open={divisionType[imgType].open}
        onOk={divisionType[imgType].ok}
        onCancel={divisionType[imgType].cancel}
        imgType={imgType || 'THUMBNAIL'}
        imgFile={divisionType[imgType].imgFile}
      />
      <ViewGuideRollingPopup
        open={viewGuideOpen}
        onOk={handleViewGuide}
        onCancel={handleViewGuide}
        canRegisterThumbnailImage={canRegisterThumbnailImage}
        canRegisterSignatureImage={canRegisterSignatureImage}
        canRegisterMenuBoardImage={canRegisterMenuBoardImage}
      />
    </Container>
  );
};

export default StoreImage;
