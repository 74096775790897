import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
import {
  errorHandler,
  exportExcel,
  getAdmissionHistory,
  getExcelStorage,
  getLogData,
  getMeVerTwo,
  getStore,
  getStoreImagesInfo,
  getStores,
  getTodayReport,
  transformAccount,
  saveStoreDisplayInfo,
  saveThumbnail,
  saveSignatureMenuImg,
  deleteSignatureMenuImg,
  saveSignatureMenuImgOrder
} from 'apis';
import { ISaveAppDisplayInfo, StoreImagesType, StoreType, TInfiniteReturn } from 'interfaces/store';
import { sendBridge } from 'utils/clientUtils';
import { debounce } from 'utils/eventUtils';
import { getToken, setMyInfo } from 'utils/storages';
import { modalMethod } from 'components';
import { useMeInfo } from './useMeInfo';

const onSuccessStore = (data: StoreType) => {
  const { id, name } = data;
  const accessToken = getToken();
  const storeInfo = { id, name, accessToken };
  sendBridge('sendStoreInfo', storeInfo);
  setMyInfo(data);
};

export const useStore = (sid: string, enabled: boolean) => {
  // const [key, setKey] = useState(sid);
  // useEffect(() => {
  //   console.log('change sid', sid);
  //   setKey(sid);
  // }, [sid]);
  const key = sid;
  return useQuery<StoreType>(['getStore', key], () => getStore(key), {
    enabled: !!key && enabled,
    onSuccess: onSuccessStore
  });
};

export const useStores = (config: Record<string, unknown>) => {
  return useQuery(['getStores'], () => getStores(), {
    ...config
  });
};

export const useTransformAccount = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ['transformAccount'],
    (data: { transactionId: string; id: string; isAdmin: boolean; token: string }) => transformAccount(data),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['getMe']);
      },
      onError: async () => {
        await queryClient.invalidateQueries(['getMe']);
      }
    }
  );
};

export const useSaveAppDisplayInfo = () => {
  const queryClient = useQueryClient();
  return useMutation(['saveAppDisplayInfo'], (params: ISaveAppDisplayInfo) => saveStoreDisplayInfo(params), {
    onSuccess: async () => {
      modalMethod.info('수정되었습니다.');
      await queryClient.invalidateQueries('getSettlementMetaInfo');
    }
  });
};

export const useSaveThumbnail = () => {
  const queryClient = useQueryClient();
  return useMutation(['saveThumbnail'], (params: FormData) => saveThumbnail(params), {
    onSuccess: async () => {
      await queryClient.invalidateQueries('getStoreVerTwo');
      await queryClient.invalidateQueries('getStoreImagesInfo');
    }
  });
};

export const useSaveSignatureMenuImg = () => {
  const queryClient = useQueryClient();
  return useMutation(['saveSignatureMenuImg'], (params: FormData) => saveSignatureMenuImg(params), {
    onSuccess: async () => {
      await queryClient.invalidateQueries('getStoreVerTwo');
      await queryClient.invalidateQueries('getStoreImagesInfo');
    }
  });
};
export const useSaveSignatureMenuImgOrder = () => {
  const queryClient = useQueryClient();
  return useMutation(['saveSignatureMenuImgOrder'], (params: FormData) => saveSignatureMenuImgOrder(params), {
    onSuccess: async () => {
      await queryClient.invalidateQueries('getStoreVerTwo');
      await queryClient.invalidateQueries('getStoreImagesInfo');
    }
  });
};

export const useDeleteSignatureMenuImg = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ['deleteSignatureMenuImg'],
    (idxArray: { storeImageIdxList: number[] }) => deleteSignatureMenuImg(idxArray),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('getStoreVerTwo');
        await queryClient.invalidateQueries('getStoreImagesInfo');
      }
    }
  );
};

export const useTodayReport = (params: unknown, config: Record<string, unknown>) => {
  return useQuery(['getTodayReport', params], () => getTodayReport(params), {
    ...config
  });
};

export const useExportExcel = () => useMutation(['exportExcel'], (params: unknown) => exportExcel(params));

export const useInfiniteExcelStorage = () => {
  const pageRow = 15;
  const queryClient = useQueryClient();
  const { selectedStore, meInfo } = useMeInfo();

  const invalidateQueries = () => {
    return debounce(queryClient.invalidateQueries('getExcelStorage'), 2000);
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const debounceGetQuery: (...args: any[]) => Promise<TInfiniteReturn<any>> = debounce(async (info) => {
    const params = {
      sid: selectedStore?.sid || meInfo?.admin?.said,
      page: info.pageParam ?? 1,
      pageRow
    };
    const result = await getExcelStorage(params);
    const { excelList, totalCount } = result;
    const nextId = params.page * pageRow < totalCount ? params.page + 1 : null;
    return { data: excelList, nextId, previousId: null };
  }, 100);
  const infiniteQuery = useInfiniteQuery(
    ['getExcelStorage'],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (info): Promise<TInfiniteReturn<any>> => {
      return debounceGetQuery(info);
    },
    {
      getPreviousPageParam: (firstPage) => firstPage.previousId ?? undefined,
      getNextPageParam: (lastPage) => lastPage.nextId ?? undefined,
      enabled: !!selectedStore?.sid || !!meInfo?.admin?.said
    }
  );
  return { ...infiniteQuery, invalidateQueries };
};

export const useAdmissionHistory = (said: string) => {
  return useQuery(['getAdmissionHistory', said], () => getAdmissionHistory(said), {
    onError: errorHandler
  });
};

export const useLogData = (pageNum) => {
  const queryClient = useQueryClient();
  const invalidateQueries = () => {
    return debounce(queryClient.invalidateQueries('useLogData'), pageNum);
  };

  const query = useQuery(['useLogData', pageNum], () => getLogData(pageNum));

  return {
    ...query,
    invalidateQueries
  };
};

export const useMeVerTwo = (sid: string) => {
  const key = sid;
  return useQuery<StoreType>(['getStoreVerTwo', key], () => getMeVerTwo(key), {
    enabled: !!key,
    onSuccess: onSuccessStore
  });
};

export const useStoreImagesInfo = (sid: string) => {
  const key = sid;
  return useQuery<StoreImagesType>(['getStoreImagesInfo', key], () => getStoreImagesInfo(key), {
    enabled: !!key
  });
};
