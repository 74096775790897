import { Button, Input, Modal } from 'antd';
import { StepLayout } from 'layouts';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const redirect = {
  success: '/alreadyExists',
  fault: '/signUp'
};

const ForgotPasswordContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [signId, setSignId] = useState('');

  const onChangeHandler = (e: any) => {
    const {
      target: { value }
    } = e;
    setSignId(value);
  };

  const next = () => {
    if (!signId) {
      Modal.info({
        content: <>아이디를 입력해주세요</>,
        icon: null,
        okText: '확인',
        className: 'info-handler-modal'
      });
      return;
    }
    const state = {
      from: location.pathname,
      passData: { signId },
      signId
    };
    navigate('/certification', { state, replace: true });
  };
  return (
    <StepLayout
      desc={<p>가입하신 아이디를 입력해주세요.</p>}
      content={<Input placeholder="아이디를 입력해주세요" onChange={onChangeHandler} />}
      bottom={
        <Button type="primary" onClick={next}>
          본인인증
        </Button>
      }
    />
  );
};

export default ForgotPasswordContainer;
