import React from 'react';
import { Button } from 'antd';
import { StyledTermModal } from './styles';

interface TermsModalProps {
  title?: React.ReactNode;
  width?: string | number;
  open?: boolean;
  close?: () => void;
  children?: React.ReactNode;
}

const TermsModal = ({ title, width, open, close, children }: TermsModalProps) => {
  return (
    <StyledTermModal
      width={width}
      title={title}
      open={open}
      onCancel={close}
      footer={
        <Button type="primary" onClick={close}>
          확인
        </Button>
      }
    >
      {children}
    </StyledTermModal>
  );
};

export default TermsModal;
