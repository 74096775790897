/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { StepLayout } from 'layouts';
import { CheckboxChangeEventTarget } from 'antd/es/checkbox/Checkbox';
import { modalMethod, AgreeCheckbox, Checkbox as StyledCheckbox } from 'components';
import styled from 'styled-components';
import { TermContainer } from 'containers';
import { useCertification } from 'hooks';

const AgreeCheckboxGroupWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.color.gray04};
  background-color: ${({ theme }) => theme.color.gray06};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  width: 100%;
`;

const OptionalAgreeCheckboxGroupWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 0 0 24px 36px;
  background-color: ${({ theme }) => theme.color.gray06};
  & .ant-checkbox-wrapper {
    width: auto;
    padding: 0;
    color: ${({ theme }) => theme.color.gray02};
  }
`;

export interface IAgreements {
  push: boolean;
  sms: boolean;
  email: boolean;
}

export interface IRequireAgreements {
  service: boolean;
  privacy: boolean;
}

const redirect = {
  success: '/signUp',
  fault: '/alreadyExists'
};

const AgreeSignUpContainer = () => {
  const navigate = useNavigate();
  const [requireAgreements, setRequireAgreements] = useState<IRequireAgreements>({ service: false, privacy: false });
  const [agreements, setAgreements] = useState<IAgreements>({ push: false, sms: false, email: false });

  const onSuccess = (tId) => {
    if (tId) {
      navigate('/signUp', { state: { transactionId: tId, passData: agreements }, replace: true });
    }
  };
  const onError = (error) => {
    if (error?.responseCode === 'DUPLICATE_ACCOUNT') {
      const { duplicationId, regDate } = error;
      navigate('/alreadyExists', {
        state: { data: { account: { signId: duplicationId, joinDate: regDate } } },
        replace: true
      });
    }
  };
  const [transactionId, open] = useCertification(onSuccess, onError);

  const handleAllChange = ({ target }: { target: CheckboxChangeEventTarget }) => {
    const { checked } = target;
    setRequireAgreements({ service: checked, privacy: checked });
    setAgreements({ push: checked, sms: checked, email: checked });
  };

  const handleRequireChange = ({ target }: { target: CheckboxChangeEventTarget }) => {
    const { name, checked } = target;
    setRequireAgreements({ ...requireAgreements, [name as string]: checked });
  };

  const handleOptionAllChange = ({ target }: { target: CheckboxChangeEventTarget }) => {
    const { checked } = target;
    setAgreements({ push: checked, sms: checked, email: checked });
  };

  const handleChange = ({ target }: { target: CheckboxChangeEventTarget }) => {
    const { name, checked } = target;
    setAgreements({ ...agreements, [name as string]: checked });
  };

  const gotoSignUp = () => {
    if (!Object.values(requireAgreements).every((v) => v)) {
      modalMethod.warning('필수 항목에 동의해주세요');
      return;
    }
    // open();
    navigate('/certification', { state: { passData: agreements, redirect, from: '/agreeSignUp' }, replace: true });
  };

  const isAllOptionalChecked = Object.values(agreements).every((v) => v);
  const isAllRequiredChecked = Object.values(requireAgreements).every((v) => v);
  const isAllChecked = isAllRequiredChecked && isAllOptionalChecked;

  return (
    <StepLayout
      desc={
        <>
          <div>서비스 이용을 위해</div>
          <div>약관에 동의해 주세요</div>
        </>
      }
      content={
        <AgreeCheckboxGroupWrapper>
          <StyledCheckbox $color="reverse" checked={isAllChecked} onChange={handleAllChange}>
            약관 전체동의
          </StyledCheckbox>
          <AgreeCheckbox
            required
            name="service"
            checked={requireAgreements.service}
            onChange={handleRequireChange}
            modalContent={<TermContainer type="STORE_SIGNUP_SERVICE" />}
          >
            서비스 이용약관
          </AgreeCheckbox>
          <AgreeCheckbox
            required
            name="privacy"
            checked={requireAgreements.privacy}
            onChange={handleRequireChange}
            modalContent={<TermContainer type="PRIVACY" />}
          >
            개인정보 수집 및 이용 동의
          </AgreeCheckbox>
          <AgreeCheckbox
            checked={isAllOptionalChecked}
            onChange={handleOptionAllChange}
            modalContent={<TermContainer type="STORE_SIGNUP_MARKETING" />}
          >
            (선택) 마케팅 정보 활용 및 수신 동의
          </AgreeCheckbox>
          <OptionalAgreeCheckboxGroupWrapper>
            <StyledCheckbox name="push" checked={agreements.push} onChange={handleChange}>
              Push
            </StyledCheckbox>
            <StyledCheckbox name="sms" checked={agreements.sms} onChange={handleChange}>
              SMS
            </StyledCheckbox>
            <StyledCheckbox name="email" checked={agreements.email} onChange={handleChange}>
              이메일
            </StyledCheckbox>
          </OptionalAgreeCheckboxGroupWrapper>
        </AgreeCheckboxGroupWrapper>
      }
      bottom={
        <Button type="primary" onClick={gotoSignUp} disabled={!isAllRequiredChecked}>
          다음
        </Button>
      }
    />
  );
};

export default AgreeSignUpContainer;
