import React, { useMemo } from 'react';
import moment from 'moment';
import { numberWithCommas } from 'utils';
import alertIcon from 'images/alert-circle.svg';
import { FlexBox, DetailInfo, AboutInfo, RevenueInfo } from './styles/historyDetailStyle';

const TitleBox = ({ title, info }) => {
  return (
    <FlexBox className="rvin-title-box">
      <div className="rvin-title">{title}</div>
      <div className="rvin-title">{numberWithCommas(info) || 0}원</div>
    </FlexBox>
  );
};

const DetailBox = ({ detailTitle, detailInfo }) => {
  return (
    <FlexBox className="rvin-box">
      <div className="rvin-description">{detailTitle}</div>
      <div className="rvin-content">{numberWithCommas(detailInfo) || 0}원</div>
    </FlexBox>
  );
};

const IncomeDetailHistory = ({ dataDetail }) => {
  const total = useMemo<{ netIncome: number; netServiceFee: number }>(() => {
    if (!dataDetail?.transfer) {
      return { netIncome: 0, netServiceFee: 0 };
    }

    const incomeInfo = dataDetail.transfer.settlementAmtInfo;
    const serviceInfo = dataDetail.transfer.serviceAmtInfo;

    return {
      netIncome:
        incomeInfo.mealAmt +
        incomeInfo.captainCouponAmt +
        incomeInfo.instantPayAmt +
        incomeInfo.captainPointAmt +
        incomeInfo.mealOtherCosts,
      netServiceFee:
        serviceInfo.mealAmt +
        serviceInfo.captainCouponAmt +
        serviceInfo.instantPayAmt +
        serviceInfo.captainPointAmt +
        serviceInfo.shippingAmt +
        serviceInfo.otherCosts
    };
  }, [dataDetail]);

  return (
    <div>
      <DetailInfo>
        <AboutInfo>
          <div className="abin-date">
            {dataDetail?.transfer
              ? `${moment(dataDetail.transfer.transferInfo.transferDate).format('YYYY년 MM월 DD일')} 입금정보`
              : null}
          </div>
          <div className="abin-income-box">
            <div>입금예정액</div>
            <div>{numberWithCommas(dataDetail?.transfer?.transferInfo.transferAmt) || 0}원</div>
          </div>
          <div>
            <div className="abin-box-top">
              <FlexBox className="abin-box">
                <div className="abin-description">이용기간</div>
                <div className="abin-content">
                  {dataDetail?.transfer
                    ? `${moment(dataDetail.transfer.transferInfo.settleStartDate).format('YYYY.MM.DD')} ~ ${moment(
                        dataDetail.transfer.transferInfo.settleEndDate
                      ).format('YYYY.MM.DD')}`
                    : null}
                </div>
              </FlexBox>
              <FlexBox className="abin-box">
                <div className="abin-description">이용회사</div>
                <FlexBox>
                  <div className="abin-content">{dataDetail?.transfer?.companyInfo.companyName}</div>
                  {dataDetail?.transfer?.companyInfo.divisionName === '' ? null : (
                    <div className="abin-division">{dataDetail?.transfer?.companyInfo.divisionName}</div>
                  )}
                </FlexBox>
              </FlexBox>
              <FlexBox className="abin-box">
                <div className="abin-description">이체 예정일</div>
                <div className="flex-column">
                  <div className="abin-content">
                    {dataDetail?.transfer
                      ? `${moment(dataDetail.transfer.transferInfo.transferDate).format('YYYY년 MM월 DD일')}`
                      : null}
                  </div>
                  <div className="abin-content">10일 이전까지 역발행 승인 할 시 15일 입금 (휴일 시 익 영업일)</div>
                </div>
              </FlexBox>
            </div>
            <div className="abin-warning">
              <img src={alertIcon} alt="alert Icon" />
              <div>결제를 취소하거나 변경할 시 금액에 변동이 생길 수 있다는 점 유의해주세요.</div>
            </div>
          </div>
        </AboutInfo>
        <RevenueInfo>
          <TitleBox title="매출액" info={total.netIncome} />
          <div className="rvin-box-top">
            <DetailBox detailTitle="식대 금액" detailInfo={dataDetail?.transfer?.settlementAmtInfo.mealAmt} />
            <DetailBox detailTitle="대장쿠폰 금액" detailInfo={dataDetail?.transfer?.settlementAmtInfo.captainCouponAmt} />
            <DetailBox detailTitle="간편결제 금액" detailInfo={dataDetail?.transfer?.settlementAmtInfo.instantPayAmt} />
            <DetailBox detailTitle="대장포인트 금액" detailInfo={dataDetail?.transfer?.settlementAmtInfo.captainPointAmt} />
            <FlexBox className="rvin-box">
              <div className="rvin-description">(매출액 중 배송비)</div>
              <div className="rvin-content">({numberWithCommas(dataDetail?.transfer?.settlementAmtInfo.shippingAmt) || 0}원)</div>
            </FlexBox>
            <DetailBox detailTitle="식대 기타 금액" detailInfo={dataDetail?.transfer?.settlementAmtInfo.mealOtherCosts} />
          </div>
        </RevenueInfo>
        <RevenueInfo>
          <TitleBox title="수수료" info={total.netServiceFee} />
          <div className="rvin-box-top">
            <DetailBox detailTitle="식대 수수료" detailInfo={dataDetail?.transfer?.serviceAmtInfo.mealAmt} />
            <DetailBox detailTitle="대장쿠폰 수수료" detailInfo={dataDetail?.transfer?.serviceAmtInfo.captainCouponAmt} />
            <DetailBox detailTitle="간편결제 수수료" detailInfo={dataDetail?.transfer?.serviceAmtInfo.instantPayAmt} />
            <DetailBox detailTitle="대장포인트 수수료" detailInfo={dataDetail?.transfer?.serviceAmtInfo.captainPointAmt} />
            <DetailBox detailTitle="배송비 수수료" detailInfo={dataDetail?.transfer?.serviceAmtInfo.shippingAmt} />
            <DetailBox detailTitle="기타 비용" detailInfo={dataDetail?.transfer?.serviceAmtInfo.otherCosts} />
            <div className="rvin-warning">
              <img src={alertIcon} alt="alert Icon" />
              <div>배송비에는 배송비 수수료가 우선 적용됩니다.</div>
            </div>
          </div>
        </RevenueInfo>
      </DetailInfo>
    </div>
  );
};

export default IncomeDetailHistory;
