import { Skeleton } from 'antd';
import { getAuthHost } from 'apis/host';
import { useStoreAccount, useStoreTransactionId } from 'hooks';
import { TState } from 'interfaces/transition';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container } from './style';

const CertificationContainer = () => {
  const host = getAuthHost();
  const navigate = useNavigate();
  const location = useLocation();
  const [transactionId, setTransactionId] = useState('');
  const [signId, setSignId] = useState<string | undefined>();
  const [isIframeLoading, setIframeLoading] = useState(true);

  const onSuccess = (data) => {
    const { state } = location;
    const { redirect } = (state as TState) || {};
    const { success } = redirect || {};
    console.log('data fetched and goto result', data);
    navigate(success || '/certificationResult', {
      state: { transactionId, data, ...(state as TState) },
      replace: true
    });
  };

  const onError = (error) => {
    const { state } = location;
    const { redirect } = (state as TState) || {};
    const { fault } = redirect || {};
    navigate(fault || '/certificationFault', {
      state: { transactionId, data: error, ...(state as TState) },
      replace: true
    });
  };

  useStoreAccount(transactionId, signId, { onSuccess, onError });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { mutateAsync } = useStoreTransactionId({ onSuccess, onError });

  const handler = async (e) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { currentTarget } = e;
    console.log(e);
    if (e.data.id === 'store_certification') {
      const certificationData = JSON.parse(e.data.thirdPartyCertificationResponse);
      console.log(certificationData);
      if (certificationData.responseCode === 'SUCCESS') {
        const { state } = location;
        const { from, redirect } = (state as TState) || {};
        const { success } = redirect || {};
        if (from === '/forgotSignId' || from === '/forgotPassword') {
          setTransactionId(certificationData.transactionId);
        } else {
          navigate(success || '/certificationResult', {
            state: { transactionId: certificationData.transactionId, ...(state as TState) },
            replace: true
          });
        }
      } else if (certificationData.responseCode === 'DUPLICATE_ACCOUNT') {
        const account = {
          signId: certificationData.duplicationId,
          joinDate: certificationData.regDate
        };
        navigate('/alreadyExists', {
          state: { transactionId: certificationData.transactionId, data: { account } },
          replace: true
        });
      } else {
        alert('인증 중 에러 발생');
      }
    }
  };

  useEffect(() => {
    window.addEventListener('message', handler);
    return () => {
      window.removeEventListener('message', handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { state } = location;
    const { signId: id } = (state as TState) || {};
    if (id) {
      setSignId(id);
    }
    return () => {
      console.log('<<<<<<<<<<<<<<<<<<<<<unmount location change>>>>>>>>>>>>>>>>>>>>');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const onLoadIframe = () => {
    console.log('loaded iframe');
    setIframeLoading(false);
  };

  let referrerType = 'STORE_CERTIFICATION';
  const { state } = location;
  const { from } = (state as TState) || {};
  if (from === '/agreeSignUp') {
    referrerType = 'STORE_JOIN';
  }
  const url = `${host}/gateway/v1/store/certification?thirdPartyType=MOBILIANS&refferType=${referrerType}`;

  return (
    <Container>
      {isIframeLoading ? <Skeleton active={isIframeLoading} paragraph={{ rows: 12 }} /> : null}
      <iframe height="100%" title="certification" src={url} scrolling="no" onLoad={onLoadIframe} />
    </Container>
  );
};

export default CertificationContainer;
