import React, { useMemo, useState } from 'react';
import { Button } from 'antd';
import Icon from '@ant-design/icons';
import type { CheckboxChangeEvent as AntCheckboxChangeEvent } from 'antd/es/checkbox/Checkbox';
import TermsModal from 'components/Modal/TermsModal';
import { StyledArrowRight16Svg, RequireSpan } from 'styles';

import { AgreeCheckboxWithModalWrapper, StyledCheckBox } from './style';

export type CheckboxChangeEvent = AntCheckboxChangeEvent;

export interface AgreeCheckboxProps {
  required?: boolean | string;
  name?: string;
  children?: React.ReactNode;
  checked?: boolean;
  onChange?: (e: CheckboxChangeEvent) => void;
  modalTitle?: React.ReactNode;
  modalContent?: React.ReactNode;
  checkbox?: boolean;
  confirmText?: string;
  color?: 'reverse' | 'light';
  transparent?: boolean;
  beforeVisible?: () => boolean;
}

export const Checkbox = StyledCheckBox;

const AgreeCheckbox = ({
  required,
  name,
  children,
  checked,
  onChange,
  modalTitle,
  modalContent,
  checkbox = true,
  confirmText,
  color,
  transparent,
  beforeVisible
}: AgreeCheckboxProps) => {
  const [visible, setVisible] = useState(false);

  const title = useMemo(() => {
    if (modalTitle) {
      return modalTitle;
    }
    if (required && typeof children === 'string') {
      return `${children.replace('(필수)', '')}(필수)`;
    }
    if (!required && typeof children === 'string') {
      return `${children.replace('(선택)', '')}(선택)`;
    }
    return null;
  }, [modalTitle, children, required]);

  const toggleVisible = () => {
    if (beforeVisible && !beforeVisible()) {
      return;
    }
    setVisible(!visible);
  };

  return (
    <AgreeCheckboxWithModalWrapper>
      {checkbox ? (
        <StyledCheckBox
          $color={color}
          $backgroundColor={transparent ? 'transparent' : null}
          name={name}
          checked={checked}
          onChange={onChange}
        >
          {required ? <span className="required-text">{typeof required === 'string' ? required : '(필수)'}</span> : null}
          {children}
        </StyledCheckBox>
      ) : (
        <div style={{ width: '100%' }}>
          <RequireSpan>{typeof required === 'string' ? required : '(필수)'}</RequireSpan>
          {children}
        </div>
      )}
      <Button type="link" icon={!confirmText ? <Icon component={StyledArrowRight16Svg} /> : null} onClick={toggleVisible}>
        {confirmText}
      </Button>
      <TermsModal title={title} open={visible} close={toggleVisible}>
        {modalContent}
      </TermsModal>
    </AgreeCheckboxWithModalWrapper>
  );
};

export default AgreeCheckbox;
