import { useMutation } from 'react-query';
import { login, logout, LoginParams } from 'apis';
import { OAuth, LoginForm } from 'interfaces/auth';
import { setToken, getRefreshToken } from 'utils/storages';
import { Buffer } from 'buffer';

const onSuccess = (data: OAuth) => {
  setToken(data);
};

export const useLogin = () =>
  useMutation<OAuth, unknown, LoginForm, unknown>(
    ['login'],
    (user: LoginForm) => {
      const { username, password } = user;
      const data: LoginParams = {
        username,
        password: Buffer.from(password, 'utf8').toString('base64'),
        grant_type: 'password'
      };
      return login(data);
    },
    { onSuccess, retry: false }
  );

export const useRefresh = () =>
  useMutation<OAuth, unknown, void, unknown>(
    ['refresh'],
    () => {
      const data = {
        grant_type: 'refresh_token',
        refresh_token: getRefreshToken()
      };
      return login(data);
    },
    { onSuccess, retry: false }
  );

export const useLogout = () =>
  useMutation<void, unknown, string, unknown>('logout', (token: string) => logout(token), { retry: false });
