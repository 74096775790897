import { Space } from 'antd';
import { ReactComponent as ArrowRight16Svg } from 'images/arrow/arrow-right-16.svg';
import styled from 'styled-components';

export const ContentPadding24 = styled.div`
  padding: 24px;
`;

interface MaxWidthContentProps {
  maxWidth?: string;
}

export const MaxWidthContent = styled.div<MaxWidthContentProps>`
  text-align: left;
  padding: 28px 20px 43px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: ${(props) => (props?.maxWidth ? props.maxWidth : '768px')};
  margin-left: auto;
  margin-right: auto;
`;

interface FlexBoxFullWidthCenterProps {
  direction?: 'row' | 'column' | 'row-revers' | 'column-revers' | 'initial' | 'inherit' | 'unset';
  align?: 'baseline' | 'center' | 'flex-start' | 'flex-end' | 'stretch' | 'initial' | 'inherit' | 'unset';
  gap?: string;
  justify?:
    | 'baseline'
    | 'center'
    | 'end'
    | 'first baseline'
    | 'flex-start'
    | 'flex-end'
    | 'last baseline'
    | 'left'
    | 'right'
    | 'safe'
    | 'space-around'
    | 'space-between'
    | 'space-evenly'
    | 'start'
    | 'stretch'
    | 'initial'
    | 'inherit'
    | 'unset';
}
export const FlexBoxFullWidthCenter = styled.div<FlexBoxFullWidthCenterProps>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  align-items: ${({ align }) => align ?? 'center'};
  justify-content: ${({ justify }) => justify};
  width: 100%;
  gap: ${({ gap }) => gap};
`;

export const FlexBoxFullWidthHeight = styled(FlexBoxFullWidthCenter)`
  height: 100%;
`;

export const FlexBoxCenter = styled.div<FlexBoxFullWidthCenterProps>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  align-items: ${({ align }) => align ?? 'center'};
  justify-content: ${({ justify }) => justify};
  gap: ${({ gap }) => gap};
`;
export const FullWidthSpace = styled(Space)`
  width: 100%;
`;

export const FullSpaceCenter = styled(Space)<Pick<FlexBoxFullWidthCenterProps, 'justify'> & { $minHeight?: string }>`
  width: 100%;
  min-height: ${({ $minHeight }) => $minHeight ?? '100vh'};
  justify-content: ${({ justify }) => justify ?? 'center'};
`;

export const BottomButtonArea = styled(Space)`
  width: 100%;
  justify-content: space-between;
  & .ant-space-item {
    flex: auto;
    & button.ant-btn {
      width: 100%;
      height: 55px;
      font-size: 20px;
      font-weight: 700;
      &.prev-btn {
        background-color: ${({ theme }) => theme.color.mint};
        color: ${({ theme }) => theme.color.primary};
      }
      &.cancel-btn {
        background-color: ${({ theme }) => theme.color.gray01};
        color: ${({ theme }) => theme.color.white};
      }
    }
  }
`;

export const StyledArrowRight16Svg = styled(ArrowRight16Svg)`
  font-size: 16px;
`;

export const RequireSpan = styled.span`
  color: ${({ theme }) => theme.color.primary};
  margin-right: 4px;
`;

export const PrimaryColorSpan = styled.span`
  color: #4bb378;
`;
