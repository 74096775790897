import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Drawer, Layout } from 'antd';
import { Outlet, useLocation } from 'react-router-dom';
import { useIsFetching, useIsMutating } from 'react-query';
import { useBreakpoint, useChannelTalkScrollHide } from 'hooks';

import toggleImage from 'images/gnb/g_icon04.png';
import { handlerKeyUp } from 'utils';
import classNames from 'classnames';

export interface MainLayoutProps {
  header?: React.ReactElement;
  navigator?: React.ReactElement;
  children: React.ReactNode;
}

const StyledHeader = styled(Layout.Header)`
  padding: 0;
  height: 60px;
  line-height: 60px;
  .trigger {
    display: inline-block;
    z-index: 1;
    position: absolute;
    color: #ffffff;
    padding: 0 24px;
    font-size: 18px;
    line-height: 60px;
    cursor: pointer;
    transition: color 0.3s;
    &:hover {
      color: #1890ff;
    }
    &.toggle-icon {
      line-height: 60px;
    }
    svg {
      vertical-align: middle;
    }
  }
  .navbar-collapse.toptitle {
    padding-left: 75px;
  }
`;

const ContentWrapper = styled.div`
  font-size: 14px;
  width: 100%;
  //height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  //overflow-y: scroll;
  height: calc(100vh - 60px);

  & {
    a {
      color: #6d7582;
      font-size: 16px;
    }
    .ant-checkbox {
      ms-transform: scale(1.25);
      -moz-transform: scale(1.25);
      -webkit-transform: scale(1.25);
      -o-transform: scale(1.25);
      font-size: 18px;
      top: 0.09em;
    }
    .ant-divider {
      color: #cccccc;
      margin: 0 16px;
    }
  }
`;

const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 0;
  }
`;

const { Content, Sider: ASide } = Layout;

const MainLayout = ({ header, navigator, children }: MainLayoutProps) => {
  const location = useLocation();
  const { pos } = useBreakpoint();
  const [collapsed, setCollapsed] = useState(false);
  const [visible, setVisible] = useState(false);

  useChannelTalkScrollHide();

  const isFetching = useIsFetching({
    predicate: (query) => {
      return (
        !query.queryHash.includes('pollingDeliveryStatus') &&
        !query.queryHash.includes('order') &&
        !query.queryHash.includes('getRobotSettings')
      );
    }
  });

  const isMutating = useIsMutating({
    predicate: (query) => {
      return !query.options.mutationKey.includes('updateStoreOpen') && !query.options.mutationKey.includes('getRobotOrderDetail');
    }
  });

  useEffect(() => {
    // breakpoint pos width 14000px
    setCollapsed(pos);
    if (!pos) {
      setVisible(false);
    }
  }, [pos]);

  useEffect(() => {
    if (!pos) {
      return;
    }
    setVisible((prev) => {
      if (prev) return false;
      return prev;
    });
  }, [pos, location.pathname]);

  const apiLoading = true; // window.location.pathname !== '/robotDelivery';

  return (
    <Layout style={{ maxHeight: '100vh' }}>
      {(isFetching || isMutating) && apiLoading ? (
        <div className="loading">
          <div className="spin" />
        </div>
      ) : null}
      <ASide
        // style={{ height: '100vh' }}
        className={classNames({ 'side-collapsed': collapsed })}
        collapsedWidth={pos ? 0 : undefined}
        width={290}
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => {
          setCollapsed(value);
        }}
        trigger={null}
      >
        {navigator}
      </ASide>
      <StyledDrawer width={290} placement="left" open={visible} closable={false}>
        {React.cloneElement(navigator, { onCloseDrawer: () => setVisible(false), posBreakPoint: pos })}
      </StyledDrawer>
      <Layout>
        <StyledHeader>
          {!pos ? (
            React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
              className: 'trigger',
              onClick: () => setCollapsed(!collapsed)
            })
          ) : (
            <div
              className="trigger toggle-icon"
              role="button"
              onClick={() => setVisible(true)}
              onKeyUp={handlerKeyUp(() => setVisible(true))}
              tabIndex={-1}
            >
              <img src={toggleImage} className="gnbicon" alt="toggle" />
            </div>
          )}
          {header}
        </StyledHeader>
        <Content style={{ overflowY: 'scroll' }}>
          <ContentWrapper>
            {children}
            <Outlet />
          </ContentWrapper>
        </Content>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
