import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { StepLayout } from 'layouts';
import { Button } from 'antd';
import { NoticeBeforeCertification } from 'components';

const ForgotSignIdContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const goCertification = () => {
    const state = {
      from: location.pathname
    };
    navigate('/certification', { state });
  };

  return (
    <StepLayout
      desc={
        <>
          <div>회원가입시 인증한 휴대폰 번호로</div>
          <div>본인인증을 진행해주세요.</div>
        </>
      }
      content={<NoticeBeforeCertification />}
      bottom={
        <Button type="primary" onClick={goCertification}>
          본인인증
        </Button>
      }
    />
  );
};

export default ForgotSignIdContainer;
