/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { SignUpForm } from 'components';
import { useCheckDuplicate, useSignUp } from 'hooks';

import { IAgreements } from './AgreeSignUp';

const SignUpContainer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoading: isSearchLoading, mutateAsync: checking } = useCheckDuplicate();
  const { isLoading, mutateAsync: signUp } = useSignUp();
  const [transactionId, setTransactionId] = useState<string | undefined>();
  const [agreements, setAgreements] = useState<IAgreements>({ email: false, push: false, sms: false });

  useEffect(() => {
    const { state } = location;
    const { transactionId: tId, passData } = (state as Record<string, unknown>) || {};
    if (!tId) {
      navigate('/agreeSignUp', { replace: true });
    } else {
      setTransactionId(tId as string);
      setAgreements(passData as IAgreements);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearch = async (signId: string) => {
    const result = await checking(signId);
    return result;
  };

  const onSignUp = async (values) => {
    const {
      signId: { value: id },
      password,
      email,
      joinPath
    } = values;
    if (transactionId) {
      const { push: marketingPush, sms: marketingSms, email: marketingEmail } = agreements;
      const params = {
        transactionId,
        id,
        password,
        email,
        joinPath,
        agreementTerms: {
          marketingPush,
          marketingSms,
          marketingEmail
        }
      };
      const complete = await signUp(params);
      navigate('/completeSignUp', { state: { signUpData: complete } });
    }
  };
  return (
    <div>
      <SignUpForm loading={isLoading} searchLoading={isSearchLoading} onSearch={onSearch} onSignUp={onSignUp} />
    </div>
  );
};

export default SignUpContainer;
