import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import MonthSelector from 'components/Modal/ MonthSelector';
import { generate } from 'shortid';
import { ConviSet, StyledDateModal, Container, DateRange } from './styles';

const quarterBtn = () => {
  const quarterBtnStr = [];
  for (let i = 0; i < 6; i += 1) {
    const standardDate = moment().subtract(i, 'quarter');
    quarterBtnStr.push(standardDate);
  }
  return quarterBtnStr;
};
const quarterSetBtn = quarterBtn();

type TDate = {
  startDate: Moment;
  endDate: Moment;
};

interface DateModalProps {
  startDate: Moment;
  endDate: Moment;
  dateSelected: (date: { startDate: Moment; endDate: Moment }) => void;
  close?: (v?: unknown) => void;
  isOpenCalendar?: boolean;
  title?: string;
  target?: string;
}

const MonthModal = ({
  isOpenCalendar,
  startDate,
  endDate,
  title = '조회 기간 설정',
  dateSelected,
  close,
  target
}: DateModalProps) => {
  const [isVisible, setVisible] = useState<boolean>(false);
  const [date, setDate] = useState<TDate>({
    startDate: moment().startOf('month'),
    endDate: moment()
  });
  const [current, setCurrent] = useState(moment());
  const [currentFocus, setCurrentFocus] = useState<'start' | 'end'>('start');
  const minYear = moment().subtract(60, 'month').year();
  const maxYear = moment().year();

  useEffect(() => {
    setDate({
      startDate,
      endDate
    });
    setCurrent(moment(startDate));
  }, [startDate, endDate]);

  useEffect(() => {
    if (isOpenCalendar === true) {
      setVisible(true);
    }
    if (isOpenCalendar === false) {
      setVisible(false);
    }
  }, [isOpenCalendar]);

  const handleClose = () => {
    setVisible(false);
    close?.();
    setDate({ startDate, endDate });
  };

  const handleConfirm = () => {
    dateSelected(date);
  };

  const handleArrowLeft = () => {
    if (current.year() === minYear) {
      alert('자료가 존재하지 않습니다.');
      return;
    }
    setCurrent(moment(current).subtract(1, 'year'));
  };
  const handleArrowRight = () => {
    if (current.year() === maxYear) {
      alert('자료가 존재하지 않습니다');
      return;
    }
    setCurrent(moment(current).add(1, 'year'));
  };

  const handleQuarter = (quarter) => {
    if (quarter.isSame(moment(), 'quarter')) {
      setDate({
        startDate: moment(quarter).startOf('quarter'),
        endDate: moment()
      });
    } else {
      setDate({
        startDate: moment(quarter).startOf('quarter'),
        endDate: moment(quarter).endOf('quarter')
      });
    }
    setCurrent(moment(quarter));
    if (currentFocus === 'end') {
      setCurrentFocus('start');
    }
  };

  const handleCurrentMonth = (month: number) => {
    const selectedDate = moment(current.month(month - 1).startOf('month'));
    if (currentFocus === 'start') {
      if (selectedDate.isAfter(date.endDate)) {
        alert('기준일보다 미래로 갈 수 없습니다.');
        return;
      }
      setDate({
        ...date,
        startDate: moment(selectedDate)
      });
      setCurrent(moment(selectedDate));
      setCurrentFocus('end');
      setCurrent(moment(date.endDate));
    }
    if (currentFocus === 'end') {
      if (selectedDate.isSame(moment().startOf('month'), 'month')) {
        setDate({
          ...date,
          endDate: moment()
        });
        return;
      }
      if (selectedDate.isBefore(date.startDate)) {
        alert('기준일보다 과거로 갈 수 없습니다.');
        return;
      }
      setDate({
        ...date,
        endDate: moment(selectedDate).endOf('month')
      });
      setCurrent(moment(selectedDate));
    }
  };

  const handleLeftBox = () => {
    setCurrent(moment(date.startDate));
    setCurrentFocus('start');
  };

  const handleRightBox = () => {
    setCurrent(moment(date.endDate));
    setCurrentFocus('end');
  };

  return (
    <div>
      {target === 'conviseton' ? (
        <StyledDateModal
          width={700}
          open={isVisible}
          title={title}
          onCancel={handleClose}
          closable
          footer={
            <div className="modal-footer">
              <button type="button" className="btn btn-cancel" data-dismiss="modal" onClick={handleClose}>
                취소
              </button>
              <button type="button" className="btn btn-sikdae" onClick={handleConfirm}>
                확인
              </button>
            </div>
          }
        >
          <Container>
            <div className="flex">
              <DateRange className="inner-width">
                <div className="inner-title">
                  <div>조회기간</div>
                </div>
                <div>
                  <div className="date-btn-wrapper">
                    <button type="button" onClick={handleLeftBox}>
                      <div className={classNames('date-btn', { active: currentFocus === 'start' })}>
                        {date.startDate.year()}년 {date.startDate.month() + 1}월
                      </div>
                    </button>
                    <div className="to-from margin-right">부터</div>
                    <button type="button" onClick={handleRightBox}>
                      <div className={classNames('date-btn', 'end-month', { active: currentFocus === 'end' })}>
                        {date.endDate.year()}년 {date.endDate.month() + 1}월
                      </div>
                    </button>
                    <div className="to-from">까지</div>
                  </div>
                  <div className="date-range">
                    적용일 {date.startDate.format('YYYY.MM.DD')} ~ {date.endDate.format('YYYY.MM.DD')}
                  </div>
                  <MonthSelector
                    date={date}
                    current={current}
                    currentFocus={currentFocus}
                    onArrowLeft={handleArrowLeft}
                    onArrowRight={handleArrowRight}
                    onSelectMonth={handleCurrentMonth}
                  />
                </div>
              </DateRange>
              <ConviSet className="inner-width">
                <div className="inner-title">
                  <div>간편설정</div>
                </div>
                <div className="btn-wrapper">
                  {quarterSetBtn.map((quarter) => {
                    let isActive;
                    if (quarter.isSame(moment(), 'quarter')) {
                      isActive = date.startDate.isSame(quarter.startOf('quarter'), 'day') && date.endDate.isSame(moment(), 'day');
                    } else {
                      isActive =
                        date.startDate.isSame(quarter.startOf('quarter'), 'day') &&
                        date.endDate.isSame(quarter.endOf('quarter'), 'day');
                    }
                    return (
                      <div className={isActive ? 'btn active' : 'btn'} key={generate()}>
                        <button type="button" onClick={() => handleQuarter(quarter)}>
                          <div>{quarter.year()}년</div>
                          <div>{quarter.quarter()}분기</div>
                        </button>
                      </div>
                    );
                  })}
                </div>
              </ConviSet>
            </div>
          </Container>
        </StyledDateModal>
      ) : (
        <StyledDateModal
          width={375}
          open={isVisible}
          title={title}
          onCancel={handleClose}
          closable
          footer={
            <div className="modal-footer">
              <button type="button" className="btn btn-cancel" data-dismiss="modal" onClick={handleClose}>
                취소
              </button>
              <button type="button" className="btn btn-sikdae" onClick={handleConfirm}>
                확인
              </button>
            </div>
          }
        >
          <Container>
            <div className="flex">
              <DateRange className="inner-width">
                <div className="inner-title">
                  <div>조회기간</div>
                </div>
                <div>
                  <div className="date-btn-wrapper">
                    <button type="button" onClick={handleLeftBox}>
                      <div className={classNames('date-btn', { active: currentFocus === 'start' })}>
                        {date.startDate.year()}년 {date.startDate.month() + 1}월
                      </div>
                    </button>
                    <div className="to-from margin-right">부터</div>
                    <button type="button" onClick={handleRightBox}>
                      <div className={classNames('date-btn', 'end-month', { active: currentFocus === 'end' })}>
                        {date.endDate.year()}년 {date.endDate.month() + 1}월
                      </div>
                    </button>
                    <div className="to-from">까지</div>
                  </div>
                  <div className="date-range">
                    적용일 {date.startDate.format('YYYY.MM.DD')} ~ {date.endDate.format('YYYY.MM.DD')}
                  </div>
                  <MonthSelector
                    date={date}
                    current={current}
                    currentFocus={currentFocus}
                    onArrowLeft={handleArrowLeft}
                    onArrowRight={handleArrowRight}
                    onSelectMonth={handleCurrentMonth}
                  />
                </div>
              </DateRange>
            </div>
          </Container>
        </StyledDateModal>
      )}
    </div>
  );
};

export default MonthModal;
