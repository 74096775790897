import React, { useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { FlexBoxCenter, FlexBoxFullWidthCenter } from 'styles';
import { IAggregateOrder } from 'interfaces/robot';
import { useRobotOrderDetail } from 'hooks/useRobotDelivery';
import { ReactComponent as AccordionArrow } from 'images/arrow/arrow-right-16.svg';

import OrderAggregateCard from './components/OrderAggregateCard';
import OrderDetailModal from './modal/OrderDetailModal';
import { TColumns } from './components/DefaultTable';
import { ContentWrapper, NoContentWrapper } from './styles';

const AGGREGATE_COLUMNS: TColumns[] = [
  { field: 'menuName', headerName: '메뉴명', width: 300 },
  { field: 'count', headerName: '수량', width: 150, textAlign: 'center' },
  { field: 'price', headerName: '가격', width: 200, textAlign: 'end' }
];
const ORDER_STATUS_CARD = {
  ACCEPT: { bgColor: '#DEF8EA', color: '#46B476', label: '배달 시작' },
  DELIVERING: { bgColor: '#FFF1D3', color: '#FFBE00', label: '도착 예정' },
  COMPLETE: { bgColor: '#EFF6FF', color: '#0067FF', label: '배달 완료' },
  PICKED_UP: { bgColor: '#EEEEEE', color: '#AFB2B9', label: '픽업이 완료된\n주문 건 입니다' }
};
const DATE_BY_STATUS = {
  ACCEPT: 'deliveryStartTime',
  DELIVERING: 'expectedDeliveryTime',
  COMPLETE: 'deliveryEndTime'
};

const AggregateWrapper = styled.div`
  padding: 14px 26px 34px;
  background: #fff;
  border: solid 1px #e2e2e2;
`;
const CardHeader = styled(FlexBoxFullWidthCenter)`
  padding-bottom: 10px;
  font-size: 24px;
  color: #afb2b9;
  .order-idx,
  .robot-name {
    font-weight: 700;
    color: #46b476;
  }
  .address {
    padding-top: 10px;
    font-size: 20px;
  }
`;
const AggregateContentWrapper = styled.div<{ $isActive: boolean }>`
  .accordion-content {
    max-height: ${({ $isActive }) => ($isActive ? '100vh' : 0)};
    padding: 0;
    overflow: hidden;
    transition: all 0.2s;
  }
`;
const OrderStatusBox = styled(FlexBoxFullWidthCenter)<{ $status: string }>`
  width: 100%;
  max-width: 240px;
  height: 120px;
  border-radius: 10px;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  white-space: break-spaces;
  color: ${({ $status }) => ORDER_STATUS_CARD[$status].color};
  background: ${({ $status }) => ORDER_STATUS_CARD[$status].bgColor};
`;
const AccordionButton = styled(FlexBoxCenter)<{ $isActive: boolean }>`
  gap: 10px;
  font-size: 20px;
  color: #afb2b9;
  .accordion-arrow {
    display: inline-flex;
    transform: ${({ $isActive }) => ($isActive ? 'rotate(270deg)' : 'rotate(90deg)')};
    transition: 0.2s all;
    width: 30px;
    height: 30px;
  }
`;

const OrderAcceptPage = ({ orders }: { orders: IAggregateOrder['orders'] }) => {
  const [checkedIdx, setCheckedIdx] = useState<number[]>([]);
  const [isDetailModal, setIsDetailModal] = useState(false);

  const { mutate: getOrderDetail, data: orderDetail } = useRobotOrderDetail();

  const handleAcceptCardClick = (idx: number) => {
    if (checkedIdx.indexOf(idx) >= 0) {
      checkedIdx.splice(checkedIdx.indexOf(idx), 1);
      setCheckedIdx([...checkedIdx]);
    } else {
      setCheckedIdx([...checkedIdx, idx]);
    }
  };

  const handleOnOffDetailModal = () => setIsDetailModal(!isDetailModal);
  const handleDetailClick = async (couponId: string) => {
    getOrderDetail(couponId);
    handleOnOffDetailModal();
  };
  return (
    <ContentWrapper>
      {orders?.length ? (
        orders?.map((order) => {
          const isChecked = checkedIdx.indexOf(order.robotDeliveryIdx) === -1;
          const isActive = order.deliveryStatus === 'ACCEPT' ? isChecked : !isChecked;
          const dateKey = DATE_BY_STATUS[order.deliveryStatus];
          const statusTime = order.deliveryStatus !== 'PICKED_UP' ? moment(`2000.01.01 ${order[dateKey]}`).format('HH:mm') : null;

          const totalOrderCount = order.summaries.reduce((number, cur) => number + cur.totalOrderCount, 0);
          return (
            <AggregateWrapper key={order.robotDeliveryIdx}>
              <CardHeader align="flex-start">
                <div style={{ width: '100%' }}>
                  <FlexBoxFullWidthCenter gap="100px" style={{ paddingBottom: '20px' }}>
                    <div className="order-idx">주문 승인 번호 : {order.robotDeliveryIdx}</div>
                    <div>{order.aggregatedDate}</div>
                    <div className="robot-name">로봇 : {order.deliveryRobot}</div>
                  </FlexBoxFullWidthCenter>
                  <div className="address">출발지 : {order.storeName}</div>
                  <div className="address">목적지 : {order.spotName}</div>
                </div>
                <OrderStatusBox justify="center" $status={order.summaries[0]?.orderStatus}>
                  {statusTime} {ORDER_STATUS_CARD[order.summaries[0]?.orderStatus].label}
                </OrderStatusBox>
              </CardHeader>
              <div>
                <FlexBoxFullWidthCenter justify="space-between" style={{ paddingBottom: 15 }}>
                  <div style={{ fontSize: 24, fontWeight: 700, color: '#0067FF' }}>
                    포함된 주문 : {order.summaries.length}건 ({totalOrderCount}개)
                  </div>
                  <AccordionButton $isActive={isActive} onClick={() => handleAcceptCardClick(order.robotDeliveryIdx)}>
                    <span>포함된 주문 상세</span>
                    <AccordionArrow className="accordion-arrow" />
                  </AccordionButton>
                </FlexBoxFullWidthCenter>
                <AggregateContentWrapper $isActive={isActive}>
                  {order?.summaries?.map((summary) => (
                    <OrderAggregateCard
                      key={summary.payRoomIdx}
                      isActive={isActive}
                      status={summary.orderStatus}
                      spotName={summary.spotName}
                      couponId={summary.couponId}
                      payRoomIdx={summary.payRoomIdx}
                      confirmDate={summary.confirmDate}
                      receiverName={summary.receiverName}
                      deliveryMemo={summary.deliveryMemo}
                      totalOrderCount={summary.totalOrderCount}
                      columns={AGGREGATE_COLUMNS}
                      menus={summary.menus}
                      onDetailClick={handleDetailClick}
                    />
                  ))}
                </AggregateContentWrapper>
              </div>
            </AggregateWrapper>
          );
        })
      ) : (
        <NoContentWrapper>해당 상태의 주문 건이 없습니다.</NoContentWrapper>
      )}
      <OrderDetailModal open={isDetailModal} onCancel={handleOnOffDetailModal} orderDetail={orderDetail} />
    </ContentWrapper>
  );
};

export default OrderAcceptPage;
