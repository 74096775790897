/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';
import { Upload, Button, UploadProps } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { UploadFile } from 'antd/es/upload/interface';
import CustomUploadList from './CustomUploadList';

export interface VFileUploadProps extends Omit<UploadProps, 'onChange'> {
  value?: Array<UploadFile>;
  onChange?: (v: UploadFile[]) => void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getBase64(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const VFileUpload: React.FC<VFileUploadProps> = ({ value, onChange, children, maxCount, ...uploadProps }) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  useEffect(() => {
    if (value && value.length > 0) {
      setFileList(value);
    }
  }, [value]);

  const beforeUpload = (file: UploadFile) => {
    const newFileList = [...fileList, file];
    // setFileList(newFileList);
    if (typeof onChange === 'function') {
      if (maxCount === 1) {
        onChange([file]);
      } else {
        onChange(newFileList);
      }
    }
    return false;
  };

  const onRemove = (file: UploadFile) => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    // setFileList(newFileList);
    if (typeof onChange === 'function') {
      onChange(newFileList);
    }
  };

  const props = {
    beforeUpload,
    onRemove,
    fileList
  };

  return (
    <Upload
      maxCount={maxCount}
      listType="picture"
      itemRender={(originNode, file, currFileList, actions) => {
        return <CustomUploadList originNode={originNode} file={file} currFileList={currFileList} actions={actions} />;
      }}
      {...props}
      {...uploadProps}
    >
      <Button icon={<UploadOutlined />}>{children || '파일 선택'}</Button>
    </Upload>
  );
};

export default VFileUpload;
