import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Input, InputRef, Modal, Button, ButtonV2 } from 'components';
import { useBookingRelationCompaniesByDay } from 'hooks';
import { BookingMenu } from 'interfaces/booking';

const SearchBox = styled.form`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  gap: 16px;
`;

const InputLabel = styled.label`
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 16px;
  flex: 1;
  margin-bottom: 0;
  input:not([type='password']).ant-input.ant-input-lg {
    height: 32px;
    padding: 8px;
    font-size: 16px;
    font-weight: normal;
    flex: 1;
  }
`;

const Box = styled.div`
  border: 1px solid #cdcdcd;
  border-radius: 5px;
`;

const HeaderRow = styled.div`
  background-color: #8a8a8a;
  padding: 15px;
`;

const BodyRow = styled.div`
  padding: 15px;
  border-top: 1px solid #cdcdcd;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

interface RelationCompaniesModalProps {
  bookingDate: string;
  menu?: BookingMenu;
  open: boolean;
  close: () => void;
}
const RelationCompaniesModal = ({ bookingDate, menu, open, close }: RelationCompaniesModalProps) => {
  const antInputRef = useRef<InputRef>();
  const [params, setParams] = useState<{ searchValue: string }>();
  const { data, refetch } = useBookingRelationCompaniesByDay(bookingDate, menu?.id, params, { enabled: open });

  useEffect(() => {
    if (open) {
      refetch().then();
    }
  }, [open, refetch]);
  const search = (e: React.FormEvent) => {
    e.preventDefault();
    setParams({ searchValue: antInputRef.current?.input.value });
  };

  return (
    <Modal
      title={`${menu?.name ? `${menu.name} - ` : ''}연결된 고객사`}
      open={open}
      onCancel={close}
      cancelText="취소"
      footer={<Button onClick={close}>취소</Button>}
    >
      <div>
        <SearchBox onSubmit={search}>
          <InputLabel htmlFor="company-name">
            고객사
            <Input id="company-name" ref={antInputRef} placeholder="검색할 고객사명을 입력해주세요" />
          </InputLabel>
          <ButtonV2 type="submit">검색</ButtonV2>
        </SearchBox>
        <Box>
          <HeaderRow>고객사명</HeaderRow>
          {data?.companies?.map(({ companyId, companyName }) => <BodyRow key={companyId}>{companyName}</BodyRow>)}
        </Box>
      </div>
    </Modal>
  );
};

export default RelationCompaniesModal;
