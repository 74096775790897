import { storeClient } from './apiClients';

export const getBanner = async () => {
  const { data } = await storeClient.get('/webbanner/v1/main');
  return data;
};

export const getShortcut = async () => {
  const { data } = await storeClient.get('/webbanner/v1/shortcut');
  return data;
};
