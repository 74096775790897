import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Form } from 'antd';
import { modalMethod } from 'components';
import { useEvidenceDetail, useMeInfo, useSaveEvidence } from 'hooks';
import { IEvidenceState } from 'interfaces/settlement';
import moment from 'moment';
import html2canvas from 'html2canvas';
import { jsPDF as JsPDF } from 'jspdf';

import { Wrapper, SelectBox, StyledTable, FormWrapper } from './style';
import TaxRefuseModal from './TaxRefuseModal';

const evidenceColumns = [
  { title: '작성일자', dataIndex: 'publishDateYmd' },
  { title: '공급가액', dataIndex: 'evidenceSupplyAmt' },
  { title: '세액', dataIndex: 'evidenceVATAmt' },
  { title: '수정사유', dataIndex: '-' }
];
const itemColumns = [
  { title: '월', dataIndex: 'month' },
  { title: '일', dataIndex: 'day' },
  { title: '품목', dataIndex: 'itemName' },
  { title: '규격', dataIndex: '-' },
  { title: '수량', dataIndex: '-' },
  { title: '단가', dataIndex: '-' },
  { title: '공급가액', dataIndex: 'itemSupplyAmt' },
  { title: '세액', dataIndex: 'itemVATAmt' }
];
const amountColumns = [
  { title: '합계금액', dataIndex: 'evidenceAmt' },
  { title: '현금', dataIndex: '-' },
  { title: '수표', dataIndex: '-' },
  { title: '어음', dataIndex: '-' },
  { title: '외상미수금', dataIndex: '-' }
];

const StyleTable = ({ columns, data }) => (
  <StyledTable>
    <thead>
      <tr>{columns?.map(({ title }) => <th key={title}>{title}</th>)}</tr>
    </thead>
    <tbody>
      {data?.map((item) => (
        <tr>
          {columns?.map(({ dataIndex }) => {
            const it = typeof item?.[dataIndex] === 'number' ? item?.[dataIndex].toLocaleString() : item?.[dataIndex];
            return <td>{it || '-'}</td>;
          })}
        </tr>
      ))}
    </tbody>
  </StyledTable>
);

const TaxInvoice = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);

  const { selectedStore } = useMeInfo();
  const { data } = useEvidenceDetail(selectedStore.sid, location?.state?.evidenceSeq);
  const { mutate } = useSaveEvidence();

  useEffect(() => {
    if ((typeof location?.state?.evidenceSeq !== 'number' && !location?.state?.evidenceSeq) || !selectedStore?.sid) {
      navigate('/');
    }
  }, [location, navigate, selectedStore?.sid]);

  const download = () => {
    const content = document.getElementById('pdf');
    if (!content) return;
    const w = content.offsetWidth * 0.245;
    const h = content.offsetHeight * 0.245;
    html2canvas(content).then((canvas) => {
      const filename = data?.evidenceItems[0].itemName;
      const imgHeight = (h * 297) / w;
      const y = imgHeight + 30 > 210 ? 5 : 30;

      const doc = new JsPDF('l', 'mm', 'a4');
      const img = canvas.toDataURL('image/jpeg');
      doc.addImage(img, 'JPEG', 5, y, 287, imgHeight);
      doc.save(`${filename}.pdf`);
    });
  };

  const handleStateChange = async (isOk: boolean) => {
    if (!isOk) {
      setVisible(true);
      return;
    }
    const confirmResult = await modalMethod.confirm(
      <>
        세금계산서 확인이 완료되었으며 해당금액으로 정산이 진행됩니다.
        <br />
        계속 진행하시겠습니까?
      </>,
      { okText: '확인', cancelText: '취소' }
    );
    if (confirmResult) {
      const params: IEvidenceState = { issueStateCode: 3, cancellationReason: null };
      mutate({ storeId: selectedStore.sid, evidenceIdx: location.state.evidenceSeq, params });
    }
  };

  const handlePDFDownload = async () => {
    if (data.settleIssueState === '발급대기') {
      const confirmResult = await modalMethod.confirm(
        <>
          계산서 확인이 완료 시 되어야 PDF 다운로드가 가능합니다.
          <br />
          확인처리 후 다운로드 진행하시겠습니까?
        </>,
        { okText: '확인', cancelText: '취소' }
      );
      if (confirmResult) {
        const params: IEvidenceState = { issueStateCode: 3, cancellationReason: null };
        mutate({ storeId: selectedStore.sid, evidenceIdx: location.state.evidenceSeq, params });
      }
    }
    if (data.settleIssueState !== '발급대기') {
      download();
    }
  };

  const handleClose = () => {
    setVisible(false);
  };

  const handleClickBack = () => {
    navigate(-1);
  };

  const idx = { storeId: selectedStore.sid, evidenceIdx: location?.state?.evidenceSeq };
  const { evidenceItems, receiver, provider } = useMemo(() => {
    const evidences = data?.evidenceItems.map((item) => {
      const month = moment(data.publishDateYmd).format('MM');
      const day = moment(data.publishDateYmd).format('DD');
      return { ...item, month, day };
    });
    return { evidenceItems: evidences, receiver: data?.receiver, provider: data?.provider };
  }, [data]);

  return (
    <Wrapper>
      <SelectBox>
        <div className="display-flex gap">
          <span className="field-label">유형</span>
          <span className="field-input">{data?.taxInvoiceType}</span>
          <span className="field-label">식별코드</span>
          <span className="field-input">{data?.evidenceSeq}</span>
          <span className="field-label">품목명</span>
          <span className="field-input">{data?.evidenceItems[0].itemName}</span>
          <span className="field-label">발급상태</span>
          <span className="field-input" style={{ color: data?.settleIssueState === '발급거절' && 'red' }}>
            {data?.settleIssueState}
          </span>
        </div>
        <img id="image" alt="" />
        {data?.taxInvoiceTypeCode === 'PAPER' && data?.settleIssueState === '발급대기' && (
          <div className="button-group display-flex gap">
            <Button className="approval" onClick={() => handleStateChange(true)}>
              승인
            </Button>
            <Button className="lineBtn" onClick={() => handleStateChange(false)}>
              거절
            </Button>
          </div>
        )}
      </SelectBox>
      <div id="pdf">
        <div className="display-flex">
          <FormWrapper>
            <div className="title">세금계산서</div>
            <div className="display-flex">
              <div className="target">
                공<br />급<br />자
              </div>
              <Form layout="inline">
                <div className="display-flex">
                  <Form.Item label="등록번호">{provider?.providerBizSerial}</Form.Item>
                  <Form.Item label="종사업장">{provider?.providerBizSubSerial}</Form.Item>
                </div>
                <div className="display-flex">
                  <Form.Item label={`상호\n(법인명)`}>{provider?.providerBizName}</Form.Item>
                  <Form.Item label="성명">{provider?.providerChargeName}</Form.Item>
                </div>
                <div className="display-flex">
                  <Form.Item label={`사업장\n주소`}>{provider?.providerAddress}</Form.Item>
                </div>
                <div className="display-flex">
                  <Form.Item label="업태">{provider?.providerBizCondition}</Form.Item>
                  <Form.Item label="종목">{provider?.providerBizType}</Form.Item>
                </div>
                <div className="display-flex">
                  <Form.Item label="이메일">{provider?.providerEmail}</Form.Item>
                </div>
              </Form>
            </div>
          </FormWrapper>
          <FormWrapper>
            <div className="display-flex">
              <div className="title-field">{data?.taxInvoiceTypeCode === 'PAPER' ? '일련번호' : '승인번호'}</div>
              <div className="title-input">{data?.ntsSendKey || '-'}</div>
            </div>
            <div className="display-flex">
              <div className="target">
                공<br />급<br />받<br />는<br />자
              </div>
              <Form layout="inline">
                <div className="display-flex">
                  <Form.Item label="등록번호">{receiver?.receiverBizSerial}</Form.Item>
                  <Form.Item label="종사업장">{receiver?.receiverBizSubSerial}</Form.Item>
                </div>
                <div className="display-flex">
                  <Form.Item label={`상호\n(법인명)`}>{receiver?.receiverBizName}</Form.Item>
                  <Form.Item label="성명">{receiver?.receiverChargeName}</Form.Item>
                </div>
                <div className="display-flex">
                  <Form.Item label={`사업장\n주소`}>{receiver?.receiverAddress}</Form.Item>
                </div>
                <div className="display-flex">
                  <Form.Item label="업태">{receiver?.receiverBizCondition}</Form.Item>
                  <Form.Item label="종목">{receiver?.receiverBizType}</Form.Item>
                </div>
                <div className="display-flex">
                  <Form.Item label="이메일">{receiver?.receiverEmail}</Form.Item>
                </div>
              </Form>
            </div>
          </FormWrapper>
        </div>
        <StyleTable key="evidence" columns={evidenceColumns} data={[data]} />
        <StyleTable key="item" columns={itemColumns} data={evidenceItems} />
        <StyleTable key="amount" columns={amountColumns} data={[data]} />
      </div>
      <div className="btn-group">
        <Button className="lineBtn" onClick={handleClickBack}>
          목록으로
        </Button>
        {data?.taxInvoiceTypeCode === 'PAPER' && (
          <Button className="pdfBtn" onClick={handlePDFDownload}>
            PDF 다운로드
          </Button>
        )}
      </div>
      <TaxRefuseModal isVisible={visible} close={handleClose} idx={idx} />
    </Wrapper>
  );
};

export default TaxInvoice;
