import styled from 'styled-components';
import { Radio } from 'antd';

interface StyledRadioButtonWithCheckboxShapeProps {
  $fullWidth?: boolean;
  $color?: 'reverse';
}

export const StyledRadioButtonWithCheckboxShape = styled(Radio.Button)<StyledRadioButtonWithCheckboxShapeProps>`
  & {
    width: ${({ $fullWidth }) => ($fullWidth ? '100%' : null)};
  }
  &.ant-radio-button-wrapper {
    border-radius: 4px;
    font-size: 20px;
    font-weight: 700;
    padding: 19px 8px 19px 16px;
    line-height: 22px;
    height: 65px;
    &:first-child:last-child {
      border-radius: 4px;
    }
  }
  &.ant-radio-button-wrapper-checked {
    background-color: ${({ $color, theme }) => ($color === 'reverse' ? theme.color.primary : null)};
    color: ${({ $color, theme }) => ($color === 'reverse' ? theme.color.white : 'rgba(0, 0, 0, 0.85)')};
    & .checked-color {
      color: ${({ $color, theme }) => ($color === 'reverse' ? theme.color.white : theme.color.primary)};
    }
  }
  & > span:last-child {
    display: flex;
    align-items: flex-start;
  }
`;

export const FullWidthRadioGroup = styled(Radio.Group)`
  & {
    width: 100%;
  }
`;
