import styled from 'styled-components';

export const Container = styled.div`
  background-color: #f2f4f6;
  height: 100%;
  height: 100vh;
  width: 100vw;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 768px) {
    align-items: start;
  }
`;

export const Content = styled.div`
  font-size: 18px;
  width: 100%;
  text-align: left;
  padding-right: 16px;
  padding-left: 16px;
  max-width: 375px;
  @media screen and (max-width: 768px) {
    padding-top: 20px;
  }

  & {
    .custom-channel-container {
      text-align: right;
      height: 76px;
    }
    .login-logo {
      margin-bottom: 28px;
      & img {
        width: 142px;
        height: 21px;
      }
      .mode {
        margin-left: 8px;
        text-decoration: underline;
      }
    }
    button {
      height: 65px;
      font-size: 24px;
      font-weight: 700;
      color: #ffffff;
      width: 100%;
      border-radius: 4px;
    }
    a {
      color: #6d7582;
      font-size: 16px;
    }
    .username {
      margin-bottom: 8px;
      & input {
        height: 65px;
        padding: 18.5px 8px 18.5px 16px;
        font-size: 18px;
      }
    }
    .password {
      margin-bottom: 18px;
      & input {
        height: 50px;
      }
    }
    .remember-wrapper {
      margin-bottom: 0;
    }
    .remember {
      margin-bottom: 18px;
      font-size: 18px;
      font-weight: normal;
      color: #6d7582;
    }
    .ant-checkbox {
      ms-transform: scale(1.25);
      -moz-transform: scale(1.25);
      -webkit-transform: scale(1.25);
      -o-transform: scale(1.25);
      font-size: 18px;
      top: 0.09em;
    }
    .go-to {
      text-align: center;
    }
    .ant-divider {
      color: #cccccc;
      margin: 0 16px;
    }
  }
`;
