/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from 'react';
import { Modal, Row, Col, Button, Input, Select, Spin } from 'antd';
import { LoadingOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { IDefaultData } from 'interfaces/booking';

import { useDeliveryInfo, useOrderIndices, useAgentCall, useExpectFee } from 'hooks';
import { AgentsCallContainer, LoadingModal, AgentModal } from '../styles';
import QuantityPicker from './QuantityPicker';

interface RiderCallModalProps {
  open: boolean;
  close: (b?) => void;
  searchParams: IDefaultData;
}

const RiderCallModal = ({ open, close, searchParams }: RiderCallModalProps) => {
  const [menus, setMenus] = useState<Array<object>>([]);
  const [resetMenus, setResetMenus] = useState<Array<object>>([]);
  const [time, setTime] = useState<{ hour: number; minute: string }>({ hour: 0, minute: '00' });
  const [storeInfo, setStoreInfo] = useState<{ cellPhone: string; additionalCellPhone: string }>({
    cellPhone: null,
    additionalCellPhone: null
  });
  const [feeInfo, setFeeInfo] = useState<{ expectFee: string; diff: number | string }>({ expectFee: '-', diff: '-' });
  const [agents, setAgents] = useState<object>({});
  const [isReset, setReset] = useState<boolean>(false);

  const [hourArray, setHourArray] = useState([]);
  const [minuteArray, setMinuteArray] = useState([]);
  const [agentCount, setAgentCount] = useState<number>(0);

  const { data } = useDeliveryInfo(searchParams, open);
  const { data: orderInfo } = useOrderIndices({ ...searchParams, agentCount }, open);
  const { mutateAsync, isLoading } = useAgentCall();
  const { mutateAsync: postExpectFee, isLoading: loading } = useExpectFee();

  const times = useMemo(() => {
    const minute = Math.ceil(moment().add(20, 'm').minute() / 5) * 5;
    const addMinute = minute === 60 ? 25 : 20;

    const accHour = moment().add(addMinute, 'm').hour();
    const curHour = moment().add(addMinute, 'm').add(1, 'h').hour();
    const defaultTime = moment().add(20, 'm');

    const scroll = document.getElementsByClassName('ant-modal-body');

    if (scroll.length > 0) {
      for (let i = 0; i < scroll.length; i += 1) {
        setTimeout(() => scroll[i].scrollTo(0, 0), 100);
      }
    }
    return { accHour, curHour, defaultTime };
  }, [open]);

  const deliveryInfo = useMemo(() => {
    if (!data) {
      return undefined;
    }
    const newMenus = [];
    data?.booking?.menus.forEach((menu) => {
      for (let i = 0; i < menu.quantity; i += 1) {
        newMenus.push({ ...menu, quantity: 1 });
      }
    });

    const { previousInfo, cellPhone } = data.store;

    newMenus.sort((a, b) => b.amount - a.amount);
    setMenus(newMenus);
    setResetMenus(newMenus);
    setStoreInfo({
      ...storeInfo,
      cellPhone: previousInfo?.previousCellPhone ? previousInfo?.previousCellPhone : cellPhone,
      additionalCellPhone: previousInfo?.previousAdditionalCellPhone
    });

    return data;
  }, [data, isReset]);

  useEffect(() => {
    const newAgents = {};
    const count = {};
    const menuList = resetMenus.slice();

    for (let j = 0; j < agentCount; j += 1) {
      if (!count[j]) {
        count[j] = 0;
      }

      for (let i = j; i < menuList.length; i += agentCount) {
        count[j] += 1;
      }
    }

    for (let j = 0; j < agentCount; j += 1) {
      if (!newAgents[`agent${j + 1}`]) {
        newAgents[`agent${j + 1}`] = [];
      }
      newAgents[`agent${j + 1}`] = menuList.splice(0, count[j]);
    }

    setAgents(newAgents);
    setReset(!isReset);
  }, [agentCount]);

  const hourContent = () => {
    const result: number[] = [];
    const current = times.accHour;
    for (let i = current; i < 24; i += 1) {
      result.push(i);
    }
    setHourArray(result);
  };

  const minuteContent = () => {
    const result: number[] = [];
    for (let i = 0; i < 60; i += 5) {
      result.push(i);
    }
    setMinuteArray(result);
  };

  useEffect(() => {
    if (open) {
      const minute = Math.ceil(moment().add(20, 'm').minute() / 5) * 5;
      setTime({
        hour: minute === 60 ? moment().add(20, 'm').add(1, 'h').hour() : moment().add(20, 'm').hour(),
        minute: String(minute === 60 ? 0 : minute).padStart(2, '0')
      });
      minuteContent();
      hourContent();

      setAgents({});
      setAgentCount(0);
      setFeeInfo({ expectFee: '-', diff: '-' });
    } else {
      setStoreInfo({ cellPhone: null, additionalCellPhone: null });
    }
  }, [open]);

  const onIncrement = (obj, key) => {
    let menuList = menus.slice();
    if (!menuList || menuList.length <= 0) {
      menuList = resetMenus.slice();
    }

    obj[key].push(menuList[0]);
    menuList.splice(0, 1);

    setAgents({ ...agents, ...obj });
    setMenus(menuList);
  };

  const onDecrement = (key) => {
    const menuList = menus.slice();
    const newAgents = { ...agents };
    if (newAgents[key].length > 1) {
      const acc = newAgents[key][newAgents[key].length - 1];

      newAgents[key].pop();
      menuList.unshift(acc);

      setAgents({ ...agents, ...newAgents });
      setMenus(menuList);
    }
  };

  const onValueChange = (name: string, value) => {
    if (name === 'agentCount') {
      setAgentCount(value);
    } else if (name === 'hour' || name === 'minute') {
      setTime({ ...time, [name]: value });
    } else if (name === 'additionalCellPhone' || name === 'cellPhone') {
      setStoreInfo({ ...storeInfo, [name]: value });
    } else if (name === 'increment') {
      onIncrement(agents, value);
    } else if (name === 'decrement') {
      onDecrement(value);
    }
  };

  const onCancel = async () => {
    Modal.confirm({
      content: <>입력하신 정보가 저장되지 않습니다. 정말 닫으시겠습니까?</>,
      icon: null,
      cancelText: '취소',
      okText: '확인',
      style: { top: 'calc(50% - 200px)' },
      onOk: async () => {
        close();
      }
    });
  };

  const openAlert = (content, isClose) => {
    Modal.info({
      content,
      icon: null,
      okText: '확인',
      onOk: () => {
        if (isClose) {
          close(true);
        }
      },
      className: 'info-handler-modal',
      style: { top: 'calc(50% - 200px)' }
    });
  };

  const onExpectFee = async () => {
    const newArray: object[] = [];
    Object.entries(agents).forEach(([, v]) => {
      newArray.push({ quantity: v.length });
    });

    try {
      const { expectFee } = await postExpectFee({ searchParams, agents: newArray });
      const cur = moment(`${time.hour}:${time.minute}`, 'HH:mm');
      const diff = moment.duration(cur.diff(moment())).asMinutes();
      setFeeInfo({ expectFee, diff: Math.floor(diff) });
    } catch (error) {
      const { response } = error as { response: { data: Record<string, unknown> } };
      if (response) {
        await openAlert(response.data.message, false);
      }
    }
  };

  const onSubmit = async () => {
    const newAgents: { orderIdx: string; userId: string; shippingIndices: Array<object>; items: Array<object> }[] = [];
    const { booking } = deliveryInfo;
    const { orderIndices } = orderInfo;
    const { hour, minute } = time;

    Object.entries(agents).forEach(([, v], index) => {
      const order = booking.bookingInfo[index] ? booking.bookingInfo[index] : booking?.bookingInfo[0];
      const menuGroup = {};
      const shippingIndices = [];
      v.forEach((menu) => {
        if (!menuGroup[menu.menuId]) {
          shippingIndices.push({ shippingIdx: menu.shippingIdx });
          menuGroup[menu.menuId] = {
            amount: menu.amount,
            menuId: menu.menuId,
            menuName: menu.menuName,
            quantity: 0
          };
        }
        menuGroup[menu.menuId].quantity += 1;
      });

      const uniqueArr = shippingIndices.filter((item, i) => {
        return (
          shippingIndices.findIndex((item2) => {
            return item.shippingIdx === item2.shippingIdx;
          }) === i
        );
      });

      newAgents.push({
        orderIdx: orderIndices[index].orderIdx,
        userId: order?.leaderId,
        shippingIndices: uniqueArr,
        items: Object.values(menuGroup)
      });
    });

    const bool = newAgents.filter(({ items }) => items.length <= 0)[0];
    if (bool) {
      openAlert(
        <>
          총 메뉴 수량보다 많은 라이더를 선택하여
          <br />
          메뉴가 할당되지 않은 라이더가 존재합니다.
          <br />
          확인 후 다시 호출해주세요.
        </>,
        false
      );
      return;
    }

    const regPhone = /^[0-9]{2,3}[0-9]{3,4}[0-9]{4}/;
    if (
      !storeInfo.cellPhone ||
      !regPhone.test(storeInfo.cellPhone) ||
      storeInfo.cellPhone.length > 11 ||
      (storeInfo.additionalCellPhone &&
        (storeInfo.additionalCellPhone.length > 11 || !regPhone.test(storeInfo.additionalCellPhone)))
    ) {
      await openAlert('전화번호를 확인해 주세요.', false);
      return;
    }
    const obj = {
      store: storeInfo,
      pickupAt: moment(`${hour}:${minute}`, 'HH:mm'),
      agents: newAgents
    };
    try {
      await mutateAsync({ sid: searchParams.sid, data: obj });
      await openAlert(
        <>
          픽업 요청이 완료되었습니다.
          <br />
          배차실패시에는 문자로 안내드리며 재요청만 가능합니다.
        </>,
        true
      );
    } catch (error) {
      const { response } = error as { response: { data: Record<string, unknown> } };
      if (response) {
        await openAlert(response.data.message, false);
      }
    }
  };

  return (
    <AgentModal
      className="agentModal"
      visible={open}
      title="픽업 요청"
      onCancel={onCancel}
      width={861}
      zIndex={100}
      closable={false}
      footer={[
        <Button key="back" onClick={onCancel} danger>
          취소
        </Button>,
        <Button
          key="call"
          className="request"
          onClick={onSubmit}
          loading={isLoading}
          disabled={!storeInfo.cellPhone || !(agentCount > 0)}
        >
          요청하기
        </Button>
      ]}
    >
      <AgentsCallContainer>
        <div className="info">
          <div className="title">배달 정보</div>

          <div className="borderBox">
            <div className="companyInfo">
              <span className="title">{deliveryInfo?.company.companyName}</span>
              <span className="serviceTime">{deliveryInfo?.booking.serviceTime} 까지 배달</span>
            </div>
            <div className="addressGroup">
              <span>
                {deliveryInfo?.spot.roadAddress}
                {', '}
                {deliveryInfo?.spot.roadAddressDetail}
              </span>
              <span className="addressDetail">{deliveryInfo?.spot.spotName}</span>
            </div>
            <div>
              <span>총 {deliveryInfo?.booking.quantity}개</span>
              <span className="totalAmount">{deliveryInfo?.booking.totalAmount.toLocaleString()}원</span>
            </div>
          </div>
        </div>

        <div className="pickupDate">
          <div className="title">
            <span>픽업을 원하시는 시간</span>을 선택해주세요
          </div>
          <Row gutter={20}>
            <Col span={7}>
              <div className="desc">시</div>
              <div className="borderBox">
                <div className="hour">
                  <Button
                    onClick={() => onValueChange('hour', times.accHour)}
                    className={time.hour === times.accHour ? 'focus' : ''}
                  >
                    {String(times.accHour).padStart(2, '0')}
                  </Button>
                  <Button
                    onClick={() => onValueChange('hour', times.curHour)}
                    className={time.hour === times.curHour ? 'focus' : ''}
                  >
                    {String(times.curHour).padStart(2, '0')}
                  </Button>
                </div>

                <Select
                  value={time.hour <= times.curHour ? 0 : time.hour}
                  onChange={(e) => onValueChange('hour', e)}
                  className="hour-select"
                >
                  <Select.Option value={0}>직접선택</Select.Option>
                  {hourArray.map((item) => (
                    <Select.Option value={item} key={item}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </Col>
            <Col span={17}>
              <div className="desc">분</div>
              <div className="borderBox">
                <Row gutter={[32, 16]}>
                  {minuteArray.map((m) => {
                    const laterMinute = Math.ceil(times.defaultTime.minute() / 5) * 5;
                    const disabled = time.hour > times.defaultTime.hour() ? false : laterMinute > m;
                    const stringMinute = String(m).padStart(2, '0');
                    return (
                      <Col span={4} key={m}>
                        <Button
                          disabled={disabled}
                          onClick={() => onValueChange('minute', stringMinute)}
                          className={time.minute === stringMinute ? 'focus' : ''}
                        >
                          {stringMinute}
                        </Button>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </Col>
          </Row>
        </div>

        <div className="riderCnt">
          <div className="title">
            <span>필요하신 라이더 수</span>를 선택해주세요
          </div>
          <div className="desc">명</div>
          <div className="borderBox">
            <Button onClick={() => onValueChange('agentCount', 1)} className={agentCount === 1 ? 'focus' : ''}>
              1
            </Button>
            <Button onClick={() => onValueChange('agentCount', 2)} className={agentCount === 2 ? 'focus' : ''}>
              2
            </Button>
            <Button onClick={() => onValueChange('agentCount', 3)} className={agentCount === 3 ? 'focus' : ''}>
              3
            </Button>
            <Button onClick={() => onValueChange('agentCount', 4)} className={agentCount === 4 ? 'focus' : ''}>
              4
            </Button>
            <Button onClick={() => onValueChange('agentCount', 5)} className={agentCount === 5 ? 'focus' : ''}>
              5
            </Button>
          </div>
        </div>

        <div className="quantity">
          <div className="title">
            <span>배달수량</span>을 선택해주세요
          </div>
          <div className="guide">수량에 따라 메뉴 금액이 높은 순으로 배분되어 금액이 자동 할당됩니다.</div>

          <div className="quantityGroup">
            <QuantityPicker disabled={agentCount < 1} onClick={onValueChange} orderIdx="agent1" value={agents} />
            <QuantityPicker disabled={agentCount < 2} onClick={onValueChange} orderIdx="agent2" value={agents} />
            <QuantityPicker disabled={agentCount < 3} onClick={onValueChange} orderIdx="agent3" value={agents} />
            <QuantityPicker disabled={agentCount < 4} onClick={onValueChange} orderIdx="agent4" value={agents} />
            <QuantityPicker disabled={agentCount < 5} onClick={onValueChange} orderIdx="agent5" value={agents} />
          </div>
        </div>

        <div>
          <div className="title">
            배차실패 시, 문자로 안내 받으실 수 있습니다. 연락받으실 휴대폰 번호를 확인해주세요. (최대 2명)
          </div>
          <Row gutter={20} className="cellPhone">
            <Col span={12}>
              <Input
                value={storeInfo.cellPhone}
                onChange={({ target }) => onValueChange('cellPhone', target.value)}
                onWheel={(e) => e.currentTarget.blur()}
                type="number"
                allowClear
              />
            </Col>
            <Col span={12}>
              <Input
                placeholder="(선택)"
                value={storeInfo.additionalCellPhone}
                onChange={({ target }) => onValueChange('additionalCellPhone', target.value)}
                onWheel={(e) => e.currentTarget.blur()}
                type="number"
                allowClear
              />
            </Col>
          </Row>

          <div className="fareBox">
            <div className="fare">
              <ExclamationCircleOutlined />
              기사님이
              <span className={typeof feeInfo.diff === 'number' ? 'bold' : ''}> {feeInfo.diff}</span>분 후 픽업 예정이며,
              예상운임은
              <span className={typeof feeInfo.diff === 'number' ? 'bold' : ''}> {feeInfo.expectFee.toLocaleString()}</span>
              입니다.
            </div>

            <Button onClick={onExpectFee} type="primary" disabled={!(agentCount > 0)} loading={loading} ghost>
              예상운임 확인하기
            </Button>
          </div>
        </div>
      </AgentsCallContainer>

      <LoadingModal visible={isLoading} onCancel={() => {}} width={480} footer={null} closable={false} zIndex={1000}>
        <Spin indicator={<LoadingOutlined spin />} />
        <div className="content">접수 확인 중 입니다. 잠시만 기다려주세요.</div>
      </LoadingModal>
    </AgentModal>
  );
};

export default RiderCallModal;
