import { paramsToFormData } from 'utils/apiUtils';
import { storeClient } from './apiClients';

export const getShippingProduct = async (couponId: string) => {
  const { data } = await storeClient.get(`shipping/v1/product/${couponId}`);
  return data;
};

export const getShipping = async (params) => {
  const { data } = await storeClient.get(`shipping/v1`, { params });
  return data;
};
export const getShippingLogistics = async () => {
  const { data } = await storeClient.get(`shipping/v1/logistics`);
  return data?.contents;
};

export const saveShipping = async (saveData: unknown) => {
  const { data } = await storeClient.put(`shipping/v1`, saveData);
  return data;
};
export const saveTrackingAndLogistics = async (couponId: string, saveData: unknown) => {
  const { data } = await storeClient.put(`shipping/v1/product/${couponId}`, saveData);
  return data;
};
export const uploadTrackingNumberExcel = async (excelFileFormData: FormData) => {
  const { data } = await storeClient.post(`shipping/v1/trackingNumberExcel`, excelFileFormData);
  return data;
};

export const downloadShippingExcelDown = async (params) => {
  const { data } = await storeClient.post(`shipping/v1/shippingExcelDown`, paramsToFormData(params));
  return data;
};
