import React from 'react';
import styled from 'styled-components';
import { Modal, ModalProps } from 'antd';
import { cssButton } from 'pages/RobotDelivery/styles';

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 20px;
    .ant-modal-header {
      padding: 10px 28px;
      border-radius: 20px 20px 0 0;
      .ant-modal-title {
        font-size: 32px;
        font-weight: 600;
        line-height: 40px;
      }
    }
    .ant-modal-body {
      padding: 24px 25px;
    }
    .ant-modal-footer {
      display: flex;
      justify-content: center;
      gap: 100px;
      padding: 3px 24px 24px;
      text-align: center;
      border-top: none;
      .ant-btn {
        ${cssButton};
        margin: 0;
      }
      .ant-btn.ant-btn-default,
      .ant-btn.ant-btn-default:hover,
      .ant-btn.ant-btn-default:focus {
        background: #212529;
      }
    }
  }
`;

interface DefaultModalProps extends ModalProps {
  children?: React.ReactNode;
  closable?: boolean;
  cancellable?: boolean;
  cancelText?: string;
  isOkButtonDisabled?: boolean;
}

const DefaultModal = ({
  children,
  closable,
  cancellable,
  cancelText = '닫기',
  isOkButtonDisabled = false,
  ...modalProps
}: DefaultModalProps) => {
  const buttonProps: ModalProps = {
    okText: '확인',
    okButtonProps: { disabled: isOkButtonDisabled },
    cancelText,
    cancelButtonProps: { style: { display: cancellable && 'none' } }
  };
  return (
    <StyledModal width={980} closable={closable ?? false} {...buttonProps} {...modalProps}>
      {children}
    </StyledModal>
  );
};

export default DefaultModal;
