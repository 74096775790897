import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { RouteModal } from 'components';
import { StepLayout } from 'layouts';
import { ContentPadding24, BottomButtonArea } from 'styles';

const Complete = () => {
  const navigate = useNavigate();

  const onOk = () => {
    navigate(-1);
  };

  return (
    <RouteModal title="사업자 정보 작성">
      <ContentPadding24>
        <StepLayout
          desc={
            <>
              <p>신청 서류가</p>
              <p>정상적으로 제출되었습니다!</p>
            </>
          }
          subDesc={
            <>
              <div>입점 심사는 최대 2주 소요됩니다.</div>
              <div>심사후 연락드리겠습니다.</div>
            </>
          }
          content={<div />}
          bottom={
            <BottomButtonArea>
              <Button type="primary" onClick={onOk}>
                확인
              </Button>
            </BottomButtonArea>
          }
        />
      </ContentPadding24>
    </RouteModal>
  );
};

export default Complete;
