/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import $ from 'jquery';
import React, { useMemo, useState } from 'react';
import { Select } from 'antd';
import moment from 'moment';

import icCheckDefault from 'images/other/ic-check-default@3x.png';
import icCheckSelected from 'images/other/ic-check-selected@3x.png';

import MenuTable from 'pages/Menu/MenuTable';
import { isMobile } from 'utils';
import { MenuListWrapper, MenuBoardWrapper } from 'pages/Menu/Style';
import { useShortcut } from 'hooks';
import MenuUpdateModal from './MenuUpdateModal';
import NullData from './NullData';
import Banner from './Banner';

const MenuList = ({ data, category, totalCount, update }) => {
  const [checkedItems, setCheckItems] = useState<Set<string>>(new Set());
  const [filter, setFilter] = useState<string>('ALL');
  const [menuName, setMenuName] = useState<string>('');
  const [modal, setModal] = useState({ isVisible: false, message: null, statusMsg: null, mids: null, status: null });
  const { data: shortcutInfo } = useShortcut();
  const { webBanners } = shortcutInfo || {};
  const webBanner = webBanners?.filter(
    (el) => moment(el.startDate).isBefore(moment()) && moment(el.endDate).isAfter(moment())
  )[0];
  const { name: bannerName, webImageFileUrl, linkUrl } = webBanner || {};
  const isWebBannerExpired = moment(webBanner?.endDate).isBefore(moment());

  const menuData = useMemo(() => {
    if (!data) {
      return [];
    }
    if (filter !== 'ALL') {
      return data.filter(({ status, name }) => status === filter && name.indexOf(menuName) >= 0);
    }
    return data.filter(({ name }) => name.indexOf(menuName) >= 0);
  }, [data, filter, menuName]);

  const handleSearchClick = (e) => {
    if (e.type === 'click' || e.keyCode === 13) {
      const elem = document.getElementById('menuSearchInput') as HTMLInputElement;
      setMenuName(elem.value);
    }
  };

  const handleStatusChange = (value) => {
    setFilter(value);
  };

  const handleUpdate = async (params) => {
    await update(params);
    setCheckItems(new Set());
  };

  const checkAll = (e: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLTableCellElement>) => {
    e.stopPropagation();
    if (!checkedItems.size) {
      setCheckItems(new Set(menuData.map(({ id }) => id)));
    } else {
      const items = new Set(checkedItems);
      items.clear();
      setCheckItems(items);
    }
  };

  const checkCategory = (name) => {
    // e.stopPropagation();
    const categoriesMenuItems = new Set<string>(menuData.filter(({ category: c }) => name === c).map(({ id }) => id));
    const intersection = new Set([...checkedItems].filter((e) => categoriesMenuItems.has(e)));
    if (!intersection.size) {
      setCheckItems(new Set([...checkedItems, ...categoriesMenuItems]));
    } else {
      const difference = new Set([...checkedItems].filter((i) => !categoriesMenuItems.has(i)));
      setCheckItems(difference);
    }
  };

  const showModal = (message, statusMsg, mids, status) => {
    setModal({ isVisible: true, message, statusMsg, mids, status });
  };

  const closeModal = () => {
    setModal({ isVisible: false, message: null, statusMsg: null, mids: null, status: null });
  };

  const updateCheckModal = (status) => {
    if (checkedItems.size === 0) {
      showModal(`판매 상태를 변경할 메뉴를 먼저 선택해 주세요.`, null, null, null);
      return;
    }

    let activeCnt = 0;
    let inActiveCnt = 0;
    let soldOutCnt = 0;

    checkedItems.forEach((menuId) => {
      menuData
        .filter(({ id }) => menuId === id)
        .forEach((menu) => {
          if (menu.status === 'ACTIVE') {
            activeCnt += 1;
          } else if (menu.status === 'INACTIVE') {
            inActiveCnt += 1;
          } else {
            soldOutCnt += 1;
          }
        });
    });

    let message;
    if (status === 'ACTIVE') {
      message = `선택한 메뉴를 판매중 상태로 변경하시겠습니까? 변경 시 해당 메뉴 주문이 가능합니다.`;
    } else if (status === 'INACTIVE') {
      message = `선택한 메뉴를 판매종료 상태로 변경하시겠습니까? 변경 시 해당 메뉴는 주문 받을 수 없습니다.`;
    } else {
      message = `선택한 메뉴를 일시품절 상태로 변경하시겠습니까? 변경 시 해당 메뉴는 주문 받을 수 없습니다.`;
    }
    const statusMsg = `(선택한 메뉴 : 판매중 ${activeCnt}, 일시품절 ${soldOutCnt}, 판매종료 ${inActiveCnt})`;

    showModal(message, statusMsg, Array.from(checkedItems), status);
  };

  const activeUpdate = () => {
    updateCheckModal('ACTIVE');
  };

  const inActiveUpdate = () => {
    updateCheckModal('INACTIVE');
  };

  const soldOutUpdate = () => {
    updateCheckModal('SOLD_OUT');
  };

  const mobileSelect = (name) => {
    const items = new Set(checkedItems);
    if (checkedItems.has(name)) {
      items.delete(name);
    } else {
      items.add(name);
    }
    setCheckItems(items);
    if (isMobile()) {
      const divBox = $(`div[name=${name}]`);
      if (divBox.hasClass('back-color-green')) {
        divBox.removeClass('back-color-green');
        $(`input:checkbox[id=${name}]`).prop('checked', false);
        $(`#mobile-select-icon${name}`).attr('src', icCheckDefault);
      } else {
        divBox.addClass('back-color-green');
        $(`input:checkbox[id=${name}]`).prop('checked', true);
        $(`#mobile-select-icon${name}`).attr('src', icCheckSelected);
      }
    }
  };

  return (
    <MenuListWrapper>
      <MenuBoardWrapper>
        <MenuUpdateModal
          isVisible={modal.isVisible}
          onOk={handleUpdate}
          onClose={closeModal}
          message={modal.message}
          statusMsg={modal.statusMsg}
          status={modal.status}
          mids={modal.mids}
        />
        <div className="menu-salesStats-info media-display-none">
          메뉴별 판매 상태를 설정 할 수 있습니다.
          <br />
          메뉴를 선택한 후 우측 상단의 변경할 상태 버튼(일시품절하기/판매종료하기/판매하기)을 선택해주세요.
          <br />
          (일시품절하기 : 메뉴 리스트에 노출되나 주문 불가, 판매종료 : 메뉴 리스트에 미노출)
          <br />
          메뉴 등록/변경 요청은 메인화면 오른쪽 하단의 1:1문의 요청 주시면 순차적으로 도와드리고 있습니다.
        </div>
        <div className="ele2 media-display-none">
          <table className="menu-header-table media-display-none">
            <thead>
              <tr>
                <th colSpan={3}>
                  <div className="menu-button-box media-display-none">
                    <span className="menu-search-box">
                      <Select className="menu-status-select form-control" defaultValue="ALL" onChange={handleStatusChange}>
                        <Select.Option value="ALL">전체</Select.Option>
                        <Select.Option value="ACTIVE">판매중</Select.Option>
                        <Select.Option value="SOLD_OUT">일시품절</Select.Option>
                        <Select.Option value="INACTIVE">판매종료</Select.Option>
                      </Select>
                      <span className="menu-search-box">
                        <input
                          id="menuSearchInput"
                          placeholder="메뉴명"
                          type="text"
                          className="form-control margin-left-5px"
                          onKeyDown={handleSearchClick}
                        />
                        <button type="button" onClick={handleSearchClick} className="btn btn-success">
                          검색
                        </button>
                      </span>
                    </span>
                    <span>
                      <button type="button" onClick={activeUpdate} className="btn btn-success margin-left-5px">
                        판매하기
                      </button>
                      <button type="button" onClick={soldOutUpdate} className="btn btn-warning margin-left-5px">
                        일시품절하기
                      </button>
                      <button type="button" onClick={inActiveUpdate} className="btn btn-danger margin-left-5px">
                        판매종료하기
                      </button>
                    </span>
                  </div>
                </th>
              </tr>
              <tr className="menu-table-header media-display-none">
                <td className="menu-check-box">
                  <input type="checkbox" onChange={checkAll} checked={checkedItems.size === data?.length} />
                </td>
                <td className="padding-left-10px font-color-white" onClick={checkAll}>
                  <span className="font-size-18px">메뉴명</span>
                </td>
                <td className="menu-status-box font-color-white" onClick={checkAll}>
                  <span className="font-size-18px">판매상태</span>
                </td>
              </tr>
            </thead>
          </table>
        </div>

        <div className="scroll-box">
          <table className="menu-salesStats-table media-display-none" id="menu-table">
            {totalCount === 0 ? (
              <NullData />
            ) : (
              <MenuTable
                category={category}
                data={menuData}
                checkedItems={checkedItems}
                checkCategory={checkCategory}
                mobileSelect={mobileSelect}
              />
            )}
          </table>
        </div>
      </MenuBoardWrapper>
      {!isWebBannerExpired ? (
        <div className="banner-wrapper">
          <div className="style-div" />
          <Banner bannerName={bannerName} linkUrl={linkUrl} webImageFileUrl={webImageFileUrl} />
        </div>
      ) : null}
    </MenuListWrapper>
  );
};

export default MenuList;
