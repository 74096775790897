/* eslint-disable react/no-danger */
import React, { useMemo } from 'react';
import { useTerm } from 'hooks';
import { Rectangle } from './style';

const TermContainer = ({ type }) => {
  const { data } = useTerm(type);

  const contents = useMemo(() => {
    if (!data?.termList?.length) {
      return null;
    }
    if (type === 'SERVICE' || type === 'PRIVACY' || type === 'STORE_SIGNUP_SERVICE') {
      const [{ content }] = data.termList.slice(-1);
      return content;
    }

    const [{ content }] = data.termList;
    return content;
  }, [data, type]);

  return (
    <Rectangle>
      <div dangerouslySetInnerHTML={{ __html: contents }} />
    </Rectangle>
  );
};

export default TermContainer;
