import { useCallback, useRef } from 'react';
import { useMutation, useQuery, useQueries, useQueryClient } from 'react-query';
import {
  getRobotSpots,
  getRobotOrders,
  getRobotOrderDetail,
  getRobotSettings,
  getRobotSchedule,
  updateStoreOpen,
  getAggregateOrders,
  saveRobotOrderAggregate,
  deleteRobotOrder
} from 'apis/robot';
import {
  IAggregateOrder,
  IOrderDetail,
  IRobotOrders,
  IRobotSchedules,
  IRobotSettings,
  IRobotSpots,
  TSchedule,
  IRobotOrderCancelParams
} from 'interfaces/robot';
import moment from 'moment';
import { Modal } from 'antd';
import { errorHandler } from 'apis';

export default function useThrottle<T extends any[]>(callback: (...params: T) => void, time: number) {
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);
  return (...params: T) => {
    if (!timer.current) {
      callback(...params);
      timer.current = setTimeout(() => {
        timer.current = null;
      }, time);
    }
  };
}

// 제휴점 로봇배달 배송지 목록 조회
export const useRobotSpots = () => {
  return useQuery<IRobotSpots>(['getRobotSpots'], () => getRobotSpots());
};

// 제휴점 설정 정보 조회
export const useRobotSettings = () => {
  return useQuery<IRobotSettings>(['getRobotSettings'], () => getRobotSettings());
};

// 로봇배달 가능 스캐쥴 조회
export const useRobotSchedule = (isRefetch: boolean) => {
  return useQuery<IRobotSchedules>(['getRobotSchedule', isRefetch], () => getRobotSchedule(), {
    enabled: isRefetch
  });
};

// 로봇배달 주문 받기 on/off
export const useUpdateStoreOpen = () => {
  const queryClient = useQueryClient();
  return useMutation(['updateStoreOpen'], (isOpen: boolean) => updateStoreOpen(isOpen), {
    onSuccess: async () => {
      await queryClient.invalidateQueries('getRobotSettings');
    }
  });
};

// 로봇배달 주문 취소
export const useRobotOrderCancel = () => {
  const queryClient = useQueryClient();
  return useMutation(['deleteRobotOrder'], (params: IRobotOrderCancelParams) => deleteRobotOrder(params), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['order', 'getRobotOrders']);
      Modal.info({
        content: `주문이 취소되었습니다.`,
        icon: null,
        okText: '확인',
        className: 'info-handler-modal'
      });
    },
    onError: async (error) => {
      await queryClient.invalidateQueries(['order', 'getRobotOrders']);
      errorHandler(error);
    }
  });
};

// 로봇배달 주문 상세
export const useRobotOrderDetail = () => {
  return useMutation<IOrderDetail, unknown, string, unknown>(['getRobotOrderDetail'], (couponId: string) =>
    getRobotOrderDetail(couponId)
  );
};

// 로봇배달 합배송
export const useRobotOrderAggregate = (schedule?: TSchedule, onSuccess?: () => void, onError?: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(['saveRobotOrderAggregate'], (params: any) => saveRobotOrderAggregate(params), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['order', 'getRobotOrders']);
      await queryClient.invalidateQueries(['order', 'ACCEPT']);
      onSuccess?.();
      Modal.info({
        content: `주문이 확정되었습니다. (${schedule?.deliveryStartTime} 출발 예정)`,
        icon: null,
        okText: '확인',
        className: 'info-handler-modal'
      });
    },
    onError: async (error: any) => {
      await queryClient.invalidateQueries(['order', 'getRobotOrders']);
      await queryClient.invalidateQueries(['getRobotSpots']);
      if (error) {
        const { response } = error;
        if (response.status === 500 || response.status === 400 || response.status === 401) {
          const { status } = response.data;
          if (status === -2610) {
            onError?.();
          }
        }
      }
      errorHandler(error);
    }
  });
};

type TRobotOrders = {
  robotOrders?: IRobotOrders;
  robotAggregateOrders?: IAggregateOrder;
  robotDeliveringOrders?: IAggregateOrder;
  robotCompleteOrders?: IAggregateOrder;
  refetchAll?: () => void;
  dataUpdatedAt?: number;
};
export const useRobotOrders = (requestDate: string): TRobotOrders => {
  const options = { enabled: !!requestDate, refetchInterval: 60000 };
  const results = useQueries([
    // 로봇배달 일자별 주문요청 & 주문취소 내역 조회
    { queryKey: ['order', 'getRobotOrders', requestDate], queryFn: () => getRobotOrders(requestDate), ...options },
    // 배달중 탭, 배달완료 & 픽업 탭 조회 API
    { queryKey: ['order', 'ACCEPT', requestDate], queryFn: () => getAggregateOrders(requestDate, 'ACCEPT'), ...options },
    { queryKey: ['order', 'DELIVERING', requestDate], queryFn: () => getAggregateOrders(requestDate, 'DELIVERING'), ...options },
    { queryKey: ['order', 'COMPLETE', requestDate], queryFn: () => getAggregateOrders(requestDate, 'COMPLETE'), ...options }
  ]);
  const refetchAll = useCallback(() => {
    results.forEach((result) => result.refetch());
  }, [results]);
  const throttleRefetchAll = useThrottle(refetchAll, 5000);

  const dataUpdatedAt = results.reduce((updated, cur) => {
    return updated < cur.dataUpdatedAt ? cur.dataUpdatedAt : updated;
  }, moment().valueOf());

  const [robotOrders, robotAggregateOrders, robotDeliveringOrders, robotCompleteOrders] = results;
  return {
    robotOrders: robotOrders.data,
    robotAggregateOrders: robotAggregateOrders.data,
    robotDeliveringOrders: robotDeliveringOrders.data,
    robotCompleteOrders: robotCompleteOrders.data,
    refetchAll: throttleRefetchAll,
    dataUpdatedAt
  };
};
