import errorHandler from 'apis/errorHandler';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getMyInfo, getPushToken, getStoreAdmin, getToken, getXUserAgent } from 'utils/storages';
import { getAuthHost, getStoreHost } from './host';

const { CancelToken } = axios;
const soruce = CancelToken.source();

const authReqFulfilledInterceptor = (config: AxiosRequestConfig) => {
  const pushToken = getPushToken();
  const token = getToken();
  const reConfig = { ...config };

  reConfig.cancelToken = soruce.token;

  const headers = reConfig.headers || {};
  if (pushToken) {
    headers['X-Push-Token'] = getXUserAgent();
  }
  if (token && config.method?.toUpperCase() === 'PUT') {
    headers.Authorization = `Bearer ${token}`;
  }
  reConfig.headers = {
    ...config.headers,
    ...headers
  };

  return reConfig;
};

export const authClient = axios.create({
  baseURL: getAuthHost(),
  headers: { 'X-User-Agent': getXUserAgent(), 'Content-Type': 'application/json' }
});

export const storeClient = axios.create({
  baseURL: getStoreHost(),
  headers: { 'X-User-Agent': getXUserAgent() }
});

export const authClientWithoutRequestInterceptor = axios.create({
  baseURL: getAuthHost(),
  headers: { 'X-User-Agent': getXUserAgent() }
});

// Info: authClient response, request 설정
authClient.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  async (error) => {
    errorHandler && errorHandler(error);
    return Promise.reject(error);
  }
);

authClient.interceptors.request.use(authReqFulfilledInterceptor, (error) => Promise.reject(error));

// Info: storeClient response, request 설정
storeClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

storeClient.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const reConfig = { ...config };
    const headers = reConfig.headers || {};

    const token = getToken();
    const meInfo = getMyInfo();
    const storeAdmin = getStoreAdmin();

    if (token) headers.Authorization = `Bearer ${token}`;

    if (meInfo) {
      headers['X-Store-gsid'] = meInfo.sid;
    } else if (storeAdmin) {
      headers['X-Store-gsid'] = storeAdmin.said;
    }

    reConfig.cancelToken = soruce.token;
    reConfig.headers = {
      ...config.headers,
      ...headers
    };

    return reConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Info: authClientWithoutRequestInterceptor request 설정
authClientWithoutRequestInterceptor.interceptors.request.use(authReqFulfilledInterceptor, (error) => Promise.reject(error));

export const mockFetcher = {
  get(url: string, delay?: any) {
    const reUrl = `mock/${url}.json`;
    return new Promise<{ data: unknown }>((resolve, reject) => {
      fetch(reUrl, {
        method: 'GET'
      })
        .then((res) => res.json())
        .then((data) => {
          setTimeout(() => {
            resolve({ data });
          }, delay || 0);
        })
        .catch((e) => reject(e));
    });
  }
};

const apis = {
  authClient,
  storeClient
};

export default apis;
