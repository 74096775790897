/* eslint-disable react/no-danger */
import React from 'react';
import { useAdmissionServiceTerm } from 'hooks';
import { Rectangle } from './style';

const AdmissionServiceTermContainer = ({ admissionIdx, supplyType }) => {
  const { data } = useAdmissionServiceTerm(admissionIdx, supplyType);

  return (
    <Rectangle>
      <div dangerouslySetInnerHTML={{ __html: data?.content }} />
    </Rectangle>
  );
};

export default AdmissionServiceTermContainer;
