/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button, Checkbox, Divider, Form, Input } from 'antd';
import { useMagicCode, useRemember } from 'hooks';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
// import { isMobileDevice, sendBridge } from 'utils/clientUtils';
import logo from 'images/N_Logo.svg';

export interface LoginProps {
  loading: boolean;
  onLogin: (v: any) => void;
}

const Login = ({ loading, onLogin }: LoginProps) => {
  const [form] = Form.useForm();
  const [code] = useMagicCode();
  const [autoLogin, setAutoLogin] = useRemember();
  const formItemLayout = {
    labelCol: {
      span: 24
      // xs: { span: 24 },
      // sm: { span: 6 }
    },
    wrapperCol: {
      span: 24
      // xs: { span: 24 },
      // sm: { span: 14 }
    }
  };

  useEffect(() => {
    form?.setFieldValue('remember', autoLogin);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const changeMode = () => {
    const username = form.getFieldValue('username');
    if (username === 'gitmerge') {
      alert('지원하지 않는 기능입니다');
      // const isMobile = isMobileDevice();
      // if (isMobile) {
      //   const sv = prompt('Please enter servername');
      //   sendBridge('changeURL', sv);
      //   if (sv !== null) {
      //     setCode(sv);
      //     alert('saved.');
      //   } else {
      //     setCode('');
      //   }
      // } else if (code) {
      //   setCode('');
      //   alert('restore');
      // } else {
      //   const sv = prompt('Please enter servername');
      //   if (sv !== null) {
      //     setCode(sv);
      //     alert('saved.');
      //   }
      // }
    }
  };

  return (
    <>
      <div className="login-logo">
        <img src={logo} alt="login logo" onClick={changeMode} />
        <b className="mode">{code ? `# MODE: ${code}` : ''}</b>
      </div>
      <Form form={form} autoComplete="off" onFinish={onLogin} {...formItemLayout}>
        <Form.Item id="username" className="username" name="username">
          <Input placeholder="아이디를 입력해주세요" />
        </Form.Item>
        <Form.Item id="password" className="password" name="password">
          <Input.Password placeholder="비밀번호를 입력해주세요" />
        </Form.Item>
        <Form.Item className="remember-wrapper" name="remember" valuePropName="checked">
          <Checkbox className="remember" onChange={() => setAutoLogin(!autoLogin)}>
            자동 로그인
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            로그인
          </Button>
        </Form.Item>
        <Form.Item className="go-to">
          <Link to="/forgotSignId">아이디 찾기</Link>
          <Divider type="vertical" />
          <Link to="/forgotPassword">비밀번호 찾기</Link>
          <Divider type="vertical" />
          <Link to="/agreeSignUp">회원가입</Link>
        </Form.Item>
      </Form>
    </>
  );
};

export default Login;
