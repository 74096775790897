import React from 'react';
import moment from 'moment';
import { List } from 'components';
import { FlexBoxCenter, FlexBoxFullWidthCenter } from 'styles';
import { ListItem } from '../styles';

const historyType = {
  ADMISSION: '입점 신청',
  BIZ: '사업자등록증 변경',
  BANK_ACCOUNT: '계좌정보 변경'
};

const AdmissionStatus = {
  APPLYING: '신청중',
  PREPARATION: '대기',
  COMPLETE: '완료',
  IMPROVE: '보완',
  REJECT: '거절',
  HOLD: '보류'
};

const AdmissionHistory = ({ admissionHistory, onClick }) => {
  return (
    <List
      items={admissionHistory}
      renderItem={({ idx, type, storeName, regDate, status, updateDate, reason }) => (
        <ListItem key={idx} onClick={() => onClick(status, reason, type, idx)} disabled={status === 'COMPLETE'}>
          <FlexBoxCenter className="top-desc" justify="space-between">
            <div>요청일: {moment(regDate).format('YYYY.MM.DD HH:mm')}</div>
            <div>{historyType[type]}</div>
          </FlexBoxCenter>
          <FlexBoxCenter justify="space-between">
            <FlexBoxFullWidthCenter direction="column" align="flex-start" gap="6px">
              <div>{updateDate ? `등록일: ${moment(updateDate).format('YYYY.MM.DD HH:mm')}` : '-'}</div>
              <FlexBoxFullWidthCenter justify="space-between">
                <div className="point">{storeName}</div>
                <div className="point">({AdmissionStatus[status]})</div>
              </FlexBoxFullWidthCenter>
              <div>{status !== 'COMPLETE' ? '자세히보기' : '완료된 신청입니다.'}</div>
            </FlexBoxFullWidthCenter>
          </FlexBoxCenter>
        </ListItem>
      )}
    />
  );
};

export default AdmissionHistory;
