// import React from 'react';
import { Modal } from 'antd';
import { getToken } from 'utils/storages';
import { logout } from './auth';

const logoutClose = async () => {
  const token = getToken();
  if (token) {
    await logout(token);
  }
};

export const errorBind = (status) => {
  /* -2306 : 토큰시간초과, -2302 : 인증정보없음, -2303 : 인증정보만료, -2304 : 토큰정보가 없음 */
  const data = {
    '-2300': {
      // -2300 : 다시로그인
      redirect: '/',
      stopBubble: true,
      close: logoutClose
    },
    '-2302': {
      // -2302 : 인증정보없음
      redirect: '/',
      stopBubble: true,
      close: logoutClose
    },
    '-2303': {
      // -2303 : 인증정보만료
      redirect: '/',
      stopBubble: true,
      close: logoutClose
    },
    '-2304': {
      // -2304 : 토큰정보가 없음
      redirect: '/',
      stopBubble: true,
      close: logoutClose
    },
    '-2306': {
      // -2306 : 토큰시간초과
      open: false,
      refresh: true
    }
  };
  return data[status];
};

const route = ({ redirect, routeTo }) => {
  if (redirect) {
    window.location.href = redirect;
    // eslint-disable-next-line no-empty
  } else if (routeTo) {
  }
};

const handler = (error) => {
  const { response } = error;
  if (response.status === 500 || response.status === 400 || response.status === 401) {
    const { status, message: content, error: responseError, title } = response.data;
    const bindError = errorBind(status);
    if (bindError) {
      const { redirect, routeTo, open = true, close, nothing } = bindError;
      if (nothing) return;

      if (open) {
        Modal.warning({
          title,
          content: responseError?.message || content || error?.message,
          // icon: <ExclamationCircleOutlined />,
          okText: '확인',
          className: 'error-handler-modal',
          // cancelButtonProps: { style: { display: 'none' } },
          onOk: async () => {
            if (close) await close();
            route({ redirect, routeTo });
          }
        });
      } else {
        // if(close) close();
        // route({redirect, routeTo})
        Modal.warning({
          title,
          content: responseError?.message || content || error?.message,
          // icon: <ExclamationCircleOutlined />,
          okText: '확인',
          className: 'error-handler-modal'
          // cancelButtonProps: { style: { display: 'none' } },
          // onOk: () => {}
        });
      }
    } else {
      Modal.warning({
        // title: 'error',
        content: responseError?.message || content || error?.message,
        icon: null,
        okText: '확인',
        className: 'error-handler-modal'
        // cancelButtonProps: { style: { display: 'none' } },
        // onOk: () => {}
      });
    }
  }
};

export default handler;
