import styled from 'styled-components';
import { Button, Modal } from 'antd';

export const StoreSelectContainer = styled.div`
  button {
    width: 100%;
  }
  .ant-space {
    display: flex;
  }
`;

export const AdmissionButton = styled(Button)`
  border-color: ${({ theme }) => theme.color.gray01};
  background-color: ${({ theme }) => theme.color.gray01};
  color: ${({ theme }) => theme.color.white};
  &:hover,
  :active,
  :focus {
    border-color: ${({ theme }) => theme.color.black};
    background-color: ${({ theme }) => theme.color.black};
    color: ${({ theme }) => theme.color.white};
  }
`;

export const SettleModal = styled(Modal)`
  color: #353c49;
  .ant-modal-header .ant-modal-title {
    font-size: 22px;
    font-weight: 700;
  }
  .ant-modal-body {
    font-size: 16px;
    .bold {
      font-weight: 700;
    }
  }
  .ant-modal-footer .ant-btn-primary {
    width: 100px;
    border-radius: 4px;
  }
`;
