import React, { useEffect } from 'react';
import moment from 'moment';
import { Skeleton } from 'antd';
import { numberWithCommas } from 'utils/stringUtils';
import { generate } from 'shortid';

interface PayInfoModalProps {
  open: boolean;
  isLoading?: boolean;
  close?: () => void;
  data?;
  menus?;
  shippingFeeInfos?;
  supplyType?;
}

const PayInfoModal = ({ open, close, data, menus, shippingFeeInfos, supplyType, isLoading }: PayInfoModalProps) => {
  /*
			시현으로 인한 회사명 미노출 코드
		 */
  // const id = sessionStorage.getItem('storeAdminId');
  // const testid = 'hvendys';
  useEffect(() => {
    if (open === true) {
      // TODO delete bootstrap
      // eslint-disable-next-line
      // @ts-ignore
      $('#payModal').modal('show');
    } else if (open === false) {
      // TODO delete bootstrap
      // eslint-disable-next-line
      // @ts-ignore
      $('#payModal').modal('hide');
    }
  }, [open]);
  useEffect(() => {
    // TODO delete bootstrap
    // eslint-disable-next-line
    // @ts-ignore
    $('#payModal').on('hidden.bs.modal', () => {
      close();
    });
  }, [close]);

  const stringmember = data?.couponType === 'SINGLE' ? '혼자결제' : '함께결제';
  const couponStatus = data?.paymentStatus === 'USED' ? '결제완료' : '결제취소';
  const totalPrice = numberWithCommas(data?.totalPrice);

  const menusTotal = menus && menus.length > 0 ? menus.reduce((acc, cur) => acc + cur.count * cur.price, 0) : 0;
  const shippingFeeTotal =
    shippingFeeInfos && shippingFeeInfos.length > 0 ? shippingFeeInfos.reduce((acc, cur) => acc + cur.totalFee, 0) : 0;

  return (
    <div id="payModal" className="modal fade" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content payModal">
          <div className="modal-header">
            <h4 className="modal-title">상세 정보</h4>
          </div>
          <div className="modal-body">
            {isLoading ? (
              <Skeleton paragraph={{ rows: 7 }} active />
            ) : (
              <div className="container-fluid">
                <div className="form-group row">
                  <label className="col-sm-2 col-form-label">결제번호</label>
                  <div className="col-sm-4">{data?.payRoomIdx}</div>
                  <label className="col-sm-2 col-form-label">결제자</label>
                  <div className="col-sm-4">{data?.leaderName}</div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-2 col-form-label">결제일시</label>
                  <div className="col-sm-4">
                    {data?.useDate ? moment(data.useDate * 1000).format('YYYY-MM-DD HH:mm:ss') : null}
                  </div>
                  <label className="col-sm-2 col-form-label">사용형태</label>
                  <div className="col-sm-4">{stringmember}</div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-2 col-form-label">회사명</label>
                  <div className="col-sm-4">{data?.comName}</div>
                  <label className="col-sm-2 col-form-label">상태</label>
                  <div className="col-sm-4">{couponStatus}</div>
                </div>
                <hr />
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">메뉴/상품 금액</label>
                </div>
                <table className="table menuTable min-height-auto table-hover">
                  <thead className="menuHead">
                    <tr>
                      <th scope="col">메뉴/상품명</th>
                      <th scope="col">수량</th>
                      <th scope="col">결제금액</th>
                    </tr>
                  </thead>
                  <tbody>
                    {menus && menus.length > 0 ? (
                      menus.map((row) => {
                        const { menuId, menuName, price, count } = row;
                        return (
                          <tr className="table-row" key={menuId}>
                            <td>{menuName}</td>
                            <td>{numberWithCommas(count)}</td>
                            <td>{numberWithCommas(price)}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td className="no-content" colSpan={3}>
                          검색 결과가 없습니다.
                        </td>
                      </tr>
                    )}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={2} />
                      <td>{numberWithCommas(menusTotal)}</td>
                    </tr>
                  </tfoot>
                </table>
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">배송비</label>
                </div>
                <table className="table menuTable min-height-auto table-hover">
                  <thead className="menuHead">
                    <tr>
                      <th scope="col">배송비</th>
                      <th scope="col">기본 배송비</th>
                      <th scope="col">조건부 추가배송비</th>
                      <th scope="col">제주/도서 추가배송비</th>
                      <th scope="col">결제금액</th>
                    </tr>
                  </thead>
                  <tbody>
                    {shippingFeeInfos && shippingFeeInfos.length > 0 ? (
                      shippingFeeInfos.map((row) => {
                        const { feeConditionName, defaultFee, conditionalFee, addressFee, totalFee } = row;
                        return (
                          <tr className="table-row" key={generate()}>
                            <td>{feeConditionName || '-'}</td>
                            <td>{defaultFee !== null ? numberWithCommas(defaultFee) : '-'}</td>
                            <td>{conditionalFee !== null ? numberWithCommas(conditionalFee) : '-'}</td>
                            <td>{addressFee !== null ? numberWithCommas(addressFee) : '-'}</td>
                            <td>{totalFee !== null ? numberWithCommas(totalFee) : '-'}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="table-row">
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                      </tr>
                    )}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={4} />
                      <td>
                        {typeof shippingFeeTotal !== 'undefined' && shippingFeeTotal !== null && supplyType === 7
                          ? numberWithCommas(shippingFeeTotal)
                          : '없음'}
                      </td>
                    </tr>
                  </tfoot>
                </table>
                <div className="form-group row menutotal">
                  <label className="col-sm-4 col-form-label name">결제금액</label>
                  <div className="col-sm-8 price total-price">{totalPrice}</div>
                </div>
              </div>
            )}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-sikdae" data-dismiss="modal">
              확인
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayInfoModal;
