import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const TabWrapper = styled.div`
  .select-type {
    background-color: white;
    height: 47px;
    display: flex;

    .tab-nav {
      cursor: pointer;
      width: 100%;
      line-height: 47px;
      text-align: center;
      font-size: 18px;
      display: inline-block;
      border-left: 1px solid #dcdcdc;
      border-bottom: 1px solid #dcdcdc;
      &.active {
        color: #46b476;
        background-color: white;
        font-weight: bold;
        border-bottom: 3px solid #46b476 !important;
      }
      &:last-child {
        border-right: 1px solid #dcdcdc;
      }
    }
    .tab-content {
      display: none;
      &.active {
        display: block;
      }
    }
  }
`;
