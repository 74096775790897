import React from 'react';
import moment from 'moment';
import styled from 'styled-components';

const StyledTable = styled.table`
  width: 100%;
  min-width: 100%;
  th,
  td {
    border: 1px solid #e2e2e2;
    background: #fff;
    padding: 15px 8px;
    text-align: center;
    white-space: pre-wrap;
  }
`;

const PaymentStatsTable = ({ data }) => {
  return (
    <StyledTable>
      <thead>
        <tr>
          <th rowSpan={2}>날짜</th>
          <th rowSpan={2}>고객사</th>
          <th rowSpan={2}>정산방식</th>
          <th>결제금액(가)</th>
          <th colSpan={2}>품목금액(나)</th>
          <th colSpan={4}>결제수단별 금액(다)</th>
          <th rowSpan={2}>식사수</th>
          <th rowSpan={2}>취소수</th>
          <th rowSpan={2}>{`수수료\n상계여부`}</th>
          <th colSpan={2}>수수료</th>
        </tr>
        <tr>
          <th>사용자결제금액(A)</th>
          <th>메뉴판매금액(B)</th>
          <th>배송비(C)</th>
          <th>대장쿠폰(D)</th>
          <th>식대포인트(E)</th>
          <th>대장포인트(F)</th>
          <th>간편결제(G)</th>
          <th>과금방식</th>
          <th>요율</th>
        </tr>
      </thead>
      <tbody>
        {data?.length ? (
          data.map((item, index) => {
            const key = index;
            const userPriceAmount =
              item.companySettleAmount + item.captainPointAmount + item.couponAmount + item.captainPayAmount;
            const menuPriceAmount = userPriceAmount - item.deliveryAmount;
            const date = `${moment(item.salesRecordFrom).format('YYYY. MM. DD')} ~ ${moment(item.salesRecordTo).format(
              'YYYY. MM. DD'
            )}`;
            return (
              <tr key={key}>
                <td>{date}</td>
                <td>{item.companyName}</td>
                <td>{item.settleType}</td>
                <td>{userPriceAmount.toLocaleString()}</td>
                <td>{menuPriceAmount.toLocaleString()}</td>
                <td>{item.deliveryAmount.toLocaleString()}</td>
                <td>{item.couponAmount.toLocaleString()}</td>
                <td>{item.mealAmount.toLocaleString()}</td>
                <td>{item.captainPointAmount.toLocaleString()}</td>
                <td>{item.captainPayAmount.toLocaleString()}</td>
                <td>{item.paymentCount.toLocaleString()}</td>
                <td>{item.cancelCount.toLocaleString()}</td>
                <td>{item.evidenceOffsetFlag ? '상계' : '별도'}</td>
                <td>{item.feeType}</td>
                <td>{item.feeTypeCode !== 2 ? `${item.serviceMealRate}%` : '-'}</td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan={15}>데이터가 존재하지 않습니다.</td>
          </tr>
        )}
      </tbody>
    </StyledTable>
  );
};

export default PaymentStatsTable;
