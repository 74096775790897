import { Input } from 'antd';
import React, { useState } from 'react';
import { useTheme } from 'styled-components';
import { Checkbox } from 'components/Checkbox';
import { FlexBoxFullWidthCenter } from 'styles';

interface AccountHolderProps {
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeCheck?: () => void;
  disabled?: boolean;
  initValue?: string;
}

const AccountHolder = ({ onChange, value, onChangeCheck, disabled, initValue }: AccountHolderProps) => {
  const [is, setCheck] = useState<boolean>(false);
  const theme = useTheme();
  const handleCheck = () => {
    const result = onChangeCheck?.();
    if (typeof result === 'boolean') {
      setCheck(result);
    }
  };
  return (
    <FlexBoxFullWidthCenter gap="8px">
      <Input
        placeholder="예금주명(대표자 또는 사업자명의) 입력"
        onChange={onChange}
        value={value}
        disabled={disabled || (initValue && !is)}
        maxLength={10}
      />
      {onChangeCheck ? (
        <Checkbox
          disabled={disabled || !initValue}
          onChange={() => handleCheck()}
          checked={is}
          $backgroundColor="transparent"
          style={{
            flex: '0 0 108px',
            height: 65,
            padding: '10px 4px',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            border: `1px solid ${theme.color.gray04}`,
            borderRadius: 4,
            color: `${is === true ? theme.color.primary : theme.color.black}`
          }}
        >
          본인명과 다름
        </Checkbox>
      ) : null}
    </FlexBoxFullWidthCenter>
  );
};

export default AccountHolder;
