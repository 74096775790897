import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { handlerKeyUp } from 'utils';
import { TabWrapper } from './styles';

interface TabsProps {
  items: { label: string; key: string | number; content?: React.ReactNode }[];
  onChange?: (key: string | number) => void;
  defaultActive?: string | number;
}

const Tabs = ({ items, onChange, defaultActive }: TabsProps) => {
  const [active, setActive] = useState<number | string>(0);

  useEffect(() => {
    if (!items?.length) {
      return;
    }
    const [{ key, content }] = items;
    if (typeof defaultActive === 'string' || typeof defaultActive === 'number') {
      setActive(defaultActive);
      return;
    }
    if (content) setActive(key);
  }, [items, defaultActive]);

  const handleTabClick = (key: number | string) => {
    setActive(key);
    onChange?.(key);
  };
  return (
    <TabWrapper>
      <div className="select-type">
        {items && items.length > 0
          ? items.map(({ label, key }) => (
              <div
                key={key}
                className={classNames('tab-nav', { active: active === key })}
                onClick={() => handleTabClick(key)}
                onKeyUp={handlerKeyUp(() => handleTabClick(key))}
                role="tab"
                tabIndex={0}
              >
                {label}
              </div>
            ))
          : null}
      </div>
      <div className="tab-content">
        {items && items.length > 0
          ? items.map(
              ({ content, key }) =>
                content && (
                  <div key={key} className={classNames('tab-content', { active: active === key })}>
                    {content}
                  </div>
                )
            )
          : null}
      </div>
    </TabWrapper>
  );
};

export default Tabs;
