import React from 'react';
import { Chart } from 'react-google-charts';

const ColumnChart = ({ data }) => {
  const options = { legend: 'none', colors: ['#61C282'], vAxis: { format: '#,### 만원' } };

  const height = '450px';
  return <Chart chartType="ColumnChart" data={data} options={options} graph_id="ScatterChart" width="100%" height={height} />;
};

export default ColumnChart;
