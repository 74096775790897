import { ChangeEvent, useState } from 'react';

export const useForm = <T extends Record<string, unknown>>(
  initialValue?: T
): [T | undefined, (e: ChangeEvent<HTMLInputElement>) => void, (v: Partial<T>) => void] => {
  const [values, setValues] = useState<T | undefined>(initialValue);

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const {
      target: { name, value, checked }
    } = e;
    const changeValue = { [name]: value ?? checked };
    setValues((v) => ({ ...v, ...changeValue }) as T);
  };

  const onChangeValues = (newVal: Partial<T>): void => {
    setValues((v) => ({ ...v, ...newVal }) as T);
  };

  return [values, onChange, onChangeValues];
};
