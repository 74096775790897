import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useMeInfo, useBankCodes } from 'hooks';
import { MaxWidthContent } from 'styles';
import { setPassCertification } from 'utils';

import { modalMethod } from 'components';
import { SettlementContainer } from './styles';
import BizInfo from './TabContents/BizInfo';
import BankAccount from './TabContents/BankAccount';
import FeeInfo from './TabContents/FeeInfo';
import PersonInChargeOfSettlement from './TabContents/PersonInChargeOfSettlement';

const Settlement = () => {
  const typeClassName1 = 'type1';
  const typeClassName2 = 'type2';
  const typeClassName3 = 'type3';
  const typeClassName4 = 'type4';

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { meInfo, selectedStore } = useMeInfo();

  const { data: bankCodes } = useBankCodes();

  const [type, setType] = useState('type1');

  const handleTabClick = async (active) => {
    if (selectedStore?.meInfoCurrent?.grade === 'STAFF') {
      modalMethod.warning('직원은 볼 수 없는 화면입니다.');
      return;
    }
    setType(active);
  };

  useEffect(() => {
    if (!meInfo?.admin.isCertification) {
      setPassCertification(meInfo?.admin?.id, false);
      navigate('/');
      return;
    }
    const tab = searchParams.get('tab');
    if (tab === '2') {
      handleTabClick(typeClassName1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTabKeyUp = async (e, active) => {
    const key = e.key || e.keyCode;
    if (key === 'Enter' || key === 13) {
      await handleTabClick(active);
    }
  };

  return (
    <SettlementContainer>
      <div>
        <div className="settlement-info-content" id="settlementMetaInfo">
          <div className="selectType">
            <div
              id="selectType"
              role="tab"
              tabIndex={0}
              className={`${typeClassName1}${type === typeClassName1 ? ' active' : ''}`}
              onClick={() => handleTabClick(typeClassName1)}
              onKeyUp={(e) => handleTabKeyUp(e, typeClassName1)}
            >
              사업자
            </div>
            <div
              id="selectType1"
              role="tab"
              tabIndex={0}
              className={`${typeClassName2}${type === typeClassName2 ? ' active' : ''}`}
              onClick={() => handleTabClick(typeClassName2)}
              onKeyUp={(e) => handleTabKeyUp(e, typeClassName2)}
            >
              계좌
            </div>
            <div
              id="selectType2"
              role="tab"
              tabIndex={0}
              className={`${typeClassName3}${type === typeClassName3 ? ' active' : ''}`}
              onClick={() => handleTabClick(typeClassName3)}
              onKeyUp={(e) => handleTabKeyUp(e, typeClassName3)}
            >
              정산 담당자
            </div>
            <div
              id="selectType3"
              role="tab"
              tabIndex={0}
              className={`${typeClassName4}${type === typeClassName4 ? ' active' : ''}`}
              onClick={() => handleTabClick(typeClassName4)}
              onKeyUp={(e) => handleTabKeyUp(e, typeClassName4)}
            >
              수수료
            </div>
          </div>
        </div>

        <div className="settlement-detail-content">
          {type !== typeClassName4 ? (
            <MaxWidthContent>
              {type === typeClassName1 ? <BizInfo /> : null}
              {type === typeClassName2 ? <BankAccount bankCodes={bankCodes?.bankCodeList} /> : null}
              {type === typeClassName3 ? <PersonInChargeOfSettlement /> : null}
            </MaxWidthContent>
          ) : null}
          {type === typeClassName4 ? <FeeInfo /> : null}
          {/* </div> */}
        </div>
      </div>
    </SettlementContainer>
  );
};

export default Settlement;
