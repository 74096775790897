import { useSaveTrackingAndLogistics, useShippingProduct } from 'hooks';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';

import { numberWithCommas } from 'utils/stringUtils';

const ShippingOrderManagementModal = ({ couponId, logistics = [], logisticsMap, close }) => {
  const trackingNumberRef = useRef<HTMLInputElement>(null);
  const { data } = useShippingProduct(couponId);
  const { mutateAsync: save } = useSaveTrackingAndLogistics();
  const [state, setState] = useState<any>({
    couponId: '',
    payRoomIdx: '',
    comName: '',
    userName: '',
    paymentPrice: 0,
    paymentDate: moment(),
    trackingNumber: '',
    logisticsIdx: '',
    logisticsName: '',
    status: '',
    menu: '',
    recipient: '',
    tel: '',
    address: '',
    shippingMemo: '',
    saveBtn: false,
    menus: [],
    shippingFeeInfos: []
  });

  useEffect(() => {
    if (!data) {
      return;
    }
    const {
      coupon: { couponId: id, payRoomIdx, comName, userName, paymentPrice, paymentDate, couponType, menu },
      shipping: {
        trackingNumber,
        logisticsIdx,
        recipient,
        tel,
        zoneCode,
        jibunAddress,
        roadAddress,
        addressDetail,
        shippingLocation,
        shippingMemo,
        userSelectedType
      },
      status: { statusCode, statusName },
      menus,
      shippingFeeInfo
    } = data;

    let shippingLocationAndMemo = shippingLocation || shippingMemo || '';
    if (shippingLocation && shippingMemo) {
      shippingLocationAndMemo = `${shippingLocation} | ${shippingMemo}`;
    }
    const flatData = {
      couponId: id,
      payRoomIdx,
      comName,
      userName,
      paymentPrice,
      couponType,
      paymentDate: moment(paymentDate),
      trackingNumber: trackingNumber || '',
      logisticsIdx: logisticsIdx || '',
      statusCode,

      statusName,
      menu,
      recipient,
      zoneCode,
      tel,
      address: `${userSelectedType === 'R' ? roadAddress : jibunAddress} ${addressDetail}`,
      shippingMemo: shippingLocationAndMemo,
      menus,
      shippingFeeInfos: [shippingFeeInfo]
    };
    setState(flatData);
  }, [data]);

  const handleFieldChange = ({ target }) => {
    const { name, value } = target;
    if (name === 'logisticsIdx') {
      logistics
        .filter((e) => !e.isUseTrackingNumber)
        .forEach((e) => {
          if (e.idx === value) {
            setState((v) => ({ ...v, trackingNumber: '-' }));
            // trackingNumberRef.current?.disabled = true;
            // this.refs.trackingNumberRef.disabled = true;
          } else {
            // trackingNumberRef?.current?.disabled = false;
            // this.refs.trackingNumberRef.disabled = false;
          }
        });
    }

    setState((v) => ({ ...v, [name]: value }));
  };

  const saveTrackingAndLogistics = async () => {
    const { couponId: id, trackingNumber, logisticsIdx } = state;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line no-bitwise
    if (!trackingNumber ^ !logisticsIdx) {
      alert('송장번호와 택배사가 모두 입력되어야 합니다. 다시 확인 부탁드립니다.');
      return;
    }
    const dirtyData = {
      trackingNumber,
      logisticsIdx
    };
    // eslint-disable-next-line no-restricted-globals
    if (confirm('저장하시겠습니까?')) {
      setState((v) => ({ ...v, saveBtn: true }));
      await save({
        couponId: id,
        saveData: dirtyData
      });
      close();
      setState((v) => ({ ...v, saveBtn: false }));
      // network.HTTPManagerWithJson(
      //   'PUT',
      //   `shipping/v1/product/${couponId}`,
      //   dirtyData,
      //   function () {
      //     this.setState({ saveBtn: false });
      //     close();
      //   }.bind(this),
      //   function (xhr, status, error) {
      //     this.setState({ saveBtn: false });
      //     console.log(xhr);
      //   }.bind(this)
      // );
    }
  };

  const handleModalClose = () => {
    setState(() => ({
      couponId: '',
      payRoomIdx: '',
      comName: '',
      userName: '',
      paymentPrice: 0,
      paymentDate: moment(),
      trackingNumber: '',
      logisticsIdx: '',
      logisticsName: '',
      status: '',
      menu: '',
      recipient: '',
      tel: '',
      address: '',
      shippingMemo: '',
      saveBtn: false,
      menus: [],
      shippingFeeInfos: []
    }));
    close();
  };

  const {
    payRoomIdx,
    comName,
    userName,
    paymentPrice,
    couponType,
    paymentDate,
    trackingNumber,
    logisticsIdx,
    statusCode,
    statusName,
    recipient,
    tel,
    address,
    zoneCode,
    shippingMemo,
    saveBtn,
    menus,
    shippingFeeInfos
  } = state;

  const menusTotal = menus && menus.length > 0 ? menus.reduce((acc, cur) => acc + cur.count * cur.sellPrice, 0) : 0;
  const shippingFeeTotal =
    shippingFeeInfos && shippingFeeInfos.length > 0 ? shippingFeeInfos.reduce((acc, cur) => acc + cur.totalFee, 0) : 0;

  // const sumCount = menus && menus.length > 0 ? menus.reduce((acc, cur) => acc + cur.count, 0) : 0;

  let isDisabled = false;
  logistics
    .filter((e) => !e.isUseTrackingNumber)
    .forEach((e) => {
      if (e.idx === Number(logisticsIdx)) {
        isDisabled = true;
      }
    });

  return (
    <div className="modal fade" id="shippingModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              배송 상품 주문 관리
            </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleModalClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form className="shipping-modal-form">
              <div className="form-group row">
                <label className="col-sm-2 col-form-label">결제번호</label>
                <div className="col-sm-4">{payRoomIdx}</div>
                <label className="col-sm-2 col-form-label">결제자</label>
                <div className="col-sm-4">{userName}</div>
              </div>
              <div className="form-group row">
                <label className="col-sm-2 col-form-label">결제일시</label>
                <div className="col-sm-4">{moment(paymentDate).format('YYYY-MM-DD HH:mm:ss')}</div>
                <label className="col-sm-2 col-form-label">사용형태</label>
                <div className="col-sm-4">{couponType}</div>
              </div>
              <div className="form-group row">
                <label className="col-sm-2 col-form-label">회사명</label>
                <div className="col-sm-4">{comName}</div>
                <label className="col-sm-2 col-form-label">상태</label>
                <div className="col-sm-4">{statusName}</div>
              </div>
              <hr />
              <h3>배송 정보</h3>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">받을 사람</label>
                <div className="col-sm-9">{recipient}</div>
              </div>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">연락받을 번호</label>
                <div className="col-sm-9">{tel}</div>
              </div>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">우편번호</label>
                <div className="col-sm-9">{zoneCode}</div>
              </div>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">주소</label>
                <div className="col-sm-9">{address}</div>
              </div>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">배송 메모</label>
                <div className="col-sm-9">{shippingMemo}</div>
              </div>
              <div className="form-group row">
                <label htmlFor="trackingNumber" className="col-sm-3 col-form-label">
                  송장번호
                </label>
                <div className="col-sm-9">
                  {statusCode === 'CANCEL' ? (
                    trackingNumber
                  ) : (
                    <input
                      type="text"
                      className="form-control"
                      id="trackingNumber"
                      name="trackingNumber"
                      placeholder="송장번호"
                      maxLength={100}
                      ref={trackingNumberRef}
                      value={trackingNumber}
                      onChange={handleFieldChange}
                      disabled={isDisabled}
                    />
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="logisticsName" className="col-sm-3 col-form-label">
                  택배사
                </label>
                <div className="col-sm-9">
                  {statusCode === 'CANCEL' ? (
                    logisticsMap[logisticsIdx]
                  ) : (
                    <select
                      className="form-control"
                      id="logisticsName"
                      name="logisticsIdx"
                      value={logisticsIdx}
                      onChange={handleFieldChange}
                    >
                      <option value="">등록안됨</option>
                      {logistics.map((e) => (
                        <option key={e.idx} value={e.idx}>
                          {e.name}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
              </div>
              <hr />
              <h3>결제 정보</h3>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">메뉴/상품 금액</label>
              </div>
              <table className="table menuTable min-height-auto table-hover">
                <thead className="menuHead">
                  <tr>
                    <th scope="col">메뉴/상품명</th>
                    <th scope="col">판매단가</th>
                    <th scope="col">수량</th>
                    <th scope="col">결제금액</th>
                  </tr>
                </thead>
                <tbody>
                  {menus && menus.length > 0 ? (
                    menus.map((row) => {
                      const { menuId, menuName, sellPrice, count } = row;
                      return (
                        <tr className="table-row" key={menuId}>
                          <td>{menuName}</td>
                          <td>{numberWithCommas(sellPrice)}</td>
                          <td>{numberWithCommas(count)}</td>
                          <td>{numberWithCommas(sellPrice * count)}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td className="no-content" colSpan={3}>
                        검색 결과가 없습니다.
                      </td>
                    </tr>
                  )}
                </tbody>
                <tfoot>
                  <tr className="total-content">
                    <td colSpan={3}>확정 금액</td>
                    <td>{numberWithCommas(menusTotal)}</td>
                  </tr>
                </tfoot>
              </table>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">배송비</label>
              </div>
              <table className="table menuTable min-height-auto table-hover">
                <thead className="menuHead">
                  <tr>
                    <th scope="col">배송비</th>
                    <th scope="col">기본 배송비</th>
                    <th scope="col">조건부 추가배송비</th>
                    <th scope="col">제주/도서 추가배송비</th>
                    <th scope="col">결제금액</th>
                  </tr>
                </thead>
                <tbody>
                  {shippingFeeInfos && shippingFeeInfos.length > 0 ? (
                    shippingFeeInfos.map((row) => {
                      const { feeConditionName, defaultFee, conditionalFee, addressFee, totalFee } = row;
                      return (
                        <tr className="table-row" key={feeConditionName}>
                          <td>{feeConditionName}</td>
                          <td>{numberWithCommas(defaultFee)}</td>
                          <td>{numberWithCommas(conditionalFee)}</td>
                          <td>{numberWithCommas(addressFee)}</td>
                          <td>{numberWithCommas(totalFee)}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td className="no-content" colSpan={5}>
                        검색 결과가 없습니다.
                      </td>
                    </tr>
                  )}
                </tbody>
                <tfoot>
                  <tr className="total-content">
                    <td colSpan={4} />
                    <td>{numberWithCommas(shippingFeeTotal)}</td>
                  </tr>
                </tfoot>
              </table>
              <div className="form-group row menutotal">
                <label className="col-sm-4 col-form-label name">결제금액</label>
                <div className="col-sm-8 price total-price">{numberWithCommas(paymentPrice)}</div>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handleModalClose}>
              취소
            </button>
            <button
              type="button"
              className="btn btn-primary"
              disabled={statusCode === 'CANCEL' || saveBtn}
              onClick={saveTrackingAndLogistics}
            >
              수정
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShippingOrderManagementModal;
