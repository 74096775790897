import { Table } from 'antd';
import { TCategoryMenu, TMenu } from 'interfaces/booking';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { numberWithCommas } from 'utils';

const StyledMenuTable = styled.div`
  height: 450px;
  overflow-y: scroll;
  margin-top: 24px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;

  .ant-table-wrapper {
    &:not(:last-child) {
      border-bottom: 2px solid #d9d9d9;
    }
  }

  .ant-table-thead {
    z-index: 2;
    top: -1px;
    position: sticky;
  }

  .ant-table-tbody > tr > td {
    border-top: none;
    border-right: none;
    border-left: none;
  }
`;

interface MenuTableProps {
  menuList?: { contents: TCategoryMenu[] };
  handleChange: (mIdx: string[]) => void;
  selectedKeys: string[];
}

interface DataType {
  key: React.Key;
  name: string;
  price?: string | number;
  image?: string;
  select?: string;
}

const MenuTable = ({ menuList, handleChange, selectedKeys }: MenuTableProps) => {
  const [columnsTypes, setColumnsTypes] = useState<{ [categoryId: string]: { columnsType: any; categoryMenus: DataType[] } }>({});

  const generateMenus = (menus: any) => {
    const isExistMenu = menus.length > 0;

    const categoryMenus = isExistMenu
      ? menus.map(({ id, name, price, image }: TMenu) => {
          return { key: id, name, price: numberWithCommas(price), image: image.thumb };
        })
      : [];

    return categoryMenus;
  };

  const generateColumnsType = ({ categoryName, isExistMenu }: { categoryName: string; isExistMenu: boolean }) => {
    const columnsType = isExistMenu
      ? [
          {
            title: categoryName,
            dataIndex: 'image',
            colSpan: 2,
            render: (imgSrc: string) => (
              <img
                className="test-img"
                src={imgSrc || ''}
                alt="Smiley face"
                height="50"
                width="50"
                onError={(e: React.ChangeEvent<HTMLImageElement>) => {
                  if (imgSrc) {
                    e.target.onerror = null;
                    e.target.src = '';
                  }
                }}
              />
            ),
            width: 100,
            align: 'left'
          },
          {
            title: 'FoodName',
            dataIndex: 'name',
            colSpan: 0
          },
          {
            title: `${categoryName} 전체선택`,
            dataIndex: 'price',
            colSpan: 1
          },
          Table.SELECTION_COLUMN
        ]
      : [
          {
            title: categoryName,
            dataIndex: 'name'
          }
        ];

    return columnsType;
  };

  useEffect(() => {
    const isLoading = !menuList;

    if (isLoading) {
      console.log('[TEMP] 호출 중...');
    } else {
      const targetContents = menuList.contents;

      if (targetContents) {
        const targetColumnsTypes = {};

        targetContents.forEach(({ categoryId, categoryName, menus }: TCategoryMenu) => {
          targetColumnsTypes[categoryId] = {
            columnsType: generateColumnsType({ categoryName, isExistMenu: menus.length > 0 }),
            categoryMenus: generateMenus(menus)
          };
        });

        setColumnsTypes(targetColumnsTypes);
      } else {
        setColumnsTypes({
          [-1]: {
            columnsType: [
              {
                title: '전체',
                dataIndex: 'name'
              }
            ],
            categoryMenus: []
          }
        });
      }
    }
  }, [menuList]);

  return (
    <StyledMenuTable>
      {Object.keys(columnsTypes).map((categoryId: string) => {
        const { columnsType, categoryMenus } = columnsTypes[categoryId];

        return (
          <Table
            key={categoryId}
            rowSelection={
              categoryMenus.length > 0
                ? {
                    type: 'checkbox',
                    onChange: handleChange,
                    selectedRowKeys: selectedKeys
                  }
                : undefined
            }
            columns={columnsType}
            dataSource={categoryMenus.length > 0 ? categoryMenus : [{ key: '전체', name: '검색 결과가 없습니다.' }]}
            pagination={false}
            bordered={false}
            size="small"
          />
        );
      })}
    </StyledMenuTable>
  );
};

export default MenuTable;
