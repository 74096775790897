import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  getSettlementMetaInfo,
  getSettlementFeeInfo,
  updateSettlementBankAccount,
  saveSettlementMetaInfo,
  getEvidenceAgency,
  updateEvidenceAgency,
  getDeposit,
  getDepositDetail,
  getVat,
  getPersonInChargeOfSettlement,
  getSettlementStoreAccount,
  savePersonInChargeOfSettlement,
  errorHandler,
  getEvidences,
  getEvidenceDetail,
  saveEvidence
} from 'apis';

import {
  ISaveBizInfo,
  IUpdateBankAccount,
  IDataVat,
  IDataDetail,
  IPersonInChargeOfSettlement,
  SettlementStoreAccount,
  IEvidenceParams,
  IEvidenceState,
  IEvidenceDetail
} from 'interfaces/settlement';

interface IMetaInfoSaveData {
  storeId: string;
  bizInfo: ISaveBizInfo;
}

interface IUpdateBankAccountData {
  storeId: string;
  bankAccount: IUpdateBankAccount;
}

export const useSettlementMetaInfo = (storeId: string) =>
  useQuery(['getSettlementMetaInfo'], () => getSettlementMetaInfo(storeId), {
    enabled: !!storeId
  });

export const useSettlementFeeInfo = (storeId: string) =>
  useQuery(['getSettlementFeeInfo'], () => getSettlementFeeInfo(storeId), {
    enabled: !!storeId
  });

export const useSaveSettlementBizInfo = () => {
  const queryClient = useQueryClient();
  return useMutation<void, unknown, IMetaInfoSaveData>(
    ['saveBizInfo'],
    (params: IMetaInfoSaveData) => {
      const { storeId, bizInfo } = params;
      return saveSettlementMetaInfo(storeId, bizInfo);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['getSettlementMetaInfo']);
        await queryClient.invalidateQueries(['getAdmissionHistory']);
      }
    }
  );
};

export const useUpdateSettlementBankAccount = () => {
  const queryClient = useQueryClient();
  return useMutation<void, unknown, IUpdateBankAccountData>(
    ['saveBankAccount'],
    (params: IUpdateBankAccountData) => {
      const { storeId, bankAccount } = params;
      return updateSettlementBankAccount(storeId, bankAccount);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['getSettlementMetaInfo']);
        await queryClient.invalidateQueries(['getAdmissionHistory']);
      }
    }
  );
};

export const useEvidenceAgency = (storeId: string, config: Record<string, unknown>) =>
  useQuery(['getEvidenceAgency', storeId], () => getEvidenceAgency(storeId), {
    enabled: !!storeId,
    ...config
  });

export const useUpdateEvidenceAgency = () =>
  useMutation(['updateEvidenceAgency'], (updateData: unknown) => {
    return updateEvidenceAgency(updateData);
  });

export const useDeposit = (storeId: string, onSuccess: () => void) =>
  useQuery(['getDeposit', storeId], () => getDeposit(storeId), {
    enabled: !!storeId,
    onSuccess
  });

export const useDepositDetail = (storeId: string, settleProgressSeq: number, companySeq: number) =>
  useQuery<IDataDetail>(
    ['getDepositDetail', storeId, settleProgressSeq, companySeq],
    () => getDepositDetail(storeId, settleProgressSeq, companySeq),
    {
      enabled: !!storeId && !!settleProgressSeq && !!companySeq
    }
  );

export const useVat = (storeId: string, startDate: string, endDate: string, onSuccess: () => void) =>
  useQuery<IDataVat>(['getVat', storeId, startDate, endDate], () => getVat(storeId, startDate, endDate), {
    enabled: !!storeId && !!startDate && !!endDate,
    onSuccess
  });

export const usePersonInChargeOfSettlement = (storeId: string, onSuccess) =>
  useQuery<IPersonInChargeOfSettlement>(
    ['getPersonInChargeOfSettlement', storeId],
    () => getPersonInChargeOfSettlement(storeId),
    {
      enabled: !!storeId,
      onSuccess,
      onError: (err) => {
        errorHandler(err);
      }
    }
  );

export const useSettlementStoreAccount = (storeId: string, emptyResult = false, onError?: (err: unknown) => void) =>
  useQuery<SettlementStoreAccount>(
    ['getSettlementStoreAccount', storeId, emptyResult],
    () => getSettlementStoreAccount(storeId),
    {
      enabled: !!storeId && !emptyResult,
      select: (result) => {
        if (emptyResult) return undefined;
        return result;
      },
      onError,
      cacheTime: 0
    }
  );

export const useSavePersonInChargeOfSettlement = () => {
  const queryClient = useQueryClient();
  return useMutation<void, unknown, { storeId: string; chargeData: Omit<IPersonInChargeOfSettlement, 'barobillId'> }>(
    ['savePersonInChargeOfSettlement'],
    ({ storeId, chargeData }) => savePersonInChargeOfSettlement(storeId, chargeData),
    {
      onSuccess: async () => {
        queryClient.setQueriesData(['getSettlementStoreAccount'], null);
        await queryClient.invalidateQueries('getPersonInChargeOfSettlement');
      },
      onError: (err) => {
        errorHandler(err);
      }
    }
  );
};

export const useEvidences = (storeId: string, params: IEvidenceParams) =>
  useQuery(['getEvidences', params], () => getEvidences(storeId, params), {
    enabled: !!storeId
  });

export const useEvidenceDetail = (storeId: string, evidenceIdx: number) =>
  useQuery<IEvidenceDetail>(['getEvidenceDetail'], () => getEvidenceDetail(storeId, evidenceIdx), {
    enabled: !!storeId && !!evidenceIdx
  });

export const useSaveEvidence = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ['saveEvidence'],
    ({ storeId, evidenceIdx, params }: { storeId: string; evidenceIdx: number; params: IEvidenceState }) => {
      return saveEvidence(storeId, evidenceIdx, params);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getEvidenceDetail']);
      }
    }
  );
};
