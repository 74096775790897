import React from 'react';
import { Chart } from 'react-google-charts';
import { isMobile } from 'utils';

const BarChart = ({ data }) => {
  const options = {
    legend: 'none',
    // width: '80%',
    series: {
      0: { color: '#e5e4e2' },
      1: { color: '#61C282' }
    },
    chartArea: { height: '80%' },
    hAxis: { format: '#,### 원', textStyle: { marginBottom: '100' } },
    vAxis: { textStyle: { color: 'black' } }
  };

  let minHeight = (data.length + 1) * 50;
  if (minHeight <= 500) {
    minHeight = 500;
  }
  let height = `${minHeight}px`;
  if (isMobile()) height = '150px';

  return <Chart key={data} chartType="BarChart" data={data} options={options} width="100%" height={height} />;
};

export default BarChart;
