import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'antd';
import { LoginForm, ChannelTalkButton } from 'components';
import { setRememberMe } from 'utils/storages';
import { useAuth, useChannelTalk } from 'hooks';
import { OAuth } from 'interfaces/auth';
import { Container, Content } from './style';

const Login = () => {
  const navigate = useNavigate();
  const auth = useAuth();

  useChannelTalk(true);

  const onLogin = (value: any) => {
    const { username, password, remember } = value;
    if (!username) {
      // ee
      Modal.info({
        content: <>아이디를 입력해 주세요.</>,
        icon: null,
        okText: '확인',
        className: 'info-handler-modal'
      });
      return;
    }
    if (!password) {
      // ee
      Modal.info({
        content: <>비밀번호를 입력해 주세요.</>,
        icon: null,
        okText: '확인',
        className: 'info-handler-modal'
      });
      return;
    }
    setRememberMe(remember);
    // const result = await mutateAsync({ username, password });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    auth.signIn(username, password, (data: OAuth) => {
      // const { account } = data;
      // if (account?.password && account.password !== 'NONE') {
      //   navigate('/changePassword', { state: { userid: account.guid, passwordStatus: account.password } });
      // } else {
      // }
      navigate('/', { replace: true });
    });
  };

  return (
    <Container>
      <Content>
        <div className="custom-channel-container">
          <ChannelTalkButton />
        </div>
        <LoginForm loading={auth.isSignInLoading} onLogin={onLogin} />
      </Content>
    </Container>
  );
};

export default Login;
