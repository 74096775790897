import React from 'react';
import { Modal } from 'antd';
import { FlexBoxFullWidthCenter, FlexBoxFullWidthHeight } from 'styles';
import { useChannelTalk } from 'hooks';
import { ChannelTalkButton } from 'components';

const RequestResultModal = ({ title, open, onClose }) => {
  useChannelTalk(true, true);

  return (
    <Modal title={title} open={open} onCancel={onClose} footer={null} bodyStyle={{ height: 712 }}>
      <FlexBoxFullWidthHeight direction="column" gap="55px" justify="space-evenly">
        <FlexBoxFullWidthCenter direction="column">
          <div>요청 내용이 변경되지 않았습니다.</div>
          <br />
          <div>자세한 내용은 1:1문의를 통해</div>
          <div>확인해주세요.</div>
        </FlexBoxFullWidthCenter>
        <div className="custom-channel-container">
          <ChannelTalkButton />
        </div>
      </FlexBoxFullWidthHeight>
    </Modal>
  );
};

export default RequestResultModal;
