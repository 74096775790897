/* eslint-disable import/prefer-default-export */
import { Form, Radio } from 'antd';
import styled from 'styled-components';

export const Step2Container = styled.div`
  margin-top: 32px;
  .radio-group-space {
    display: flex;
  }
  .ant-radio-group {
  }
  .ant-radio-group-large .ant-radio-button-wrapper {
    height: 100px;
    width: 100%;
    padding: 16px 0 0 16px;
    gap: 13px;
    border-color: #d7d8dc;
    border-radius: 8px;
  }
`;
export const FormItemWithRadioGroup = styled(Radio.Group)`
  & {
    width: 100%;
  }
`;

export const FormItemMarginBottom = styled(Form.Item)`
  & {
    margin-bottom: 8px;
  }
`;

export const FormItemDescription = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 8px;
`;

export const SupplyTypeRadioContent = styled.div`
  display: inline-flex;
  flex-direction: column;
  gap: 13px;

  .supply-type-name {
    display: flex;
    align-items: center;
    gap: 13px;
  }
  .commission {
    background-color: ${({ theme }) => theme.color.gray05};
    color: ${({ theme }) => theme.color.gray04};
    border-radius: 4px;
    padding: 4px 6px;
    font-size: 16px;
  }
  .explanation {
    color: ${({ theme }) => theme.color.gray02};
    font-size: 16px;
    font-weight: 400;
  }
`;

export const ImageSizeNotice = styled.ul`
  padding-inline-start: 16px;
  color: ${({ theme }) => theme.color.red};
`;

export const EvidenceTypeCodeDesc = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: ${({ theme }) => theme.color.gray02};
`;
