import { closeReport, getReviewReportTypes, getReviews, saveComment, updateComment, deleteComment } from 'apis';
import { useMutation, useQuery, useQueryClient } from 'react-query';

export const useReviews = (params: unknown, config: Record<string, unknown>) =>
  useQuery(['getReviews', params], () => getReviews(params), {
    ...config
  });

export const useReviewReportTypes = () => useQuery(['getReviewReportTypes'], () => getReviewReportTypes());

export const useCloseReport = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ['closeReport'],
    (params: { reviewIdx: string; saveData: unknown }) => {
      const { reviewIdx, saveData } = params;
      return closeReport(reviewIdx, saveData);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('getReviews');
      }
    }
  );
};
export const useSaveComment = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ['saveComment'],
    (params: { reviewIdx: string; saveData: unknown }) => {
      const { reviewIdx, saveData } = params;
      return saveComment(reviewIdx, saveData);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('getReviews');
      }
    }
  );
};
export const useUpdateComment = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ['updateComment'],
    (params: { reviewIdx: string; commentIdx: string; saveData: unknown }) => {
      const { reviewIdx, commentIdx, saveData } = params;
      return updateComment(reviewIdx, commentIdx, saveData);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('getReviews');
      }
    }
  );
};
export const useDeleteComment = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ['deleteComment'],
    (params: { reviewIdx: string; commentIdx: string }) => {
      const { reviewIdx, commentIdx } = params;
      return deleteComment(reviewIdx, commentIdx);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('getReviews');
      }
    }
  );
};
