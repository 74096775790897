import { storeClient } from 'apis/apiClients';
import { IAggregateOrder, IRobotOrders, TOrderStatus, IRobotOrderCancelParams } from 'interfaces/robot';

// 제휴점 로봇배달 배송지 목록 조회
export const getRobotSpots = async () => {
  const { data } = await storeClient.get(`robot/v1/spots`);
  return data;
};

// 제휴점 설정 정보 조회
export const getRobotSettings = async () => {
  const { data } = await storeClient.get(`robot/v1/store/settings`);
  return data;
};

// 로봇배달 주문 받기 on/off
export const updateStoreOpen = async (isOpen: boolean) => {
  await storeClient.put(`robot/v1/store/settings/is-open?isOpen=${isOpen}`);
};

// 로봇배달 가능 스캐쥴 조회
export const getRobotSchedule = async () => {
  const { data } = await storeClient.get(`robot/v1/schedule`);
  return data;
};

// 로봇배달 일자별 주문요청 & 주문취소 내역 조회
export const getRobotOrders = async (requestDate: string) => {
  const { data } = await storeClient.get<IRobotOrders>(`robot/v1/orders`, { params: { requestDate } });
  return data;
};

// 로봇배달 주문 상세
export const getRobotOrderDetail = async (couponId: string) => {
  const { data } = await storeClient.get(`robot/v1/order/${couponId}`);
  return data;
};

// 로봇배달 제휴점 결제 취소
export const deleteRobotOrder = async (params: IRobotOrderCancelParams) => {
  await storeClient.delete(`robot/v1/order`, { data: params });
};

// 로봇배달 합배송
export const saveRobotOrderAggregate = async (params: any) => {
  await storeClient.put(`robot/v1/orders/aggregate`, { ...params, scheduleId: params.schedule.scheduleId });
};

// 배달중 탭, 배달완료 & 픽업 탭 조회 API
export const getAggregateOrders = async (requestDate: string, status: TOrderStatus) => {
  const { data } = await storeClient.get<IAggregateOrder>(`robot/v1/aggregate/orders`, { params: { requestDate, status } });
  return data;
};
