import React from 'react';
import styled from 'styled-components';
import { TOrderStatus } from 'interfaces/robot';

const ORDER_STATUS: { [key in TOrderStatus]: Record<string, string> } = {
  CONFIRM: { bgColor: '#dbebff', color: '#0067ff', label: '주문요청' },
  ACCEPT: { bgColor: '#def8ea', color: '#46B476', label: '주문확정' },
  DELIVERING: { bgColor: '#ffbe00', color: '#212529', label: '배달중' },
  COMPLETE: { bgColor: '#212529', color: '#ffffff', label: '배달완료' },
  PICKED_UP: { bgColor: '#e2e2e2', color: '#212529', label: '픽업완료' },
  USER_CANCEL: { bgColor: '#ff8080', color: '#212529', label: '취소완료' },
  STORE_CANCEL: { bgColor: '#ff8080', color: '#212529', label: '취소완료' },
  ADMIN_CANCEL: { bgColor: '#ff8080', color: '#212529', label: '취소완료' }
};

const BadgeWrapper = styled.div<{ $status: TOrderStatus }>`
  width: 100px;
  height: 32px;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 700;
  line-height: 34px;
  text-align: center;
  color: ${({ $status }) => ORDER_STATUS[$status].color};
  background: ${({ $status }) => ORDER_STATUS[$status].bgColor};
`;

export const StatusBadge = ({ status }: { status: TOrderStatus }) => (
  <BadgeWrapper className="status-badge" $status={status}>
    {ORDER_STATUS[status].label}
  </BadgeWrapper>
);
