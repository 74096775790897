import MenuDialog from 'components/Booking/MenuModal/MenuDialog';
import WeekBookingMain from 'components/Booking/WeekBooking';
import { TBooking, TCategoryMenu, TDetailInfo, TMenuContent } from 'interfaces/booking';
import moment, { Moment } from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Range } from 'react-date-range';
import { openNotificationWithIcon } from 'utils';

interface ManageBookingMenuProps {
  bookingList?: TBooking[];
  menuDetail: { content: TMenuContent };
  menuList?: { contents: TCategoryMenu[] };
  getBookings: (v: unknown) => void;
  getMenuDetail: (v: number) => void;
  deleteMenu: (idx: number, v: string) => void;
  saveMenu: (idx: number, v: unknown) => Promise<void>;
  getMenus: (v?: unknown) => Promise<void>;
  allComponent?: React.ReactNode;
}

const InitDetailInfo = {
  artifactIdx: -1,
  templateIdx: -1
};

const NewManageBookingMenu = ({
  bookingList,
  menuDetail,
  menuList,
  getBookings,
  getMenuDetail,
  getMenus,
  deleteMenu,
  saveMenu,
  allComponent
}: ManageBookingMenuProps) => {
  const [menuIds, setMenuIds] = useState<string[]>([]);
  const [isMenuDialogOpen, setMenuDialogOpen] = useState(false);
  const [calenderOptions, setCalenderOptions] = useState<
    { selectedDate: Date; beforeSelectedDate: Date; formatText: string } & Range
  >({
    key: 'selection',
    selectedDate: new Date(),
    beforeSelectedDate: new Date(),
    startDate: moment().startOf('day').day(0).toDate(),
    endDate: moment().startOf('day').day(6).toDate(),
    formatText: `${moment().startOf('day').day(0).format('YYYY. M. DD.')} - ${moment()
      .startOf('day')
      .day(6)
      .format('YYYY. M. DD.')}`
  });

  const [weekDays, setWeekDays] = useState<string[]>([]);
  const [detailInfo, setDetailInfo] = useState<TDetailInfo>(InitDetailInfo);

  const handleGetBookingArtifacts = useCallback(
    (aIdx?: number) => {
      const { startDate, endDate } = calenderOptions;
      const params = {
        start: moment(startDate).format('YYYY-MM-DD'),
        end: moment(endDate).format('YYYY-MM-DD')
      };
      if (!aIdx) {
        setDetailInfo(InitDetailInfo);
      }
      getBookings(params);
    },
    [calenderOptions, getBookings]
  );

  const menuDetSel = (artifactIdx: number) => {
    getMenuDetail(artifactIdx);
  };

  const handleGetMenuDetail = ({ artifactIdx, templateIdx }: TDetailInfo) => {
    menuDetSel(artifactIdx);

    if (detailInfo.artifactIdx === artifactIdx) {
      setDetailInfo(InitDetailInfo);
    } else {
      setDetailInfo({
        templateIdx,
        artifactIdx
      });
    }
  };

  const menuSel = async (params?: unknown) => {
    await getMenus(params);
  };

  /**
   * 요일 텍스트
   */
  const handleSetWeekDays = ({ startDate }: { startDate: Date }) => {
    const newDays = [];
    for (let i = 0; i < 7; i += 1) {
      const strD = moment(startDate).startOf('day').day(0);
      newDays.push(strD.add(i, 'day').format('M. DD.'));
    }
    setWeekDays(newDays);
  };

  const changeCalenderOptions = useCallback(
    ({
      standardDate,
      isSetWeekDay,
      isResetDetailInfo,
      isGetBookings,
      isChangeBeforeSelectedDate
    }: {
      standardDate: Moment;
      isSetWeekDay: boolean;
      isResetDetailInfo: boolean;
      isGetBookings: boolean;
      isChangeBeforeSelectedDate?: boolean;
    }) => {
      const star = moment(standardDate).startOf('day').day(0);
      const end = moment(standardDate).startOf('day').day(6);

      setCalenderOptions((prevDate) => ({
        ...prevDate,
        selectedDate: standardDate.toDate(),
        beforeSelectedDate: isChangeBeforeSelectedDate ? standardDate.toDate() : prevDate.beforeSelectedDate,
        startDate: star.toDate(),
        endDate: end.toDate(),
        formatText: `${star.format('YYYY. M. DD.')} - ${end.format('YYYY. M. DD.')}`
      }));

      if (isSetWeekDay) handleSetWeekDays({ startDate: star.toDate() });
      if (isResetDetailInfo) setDetailInfo(InitDetailInfo);

      if (isGetBookings) {
        const params = {
          start: star.format('YYYY-MM-DD'),
          end: end.format('YYYY-MM-DD')
        };

        getBookings(params);
      }
    },
    [getBookings]
  );

  const handleCalender = useCallback(
    (rangeDate: Record<string, Range>) => {
      const { startDate } = rangeDate.selection;

      changeCalenderOptions({
        standardDate: moment(startDate),
        isSetWeekDay: false,
        isResetDetailInfo: false,
        isGetBookings: false,
        isChangeBeforeSelectedDate: false
      });
    },
    [changeCalenderOptions]
  );

  const handleCalenderSuccess = useCallback(() => {
    const { startDate, selectedDate } = calenderOptions;
    setCalenderOptions({
      ...calenderOptions,
      beforeSelectedDate: selectedDate
    });
    handleSetWeekDays({ startDate });
    handleGetBookingArtifacts();
  }, [calenderOptions, handleGetBookingArtifacts]);

  const handleCalenderCancel = useCallback(() => {
    const selectedDate = moment(calenderOptions.beforeSelectedDate);
    changeCalenderOptions({ standardDate: selectedDate, isSetWeekDay: false, isResetDetailInfo: true, isGetBookings: false });
  }, [calenderOptions.beforeSelectedDate, changeCalenderOptions]);

  const handleBeforeAndAfter = useCallback(
    (e: React.ChangeEvent<HTMLButtonElement>) => {
      const { name } = e.currentTarget;
      const selectedDate = moment(calenderOptions.selectedDate);
      let defaultDate: moment.Moment;

      if (name === 'PREV') defaultDate = selectedDate.add(-7, 'day');
      else defaultDate = selectedDate.add(7, 'day');

      changeCalenderOptions({ standardDate: defaultDate, isSetWeekDay: true, isResetDetailInfo: true, isGetBookings: true });
    },
    [calenderOptions.selectedDate, changeCalenderOptions]
  );

  // 제휴점 메뉴 팝업 on/off
  const handleDialog = () => {
    if (!isMenuDialogOpen) {
      menuSel();
    } else {
      setMenuIds([]);
    }
    setMenuDialogOpen(!isMenuDialogOpen);
  };

  // 제휴점 검색
  const handleForm = async (keyword?: string) => {
    const params = { keyword };
    await getMenus(params);
    if (keyword) setMenuIds([]);
  };

  const handleDeleteMenu = (selectMenuId: string) => {
    const { artifactIdx } = detailInfo;
    deleteMenu(artifactIdx, selectMenuId);
    // handleGetBookingArtifacts(artifactIdx);
    // menuDetSel(artifactIdx);
  };

  /**
   * [ 메뉴 id 추가 ]
   * @param  {string} menuId [ 메뉴 고유  ID ]
   * @param  {number} pIdx [ Array Index ]
   * @param  {number} categoryIdx [ 카테고리 고유  ID ]
   */
  const handleMenuAdd = (selectedMenuIds: string[]) => {
    setMenuIds(selectedMenuIds);
  };

  // 메뉴 추가
  const addMenu = async () => {
    let success = false;
    const { artifactIdx } = detailInfo;
    const params = {
      contents: menuIds
    };
    if (menuIds.length > 0) {
      try {
        await saveMenu(artifactIdx, params);
        handleDialog();
        menuDetSel(artifactIdx);
        handleGetBookingArtifacts(artifactIdx);
        openNotificationWithIcon({ message: '메뉴를 성공적으로 추가하였습니다.', type: 'success', duration: 2 });
        success = true;
      } catch (error) {
        console.log(error);
        const { response } = error as { response: { data: Record<string, unknown> } };
        openNotificationWithIcon({ message: response?.data?.message as string, type: 'error', duration: 2 });
      }
    } else {
      openNotificationWithIcon({ message: '메뉴를 선택해주세요.', type: 'warning', duration: 2 });
    }
    return success;
  };

  useEffect(() => {
    handleSetWeekDays({ startDate: new Date() });
  }, []);

  const store = useMemo(() => {
    return {
      calenderOptions,
      weekDays,
      handleCalenderSuccess,
      handleCalenderCancel,
      handleCalender,
      handleBeforeAndAfter
    };
  }, [calenderOptions, weekDays, handleBeforeAndAfter, handleCalender, handleCalenderSuccess, handleCalenderCancel]);

  return (
    <div>
      <WeekBookingMain
        bookingList={bookingList}
        detailInfo={detailInfo}
        getMenuDetail={handleGetMenuDetail}
        menuDetail={menuDetail}
        deleteMenuDetail={handleDeleteMenu}
        handleDialog={handleDialog}
        store={store}
        allComponent={allComponent}
      />
      <MenuDialog
        handleForm={handleForm}
        open={isMenuDialogOpen}
        menuAdd={addMenu}
        handleDialog={handleDialog}
        handleMenuAdd={handleMenuAdd}
        menuList={menuList}
      />
    </div>
  );
};

export default NewManageBookingMenu;
