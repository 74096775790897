import { OAuth } from 'interfaces/auth';
import { Moment } from 'moment';

const TOKEN_KEY = 'oauth';
const STORE_LIST = 'storeList';
const MY_INFO = 'myInfo';
const STORE_ADMIN = 'storeAdmin';
const ADMIN_MODE = 'adminMode';
const STORE_ADMIN_ID = 'storeAdminId';
const ACCOUNT = 'account';
const REMEMBER_ME = 'save';
const MAGIC_CODE = 'magicCode';

const MOBILE_OS = 'mparams.os';
const MOBILE_OS_VERSION = 'mparams.osversion';
const MOBILE_MODEL = 'mparams.model';
const MOBILE_PUSH_TOKEN = 'mparams.pushtoken';
// const MOBILE_TTS = 'mparams.tts';
const ADMISSION_IDX = 'admissionIdx';
const PASS_CERTIFICATION = 'passCertification';
const TARGET_EVIDENCE_AGENCY_NEXT_TIME = 'targetEvidenceAgencyNextTime';

const POPUP_DONT_VIEW = 'popupDontView';

export const getLocalStorage = (key: string) => {
  const item = localStorage.getItem(key) ?? null;
  try {
    return item !== null ? JSON.parse(item) : item;
  } catch (error) {
    return item;
  }
};

export const setLocalStorage = (key: string, value: unknown) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.error(error);
  }
};

const getSessionStorage = (key: string) => {
  const item = sessionStorage.getItem(key) ?? null;
  try {
    return item !== null ? JSON.parse(item) : item;
  } catch (error) {
    return item;
  }
};

const setSessionStorage = (key: string, value: unknown) => {
  try {
    sessionStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.error(error);
  }
};

export const getPopupDontView = () => getLocalStorage(POPUP_DONT_VIEW);
export const setPopupDontView = (value: Moment) => setLocalStorage(POPUP_DONT_VIEW, value);

export const getLocalStorageAfterLogout = () => {
  const popupDontView = getPopupDontView();
  return popupDontView;
};

export const setLocalStorageAfterLogout = (value: any) => {
  setPopupDontView(value);
};

export const getRememberMe = () => getLocalStorage(REMEMBER_ME);
export const setRememberMe = (value: boolean) => setLocalStorage(REMEMBER_ME, value);
export const getExistsToken = () => {
  const token = getLocalStorage(TOKEN_KEY);
  if (token) {
    setRememberMe(true);
  }
  return token;
};

const getStorage = (key: string) => {
  const auto = getRememberMe();
  if (!auto) {
    return getSessionStorage(key);
  }
  return getLocalStorage(key);
};

const setStorage = (key: string, value: unknown) => {
  const auto = getRememberMe();
  if (!auto) {
    setSessionStorage(key, value);
  } else {
    setLocalStorage(key, value);
  }
};

const removeStorage = (key: string) => {
  localStorage.removeItem(key);
  sessionStorage.removeItem(key);
};

export const getOauth = (): OAuth => getStorage(TOKEN_KEY);
export const getAccount = (): OAuth['account'] => getStorage(ACCOUNT);
export const getExistsAccount = (): OAuth['account'] => getLocalStorage(ACCOUNT);

export const getToken = () => {
  const token = getStorage(TOKEN_KEY);
  return token ? token.access_token : undefined;
};

export const getRefreshToken = () => {
  const token = getStorage(TOKEN_KEY);
  return token ? token.refresh_token : undefined;
};

export const setToken = (token: OAuth) => {
  const { account } = token;
  setStorage(TOKEN_KEY, token);
  if (account) {
    setStorage(ACCOUNT, account);
  }
};

export const afterChangedPasswordAndStorageChange = () => {
  const oAuth = getOauth();
  oAuth.account.password = 'NONE';
  setToken(oAuth);
};

export const clearByLogout = () => {
  removeStorage(TOKEN_KEY);
  removeStorage(STORE_LIST);
  removeStorage(MY_INFO);
  removeStorage(STORE_ADMIN);
  removeStorage(ADMIN_MODE);
  removeStorage(STORE_ADMIN_ID);
  removeStorage(ACCOUNT);
  removeStorage(PASS_CERTIFICATION);
  removeStorage(ADMISSION_IDX);
};

export const getMagicCode = () => getLocalStorage(MAGIC_CODE);
export const setMagicCode = (value: string) => setLocalStorage(MAGIC_CODE, value);

export const getMobileParams = () => {
  const os = getLocalStorage(MOBILE_OS);
  const osVersion = getLocalStorage(MOBILE_OS_VERSION);
  const model = getLocalStorage(MOBILE_MODEL);

  return {
    os,
    osVersion,
    model,
    isMobile: os || osVersion || model
  };
};

export const getPushToken = () => getLocalStorage(MOBILE_PUSH_TOKEN);

export const setMyInfo = <T>(info: T) => setStorage(MY_INFO, info);
export const getMyInfo = () => getStorage(MY_INFO);

export const setStores = <T>(stores: Array<T>) => setStorage(STORE_LIST, stores);
export const getStores = () => getStorage(STORE_LIST);

export const setMyStores = <T>(storeList: Array<T>, id: string) => {
  setStores(storeList);
  setStorage(STORE_ADMIN_ID, id);
};

export const setAdminMode = (v: boolean) => setStorage(ADMIN_MODE, v);
export const setAdminMeInfo = <T>(admin: T) => {
  setStorage(STORE_ADMIN, { ...admin });
  // setMyInfo({});
  setAdminMode(true);
};

export const getStoreAdmin = () => getStorage(STORE_ADMIN);

export const getIsAdmin = () => {
  const storeAdmin = getStorage(STORE_ADMIN);
  const adminMode = getStorage(ADMIN_MODE);
  return !!(storeAdmin?.admin && adminMode);
};

export const getAdmissionIdx = () => getSessionStorage(ADMISSION_IDX);
export const setAdmissionIdx = (idx: number) => setSessionStorage(ADMISSION_IDX, idx);
export const removeAdmissionIdx = () => removeStorage(ADMISSION_IDX);

const getValuePassCertification = (id: string) => {
  const passObj = getSessionStorage(PASS_CERTIFICATION);
  if (!passObj) {
    return false;
  }
  return Object.entries(passObj).filter(([key]) => key === id);
  // .map(([, v]) => v);
};

export const setPassCertification = (id: string, isPass: boolean) => {
  if (!id) {
    return;
  }
  const passValue = getValuePassCertification(id);
  const now = new Date();
  now.setDate(now.getDate() + 3);
  if (passValue) {
    setSessionStorage(PASS_CERTIFICATION, { [id]: { isPass, expiry: now.getTime() } });
  } else {
    const longTerm = new Date();
    longTerm.setDate(longTerm.getDate() + 30);
    setSessionStorage(PASS_CERTIFICATION, {
      [id]: { isPass, expiry: now.getTime(), longTermExpiry: longTerm.getTime() }
    });
  }
};

export const getPassCertification = (id) => {
  if (!id) {
    return false;
  }
  const pass = getValuePassCertification(id);
  if (!pass) {
    return false;
  }

  if (pass.length) {
    const certification = pass[0][1] as { isPass: boolean; expiry: number; longTermExpiry: number };
    const now = new Date().getTime();
    if (now > certification.longTermExpiry) {
      return false;
    }
    if (certification.isPass && now > certification.expiry) {
      setPassCertification(pass[0][0], false);
      return false;
    }
    return certification.isPass;
  }
  return false;
};

export const getEvidenceAgencyNextTime = () => getLocalStorage(TARGET_EVIDENCE_AGENCY_NEXT_TIME);
export const setEvidenceAgencyNextTime = (value: unknown) => setLocalStorage(TARGET_EVIDENCE_AGENCY_NEXT_TIME, value);
export const removeEvidenceAgencyNextTime = () => removeStorage(TARGET_EVIDENCE_AGENCY_NEXT_TIME);

export const getXUserAgent = () => {
  const jsVersion = '2.63.0';
  const mobileParams = getMobileParams();
  if (mobileParams.isMobile) {
    return `Vendys/1.0 {"client": "SikdaeUser", "os": "${mobileParams.os}", "osVersion": "${mobileParams.osVersion}", "deviceModel": "${mobileParams.model}", "JsVersion": "${jsVersion}"}`;
  }

  return `Vendys/1.0 {"client": "SikdaeUser", "os": "web", "JsVersion": "${jsVersion}"}`;
};

export const getClient = (): string | undefined => {
  const mobileParams = getMobileParams();
  if (mobileParams.os === 'ios') {
    return process.env.REACT_APP_CLIENT_ID_IOS;
  }
  if (mobileParams.os === 'ios') {
    return process.env.REACT_APP_CLIENT_ID_ANDROID;
  }
  return process.env.REACT_APP_CLIENT_ID_WEB;
};

export const getClients = (): string | undefined => {
  const mobileParams = getMobileParams();
  if (mobileParams.os === 'ios') {
    return process.env.REACT_APP_CLIENTS_IOS;
  }
  if (mobileParams.os === 'ios') {
    return process.env.REACT_APP_CLIENTS_ANDROID;
  }
  return process.env.REACT_APP_CLIENTS_WEB;
};

export const getCurrentMode = () => {
  const host = window.location.hostname;
  if (
    host === '192.168.0.41' ||
    host === 'localhost' ||
    host === 'store.mealc.co.kr' ||
    host === 'sb-store.mealc.co.kr' ||
    host === 'dev-store.mealc.co.kr'
  ) {
    return 'test';
  }

  if (host === 'store-web-stage.sikdae.com') {
    return 'stage';
  }

  return null;
};
