import React from 'react';
import { generate } from 'shortid';
import styled from 'styled-components';

const StyledWeekDay = styled.div`
  display: flex;
  min-width: 1027px;
  background-color: #46b476;
  border-top: 1px solid #43a66e;

  .week-list {
    width: 85%;
    height: auto;
    color: #ffffff;
    font-size: 36px;
    font-weight: bold;
    padding: 11px 24px 0 7px;
  }

  .day {
    width: 14%;
    min-width: 30px;
    display: inline-block;
    vertical-align: middle;
    & div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }

  .title {
    width: 239px;
    font-size: 36px;
    font-weight: bold;
    color: #ffffff;
    line-height: 2;
  }

  .date-list {
    font-size: 16px;
    & div {
      width: 14%;
      min-width: 60px;
      display: inline-block;
      vertical-align: middle;
      text-align: center;
    }
  }
`;

interface WeekDayProps {
  weekDays: string[];
}

const WeekDay = ({ weekDays }: WeekDayProps) => (
  <StyledWeekDay>
    <div className="title" />
    <div className="week-list">
      <div className="day">
        <div>
          <span>일</span>
        </div>
      </div>
      <div className="day">
        <div>
          <span>월</span>
        </div>
      </div>
      <div className="day">
        <div>
          <span>화</span>
        </div>
      </div>
      <div className="day">
        <div>
          <span>수</span>
        </div>
      </div>
      <div className="day">
        <div>
          <span>목</span>
        </div>
      </div>
      <div className="day">
        <div>
          <span>금</span>
        </div>
      </div>
      <div className="day">
        <div>
          <span>토</span>
        </div>
      </div>
      <div className="date-list">
        {weekDays?.map((item) => {
          const uid = generate();
          return (
            <div key={uid}>
              <div>
                <span>{item}</span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  </StyledWeekDay>
);

export default WeekDay;
