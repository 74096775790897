import styled from 'styled-components';
import { List } from 'antd';

export const FlexBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const IncomeHistoryWrapper = styled.div`
  .listbox-detailinfo-flex {
    display: flex;
    align-items: stretch;
    padding-top: 16px;
  }
`;

export const SelectBox = styled.div`
  width: 100%;
  padding-top: 20px;
  display: flex;
  align-items: center;

  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    height: 54px;
  }

  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item {
    line-height: 54px;
  }

  .ant-select-arrow {
    margin-top: 2px;
  }

  .year-select {
    width: 150px;
    height: 54px;
  }

  .month-select {
    width: 120px;
    height: 54px;
    padding-left: 8px;
  }

  span:nth-child(1) {
    font-weight: 700;
    font-size: 20px;
    line-height: 34px;
  }
  span:nth-child(2) {
    font-weight: 700;
    font-size: 20px;
    line-height: 34px;
  }

  .selectbox {
    .net-income {
      width: 155px;
      height: 32px;
      padding-left: 10px;
      font-weight: 800;
      background-color: #d7d8dc;
    }

    .sales-revenue,
    .commission-fee {
      width: 155px;
      height: 32px;
      padding-left: 10px;
    }
  }

  .net-income {
    padding: 2px 8px 2px 8px;
    background-color: #d7d8dc;
    border-radius: 4px;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    margin-left: 8px;
  }
  .sales-revenue {
    padding: 2px 10px 2px 10px;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    color: #6d7582;
    border-right: 1px solid #d7dbdc;
  }
  .commission-fee {
    padding: 2px 10px 2px 10px;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    color: #6d7582;
  }
`;

export const ListBox = styled.div`
  min-height: 500px;
  background-color: white;
  border: 1px solid #dcdcdc;
  border-right: none;

  .border-bottom {
    border-bottom: 1px solid #f2f4f6;
  }
`;

export const ListContent = styled(List)`
  width: 300px;
  overflow: auto;

  li {
    padding: 16px 16px 0 16px;
    :hover {
      cursor: pointer;
    }
    .active {
      background-color: #ecf7f1;
    }
  }
  li.list.active {
    font-weight: 800;
  }

  .ant-list-item-meta-description {
    padding-bottom: 16px;
    .display {
      display: flex;
      flex-direction: row;
      padding-left: 16px;
    }
    div:nth-child(1) {
    }
    div:nth-child(2) {
      padding-left: 8px;
      color: black;
    }
    div:nth-child(3) {
      padding-left: 8px;
      width: 135px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;
