// eslint-disable-next-line import/prefer-default-export
export function paramsToFormData(obj: Record<string, unknown>, formParam?: FormData) {
  const form = formParam || new FormData();
  if (!obj) {
    return form;
  }
  if (obj) {
    Object.entries(obj).forEach(([k, v]) => {
      if (typeof v === 'undefined' || v === null) {
        return;
      }
      if ((v as string | Record<string, unknown>).constructor === Object) {
        paramsToFormData(v as Record<string, unknown>, form);
      } else {
        form.append(k, v as string | Blob);
      }
    });
  }
  return form;
}
