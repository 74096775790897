import { ISaveAppDisplayInfo, CouponParam, CouponParamPaging } from 'interfaces/store';
import { paramsToFormData } from 'utils';
import { storeClient } from './apiClients';

export const getStore = async (sid: string) => {
  const { data } = await storeClient.get(`/v1/store/${sid}`);
  return data;
};

export const getStores = async () => {
  const { data } = await storeClient.get('/store/v1/store');
  return data;
};

export const getStoreImagesInfo = async (sid: string) => {
  const { data } = await storeClient.get(`/store/v1/${sid}/image/register/info`);
  return data;
};

export const getMe = async () => {
  const { data } = await storeClient.get('/v1/store/me');
  return data;
};

export const getMeVerTwo = async (sid: string) => {
  const { data } = await storeClient.get(`/store/v2/${sid}`);
  return data;
};

export const transformAccount = async (updateData: unknown): Promise<unknown> => {
  const { data } = await storeClient.put('/store/v1/account/transform', updateData);
  return data;
};

export const saveStoreDisplayInfo = async (params: ISaveAppDisplayInfo) => {
  const { storeId, displayData } = params;
  await storeClient.put(`/v1/store/${storeId}`, displayData);
};

export const getEmployee = async (storeId: string) => {
  const { data } = await storeClient.get(`/store/v1/${storeId}/workers`);
  return data;
};
export const addEmployee = async (storeId: string, worker) => {
  const { data } = await storeClient.post(`/store/v1/${storeId}/worker`, worker);
  return data;
};
export const updateEmployee = async (storeId: string, worker) => {
  const { data } = await storeClient.put(`/store/v1/${storeId}/worker`, worker);
  return data;
};
export const getWorkers = async () => {
  const { data } = await storeClient.get(`/store/v1/workers`);
  return data;
};
export const updateWorkers = async (updateList) => {
  const { data } = await storeClient.put(`/store/v1/workers`, updateList);
  return data;
};

export const getCoupon = async (params: CouponParam) => {
  const { data } = await storeClient.get(`/v1/store/coupon`, { params });
  return data;
};

export const getCouponGroup = async (params: CouponParamPaging) => {
  const { data } = await storeClient.get(`/v1/store/coupon/group`, { params });
  return data;
};

export const getMenus = async (sid) => {
  const { data } = await storeClient.get(`/store/v1/${sid}/menu`);
  return data;
};

export const getTodayReport = async (params: unknown) => {
  const { data } = await storeClient.get(`/v1/store/sales`, { params });
  return data;
};

export const updateMenuStatus = async (sid, params) => {
  const { data } = await storeClient.put(`/store/v1/${sid}/status/menu`, params);
  return data;
};

export const getCompanies = async () => {
  const { data } = await storeClient.get(`/store/v2/company`);
  return data;
};

export const saveThumbnail = async (params) => {
  const { data } = await storeClient.post('/v1/store/thumbnail', params);
  return data;
};

export const saveSignatureMenuImg = async (params) => {
  const { data } = await storeClient.post('v1/store/register/info/image-order', params);
  return data;
};

export const saveSignatureMenuImgOrder = async (params) => {
  const { data } = await storeClient.post('v1/store/register/info/image-order', params);
  return data;
};

export const deleteSignatureMenuImg = async (data: unknown) => {
  await storeClient.delete('v1/store/delete/info/image', { data });
};

export const getExcelStorage = async (params: unknown) => {
  const { data } = await storeClient.get('v1/store/excel/storage', { params });
  return data;
};
export const exportExcel = async (params: unknown) => {
  const { data } = await storeClient.post('v1/store/excel/export', paramsToFormData(params as Record<string, unknown>));
  return data;
};
export const getCouponDetail = async (couponId, params) => {
  const { data } = await storeClient.get(`/v1/store/coupon/${couponId}`, { params });
  return data;
};
export const getAdmissionHistory = async (said: string) => {
  const { data } = await storeClient.get(`/store/v1/${said}/history`);
  return data;
};

export const getLogData = async (pageNum) => {
  const { data } = await storeClient.get(`/store/v1/menu/log?page=${pageNum}`);
  return data;
};
