import { getAccount, getExistsToken } from 'utils/storages';

export const isMobileDevice = (): boolean => {
  const { userAgent } = navigator;
  const AgentUserOs = userAgent.replace(/ /g, '');
  return !!(AgentUserOs.indexOf('Android') !== -1 || navigator.userAgent.match(/iPhone/i));
};

export const isMobileScreen = (): boolean => {
  const { innerWidth } = window;
  return innerWidth <= 768;
};

export const isMobile = (): boolean => isMobileDevice() || isMobileScreen();

export const sendFlutterGetTokenBridgeIsNull = () => {
  if (window.getToken) {
    const oAuth = getExistsToken();
    if (!oAuth) {
      window.getToken.postMessage(false);
    }
  }
};

export const sendFlutterGetTokenBridge = (forceFalse?: boolean) => {
  if (window.getToken) {
    const oAuth = getExistsToken();
    if (forceFalse || !oAuth) {
      window.getToken.postMessage(false);
      return;
    }
    if (!oAuth?.account) {
      oAuth.account = getAccount();
    }
    window.getToken.postMessage(JSON.stringify(oAuth));
  }
};

export const sendBridge = (functionName: string, parameter: unknown): void => {
  const { userAgent } = navigator;
  const AgentUserOs = userAgent.replace(/ /g, '');
  const { SikdaeBridge, webkit } = window;
  let key = functionName;
  try {
    if (AgentUserOs.indexOf('Android') !== -1) {
      if (!SikdaeBridge || !SikdaeBridge[key]) {
        // eslint-disable-next-line no-console
        console.log('식대 브릿지 체크 오류');
        return;
      }
      if (parameter !== null && typeof parameter !== 'undefined') {
        SikdaeBridge[key](JSON.stringify(parameter));
      } else {
        SikdaeBridge[key]();
      }
    }
  } catch (e) {
    // eslint-disable-next-line no-alert
    alert(e);
  }
  // iphone
  if (functionName === 'sendStoreInfo') {
    key = 'SikdaeBridge';
  }
  if (userAgent.match(/iPhone/i)) {
    if (!webkit || !webkit.messageHandlers || !webkit.messageHandlers[key]) {
      // eslint-disable-next-line no-console
      console.log('식대 브릿지 체크 오류');
      return;
    }
    if (parameter !== null && typeof parameter !== 'undefined') {
      webkit.messageHandlers[key].postMessage(JSON.stringify(parameter));
    } else {
      webkit.messageHandlers[key].postMessage();
    }
  }
};

export const getManual = () => {
  const {
    navigator: { userAgent }
  } = window;
  // const userAgent = window.navigator.userAgent;
  const url = 'https://s3.ap-northeast-2.amazonaws.com/vendys.etc/develop-etc-filestorage/2021/20210408153453512.pdf';
  const downLoad = () => {
    const a = document.createElement('a');
    a.href = 'https://sikd.ae/manual-store';
    a.download = '식당대장-사용설명서.pdf';
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    a.remove();
  };
  if (userAgent.match(/Android/i)) {
    if (window.SikdaeBridge && window.SikdaeBridge.download) {
      window.SikdaeBridge.download(url);
    } else {
      downLoad();
    }
    // iphone
  } else if (userAgent.match(/iPhone|iPod/i)) {
    if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.download) {
      window.webkit.messageHandlers.download.postMessage(url);
    } else {
      downLoad();
    }
  } else {
    downLoad();
  }
};
