import React from 'react';
import { useQuery } from 'react-query';
import { MeInfoContext } from 'contexts/MeInfoContext';
import { getMe } from 'apis';
import { SikdaeBridge, MeInfo } from 'interfaces/auth';
import { sendBridge } from 'utils/clientUtils';
import { getToken, setMyStores, setAdminMeInfo, setAdminMode } from 'utils/storages';

export const useMeInfo = () => React.useContext(MeInfoContext);

export const onSuccessMe = (data: MeInfo) => {
  try {
    const { admin, storeList } = data;
    const result: Partial<SikdaeBridge> = {};

    if (admin) {
      result.id = admin.id;
      result.name = '';
    }
    result.accessToken = getToken();
    sendBridge('sendStoreInfo', result);

    /*
    시현 코드 추가
    */
    setAdminMode(true);
    setAdminMeInfo(admin);
    if (storeList && storeList.length) {
      setMyStores(storeList, admin.id);
    }
  } catch (error) {
    console.error(error);
  }
};

export const useGetMe = (enabled: boolean) => {
  const { changeMeInfo } = useMeInfo();
  const onSuccess = (data: MeInfo) => {
    onSuccessMe(data);
    changeMeInfo(data);
  };
  return useQuery<MeInfo>(['getMe'], () => getMe(), { enabled, onSuccess });
};
