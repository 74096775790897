/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useRef, useState } from 'react';
import { ChangePasswordParams } from 'interfaces/auth';
import { useChangeVendysPassword, useSkipChangeVendysPassword, useMeInfo, useForm, useAuth } from 'hooks';
import { afterChangedPasswordAndStorageChange, clearByLogout, getMyInfo, getStoreAdmin } from 'utils/storages';
import { regex } from 'validators';
// import $ from 'jquery';

import PasswordExample from './PasswordExample';

interface ChangePasswordProps {
  userId?: string;
  fromSetting?: boolean;
  openMain?: () => void;
  passwordStatus?: string;
}

const engReg = /[a-zA-Z]+/;
const numberReg = /\d+/;

const ChangePassword = ({ userId, fromSetting, openMain, passwordStatus }: ChangePasswordProps) => {
  const oldPasswordInput = useRef<HTMLInputElement>();
  const passwordInput = useRef<HTMLInputElement>();
  const rePasswordInput = useRef<HTMLInputElement>();
  const changePwdBtnRef = useRef<HTMLButtonElement>(null);

  const { account, setAccount, signOut } = useAuth();
  const { mutateAsync: changePasswordMutate } = useChangeVendysPassword();
  const { mutateAsync: skipChangePasswordMutate } = useSkipChangeVendysPassword();

  const [values, onChange, setValues] = useForm({
    activeOld: false,
    active: false,
    activeRe: false,
    oldPassword: '',
    password: '',
    rePassword: '',
    isPasswordLength: false,
    isIncludeEng: false,
    isIncludeNumber: false,
    isIncludeSpecChar: false,
    isUpRepeat3: false
  });

  const [isOpen, setOpen] = useState<boolean>(false);

  // componentDidMount = () => {
  //   network.hud(false);
  // },

  const clearField = () => {
    setValues({
      oldPassword: '',
      password: '',
      rePassword: '',
      activeOld: false,
      active: false,
      activeRe: false,
      isPasswordLength: false,
      isIncludeEng: false,
      isIncludeNumber: false,
      isIncludeSpecChar: false,
      isUpRepeat3: false
    });
  };

  const changePassword = async () => {
    let loginUserId = userId;
    if (fromSetting && !values?.oldPassword) {
      alert('현재 사용중인 비밀번호를 입력해 주십시오');
      return;
    }

    const meInfo = getMyInfo();
    const storeAdmin = getStoreAdmin();

    if (!loginUserId) {
      loginUserId = storeAdmin.isAdmin ? storeAdmin.said : meInfo.sid;
    }
    const params: ChangePasswordParams = {
      id: loginUserId!,
      type: 'STORE',
      target: values.password,
      source: values.oldPassword,
      reset: 'N'
    };

    await changePasswordMutate(params);
    alert('비밀번호가 변경되었습니다.');
    if (fromSetting) {
      clearField();
      return;
    }
    try {
      afterChangedPasswordAndStorageChange();
      setAccount({ ...account, password: 'NONE' });
    } catch (e) {
      console.error(e);
    } finally {
      openMain?.();
    }
  };

  const skipChange = async () => {
    try {
      await skipChangePasswordMutate();
    } catch (e: unknown) {
      const { response } = e as Record<string, unknown>;
      const { status } = response as Record<string, unknown>;
      if (status === 500) {
        clearByLogout();
        window.location.href = '/';
        return;
      }
    }
    try {
      afterChangedPasswordAndStorageChange();
      setAccount({ ...account, password: 'NONE' });
    } catch (e) {
      console.error(e);
    } finally {
      openMain?.();
    }
  };

  const checkInvalidData = (e) => {
    let flag = true;
    const { value } = e.target;
    if (value.length >= 8 && value.length <= 20) {
      setValues({ isPasswordLength: true });
    } else {
      setValues({ isPasswordLength: false });
      flag = false;
    }

    // 영어 포함
    if (engReg.test(value)) {
      setValues({ isIncludeEng: true });
    } else {
      setValues({ isIncludeEng: false });
      flag = false;
    }

    // 숫자 포함
    if (numberReg.test(value)) {
      setValues({ isIncludeNumber: true });
    } else {
      setValues({ isIncludeNumber: false });
      flag = false;
    }

    // 특수문자 포함
    if (regex.includeSpecReg.test(value)) {
      setValues({ isIncludeSpecChar: true });
    } else {
      setValues({ isIncludeSpecChar: false });
      flag = false;
    }

    setValues({ password: value });

    // 빈값 초기화
    if (!flag) {
      setValues({
        rePassword: '',
        activeRe: false
      });
    }
  };

  const handleOldPassword = (e) => {
    const {
      target: { value }
    } = e;

    // 빈값 초기화
    if (!value) {
      setValues({
        oldPassword: value,
        password: '',
        rePassword: '',
        active: false,
        activeRe: false,
        isPasswordLength: false,
        isIncludeEng: false,
        isIncludeNumber: false,
        isIncludeSpecChar: false
      });
      // this.passwordInput.value = '';
      // this.rePasswordInput.value = '';
    } else {
      onChange(e);
    }
  };

  const focus = (e) => {
    const { id } = e.target;
    if (id === 'newuserpassword' && !values?.active) {
      setValues({ active: true });
    } else if (id === 'reuserpassword' && !values?.activeRe) {
      setValues({ activeRe: true });
    } else if (id === 'oldpassword' && !values?.activeOld) {
      setValues({ activeOld: true });
    }
  };

  const enter = async (e) => {
    if (e.keyCode === 13) {
      if (!changePwdBtnRef.current) {
        return;
      }
      const { disabled } = changePwdBtnRef.current;
      if (disabled) {
        await changePassword();
      }
    }
  };

  const openModal = () => {
    $('#passwordModal').modal('show');
    // setOpen(true);
  };

  const closeModal = () => {
    // $('#passwordModal').modal('hide');
    // setOpen(false);
  };

  const {
    active,
    activeRe,
    activeOld,
    oldPassword,
    password,
    rePassword,
    isPasswordLength,
    isIncludeEng,
    isIncludeNumber,
    isIncludeSpecChar
  } = values || {};

  const VALID = 'valid';
  const INVALID = 'invalid';

  const isPasswordValid = isPasswordLength && isIncludeEng && isIncludeNumber && isIncludeSpecChar;
  const isEqualPassword = password && rePassword && password === rePassword;
  const isValid = password && rePassword && isPasswordValid && isEqualPassword;
  const validStyleHide = active ? { display: 'block' } : { display: 'none' };
  const validRePwdStyleHide = activeRe ? { display: 'block' } : { display: 'none' };
  const validOldPwdStyleHide = activeOld ? { display: 'block' } : { display: 'none' };

  return (
    <div className="password-change-wrap">
      <div className="password-change screen">
        <div className="head">
          {fromSetting ? (
            <div className="password-chnage-header">
              <p>비밀번호 변경을 위해</p>
              <p>현재 사용중인 비밀번호와 새로운 비밀번호를 입력해주세요</p>
            </div>
          ) : (
            <div className="password-chnage-header">
              <p>보안 강화를 위해</p>
              <p>새로운 비밀번호를 설정해주세요</p>
            </div>
          )}
        </div>
        <div className="content">
          {fromSetting ? (
            <div style={{ paddingBottom: '32px' }}>
              <p>현재 사용중인 비밀번호</p>
              <input
                type="password"
                id="oldpassword"
                name="oldPassword"
                placeholder="현재 비밀번호"
                className={`userinput form-control ${oldPassword ? VALID : activeOld ? INVALID : ''}`}
                value={values?.oldPassword}
                onChange={handleOldPassword}
                onFocus={focus}
                // ref={(ref) => {
                //   oldPasswordInput = ref;
                // }}
              />
              <div className="validation-wrap" style={validOldPwdStyleHide}>
                <div className={`validation ${oldPassword ? VALID : INVALID}`}>현재 비밀번호 입력</div>
              </div>
            </div>
          ) : null}
          <p>새로운 비밀번호</p>
          <input
            type="password"
            id="newuserpassword"
            placeholder="새로운 비밀번호"
            className={`userinput form-control ${isPasswordValid ? VALID : password ? INVALID : ''}`}
            disabled={fromSetting ? !oldPassword : false}
            value={values?.password}
            onChange={checkInvalidData}
            onFocus={focus}
            maxLength={20}
            // ref={(ref) => {
            //   passwordInput = ref;
            // }}
          />
          <div className="validation-wrap" style={validStyleHide}>
            <div className={`validation ${isPasswordLength ? VALID : INVALID}`}>8-20자 입력</div>
            <div className={`validation ${isIncludeEng ? VALID : INVALID}`}>영문 입력</div>
            <div className={`validation ${isIncludeNumber ? VALID : INVALID}`}>숫자 입력</div>
            <div className={`validation ${isIncludeSpecChar ? VALID : INVALID}`}>특수문자 입력</div>
          </div>
          <p className="re">비밀번호 재입력</p>
          <p className="desc">방금 입력하신 비밀번호를 한번 더 입력해주세요</p>
          <input
            type="password"
            id="reuserpassword"
            name="rePassword"
            placeholder="새로운 비밀번호 재입력"
            className={`userinput form-control ${password ? (isEqualPassword ? VALID : rePassword ? INVALID : '') : ''}`}
            value={values?.rePassword}
            onChange={onChange}
            onKeyDown={enter}
            disabled={!isPasswordValid}
            onFocus={focus}
            maxLength={20}
            // ref={(ref) => {
            //   rePasswordInput = ref;
            // }}
          />
          <div className="validation-wrap" style={validRePwdStyleHide}>
            <div className={`validation ${isEqualPassword ? VALID : INVALID}`}>동일한 비밀번호 입력</div>
          </div>
        </div>
        <div className="ex">
          <a onClick={openModal}>비밀번호 예제를 보시겠어요?</a>
        </div>
        <div className="btnArea d-grid">
          {passwordStatus === 'RECOMMEND' ? (
            <button type="button" onClick={skipChange} className="btn-default btn btn-block">
              30일 후 변경
            </button>
          ) : null}
          <button
            type="button"
            onClick={changePassword}
            className="loginBtn btn btn-block"
            disabled={!isValid}
            ref={changePwdBtnRef}
          >
            변경하기
          </button>
        </div>
      </div>
      <div className="password-example-modal">
        <PasswordExample show={isOpen} close={closeModal} />
      </div>
    </div>
  );
};

export default ChangePassword;
