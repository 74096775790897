import React, { useState, useEffect } from 'react';
import { Form, Input, Select } from 'antd';
import { minLength, onlyNumberValidator, validateMessages } from 'validators';
import AddressPost from 'components/AddressPost';
import BottomButton from 'components/Button/BottomButton';
import { BizSerialFormItem } from 'components/FormItem';

const { useForm } = Form;

export interface AdmissionStepOneFormProps {
  initValues;
  onSearch;
  searchLoading;
  onNextStep;
  loading;
  mainCategories;
}

const AdmissionStepOneForm = ({
  initValues,
  onSearch,
  searchLoading,
  onNextStep,
  loading,
  mainCategories
}: AdmissionStepOneFormProps) => {
  const [form] = useForm();
  const [, forceUpdate] = useState({});

  // To disable submit button at the beginning.
  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    if (initValues && initValues.length) {
      const value = { ...initValues[0] };
      if (typeof value?.mainCategoryIdx === 'string') {
        value.mainCategoryIdx = value?.mainCategoryIdx.split(',');
      }
      const { zoneCode, addressType, addressDetail, jibunAddress, roadAddress, bizSerial } = initValues[0];
      value.address = {
        zoneCode,
        addressType,
        addressDetail,
        jibunAddress,
        roadAddress
      };
      value.bizSerial = { value: bizSerial, status: 'changed' };
      form.setFieldsValue(value);
      if (bizSerial) form.submit();
    } else {
      form.resetFields();
    }
    return () => {
      form.resetFields();
    };
  }, [form, initValues]);

  const handleForm = (values) => {
    const { bizSerial } = values;
    const { settlementReceiveEmail } = initValues[0];
    if (!bizSerial?.value) return;
    onNextStep({ ...values, bizSerial: bizSerial.value, settlementReceiveEmail });
  };

  return (
    <Form
      autoComplete="off"
      layout="vertical"
      name="admission_step1_form"
      form={form}
      onFinish={handleForm}
      validateMessages={validateMessages}
    >
      <BizSerialFormItem onSearch={onSearch} isSearching={searchLoading} />

      <Form.Item name="presidentName" label="대표자 이름">
        <Input placeholder="대표자 이름 입력" disabled />
      </Form.Item>
      <Form.Item name="presidentPhone" label="대표자 전화번호">
        <Input placeholder="대표자 전화번호 입력" disabled />
      </Form.Item>
      <Form.Item name="storeName" label="상호명" rules={[{ required: true }]}>
        <Input placeholder="상호명 입력" />
      </Form.Item>
      <Form.Item
        name="storePhone"
        label="상호 전화번호"
        rules={[{ required: true }, onlyNumberValidator<string>('-없이 숫자만 입력'), minLength<string>(7)]}
      >
        <Input placeholder="-없이 숫자만 입력" />
      </Form.Item>
      <Form.Item name="address" label="사업장 주소" rules={[{ required: true }]}>
        <AddressPost />
      </Form.Item>
      <Form.Item name="mainCategoryIdx" label="업종" rules={[{ required: true }]}>
        <Select mode="multiple">
          {mainCategories &&
            mainCategories.length &&
            mainCategories.map((category) => <Select.Option key={category.idx}>{category.name}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name="storeIntro" label="제휴점 소개" rules={[{ required: true }]}>
        <Input placeholder="운영시간 9시~18시(20자 이내)" maxLength={20} />
      </Form.Item>
      <Form.Item shouldUpdate>
        {() => {
          const isTouched =
            form.isFieldTouched('bizSerial') &&
            form.isFieldTouched('storeName') &&
            form.isFieldTouched('storePhone') &&
            form.isFieldTouched('address') &&
            form.isFieldTouched('mainCategoryIdx');

          return (
            <BottomButton
              type="primary"
              htmlType="submit"
              disabled={!isTouched || !!form.getFieldsError().filter(({ errors }) => errors.length).length}
              loading={loading}
            >
              다음
            </BottomButton>
          );
        }}
      </Form.Item>
    </Form>
  );
};

export default AdmissionStepOneForm;
