import { Button, Modal } from 'antd';
import React from 'react';
import styled from 'styled-components';

const StyledModal = styled(Modal)`
  .ant-modal-close-x {
    color: black;
  }

  .ant-modal-header {
    background: #eee;
  }

  .ant-modal-body {
    text-align: center;
    padding: 0;
  }
`;

interface DefaultModalProps {
  component: React.ReactNode;
  heading: React.ReactNode;
  description?: React.ReactNode;
  open: boolean;
  close: (v: React.MouseEvent<HTMLElement> | string | React.SyntheticEvent<unknown>) => void;
  handleOk?: (v: React.MouseEvent<HTMLElement> | string | React.SyntheticEvent<unknown>) => void;
}

const DefaultModal = ({ component, heading, description, open, close, handleOk }: DefaultModalProps) => {
  return (
    <StyledModal
      width={380}
      title={heading}
      open={open}
      centered
      footer={
        handleOk
          ? [
              <Button key="close" onClick={close}>
                취소
              </Button>,
              <Button key="submit" type="primary" onClick={handleOk}>
                확인
              </Button>
            ]
          : null
      }
      onCancel={close}
    >
      {description && <p>{description}</p>}
      {component}
    </StyledModal>
  );
};

export default DefaultModal;
