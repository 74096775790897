import { BankAccountForm, modalMethod } from 'components';
import React, { useMemo } from 'react';
import errorHandler from 'apis/errorHandler';
import {
  useValidBankAccount,
  useUpdateSettlementBankAccount,
  useMeInfo,
  useSettlementMetaInfo,
  useAdmissionHistory
} from 'hooks';
import { SettlementDescription, SettlementTextStyleDiv, SettlementTextStyleSpan, SettlementUl } from '../styles';

const BankAccount = ({ bankCodes }) => {
  const { selectedStore, meInfo } = useMeInfo();
  const { data: metaInfo } = useSettlementMetaInfo(selectedStore?.sid || '');
  const { data: admissionHistory } = useAdmissionHistory(meInfo.admin.said || '');
  const { isLoading: isChecking, mutateAsync: validBankAccount } = useValidBankAccount();
  const { isLoading: isUpdating, mutateAsync: updateBankAccount } = useUpdateSettlementBankAccount();

  const handleSearchBankAccount = async (param) => {
    const result = await validBankAccount(param);
    return result;
  };

  const handleUpdateBankAccount = async (values) => {
    if (selectedStore?.sid) {
      try {
        const {
          accountFile,
          accountHolder,
          bank: { bankCode, bankAccount }
        } = values;
        const params = {
          accountHolder,
          bankCode,
          accountNo: bankAccount,
          accountFile: accountFile[0]
        };
        await updateBankAccount({ storeId: selectedStore.sid, bankAccount: { ...params } });
        modalMethod.info(
          <>
            <div>정산은 정보가 변경된 시점을 기준으로</div>
            <SettlementTextStyleDiv>당월분(익월 지급)부터 적용됩니다.</SettlementTextStyleDiv>
            <div>(입금 전 계좌정보 변경 &gt; 다음 달부터 변경정보 적용)</div>
          </>
        );
      } catch (error) {
        errorHandler(error);
      }
    }
  };

  const isPreparationHistory = useMemo(() => {
    return !!admissionHistory?.historyList.filter(
      ({ type, status, storeId }) => type === 'BANK_ACCOUNT' && status === 'PREPARATION' && storeId === selectedStore?.sid
    ).length;
  }, [admissionHistory?.historyList, selectedStore?.sid]);

  return (
    <>
      <SettlementDescription>
        <SettlementUl>
          <div>
            <div>계좌 정보를 변경할 시, 정산은 정보가 변경된 시점을</div>
            <div>
              기준으로 <SettlementTextStyleSpan>당월분(익월 지급)부터 적용됩니다.</SettlementTextStyleSpan>
            </div>
            <div>(입금 전 계좌정보 변경 &gt; 다음 달부터 변경정보 적용)</div>
          </div>
          <br />
          <div>
            <div>수정된 계좌 정보는 사업자 등록증과 통장 사본을</div>
            <div>대조한 후, 소유주 명의가 아닌 경우 요청이 거절됩니다.</div>
          </div>
        </SettlementUl>
      </SettlementDescription>
      <BankAccountForm
        initValue={metaInfo}
        onSearch={handleSearchBankAccount}
        isSearching={isChecking}
        bankCodes={bankCodes}
        onSubmit={handleUpdateBankAccount}
        isLoading={isUpdating}
        isPreparationHistory={isPreparationHistory}
      />
    </>
  );
};

export default BankAccount;
