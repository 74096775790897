import styled from 'styled-components';
import { Modal, Collapse } from 'antd';
import CheckboxUnchecked from '../../assets/images/storeImage/checkbox_unchecked.svg';
import CheckboxChecked from '../../assets/images/storeImage/checkbox_checked.svg';

export const Container = styled.div`
  background-color: #ffffff;
  max-width: 708px;
  min-height: 890px;
  padding: 30px;
  .header {
    padding-bottom: 24px;
  }
  .title {
    font-weight: 600;
    font-size: 24px;
  }
  .description {
    font-size: 18px;
    color: grey;
  }
  button {
    background: inherit;
    border: none;
    box-shadow: none;
    border-radius: 0;
    padding: 0;
    overflow: visible;
    color: #afb2b9;
    text-align: center;
    cursor: pointer;
  }
  .help-description {
    display: flex;
    justify-content: center;
  }
  .flex-space-between {
    display: flex;
    justify-content: space-between;
  }
  .register-guide-btn {
    display: flex;
    width: 100px;
    justify-content: flex-end;
    align-items: center;

    img {
      width: 20px;
      height: 20px;
    }
    div {
      padding-left: 3px;
      font-size: 16px;
      color: #46b476;
    }
  }
`;

export const SubContainer = styled.div`
  min-width: 648px;
  min-height: 88px;
  border: 1px solid #e2e2e2;
  margin-bottom: 24px;
  cursor: pointer;

  .direction {
    display: flex;
    justify-content: space-between;
    padding: 24px 16px;
  }

  .space-between {
    img {
      padding-right: 8px;
    }
  }
  .list {
    font-size: 18px;
    padding-right: 8px;
  }
  .tag {
    border-radius: 10px;
    font-size: 14px;
    text-align: center;
  }
`;

export const ImageModal = styled(Modal)`
  // 모달 아웃라인 스타일링
  overflow-y: scroll;
  min-width: 708px;
  position: absolute;
  left: 35%;
  .ant-modal-content,
  .ant-modal-header {
    border-radius: 10px;
  }
  // 이미지 등록
  .ant-modal-header {
    background-color: #f7f7f7;
    padding: 0;
    border-radius: 10px 10px 0 0;
    border-bottom: 1px solid #e2e2e2;
    .header {
      font-size: 22px;
      font-weight: 600;
      height: 68px;
      padding-top: 23px;
      padding-left: 30px;
    }
  }
  // footer
  .ant-modal-footer {
    border-top: 1px solid #e2e2e2;
  }
  // 닫기, 저장 버튼
  .footer {
    width: 100%;
    max-height: 68px;
  }
  .close-btn {
    margin-right: 10px;
  }
  // {썸네일, 대표이미지, 메뉴판이미지} 등록
  .title {
    font-size: 24px;
    font-weight: 600;
  }
  .title-description {
    font-size: 18px;
    font-weight: 200;
    color: #6d7582;
  }
  // 등록해주세요 문구 가이드
  .modal-box {
    padding-bottom: 24px;
  }
  .modal-inner-box {
    padding-bottom: 5px;
  }
  .guide-description {
    font-size: 14px;
    font-weight: 600;
  }
  .guide-mandatory {
    color: #46b476;
    padding-left: 2px;
  }
`;

interface ImageEnrollBoxProps {
  isImage?: boolean;
}

export const ImageEnrollBox = styled.div<ImageEnrollBoxProps>`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  // 기본사진 박스(첫번째)
  .empty-image-container {
    position: relative;
    width: 147px;
    height: 147px;
    border: 2px dashed #e2e2e2;
    border-radius: 3px;
  }
  .empty-image-container .container-center {
    width: 100%;
    height: 100%;
    position: relative;
    top: 33%;
    left: 40%;
  }
  .image-count {
    font-size: 14px;
    span:nth-child(1) {
      color: ${({ isImage }) => (isImage ? '#afb3b8' : '#46b476')};
    }
    span:nth-child(2) {
      color: #afb3b8;
    }
  }
  label {
    width: 147px;
    height: 147px;
    :hover {
      cursor: pointer;
    }
  }
  input[type='file'] {
    position: absolute;
    width: 0;
    height: 0;
    padding: 0;
    overflow: hidden;
    border: 0;
  }
`;

interface TRegisterImgBox {
  imgIdx: number;
  blobData?: string | Blob | null | undefined;
}

export const RegisterImgBox = styled.div<TRegisterImgBox>`
  margin-left: ${({ imgIdx }) => (imgIdx !== 3 ? '24px' : '0px')};
  cursor: ${({ imgIdx }) => (imgIdx === 0 ? 'default' : 'move')};

  &:hover {
    transform: ${({ imgIdx }) => (imgIdx === 0 ? 'none' : 'translateY(-2px)')};
    transition: transform 0.2s ease;
  }

  &:active {
    transform: translateY(0);
  }
  // 등록이미지
  .enrolled-image-container {
    position: relative;
  }
  .enrolled-image-delete-btn {
    position: absolute;
    top: 3%;
    right: 3%;
    z-index: 300;
  }
  .enrolled-image-numbering {
    position: absolute;
    top: 37%;
    left: 46%;
    color: #ffffff;
    font-size: 20px;
    font-weight: 600;
    z-index: 300;
    opacity: ${({ blobData }) => (blobData ? '100%' : '50%')};
  }
  .enrolled-image-crop {
    width: 20px;
    height: 20px;
    position: absolute;
    z-index: 300;
    top: 82%;
    left: 82%;
    opacity: ${({ blobData }) => (blobData ? '100%' : '50%')};
  }
  .enrolled-image-dimmed {
    width: 147px;
    height: 147px;
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 200;
    border-radius: 3px;
  }
  .enrolled-image {
    width: 147px;
    height: 147px;
    border: 1px solid #e2e2e2;
    border-radius: 3px;
    overflow: hidden;
    z-index: 100;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;

interface TCropBox {
  imgType?: string;
  imgCount?: number;
}

export const CropBox = styled.div<TCropBox>`
  padding-top: ${({ imgType, imgCount }) => (imgType === 'THUMBNAIL' || imgCount < 4 ? '0px' : '20px')};
  .crop-container {
    padding-bottom: 10px;
  }
  .cropper {
    width: 100%;
    height: 400px;
  }
`;

interface CollapseProps {
  checkbox?: boolean;
}

export const CollapseBox = styled(Collapse)<CollapseProps>`
  border-radius: 4px;
  overflow: hidden;
  .ant-collapse {
    border: 3px solid #e2e2e2;
  }
  .expand-icon {
    color: #afb2b9;
    text-decoration: underline;
    font-size: 14px;
  }
  // Collapse Header
  .ant-collapse-header.ant-collapse-icon-collapsible-only {
    background-color: #ffffff;
  }
  &&.ant-collapse.ant-collapse-icon-position-end {
    border: 1px solid #e2e2e2;
  }
  &&.ant-collapse .ant-collapse-item {
    border-bottom: none;
  }
  // 체크박스 버튼
  input[type='checkbox'] {
    display: none;
  }
  .ant-checkbox-checked {
    &:after {
      border: none;
    }
  }
  .ant-checkbox-wrapper.checkbox-round {
    display: flex;
    align-items: center;
  }
  .ant-checkbox {
    display: none;
    padding-bottom: 6px;
  }
  .ant-checkbox-inner {
    border-radius: 50%;
    width: 22px;
    height: 22px;
  }
  .checkbox-round {
    vertical-align: middle;
    font-size: 16px;
    font-weight: ${({ checkbox }) => (checkbox ? 600 : 300)};
    color: ${({ checkbox }) => (checkbox ? '#46b476' : '#6d7582')};
    background-image: ${({ checkbox }) => (checkbox ? `url(${CheckboxChecked})` : `url(${CheckboxUnchecked})`)};
    background-repeat: no-repeat;
    span:nth-child(2) {
      padding-left: 30px;
    }
  }
  // 안내사항 내용
  .checkbox-inner-content {
    font-size: 14px;
    color: #6d7582;
    font-weight: 200;
    background-color: #f7f7f7;
    padding: 16px;
  }
  .ant-collapse-content-box {
    padding: 0;
  }
  .ant-collapse-content {
    border-top: 1px solid #e2e2e2;
  }
`;

export const RollingModal = styled(Modal)`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  min-width: 708px;
  max-height: 867px;

  .ant-modal-content,
  .ant-modal-header {
    border-radius: 10px;
  }
  .ant-modal-close {
    display: none;
  }
  .ant-modal-body {
    padding: 0;
  }

  img {
    width: 100%;
    height: 100%;
  }
`;
