import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, ConfigProvider } from 'antd';
import { isMobile } from 'utils/clientUtils';

export interface RouteModalProps {
  title?: string;
  onCancel?;
  children?: React.ReactNode;
}

const RouteModal: React.FC<RouteModalProps> = ({ title, onCancel, children }) => {
  const navigate = useNavigate();
  const [isVisible, setVisible] = useState(true);

  const handleCancel = () => {
    setVisible(false);
    setTimeout(() => {
      navigate(-1);
    }, 200);
  };

  const isFullWidth = isMobile() ? '100%' : undefined;

  return (
    <Modal
      className="router-modal"
      transitionName="ant-move-down"
      open={isVisible}
      title={title}
      style={{ top: isMobile() ? 0 : 5, maxWidth: isFullWidth, minHeight: isFullWidth }}
      onCancel={onCancel || handleCancel}
      footer={null}
      maskClosable={false}
      width={isFullWidth}
    >
      <ConfigProvider componentSize="large">{children}</ConfigProvider>
    </Modal>
  );
};

export default RouteModal;
