/* eslint-disable @typescript-eslint/no-unused-vars */
import classNames from 'classnames';
import { DateModal, DateRangeNavigation } from 'components';
import { useCloseReport, useDeleteComment, useMeInfo, useReviews, useSaveComment, useUpdateComment } from 'hooks';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { flushSync } from 'react-dom';
import { generate } from 'shortid';
import { handlerKeyUp, isMobile as mobileCheck } from 'utils';
import { numberWithCommas } from 'utils/stringUtils';
// import DateModalMobile from './date-modal-mobile';
import PhotoViewer from './ReviewImageModal';
import Report from './ReviewReportModal';

const Review = () => {
  const { selectedStore } = useMeInfo();
  const [state, setState] = useState({
    startDate: moment().startOf('month'),
    endDate: moment(),
    edit: '',
    paging: {
      page: 0,
      pageRow: 0
    },
    reviews: [],
    type: 'ALL',
    mobileOpen: false,
    expended: '',
    reportOpen: false,
    selectedReview: null,
    imageOpen: false,
    imageUrl: '',
    commentIdx: ''
  });

  const [reviewParams, setReviewParams] = useState({
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    exposureType: 'ALL'
  });

  const handleExpend = (expended?: string, i?: number) => {
    if (expended && typeof i === 'number') {
      const { reviews } = state;
      const { commentInfo = {} }: { commentInfo: Record<string, string> } = reviews[i] || {};
      setState({
        ...state,
        expended,
        edit: commentInfo && commentInfo.content,
        commentIdx: commentInfo && commentInfo.commentIdx
      });
    } else {
      setState((prevState) => {
        return { ...prevState, expended: '', edit: '' };
      });
    }
  };

  const onSuccess = (reviewData) => {
    const { reviews } = reviewData;
    setState((prevState) => {
      return { ...prevState, reviews };
    });
    handleExpend();
  };

  const { data: reviewData } = useReviews(reviewParams, {
    enabled: !!selectedStore?.sid,
    onSuccess: (e) => {
      onSuccess(e);
    },
    onError: () => {
      handleExpend();
    }
  });

  const { mutate: closeReportMutate } = useCloseReport();
  const { mutateAsync: saveCommentMutateAsync, isLoading: isSaveLoading } = useSaveComment();
  const { mutateAsync: updateCommentMutateAsync, isLoading: isUpdateLoading } = useUpdateComment();
  const { mutateAsync: deleteCommentMutateAsync } = useDeleteComment();

  const dateModel = () => {
    // if (global.mobilechk()) {
    //   // is mobile size
    //   modalToggleClass();

    //   // $('#mobileView').show();
    //   setState({ ...state, mobileOpen: true });
    //   ReactDom.render(<SidebarToggle type="none" />, document.getElementById('stoggle'));
    //   ReactDom.render(<GnbTitle text="날짜 선택" />, document.getElementById('title'));
    //   ReactDom.render(<GnbRightRefresh mode="close" onClick={backEvent} />, document.getElementById('gnbright'));
    // } else {
    //   $('#dateModal').modal('show');
    // }
    // TODO delete bootstrap
    // eslint-disable-next-line
    // @ts-ignore
    $('#dateModal').modal('show');
  };

  const dateHandle = (range) => {
    setState({
      ...state,
      startDate: range.startDate,
      endDate: range.endDate
    });
    setReviewParams({
      ...reviewParams,
      startDate: range.startDate.format('YYYY-MM-DD'),
      endDate: range.endDate.format('YYYY-MM-DD')
    });
    // TODO delete bootstrap
    // eslint-disable-next-line
    // @ts-ignore
    $('#dateModal').modal('hide');
  };

  const closeReport = (result) => {
    const { reason } = result || {};
    if (reason) {
      const { selectedReview } = state;
      if (!selectedReview) {
        alert('선택된 리뷰가 없습니다.');
        return;
      }
      const { reasonDetail, requesterName, requesterPhone } = result;
      const data = {
        reportReasonType: reason,
        content: reasonDetail,
        requesterName,
        requesterPhone
      };
      closeReportMutate(
        { reviewIdx: selectedReview, saveData: data },
        {
          onSuccess: () => {
            alert('이의 신청 접수 완료되었습니다.');
          },
          onError: (e) => {
            const { responseJSON } = (e as Record<string, Record<string, unknown>>) || {};
            if (responseJSON && responseJSON.status) {
              alert(responseJSON.message);
            }
          }
        }
      );
    }
    flushSync(() => {
      setState({ ...state, reportOpen: false });
    });
  };

  const openReport = (idx) => {
    setState({ ...state, reportOpen: true, selectedReview: idx });
  };

  const changeType = (type: string) => {
    window.scrollTo(0, 0);
    setReviewParams({ ...reviewParams, exposureType: type });
    // setState({ type }, () => getReviews());
  };

  const handleChangeEdit = ({ target }) => {
    const { name, value } = target;
    setState({ ...state, [name]: value });
  };

  const modifyComment = async (reviewIdx, commentIdx) => {
    // eslint-disable-next-line no-restricted-globals
    if (!confirm('댓글을 수정하시겠습니까?')) {
      return;
    }
    const { edit } = state;
    if (!commentIdx) {
      alert(`must be required commentIdx`);
      return;
    }
    const data = {
      content: edit
    };
    try {
      await updateCommentMutateAsync({
        reviewIdx,
        commentIdx,
        saveData: data
      });
      alert('댓글이 수정되었습니다.');
    } catch (error) {
      handleExpend();
      const { responseJSON } = (error as Record<string, Record<string, string>>) || {};
      if (responseJSON && responseJSON.status) {
        alert(responseJSON.message);
      }
    }
  };

  const registerComment = async (reviewIdx) => {
    const { edit, commentIdx } = state;
    if (!edit) {
      alert('댓글을 입력해주세요.');
      return;
    }
    if (edit.length < 10 || edit.length > 3000) {
      alert('10자 이상 3000자 이하의 댓글을 입력해주세요.');
      return;
    }
    const data = {
      content: edit
    };
    if (commentIdx) {
      await modifyComment(reviewIdx, commentIdx);
    } else {
      try {
        await saveCommentMutateAsync({ reviewIdx, saveData: data });
        alert('댓글이 등록되었습니다.');
      } catch (error) {
        handleExpend();
        const { responseJSON } = (error as Record<string, Record<string, unknown>>) || {};
        if (responseJSON && responseJSON.status) {
          alert(responseJSON.message);
        }
      }
    }
  };

  const deleteComment = async (reviewIdx, commentIdx) => {
    // eslint-disable-next-line no-restricted-globals
    if (!confirm('댓글을 정말 삭제하시겠습니까?')) {
      return;
    }
    if (!commentIdx) {
      alert(`must be required commentIdx`);
      return;
    }
    try {
      await deleteCommentMutateAsync({ reviewIdx, commentIdx });
      alert('댓글이 삭제되었습니다.');
    } catch (error) {
      handleExpend();
      const { responseJSON } = (error as Record<string, Record<string, unknown>>) || {};
      if (responseJSON && responseJSON.status) {
        alert(responseJSON.message);
      }
    }
  };

  const openImage = (imageUrl) => {
    setState({ ...state, imageOpen: true, imageUrl });
  };
  const closeImage = () => {
    setState({ ...state, imageOpen: false });
  };

  const { startDate, endDate, expended, edit, reportOpen, imageOpen, imageUrl } = state;
  const { exposureType } = reviewParams;
  const startDateFormat = startDate.format('YYYY. M. DD.');
  const endDateFormat = endDate.format('YYYY. M. DD.');
  const isMobile = mobileCheck();

  return (
    <div className="salesstats-content" id="review">
      <div className="review-container">
        <div className="content-header-area">
          {/* <div className="date">
            <div className="left">조회기간</div>
            <div className="right" role="button" tabIndex={0} onClick={dateModel} onKeyUp={handlerKeyUp(dateModel)}>
              <div className="todate" />
              <div className="string">
                {startDateFormat} - {endDateFormat}
              </div>
            </div>
          </div> */}
          {/* <div className="date">
            <div className="left">조회기간</div>
            <div className="right" onClick={dateModel} onKeyUp={handlerKeyUp(dateModel)} role="button" tabIndex={0}>
              <div className="todate">
                <div className="string">
                  {startDateFormat} - {endDateFormat}
                </div>
              </div>
            </div>
          </div> */}
          <DateRangeNavigation startDate={startDate} endDate={endDate} onChange={dateHandle} month={6} />
          <div className="selectType">
            <div
              id="selectType1"
              className={classNames('type1', { active: exposureType === 'ALL' })}
              onClick={() => changeType('ALL')}
              onKeyUp={handlerKeyUp(() => changeType('ALL'))}
              role="tab"
              tabIndex={0}
            >
              전체({numberWithCommas(reviewData?.countInfo?.total || 0)})
            </div>
            <div
              id="selectType2"
              className={classNames('type2', { active: exposureType === 'NOT_REPLY' })}
              onClick={() => changeType('NOT_REPLY')}
              onKeyUp={handlerKeyUp(() => changeType('NOT_REPLY'))}
              role="tab"
              tabIndex={0}
            >
              미답변({numberWithCommas(reviewData?.countInfo?.notReply || 0)})
            </div>
            <div
              id="selectType3"
              className={classNames('type3', { active: exposureType === 'BLOCK' })}
              onClick={() => changeType('BLOCK')}
              onKeyUp={handlerKeyUp(() => changeType('BLOCK'))}
              role="tab"
              tabIndex={0}
            >
              차단({numberWithCommas(reviewData?.countInfo?.block || 0)})
            </div>
          </div>
        </div>
        <div className="content-area">
          <ul className="list-group list-group-flush">
            {reviewData?.reviews &&
              reviewData.reviews.map(
                (
                  {
                    reviewIdx,
                    content,
                    nickname,
                    commentInfo,
                    score = 0,
                    images,
                    menuName,
                    createDate,
                    likeCount,
                    reportCount,
                    isReportByMe
                  },
                  i
                ) => {
                  const expendedType = `review${i}`;
                  const scoreString = score.toString().replace('.', '');
                  return (
                    <li key={expendedType} className="list-group-item">
                      <div className="card">
                        <div className="card-header">
                          <ul className="review-score list-inline">
                            <li className="list-inline-item">
                              <p>{nickname}</p>
                              <div className={`stars rating${scoreString}`} aria-label="Rating" />
                              <small className="text-muted">{moment(createDate).format('YYYY-MM-DD')}</small>
                            </li>
                            <li className="list-inline-item">
                              {isReportByMe ? (
                                <span className="material-icons">done</span>
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn-outline-secondary background-color-transparent"
                                  onClick={() => openReport(reviewIdx)}
                                >
                                  이의제기
                                </button>
                              )}
                            </li>
                          </ul>
                        </div>
                        <div className="card-body">
                          {/* <h5 className="card-title">Card title</h5> */}
                          <div className="card-text content">{content}</div>
                          <div className="gallery">
                            {images && images.length
                              ? images.map(({ main, thumb }) => (
                                  <figure
                                    role="presentation"
                                    className="gallery-item"
                                    key={generate()}
                                    onClick={() => openImage(main)}
                                    onKeyUp={handlerKeyUp(() => {
                                      openImage(main);
                                    })}
                                  >
                                    <img src={thumb} loading="lazy" alt={thumb.split('/').pop()} />
                                  </figure>
                                ))
                              : null}
                          </div>
                          <div>
                            <ul className="review-score list-inline">
                              <li className="list-inline-item">
                                <p className="menu">{menuName}</p>
                              </li>
                              <li className="list-inline-item">
                                <div className="like-report">
                                  도움 {likeCount} 신고 {reportCount}
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="comment">
                        {expended === expendedType ? (
                          <div className="mb-3">
                            {commentInfo ? (
                              <label htmlFor={`formControlTextarea${i}`} className="form-label">
                                {commentInfo.nickname || '사장님'}
                                <span className="owner-reg-date">
                                  {moment(commentInfo.createDate).format('YYYY-MM-DD HH:mm')}
                                </span>
                              </label>
                            ) : null}
                            <textarea
                              className="form-control"
                              name="edit"
                              onChange={handleChangeEdit}
                              rows={3}
                              id={`formControlTextarea${i}`}
                              placeholder="내용을 입력해주세요"
                              minLength={10}
                              maxLength={3000}
                              value={edit}
                            />
                            <div className="comment-button-area">
                              <button
                                type="button"
                                className="btn btn-outline-success background-color-transparent"
                                onClick={() => registerComment(reviewIdx)}
                                disabled={isSaveLoading}
                              >
                                등록
                              </button>
                              <button
                                type="button"
                                className="btn btn-outline-danger background-color-transparent"
                                onClick={() => handleExpend('', i)}
                              >
                                취소
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div>
                            {commentInfo ? (
                              <div className="reade-only">
                                <h5 className="">
                                  {commentInfo.nickname || '사장님'}
                                  <span className="owner-reg-date">
                                    {moment(commentInfo.createDate).format('YYYY-MM-DD HH:mm')}
                                  </span>
                                </h5>
                                <div className="content">{commentInfo.content}</div>
                                <div className="comment-button-area">
                                  <button
                                    type="button"
                                    className="btn btn-outline-success background-color-transparent"
                                    onClick={() => handleExpend(expendedType, i)}
                                    disabled={isUpdateLoading}
                                  >
                                    수정
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-outline-danger background-color-transparent"
                                    onClick={() => deleteComment(reviewIdx, commentInfo.commentIdx)}
                                  >
                                    삭제
                                  </button>
                                </div>
                              </div>
                            ) : null}
                            {!commentInfo ? (
                              <button
                                type="button"
                                className={`btn${isMobile ? ' btn-block btn-success' : ' btn-sikdae'}`}
                                style={{ width: 110 }}
                                onClick={() => handleExpend(expendedType, i)}
                              >
                                댓글 등록하기
                              </button>
                            ) : null}
                          </div>
                        )}
                      </div>
                    </li>
                  );
                }
              )}
          </ul>
        </div>
        <DateModal startDate={startDate} endDate={endDate} dateSelected={dateHandle} month={6} />
      </div>

      <Report open={reportOpen} close={closeReport} />
      <PhotoViewer open={imageOpen} imageUrl={imageUrl} close={closeImage} />
    </div>
  );
};

export default Review;
