/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { flushSync } from 'react-dom';
import { Collapse, Modal } from 'antd';
import {
  useSalesStatsGraph,
  useExcelDownload,
  useMeInfo,
  useSalesStatsListDetail,
  useSalesStatsList,
  useSalesStatsSales
} from 'hooks';
import { numberWithCommas, isMobile, getMobileParams } from 'utils';
import { DateRangeNavigation, ColumnChart, DonutChart } from 'components';
import classNames from 'classnames';
import paymentTypeSalesTip from 'images/paymentTypeSalesTip.png';
import SalesStatsTable from './SalesStatsTable';
import PaymentStatsTable from './PaymentStatsTable';

const { Panel } = Collapse;

const SalesStats = () => {
  const { changeExcelParams } = useExcelDownload();
  const { selectedStore } = useMeInfo();

  const checkGraph = () => {
    let isGraph = true;
    if (isMobile()) {
      const { os, osVersion } = getMobileParams();
      if (os?.toLowerCase() === 'android' && osVersion?.split('(')[1].split(')')[0] < 19) {
        isGraph = false;
      }
    }
    return isGraph;
  };

  const [open, setOpen] = useState(true);
  const [state, setState] = useState({
    startDate: moment().date(1),
    endDate: moment(),
    type: 'DATE',
    openDetail: false,
    content: -1,
    menuName: true,
    menuCount: true,
    totalPrice: true,
    sortType: null,
    page: 1,
    paging: 100,
    graph: checkGraph(),
    firstload: true,
    colorSet: ['#61C282', '#647FB3', '#A294D6', '#FB828A', '#FFB982', '#F0E46B'],
    colorTable: {},
    graphData: [],
    openMonth: 0,
    selectKey: 0,
    sortColumn: ''
  });

  const [monthList, setMonthList] = useState<any[]>([moment().format('YYYY-MM')]);

  const [graphParams, setGraphParams] = useState({
    startDate: moment().date(1).format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    kind: 'PAYMENT'
  });

  const [detailParams, setDetailParams] = useState({
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    month: '',
    kind: 'PAYMENT'
  });

  const [salesStatsParams, setSalesStatsParams] = useState({
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    kind: 'PAYMENT'
  });

  useEffect(() => {
    changeExcelParams({ date1: state.startDate, date2: state.endDate, filterData: { orderStatus: graphParams.kind } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphParams]);

  const getDetail = useCallback(
    (year, month, key) => {
      const monthStr = year.toString() + month.toString();
      const startDate = state.startDate.format('YYYY-MM-DD');
      const endDate = state.endDate.format('YYYY-MM-DD');

      const params = { kind: salesStatsParams.kind, month: monthStr, startDate, endDate };
      setDetailParams(params);
      setState({ ...state, openMonth: key, openDetail: true });
    },
    [salesStatsParams.kind, state]
  );

  const { data } = useSalesStatsGraph(graphParams, selectedStore?.sid);
  const { data: salesData } = useSalesStatsSales(graphParams, selectedStore?.sid);
  const { data: salesStats } = useSalesStatsList(salesStatsParams, selectedStore?.sid, {
    onSuccess: (result) => {
      changeExcelParams({ date1: state.startDate, date2: state.endDate, filterData: { orderStatus: graphParams.kind } });
      if (!result?.stats?.length) {
        return;
      }
      const [{ year, monthStats }] = result.stats;
      const [row1] = monthStats;
      const { month } = row1;
      getDetail(year, month, 1);
    }
  });
  const { data: detailData } = useSalesStatsListDetail(detailParams, selectedStore?.sid);

  const { colorTable, graphData } = useMemo(() => {
    if (!data?.dataList?.length) {
      return { colorTable: {}, graphData: [] };
    }
    let j = 0;
    const colorMap = {};

    if (graphParams.kind === 'DATE') {
      const result = [];
      result.push(['월', '금액', { role: 'tooltip' }]);
      data.dataList.forEach((c) => {
        const convertDate = monthList[j];
        j += 1;
        const tooltip = `${convertDate}:\n${numberWithCommas(c.totalPrice)}원`;
        result.push([convertDate, c.totalPrice / 10000, tooltip]);
      });
      return { colorTable: {}, graphData: result };
    }
    if (graphParams.kind === 'MENU' || graphParams.kind === 'COMPANY') {
      let target = 'menuName';
      if (graphParams.kind === 'COMPANY') target = 'comName';

      const result = [];
      result.push(['월', '메뉴']);
      if (data.dataList.length <= 6) {
        data.dataList.forEach((c, i) => {
          if (c.totalPrice >= 0) {
            result.push([c[target], c.totalPrice]);
            colorMap[c[target]] = state.colorSet[i];
          }
        });
      } else if (data.dataList.length > 6) {
        let others = 0;
        data.dataList.forEach((c, idx) => {
          if (c.totalPrice >= 0) {
            if (idx >= 5) {
              others += c.totalPrice;
            } else {
              result.push([c[target], c.totalPrice]);
              colorMap[c[target]] = state.colorSet[idx];
            }
          }
        });
        result.push(['기타', others]);
      }

      return { colorTable: colorMap, graphData: result };
    }
    return { colorTable: {}, graphData: [] };
  }, [data?.dataList, graphParams.kind, state.colorSet, monthList]);

  const getMonthData = useCallback(
    (year, month, key) => {
      if (state.openDetail && state.openMonth === key) {
        // setState({ detailData: null, openDetail: false });
        setState({ ...state, openDetail: false });
        return;
      }

      getDetail(year, month, key);
    },
    [getDetail, state]
  );

  const contents = useMemo(() => {
    if (!salesStats?.stats?.length) {
      return [];
    }
    const useDataArr = [];
    let key = 1;

    salesStats.stats.forEach((e) => {
      const { year, monthStats } = e;
      useDataArr.push({ type: 'year', year });

      monthStats.forEach((stats) => {
        const { totalPrice, month } = stats;
        useDataArr.push({ type: 'month', year, month, price: totalPrice, key });
        key += 1;
      });
      key += 1;
    });
    return useDataArr;
  }, [salesStats?.stats]);

  const detailDataList = useMemo(() => {
    if (!detailData?.rows?.length) {
      return [];
    }
    const dataArray = [];
    const key = state.openMonth;

    if (detailParams.kind === 'DATE' || detailParams.kind === 'COMPANY' || detailParams.kind === 'MENU') {
      dataArray.push({ type: 'info' });
    }
    detailData.rows.forEach((dataRow, row) => {
      const insertData = {
        type: 'data',
        row,
        useDate: dataRow.useDate,
        comName: dataRow.comName,
        menuName: dataRow.menuName,
        menuCount: dataRow.menuCount,
        payCount: dataRow.payCount,
        totalPrice: dataRow.totalPrice,
        ticketFormat: dataRow.ticketFormat
      };
      dataArray.push(insertData);
    });

    const newArray = [];

    contents.forEach((d, i) => {
      newArray.push(d);
      if (i === key) {
        dataArray.forEach((e) => {
          newArray.push(e);
        });
      }
    });
    return newArray;
  }, [contents, detailData?.rows, detailParams.kind, state.openMonth]);

  const dateHandle = (range) => {
    // state.startDate = range.startDate;
    // state.endDate = range.endDate;
    const params = {
      ...state,
      startDate: range.startDate,
      endDate: range.endDate
    };
    flushSync(() => {
      setState(params);
    });
    changeExcelParams({
      data: params,
      date1: params.startDate,
      date2: params.endDate,
      filterData: {
        orderStatus: graphParams.kind
      }
    });
    setGraphParams({
      ...graphParams,
      startDate: params.startDate.format('YYYY-MM-DD'),
      endDate: params.endDate.format('YYYY-MM-DD')
    });
    setSalesStatsParams({
      ...salesStatsParams,
      startDate: params.startDate.format('YYYY-MM-DD'),
      endDate: params.endDate.format('YYYY-MM-DD')
    });
    setState({ ...state, startDate: params.startDate, endDate: params.endDate });
    const timeDiff = params.endDate.diff(params.startDate, 'months');
    const result = [];
    for (let i = 0; i <= timeDiff; i += 1) {
      const time = new Date(params.startDate.format('YYYY-MM-DD'));
      result.push(moment(time).add(i, 'M').format('YYYY-MM'));
    }
    setMonthList(result);
  };

  const handleChangeTab = (type: string) => {
    setGraphParams({ ...graphParams, kind: type });
    setSalesStatsParams({ ...salesStatsParams, kind: type });
  };

  const handelClose = () => {
    setOpen(false);
  };

  return (
    <div className="salesstats-content" id="salesstats">
      <DateRangeNavigation startDate={state.startDate} endDate={state.endDate} onChange={dateHandle} month={6} />
      <div className="selectType">
        <div
          id="selectType4"
          className={classNames('type1', { active: graphParams.kind === 'PAYMENT' })}
          onClick={() => handleChangeTab('PAYMENT')}
        >
          결제수단별
        </div>
        <div
          id="selectType1"
          className={classNames('type1', { active: graphParams.kind === 'DATE' })}
          onClick={() => handleChangeTab('DATE')}
        >
          날짜별
        </div>
        <div
          id="selectType2"
          className={classNames('type2', { active: graphParams.kind === 'COMPANY' })}
          onClick={() => handleChangeTab('COMPANY')}
        >
          회사별
        </div>
        <div
          id="selectType3"
          className={classNames('type2', { active: graphParams.kind === 'MENU' })}
          onClick={() => handleChangeTab('MENU')}
        >
          메뉴별
        </div>
      </div>
      {graphParams.kind === 'PAYMENT' && (
        <div className="content-area">
          <Collapse className="payment-tip" defaultActiveKey={['tip']}>
            <Panel header="결제수단별 매출 통계 팁" key="tip">
              <img src={paymentTypeSalesTip} alt="" width="100%" />
            </Panel>
          </Collapse>
          <div className="payment-table">
            <PaymentStatsTable data={salesData} />
          </div>
        </div>
      )}
      {graphParams.kind !== 'PAYMENT' && (
        <div className="content-area">
          <div className="graph" id="graph">
            {graphParams.kind === 'DATE' ? <ColumnChart data={graphData} /> : <DonutChart data={graphData} />}
          </div>
          <SalesStatsTable
            contents={state.openDetail ? detailDataList : contents}
            getMonthData={getMonthData}
            openMonth={state.openMonth}
            colorSet={state.colorSet}
            colorTable={colorTable}
            type={graphParams.kind}
            openDetail={state.openDetail}
          />
        </div>
      )}
      <Modal
        title="알림"
        open={open}
        okText="확인"
        onOk={handelClose}
        closable={false}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <div>월중 수수료(정산단가) 변동 사항이 있을 경우 계산한 금액과 다를 수 있습니다.</div>
      </Modal>
      <div id="mobileView" />
    </div>
  );
};

export default SalesStats;
