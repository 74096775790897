import { Button, Modal, Slider } from 'antd';
import moment from 'moment';
import React, { useEffect, useMemo } from 'react';

import { useCanceled } from 'hooks';
import { state_canceled_on, status_off, status_on } from '../image';
import { DetailModal, RiderContainer, Table } from '../styles';

const DeliveryDetailModal = ({ open, close, detailData, searchParams }) => {
  const { mutateAsync } = useCanceled();

  useEffect(() => {
    const scroll = document.getElementsByClassName('ant-modal-body');

    if (scroll.length > 0) {
      for (let i = 0; i < scroll.length; i += 1) {
        setTimeout(() => scroll[i].scrollTo(0, 0), 100);
      }
    }
  }, [open]);

  const { booking, company, spot, fee, agent, status, date } = useMemo(() => {
    if (detailData?.deliveryInfo) {
      return { ...detailData.deliveryInfo };
    }
    return {};
  }, [detailData]);

  const handleCancel = () => {
    close?.(false);
  };

  const onCancel = async () => {
    Modal.confirm({
      content: <>정말 취소하시겠습니까?</>,
      icon: null,
      cancelText: '취소',
      okText: '확인',
      style: { top: 'calc(50% - 200px)' },
      onOk: async () => {
        await mutateAsync(searchParams);
        close?.(true);
      }
    });
  };

  const { statusType, colorList, imgList } = useMemo(() => {
    const obj = {
      RESERVED: {
        title: (
          <div className="title">
            라이더가 <span className="primary">준비중</span>입니다. 음식을 맛있게 준비해주세요!
          </div>
        ),
        state: 11
      },
      ASSIGNING_AGENT: {
        title: (
          <div className="title">
            라이더가 <span className="primary">준비중</span>입니다. 음식을 맛있게 준비해주세요!
          </div>
        ),
        state: 11
      },
      PICKING_UP: {
        title: (
          <div className="title">
            라이더가 <span className="primary">배차</span>되었습니다. <span className="primary">픽업까지 10분</span> 남았어요!
          </div>
        ),
        state: 50
      },
      DELIVERING: {
        title: (
          <div className="title">
            라이더가 <span className="primary">배달중</span>입니다. <span className="primary">10분 후 도착 예정</span>
            이에요.
          </div>
        ),
        state: 77
      },
      DELIVERED: {
        title: (
          <div className="title">
            라이더가 <span className="primary">배달완료</span>되었습니다.{' '}
            <span className="primary">확정운임은 {fee ? (fee.baseFee + fee.extraFee).toLocaleString() : '-'}원</span>
            이에요.
          </div>
        ),
        state: 89
      },
      CANCELED: {
        title: (
          <div className="title">
            <span className="danger">배달취소</span>되었습니다. 메쉬코리아 고객센터(1800-8255)로 확인 부탁드립니다.
          </div>
        ),
        state: 100
      },
      ASSIGN_FAILED: {
        title: (
          <div className="title">
            <span className="danger">배차실패</span>되었습니다. 메쉬코리아 고객센터(1800-8255)로 확인 부탁드립니다.
          </div>
        ),
        state: 89
      },
      SUBMIT_FAILED: {
        title: (
          <div className="title">
            <span className="danger">배차실패</span>되었습니다. 메쉬코리아 고객센터(1800-8255)로 확인 부탁드립니다.
          </div>
        ),
        state: 89
      },
      OWNER_CANCELED: {
        title: (
          <div className="title">
            <span className="danger">호출취소</span>되었습니다.
          </div>
        ),
        state: 100
      }
    };

    const colors: Record<string, string> = {};
    const images: Record<string, string> = {};
    const state = obj[status]?.state;
    Object.keys(obj).forEach((key) => {
      images[key] = state >= obj[key]?.state ? status_on[key] : status_off[key];
      let color = '';
      if (state >= obj[key]?.state) {
        color = state === obj[key]?.state ? '#4BB378' : '#6D7582';
      } else {
        color = '#D7D8DC';
      }
      colors[key] = color;
    });
    return { statusType: obj, colorList: colors, imgList: images };
  }, [fee, status]);

  const bool = status === 'RESERVED' || status === 'ASSIGNING_AGENT';
  const cancelType = {
    CANCELED: '배달취소',
    OWNER_CANCELED: '호출취소',
    ASSIGN_FAILED: '배차실패',
    SUBMIT_FAILED: '배차실패'
  };

  return (
    <DetailModal
      visible={open}
      status={status}
      title={`배달 상세보기 - 라이더${searchParams?.agentIdx} (${booking?.quantity} | ${booking?.totalAmount.toLocaleString()})`}
      onCancel={handleCancel}
      width={861}
      className="modalStyle"
      closable={false}
      footer={[
        <div className="footer" key="detail-footer">
          <Button key="detail-back" onClick={handleCancel}>
            {bool ? '닫기' : '확인'}
          </Button>
          {bool ? (
            <Button key="detail-cancel" className="cancel" danger onClick={onCancel}>
              호출취소
            </Button>
          ) : null}
        </div>
      ]}
    >
      <RiderContainer status={status}>
        <div>
          <div>{statusType[status]?.title}</div>
          <div>
            <Slider value={statusType[status]?.state} />
            <div className="imageGroup">
              <div>
                <img src={imgList.RESERVED} alt="" />
                <div className="status" style={{ color: colorList.RESERVED }}>
                  배차 예약
                </div>
                <div className="date">{date?.bookingAt && moment(date?.bookingAt).format('HH:mm')}</div>
              </div>

              {!cancelType[status] || (agent?.agentName && agent?.agentName) ? (
                <div>
                  <img src={imgList.PICKING_UP} alt="" />
                  <div className="status" style={{ color: colorList.PICKING_UP }}>
                    배차 완료
                  </div>
                  <div className="date">{date?.agentAssignedAt && moment(date?.agentAssignedAt).format('HH:mm')}</div>
                </div>
              ) : null}

              {!cancelType[status] ? (
                <div>
                  <img src={imgList.DELIVERING} alt="" />
                  <div className="status" style={{ color: colorList.DELIVERING }}>
                    픽업 완료
                  </div>
                  <div className="date">{date?.agentPickedAt && moment(date?.agentPickedAt).format('HH:mm')}</div>
                </div>
              ) : null}

              {!cancelType[status] ? (
                <div>
                  <img src={imgList.DELIVERED} alt="" />
                  <div className="status" style={{ color: colorList.DELIVERED }}>
                    배달 완료
                  </div>
                  <div className="date">
                    {status === 'DELIVERED' ? (
                      <span>{date?.completedAt && moment(date?.completedAt).format('HH:mm')}</span>
                    ) : (
                      date?.agentPickedAt && <span>{moment(date?.agentPickedAt).add(10, 'm').format('HH:mm')} 예정</span>
                    )}
                  </div>
                </div>
              ) : (
                <div>
                  <img src={state_canceled_on} alt="" />
                  <div className="status" style={{ color: '#FF5630' }}>
                    {cancelType[status]}
                  </div>
                  <div className="date">{date?.canceledAt && moment(date?.canceledAt).format('HH:mm')}</div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="info">
          <div className="title">배달 정보</div>

          <div className="borderBox">
            <div className="companyInfo">
              <span className="title">{company?.companyName}</span>
              <span className="serviceTime">{booking?.serviceTime} 까지 배달</span>
            </div>
            <div className="addressGroup">
              <span>
                {spot?.roadAddress}
                {', '}
                {spot?.roadAddressDetail}
              </span>
              <span className="addressDetail">{spot?.spotName}</span>
            </div>
            <div>
              <span>총 {booking?.quantity}개</span>
              <span className="totalAmount">{booking?.totalAmount.toLocaleString()}원</span>
            </div>
          </div>
        </div>

        <div>
          <div className="title">운임정보</div>
          <Table>
            <thead>
              <tr>
                <th style={{ textAlign: 'center', width: '33.3%' }}>예상운임(a)</th>
                <th style={{ textAlign: 'center', width: '33.3%' }}>추가운임(b)</th>
                <th style={{ textAlign: 'center', width: '33.3%' }}>확정운임(a+b)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ textAlign: 'center' }}>{!cancelType[status] ? fee?.baseFee.toLocaleString() : 0}</td>
                <td style={{ textAlign: 'center' }}>
                  <div>{fee?.extraFee <= 0 ? '-' : fee?.extraFee.toLocaleString()}</div>
                  <div>{fee?.extraFeeDetail.join(', ')}</div>
                </td>
                <td style={{ textAlign: 'center' }}>
                  {status === 'DELIVERED' && fee ? (fee.baseFee + fee.extraFee).toLocaleString() : '-'}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>

        <div>
          <div className="title">라이더정보</div>
          <Table>
            <thead>
              <tr>
                <th style={{ textAlign: 'center', width: '50%' }}>이름</th>
                <th style={{ textAlign: 'center', width: '50%' }}>전화번호</th>
              </tr>
            </thead>
            <tbody>
              {status === 'PICKING_UP' || status === 'DELIVERING' ? (
                <tr>
                  <td style={{ textAlign: 'center' }}>{agent?.agentName}</td>
                  <td style={{ textAlign: 'center' }}>{agent?.agentPhone}</td>
                </tr>
              ) : (
                <tr>
                  {status === 'RESERVED' ||
                    (status === 'ASSIGNING_AGENT' && (
                      <td className="colSpan" colSpan={2}>
                        배차완료 후 확인 가능합니다
                      </td>
                    ))}
                  {status === 'DELIVERED' && (
                    <td className="colSpan" colSpan={2}>
                      ‘배달 완료’ 후 라이더 정보 확인은 메쉬코리아 고객센터(1800-8255)로 확인 부탁드립니다.
                    </td>
                  )}
                  {cancelType[status] && (
                    <td className="colSpan" colSpan={2}>
                      {status !== 'OWNER_CANCELED' ? '메쉬코리아 고객센터(1800-8255)로 확인 부탁드립니다.' : '-'}
                    </td>
                  )}
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </RiderContainer>
    </DetailModal>
  );
};

export default DeliveryDetailModal;
