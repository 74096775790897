import { DateModal, MonthModal } from 'components/Modal';
import { Moment } from 'moment';
import React, { useState } from 'react';
import { handlerKeyUp } from 'utils/eventUtils';

export interface DateRangeNavigationProps {
  startDate: Moment;
  endDate: Moment;
  onChange: (v: { startDate: Moment; endDate: Moment }) => void;
  filter?: Record<string, unknown>;
  month?: number;
  title?: string;
  target?: string;
  logistics?: [];
}

const DateRangeNavigation = ({
  startDate,
  endDate,
  onChange,
  month,
  filter,
  title,
  target,
  logistics
}: DateRangeNavigationProps) => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const calculateFlag = target === 'calculate';

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleSelectDate = (range: { startDate: Moment; endDate: Moment }) => {
    onChange(range);
    closeModal();
  };

  const startDateFormat = startDate.format('YYYY. M. DD.');
  const endDateFormat = endDate.format('YYYY. M. DD.');

  return (
    <>
      <div className="date">
        <div className="left">조회기간</div>
        <div className="right" onClick={openModal} onKeyUp={handlerKeyUp(openModal)} role="button" tabIndex={0}>
          <div className="todate">
            <div className="string">
              {startDateFormat} - {endDateFormat}
            </div>
          </div>
        </div>
      </div>
      {calculateFlag ? (
        <MonthModal
          isOpenCalendar={isModalOpen}
          startDate={startDate}
          endDate={endDate}
          dateSelected={handleSelectDate}
          close={closeModal}
          target="conviseton"
        />
      ) : (
        <DateModal
          startDate={startDate}
          endDate={endDate}
          dateSelected={handleSelectDate}
          close={closeModal}
          isOpenCalendar={isModalOpen}
          month={month}
          filter={filter}
          title={title}
          target={target}
          logistics={logistics}
        />
      )}
    </>
  );
};

export default DateRangeNavigation;
