import React, { useMemo } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { FlexBoxFullWidthCenter } from 'styles';
import { useMeInfo } from 'hooks';
import { IConfirmOrder, IRobotSpot, TSchedule } from 'interfaces/robot';

import DefaultModal from '../components/DefaultModal';
import DefaultTable, { TColumns } from '../components/DefaultTable';

const AggregateModalWrapper = styled(FlexBoxFullWidthCenter)`
  padding: 45px 45px 0;
  .robot-name {
    width: 100%;
    font-size: 32px;
  }
  .blue {
    color: #0067ff;
  }
  .address {
    //width: 100%;
    color: #6d7582;
  }
  .description {
    color: #fa503c;
    font-size: 20px;
    text-align: center;
    white-space: pre;
    padding-top: 30px;
  }
  .align {
    text-align: center;
  }
`;
const ModalHeaderWrapper = styled.div`
  font-size: 32px;
  font-weight: 700;
`;
const ModalContentWrapper = styled(FlexBoxFullWidthCenter)`
  font-size: 20px;
  font-weight: 700;
`;

const AGGREGATE_COLUMNS: TColumns[] = [
  { field: 'payRoomIdx', headerName: '결제번호', width: 300 },
  { field: 'createDate', headerName: '결제일시', width: 300, textAlign: 'center' },
  { field: 'totalCount', headerName: '주문수량', width: 150, textAlign: 'center' },
  { field: 'totalPrice', headerName: '주문금액', width: 200, textAlign: 'end' }
];

interface OrderAggregateModalProps {
  open: boolean;
  checkedOrders: IConfirmOrder[];
  schedule?: TSchedule;
  spot: IRobotSpot;
  totalCount: number;
  onCancel: () => void;
  onConfirm: () => void;
}

const OrderAggregateModal = ({
  open,
  checkedOrders,
  schedule,
  spot,
  totalCount,
  onCancel,
  onConfirm
}: OrderAggregateModalProps) => {
  const { selectedStore } = useMeInfo();

  const data = useMemo(() => {
    return checkedOrders?.map(({ menus, ...order }) => {
      const count = menus.reduce((number, cur) => cur.count + number, 0);
      const price = menus.reduce((number, cur) => cur.count * cur.price + number, 0);
      return { ...order, totalCount: count, totalPrice: price.toLocaleString() };
    });
  }, [checkedOrders]);
  return (
    <DefaultModal open={open} onCancel={onCancel} onOk={onConfirm}>
      <AggregateModalWrapper direction="column" gap="25px">
        <ModalHeaderWrapper>
          <span>선택한</span>
          <span className="blue">&#32;{checkedOrders.length}건&#32;</span>
          <span>
            (<span className="blue">{totalCount}개</span>)에 대해
          </span>
          <span className="blue">&#32;주문 확정</span>
          <span>처리하시겠습니까?</span>
        </ModalHeaderWrapper>
        <ModalContentWrapper justify="space-between">
          <div className="align">
            <div className="robot-name">
              배달 로봇 : <span className="blue">{schedule?.robotName}</span>
            </div>
            <div className="blue">(출발 : {moment(`2025-01-01 ${schedule?.deliveryStartTime}`).format('HH시 mm분')})</div>
          </div>
          <div className="address">
            <div>출발장소(적재지) : {selectedStore?.name}</div>
            <div>배달장소(목적지) : {spot?.spotName}</div>
          </div>
        </ModalContentWrapper>
        <DefaultTable columns={AGGREGATE_COLUMNS} data={data} />
        <div className="description">
          *주문 확정 시, 각 주문 건에 대한 취소가 불가합니다. 로봇 출발 시간 전 제조 가능 여부 확인 후 버튼을 눌러주세요.
        </div>
      </AggregateModalWrapper>
    </DefaultModal>
  );
};

export default OrderAggregateModal;
