import { Form, Space } from 'antd';
import type { RadioChangeEvent } from 'antd';
import { TermContainer, AdmissionServiceTermContainer } from 'containers';
import React, { useEffect, useState } from 'react';
import { validateMessages, checkboxRequiredRuleValidator } from 'validators';
import { BottomButtonArea } from 'styles';
import { modalMethod } from 'components/Modal';
import RadioButtonWithCheckboxShape from 'components/Radio/RadioButtonWithCheckboxShape';
import FormCheckbox from 'components/Checkbox/FormCheckbox';
import BottomButton from 'components/Button/BottomButton';

import {
  Step2Container,
  FormItemMarginBottom,
  FormItemDescription,
  FormItemWithRadioGroup,
  SupplyTypeRadioContent
} from './styles';

const { useForm } = Form;

export interface AdmissionStepTwoFormProps {
  applyingAdmissionIdx?;
  initValues?;
  onNextStep;
  onPreviousStep;
  supplyTypes?;
  loading;
}

const AdmissionStepTwoForm = ({
  applyingAdmissionIdx,
  initValues,
  onNextStep,
  onPreviousStep,
  supplyTypes,
  loading
}: AdmissionStepTwoFormProps) => {
  const [form] = useForm();
  const [radioValue, setRadioValue] = useState();
  const [, forceUpdate] = useState({});

  // To disable submit button at the beginning.
  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    if (initValues && initValues.length) {
      const value = initValues[0];
      form.setFieldsValue({ supplyType: value?.supplyType || undefined });
      if (value?.supplyType) {
        setRadioValue(value.supplyType);
      }
    }
  }, [form, initValues]);

  const handleChangeRadio = ({ target: { value } }: RadioChangeEvent) => {
    setRadioValue(value);
    form.setFieldsValue({
      agreeService: false,
      agreePrivacy: false
    });
    form.submit();
  };

  const handleNextClick = (values) => {
    onNextStep(values);
  };

  const handleBeforeVisibleTerm = () => {
    if (!radioValue) {
      modalMethod.info('먼저 판매 형태를 선택해주세요');
      return false;
    }
    return true;
  };

  return (
    <Step2Container>
      <Form
        autoComplete="off"
        layout="vertical"
        name="admission_step2_form"
        form={form}
        onFinish={handleNextClick}
        validateMessages={validateMessages}
      >
        <Form.Item name="supplyType" label="" rules={[{ required: true }]}>
          <FormItemWithRadioGroup onChange={handleChangeRadio}>
            <Space direction="vertical" className="radio-group-space" style={{ width: '100%', marginTop: 8 }}>
              {supplyTypes && supplyTypes.length > 0
                ? supplyTypes.map(({ code, name, commission, explanation }) => (
                    <RadioButtonWithCheckboxShape key={code} value={code} checked={code === radioValue}>
                      <SupplyTypeRadioContent>
                        <div className="supply-type-name">
                          {name}
                          <span className="commission checked-color">{commission}</span>
                        </div>
                        <div className="explanation">{explanation}</div>
                      </SupplyTypeRadioContent>
                    </RadioButtonWithCheckboxShape>
                  ))
                : null}
            </Space>
          </FormItemWithRadioGroup>
        </Form.Item>
        <FormItemDescription>
          <p>서비스 이용을 위해</p>
          <p>약관 동의가 필요합니다.</p>
        </FormItemDescription>
        <FormItemMarginBottom
          name="agreeService"
          label=""
          valuePropName="checked"
          rules={[
            {
              required: true,
              type: 'boolean',
              validator: checkboxRequiredRuleValidator
            }
          ]}
        >
          <FormCheckbox
            modalTitle="서비스 공급 계약 동의"
            modalContent={<AdmissionServiceTermContainer admissionIdx={applyingAdmissionIdx} supplyType={radioValue} />}
            beforeVisible={handleBeforeVisibleTerm}
          >
            서비스 공급 계약 동의
          </FormCheckbox>
        </FormItemMarginBottom>
        <Form.Item
          name="agreePrivacy"
          label=""
          valuePropName="checked"
          rules={[{ required: true, type: 'boolean', validator: checkboxRequiredRuleValidator }]}
        >
          <FormCheckbox modalTitle="개인정보 수집 및 이용 동의" modalContent={<TermContainer type="PRIVACY" />}>
            개인정보 수집 및 이용 동의
          </FormCheckbox>
        </Form.Item>
        <Form.Item shouldUpdate>
          {() => (
            <BottomButtonArea>
              <BottomButton bottomType="mint" onClick={onPreviousStep}>
                이전
              </BottomButton>
              <BottomButton
                type="primary"
                htmlType="submit"
                loading={loading}
                disabled={!form.isFieldsTouched(true) || !!form.getFieldsError().filter(({ errors }) => errors.length).length}
              >
                다음
              </BottomButton>
            </BottomButtonArea>
          )}
        </Form.Item>
      </Form>
    </Step2Container>
  );
};

export default AdmissionStepTwoForm;
