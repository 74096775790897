import styled from 'styled-components';
import { HTMLAttributes } from 'react';

export { Button } from 'antd';
export type { ButtonProps } from 'antd';

export const ButtonV2 = styled.button<HTMLAttributes<HTMLButtonElement>>`
  & {
    height: 32px;
    width: 80px;
    font-size: 15px;
    min-height: 30px;
    line-height: 1;
    border-radius: 5px;
    color: #ffffff;
    padding: 0;
    background-color: #333333;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 0;
    cursor: pointer;
    margin: 0;
    outline: 0;
    text-decoration: none;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    &:active {
      box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    }
    &:hover {
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    }
`;

interface ButtonProps {
  width?: number;
  height?: number;
  disable?: boolean;
}

export const CloseButton = styled.button<ButtonProps>`
  width: ${(props) => (props?.width ? `${props.width}px` : '100px')};
  height: ${(props) => (props?.height ? props.height : '40px')};
  border-radius: 5px;
  border: 1px solid #afb2b9;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  padding: 0px;
  background-color: #ffffff;
  color: #afb2b9;
`;

export const SikdaeButton = styled.button<ButtonProps>`
  width: ${(props) => (props?.width ? `${props.width}px` : '100px')};
  height: ${(props) => (props?.height ? props.height : '40px')};
  border-radius: 5px;
  border: none;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  padding: 0px;
  background-color: ${({ disabled }) => (disabled ? '#eeeeee' : '#46b476')};
  color: ${({ disabled }) => (disabled ? '#afb2b9' : '#ffffff')};
`;
