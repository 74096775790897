import React, { useEffect, useState } from 'react';
import { Form, Input, Button } from 'antd';
import { passwordValidator } from 'validators';

const { useForm } = Form;

const ForgotPassword = ({ onChangePassword, loading }) => {
  const formItemLayout = {
    labelCol: {
      span: 24
      // xs: { span: 24 },
      // sm: { span: 6 }
    },
    wrapperCol: {
      span: 24
      // xs: { span: 24 },
      // sm: { span: 14 }
    }
  };

  const validateMessages = {
    required: '필수 입력 항목입니다.'
    // ...
  };

  const [form] = useForm();
  const [, forceUpdate] = useState({});

  // To disable submit button at the beginning.
  useEffect(() => {
    forceUpdate({});
  }, []);

  return (
    <Form
      autoComplete="off"
      layout="vertical"
      name="siginup_form"
      form={form}
      onFinish={onChangePassword}
      validateMessages={validateMessages}
      {...formItemLayout}
    >
      <Form.Item
        id="password"
        className="password"
        name="password"
        label="비밀번호"
        rules={[{ required: true }, ...passwordValidator]}
      >
        <Input.Password placeholder="새 비밀번호 입력 (8~20자)" />
      </Form.Item>
      <Form.Item
        id="passwordRe"
        className="password-re"
        name="passwordRe"
        label="비밀번호 재입력"
        rules={[
          { required: true },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('비밀번호가 일치하지 않습니다.'));
            }
          })
        ]}
      >
        <Input.Password placeholder="새 비밀번호 재입력" />
      </Form.Item>
      <Form.Item shouldUpdate>
        {() => (
          <Button
            type="primary"
            htmlType="submit"
            disabled={!form.isFieldsTouched(true) || !!form.getFieldsError().filter(({ errors }) => errors.length).length}
            loading={loading}
          >
            확인
          </Button>
        )}
      </Form.Item>
    </Form>
  );
};

export default ForgotPassword;
