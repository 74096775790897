import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Tabs, Switch, Modal } from 'antd';

import { useRobotSettings, useRobotSpots, useUpdateStoreOpen, useRobotOrders } from 'hooks/useRobotDelivery';
import { TOrderStatus, IRobotSpot } from 'interfaces/robot';
import { DateNavigation } from 'components';
import { FlexBoxCenter } from 'styles';

import { ReactComponent as RefetchIcon } from 'images/ic_refetch.svg';
import OrderConfirmPage from './OrderConfirmPage';
import OrderAcceptPage from './OrderAcceptPage';
import OrderCancelPage from './OrderCancelPage';

import { RobotDeliveryWrapper, TabsWrapper, TabLabelWrapper, TabHeaderWrapper, StyledSelect, RefetchButton } from './styles';

const TabLabel = ({ label, count }: { label: string; count: number }) => (
  <TabLabelWrapper>
    <div className="tab-label">{label}</div>
    <div className="tab-content-count">{count || 0}</div>
  </TabLabelWrapper>
);

interface RobotDeliveryTopBarProps {
  opend: boolean;
  spot: IRobotSpot;
  spots: IRobotSpot[];
  dataUpdatedAt: string | number;
  refetch: () => void;
  onSpotChange: (spot: IRobotSpot) => void;
  onIsOpenChange: (isOpen: boolean) => void;
}

const RobotDeliveryTopBar = ({
  opend,
  spot,
  spots,
  dataUpdatedAt,
  refetch,
  onSpotChange,
  onIsOpenChange
}: RobotDeliveryTopBarProps) => {
  const handleSwitchClick = (checked: boolean) => {
    return Modal.confirm({
      className: 'info-handler-modal',
      content: opend ? '로봇배달 주문을 막으시겠습니까?' : '로봇배달 주문을 받으시겠습니까?',
      icon: false,
      okText: '확인',
      cancelText: '취소',
      onOk: async () => onIsOpenChange(checked)
    });
  };
  const handleSpotChange = (spotIdx: number) => {
    const target = spots.find((item) => item.spotIdx === spotIdx);
    onSpotChange(target);
  };

  const options = spots?.map(({ spotIdx, spotName }) => ({ value: spotIdx, label: spotName })) || [];
  return (
    <TabHeaderWrapper>
      <FlexBoxCenter gap="6px">
        <div>배달장소</div>
        <StyledSelect value={spot?.spotIdx} options={options} disabled={!(spots?.length > 1)} onChange={handleSpotChange} />
      </FlexBoxCenter>
      <FlexBoxCenter gap="16px" className="store-opend">
        <div>로봇배달 주문 받기</div>
        <Switch checked={opend ?? false} checkedChildren="켜짐" unCheckedChildren="꺼짐" onChange={handleSwitchClick} />
      </FlexBoxCenter>
      <FlexBoxCenter gap="16px">
        <div className="last-refresh-date">
          <div>최근 새로고침 일시</div>
          <div>{moment(dataUpdatedAt).format('YYYY.MM.DD HH:mm:ss')}</div>
        </div>
        <RefetchButton type="primary" shape="circle" icon={<RefetchIcon />} onClick={() => refetch()} />
      </FlexBoxCenter>
    </TabHeaderWrapper>
  );
};

const RobotDeliveryPage = () => {
  const [tabKey, setTabKey] = useState<TOrderStatus>('CONFIRM');
  const [selectedDate, setSelectedDate] = useState<string>(moment().format('YYYY-MM-DD'));
  const [selectedSpot, setSelectedSpot] = useState<IRobotSpot>();

  const { data: robotSpots } = useRobotSpots();
  const { data: robotSettings } = useRobotSettings();
  const { mutate: updateStoreIsOpen } = useUpdateStoreOpen();

  const {
    robotOrders,
    robotAggregateOrders,
    robotDeliveringOrders,
    robotCompleteOrders,
    refetchAll,
    dataUpdatedAt
    //
  } = useRobotOrders(selectedDate);

  useEffect(() => {
    if (robotSpots?.spots.length) {
      setSelectedSpot(robotSpots.spots[0]);
    }
  }, [robotSpots]);

  const handleDateChange = (date: Date) => setSelectedDate(moment(date).format('YYYY-MM-DD'));
  const handleSpotChange = (spot: IRobotSpot) => setSelectedSpot(spot);

  const handleUpdateStoreIsOpen = (checked: boolean) => updateStoreIsOpen(checked);

  const orders = useMemo(() => {
    const spotIdx = selectedSpot?.spotIdx;
    // 배송지(spotIdx) 필터
    const confirmOrders = robotOrders?.confirmOrders.filter((item) => item.robotDeliverySpotIdx === spotIdx) ?? [];
    const canceledOrders = robotOrders?.canceledOrders.filter((item) => item.robotDeliverySpotIdx === spotIdx) ?? [];
    const aggregateOrders = robotAggregateOrders?.orders.filter((item) => item.spotIdx === spotIdx) ?? [];
    const deliveringOrders = robotDeliveringOrders?.orders.filter((item) => item.spotIdx === spotIdx) ?? [];
    const completeOrders = robotCompleteOrders?.orders.filter((item) => item.spotIdx === spotIdx) ?? [];

    // 내림차순 정렬
    canceledOrders.sort((a, b) => (a.createDate > b.createDate ? -1 : 1));
    aggregateOrders.sort((a, b) => (a.aggregatedDate > b.aggregatedDate ? -1 : 1));
    deliveringOrders.sort((a, b) => (a.aggregatedDate > b.aggregatedDate ? -1 : 1));
    completeOrders.sort((a, b) => (a.aggregatedDate > b.aggregatedDate ? -1 : 1));

    return { confirmOrders, canceledOrders, aggregateOrders, deliveringOrders, completeOrders };
  }, [robotAggregateOrders?.orders, robotCompleteOrders?.orders, robotDeliveringOrders?.orders, robotOrders, selectedSpot]);

  const count = useMemo(() => {
    return {
      confirmCount: robotOrders?.confirmOrderCount || 0,
      canceledCount: robotOrders?.canceledOrderCount || 0,
      aggregateCount: robotAggregateOrders?.orders.length || 0,
      deliveringCount: robotDeliveringOrders?.orders.length || 0,
      completeCount: robotCompleteOrders?.orders.length || 0,
      maxOrderCount: robotSettings?.maxDeliveryOrderCount || 0
    };
  }, [robotAggregateOrders?.orders, robotCompleteOrders?.orders, robotDeliveringOrders?.orders, robotOrders, robotSettings]);

  const isToday = moment().startOf('day').isSame(moment(selectedDate).startOf('day'), 'day');
  return (
    <RobotDeliveryWrapper>
      <DateNavigation date={new Date(moment(selectedDate).valueOf())} onChange={handleDateChange} />
      <TabsWrapper $isConfirm={isToday && tabKey === 'CONFIRM'}>
        <div className="top-bar-wrapper">
          <RobotDeliveryTopBar
            opend={robotSettings?.opend}
            dataUpdatedAt={dataUpdatedAt}
            spots={robotSpots?.spots}
            spot={selectedSpot}
            refetch={refetchAll}
            onSpotChange={handleSpotChange}
            onIsOpenChange={handleUpdateStoreIsOpen}
          />
        </div>
        <Tabs
          items={[
            {
              key: 'CONFIRM',
              label: <TabLabel label="주문요청" count={count.confirmCount} />,
              children: (
                <OrderConfirmPage
                  confirmOrders={orders.confirmOrders}
                  maxOrderCount={count.maxOrderCount}
                  selectedDate={selectedDate}
                  selectedSpot={selectedSpot}
                />
              )
            },
            {
              key: 'ACCEPT',
              label: <TabLabel label="주문확정" count={count.aggregateCount} />,
              children: <OrderAcceptPage orders={orders.aggregateOrders} />
            },
            {
              key: 'DELIVERING',
              label: <TabLabel label="배달중" count={count.deliveringCount} />,
              children: <OrderAcceptPage orders={orders.deliveringOrders} />
            },
            {
              key: 'COMPLETE',
              label: <TabLabel label={`배달완료/\n픽업완료`} count={count.completeCount} />,
              children: <OrderAcceptPage orders={orders.completeOrders} />
            },
            {
              key: 'CANCEL',
              label: <TabLabel label="취소완료" count={count.canceledCount} />,
              children: <OrderCancelPage canceledOrders={orders.canceledOrders} />
            }
          ]}
          tabPosition="left"
          onChange={(key: TOrderStatus) => setTabKey(key)}
        />
      </TabsWrapper>
    </RobotDeliveryWrapper>
  );
};

export default RobotDeliveryPage;
