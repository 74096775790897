import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Modal } from 'antd';
import { ForgotPassword } from 'components';
import { useChangePassword } from 'hooks';
import { StepLayout } from 'layouts';

interface IAccount {
  signId?: string;
  joinDate?: number;
}

interface IData {
  account?: IAccount;
  isJoin?: boolean;
}

interface IState {
  transactionId?;
  signId?;
  data?: IData;
  passData?;
}

const ChangePasswordContainer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoading, mutateAsync: changePassword } = useChangePassword();
  const [transactionId, setTransactionId] = useState<string | undefined>();
  const [signId, setSignId] = useState<string | undefined>();

  useEffect(() => {
    const { state } = location;
    const { transactionId: tId, signId: id, data } = (state as IState) || {};
    const { account } = data || {};
    const currentSignId = id || account?.signId;
    if (!tId) {
      navigate(-1);
    } else {
      setTransactionId(tId as string);
      setSignId(currentSignId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangePassword = async (values) => {
    const { password } = values;
    if (transactionId) {
      const params = {
        transactionId,
        signId,
        password
      };
      await changePassword(params);
      Modal.info({
        content: <>비밀번호 변경이 완료되었습니다. 다른 모든 기기에서 자동으로 로그아웃됩니다.</>,
        icon: null,
        okText: '확인',
        onOk: () => {
          navigate('/', { replace: true });
        },
        className: 'info-handler-modal'
      });
    }
  };
  return (
    <StepLayout
      desc="새로운 비밀번호를 입력해주세요."
      subDesc={
        <>
          <div>영문, 숫자, 특수문자를 조합하여</div>
          <div>8자 이상 20자 이하로 입력해주세요.</div>
        </>
      }
      content={<ForgotPassword loading={isLoading} onChangePassword={onChangePassword} />}
    />
  );
};

export default ChangePasswordContainer;
