import { Tabs } from 'antd';
import { TDate, TStore } from 'interfaces/booking';
import React from 'react';
import { RangeKeyDict } from 'react-date-range';
import styled from 'styled-components';
import DurationCalendar from './DurationCalendar';
import Statistics from './Statistics';

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    border: 1px solid #d9d9d9;

    .ant-tabs-nav-list {
      background-color: white;
      width: 100%;
      display: flex;

      .ant-tabs-tab {
        flex: 1 1 auto;
        justify-content: center;
        font-size: 18px;
        font-weight: 600;
        margin: 0;
        padding: 8px 12px;

        &:not(:first-child) {
          border-left: 1px solid #e0e0e0;
        }
      }
    }
  }
`;

interface BookingStatsProps {
  date: TDate;
  changeGroup: (v: string) => void;
  changeCompany: (v: string) => void;
  changeBooking: (v: string) => void;
  handleCalendar?: (v: RangeKeyDict) => void;
  handleCalenderSuccess?: () => void;
  tabStore: TStore;
}

const BookingStats = ({
  date,
  changeGroup,
  changeCompany,
  changeBooking,
  handleCalenderSuccess,
  handleCalendar,
  tabStore
}: BookingStatsProps) => {
  // componentDidMount() {
  //   // 스토어 설정, 전체 데이터 조회
  //   this.props.setStore(stores, stores.getData());
  // }

  const onChangeGroup = (value: string) => {
    // 탭메뉴 클릭시 그룹명 변경

    changeGroup(value);
    // 전체 데이터 조회
    // getData();
  };

  const onChangeCompany = (value: string) => {
    // 셀렉트 선택시
    changeCompany(value);
    // 전체 데이터 조회
    // getData();
  };

  const onChangeBooking = (value: string) => {
    // 탭메뉴 클릭시 그룹명 변경
    changeBooking(value);
    // 전체 데이터 조회
    // getData();
  };

  return (
    <div>
      <DurationCalendar date={date} handleCalenderSuccess={handleCalenderSuccess} handleCalendar={handleCalendar} />
      <StyledTabs
        defaultActiveKey="DATE"
        onChange={onChangeGroup}
        items={[
          {
            label: `날짜별`,
            key: 'DATE',
            children: <Statistics title="날짜" store={tabStore} />
          },
          {
            label: `회사별`,
            key: 'COMPANY',
            children: <Statistics title="회사" store={tabStore} />
          },
          {
            label: `부서별`,
            key: 'DIVISION',
            children: (
              <Statistics title="부서" store={tabStore} onChangeCompany={onChangeCompany} onChangeBooking={onChangeBooking} />
            )
          },
          {
            label: `메뉴별`,
            key: 'MENU',
            children: <Statistics title="메뉴" store={tabStore} />
          }
        ]}
      />
    </div>
  );
};

export default BookingStats;
