import React, { useState, useEffect } from 'react';
import { Form, Input, Typography } from 'antd';
import { validateMessages } from 'validators';
import { FileUpload, BizSerialFormItem, BottomButton } from 'components';
import { FlexBoxFullWidthCenter } from 'styles';
import { useTheme } from 'styled-components';

const { useForm } = Form;

export interface BizFormProps {
  initValue?;
  onSearch?;
  isSearching?;
  isPreparationHistory?: boolean;
  isLoading;
  onSave;
}

const BizForm = ({ initValue, onSearch, isSearching, isLoading, onSave, isPreparationHistory }: BizFormProps) => {
  const theme = useTheme();
  const [form] = useForm();
  const [, forceUpdate] = useState({});
  const [isValidBizSerial, setValidBizSerial] = useState<boolean | null | undefined>();

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    if (!initValue?.bizInfo) {
      return;
    }
    form.setFieldsValue(initValue.bizInfo);
  }, [form, initValue]);

  const handleSaveBizInfo = async () => {
    const {
      bizSerial: { value },
      bizFile
    } = form.getFieldsValue();
    if (!value || !bizFile.length) {
      return;
    }
    await onSave({ bizSerial: value, bizFile: bizFile[0] });
    form.setFieldsValue(initValue?.bizInfo || { bizSerial: '' });
  };

  const handleValid = (isValid) => {
    setValidBizSerial(isValid);
  };

  return (
    <Form
      autoComplete="off"
      layout="vertical"
      name="settlementBizForm"
      form={form}
      validateMessages={validateMessages}
      onFinish={handleSaveBizInfo}
    >
      <BizSerialFormItem
        onSearch={onSearch}
        isSearching={isSearching}
        checkValid={handleValid}
        disabled={isPreparationHistory}
        help={initValue?.bizInfo && isPreparationHistory ? initValue.bizInfo.ntsTaxTypeName : null}
      />
      <Form.Item
        name="bizFile"
        label="사업자등록증"
        hidden={isPreparationHistory || !isValidBizSerial}
        rules={[{ required: true }]}
        help={<Typography.Text type="danger">사진은 40mb까지 첨부하실 수 있습니다.</Typography.Text>}
      >
        <FileUpload maxCount={1} accept="image/*" />
      </Form.Item>
      <Form.Item shouldUpdate>
        {() => {
          const isFieldsTouched = form.isFieldTouched('bizSerial') && form.isFieldTouched('bizFile');
          return (
            <BottomButton
              bottomType="cancel"
              type="primary"
              htmlType="button"
              className="settlement-submit"
              disabled={
                isPreparationHistory || !isFieldsTouched || !!form.getFieldsError().filter(({ errors }) => errors.length).length
              }
              loading={isLoading}
              onClick={handleSaveBizInfo}
            >
              {isPreparationHistory ? '진행중인 변경 요청건이 있습니다.' : '상호명 수정 요청'}
            </BottomButton>
          );
        }}
      </Form.Item>
      <Form.Item name="bizCondition" label="업태" rules={[{ required: true }]}>
        <Input disabled />
      </Form.Item>
      <Form.Item name="bizType" label="업종" rules={[{ required: true }]}>
        <Input disabled />
      </Form.Item>
      <Form.Item name="chargeName" label="대표자 이름" rules={[{ required: true }]}>
        <Input placeholder="대표자 이름 입력" disabled />
      </Form.Item>
      <Form.Item name="phone" label="대표자 전화번호" rules={[{ required: true }]}>
        <Input placeholder="대표자 전화번호 입력" disabled />
      </Form.Item>
      <Form.Item>
        <FlexBoxFullWidthCenter justify="center">
          <a className="custom-channel" href="#channel">
            <u style={{ color: theme.color.primary }}>양도 1:1 문의하기</u>
          </a>
        </FlexBoxFullWidthCenter>
      </Form.Item>
    </Form>
  );
};

export default BizForm;
