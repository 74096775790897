import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Select } from 'antd';
import { IBankCodeValue, WarringBorrowedAccount } from 'components';
import { BankAccountFormItem } from 'components/FormItem';
import { IAccountCheckParams, IAccountCheckResult } from 'interfaces/admission';
import { validateMessages } from 'validators';
import { BottomButtonArea } from 'styles';
import theme from 'styles/theme';
import { EvidenceTypeCodeDesc } from './styles';

const { useForm } = Form;

export interface AdmissionStepThreeFormProps {
  initValues?;
  onNextStep;
  onPreviousStep;
  loading;
  bankCodes;
  onSearch: (v: IAccountCheckParams) => Promise<IAccountCheckResult | undefined>;
  searchLoading?;
  bizConditionList;
  bizTypeList;
}

const EvidenceTypeCode = (props) => (
  <EvidenceTypeCodeDesc>
    <Input placeholder="예금주명(대표자 또는 사업자명의) 입력" hidden {...props} />
    정산은 식권대장에서 사장님께 세금계산서를 역발행하여 진행됩니다. 입점신청 완료 후, 정산담당자 정보를 기입해주세요.
  </EvidenceTypeCodeDesc>
);

const AdmissionStepThreeForm = ({
  initValues,
  onNextStep,
  onPreviousStep,
  loading,
  bankCodes,
  onSearch,
  searchLoading,
  bizConditionList,
  bizTypeList
}: AdmissionStepThreeFormProps) => {
  const [form] = useForm();
  const [, forceUpdate] = useState({});

  // To disable submit button at the beginning.
  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    if (initValues && initValues.length) {
      const value = initValues[0];
      const { bankCode, bankAccount, evidenceTypeCode, bankAccountHolder, bizConditions, bizType, settlementReceiveEmail } =
        value;
      form.setFieldsValue({
        bank: {
          bankCode: bankCode || undefined,
          bankAccount: bankAccount || undefined,
          validStatus: 'changed'
        },
        bankAccountHolder: bankAccountHolder || undefined,
        evidenceTypeCode: evidenceTypeCode || 4,
        bizConditions: bizConditions || undefined,
        bizType: bizType || undefined,
        settlementReceiveEmail: settlementReceiveEmail || undefined
      });
      if (bankAccount) form.submit();
    }
  }, [form, initValues]);

  const handleSearch = async (value: Partial<IBankCodeValue>) => {
    if (
      !value ||
      typeof value?.bankCode === 'undefined' ||
      value?.bankCode === null ||
      typeof value?.bankAccount === 'undefined'
    ) {
      return undefined;
    }
    const bankAccountHolder = form.getFieldValue('bankAccountHolder');
    if (!bankAccountHolder) {
      Modal.warning({
        content: <>예금주명을 입력해주세요.</>,
        icon: null,
        okText: '확인',
        onOk: () => {},
        className: 'info-handler-modal'
      });
      return undefined;
    }
    const { bankCode, bankAccount } = value;
    const param = {
      accountHolder: bankAccountHolder,
      bankCode,
      accountNo: bankAccount
    };
    const result = await onSearch(param);
    return result;
  };

  const handleFieldsChange = (changeFields, allFields) => {
    const bankAccountHolderField = changeFields.find(({ name }) => name[0] === 'bankAccountHolder');
    const bank = allFields.find(({ name }) => name[0] === 'bank');
    if (changeFields.length === 1 && bankAccountHolderField && (bank?.value?.bankCode || bank?.value?.bankAccount)) {
      form.setFieldValue('bank', { validStatus: 'changed' });
      form.submit();
    }
  };

  return (
    <Form
      autoComplete="off"
      layout="vertical"
      name="admission_step3_form"
      form={form}
      onFinish={onNextStep}
      onFieldsChange={handleFieldsChange}
      validateMessages={validateMessages}
    >
      <Form.Item name="evidenceTypeCode" label="지출 증빙" rules={[{ required: true, type: 'number' }]}>
        <EvidenceTypeCode />
      </Form.Item>
      <Form.Item name="bankAccountHolder" label="예금주명(대표자 또는 사업자명의)" rules={[{ required: true }]}>
        <Input placeholder="예금주명(대표자 또는 사업자명의) 입력" maxLength={10} />
      </Form.Item>
      <div style={{ padding: '5px', fontSize: '13px', margin: '-20px 0px 20px', color: theme.color.gray02 }}>
        예금주명은 한글, 영문, 특수문자 및 공백문자 포함 최대 10자까지 입력 가능합니다.
      </div>
      <BankAccountFormItem
        onSearch={handleSearch}
        isSearching={searchLoading}
        bankCodes={bankCodes}
        accountHolderKey="bankAccountHolder"
      />
      <Form.Item>
        <WarringBorrowedAccount />
      </Form.Item>
      <Form.Item name="bizConditions" label="업태" rules={[{ required: true }]}>
        <Select>
          {bizConditionList &&
            bizConditionList.map((bizCondition) => <Select.Option key={bizCondition}>{bizCondition}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name="bizType" label="종목" rules={[{ required: true }]}>
        <Select>{bizTypeList && bizTypeList.map((bizType) => <Select.Option key={bizType}>{bizType}</Select.Option>)}</Select>
      </Form.Item>
      <Form.Item name="settlementReceiveEmail" label="정산정보 수신 이메일" rules={[{ required: true, type: 'email' }]}>
        <Input placeholder="example@xxx.com" />
      </Form.Item>
      <Form.Item shouldUpdate>
        {() => (
          <BottomButtonArea>
            <Button className="admission-submit-btn prev-btn" onClick={onPreviousStep}>
              이전
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="admission-submit-btn"
              disabled={!form.isFieldsTouched(true) || !!form.getFieldsError().filter(({ errors }) => errors.length).length}
              loading={loading}
            >
              다음
            </Button>
          </BottomButtonArea>
        )}
      </Form.Item>
    </Form>
  );
};

export default AdmissionStepThreeForm;
