import React, { useState } from 'react';
import { ICancelOrder } from 'interfaces/robot';
import { useRobotOrderDetail } from 'hooks/useRobotDelivery';

import { TColumns } from './components/DefaultTable';
import OrderDetailModal from './modal/OrderDetailModal';
import OrderAggregateCard from './components/OrderAggregateCard';
import { ContentWrapper, NoContentWrapper } from './styles';

interface OrderCancelPageProps {
  canceledOrders: ICancelOrder[];
}

const DETAIL_COLUMNS: TColumns[] = [
  { field: 'menuName', headerName: '메뉴명', width: 400 },
  { field: 'count', headerName: '수량', width: 150, textAlign: 'center' },
  { field: 'price', headerName: '가격', width: 200, textAlign: 'end' }
];

const OrderCancelPage = ({ canceledOrders }: OrderCancelPageProps) => {
  const [isDetailModal, setIsDetailModal] = useState(false);

  const { mutate: getOrderDetail, data: orderDetail } = useRobotOrderDetail();

  const handleOnOffDetailModal = () => setIsDetailModal(!isDetailModal);
  const handleDetailClick = async (couponId: string) => {
    getOrderDetail(couponId);
    handleOnOffDetailModal();
  };

  return (
    <ContentWrapper>
      {canceledOrders?.length ? (
        canceledOrders?.map((order) => {
          const totalOrderCount = order.menus.reduce((num, cur) => cur.count + num, 0);
          return (
            <OrderAggregateCard
              key={order.payRoomIdx}
              status={order.status}
              spotName={order.spotName}
              couponId={order.couponId}
              payRoomIdx={order.payRoomIdx}
              createDate={order.createDate}
              canceledDate={order.canceledDate}
              receiverName={order.receiverName}
              deliveryMemo={order.deliveryMemo}
              totalOrderCount={totalOrderCount}
              columns={DETAIL_COLUMNS}
              menus={order.menus}
              onDetailClick={handleDetailClick}
            />
          );
        })
      ) : (
        <NoContentWrapper>해당 상태의 주문 건이 없습니다.</NoContentWrapper>
      )}
      <OrderDetailModal open={isDetailModal} onCancel={handleOnOffDetailModal} orderDetail={orderDetail} />
    </ContentWrapper>
  );
};

export default OrderCancelPage;
