/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Button, Collapse, Row, Col, Divider } from 'antd';
import { DownOutlined, UpOutlined, RightOutlined } from '@ant-design/icons';
import moment from 'moment';
import { generate } from 'shortid';
import { useBookingDetail } from 'hooks';
import { BookingInfos, statusType } from 'interfaces/booking';
import { BookingContainer, BookingRow, StatusContainer, Table } from '../styles';

import DeliveryDetailModal from '../modal/DeliveryDetailModal';
import BookingPushModal from '../modal/BookingPushModal';
import RiderCallModal from '../modal/RiderCallModal';
import BookingCompanyInfos from './BookingCompanyInfos';
import DeliveryInfoModal from '../modal/DeliveryInfoModal';

import CompanyTable from './CompanyTable';

const { Panel } = Collapse;

interface IDeliveries {
  totalFee: number | string;
  assigning: number;
  delivered: number;
  canceled: number;
}

const DeliveryStatus = ({ agentAlias, status, quantity, onClick, cellphone }) => (
  <StatusContainer>
    <div className="number">{agentAlias.replace('라이더', '')}</div>
    <div>
      <img src={statusType[status].img} alt="" />
      <div className="phone">({status === 'DELIVERING' || status === 'DELIVERED' ? cellphone : '-'})</div>
    </div>

    <Button style={statusType[status].style} onClick={onClick}>
      <span className="dot">•</span>
      <span className="status">{statusType[status].status}</span>
      <span className="quantity">{quantity}</span>
      <RightOutlined className="arrow" />
    </Button>
  </StatusContainer>
);

const CompanyBy = ({ data, sid, bookingDate, onRefetch, companyId, diffArray }) => {
  const [allExpand, setAllExpand] = useState<Array<string>>([]);
  const [activeKey, setActiveKey] = useState<Array<string>>([]);
  const [recipient, setRecipient] = useState<Array<object>>([]);
  const [deliveryData, setDeliveryData] = useState<object>({});

  const [spotKey, setSpotKey] = useState<string>('');
  const [detailData, setDetailData] = useState<Array<object>>([]);
  const [agentIdx, setAgentIdx] = useState<number>();
  const [orderIdx, setOrderIdx] = useState<number>();
  const [bookingArtifactIdx, setBookingArtifactIdx] = useState<number>();

  const [isDetail, setDetail] = useState<boolean>(false);
  const [isPush, setPush] = useState<boolean>(false);
  const [isRiderCall, setRiderCall] = useState<boolean>(false);
  const [isDeliveryInfo, setDeliveryInfo] = useState<boolean>(false);

  const { mutateAsync } = useBookingDetail();

  const companies = useMemo(() => {
    const array: string[] = [];
    const bookingCompanyInfoData: BookingInfos[] = [];

    if (data?.bookingInfos) {
      data?.bookingInfos.forEach((infos) => {
        if (infos?.bookingCompanyInfos?.length <= 0) return;

        const group = infos?.bookingCompanyInfos?.reduce((result, value) => {
          const obj = Object.assign(result);
          if (!obj[value.companyId]) {
            obj[value.companyId] = {};
            obj[value.companyId] = {
              companyId: value.companyId,
              companyName: value.companyName,
              spots: []
            };
          }

          const spot = obj[value.companyId].spots.filter(({ spotKey: k }) => value.spotKey === k)[0];
          if (!spot) {
            obj[value.companyId].spots.push({
              spotKey: value.spotKey,
              spotName: value.spotName,
              bookingArtifactIdx: value.bookingArtifactIdx,
              bookedCount: value.bookedCount,
              couponId: value.couponId,
              menus: [value]
            });
          } else {
            spot.menus.push(value);
            spot.bookedCount += value.bookedCount;
          }
          array.push(value.spotKey);
          return obj;
        }, {});
        bookingCompanyInfoData.push({ ...infos, bookingCompanyInfos: Object.values(group) });
      });
    }

    setAllExpand(array);
    return bookingCompanyInfoData;
  }, [data]);

  useEffect(() => {
    setActiveKey([]);
  }, [bookingDate]);

  const onActiveKey = (key) => {
    setActiveKey(key);
  };

  const onAllExpand = () => {
    const newArray = activeKey.length > 0 ? [] : allExpand;
    setActiveKey(newArray);
  };

  const onDetail = useCallback(
    async (orderIndex: number, idx: number, isRefetch: boolean) => {
      const newDetailData = await mutateAsync(orderIndex);

      setDetailData(newDetailData);
      if (!isRefetch) {
        setAgentIdx(idx);
        setOrderIdx(orderIndex);
        setDetail(true);
      }
    },
    [mutateAsync]
  );

  const onClose = (isRefetch) => {
    setDetail(false);
    if (isRefetch) {
      onRefetch();
    }
  };

  useEffect(() => {
    const pilling = diffArray.filter(({ orderIdx: idx }) => idx === orderIdx)[0];
    if (isDetail && pilling) {
      onDetail(orderIdx, agentIdx, true);
    }
  }, [diffArray, orderIdx, agentIdx, isDetail, onDetail]);

  const onPushModal = (idx) => {
    if (idx) {
      const { bookingTimeCompanyInfos } = data;
      const array = bookingTimeCompanyInfos.filter(
        ({ bookingStoreIdx, status }) => bookingStoreIdx === idx && status.booking === '예약 완료'
      );
      setRecipient(array);
    }
    setPush(!isPush);
  };

  const onRiderCall = (spot: string, artifactIdx: number) => {
    if (!isRiderCall) {
      setSpotKey(spot);
      setBookingArtifactIdx(artifactIdx);
    }
    onActiveKey(spot);
    setRiderCall(!isRiderCall);
  };

  const closeRiderCall = (isRefetch?) => {
    setRiderCall(false);
    if (isRefetch) {
      setTimeout(() => onRefetch(), 100);
    }
  };

  const onDeliveryInfo = (e, id) => {
    e.stopPropagation();
    const delivery = data?.bookingTimeCompanyInfos.filter(({ couponId }) => id === couponId)[0];
    setDeliveryData(delivery);
    setDeliveryInfo(true);
  };

  const closeDeliveryInfo = () => {
    setDeliveryInfo(false);
  };

  const onDeliveries = (agents) => {
    const cancelType = ['CANCELED', 'OWNER_CANCELED', 'ASSIGN_FAILED', 'SUBMIT_FAILED'];

    if (agents.length > 0) {
      const deliveries: IDeliveries = { totalFee: 0, assigning: 0, delivered: 0, canceled: 0 };

      let total = 0;
      agents.forEach(({ status, fee }) => {
        if (status === 'DELIVERED') {
          deliveries.delivered += 1;
          total += fee;
        } else if (cancelType.indexOf(status) >= 0) {
          deliveries.canceled += 1;
        } else {
          deliveries.assigning += 1;
        }
      });

      deliveries.totalFee = deliveries.delivered > 0 && agents.length === deliveries.delivered + deliveries.canceled ? total : 0;
      return deliveries;
    }
    return false;
  };

  return (
    <BookingContainer>
      {data?.storeOption?.useDeliveryAgency ? (
        <div>
          {companies.map((item) => (
            <div key={item.bookingStoreIdx}>
              <div className="header">
                <div>
                  <span className="bookingStoreName">{item.bookingStoreName}</span>
                  {bookingDate === moment().format('YYYY-MM-DD') && companyId !== 'ALL' && (
                    <Button className="pushBtn" onClick={() => onPushModal(item.bookingStoreIdx)}>
                      예약완료 push 보내기
                    </Button>
                  )}
                </div>
                <Button className="allExpand" onClick={onAllExpand}>
                  {activeKey.length > 0 ? (
                    <span>
                      메뉴전체 닫기 <UpOutlined />
                    </span>
                  ) : (
                    <span>
                      메뉴전체 열기 <DownOutlined />
                    </span>
                  )}
                </Button>
              </div>
              <div>
                {item.bookingCompanyInfos.map(({ companyId: comId, companyName, spots }) => (
                  <BookingRow key={comId}>
                    <div className="companyName">{companyName}</div>
                    <div className="spotGroup">
                      {spots.map((spot) => {
                        const {
                          spotKey: key,
                          spotName,
                          menus,
                          bookedCount,
                          couponId,
                          bookingArtifactIdx: bookingArtifactIndex
                        } = spot;
                        const agents = data?.deliveries?.filter((value) => key === value.spotKey);
                        const deliveries = onDeliveries(agents);

                        const today = moment().format('YYYY-MM-DD');
                        const closeTimes = menus.map(({ closeTime }) => moment(closeTime));
                        const closeTime = moment.min(closeTimes).format('HH:mm:ss');
                        const current = moment().format('HH:mm:ss');

                        const disabled = bookingDate === today && closeTime < current && bookedCount !== 0;
                        return (
                          <Collapse className="bookingCollapse" onChange={onActiveKey} activeKey={activeKey} key={`spot-${key}`}>
                            <Panel
                              key={key}
                              header={
                                <div className="header">
                                  <div className="bookingInfos">
                                    <div className="spotName" onClick={(e) => onDeliveryInfo(e, couponId)}>
                                      {spotName}
                                    </div>

                                    <div className="bookedCount desc grid">
                                      <span>확정 메뉴 </span>
                                      <span>{bookedCount}</span>
                                    </div>

                                    <div onClick={(e) => e.stopPropagation()}>
                                      {data?.storeOption?.useDeliveryAgency && (
                                        <Button
                                          onClick={() => onRiderCall(key, bookingArtifactIndex)}
                                          disabled={!disabled}
                                          className="agentCallBtn"
                                        >
                                          라이더 호출
                                        </Button>
                                      )}
                                    </div>
                                  </div>
                                  {deliveries ? (
                                    <div className="deliveries">
                                      <div className="desc">
                                        <span>진행중 </span>
                                        <span>{deliveries.assigning}</span>
                                      </div>
                                      <Divider type="vertical" />
                                      <div className="desc grid">
                                        <span>배달완료 </span>
                                        <span>{deliveries.delivered}</span>
                                      </div>
                                      <Divider type="vertical" />
                                      <div className="desc">
                                        <span>취소 </span>
                                        <span>{deliveries.canceled}</span>
                                      </div>

                                      <ul>
                                        <li>
                                          <div className="media totalFee">
                                            <span>배달비 </span>
                                            <span>
                                              {deliveries.totalFee > 0 ? `${deliveries.totalFee.toLocaleString()}원` : '-'}
                                            </span>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  ) : (
                                    <div className="deliveries">
                                      <div className="desc full">호출중인 라이더가 없습니다</div>
                                    </div>
                                  )}
                                </div>
                              }
                            >
                              <Row gutter={[24, 0]}>
                                <Col span={16}>
                                  <div className="title">주문정보</div>
                                  <Table>
                                    <thead>
                                      <tr>
                                        <th style={{ textAlign: 'center', width: '50%' }}>메뉴</th>
                                        <th style={{ textAlign: 'center', width: '15%' }}>예약완료</th>
                                        <th style={{ textAlign: 'center', width: '15%' }}>예약취소</th>
                                        <th style={{ textAlign: 'center', width: '20%' }}>총액</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {menus.map((menuItem) => (
                                        <tr key={menuItem.menuId}>
                                          <td style={{ textAlign: 'center' }}>{menuItem.menuName}</td>
                                          <td style={{ textAlign: 'center' }}>{menuItem.bookedCount}</td>
                                          <td style={{ textAlign: 'center' }}>{menuItem.canceledCount}</td>
                                          <td style={{ textAlign: 'center' }}>{menuItem.bookedPrice.toLocaleString()}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </Table>
                                </Col>
                                <Col span={8}>
                                  <div className="title">배달상태</div>
                                  <div className="deliveryStatus">
                                    {deliveries ? (
                                      agents
                                        .reverse()
                                        .map((order) => (
                                          <DeliveryStatus
                                            key={generate()}
                                            {...order}
                                            onClick={() =>
                                              onDetail(order.orderIdx, order.agentAlias.replace('라이더', ''), false)
                                            }
                                          />
                                        ))
                                    ) : (
                                      <div className="desc">호출중인 라이더가 없습니다.</div>
                                    )}
                                  </div>
                                </Col>
                              </Row>
                            </Panel>
                          </Collapse>
                        );
                      })}
                    </div>
                  </BookingRow>
                ))}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <CompanyTable
          data={companies}
          bookingDate={bookingDate}
          companyId={companyId}
          onPushModal={onPushModal}
          onDeliveryInfo={onDeliveryInfo}
        />
      )}

      <BookingCompanyInfos data={data} companyId={companyId} bookingDate={bookingDate} sid={sid} />

      <DeliveryDetailModal open={isDetail} close={onClose} detailData={detailData} searchParams={{ orderIdx, agentIdx, sid }} />
      <BookingPushModal
        open={isPush}
        close={onPushModal}
        recipient={recipient}
        defaultChecked={false}
        searchParams={{ sid, bookingDate }}
      />
      <RiderCallModal
        open={isRiderCall}
        close={closeRiderCall}
        searchParams={{ sid, bookingDate, spotKey, bookingArtifactIdx }}
      />
      <DeliveryInfoModal open={isDeliveryInfo} close={closeDeliveryInfo} data={deliveryData} />
    </BookingContainer>
  );
};

export default CompanyBy;
