import React, { useEffect } from 'react';

const MenuUpdateModal = ({ isVisible, onOk, onClose, message, statusMsg, status, mids }) => {
  useEffect(() => {
    $('#menuModal').on('hidden.bs.modal', () => {
      onClose();
    });
  }, [onClose]);

  useEffect(() => {
    if (isVisible === true) {
      // TODO delete bootstrap
      // eslint-disable-next-line
      // @ts-ignore
      $('#menuModal').modal('show');
    } else if (isVisible === false) {
      // TODO delete bootstrap
      // eslint-disable-next-line
      // @ts-ignore
      $('#menuModal').modal('hide');
    }
  }, [isVisible]);

  const handleOk = async () => {
    if (!status) {
      onClose();
      return;
    }

    const params = {
      menuIds: mids,
      status
    };

    onOk(params);
    onClose();
  };

  return (
    <div id="menuModal" className="modal fade" role="dialog">
      <div className="modal-dialog margin-top-50" id="modal-box">
        <div className="modal-content modal-size">
          <div className="modal-header">
            <h4 className="modal-title">알림</h4>
          </div>
          <div className="modal-body">
            <div className="padding-left-20px">
              <p>{message}</p>
              <p>{statusMsg}</p>
            </div>
          </div>
          <div className="modal-footer margin-top-30">
            {mids && mids.length ? (
              <button type="button" className="btn btn-cancel" data-dismiss="modal" onClick={onClose}>
                취소
              </button>
            ) : null}
            <button type="button" className="btn btn-sikdae" onClick={handleOk}>
              확인
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuUpdateModal;
