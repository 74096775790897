import React from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';

const GridWrapper = styled(Row)`
  width: 100%;
  font-size: 18px;
  font-weight: 400;
  line-height: 43px;
  color: #212529;
  border-right: solid 1px #afb2b9;
  border-bottom: solid 1px #afb2b9;

  .grid-col {
    display: flex;
    align-items: center;
  }

  .grid-header-name {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 150px;
    width: 100%;
    height: 100%;
    min-height: 43px;
    background: rgba(0, 0, 0, 0.06);
    border: solid 1px #afb2b9;
    text-align: center;
    //
    border-right: none;
    border-bottom: none;
  }
  .grid-field {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 43px;
    padding: 0 12px;
    border: solid 1px #afb2b9;
    line-height: 30px;
    //
    border-right: none;
    border-bottom: none;
  }
`;

export interface TGridColumns {
  field: string;
  headerName: string;
  grid?: number;
  component?: (value: any) => React.ReactNode;
}

export interface DefaultGridProps<TData> {
  columns: TGridColumns[];
  data: TData;
}

const DefaultGrid = <TData,>({ columns, data }: DefaultGridProps<TData>) => {
  return (
    <GridWrapper>
      {columns.map(({ field, headerName, grid, component }) => {
        const value = data?.[field] ?? '-';
        return (
          <Col className="grid-col" key={field} span={24 / (grid || 1)}>
            <div className="grid-header-name">{headerName}</div>
            <div className="grid-field">{component ? component?.(value) : value}</div>
          </Col>
        );
      })}
    </GridWrapper>
  );
};

export default DefaultGrid;
