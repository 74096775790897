import React from 'react';
import { Location, Route, Routes, useLocation, useRoutes } from 'react-router-dom';
import { useGetMe, useMeInfo } from 'hooks';
import ExcelDownloadProvider from 'contexts/ExcelDownloadContext';
import { getPassCertification } from 'utils/storages';
import { MainLayout, TransitionLayout } from 'layouts';
import { HeaderContainer as Header, SidebarContainer as Sidebar, TopPopDescriptionContainer } from 'containers';
import { mainRoutes } from 'configs/route';
import { FullSpaceCenter } from 'styles';
import './Main.less';

import Admission from 'pages/Admission';
import Contract from 'pages/Admission/Contract';
import AdmissionComplete from 'pages/Admission/Complete';
import { ConfirmReorganization, Reorganization } from 'pages/Reorganization';
import { Certification, CertificationFault } from 'pages/Certification';

import logoSvg from 'images/N_Logo.svg';

const Main = () => {
  const location = useLocation();
  const { meInfo, selectedStore } = useMeInfo();
  const { isFetching } = useGetMe(true);

  const isPassCertification = getPassCertification(meInfo?.admin?.id);

  const state = location.state as { component?: string; backgroundLocation: Location };

  const mainElement = useRoutes(mainRoutes, state?.backgroundLocation || location);

  return (
    <>
      {!meInfo || isFetching ? (
        <FullSpaceCenter align="center">
          <img width={256} src={logoSvg} alt="" />
        </FullSpaceCenter>
      ) : null}
      {!isPassCertification && selectedStore && !meInfo?.admin?.isCertification ? (
        <Routes>
          <Route path="/" element={<TransitionLayout />}>
            <Route index element={<Reorganization />} />
            <Route path="/certification" element={<Certification />} />
            <Route path="/confirmReorganization" element={<ConfirmReorganization />} />
            <Route path="/certificationFault" element={<CertificationFault />} />
          </Route>
        </Routes>
      ) : null}
      {isPassCertification || !selectedStore || meInfo?.admin?.isCertification ? (
        <>
          <ExcelDownloadProvider>
            <MainLayout navigator={<Sidebar />} header={<Header location={state?.backgroundLocation || location} />}>
              <TopPopDescriptionContainer />
              {mainElement}
            </MainLayout>
          </ExcelDownloadProvider>
          {state?.backgroundLocation && (
            <Routes>
              <Route path="/admission">
                <Route index element={<Admission />} />
                <Route path="contract" element={<Contract />} />
                <Route path="complete" element={<AdmissionComplete />} />
              </Route>
            </Routes>
          )}
        </>
      ) : null}
    </>
  );
};

export default Main;
